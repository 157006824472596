import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, throwError, withLatestFrom } from 'rxjs';
import { MoveWizardDataService } from 'src/app/services/wizards/move-wizard-data.service';
import { MoveSharedAssetWizardActions } from '.';
import { selectInitStep } from './selectors';
import { State } from './state';

@Injectable()
export class MoveSharedAssetWizardEffects {
  newMoveSharedAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoveSharedAssetWizardActions.createNewMoveSharedAsset),
      withLatestFrom(this.store.select(selectInitStep)),
      concatMap(([{ request }, initStep]) =>
        this.moveWizardService.moveSharedAssetLicenseServices(initStep.assetId, request).pipe(
          map((response) => {
            if (response) {
              return MoveSharedAssetWizardActions.createNewMoveSharedAssetSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            const errorMessage = error;
            return of(MoveSharedAssetWizardActions.createNewMoveSharedAssetFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<State>, private moveWizardService: MoveWizardDataService) {}
}
