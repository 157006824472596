import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { ServiceAlias } from 'src/app/models/service';
import { ReplaceFeeditemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-feeditems-wizard/activation-step/replacefeeditems-due-date-info';
import { ReplaceFeeditemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-feeditems-wizard/init-step/init-step';
import * as fromReplaceFeeditemsWizard from './reducer';
import { State } from './state';

export const selectReplaceFeeditemsWizardState = createFeatureSelector<State>(fromReplaceFeeditemsWizard.replaceFeeditemsWizardFeatureKey);

export const selectInitStep = createSelector(selectReplaceFeeditemsWizardState, (state: State): ReplaceFeeditemsInitStep => state.initStep);

export const selectDueDateStep = createSelector(
  selectReplaceFeeditemsWizardState,
  (state: State): ReplaceFeeditemsDueDates => state.dueDatesStep
);

export const selectServicesStep = createSelector(selectReplaceFeeditemsWizardState, (state: State): ServiceAlias[] => state.serviceStep);

export const selectReplaceFeeditemsState = createSelector(
  selectReplaceFeeditemsWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectReplaceFeeditemsWizardState, (state: State): string => state.errorMessage);
