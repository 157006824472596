import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { interval, switchMap, takeWhile } from 'rxjs';
import { FormWizardData, FormWizardDataStatus } from 'src/app/models/forms/form-wizard';
import { ProgressMessage } from 'src/app/models/wizard-import-response';
import { FormDataService } from 'src/app/services/form-data.service';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

@Component({
  selector: 'app-generic-form-wizard-finish-step',
  templateUrl: './generic-form-wizard-finish-step.component.html',
  styleUrls: ['./generic-form-wizard-finish-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GenericFormWizardFinishStepComponent extends FormatComponent implements OnInit {
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;
  @Input() wizardId: number;
  @Output() progressCompleted = new EventEmitter();
  @Output() goToLinkEvEm = new EventEmitter();
  progressPercentage = 0;
  completed = false;
  errorMessage: string;
  isFinishOk = false;
  finishStepTitle: string = _('form_wizard_finish_step_title');

  progressMessages: ProgressMessage[] = [];
  displayedColumnsTable = ['progressTimestamp', 'progressText'];
  progressMsgDatasource: MatTableDataSource<ProgressMessage>;

  createdEntities: FormWizardData[] = [];
  entitiesDisplayedColumnTable = ['wizarddataKind', 'wizarddataName', 'wizarddataError', 'wizarddataLink'];
  createdEntityDatasource: MatTableDataSource<FormWizardData>;
  hintsDisplayedColumnTable = ['wizarddataHint'];
  hintDatasource: MatTableDataSource<FormWizardData>;

  constructor(private formDataService: FormDataService, private cdRef: ChangeDetectorRef, private router: Router) {
    super();
  }

  ngOnInit(): void {
    LogService.debug(this, 'START', `Server Finish Job is started`, null);
    this.progressPercentage = 0;
    this.completed = false;
    this.errorMessage = null;
    this.mockFinishData();
    this.progressMsgDatasource = new MatTableDataSource();
    this.createdEntityDatasource = new MatTableDataSource();
    this.hintDatasource = new MatTableDataSource();
    this.subscribe(
      interval(2000).pipe(
        takeWhile(() => !this.completed),
        switchMap(() => this.formDataService.getFinishStatus(this.wizardId))
      ),
      (response) => {
        if (response.data) {
          if (response.data.progressMessages) {
            this.progressMsgDatasource.data.push(...response.data.progressMessages);
            this.progressMsgDatasource._updateChangeSubscription();
            this.scrollToBottom();
          } else {
            // ONLY FOR DEV
            // this.progressMsgDatasource.data.push(...this.progressMessages);
            // this.progressMsgDatasource._updateChangeSubscription();
            // this.scrollToBottom();
          }
          if (response.data.data && response.data.data.length > 0) {
            this.createdEntityDatasource.data.push(...response.data.data);
            this.createdEntityDatasource._updateChangeSubscription();
            const hints = response.data.data.filter((d) => d.wizarddataHint != null);
            if (hints && hints.length > 0) {
              this.hintDatasource.data.push(...hints);
              this.hintDatasource._updateChangeSubscription();
            }
          } else {
            // ONLY FOR DEV
            // this.createdEntityDatasource.data.push(...this.createdEntities);
            // this.createdEntityDatasource._updateChangeSubscription();
            // const hints = this.createdEntities.filter((d) => d.wizarddataHint != null);
            // if (hints && hints.length > 0) {
            //   this.hintDatasource.data.push(...hints);
            //   this.hintDatasource._updateChangeSubscription();
            // }
          }
          if (!response.data.errorMessage) {
            this.progressPercentage = response.data.progress;
            this.completed = this.progressPercentage === 100;
            if (this.completed && !this.errorMessage) {
              this.isFinishOk = response.data.state;
              this.finishStepTitle = this.isFinishOk ? _('form_wizard_finish_ok') : _('form_wizard_finish_notok');
              this.progressCompleted.emit(response.data);
            }
          } else {
            this.errorMessage = response.data.error;
            this.isFinishOk = false;
            this.progressPercentage = 100;
            this.completed = true;
            this.progressCompleted.emit(response.data);
          }
        }
      }
    );
  }

  scrollToBottom(): void {
    this.cdRef.detectChanges();
    try {
      if (this.messagesContainer) {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      }
    } catch (err) {
      //
    }
  }

  goToLink(link: string) {
    // Wizard destroyed automatically
    this.goToLinkEvEm.emit();
    this.router.navigateByUrl(link);
  }

  mockFinishData() {
    // this.progressMessages = ['Price Created', 'Billing Item Created', 'Feed Item Created', 'Vendor Created', 'Service Created'];
    this.createdEntities.push({
      ...new FormWizardData(),
      wizarddataId: 1,
      wizarddataName: 'Service Sample',
      wizarddataKind: 'entity_service',
      wizarddataError: 'error_add_catalog_item_record_already_exists',
      wizarddataLink: '/pages/catalog',
      wizarddataHint: 'Test Hint',
    });
    this.createdEntities.push({
      ...new FormWizardData(),
      wizarddataId: 1,
      wizarddataName: 'Billing Item Sample',
      wizarddataKind: 'entity_srcu',
      wizarddataError: 'error_add_catalog_item_record_already_exists',
      wizarddataLink: '/pages/catalog',
    });
    this.createdEntities.push({
      ...new FormWizardData(),
      wizarddataId: 1,
      wizarddataName: 'Vendor Sample',
      wizarddataKind: 'entity_vendor',
      wizarddataError: 'error_add_catalog_item_record_already_exists',
      wizarddataLink: '/pages/catalog',
    });
  }

  get FormWizardDataStatus() {
    return FormWizardDataStatus;
  }
}
