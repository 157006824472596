import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TerminateMultiAddonsWizardEffects } from './effects';
import * as fromTerminateMultiAddonsWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTerminateMultiAddonsWizard.terminateMultiAddonsWizardFeatureKey, fromTerminateMultiAddonsWizard.reducer),
    EffectsModule.forFeature([TerminateMultiAddonsWizardEffects]),
  ],
})
export class TerminateMultiAddonsWizardStoreModule {}
