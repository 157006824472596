import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';

const PADDING = '000000';
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[matInputCommified]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputCommifiedDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatInputCommifiedDirective),
      multi: true,
    },
  ],
})
export class MatInputCommifiedDirective {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _value: string | null;
  private currentRegion: string;
  private DECIMAL_SEPARATOR: string;
  private THOUSANDS_SEPARATOR: string;

  constructor(private elementRef: ElementRef<HTMLInputElement>, protected store: Store<RootStoreState.State>) {
    this.store
      .select(PreferencesSelectors.selectPreferencesLanguageRegion)
      // Replace required for toLocaleString. Accept only Locale in format xx-XX
      .subscribe((langRegion) => (this.currentRegion = langRegion ? langRegion.region.replace('_', '-') : 'en'));
    this.DECIMAL_SEPARATOR = this.getDecimalSeparator(this.currentRegion);
    this.THOUSANDS_SEPARATOR = this.getThousandSeparator(this.currentRegion);
    console.log('created directive: ', this.THOUSANDS_SEPARATOR);
  }

  get value(): string | null {
    return this._value;
  }

  @Input('value')
  set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  private formatValue(value: string | null, fractionSize: number = 2) {
    if (value !== null) {
      let [integer, fraction = ''] = (value || '').toString().split('.');
      fraction = fractionSize > 0 ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize) : '';
      integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
      this.elementRef.nativeElement.value = integer + fraction;
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private unFormatValue(fractionSize: number = 2) {
    const value = this.elementRef.nativeElement.value;
    let [integer, fraction = ''] = (value || '').split(this.DECIMAL_SEPARATOR);

    // integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');
    integer = integer.replace(this.THOUSANDS_SEPARATOR, '');

    fraction =
      parseInt(fraction, 10) > 0 && fractionSize > 0 ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize) : '';

    if (value) {
      this.elementRef.nativeElement.value = integer + fraction;
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private getDecimalSeparator(locale) {
    const numberWithDecimalSeparator = 1.1;
    return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
  }

  private getThousandSeparator(locale) {
    const numberWithDecimalSeparator = 1111;
    return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    this._value = value.replace(this.DECIMAL_SEPARATOR, '.').replace(/[^\d.-]/g, '');
    this._onChange(this._value);
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue();
  }

  _onChange(value: any): void {}

  writeValue(value: any) {
    this._value = value;
    this.formatValue(this._value);
    // this._onChange(this._value);
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {}
}
