import { ActionResponse } from 'src/app/models/action-response';
import { MoveAssetBillingaccountInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-billingaccount-wizard/init-step/init-step';
import { MoveAssetBillingaccountMovingToStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-billingaccount-wizard/move-asset-billingaccount-movingto-step/move-asset-billingaccount-movingto-step';
import { MoveAssetWizardRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-wizard/move-asset-confirm-step/move-asset-wizard-request';

export interface State {
  initStep: MoveAssetBillingaccountInitStep;
  movingTo: MoveAssetBillingaccountMovingToStep;
  request: MoveAssetWizardRequest;
  response: ActionResponse;
  errorMessage: any;
}

export const initialState: State = {
  initStep: null,
  movingTo: null,
  request: null,
  response: null,
  errorMessage: null,
};
