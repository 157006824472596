import { ActionResponse } from 'src/app/models/action-response';
import { AssetSubscription } from 'src/app/models/asset-subscription';
import { CatalogPackageContent } from 'src/app/models/catalog-package-particulars';
import { DeactivateBillingitemAssetDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/deactivate-billingitem-asset-wizard/deactivation-step/deactivate-billingitem-asset-due-date-info';
import { DeactivateBillingitemAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/deactivate-billingitem-asset-wizard/init-step/deactivate-billingitem-asset-init-step';

export interface State {
  initStep: DeactivateBillingitemAssetInitStep;
  dueDatesStep: DeactivateBillingitemAssetDueDates;
  subscriptionStep: AssetSubscription[];
  packageContentStep: CatalogPackageContent[];
  deactivationResponse: ActionResponse;
  errorMessage: any;
}

export const initialState: State = {
  initStep: null,
  dueDatesStep: null,
  subscriptionStep: null,
  packageContentStep: null,
  deactivationResponse: null,
  errorMessage: null,
};
