import { Directive, ElementRef, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';

@Directive({
  selector: '[mat-input-decimal]',
})
export class OnlydecimalDirective {
  private DECIMAL_SEPARATOR: string;
  private THOUSANDS_SEPARATOR: string;
  private currentRegion: string;

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  inputElement: HTMLElement;
  constructor(public el: ElementRef, protected store: Store<RootStoreState.State>) {
    this.store
      .select(PreferencesSelectors.selectPreferencesLanguageRegion)
      // Replace required for toLocaleString. Accept only Locale in format xx-XX
      .subscribe((langRegion) => (this.currentRegion = langRegion ? langRegion.region.replace('_', '-') : 'en'));
    this.DECIMAL_SEPARATOR = this.getDecimalSeparator(this.currentRegion);
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }
    const reg = new RegExp(/^[0-9]|\.|\,|\-$/);
    // Ensure that it is a number and stop the keypress
    if (e.shiftKey || !reg.test(e.key)) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData.getData('text/plain');
    // Remove extra format char (thousands separator)
    const replacedInput = pastedInput.replace(/[^0-9-+\.\,]+/g, '');
    // Split into decimal and integer
    let regex;
    if (this.DECIMAL_SEPARATOR === '.') {
      regex = /([+-]?[0-9|^.|^,]+)[\.]([0-9]+)$/gim;
    } else {
      regex = /([+-]?[0-9|^.|^,]+)[\,]([0-9]+)$/gim;
    }
    let result = regex.exec(replacedInput);
    // console.log('Regex result: ' + result);
    let floatResult = result ? result[1].replace(/[.,]/g, '') + '.' + result[2] : pastedInput.replace(/[^0-9-+]/g, '');
    // console.log('Final result: ' + floatResult);

    document.execCommand('insertText', false, floatResult);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer.getData('text'); //.replace(/\D/g, '');
    // Remove extra format char (thousands separator)
    const replacedInput = textData.replace(/[^0-9-+\.\,]+/g, '');
    // Split into decimal and integer
    let regex;
    if (this.DECIMAL_SEPARATOR === '.') {
      regex = /([+-]?[0-9|^.|^,]+)[\.]([0-9]+)$/gim;
    } else {
      regex = /([+-]?[0-9|^.|^,]+)[\,]([0-9]+)$/gim;
    }
    let result = regex.exec(replacedInput);
    console.log('Regex result: ' + result);
    let floatResult = result ? result[1].replace(/[.,]/g, '') + '.' + result[2] : textData.replace(/[^0-9-+]/g, '');
    console.log('Final result: ' + floatResult);
    this.inputElement.focus();
    document.execCommand('insertText', false, floatResult);
  }

  private getDecimalSeparator(locale) {
    const numberWithDecimalSeparator = 1.1;
    return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
  }
}
