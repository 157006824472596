import { createAction, props } from '@ngrx/store';
import { GenericFormField } from 'src/app/models/forms/form-field';

export const loadProductConfigurations = createAction(
  '[Additional Info Page] Load Product Type Configurations',
  props<{ apiPath: string; relatedEntityKinds: string[]; relatedEntityIds: number[] }>()
);

export const loadProductConfigurationsSuccess = createAction(
  '[FoProducts API] Load Product Type Configurations Success',
  props<{ data: GenericFormField[] }>()
);

export const loadProductConfigurationsFailure = createAction(
  '[FoProducts API] Load Product Type Configurations Failure',
  props<{ error: string }>()
);

export const resetProductConfigurations = createAction('[Additional Info Page] Reset Product Type Configurations');
