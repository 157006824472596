import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { RemoveRecordsModalComponent } from 'src/app/core/modal/remove-records-modal/remove-records-modal.component';
import { Entity } from 'src/app/models/entity';
import { GenericEntityFormFieldEvent, GenericEntityFormFieldStep } from 'src/app/models/forms/form-field';
import { EntityFieldsListContentCallBack, GenericEntityField } from 'src/app/models/generic-entity-field';
import { RootStoreState } from 'src/app/root-store';
import { AuthStoreSelectors } from 'src/app/root-store/auth-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from '../../base-components/format-component';
import { GenericEntityFormComponent } from '../generic-entity-form/generic-entity-form.component';
import {
  GenericEntityWizardModalDialogComponent,
  GenericEntityWizardModalDialogData,
} from '../generic-entity-wizard-modal-dialog/generic-entity-wizard-modal-dialog.component';
import { GenericGridViewComponent, GenericGridViewModel } from '../generic-grid-view/generic-grid-view.component';

@Component({
  selector: 'app-generic-grid-view-with-details-and-related',
  templateUrl: './generic-grid-view-with-details-and-related.component.html',
  styleUrls: ['./generic-grid-view-with-details-and-related.component.scss'],
})
export class GenericGridViewWithDetailsAndRelatedComponent extends FormatComponent implements OnInit, AfterViewChecked {
  @ViewChild('confirmCancelMenu') confirmCancelMenu: MatMenuTrigger;
  @Input() titleKey: string;
  @Input() isEditing: boolean;
  @Input() isPanelExpanded = true;
  @Input() showGridTitle = false;
  @Input() showPanelTitleToggle = false;

  @Input() leftButtonKey = _('label_edit');
  @Input() rightButtonKey = _('label_add');
  @Input() allowRemove = true;
  @Input() autoSelectFirstRow = false;
  @Input() rightButtonEnabledOverride = true;
  @Input() useExternalRightButton = false;

  @Input() gridEntityKind: string;
  @Input() relatedDetailsEntityKind: string;
  @Input() gridApiPath: string;
  @Input() gridRefreshEvent: Observable<void>;
  @Input() gridEnableSearch = false;
  @Input() gridEnablePagination = false;
  @Input() gridEnableHeader = false;
  @Input() gridColumnsConfig: GenericGridViewModel[];
  @Input() gridNoDataMessage: string;
  @Input() gridPageSize = 10;

  @Input() detailsBaseApiPath: string;
  @Input() noDetailsCustomMessage = false;

  @Input() relatedEntityKinds: string[] = null;
  @Input() relatedEntityIds: number[] = null;

  @Input() isRelatedFirst = false;
  @Input() createEventDataChangedCallback?: (event: GenericEntityFormFieldEvent) => void;

  @Output() isEditingChange = new EventEmitter<boolean>();
  @Output() externalRightButtonEvEm = new EventEmitter();
  @Output() rowGridSelectedEvEm = new EventEmitter<Entity>();
  @Output() treeNodeFetchedEvEm = new EventEmitter<GenericEntityField[]>();
  @Output() rowDetailsUpdatedEvEm = new EventEmitter<GenericEntityField[]>();
  @Output() eventDataChanged = new EventEmitter<GenericEntityFormFieldEvent>();

  leftButtonEnabled = false;
  rightButtonEnabled = false;
  isLoading = false;

  selectedEntitySubject: BehaviorSubject<Entity> = new BehaviorSubject<Entity>(null);
  selectedRelatedEntitySubject: BehaviorSubject<Entity> = new BehaviorSubject<Entity>(null);
  relatedRecord: Entity;
  selectedRow: Entity;
  selectedRowToRestore: Entity;
  noEntityData = false;
  detailsRowNoData = false;
  saveIsDisabled = true;
  enableConfirmCancel = false;
  isTenantAdmin = false;
  // gridRowData: GenericEntityField[];
  atomicDetailsSteps: GenericEntityFormFieldStep[];
  atomicRelatedDetailsSteps: GenericEntityFormFieldStep[];
  atomicCommonIsLoading = false;
  atomicRelatedCommonIsLoading = false;
  entityFormIsSaving = false;
  entityRelatedFormIsSaving = false;
  //Special flag
  overrideCreateCancel = false;

  @ViewChild('genericGridView') grid: GenericGridViewComponent;
  @ViewChild('entityFormContent') entityFormContent: GenericEntityFormComponent;
  @ViewChild('entityRelatedFormContent') entityRelatedFormContent: GenericEntityFormComponent;

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected coreDataService: CoreDataService,
    protected store: Store<RootStoreState.State>,
    protected messageNotifierService: MessageNotifierService,
    protected router: Router,
    protected dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    const m = this.ngOnInit.name;
    LogService.debug(this, m, 'gridApiPath: ' + this.gridApiPath, null);
    LogService.debug(this, m, 'detailsBaseApiPath: ' + this.detailsBaseApiPath, null);
    this.cdRef.detectChanges();

    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin)), (isTenantAdmin) => {
      this.isTenantAdmin = isTenantAdmin;
    });
    if (this.isPanelExpanded === undefined) {
      this.isPanelExpanded = true;
    }
    if (this.gridRefreshEvent) {
      this.subscribe(this.gridRefreshEvent, () => {
        // this.selectedRow = this.selectedRowToRestore ? this.selectedRowToRestore : null;
        // this.selectedEntitySubject.next(this.selectedRow);
        // this.selectedRowToRestore = null;
        this.detailsRowNoData = false;
      });
    }
  }

  ngAfterViewChecked(): void {
    let saveIsDisabledDetails: boolean;
    let saveIsDisabledRelatedDetails: boolean;
    if (this.entityRelatedFormContent) {
      saveIsDisabledRelatedDetails = this.entityRelatedFormContent.saveIsDisabled;
    }
    if (this.entityFormContent) {
      saveIsDisabledDetails = this.entityFormContent.saveIsDisabled;
    }
    this.saveIsDisabled = saveIsDisabledRelatedDetails && saveIsDisabledDetails;
    this.cdRef.detectChanges();
  }

  loadEntityFormFieldSteps() {
    if (this.selectedRow) {
      this.atomicCommonIsLoading = true;
      this.subscribe(
        this.coreDataService.getGenericEntityFormFieldSteps(this.selectedRow.entityKind, this.selectedRow.entityId, this.isTenantAdmin),
        ({ data }) => (this.atomicDetailsSteps = data),
        (error) => LogService.error(this, this.loadEntityFormFieldSteps.name, 'Error Loading Detail Steps', error),
        () => {
          this.atomicCommonIsLoading = false;
          this.selectedEntitySubject.next(this.selectedRow);
        }
      );
    }
  }

  async loadRelatedEntityFormFieldSteps() {
    if (this.selectedRow) {
      this.atomicRelatedCommonIsLoading = true;
      const { data } = await this.coreDataService
        .getGenericEntityRelatedRecord(this.selectedRow.entityKind, this.selectedRow.entityId, this.relatedDetailsEntityKind)
        .toPromise();
      this.relatedRecord = data;
      if (this.relatedRecord) {
        this.subscribe(
          this.coreDataService.getGenericEntityFormFieldSteps(
            this.relatedRecord.entityKind,
            this.relatedRecord.entityId,
            this.isTenantAdmin
          ),
          ({ data }) => (this.atomicRelatedDetailsSteps = data),
          (error) => LogService.error(this, this.loadRelatedEntityFormFieldSteps.name, 'Error Loading Detail Steps', error),
          () => {
            this.atomicRelatedCommonIsLoading = false;
            this.selectedRelatedEntitySubject.next(this.selectedRow);
          }
        );
      } else {
        this.atomicRelatedCommonIsLoading = false;
      }
    }
  }

  onEditChange($event: boolean) {
    this.isEditingChange.emit($event);
    this.isEditing = $event;
    this.cdRef.detectChanges();
  }

  leftButtonClick($event) {
    const m = this.leftButtonClick.name;

    this.isEditing = true;
    this.isEditingChange.emit(this.isEditing);
    $event.stopPropagation();
  }

  rightButtonClick($event) {
    const m = this.rightButtonClick.name;

    $event.stopPropagation();

    if (this.useExternalRightButton) {
      this.externalRightButtonEvEm.emit();
    } else {
      const dialogRef = this.dialog.open(GenericEntityWizardModalDialogComponent, {
        autoFocus: false,
        width: '90vw',
        maxWidth: '90vw',
        height: '90vh',
        panelClass: 'generic-entity-wizard-modal-dialog',
        disableClose: true,
        data: {
          wizardName: this.gridEntityKind,
          relatedEntityKinds: this.relatedEntityKinds,
          relatedEntityIds: this.relatedEntityIds,
          eventDataChangedCallback: this.createEventDataChangedCallback,
          isDocumentsTabEnabled: this.isDocumentEnabled,
        } as GenericEntityWizardModalDialogData,
      });
      this.subscribe(dialogRef.afterClosed(), (entity) => {
        if (entity) {
          this.selectedRowToRestore = entity;
          this.rowDetailsUpdatedEvEm.emit(entity);
        }
      });
    }
  }

  gridRowClick(entity: Entity) {
    const m = this.gridRowClick.name;

    this.selectedRow = null;
    this.cdRef.detectChanges();
    this.selectedRow = entity;
    this.grid.gbSetSelectedEntity(entity);
    this.selectedEntitySubject.next(this.selectedRow);
    this.loadEntityFormFieldSteps();
    this.loadRelatedEntityFormFieldSteps();
    this.leftButtonEnabled = entity.entityUpdate;
    LogService.debug(this, m, 'selectedRow: ', this.selectedRow);
    this.cdRef.detectChanges();
    this.rowGridSelectedEvEm.emit(entity);
  }

  resetSelectedRow() {
    this.selectedRow = null;
    this.selectedEntitySubject.next(this.selectedRow);
    this.rowGridSelectedEvEm.emit(null);
    this.cdRef.detectChanges();
  }

  getPutMainAPIFormFieldsPath(stepFormId: number) {
    return `${this.detailsBaseApiPath}/${this.selectedRow.entityId}/${stepFormId}/form`;
  }

  getPutRelatedAPIFormFieldsPath(stepFormId: number) {
    return `api/entities/${this.relatedDetailsEntityKind}/${this.relatedRecord.entityId}/${stepFormId}/form`;
  }

  fetchDetailsCallbackEvEm(callback: EntityFieldsListContentCallBack) {
    const m = this.fetchDetailsCallbackEvEm.name;

    LogService.debug(this, m, 'FETCH DETAILS callback.isSuccess: ' + callback.isSuccess + ' - Callback Object:', callback);
    // Nothing;
    if (!callback.data || callback.data.length === 0) {
      this.detailsRowNoData = true;
    }
  }

  createUpdateCallbackEvEm(callback: EntityFieldsListContentCallBack) {
    const m = this.createUpdateCallbackEvEm.name;

    LogService.debug(this, m, 'callback.isSuccess: ' + callback.isSuccess + ' - Callback Object:', callback);
    this.entityFormIsSaving = false;
    if (!this.entityFormIsSaving && !this.entityRelatedFormIsSaving) {
      this.isEditing = callback.isEditing;
      this.cdRef.detectChanges();
      this.isEditingChange.emit(this.isEditing);
      if (callback.isSuccess) {
        this.selectedRowToRestore = this.selectedRow;
        this.rowDetailsUpdatedEvEm.emit(callback.data);
      }
    }
  }

  createUpdateRelatedCallbackEvEm(callback: EntityFieldsListContentCallBack) {
    const m = this.createUpdateCallbackEvEm.name;

    LogService.debug(this, m, 'callback.isSuccess: ' + callback.isSuccess + ' - Callback Object:', callback);
    this.entityRelatedFormIsSaving = false;
    if (!this.entityFormIsSaving && !this.entityRelatedFormIsSaving) {
      this.isEditing = callback.isEditing;
      this.cdRef.detectChanges();
      this.isEditingChange.emit(this.isEditing);
      if (callback.isSuccess) {
        this.selectedRowToRestore = this.selectedRow;
        this.rowDetailsUpdatedEvEm.emit(callback.data);
      }
    }
  }

  onSave($event) {
    if (this.entityRelatedFormContent) {
      const changedFields = this.entityRelatedFormContent.checkDiffAndErrors();
      if (changedFields) {
        if (changedFields.length > 0) {
          this.entityRelatedFormContent.onSave($event);
          this.overrideCreateCancel = false;
          this.entityRelatedFormIsSaving = true;
        }
      } else return;
    }
    if (this.entityFormContent) {
      const changedFields = this.entityFormContent.checkDiffAndErrors();
      if (changedFields) {
        if (changedFields.length > 0) {
          this.entityFormContent.onSave($event);
          this.overrideCreateCancel = false;
          this.entityFormIsSaving = true;
        }
      } else return;
    }
    $event.stopPropagation();
  }

  onCancel($event) {
    if (!this.saveIsDisabled) {
      this.enableConfirmCancel = true;
      this.cdRef.detectChanges();
      this.confirmCancelMenu.openMenu();
      // this.confirmCancelButton.focus();
      $event.stopPropagation();
    } else {
      this.onConfirmCancel();
    }
  }

  onConfirmCancel() {
    if (this.entityRelatedFormContent) {
      this.entityRelatedFormContent.onConfirmCancel();
    }
    if (this.entityFormContent) {
      this.entityFormContent.onConfirmCancel();
    }
    if (this.overrideCreateCancel) {
      // delete details
      this.removeDetailsRecords();
    }
    this.overrideCreateCancel = false;
    this.enableConfirmCancel = false;
  }

  removeDetailsRecords() {
    // const detailsRecord = { ...new Entity(), entityId: this.selectedRow.entityId, entityKind: this.selectedRow.entityKind };
    const relatedDetailsRecord = { ...new Entity(), entityId: this.relatedRecord.entityId, entityKind: this.relatedRecord.entityKind };
    // const selectedEntities: Entity[] = [detailsRecord, relatedDetailsRecord];
    const selectedEntities: Entity[] = [relatedDetailsRecord];
    this.subscribe(
      this.dialog
        .open(RemoveRecordsModalComponent, {
          width: '70vw',
          minHeight: '60vh',
          autoFocus: false,
          disableClose: true,
          data: {
            entities: selectedEntities,
            isTenantAdmin: this.isTenantAdmin,
          },
        })
        .afterClosed(),
      (result) => {
        if (result) {
          this.selectedRow = null;
          this.atomicDetailsSteps = null;
          this.atomicRelatedDetailsSteps = null;
          this.relatedRecord = null;
          this.selectedEntitySubject.next(null);
          this.grid.refreshGrid();
        }
      }
    );
  }
}
