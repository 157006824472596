import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { GlobalDateFilterStoreAction } from 'src/app/root-store/global-date-filter-store';
import { FinofficeErrorToastComponent } from 'src/app/shared/toast/finoffice-error-toast/finoffice-error-toast.component';
import { FinofficeInfoToastComponent } from 'src/app/shared/toast/finoffice-info-toast/finoffice-info-toast.component';
import { FinofficeSuccessToastComponent } from 'src/app/shared/toast/finoffice-success-toast/finoffice-success-toast.component';
import { FinofficeWarningToastComponent } from 'src/app/shared/toast/finoffice-warning-toast/finoffice-warning-toast.component';

@Injectable({
  providedIn: 'root',
})
export class MessageNotifierService {
  constructor(
    protected store: Store<RootStoreState.State>,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {}

  public showSuccessMessage(key: string) {
    const opt: GlobalConfig = JSON.parse(JSON.stringify(this.toastr.toastrConfig));
    opt.toastComponent = FinofficeSuccessToastComponent;
    opt.toastClass = 'finoffice-toastr';
    this.translate
      .get(key)
      .pipe(take(1))
      .subscribe((message) => this.toastr.success('', message, opt));
  }

  public showInfoMessage(key: string) {
    const opt: GlobalConfig = JSON.parse(JSON.stringify(this.toastr.toastrConfig));
    opt.toastComponent = FinofficeInfoToastComponent;
    opt.toastClass = 'finoffice-toastr';
    this.translate
      .get(key)
      .pipe(take(1))
      .subscribe((message) => this.toastr.info('', message, opt));
  }

  public showWarningMessage(key: string) {
    const opt: GlobalConfig = JSON.parse(JSON.stringify(this.toastr.toastrConfig));
    opt.toastComponent = FinofficeWarningToastComponent;
    opt.toastClass = 'finoffice-toastr';
    this.translate
      .get(key)
      .pipe(take(1))
      .subscribe((message) => this.toastr.warning('', message, opt));
  }

  public showErrorMessage(key: string, systemerrorId?: number) {
    if (systemerrorId != null) this.showSystemErrorMessage(systemerrorId);
    else {
      const opt: GlobalConfig = JSON.parse(JSON.stringify(this.toastr.toastrConfig));
      opt.toastComponent = FinofficeErrorToastComponent;
      opt.toastClass = 'finoffice-toastr';
      this.translate
        .get(key)
        .pipe(take(1))
        .subscribe((message) => this.toastr.error('', message, opt));
    }
  }

  public showFixedSuccessMessage(key: string) {
    const opt: GlobalConfig = JSON.parse(JSON.stringify(this.toastr.toastrConfig));
    opt.toastComponent = FinofficeSuccessToastComponent;
    opt.toastClass = 'finoffice-toastr';
    opt.disableTimeOut = true;
    opt.closeButton = true;
    opt.tapToDismiss = false;
    opt.enableHtml = true;
    this.translate
      .get(key)
      .pipe(take(1))
      .subscribe((message) => this.toastr.success('', message, opt));
  }

  public showFixedInfoMessage(key: string) {
    const opt: GlobalConfig = JSON.parse(JSON.stringify(this.toastr.toastrConfig));
    opt.toastComponent = FinofficeInfoToastComponent;
    opt.toastClass = 'finoffice-toastr';
    opt.disableTimeOut = true;
    opt.closeButton = true;
    opt.tapToDismiss = false;
    opt.enableHtml = true;
    this.translate
      .get(key)
      .pipe(take(1))
      .subscribe((message) => this.toastr.info('', message, opt));
  }

  public showFixedWarningMessage(key: string) {
    const opt: GlobalConfig = JSON.parse(JSON.stringify(this.toastr.toastrConfig));
    opt.toastComponent = FinofficeWarningToastComponent;
    opt.toastClass = 'finoffice-toastr';
    opt.disableTimeOut = true;
    opt.closeButton = true;
    opt.tapToDismiss = false;
    opt.enableHtml = true;
    this.translate
      .get(key)
      .pipe(take(1))
      .subscribe((message) => this.toastr.warning('', message, opt));
  }

  public showFixedErrorMessage(key: string) {
    const opt: GlobalConfig = JSON.parse(JSON.stringify(this.toastr.toastrConfig));
    opt.toastComponent = FinofficeErrorToastComponent;
    opt.toastClass = 'finoffice-toastr';
    opt.disableTimeOut = true;
    opt.closeButton = true;
    opt.tapToDismiss = false;
    opt.enableHtml = true;
    this.translate
      .get(key)
      .pipe(take(1))
      .subscribe((message) => this.toastr.error('', message, opt));
  }

  public showSystemErrorMessage(errorId: number) {
    const opt: GlobalConfig = JSON.parse(JSON.stringify(this.toastr.toastrConfig));
    opt.toastComponent = FinofficeErrorToastComponent;
    opt.toastClass = 'finoffice-toastr';
    opt.disableTimeOut = true;
    opt.closeButton = true;
    opt.tapToDismiss = false;
    opt.enableHtml = true;
    const clickHere = this.translate.instant(_('toastr_systemerror_click_here'));
    this.translate
      .get(_('toastr_systemerror_generic_error_message'))
      .pipe(take(1))
      .subscribe((message) => {
        const toast = this.toastr.error(clickHere, message, opt);
        toast.onTap.pipe(take(1)).subscribe(() => {
          // Converts the route into a string that can be used
          // with the window.open() function
          this.store.dispatch(GlobalDateFilterStoreAction.saveGlobalDateFilterStatus());
          const urlTree = this.router.createUrlTree([`pages/system-errors`]);
          urlTree.queryParams['error-id'] = errorId;
          const url = this.router.serializeUrl(urlTree);
          window.open(url, '_blank');
          toast.toastRef.manualClose();
        });
      });
  }

  public showRequestCreatedMessage(requestIds: number[], isPartiallyValidated: boolean) {
    const opt: GlobalConfig = JSON.parse(JSON.stringify(this.toastr.toastrConfig));
    opt.toastComponent = FinofficeWarningToastComponent;
    opt.toastClass = 'finoffice-toastr';
    opt.disableTimeOut = true;
    opt.closeButton = true;
    opt.tapToDismiss = false;
    opt.enableHtml = true;
    const clickHere = this.translate.instant(_('toastr_request_click_here'));
    this.translate
      .get(isPartiallyValidated ? _('toastr_request_created_notfullyvalidated_message') : _('toastr_request_created_success_message'))
      .pipe(take(1))
      .subscribe((message) => {
        const toast = this.toastr.warning(isPartiallyValidated ? clickHere : '', message, opt);
        toast.onTap.pipe(take(1)).subscribe(() => {
          // Converts the route into a string that can be used
          // with the window.open() function
          const urlTree = this.router.createUrlTree([`pages/requests`]);
          urlTree.queryParams['requestIds'] = requestIds.join(',');
          const url = this.router.serializeUrl(urlTree);
          this.router.navigateByUrl(url);
          toast.toastRef.manualClose();
        });
      });
  }
}
