/* eslint-disable no-useless-escape */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { select } from '@ngrx/store';
import { AuthStoreAction, AuthStoreSelectors } from 'src/app/root-store';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { AuthError } from '../auth-result';
import { AuthUser } from '../auth-user';
import { ChangePwdAttempt } from '../change-pwd-attempt';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordComponent extends FormatComponent implements OnInit {
  authUser: AuthUser;
  changePwdForm: UntypedFormGroup;
  changePwdAttempt: ChangePwdAttempt;
  email: string;

  isSubmitted = false;
  isChanged = false;
  error: AuthError = null;

  entityDetailsIsOpen = true;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectAuthUser)), (authUser) => {
      this.email = authUser.email;
      this.changePwdForm = this.formBuilder.group(
        {
          email: [this.email, Validators.required],
          oldPassword: ['', Validators.required],
          newPassword: [
            '',
            Validators.compose([
              Validators.required,
              this.patternValidator(/\d/, {
                hasNumber: true,
              }),
              this.patternValidator(/[A-Z]/, {
                hasCapitalCase: true,
              }),
              this.patternValidator(/[a-z]/, {
                hasSmallCase: true,
              }),
              this.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
                hasSpecialCharacters: true,
              }),
              Validators.minLength(8),
            ]),
          ],
          confirmNewPassword: ['', Validators.required],
        },
        {
          // check whether our new password and confirm password match
          validators: [PasswordMatchValidator.validate],
        }
      );
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectChangePwdError)), (error) => {
      this.error = error;
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectPwdChanged)), (isChanged) => {
      this.isChanged = isChanged;
      if (this.isChanged) {
        setTimeout(() => {
          this.store.dispatch(AuthStoreAction.changePwdSuccessDone());
          this.router.navigate(['']);
        }, 3000);
      }
    });
  }

  getErrorMessageOldPassword() {
    const { errors } = this.changePwdForm.controls.oldPassword;
    if (errors.required && this.changePwdForm.value.oldPassword) {
      return _('changepwd_error_required_oldpwd');
    }
  }

  getErrorMessageNewPassword() {
    const { errors } = this.changePwdForm.controls.newPassword;
    const errorMessage: string[] = [''];

    if (errors.required && this.changePwdForm.value.newPassword) {
      errorMessage.push(_('changepwd_error_required_newpwd'));
    }
    if (errors.noSamePassword) {
      errorMessage.push(_('changepwd_error_nosamepwd'));
    }
    if (errors.minlength) {
      errorMessage.push(_('changepwd_error_nominlength'));
    }
    if (errors.hasNumber) {
      errorMessage.push(_('changepwd_error_nonumber'));
    }
    if (errors.hasCapitalCase) {
      errorMessage.push(_('changepwd_error_nocapitalcase'));
    }
    if (errors.hasSmallCase) {
      errorMessage.push(_('changepwd_error_nosmallcase'));
    }
    if (errors.hasSpecialCharacters) {
      errorMessage.push(_('changepwd_error_nospecialchar'));
    }
    return errorMessage;
  }

  getErrorMessageConfirmNewPassword() {
    const { errors } = this.changePwdForm.controls.confirmNewPassword;
    const errorMessage: string[] = [''];
    if (this.changePwdForm.value.oldPassword && this.changePwdForm.value.newPassword && this.changePwdForm.value.confirmNewPassword) {
      if (errors.required) {
        errorMessage.push(_('changepwd_error_required_confirmpwd'));
      }
      if (errors.passwordNotMatching) {
        errorMessage.push(_('changepwd_error_nomatchpwd'));
      }
    }
    return errorMessage;
  }

  changePwd(): void {
    const m = this.changePwd.name;

    this.error = null;
    this.isSubmitted = true;
    if (this.changePwdForm.invalid) {
      LogService.debug(this, m, 'changePwdForm is invalid', this.changePwdForm);
      return;
    }
    this.changePwdAttempt = new ChangePwdAttempt(
      this.changePwdForm.value.email,
      this.changePwdForm.value.oldPassword,
      this.changePwdForm.value.newPassword
    );
    this.store.dispatch(AuthStoreAction.changePwd({ changePwd: this.changePwdAttempt }));
  }

  get formControls() {
    return this.changePwdForm.controls;
  }

  private patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  expandPanel($event) {
    this.entityDetailsIsOpen = !this.entityDetailsIsOpen;
    $event.stopPropagation();
  }
}

class PasswordMatchValidator {
  static validate(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const oldPassword: string = control.get('oldPassword').value;
      const newPassword: string = control.get('newPassword').value;
      const confirmPassword: string = control.get('confirmNewPassword').value;
      if (oldPassword && newPassword) {
        if (newPassword !== oldPassword) {
          if (newPassword !== confirmPassword) {
            control.get('confirmNewPassword').setErrors({ passwordNotMatching: true });
            return { passwordNotMatching: true };
          } else {
            control.get('confirmNewPassword').setErrors(null);
          }
          control.get('newPassword').setErrors(null);
        } else {
          control.get('newPassword').setErrors({ noSamePassword: true });
          return { noSamePassword: true };
        }
      }
    }
    return null;
  }
}
