import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromConfigurationSearchStore from './reducer';
import { State } from './state';

export const selectConfigurationSearchStoreState = createFeatureSelector<State>(
  fromConfigurationSearchStore.configurationSearchStoreFeatureKey
);

export const selectConfigurationSearchFilter = createSelector(
  selectConfigurationSearchStoreState,
  (state: State): string => state.newFilter
);
