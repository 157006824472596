import { Directive, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

// TODO: Add Angular decorator.
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseComponent implements OnDestroy {
  private readonly subs: Subscription[];

  constructor() {
    this.subs = [];
  }

  public subscribe<T>(o: Observable<T>, next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    const sub = o.subscribe({ next, error, complete });
    this.subs.push(sub);
    return sub;
  }

  public ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub) => sub.unsubscribe());
    }
    // Remove wait mouse curson if change page
    // eslint-disable-next-line no-undef
    document.body.classList.remove('waiting-mouse-cursor');
  }
}
