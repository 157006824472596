import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { News } from 'src/app/models/news';
import { RootStoreState } from 'src/app/root-store';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

export interface NewsModalData {
  news: News[];
}

@Component({
  selector: 'app-news-modal',
  templateUrl: './news-modal.component.html',
  styleUrls: ['./news-modal.component.scss'],
})
export class NewsModalComponent extends FormatComponent implements OnInit {
  news: News[];
  displayedColumnsTable: string[] = ['news'];
  dataSourceTable: MatTableDataSource<News>;

  constructor(
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<NewsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewsModalData
  ) {
    super();
  }

  ngOnInit(): void {
    this.news = this.data.news;
    this.dataSourceTable = new MatTableDataSource(this.news);
  }
}
