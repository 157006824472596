import { Action, createReducer, on } from '@ngrx/store';
import * as AllocateConsumerAddonsWizardActions from './actions';
import { State, initialState } from './state';

export const allocateConsumerAddonWizardFeatureKey = 'allocateConsumerAddonWizard';

const allocateConsumerAddonWizardReducer = createReducer(
  initialState,

  on(AllocateConsumerAddonsWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(AllocateConsumerAddonsWizardActions.selectDatesStep, (state, { datesStep }) => ({
    ...state,
    datesStep,
  })),
  on(AllocateConsumerAddonsWizardActions.selectAssetsStep, (state, { assetsStep }) => ({
    ...state,
    assetsStep,
  })),
  on(AllocateConsumerAddonsWizardActions.exitAllocateConsumerAddonsWizard, (state) => ({
    ...state,
    initStep: null,
    datesStep: null,
    assetsStep: null,
  })),
  on(AllocateConsumerAddonsWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    datesStep: null,
    assetsStep: null,
    productList: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return allocateConsumerAddonWizardReducer(state, action);
}
