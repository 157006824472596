import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GlobalSearchStoreEffects } from './effects';
import * as fromGlobalSearchStore from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromGlobalSearchStore.globalSearchStoreFeatureKey, fromGlobalSearchStore.reducer),
    EffectsModule.forFeature([GlobalSearchStoreEffects]),
  ],
})
export class GlobalSearchStoreModule {}
