import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { EntityWizardResponse } from '../models/forms/entity-wizard';
import { PaginatedRequest } from '../models/paginated-request';
import {
  BillingAccount,
  BillingAccountMisuService,
  BillingAccountParticulars,
  BillingAccountParticularsRequest,
} from '../models/vendor-account';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class VendorAccountDataService {
  getVendorAccounts(request: PaginatedRequest): Observable<BaseResponse<BillingAccount[]>> {
    const m = this.getVendorAccounts.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<BillingAccount[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getVendorAccountsSpreadsheet(request: PaginatedRequest) {
    const m = this.getVendorAccountsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  changeVendorAccountRule(billingaccountId: number, ruleId: number, date: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.changeVendorAccountRule.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS_RULE_CHANGE(billingaccountId, ruleId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    const params = new HttpParams().set('starts', date);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getBillingAccountParticularsDefault(): Observable<BaseResponse<BillingAccountParticulars>> {
    const m = this.getBillingAccountParticularsDefault.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS_PARTICULAR_DEFAULTS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<BillingAccountParticulars>>(path);
    return retVal;
  }

  createBillingAccountParticulars(request: BillingAccountParticularsRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.createBillingAccountParticulars.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS_PARTICULARS);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);
    return retVal;
  }

  getBillingAccountMisuServices(
    request: PaginatedRequest,
    billingaccountId: number
  ): Observable<BaseResponse<BillingAccountMisuService[]>> {
    const m = this.getBillingAccountMisuServices.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS_MISU_SERVICES(billingaccountId));
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<BillingAccountMisuService[]>>(path, {
      params,
    });

    return retVal;
  }

  getBillingAccountMisuServicesSpreadsheet(request: PaginatedRequest, billingaccountId: number) {
    const m = this.getBillingAccountMisuServicesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS_MISU_SERVICES_SPREADSHEET(billingaccountId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  switchBillingAccountMisuServices(billingaccountId: number, misuId1: number, misuId2: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.changeVendorAccountRule.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS_MISU_SERVICES_SWITCH(billingaccountId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    const params = new HttpParams().set('misu1Id', misuId1).set('misu2Id', misuId2);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
