import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { BillingAccount } from 'src/app/models/vendor-account';
import { CopyBillingitemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-billingitems-wizard/activation-step/copybillingitems-due-date-info';
import { CopyBillingitemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-billingitems-wizard/init-step/init-step';
import * as fromCopyBillingitemsWizard from './reducer';
import { State } from './state';

export const selectCopyBillingitemsWizardState = createFeatureSelector<State>(fromCopyBillingitemsWizard.copyBillingitemsWizardFeatureKey);

export const selectInitStep = createSelector(selectCopyBillingitemsWizardState, (state: State): CopyBillingitemsInitStep => state.initStep);

export const selectDueDateStep = createSelector(
  selectCopyBillingitemsWizardState,
  (state: State): CopyBillingitemsDueDates => state.dueDatesStep
);

export const selectBillingaccountsStep = createSelector(
  selectCopyBillingitemsWizardState,
  (state: State): BillingAccount[] => state.billingaccountStep
);

export const selectCopyBillingitemsState = createSelector(
  selectCopyBillingitemsWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectCopyBillingitemsWizardState, (state: State): string => state.errorMessage);
