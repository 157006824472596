import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() status: string;
  @Input() message: string;
  @Input() tooltip: string;
  @Input() showButton = false;
  @Input() buttonKey: string;
  @Output() buttonClickEvEm = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {
    this.status = this.status.toLocaleLowerCase();
    if (this.status !== 'error' && this.status !== 'success' && this.status !== 'warning') {
      throw new Error(`${this.status} is invalid`);
    }
  }
}
