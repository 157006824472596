import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGlobalDateFilter from './reducer';
import { State } from './state';

export const globalDateFilter = createFeatureSelector<State>(fromGlobalDateFilter.globalDateFilterFeatureKey);

export const selectGlobalDateFilter = createSelector(globalDateFilter, (state: State): Date => state.globalDateFilter);

export const selectConfigurationGlobalDateFilter = createSelector(
  globalDateFilter,
  (state: State): Date => state.configurationGlobalDateFilter
);

export const selectIsConfigurationGlobalDateFilter = createSelector(globalDateFilter, (state: State): boolean => state.isConfiguration);
