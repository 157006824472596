export enum FormFieldTextKind {
  DEFAULT = 1,
  LABEL = 2,
}

export enum FormFieldTextSize {
  NORMAL = 1,
  MEDIUM = 2,
  HIGH = 3,
}

export enum FormFieldTextWeight {
  NORMAL = 500,
  BOLD = 700,
}
