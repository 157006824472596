<div class="question-container" cdkDragLockAxis="y" cdkDrag [cdkDragData]="question" [cdkDragDisabled]="!isEditMode">
  <div class="side-control-buttons" *ngIf="isEditMode">
    <!-- <div>
      <button
        mat-icon-button
        color="primary"
        (click)="$event.stopPropagation(); removeQuestion()"
        matTooltip="{{ 'question_remove' | translate }}"
        [matTooltipClass]="'fo-mat-tooltip'"
        [disabled]="!isEditMode"
      >
        <mat-icon fontSet="fas" fontIcon="fa-times" ></mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="questionActionsMenu"
        matTooltip="{{ 'question_move' | translate }}"
        [matTooltipClass]="'fo-mat-tooltip'"
        [disabled]="!isEditMode || questionsLength === 1"
      >
        <mat-icon fontSet="fas" fontIcon="fa-bars" ></mat-icon>
      </button>
    </div>
    <button
      mat-icon-button
      color="primary"
      (click)="$event.stopPropagation(); addQuestion()"
      matTooltip="{{ 'question_add_after' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
      [disabled]="!isEditMode"
    >
      <mat-icon fontSet="fas" fontIcon="fa-plus" ></mat-icon>
    </button> -->
    <button
      mat-icon-button
      color="primary"
      (click)="$event.stopPropagation(); moveUp()"
      matTooltip="{{ 'question_move_up' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
      [disabled]="questionIndex === 0"
    >
      <mat-icon fontSet="fas" fontIcon="fa-arrow-up"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      (click)="$event.stopPropagation(); moveDown()"
      matTooltip="{{ 'question_move_down' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
      [disabled]="questionIndex + 1 === questionsLength"
    >
      <mat-icon fontSet="fas" fontIcon="fa-arrow-down"></mat-icon>
    </button>
  </div>
  <div class="question-content particulars-form">
    <div class="particulars-row">
      <div class="particulars-col w-75">
        <div class="label-container">
          <span class="label truncate-text">{{ 'survey_question' | translate }}</span>
        </div>
        <span class="control-value">
          <mat-form-field
            appearance="outline"
            class="form-field-input reset-outline-hint"
            [ngClass]="[
              isDirtyEnabled && originalQuestion.questionaireQuestion != question.questionaireQuestion ? 'fo-dirty-behaviour' : '',
              isEditMode ? '' : 'view-mode',
              (question.questionaireQuestion == null || question.questionaireQuestion == '') && isSavePressed ? 'invalid-field' : ''
            ]"
            ><input matInput type="text" [(ngModel)]="question.questionaireQuestion" [disabled]="!isEditMode" />
          </mat-form-field>
        </span>
      </div>
      <div class="particulars-col w-25">
        <div class="label-container">
          <span class="label truncate-text">{{ 'question_type' | translate }}</span>
        </div>
        <span class="control-value">
          <mat-form-field
            appearance="outline"
            class="form-field-input reset-outline-hint"
            [ngClass]="[
              isDirtyEnabled && originalQuestion.questionaireTypeId != question.questionaireTypeId ? 'fo-dirty-behaviour' : '',
              isEditMode ? '' : 'view-mode',
              question.questionaireTypeId == null && isSavePressed ? 'invalid-field' : ''
            ]"
          >
            <mat-select
              disableOptionCentering
              panelClass="finoffice-matselect-panel"
              [(ngModel)]="question.questionaireTypeId"
              [disabled]="!isEditMode"
              (ngModelChange)="resetSelection(); changeOptionColumn()"
            >
              <mat-option class="form-field-option" *ngFor="let opt of questionOptions" [value]="castToInt(opt.key)">
                {{ opt.value | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
      <div class="particulars-col fit" *ngIf="isEditMode">
        <button
          class="remove-button"
          mat-icon-button
          color="error"
          (click)="$event.stopPropagation(); removeQuestion()"
          matTooltip="{{ 'question_remove' | translate }}"
          [matTooltipClass]="'fo-mat-tooltip'"
          [disabled]="!isEditMode"
        >
          <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
        </button>
      </div>
    </div>
    <div class="particulars-row no-label">
      <div class="particulars-col w-75">
        <div class="particulars-col">
          <div class="label-container">
            <span class="particulars-form-title">&nbsp;</span>
          </div>
        </div>
      </div>
      <div class="particulars-col w-25">
        <span class="control-value toggles">
          <mat-slide-toggle
            color="primary"
            [(ngModel)]="question.questionaireRequired"
            [ngClass]="
              isDirtyEnabled && originalQuestion.questionaireRequired != question.questionaireRequired ? 'fo-dirty-behaviour-checkbox' : ''
            "
            [disabled]="!isEditMode"
            >{{ 'question_required' | translate }}</mat-slide-toggle
          >
          <mat-slide-toggle
            *ngIf="isOptionsEnabled()"
            color="primary"
            [(ngModel)]="question.questionaireHasOtherOption"
            (ngModelChange)="dataSourceTable._updateChangeSubscription()"
            [ngClass]="
              isDirtyEnabled && originalQuestion.questionaireHasOtherOption != question.questionaireHasOtherOption
                ? 'fo-dirty-behaviour-checkbox'
                : ''
            "
            [disabled]="!isEditMode"
            >{{ 'question_has_other_option' | translate }}</mat-slide-toggle
          >
        </span>
      </div>
      <div style="width: 40px" *ngIf="isEditMode"><!-- Placeholder for alignment --></div>
    </div>
    <div
      *ngIf="isOptionsEnabled()"
      class="question-options-container"
      [ngClass]="
        isSavePressed &&
        ((!question.questionaireHasOtherOption && dataSourceTable.data.length < 2) ||
          (question.questionaireHasOtherOption && dataSourceTable.data.length < 1))
          ? 'invalid-field'
          : ''
      "
    >
      <mat-expansion-panel class="detail-expansion-panel" [expanded]="true" [hideToggle]="true" [disabled]="true">
        <!--
        (opened)="isPanelExpanded = true"
        (closed)="isPanelExpanded = false" -->
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span>{{ 'question_answer' | translate }}</span
            ><span *ngIf="!isPanelExpanded && question.questionaireQuestion != null">&nbsp;{{ question.questionaireQuestion }}</span>
          </mat-panel-title>
          <!-- <mat-panel-description>
          </mat-panel-description> -->
        </mat-expansion-panel-header>
        <div class="detail-expansion-panel-body">
          <div class="entity-table">
            <div class="table-wrapper">
              <div class="table-container">
                <table class="table table-stripes table-truncate-text no-header-table" mat-table [dataSource]="dataSourceTable">
                  <ng-container matColumnDef="questionSingleSelection">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row" class="radio-button-wrapper">
                      <mat-radio-button
                        class="table-radio-button"
                        disableRipple="true"
                        (change)="row.questionaireoptionSelected = $event.source.checked"
                        [checked]="row.questionaireoptionSelected"
                        [disabled]="!isEditMode"
                      ></mat-radio-button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef class="radio-button-wrapper">
                      <mat-radio-button class="table-radio-button" disableRipple="true" [disabled]="true"></mat-radio-button>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="questionMultiSelection">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox
                        (change)="row.questionaireoptionSelected = $event.checked"
                        [checked]="row.questionaireoptionSelected"
                        [disabled]="!isEditMode"
                      >
                      </mat-checkbox>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                      <mat-checkbox [disabled]="true"> </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="questionOption">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row; let index = index" [matTooltip]="row.questionaireoptionAnswer" showIfTruncated>
                      <ng-container *ngIf="!isEditMode">
                        {{ row.questionaireoptionAnswer }}
                      </ng-container>
                      <ng-container *ngIf="isEditMode">
                        <editable class="editable" (update)="updateField($event, index, 'value')" (resetField)="resetField(index, 'value')">
                          <ng-template viewMode>
                            <div
                              *ngIf="isValorized(row.questionaireoptionAnswer)"
                              [matTooltip]="row.questionaireoptionAnswer"
                              showIfTruncated
                              [ngClass]="isDirtyEnabled && getControl(index, 'value').dirty ? 'fo-dirty-behaviour' : ''"
                            >
                              {{ row.questionaireoptionAnswer }}
                            </div>
                            <div
                              *ngIf="!isValorized(row.questionaireoptionAnswer)"
                              class="invalid warning"
                              [ngClass]="getControl(index, 'value').dirty ? 'fo-dirty-behaviour' : ''"
                            >
                              {{ 'question_add_option_placeholder' | translate }}
                            </div>
                          </ng-template>
                          <ng-template editMode>
                            <mat-form-field floatLabel="never">
                              <input
                                type="text"
                                matInput
                                [formControl]="getControl(index, 'value')"
                                focusable
                                editableOnEnter
                                resetOnEsc
                                [ngClass]="isDirtyEnabled && getControl(index, 'value').dirty ? 'fo-dirty-behaviour' : ''"
                              />
                            </mat-form-field>
                          </ng-template>
                        </editable>
                      </ng-container>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>{{ 'question_other_option' | translate }}</td>
                  </ng-container>

                  <ng-container matColumnDef="questionOptionMoveUp">
                    <th mat-header-cell *matHeaderCellDef class="icon-header"></th>
                    <td mat-cell class="icon-cell" *matCellDef="let row; let index = index">
                      <mat-icon
                        *ngIf="isEditMode && dataSourceTable.data.length > 1 && index !== 0"
                        (click)="moveOptionUp(index)"
                        fontSet="fas"
                        fontIcon="fa-arrow-up"
                      ></mat-icon>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                  </ng-container>

                  <ng-container matColumnDef="questionOptionMoveDown">
                    <th mat-header-cell *matHeaderCellDef class="icon-header"></th>
                    <td mat-cell class="icon-cell" *matCellDef="let row; let index = index">
                      <mat-icon
                        *ngIf="isEditMode && dataSourceTable.data.length > 1 && index + 1 !== dataSourceTable.data.length"
                        (click)="moveOptionDown(index)"
                        fontSet="fas"
                        fontIcon="fa-arrow-down"
                      ></mat-icon>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                  </ng-container>

                  <ng-container matColumnDef="questionOptionRemove">
                    <th mat-header-cell *matHeaderCellDef class="icondelete-header"></th>
                    <td mat-cell class="icondelete-cell" *matCellDef="let row; let index = index">
                      <mat-icon *ngIf="isEditMode" (click)="deleteOption(row, index)" fontSet="fas" fontIcon="fa-times"></mat-icon>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
                  <tr
                    mat-footer-row
                    *matFooterRowDef="displayedColumnsTable"
                    [style.display]="question.questionaireHasOtherOption ? 'table-row' : 'none'"
                  ></tr>
                </table>
                <mat-toolbar class="table-footer-toolbar" *ngIf="dataSourceTable.data.length === 0 && !question.questionaireHasOtherOption">
                  <mat-toolbar-row class="no-data-message">
                    <span>{{ 'question_no_options' | translate }}</span>
                  </mat-toolbar-row>
                </mat-toolbar>
              </div>
            </div>
          </div>
          <ng-container *ngIf="isEditMode">
            <button
              mat-raised-button
              color="primary"
              (click)="$event.stopPropagation(); addOption()"
              style="margin: 0 0.5rem 0.5rem; width: 150px"
            >
              <span>{{ 'question_add_option' | translate }}</span>
            </button>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</div>

<!-- <mat-menu #questionActionsMenu="matMenu" xPosition="before" overlapTrigger="false">
  <ng-template matMenuContent>
    <button mat-menu-item color="primary" (click)="moveUp()" [disabled]="questionIndex === 0">
      <span>{{ 'question_move_up' | translate }}</span>
    </button>
    <button mat-menu-item color="primary" (click)="moveDown()" [disabled]="questionIndex + 1 === questionsLength">
      <span>{{ 'question_move_down' | translate }}</span>
    </button>
  </ng-template>
</mat-menu> -->

<mat-menu #optionActionsMenu="matMenu" xPosition="before" overlapTrigger="false">
  <ng-template matMenuContent let-index="index">
    <button mat-menu-item color="primary" (click)="moveOptionUp(index)" [disabled]="index === 0">
      <span>{{ 'question_move_up' | translate }}</span>
    </button>
    <button mat-menu-item color="primary" (click)="moveOptionDown(index)" [disabled]="index + 1 === dataSourceTable.data.length">
      <span>{{ 'question_move_down' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
