import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ActionRequest } from 'src/app/pages/requests/request-action';
import { RequestDataService } from 'src/app/services/request-data.service';
import * as RequestsActions from './actions';

@Injectable()
export class RequestsEffects {
  validateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestsActions.validateRequest),
      concatMap(({ requestId, requestKindId }) =>
        this.requestService.validateRequest(requestId, requestKindId).pipe(
          map((response) => {
            const { data } = response;
            if (data) {
              return RequestsActions.validateRequestResult({
                validationResult: data,
              });
            } else {
              throw Error('No Data');
            }
          }),
          catchError((error) => of(RequestsActions.validateRequestError({ error })))
        )
      )
    )
  );

  actionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestsActions.actionRequest),
      concatMap(({ requestId, action, remarks }) =>
        this.requestService.actionRequest(requestId, action, remarks).pipe(
          map((response) => {
            const { data } = response;
            if (data) {
              if (data.state) {
                switch (action) {
                  case ActionRequest.execute:
                    return RequestsActions.executionRequestResult({
                      executionStatus: data.state,
                    });
                  case ActionRequest.reject:
                    return RequestsActions.rejectionRequestResult({
                      rejectionStatus: data.state,
                    });
                  case ActionRequest.approve:
                    return RequestsActions.approveRequestResult({
                      approveStatus: data.state,
                    });
                  case ActionRequest.rejectApproval:
                    return RequestsActions.rejectionApprovalRequestResult({
                      rejectionApprovalStatus: data.state,
                    });
                }
              } else if (data.error) {
                return RequestsActions.actionRequestError({
                  error: data.error,
                });
              }
            } else {
              throw Error('No Data');
            }
          }),
          catchError((error) => {
            let errorMessage = '';
            if (error instanceof HttpErrorResponse) {
              errorMessage = error.error.errorKey;
            } else {
              errorMessage = error;
            }
            return of(
              RequestsActions.actionRequestError({
                error: errorMessage,
              })
            );
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private requestService: RequestDataService) {}
}
