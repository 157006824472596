import { Injectable } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { GenericFormField } from 'src/app/models/forms/form-field';
import { SectionNode } from 'src/app/models/generic-entity-field';
import { MessageNotifierService } from './message-notifier.service';

@Injectable({
  providedIn: 'root',
})
export class DecimalCheckerService {
  constructor(private messageNotifierService: MessageNotifierService) {}

  hasError(value, sectionChild: SectionNode): boolean {
    let hasErrors = false;
    if (value !== '') {
      const splitted = value.split('.');
      const nOfDecimalDigits = splitted[1] ? splitted[1].length : 0;
      if (nOfDecimalDigits > sectionChild.entityScale) {
        // Too much decimal digits
        this.messageNotifierService.showWarningMessage(_('decimal_error_decimal_digit'));
        hasErrors = true;
      }
      if (splitted[0].length + nOfDecimalDigits > sectionChild.entityPrecision) {
        // Number is bigger than valid precision
        this.messageNotifierService.showWarningMessage(_('decimal_error_precision'));
        hasErrors = true;
      }
    } else if (sectionChild.entityRequired) {
      // Invalid number
      this.messageNotifierService.showWarningMessage(_('decimal_error_not_number'));
      hasErrors = true;
    }
    return hasErrors;
  }

  hasErrorFormField(value, field: GenericFormField): boolean {
    let hasErrors = false;
    if (value !== '') {
      const splitted = value.split('.');
      const nOfDecimalDigits = splitted[1] ? splitted[1].length : 0;
      if (nOfDecimalDigits > field.formfieldEntityScale) {
        // Too much decimal digits
        this.messageNotifierService.showWarningMessage(_('decimal_error_decimal_digit'));
        hasErrors = true;
      }
      if (splitted[0].length + nOfDecimalDigits > field.formfieldEntityPrecision) {
        // Number is bigger than valid precision
        this.messageNotifierService.showWarningMessage(_('decimal_error_precision'));
        hasErrors = true;
      }
    } else if (field.formfieldRequired) {
      // Invalid number
      this.messageNotifierService.showWarningMessage(_('decimal_error_not_number'));
      hasErrors = true;
    }
    return hasErrors;
  }
}
