import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddBillingitemTopackageWizardStoreModule } from './add-billingitem-topackage-store';
import { AddBillingitemsWizardStoreModule } from './add-billingitems-wizard-store';
import { AddConsumersToAssetWizardStoreModule } from './add-consumersto-asset-wizard-store';
import { AddServiceTopackageWizardStoreModule } from './add-service-topackage-store';
import { AddServicesToBillingaccountsWizardStoreModule } from './add-services-to-billingaccounts-store';
import { AddSubscriptionsWizardStoreModule } from './add-subscriptions-store';
import { AllocateConsumerAddonsWizardStoreModule } from './allocate-consumer-addons-wizard-store';
import { AllocateConsumerWizardStoreModule } from './allocate-consumer-wizard-store';
import { CancelAssetWizardStoreModule } from './cancel-asset-wizard-store';
import { CancelConsumersFromAssetWizardStoreModule } from './cancel-consumers-from-asset';
import { CancelSharedAssetWizardStoreModule } from './cancel-shared-asset-wizard-store';
import { AllocationCancelWizardModule } from './cancel-wizard-store/cancel-wizard.module';
import { ChangeCostcentreAllocationsWizardStoreModule } from './change-costcentre-allocations-wizard-store';
import { CopyAssetWizardStoreModule } from './copy-asset-wizard-store';
import { CopyBillingitemsWizardStoreModule } from './copy-billingitems-wizard-store';
import { DeactivateBillingitemAssetWizardStoreModule } from './deactivate-billingitem-asset-wizard-store';
import { MoveAllDesksWizardStoreModule } from './move-all-desks-wizard-store';
import { MoveAssetBillingaccountWizardStoreModule } from './move-asset-billingaccount-wizard-store';
import { MoveAssetServicesWizardStoreModule } from './move-asset-services-wizard-store';
import { MoveAssetWizardStoreModule } from './move-asset-wizard-store';
import { MoveBillingitemsWizardStoreModule } from './move-billingitems-wizard-store';
import { MoveSharedAssetWizardStoreModule } from './move-shared-asset-wizard-store';
import { AllocationMoveWizardModule } from './move-wizard-store';
import { OrderMarketDataWizardStoreModule } from './order-market-data-wizard-store';
import { ReactivateAssetWizardStoreModule } from './reactivate-asset-wizard-store';
import { ReplaceBillingitemsWizardStoreModule } from './replace-billingitems-wizard-store';
import { ReplaceFeeditemsWizardStoreModule } from './replace-feeditems-wizard-store';
import { ReplaceProductServiceWizardStoreModule } from './replace-product-service-wizard-store';
import { AllocationSwapContractWizardModule } from './swap-contract-wizard-store/swap-contract-wizard.module';
import { TerminateMultiAddonsWizardStoreModule } from './terminate-multi-addonds-wizard-store';
import { TerminateMultiAssetWizardStoreModule } from './terminate-multi-asset-wizard-store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AllocationCancelWizardModule,
    AllocationMoveWizardModule,
    AllocateConsumerWizardStoreModule,
    AllocateConsumerAddonsWizardStoreModule,
    AllocationSwapContractWizardModule,
    CopyAssetWizardStoreModule,
    ReactivateAssetWizardStoreModule,
    DeactivateBillingitemAssetWizardStoreModule,
    AddBillingitemsWizardStoreModule,
    AddBillingitemTopackageWizardStoreModule,
    CopyBillingitemsWizardStoreModule,
    ReplaceBillingitemsWizardStoreModule,
    MoveBillingitemsWizardStoreModule,
    ReplaceFeeditemsWizardStoreModule,
    AddServiceTopackageWizardStoreModule,
    MoveAssetServicesWizardStoreModule,
    MoveAllDesksWizardStoreModule,
    ChangeCostcentreAllocationsWizardStoreModule,
    TerminateMultiAddonsWizardStoreModule,
    TerminateMultiAssetWizardStoreModule,
    AddServicesToBillingaccountsWizardStoreModule,
    AddConsumersToAssetWizardStoreModule,
    CancelConsumersFromAssetWizardStoreModule,
    MoveAssetWizardStoreModule,
    MoveSharedAssetWizardStoreModule,
    CancelAssetWizardStoreModule,
    CancelSharedAssetWizardStoreModule,
    ReplaceProductServiceWizardStoreModule,
    AddSubscriptionsWizardStoreModule,
    OrderMarketDataWizardStoreModule,
    MoveAssetBillingaccountWizardStoreModule,
  ],
})
export class AllocationWizardsStoreModule {}
