import { Action, createReducer, on } from '@ngrx/store';
import * as AddServicesToBillingaccountsWizardActions from './actions';
import { State, initialState } from './state';

export const addServicesToBillingaccountsWizardFeatureKey = 'addServicesToBillingaccountsWizard';

const addServicesToBillingaccountsWizardReducer = createReducer(
  initialState,

  on(AddServicesToBillingaccountsWizardActions.selectServices, (state, { servicesStep }) => ({
    ...state,
    servicesStep,
  })),
  on(AddServicesToBillingaccountsWizardActions.selectBillingaccounts, (state, { billingaccountsStep }) => ({
    ...state,
    billingaccountsStep,
  })),
  on(AddServicesToBillingaccountsWizardActions.exitAddServicesToBillingaccountsWizard, (state) => ({
    ...state,
    servicesStep: null,
    billingaccountsStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(AddServicesToBillingaccountsWizardActions.destroyWizard, (state) => ({
    ...state,
    servicesStep: null,
    billingaccountsStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(AddServicesToBillingaccountsWizardActions.validateAddServicesToBillingaccounts, (state, { request }) => ({
    ...state,
    request,
  })),
  on(AddServicesToBillingaccountsWizardActions.validateAddServicesToBillingaccountsResult, (state, { response }) => ({
    ...state,
    validationResponse: response,
  })),
  on(AddServicesToBillingaccountsWizardActions.validateAddServicesToBillingaccountsFail, (state, { errorMessage }) => ({
    ...state,
    validationErrorMessage: errorMessage,
  })),
  on(AddServicesToBillingaccountsWizardActions.createNewAddServicesToBillingaccounts, (state, { request }) => ({
    ...state,
    request,
  })),
  on(AddServicesToBillingaccountsWizardActions.createNewAddServicesToBillingaccountsSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(AddServicesToBillingaccountsWizardActions.createNewAddServicesToBillingaccountsFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return addServicesToBillingaccountsWizardReducer(state, action);
}
