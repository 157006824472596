import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { BaseResponse } from '../models/base-response';
import { PaginatedRequest } from '../models/paginated-request';
import { getTypeRequestParams, Type } from '../models/type';
import { TypeTreeNode } from '../models/type-node';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class TypeDataService {
  getTypes(request: PaginatedRequest): Observable<BaseResponse<Type[]>> {
    const m = this.getTypes.name;

    const path = environment.getEndpoint(ApiPath.Types.TYPES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Type[]>>(path, {
      params: getTypeRequestParams(request),
    });

    return retVal;
  }

  getTypeGridNodes(
    request: PaginatedRequest,
    typeKindId?: number,
    typeParentId?: number,
    nodeId?: number
  ): Observable<BaseResponse<TypeTreeNode[]>> {
    const m = this.getTypeNodes.name;

    const path = environment.getEndpoint(ApiPath.Types.TYPES_NODES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = getTypeRequestParams(request);
    params = typeKindId != null ? params.append('typeKindId', typeKindId.toString()) : params;
    params = typeParentId != null ? params.append('parentId', typeParentId.toString()) : params;
    params = nodeId != null ? params.append('filterIds', nodeId.toString()) : params;
    const retVal = this.http.get<BaseResponse<TypeTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getTypeNodes(
    request: PaginatedRequest,
    typeKindId?: number,
    typeParentId?: number,
    nodeId?: number
  ): Observable<BaseResponse<TypeTreeNode[]>> {
    const m = this.getTypeNodes.name;

    const path = environment.getEndpoint(ApiPath.Types.TYPES_KINDS_NODES(typeKindId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = getTypeRequestParams(request);
    // params = typeKindId != null ? params.append('typeKindId', typeKindId.toString()) : params;
    params = typeParentId != null ? params.append('parentId', typeParentId.toString()) : params;
    params = nodeId != null ? params.append('filterIds', nodeId.toString()) : params;
    const retVal = this.http.get<BaseResponse<TypeTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getTypeNodeChildsByTypeId(typeId: number): Observable<BaseResponse<TypeTreeNode[]>> {
    const m = this.getTypeNodes.name;

    const path = environment.getEndpoint(ApiPath.Types.TYPES_NODES_CHILDS(typeId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<TypeTreeNode[]>>(path);

    return retVal;
  }

  getTypesSpreadsheet(request: PaginatedRequest) {
    const m = this.getTypesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Types.TYPES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: getTypeRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  constructor(private http: HttpClient) {}
}
