<div class="atomic-sidenav-detail-title-bar">
  <div class="entity-property-header">
    <div class="title">
      <span>{{ detailsTitle }}</span>
    </div>
    <div class="control-button">
      <div class="remarks" *ngIf="detailsRemarks" [ngClass]="isRemarksValid ? 'valid' : isRemarksError ? 'error' : ''">
        <span>{{ detailsRemarks }}</span>
      </div>
      <button (click)="this.detailsCloseEvEm.emit()" mat-icon-button [disabled]="detailsIsEditing">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
