import { ActionResponse } from 'src/app/models/action-response';
import { BillingitemMoveRequest } from 'src/app/models/billingitem';
import { BillingAccount } from 'src/app/models/vendor-account';
import { MoveBillingitemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-billingitems-wizard/activation-step/movebillingitems-due-date-info';
import { MoveBillingitemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-billingitems-wizard/init-step/init-step';

export interface State {
  initStep: MoveBillingitemsInitStep;
  dueDatesStep: MoveBillingitemsDueDates;
  billingaccountStep: BillingAccount[];
  request: BillingitemMoveRequest;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  dueDatesStep: null,
  billingaccountStep: null,
  request: null,
  response: null,
  errorMessage: null,
};
