import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CopyBillingitemsWizardEffects } from './effects';
import * as fromCopyBillingitemsWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCopyBillingitemsWizard.copyBillingitemsWizardFeatureKey, fromCopyBillingitemsWizard.reducer),
    EffectsModule.forFeature([CopyBillingitemsWizardEffects]),
  ],
})
export class CopyBillingitemsWizardStoreModule {}
