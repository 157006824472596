import { CdkConnectedOverlay, ConnectedPosition, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Observable } from 'rxjs';
import { GenericPeriod } from 'src/app/models/generic-period';
import { CoreDataService } from 'src/app/services/core-data.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

export enum GenericPeriodHint {
  pastPeriod = -1,
  currentPeriod = 0,
  futurePeriod = 1,
}

@Component({
  selector: 'app-generic-period-scrolling',
  templateUrl: './generic-period-scrolling.component.html',
  styleUrls: ['./generic-period-scrolling.component.scss'],
})
export class GenericPeriodScrollingComponent extends FormatComponent implements OnInit {
  @ViewChild('filterSingleselectionOverlay') filterOverlay: CdkConnectedOverlay;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() getApiPathSubj: Observable<string>;
  @Input() showPeriodScroll: boolean;
  @Input() disablePeriodScroll: boolean;
  @Input() showPeriodCounter: boolean;
  @Input() itemNumberLabel = _('period_item_number');
  @Input() itemUsedLabel = _('period_item_used');
  @Input() itemAvailableLabel = _('period_item_available');
  @Output() selectedPeriodEvEm = new EventEmitter<GenericPeriod>();
  @Output() hasPeriodsDataEvEm = new EventEmitter<boolean>();

  getApiPath: string;
  periodsData: GenericPeriod[];
  currentPeriod: GenericPeriod;
  currentPeriodIndex: number;

  dataSourceTable: MatTableDataSource<GenericPeriod>;
  isOverlayOpen = false;
  overlayPositions: ConnectedPosition[] = [
    new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
  ];

  constructor(protected cdRef: ChangeDetectorRef, protected coreDataService: CoreDataService) {
    super();
  }

  ngOnInit() {
    if (this.getApiPathSubj) {
      this.subscribe(this.getApiPathSubj, (path) => {
        this.periodsData = null;
        this.currentPeriod = null;
        this.currentPeriodIndex = 0;
        if (path) {
          this.getApiPath = path;
          this.loadPeriodsData();
        }
      });
    }
  }

  loadPeriodsData() {
    this.dataSourceTable = new MatTableDataSource();
    this.subscribe(this.coreDataService.getGenericPeriods(this.getApiPath), ({ data }) => {
      this.periodsData = data;
      if (data) {
        this.dataSourceTable.data = data;
        this.currentPeriod = this.periodsData.find((gp) => gp.periodHintId === GenericPeriodHint.currentPeriod);
        if (!this.currentPeriod) {
          this.currentPeriod = this.periodsData[0];
        }
        this.currentPeriodIndex = this.periodsData.indexOf(this.currentPeriod);
      } else {
        this.currentPeriod = null;
      }
      this.selectedPeriodEvEm.emit(this.currentPeriod);
      this.hasPeriodsDataEvEm.emit(this.periodsData && this.periodsData.length > 0);
    });
  }

  previousPeriod() {
    this.currentPeriod = this.periodsData[++this.currentPeriodIndex];
    this.selectedPeriodEvEm.emit(this.currentPeriod);
  }

  nextPeriod() {
    this.currentPeriod = this.periodsData[--this.currentPeriodIndex];
    this.selectedPeriodEvEm.emit(this.currentPeriod);
  }

  isPreviousDisabled() {
    return this.disablePeriodScroll || this.currentPeriodIndex === this.periodsData.length - 1;
  }

  isNextDisabled() {
    return this.disablePeriodScroll || this.currentPeriodIndex === 0;
  }

  onPeriodSelected(row: GenericPeriod) {
    this.currentPeriod = row;
    this.currentPeriodIndex = this.periodsData.indexOf(this.currentPeriod);
    this.selectedPeriodEvEm.emit(this.currentPeriod);
  }

  openOverlay() {
    this.isOverlayOpen = true;
    this.cdRef.detectChanges();
    if (this.filterOverlay) {
      const maxHeight = window.window.innerHeight - this.filterOverlay.overlayRef.overlayElement.offsetTop - 32;
      this.filterOverlay.overlayRef.updateSize({ maxHeight: maxHeight });
    }
    if (this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSourceTable.paginator = this.paginator;
    }
  }

  closeOverlay() {
    this.isOverlayOpen = false;
  }

  get GenericPeriodHint() {
    return GenericPeriodHint;
  }
}
