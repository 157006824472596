<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <h1 mat-dialog-title>
    {{ 'modal_dialog_usage_for' | translate }}
    {{ data.labelName | translate }}
  </h1>
  <!-- <div class="control-button">
    <button (click)="onClose()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div> -->
</div>
<div mat-dialog-content>
  <!-- DIALOG CONTENT -->
  <div id="service-usage-table">
    <!-- SEARCH & PAGINATOR START-->
    <div class="table-search-paginate hidden-filters">
      <div class="table-search-paginate-left-wrapper">
        <mat-form-field appearance="outline" class="reset-outline-hint filter-field search">
          <input
            #searchInputField
            matInput
            [(ngModel)]="filterValue"
            (debounceFilterInput)="applyFilterTable($event)"
            [debounceTime]="600"
            maxlength="1500"
            placeholder="{{ 'service_usage_table_filter_placeholder' | translate }}"
          />
        </mat-form-field>
      </div>
      <div class="table-search-paginate-right">
        <button mat-button color="warn" (click)="resetFilter()" *ngIf="filterValue">
          {{ 'label_reset_filters' | translate }}
        </button>
        <ng-container *ngIf="!isEditing">
          <span style="margin-left: 0.5rem"></span>
          <button mat-raised-button color="primary" (click)="onClose()">
            <mat-icon>cancel</mat-icon>
            <span>{{ 'label_close' | translate }}</span>
          </button>
        </ng-container>
        <ng-container *ngIf="isEditing">
          <span style="margin-left: 0.5rem"></span>
          <button mat-raised-button color="primary" class="edit-mode-button" (click)="saveRows()" [disabled]="!controls || !controls.dirty">
            <mat-icon>save</mat-icon>
            <span>{{ 'label_save' | translate }}</span>
          </button>
          <span style="margin-left: 0.5rem"></span>
          <ng-container *ngIf="!enableConfirmCancel; else confirmCancel">
            <button mat-raised-button color="primary" class="edit-mode-button" (click)="onCancel($event)">
              <mat-icon>cancel</mat-icon>
              <span>{{ 'label_cancel' | translate }}</span>
            </button>
          </ng-container>
          <ng-template #confirmCancel>
            <button
              #confirmCancelButton
              class="cancel-confirm"
              mat-raised-button
              color="warn"
              (click)="onConfirmCancel($event)"
              (blur)="enableConfirmCancel = false"
            >
              <mat-icon>cancel</mat-icon>
              <span>{{ 'label_confirm_cancel' | translate }}</span>
            </button>
          </ng-template>
        </ng-container>
      </div>
    </div>
    <!-- SEARCH & PAGINATOR END-->
    <div class="container-fluid">
      <div class="table-wrapper table-entity-wrapper">
        <div class="table-container">
          <table
            class="table table-stripes table-truncate-text"
            mat-table
            [dataSource]="dataSourceTable"
            matSort
            #sortTable="matSort"
            [matSortActive]="gbGetInitialOrderBy()"
            [matSortDirection]="gbGetInitialSort()"
            matSortDisableClear
            matSortDirection="asc"
          >
            <!-- device address column -->
            <ng-container matColumnDef="usageAsset">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'service_usage_asset' | translate }}
              </th>
              <td mat-cell showIfTruncated [matTooltip]="row.usageAsset" showIfTruncated *matCellDef="let row">
                {{ row.usageAsset }}
              </td>
            </ng-container>
            <!-- starts column -->
            <ng-container matColumnDef="usageStarts">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'service_usage_starts' | translate }}
              </th>
              <td mat-cell showIfTruncated [matTooltip]="getDate(row.usageStarts)" showIfTruncated *matCellDef="let row">
                {{ getDate(row.usageStarts) }}
              </td>
            </ng-container>
            <!-- ends column -->
            <ng-container matColumnDef="usageEnds">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'service_usage_ends' | translate }}
              </th>
              <td mat-cell showIfTruncated [matTooltip]="getDate(row.usageEnds)" showIfTruncated *matCellDef="let row">
                {{ getDate(row.usageEnds) }}
              </td>
            </ng-container>
            <!-- units column -->
            <ng-container matColumnDef="usageUnits">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'service_usage_units' | translate }}
              </th>
              <td mat-cell showIfTruncated [matTooltip]="row.usageUnits" showIfTruncated *matCellDef="let row; let index = index">
                <ng-container *ngIf="!isEditing">{{ row.usageUnits }}</ng-container>
                <editable
                  class="editable"
                  *ngIf="isEditing"
                  (update)="updateField($event, index, 'usageUnits')"
                  (resetField)="resetField(index, 'usageUnits')"
                >
                  <ng-template viewMode>
                    <div *ngIf="isValorized(row.usageUnits)">{{ row.usageUnits }}</div>
                    <div *ngIf="!isValorized(row.usageUnits)" class="invalid">-</div>
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input type="number" matInput [formControl]="getControl(index, 'usageUnits')" focusable editableOnEnter resetOnEsc />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </td>
            </ng-container>
            <!-- price column -->
            <ng-container matColumnDef="usagePrice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'service_usage_price' | translate }}
              </th>
              <td
                mat-cell
                showIfTruncated
                [matTooltip]="getDecimal(row.usagePrice)"
                showIfTruncated
                *matCellDef="let row; let index = index"
              >
                <ng-container *ngIf="!isEditing">{{ getMoney(row.usagePrice) }}</ng-container>
                <editable
                  class="editable"
                  *ngIf="isEditing"
                  (update)="updateField($event, index, 'usagePrice')"
                  (resetField)="resetField(index, 'usagePrice')"
                >
                  <ng-template viewMode>
                    <div *ngIf="isValorized(row.usagePrice)">{{ getMoney(row.usagePrice) }}</div>
                    <div *ngIf="!isValorized(row.usagePrice)" class="invalid">-</div>
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field>
                      <input
                        type="text"
                        matInput
                        mat-input-decimal
                        [formControl]="getControl(index, 'usagePrice')"
                        focusable
                        editableOnEnter
                        resetOnEsc
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
          </table>
          <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
            <mat-toolbar-row *ngIf="isLoading" class="spinner">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-toolbar-row>
            <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
              <span>{{ 'generic_table_error_nodata' | translate }}</span>
            </mat-toolbar-row>
          </mat-toolbar>
          <mat-toolbar class="table-footer-toolbar">
            <mat-toolbar-row>
              <mat-paginator
                [disabled]="isLoading"
                #paginatorTable
                [pageSizeOptions]="pageSizeList"
                [length]="pageTotalElements"
                class="reset-paginator"
              ></mat-paginator>
            </mat-toolbar-row>
          </mat-toolbar>
        </div>
      </div>
    </div>
  </div>
  <!-- END DIALOG CONTENT -->
</div>
