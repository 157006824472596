import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GridsterModule } from 'angular-gridster2';
import { AngularResizeEventModule } from 'angular-resize-event';
import { AngularSplitModule } from 'angular-split';
import { MccColorPickerModule } from 'material-community-components/color-picker';
import { ColorPickerModule } from 'ngx-color-picker';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ToastrModule } from 'ngx-toastr';
import { CalendarModule } from 'primeng/calendar';
import { TokenService } from '../services/auth/token.service';
import { AlertComponent } from './alert/alert.component';
import { AngularTreeGridModule } from './angular-tree-grid/angular-tree-grid.module';
import { SidenavDetailsTitleBarComponent } from './base-components/atomic-sidenav-details/sidenav-details-title-bar/sidenav-details-title-bar.component';
import { DatepickerTodayHeaderComponent } from './datepicker-today-header/datepicker-today-header.component';
import { BlockCopyPasteDirective } from './directive/block-copy-paste.directive';
import { CurrencyMaskDirective } from './directive/currency-mask/currency-mask';
import { MatAutocompleteScrollToOptionDirective } from './directive/mat-autocomplete-scroll-to-option.directive';
import { MatInputAlphanumericDirective } from './directive/mat-input-alphanumeric.directive';
import { MatInputAutocompleteFromApiDirective } from './directive/mat-input-autocomplete-from-api.directive';
import { MatInputCommifiedDirective } from './directive/mat-input-commified.directive';
import { OnlydecimalDirective } from './directive/mat-input-decimal.directive';
import { LocalizedNumericInputDirective } from './directive/mat-input-localized.directive';
import { OnlynumberDirective } from './directive/mat-input-number.directive';
import { MiddleClickDirective } from './directive/middle-click.directive';
import { AngularResizableDirective } from './directive/ng-resizable.directive';
import { PrimeNgCalendarMaskDirective } from './directive/prime-ng-calendar-mask.directive';
import { RegexMaskDirective } from './directive/regex-mask';
import { RegexValidationDirective } from './directive/regex-validation';
import { ShowIfTruncatedDirective } from './directive/show-tooltip-if-trucated.directive';
import { TableFilterInputDirective } from './directive/table-filter';
import { ContentContainerDirective } from './dynamic-tab/content-container.directive';
import { TabContentComponent } from './dynamic-tab/tab-content.component';
import { EditModeDirective } from './editable/edit-mode.directive';
import { EditableOnButtonClickDirective } from './editable/edit-on-button-click.directive';
import { EditableOnEnterDirective } from './editable/edit-on-enter.directive';
import { EditableComponent } from './editable/editable.component';
import { FocusableDirective } from './editable/focusable.directive';
import { ResetOnEscDirective } from './editable/reset-on-esc.directive';
import { ViewModeDirective } from './editable/view-mode.directive';
import { FileModalComponent } from './files/file-modal/file-modal.component';
import { FileTabComponent } from './files/file-tab/file-tab.component';
import { FileTableComponent } from './files/file-table/file-table.component';
import { FileTreeViewComponent } from './files/file-tree/file-tree-view/file-tree-view.component';
import { FileTreeComponent } from './files/file-tree/file-tree.component';
import { MoveDirectorySelectDialogComponent } from './files/file-tree/move-directory-select-dialog/move-directory-select-dialog.component';
import { GenericAuditLogModalDialogComponent } from './generic-components/generic-audit-log-modal-dialog/generic-audit-log-modal-dialog.component';
import { GenericAuditLogComponent } from './generic-components/generic-audit-log/generic-audit-log.component';
import { GenericCommentChatComponent } from './generic-components/generic-comment-chat/generic-comment-chat.component';
import { GenericDemoComponent } from './generic-components/generic-demo/generic-demo.component';
import { GenericDynamicGridFiltersComponent } from './generic-components/generic-dynamic-grid-filters/generic-dynamic-grid-filters.component';
import { GenericDynamicGridSearchElementsComponent } from './generic-components/generic-dynamic-grid-search-elements/generic-dynamic-grid-search-elements.component';
import { GenericEntitiesRelationComponent } from './generic-components/generic-entities-relation/generic-entities-relation.component';
import { GenericEntityFileUploadDialogComponent } from './generic-components/generic-entity-file-upload-dialog/generic-entity-file-upload-dialog.component';
import { GenericEntityFileUploadComponent } from './generic-components/generic-entity-file-upload/generic-entity-file-upload.component';
import { GenericEntityFormModalDialogComponent } from './generic-components/generic-entity-form-modal-dialog/generic-entity-form-modal-dialog.component';
import { GenericEntityFormComponent } from './generic-components/generic-entity-form/generic-entity-form.component';
import { GenericEntityWizardModalDialogComponent } from './generic-components/generic-entity-wizard-modal-dialog/generic-entity-wizard-modal-dialog.component';
import { GenericEntityWizardFormStepComponent } from './generic-components/generic-entity-wizard/generic-entity-wizard-form-step/generic-entity-wizard-form-step.component';
import { GenericEntityWizardComponent } from './generic-components/generic-entity-wizard/generic-entity-wizard.component';
import { FormFieldConfigChipsComponent } from './generic-components/generic-form-configuration/form-fields-config-items/form-field-config-chips/form-field-config-chips.component';
import { FormFieldConfigEntityfieldComponent } from './generic-components/generic-form-configuration/form-fields-config-items/form-field-config-entityfield/form-field-config-entityfield.component';
import { FormFieldConfigInputfieldComponent } from './generic-components/generic-form-configuration/form-fields-config-items/form-field-config-inputfield/form-field-config-inputfield.component';
import { FormFieldConfigMailtemplateComponent } from './generic-components/generic-form-configuration/form-fields-config-items/form-field-config-mailtemplate/form-field-config-mailtemplate.component';
import { FormFieldConfigSearchComponent } from './generic-components/generic-form-configuration/form-fields-config-items/form-field-config-search/form-field-config-search.component';
import { FormFieldConfigSeparatorComponent } from './generic-components/generic-form-configuration/form-fields-config-items/form-field-config-separator/form-field-config-separator.component';
import { FormFieldConfigStepcontrolComponent } from './generic-components/generic-form-configuration/form-fields-config-items/form-field-config-stepcontrol/form-field-config-stepcontrol.component';
import { FormFieldConfigTextComponent } from './generic-components/generic-form-configuration/form-fields-config-items/form-field-config-text/form-field-config-text.component';
import { GenericFormConfigurationComponent } from './generic-components/generic-form-configuration/generic-form-configuration.component';
import { GenericFormForeignModalDialogComponent } from './generic-components/generic-form-foreign-modal-dialog/generic-form-foreign-modal-dialog.component';
import { GenericFormWizardModalDialogComponent } from './generic-components/generic-form-wizard-modal-dialog/generic-form-wizard-modal-dialog.component';
import { GenericFormWizardFinishStepComponent } from './generic-components/generic-form-wizard/generic-form-wizard-finish-step/generic-form-wizard-finish-step.component';
import { GenericFormWizardFormStepComponent } from './generic-components/generic-form-wizard/generic-form-wizard-form-step/generic-form-wizard-form-step.component';
import { GenericFormWizardValidationStepComponent } from './generic-components/generic-form-wizard/generic-form-wizard-validation-step/generic-form-wizard-validation-step.component';
import { GenericFormWizardComponent } from './generic-components/generic-form-wizard/generic-form-wizard.component';
import { FormFieldChipsComponent } from './generic-components/generic-form/form-field-items/form-field-chips/form-field-chips.component';
import { FormFieldInputfieldForeignComponent } from './generic-components/generic-form/form-field-items/form-field-inputfield-foreign/form-field-inputfield-foreign.component';
import { FormFieldInputfieldComponent } from './generic-components/generic-form/form-field-items/form-field-inputfield/form-field-inputfield.component';
import { FormFieldSearchComponent } from './generic-components/generic-form/form-field-items/form-field-search/form-field-search.component';
import { FormFieldSeparatorComponent } from './generic-components/generic-form/form-field-items/form-field-separator/form-field-separator.component';
import { FormFieldStepControlComponent } from './generic-components/generic-form/form-field-items/form-field-step-control/form-field-step-control.component';
import { FormFieldTextComponent } from './generic-components/generic-form/form-field-items/form-field-text/form-field-text.component';
import { GenericFormComponent } from './generic-components/generic-form/generic-form.component';
import { GenericGridFilterDatePanelComponent } from './generic-components/generic-grid-filter-date-panel/generic-grid-filter-date-panel.component';
import { GenericGridFilterDatetimePanelComponent } from './generic-components/generic-grid-filter-datetime-panel/generic-grid-filter-datetime-panel.component';
import { GenericGridFilterPanelComponent } from './generic-components/generic-grid-filter-panel/generic-grid-filter-panel.component';
import { GenericGridFilterSingleselectionPanelComponent } from './generic-components/generic-grid-filter-singleselection-panel/generic-grid-filter-singleselection-panel.component';
import { GenericGridFilterTreeSelectionPanelComponent } from './generic-components/generic-grid-filter-treeselection-panel/generic-grid-filter-treeselection-panel.component';
import { GenericGridViewWithDetailsAndRelatedComponent } from './generic-components/generic-grid-view-with-details-and-related/generic-grid-view-with-details-and-related.component';
import { GenericGridViewWithDetailsComponent } from './generic-components/generic-grid-view-with-details/generic-grid-view-with-details.component';
import { GenericGridViewComponent } from './generic-components/generic-grid-view/generic-grid-view.component';
import { GenericHistoryEmailComponent } from './generic-components/generic-history-email/generic-history-email.component';
import { GenericMassEditDialogComponent } from './generic-components/generic-mass-edit-dialog/generic-mass-edit-dialog.component';
import { GenericMultigridMultiselectionComponent } from './generic-components/generic-multigrid-multiselection/generic-multigrid-multiselection.component';
import { MultiselectionAssetAllocationsGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-asset-allocations-grid/multiselection-asset-allocations-grid.component';
import { MultiselectionBillingitemGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-billingitem-grid/multiselection-billingitem-grid.component';
import { MultiselectionContactsGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-contacts-grid/multiselection-contacts-grid.component';
import { MultiselectionCostcentreGridtreeComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-costcentre-gridtree/multiselection-costcentre-gridtree.component';
import { MultiselectionDeskGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-desk-grid/multiselection-desk-grid.component';
import { MultiselectionFeeditemGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-feeditem-grid/multiselection-feeditem-grid.component';
import { MultiselectionGenericGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-generic-grid/multiselection-generic-grid.component';
import { MultiselectionIndexesGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-indexes-grid/multiselection-indexes-grid.component';
import { MultiselectionInvoiceGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-invoice-grid/multiselection-invoice-grid.component';
import { MultiselectionLicenseGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-license-grid/multiselection-license-grid.component';
import { MultiselectionLocationGridtreeComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-location-gridtree/multiselection-location-gridtree.component';
import { MultiselectionOrganisationGridtreeComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-organisation-gridtree/multiselection-organisation-gridtree.component';
import { MultiselectionPlatformGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-platform-grid/multiselection-platform-grid.component';
import { MultiselectionProductGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-product-grid/multiselection-product-grid.component';
import { MultiselectionProductfamilyGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-productfamily-grid/multiselection-productfamily-grid.component';
import { MultiselectionServicesGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-services-grid/multiselection-services-grid.component';
import { MultiselectionStaticOptionsGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-static-options-grid/multiselection-static-options-grid.component';
import { MultiselectionTenantGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-tenant-grid/multiselection-tenant-grid.component';
import { MultiselectionTenantLedgerGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-tenant-ledger-grid/multiselection-tenant-ledger-grid.component';
import { MultiselectionTypesGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-types-grid/multiselection-types-grid.component';
import { MultiselectionUserGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-user-grid/multiselection-user-grid.component';
import { MultiselectionUserLicenseGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-userlicense-grid/multiselection-userlicense-grid.component';
import { MultiselectionVendorGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-vendor-grid/multiselection-vendor-grid.component';
import { MultiselectionVendoraccountGridComponent } from './generic-components/generic-multigrid-multiselection/grids/multiselection-vendoraccount-grid/multiselection-vendoraccount-grid.component';
import { GenericNoteComponent } from './generic-components/generic-note/generic-note.component';
import { GenericPeriodScrollingComponent } from './generic-components/generic-period-scrolling/generic-period-scrolling.component';
import { GenericSendEmailDialogComponent } from './generic-components/generic-send-email-dialog/generic-send-email-dialog.component';
import { GenericTreeViewComponent } from './generic-components/generic-tree-view/generic-tree-view.component';
import { SimpleDialogComponent } from './generic-components/simple-dialog/simple-dialog.component';
import { GlobalDateGridFilterPanelComponent } from './global-date-grid-filter-panel/global-date-grid-filter-panel.component';
import { CronDayTabComponent } from './inputs/cron-editor-input/cron-day-tab/cron-day-tab.component';
import { CronEditorInputComponent } from './inputs/cron-editor-input/cron-editor-input.component';
import { CronHoursTabComponent } from './inputs/cron-editor-input/cron-hours-tab/cron-hours-tab.component';
import { CronMinutesTabComponent } from './inputs/cron-editor-input/cron-minutes-tab/cron-minutes-tab.component';
import { CronMonthTabComponent } from './inputs/cron-editor-input/cron-month-tab/cron-month-tab.component';
import { CronSecondsTabComponent } from './inputs/cron-editor-input/cron-seconds-tab/cron-seconds-tab.component';
import { CronYearTabComponent } from './inputs/cron-editor-input/cron-year-tab/cron-year-tab.component';
import { EmailDropperComponent } from './inputs/email-dropper/email-dropper.component';
import { FileDragDropDirective } from './inputs/file-dropper/file-drag-drop.directive';
import { FileDropperComponent } from './inputs/file-dropper/file-dropper.component';
import { QuestionaireConfigPanelComponent } from './inputs/questionaire-config-panel/questionaire-config-panel.component';
import { StepValueControlComponent } from './inputs/step-value-control/step-value-control.component';
import { TranslationsInputDialogControlComponent } from './inputs/translations-input-dialog-control/translations-input-dialog-control.component';
import { TranslationsModalDialogComponent } from './inputs/translations-modal-dialog/translations-modal-dialog.component';
import { NgImageFullscreenViewComponent } from './ng-image-fullscreen-view/ng-image-fullscreen-view.component';
import { SliderCustomImageComponent } from './ng-image-fullscreen-view/slide-custom-image/slider-custom-image.component';
import { MatPaginatorTranslations } from './paginator-intl/paginator-intl';
import { FileSizePipe } from './pipes/ngx-filesize';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { TrimPipe } from './pipes/trim-pipe';
import { FinofficeErrorToastComponent } from './toast/finoffice-error-toast/finoffice-error-toast.component';
import { FinofficeInfoToastComponent } from './toast/finoffice-info-toast/finoffice-info-toast.component';
import { FinofficeSuccessToastComponent } from './toast/finoffice-success-toast/finoffice-success-toast.component';
import { FinofficeWarningToastComponent } from './toast/finoffice-warning-toast/finoffice-warning-toast.component';
import { CustomTranslateLoader } from './translate-text/translate-loader';
import { WizardCommunicationStepComponent } from './wizard-common-steps/wizard-communication-step/wizard-communication-step.component';

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  // Material modules
  MatAutocompleteModule,
  MatExpansionModule,
  MatSelectModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatDialogModule,
  MatMenuModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatRippleModule,
  DragDropModule,
  MatCardModule,
  MatStepperModule,
  MatRadioModule,
  MatToolbarModule,
  MatTooltipModule,
  MatDividerModule,
  MatSelectModule,
  MatDatepickerModule,
  MatListModule,
  MatTreeModule,
  MatProgressBarModule,
  MatSlideToggleModule,
  MatChipsModule,
  // MatMomentDateModule,
  MatTabsModule,
  OverlayModule,
  MatBadgeModule,
  CalendarModule,
  // Others
  NgxMatDatetimePickerModule,
  NgxFileDropModule,
  AngularTreeGridModule,
  MccColorPickerModule,
  ColorPickerModule,
  CKEditorModule,
  AngularResizeEventModule,
  YouTubePlayerModule,
  ClipboardModule,
];

// const DATEPICKER_FORMATS = {
//   parse: {
//     dateInput: 'L',
//   },
//   display: {
//     dateInput: 'L',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };

@NgModule({
  declarations: [
    // Shared generic components
    GenericDemoComponent,
    GenericTreeViewComponent,
    GenericGridViewComponent,
    GenericGridViewWithDetailsComponent,
    GenericEntitiesRelationComponent,
    SimpleDialogComponent,
    GenericEntityFileUploadComponent,
    GenericPeriodScrollingComponent,
    GenericAuditLogComponent,
    GenericFormComponent,
    FormFieldTextComponent,
    FormFieldSeparatorComponent,
    FormFieldSearchComponent,
    FormFieldInputfieldComponent,
    FormFieldStepControlComponent,
    FormFieldChipsComponent,
    GenericFormConfigurationComponent,
    FormFieldConfigSeparatorComponent,
    FormFieldConfigTextComponent,
    FormFieldConfigSearchComponent,
    FormFieldConfigEntityfieldComponent,
    FormFieldConfigInputfieldComponent,
    FormFieldConfigStepcontrolComponent,
    FormFieldConfigChipsComponent,
    FormFieldConfigMailtemplateComponent,
    GenericEntityFormComponent,
    GenericGridFilterPanelComponent,
    GenericGridFilterTreeSelectionPanelComponent,
    GenericGridFilterSingleselectionPanelComponent,
    GenericGridFilterDatePanelComponent,
    GenericGridFilterDatetimePanelComponent,
    GenericGridViewWithDetailsAndRelatedComponent,
    GenericMultigridMultiselectionComponent,
    MultiselectionUserGridComponent,
    MultiselectionDeskGridComponent,
    GenericNoteComponent,
    GenericCommentChatComponent,
    // Shared components
    AlertComponent,
    EditableComponent,
    SidenavDetailsTitleBarComponent,
    TabContentComponent,
    EmailDropperComponent,
    FileDropperComponent,
    StepValueControlComponent,
    DatepickerTodayHeaderComponent,
    GlobalDateGridFilterPanelComponent,
    // Shared directives
    TableFilterInputDirective,
    RegexValidationDirective,
    CurrencyMaskDirective,
    ViewModeDirective,
    EditModeDirective,
    FocusableDirective,
    EditableOnEnterDirective,
    EditableOnButtonClickDirective,
    ResetOnEscDirective,
    MatInputCommifiedDirective,
    ShowIfTruncatedDirective,
    ContentContainerDirective,
    FileDragDropDirective,
    MatInputAutocompleteFromApiDirective,
    RegexMaskDirective,
    OnlynumberDirective,
    OnlydecimalDirective,
    MatInputAlphanumericDirective,
    MatAutocompleteScrollToOptionDirective,
    MiddleClickDirective,
    BlockCopyPasteDirective,
    LocalizedNumericInputDirective,
    PrimeNgCalendarMaskDirective,
    // Shared Pipe
    FileSizePipe,
    TrimPipe,
    OrdinalPipe,
    TranslationsInputDialogControlComponent,
    TranslationsModalDialogComponent,
    MultiselectionTypesGridComponent,
    MultiselectionPlatformGridComponent,
    MultiselectionVendoraccountGridComponent,
    MultiselectionVendorGridComponent,
    MultiselectionServicesGridComponent,
    MultiselectionBillingitemGridComponent,
    MultiselectionLicenseGridComponent,
    MultiselectionLocationGridtreeComponent,
    MultiselectionOrganisationGridtreeComponent,
    MultiselectionCostcentreGridtreeComponent,
    MultiselectionGenericGridComponent,
    MultiselectionUserLicenseGridComponent,
    FormFieldInputfieldForeignComponent,
    GenericEntityWizardComponent,
    GenericEntityWizardFormStepComponent,
    CronEditorInputComponent,
    CronSecondsTabComponent,
    CronMinutesTabComponent,
    CronHoursTabComponent,
    CronMonthTabComponent,
    CronYearTabComponent,
    CronDayTabComponent,
    GenericEntityWizardModalDialogComponent,
    GenericFormWizardComponent,
    GenericFormWizardFormStepComponent,
    GenericFormWizardValidationStepComponent,
    GenericFormWizardFinishStepComponent,
    GenericFormWizardModalDialogComponent,
    GenericEntityFormModalDialogComponent,
    MultiselectionStaticOptionsGridComponent,
    MultiselectionProductGridComponent,
    MultiselectionFeeditemGridComponent,
    WizardCommunicationStepComponent,
    GenericFormForeignModalDialogComponent,
    QuestionaireConfigPanelComponent,
    MoveDirectorySelectDialogComponent,
    GenericSendEmailDialogComponent,
    MultiselectionContactsGridComponent,
    GenericHistoryEmailComponent,
    //Toastr
    FinofficeSuccessToastComponent,
    FinofficeErrorToastComponent,
    FinofficeInfoToastComponent,
    FinofficeWarningToastComponent,
    NgImageFullscreenViewComponent,
    SliderCustomImageComponent,
    MultiselectionAssetAllocationsGridComponent,
    GenericAuditLogModalDialogComponent,
    GenericEntityFileUploadDialogComponent,
    MultiselectionIndexesGridComponent,
    GenericMassEditDialogComponent,
    GenericDynamicGridFiltersComponent,
    GenericDynamicGridSearchElementsComponent,
    AngularResizableDirective,
    //Files
    FileTabComponent,
    FileTreeComponent,
    FileTableComponent,
    FileModalComponent,
    FileTreeViewComponent,
    MultiselectionTenantGridComponent,
    MultiselectionTenantLedgerGridComponent,
    MultiselectionProductfamilyGridComponent,
    MultiselectionInvoiceGridComponent,
  ],
  imports: [
    GridsterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient, TokenService],
      },
      isolate: false,
    }),
    AngularSplitModule.forRoot(),
    // Notifications
    ToastrModule.forRoot({
      timeOut: 5000,
      // disableTimeOut: true,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
      countDuplicates: true,
      includeTitleDuplicates: true,
      newestOnTop: true,
      maxOpened: 2,
      autoDismiss: true,
    }),
    NgCircleProgressModule.forRoot({}),
    ...MODULES,
  ],
  exports: [
    TranslateModule,
    ToastrModule,
    AngularSplitModule,
    NgCircleProgressModule,
    // Shared Generic components
    GenericDemoComponent,
    GenericTreeViewComponent,
    GenericGridViewComponent,
    GenericGridViewWithDetailsComponent,
    GenericEntitiesRelationComponent,
    GenericEntityFileUploadComponent,
    SimpleDialogComponent,
    GenericPeriodScrollingComponent,
    GenericAuditLogComponent,
    GenericFormComponent,
    FormFieldTextComponent,
    FormFieldSeparatorComponent,
    FormFieldSearchComponent,
    FormFieldInputfieldComponent,
    FormFieldStepControlComponent,
    GenericFormConfigurationComponent,
    FormFieldConfigSeparatorComponent,
    FormFieldConfigTextComponent,
    FormFieldConfigSearchComponent,
    FormFieldConfigEntityfieldComponent,
    FormFieldConfigInputfieldComponent,
    FormFieldConfigStepcontrolComponent,
    GenericEntityFormComponent,
    GenericDynamicGridSearchElementsComponent,
    GenericDynamicGridFiltersComponent,
    GenericGridFilterPanelComponent,
    GenericGridFilterTreeSelectionPanelComponent,
    GenericGridFilterSingleselectionPanelComponent,
    GenericGridFilterDatePanelComponent,
    GenericGridFilterDatetimePanelComponent,
    GenericGridViewWithDetailsAndRelatedComponent,
    GenericMultigridMultiselectionComponent,
    GenericEntityWizardComponent,
    GenericEntityWizardFormStepComponent,
    GenericFormWizardComponent,
    GenericNoteComponent,
    GenericCommentChatComponent,
    WizardCommunicationStepComponent,
    QuestionaireConfigPanelComponent,
    GenericHistoryEmailComponent,
    // Shared components
    AlertComponent,
    EditableComponent,
    SidenavDetailsTitleBarComponent,
    TabContentComponent,
    EmailDropperComponent,
    FileDropperComponent,
    StepValueControlComponent,
    DatepickerTodayHeaderComponent,
    CronEditorInputComponent,
    NgImageFullscreenViewComponent,
    GlobalDateGridFilterPanelComponent,
    TranslationsInputDialogControlComponent,
    // Shared directives
    TableFilterInputDirective,
    RegexValidationDirective,
    CurrencyMaskDirective,
    ViewModeDirective,
    EditModeDirective,
    FocusableDirective,
    EditableOnEnterDirective,
    EditableOnButtonClickDirective,
    MatInputCommifiedDirective,
    ShowIfTruncatedDirective,
    ResetOnEscDirective,
    FileDragDropDirective,
    MatInputAutocompleteFromApiDirective,
    RegexMaskDirective,
    OnlynumberDirective,
    OnlydecimalDirective,
    MatInputAlphanumericDirective,
    MatAutocompleteScrollToOptionDirective,
    MiddleClickDirective,
    BlockCopyPasteDirective,
    LocalizedNumericInputDirective,
    PrimeNgCalendarMaskDirective,
    AngularResizableDirective,
    //Files
    FileTabComponent,
    FileTreeComponent,
    FileTableComponent,
    FileModalComponent,
    FileTreeViewComponent,
    // Shared Pipe
    OrdinalPipe,
    TrimPipe,
    FileSizePipe,
    // Re-export these modules to prevent repeated imports
    // (see: https://angular.io/guide/ngmodule#re-exporting-other-modules)
    ...MODULES,
  ],
  providers: [
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'outline' } },
    { provide: MatPaginatorIntl, useClass: MatPaginatorTranslations },
  ],
  // providers: [
  //   { provide: MAT_DATE_LOCALE, useValue: 'en-gb' },
  //   { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  //   { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMATS },
  // ],
})
export class SharedModule {}
