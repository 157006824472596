import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiPath } from 'src/app/configs/api-paths';
import { DynamicFlatNode, GenericTreeNode } from 'src/app/models/tree-view-flat-node';
import { CoreDataService } from 'src/app/services/core-data.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { FileTreeViewDatabase, FileTreeViewDatasource } from '../file-tree-view/file-tree-view-data-source';

export interface MoveDirectorySelectDialogData {
  fileTabType: string;
  movedFolder: string;
  movedFolderId: number;
  parentFolderId: number;
  disableChildNodes: boolean;
}

@Component({
  selector: 'app-move-directory-select-dialog',
  templateUrl: './move-directory-select-dialog.component.html',
  styleUrls: ['./move-directory-select-dialog.component.scss'],
  providers: [FileTreeViewDatabase],
})
export class MoveDirectorySelectDialogComponent extends FormatComponent implements OnInit {
  isLoading = false;
  noEntityData = false;
  treeControl: FlatTreeControl<DynamicFlatNode>;
  dataSource: FileTreeViewDatasource;
  selectedNode: DynamicFlatNode;
  displayedColumnsTable = ['entityName'];

  rootNode: GenericTreeNode[] = [
    {
      entityCount: 0,
      entityId: -1,
      entityName: this.translate.instant('label_root'),
      entityKind: 'root',
      entityDescription: 'label_root_description',
      entityInfo: '',
      entityUpdate: false,
      entityDelete: false,
      entityChilds: 1,
      entityParentId: null,
      entityActive: true,
    },
  ];
  constructor(
    private database: FileTreeViewDatabase,
    private coreDataService: CoreDataService,
    public dialogRef: MatDialogRef<MoveDirectorySelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MoveDirectorySelectDialogData
  ) {
    super();
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
  }

  ngOnInit() {
    this.dataSource = new FileTreeViewDatasource(this.treeControl, this.database, this.coreDataService);
    this.dataSource.baseApiPath = ApiPath.Files.FILES_TREE(this.data.fileTabType);
    this.rootNode[0].entityName = this.translate.instant('label_root');
    this.dataSource.data = this.database.initialData(this.rootNode);
    this.noEntityData = false;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSelect() {
    this.dialogRef.close(this.selectedNode.item);
  }

  onEntitySelected(row: DynamicFlatNode) {
    this.selectedNode = row;
  }

  isEntitySelected() {
    return this.selectedNode && this.selectedNode.item.entityName != null && this.selectedNode.item.entityName !== '';
  }

  fetchData() {
    this.isLoading = true;
    this.subscribe(
      this.coreDataService.getGenericTreeNodeChilds(ApiPath.Files.FILES_TREE(this.data.fileTabType), null, null, null),
      (response) => {
        if (response.data) {
          this.dataSource.data = this.database.initialData(response.data);
        } else {
          this.noEntityData = true;
        }
        this.isLoading = false;
      }
    );
  }

  disabledRow(row: DynamicFlatNode) {
    if (this.data.movedFolderId === row.item.entityId) return true;
    if (this.data.parentFolderId === row.item.entityId) return true;
    return false;
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (x: number, nodeData: DynamicFlatNode) => nodeData.expandable;
}
