import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { LicenseAssetPeriodsAllocationWizardRequest } from '../models/asset-allocation';
import { BaseResponse } from '../models/base-response';
import { EntityWizardResponse } from '../models/forms/entity-wizard';
import { Email, EmailRequest } from '../models/mailtemplate/mail-template-preview';
import {
  Request,
  RequestCurrentFlowStep,
  RequestFlowStep,
  RequestProductReplacementRequest,
  RequestProductRequest,
  RequestSelectedProduct,
  RequestValidationResponse,
  getRequestRequestParams,
} from '../models/request';
import { RequestMarketDataOrder } from '../models/request-market-data-order';
import { ActionRequest } from '../pages/requests/request-action';
import { OrderMarketDataRequest } from '../shared-components/allocation-wizard-modal-dialogs/order-market-data-wizard/summary-step/order-market-data-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class RequestDataService {
  getRequests(request: PaginatedRequest, linkable: boolean): Observable<BaseResponse<Request[]>> {
    const m = this.getRequests.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUESTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Request[]>>(path, {
      params: getRequestRequestParams(request, linkable),
    });

    return retVal;
  }
  getLastRequests(request: PaginatedRequest, dashboardId: number): Observable<BaseResponse<Request[]>> {
    const m = this.getRequests.name;

    const path = environment.getEndpoint(ApiPath.Dashboards.DASHBOARDS_LAST_REQUESTS(dashboardId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Request[]>>(path, {
      params: getRequestRequestParams(request, false),
    });

    return retVal;
  }
  getHistoryRequests(request: PaginatedRequest, dashboardId: number): Observable<BaseResponse<Request[]>> {
    const m = this.getRequests.name;

    const path = environment.getEndpoint(ApiPath.Dashboards.DASHBOARDS_HISTORY_REQUESTS(dashboardId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Request[]>>(path, {
      params: getRequestRequestParams(request, false),
    });

    return retVal;
  }

  confirmRequest(requestId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.confirmRequest.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_CONFIRMED(requestId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  getRequestsSpreadsheet(request: PaginatedRequest, linkable: boolean) {
    const m = this.getRequestsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUESTS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: getRequestRequestParams(request, linkable),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getRequestProductServices(requestId: number): Observable<BaseResponse<RequestSelectedProduct[]>> {
    const m = this.getRequestProductServices.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_LICENSE_SERVICES(requestId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<RequestSelectedProduct[]>>(path);

    return retVal;
  }

  setRequestSyncState(requestId: number, stateId): Observable<BaseResponse<ActionResponse>> {
    const m = this.setRequestSyncState.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_SYNC_STATE(requestId));
    LogService.info(this, m, LogService.PUT + path, null);

    const params = new HttpParams().set('stateId', stateId);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getRequestSendEmail(requestId: number, mailframeId: number): Observable<BaseResponse<Email[]>> {
    const m = this.getRequestProductServices.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_SEND_EMAIL(requestId));
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams().set('mailframeId', mailframeId);
    const retVal = this.http.get<BaseResponse<Email[]>>(path, { params });

    return retVal;
  }

  sendRequestSendEmail(requestId: number, request: EmailRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.getRequestProductServices.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_SEND_EMAIL(requestId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  closeRequest(requestId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.closeRequest.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_CLOSE(requestId));
    LogService.info(this, m, LogService.POST + path, null);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  reopenRequest(requestId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.reopenRequest.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_REOPEN(requestId));
    LogService.info(this, m, LogService.POST + path, null);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  updateRequestProductServices(requestId: number, request: RequestProductRequest[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.updateRequestProductServices.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_LICENSE_SERVICES(requestId));
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  replaceRequestProductLicense(requestId: number, request: RequestProductReplacementRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.replaceRequestProductLicense.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_LICENSE_SERVICES_REPLACEMENT(requestId));
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  validateRequest(requestId: number, requestKindId?: number): Observable<BaseResponse<RequestValidationResponse>> {
    const m = this.validateRequest.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_VALIDATION(requestId));
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams();
    if (requestKindId != null) {
      params = params.append('requestKindId', requestKindId);
    }
    const retVal = this.http.get<BaseResponse<RequestValidationResponse>>(path, { params });

    return retVal;
  }

  actionRequest(requestId: number, action: ActionRequest, remarks: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.actionRequest.name;

    let path: string;
    switch (action) {
      case ActionRequest.execute:
        path = environment.getEndpoint(ApiPath.Requests.REQUEST_EXECUTION(requestId));
        break;
      case ActionRequest.reject:
        path = environment.getEndpoint(ApiPath.Requests.REQUEST_REJECTION(requestId));
        break;
      case ActionRequest.approve:
        path = environment.getEndpoint(ApiPath.Requests.REQUEST_CURRENT_FLOW_STEPS_APPROVAL(requestId));
        break;
      case ActionRequest.rejectApproval:
        path = environment.getEndpoint(ApiPath.Requests.REQUEST_CURRENT_FLOW_STEPS_REJECTION(requestId));
        break;
    }
    LogService.info(this, m, LogService.POST + path, null);
    let retVal;
    if (action === ActionRequest.execute || action === ActionRequest.reject) {
      retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);
    }
    if (action === ActionRequest.approve || action === ActionRequest.rejectApproval) {
      let params = new HttpParams().set('remarks', remarks);
      retVal = this.http.put<BaseResponse<ActionResponse>>(path, null, { params });
    }

    return retVal;
  }

  executeCancelSharedAssetRequest(
    requestId: number,
    assetId: number,
    request: LicenseAssetPeriodsAllocationWizardRequest
  ): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.executeCancelSharedAssetRequest.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_SHARED_ASSET_EXECUTION(requestId, assetId));
    LogService.info(this, m, LogService.POST + path, null);

    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);

    return retVal;
  }

  validateMarketDataOrder(request: OrderMarketDataRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.validateMarketDataOrder.name;
    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_MARKET_DATA_ORDER_VALIDATION);
    LogService.info(this, m, LogService.POST + path, null);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  createMarketDataOrder(request: OrderMarketDataRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.createMarketDataOrder.name;
    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_MARKET_DATA_ORDER);
    LogService.info(this, m, LogService.POST + path, null);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getRequestMarketDataOrder(requestId: number): Observable<BaseResponse<RequestMarketDataOrder[]>> {
    const m = this.getRequestMarketDataOrder.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_MARKET_DATA_ORDER_DATA(requestId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<RequestMarketDataOrder[]>>(path);

    return retVal;
  }

  putRequestMarketDataOrder(requestId: number, request: OrderMarketDataRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.createMarketDataOrder.name;
    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_MARKET_DATA_ORDER_DATA(requestId));
    LogService.info(this, m, LogService.PUT + path, null);

    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getRequestFlowSteps(requestId: number): Observable<BaseResponse<RequestFlowStep[]>> {
    const m = this.getRequestFlowSteps.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_FLOW_STEPS(requestId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<RequestFlowStep[]>>(path);

    return retVal;
  }

  getRequestCurrentFlowSteps(requestId: number): Observable<BaseResponse<RequestCurrentFlowStep>> {
    const m = this.getRequestCurrentFlowSteps.name;

    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_CURRENT_FLOW_STEPS(requestId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<RequestCurrentFlowStep>>(path);

    return retVal;
  }

  approveRequestCurrentFlowSteps(requestId: number, remarks: string): Observable<BaseResponse<RequestCurrentFlowStep>> {
    const m = this.getRequestCurrentFlowSteps.name;

    const params = new HttpParams().set('remarks', remarks);
    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_CURRENT_FLOW_STEPS_APPROVAL(requestId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<RequestCurrentFlowStep>>(path);

    return retVal;
  }

  rejectRequestCurrentFlowSteps(requestId: number, remarks: string): Observable<BaseResponse<RequestCurrentFlowStep>> {
    const m = this.getRequestCurrentFlowSteps.name;

    const params = new HttpParams().set('remarks', remarks);
    const path = environment.getEndpoint(ApiPath.Requests.REQUEST_CURRENT_FLOW_STEPS_REJECTION(requestId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<RequestCurrentFlowStep>>(path);

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
