import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthStoreAction, RootStoreState } from 'src/app/root-store';
import { BaseComponent } from 'src/app/shared/base-components/base-component';

@Component({
  selector: 'app-viewasuser-auth',
  templateUrl: './viewasuser-auth.component.html',
})
export class ViewAsUserAuthComponent extends BaseComponent implements AfterViewInit {
  token: string;
  isAuthenticated: any;

  constructor(private store: Store<RootStoreState.State>, private activatedRoute: ActivatedRoute, private router: Router) {
    super();
    // subscribe to router event
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.token = params.token;
    });
  }
  ngAfterViewInit(): void {
    // this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticated)), (isAuth) => {
    //   this.isAuthenticated = isAuth;
    //   if (!this.isAuthenticated && this.token) {
    //     LogService.debug(this, this.ngAfterViewInit.name, 'Token', this.token);
    //     this.store.dispatch(AuthStoreAction.demoAutoAuthenticate({ token: this.token }));
    //   } else {
    //     LogService.debug(this, this.ngAfterViewInit.name, 'is authenticated', null);
    //     this.router.navigate(['']);
    //   }
    // });
    if (this.token) {
      this.store.dispatch(AuthStoreAction.authAsUser({ token: this.token }));
    } else {
      this.router.navigate(['']);
    }
  }
}
