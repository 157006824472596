import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { ActionResponse } from 'src/app/models/action-response';
import { Entity } from 'src/app/models/entity';
import { FormFieldConfigurationKind } from 'src/app/models/forms/form-configuration-kind';
import { FormFieldDataRequest, GenericEntityFormFieldEvent, GenericFormField } from 'src/app/models/forms/form-field';
import { EntityFieldsListContentCallBack, GenericEntityRequiredType } from 'src/app/models/generic-entity-field';
import { RootStoreState } from 'src/app/root-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { DecimalCheckerService } from 'src/app/services/utils/decimal-checker.service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from '../../base-components/format-component';

@Component({
  selector: 'app-generic-entity-form, generic-entity-form',
  templateUrl: './generic-entity-form.component.html',
  styleUrls: ['./generic-entity-form.component.scss'],
})
export class GenericEntityFormComponent extends FormatComponent implements OnInit {
  @ViewChild('confirmCancelMenu') confirmCancelMenu: MatMenuTrigger;
  @Input() entitySubj: Observable<Entity>;

  @Input() titleKey: string = _('panel_entity_details');
  @Input() getAPIPath: string;
  @Input() putAPIPath: string;
  @Input() postAPIPath: string;
  @Input() copyAPIPath: string;

  @Input() foreignFilterEntityKind: string;
  @Input() foreignFilterEntityId: number;
  @Input() leftButtonKey = 'label_edit';
  @Input() leftButtonEnabled = true;
  @Input() isLeftButtonCustom = false;
  @Input() leftButtonCustomIcon: string;
  @Input() rightButtonKey: string;
  @Input() rightButtonEnabled = false;
  @Input() isRightButtonCustom = false;
  @Input() isRightButtonString = false;
  @Input() rightButtonCustomIcon: string;
  @Input() customButtonKey;
  @Input() customButtonIcon;
  @Input() customButtonEnabled = true;
  @Input() secondCustomButtonKey;
  @Input() secondCustomButtonIcon;
  @Input() secondCustomButtonEnabled = true;
  @Input() isPanelExpanded = true;
  @Input() showPanelTitleToggle = false;
  @Input() showForeignDetailsButton = true;
  @Input() isFormDialog = false;
  @Input() isEditing: boolean;
  @Input() isCopyEntity: boolean;
  @Input() copyId: number;
  @Output() isEditingChange = new EventEmitter<boolean>();
  @Output() leftButtonCustomButtonEvent = new EventEmitter();
  @Output() rightButtonCustomButtonEvent = new EventEmitter();
  @Output() customButtonEvent = new EventEmitter();
  @Output() secondCustomButtonEvent = new EventEmitter();
  @Output() fetchCallbackEvEm = new EventEmitter<EntityFieldsListContentCallBack>();
  @Output() createUpdateCallbackEvEm = new EventEmitter<EntityFieldsListContentCallBack>();
  @Output() eventDataChanged = new EventEmitter<GenericEntityFormFieldEvent>();
  @Output() dirtyFormEvEm = new EventEmitter<boolean>();
  @Output() goBack = new EventEmitter<boolean>();

  entityDetailsIsOpen = false;
  entityDetailsIsOpenAfterEdit = false;
  genericEntityFormFields: GenericFormField[];
  originalGenericEntityFormFields: GenericFormField[];
  saveSubj: Subject<void> = new Subject<void>();
  mailpreviewSubj: Subject<void> = new Subject<void>();
  mailTemplatePreviewEnabled = false;

  enableConfirmCancel = false;
  selectedEntity: Entity;
  isLoading = false;
  noEntityFieldsData = false;
  saveIsDisabled = true;

  DIRTY_CLASSNAME = 'fo-dirty-behaviour';
  DIRTY_CHECKBOX_CLASSNAME = 'fo-dirty-behaviour-checkbox';

  constructor(
    private messageNotifierService: MessageNotifierService,
    private decimalCheckerService: DecimalCheckerService,
    private coreDataService: CoreDataService,
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.entityDetailsIsOpen = this.isPanelExpanded;
    if (this.entitySubj) {
      this.subscribe(this.entitySubj, (entity) => {
        if (entity) {
          this.selectedEntity = entity;
          this.loadGenericEntityFormField();
        }
      });
    }
  }

  loadGenericEntityFormField() {
    this.isLoading = true;
    this.subscribe(this.coreDataService.getGenericEntityFormField(this.getAPIPath), ({ data }) => {
      this.genericEntityFormFields = data ? data.map((d) => new GenericFormField(d)) : [];
      this.mailTemplatePreviewEnabled = this.genericEntityFormFields.some(
        (d) =>
          d.formfieldKindId === FormFieldConfigurationKind.MAILTEMPLATE ||
          ((d.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
            d.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD) &&
            d.formfieldEntityType === 'mailtemplate')
      );
      this.noEntityFieldsData = this.genericEntityFormFields.length === 0;
      // this.originalGenericEntityFormFields = this.cloneEntityFormFields(this.genericEntityFormFields);
      this.fetchCallbackEvEm.emit(this.createCallback(this.genericEntityFormFields, null, true));
      LogService.debug(this, this.loadGenericEntityFormField.name, 'Entity Form Field', this.genericEntityFormFields);
      this.isLoading = false;
    });
  }

  setOriginalValue() {
    this.originalGenericEntityFormFields = this.cloneEntityFormFields(this.genericEntityFormFields);
  }

  formValueChanged() {
    const diffEntityFormFields = this.genericEntityFormFields.filter(
      (field, index) =>
        !field.isHidedField() &&
        !field.isReadonlyField() &&
        JSON.stringify(field) !== JSON.stringify(this.originalGenericEntityFormFields[index])
    );
    this.saveIsDisabled = diffEntityFormFields.length === 0;
    if (!this.saveIsDisabled) {
      this.dirtyFormEvEm.emit(!this.saveIsDisabled);
    }
  }

  onAutoUpdateEvent($event: GenericEntityFormFieldEvent) {
    const m = this.onAutoUpdateEvent.name;
    this.eventDataChanged.emit($event);
  }

  /** Buttons Methods */

  goEditMode() {
    this.isEditingChange.emit(true);
    this.saveSubj.next();
  }

  onSave($event) {
    this.entityDetailsIsOpenAfterEdit = true;
    const changedFields = this.checkDiffAndErrors();
    this.callCreateUpdateDetailsAPI(changedFields);
    $event.stopPropagation();
  }

  onCancel($event) {
    if (!this.saveIsDisabled) {
      this.enableConfirmCancel = true;
      if (this.confirmCancelMenu) this.confirmCancelMenu.openMenu();
      this.cdRef.detectChanges();
      // this.confirmCancelButton.focus();
      $event.stopPropagation();
    } else {
      this.onConfirmCancel();
    }
  }

  onConfirmCancel() {
    this.isLoading = true;
    this.genericEntityFormFields = [];
    this.cdRef.detectChanges();
    if (!this.isCopyEntity) {
      this.genericEntityFormFields = this.cloneEntityFormFields(this.originalGenericEntityFormFields);
      this.isEditingChange.emit(false);
    } else {
      this.goBack.emit(true);
      this.isEditingChange.emit(false);
    }
    this.isEditingChange.emit(false);
    this.entityDetailsIsOpenAfterEdit = true;
    this.enableConfirmCancel = false;
    this.isLoading = false;
    this.cdRef.detectChanges();
  }

  /** Panel Methods */

  leftButtonIsEnabled() {
    if (!this.leftButtonKey || !this.leftButtonEnabled) {
      return false;
    }
    return this.leftButtonEnabled && this.leftButtonKey.toString().length > 0;
  }

  rightButtonIsEnabled() {
    if (!this.rightButtonKey || !this.rightButtonEnabled || !this.isRightButtonCustom) {
      return false;
    }
    return this.rightButtonEnabled && this.rightButtonKey.toString().length > 0;
  }

  customButtonIsEnabled() {
    if (!this.customButtonKey || !this.customButtonEnabled) {
      return false;
    }
    return this.customButtonEnabled && this.customButtonKey.toString().length > 0;
  }

  secondCustomButtonIsEnabled() {
    if (!this.secondCustomButtonKey || !this.secondCustomButtonEnabled) {
      return false;
    }
    return this.secondCustomButtonEnabled && this.secondCustomButtonKey.toString().length > 0;
  }

  expandPanel($event) {
    if (!this.isEditing) {
      this.entityDetailsIsOpen = !this.entityDetailsIsOpen;
    }
    $event.stopPropagation();
  }

  /** Utility methods */

  private callCreateUpdateDetailsAPI(creationUpdatedData: GenericFormField[]) {
    const m = this.callCreateUpdateDetailsAPI.name;

    if (creationUpdatedData) {
      if (this.isCopyEntity) {
        const apiPath = this.copyAPIPath ? this.copyAPIPath : this.postAPIPath + `?copyId=${this.copyId}`;
        this.subscribe(
          this.coreDataService.createGenericEntityFormField(
            creationUpdatedData.map((fsd) => {
              return new FormFieldDataRequest(fsd);
            }),
            apiPath
          ),
          (response) => {
            LogService.debug(this, m, 'COPY (POST) response: ', response.data);
            this.createUpdateResponseManagement(response.data, creationUpdatedData);
          },
          (error) => {
            this.isEditing = true;
            this.isCopyEntity = true;
            this.createUpdateCallbackEvEm.emit(this.createCallback(null, null, false));
            /* HTTP Errors are managed on ServerErrorInterceptor */
          }
        );
      } else {
        this.subscribe(
          this.coreDataService.updateGenericEntityFormField(
            creationUpdatedData.map((fsd) => {
              return new FormFieldDataRequest(fsd);
            }),
            this.putAPIPath
          ),
          (response) => {
            LogService.debug(this, m, 'UPDATE (PUT) response: ', response.data);
            this.createUpdateResponseManagement(response.data, creationUpdatedData);
          },
          (error) => {
            this.isEditing = true;
            this.isCopyEntity = false;
            this.createUpdateCallbackEvEm.emit(this.createCallback(null, null, false));
            /* HTTP Errors are managed on ServerErrorInterceptor */
          }
        );
      }
    }
  }

  private createUpdateResponseManagement(responseData: ActionResponse, createUpdatedData: GenericFormField[]) {
    const m = this.createUpdateResponseManagement.name;

    // this.isEditingChange.emit(false);
    if (responseData) {
      if (responseData.state) {
        // Success
        this.isEditing = false;
        this.isCopyEntity = false;
        this.messageNotifierService.showSuccessMessage('toastr_success');
        this.createUpdateCallbackEvEm.emit(this.createCallback(createUpdatedData, responseData, true));
      } else if (responseData.error) {
        // Fail
        this.isEditing = true;
        this.messageNotifierService.showErrorMessage(responseData.error);
        this.createUpdateCallbackEvEm.emit(this.createCallback(null, responseData, false));
      } else {
        this.messageNotifierService.showWarningMessage('toastr_no_data');
        this.createUpdateCallbackEvEm.emit(this.createCallback(null, responseData, false));
      }
    }
  }

  private createCallback(data: GenericFormField[], actionResponse: ActionResponse, isSuccess: boolean): EntityFieldsListContentCallBack {
    const m = this.createCallback.name;

    const callback = new EntityFieldsListContentCallBack(data, actionResponse, this.isEditing, this.isCopyEntity, isSuccess);

    return callback;
  }

  checkDiffAndErrors() {
    let hasError = false;
    let diffEntityFormFields: GenericFormField[] = [];
    this.saveSubj.next();
    // Check for required and decimal value
    hasError = this.hasError();
    if (this.isCopyEntity) {
      diffEntityFormFields = this.cloneEntityFormFields(this.genericEntityFormFields);
    } else {
      // Check original tree and compute differences
      diffEntityFormFields = this.genericEntityFormFields.filter(
        (field, index) => JSON.stringify(field) !== JSON.stringify(this.originalGenericEntityFormFields[index])
      );
    }
    LogService.debug(this, this.checkDiffAndErrors.name, 'diffEntityFormFields', diffEntityFormFields);
    // Regex check
    diffEntityFormFields.forEach(({ formfieldEntityRegex, formfieldEntityRegexerrormsg, formfieldEntityText }) => {
      if (formfieldEntityText && formfieldEntityRegex) {
        const re = new RegExp(formfieldEntityRegex);
        const matched = formfieldEntityText.toString().match(re);
        if (!matched) {
          this.messageNotifierService.showWarningMessage(formfieldEntityRegexerrormsg);
          hasError = true;
        }
      }
    });
    if (!hasError) {
      if (this.postAPIPath || this.putAPIPath) {
        return diffEntityFormFields;
      }
    } else {
      return null;
    }
  }

  getFlyingButtonsClass(): string {
    if (this.isEditing) {
      return 'double-buttons';
    } else {
      if (this.isRightButtonString) {
        return 'fitcontent-buttons';
      }
      if (this.mailTemplatePreviewEnabled && (this.customButtonIsEnabled() || this.secondCustomButtonIsEnabled())) {
        return 'triple-buttons';
      }
      if (
        (this.mailTemplatePreviewEnabled && !(this.customButtonIsEnabled() || this.secondCustomButtonIsEnabled())) ||
        (!this.mailTemplatePreviewEnabled && (this.customButtonIsEnabled() || this.secondCustomButtonIsEnabled())) ||
        (this.mailTemplatePreviewEnabled && !this.rightButtonIsEnabled()) ||
        (!this.mailTemplatePreviewEnabled && this.rightButtonIsEnabled())
      ) {
        return 'double-buttons';
      }
      return '';
    }
  }

  private hasError(): boolean {
    const m = this.hasError.name;

    let hasError = false;
    let hasRequiredError = false;
    this.genericEntityFormFields.forEach((field) => {
      if (
        field.formfieldEntityText &&
        field.formfieldEntityType === 'decimal' &&
        this.decimalCheckerService.hasErrorFormField(field.formfieldEntityText, field)
      ) {
        hasError = true;
      }
      if (
        field.formfieldRequired === GenericEntityRequiredType.required &&
        !field.isHidedField() &&
        !field.isReadonlyField() &&
        (field.formfieldKindId === FormFieldConfigurationKind.SEARCH ||
          field.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
          field.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
          field.formfieldKindId === FormFieldConfigurationKind.CHIPS) &&
        !field.isRequiredControlValid
      ) {
        LogService.warning(this, m, `Missing required value: ${this.translate.instant(field.formfieldTextname)}`, null);
        hasError = true;
        hasRequiredError = true;
      }
      if (
        field.formfieldRequired === GenericEntityRequiredType.required &&
        !field.isHidedField() &&
        !field.isReadonlyField() &&
        field.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL &&
        !field.isStepControlValid
      ) {
        LogService.warning(this, m, `Missing required value: ${this.translate.instant(field.formfieldTextname)}`, null);
        hasError = true;
        hasRequiredError = true;
      }
    });
    if (hasRequiredError) {
      this.messageNotifierService.showWarningMessage(`${this.translate.instant('toastr_missing_required_value')}`);
    }

    return hasError;
  }

  private cloneEntityFormFields(entityFormFields: GenericFormField[]): GenericFormField[] {
    // fast way to deep copy an array avoiding reference update.
    return JSON.parse(JSON.stringify(entityFormFields)).map((d) => new GenericFormField(d));
  }
}
