import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { AuditLog } from '../models/audit-log';
import { BaseResponse } from '../models/base-response';
import { PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class AuditLogDataService {
  getAuditLogByEntityId(entityKind: string, entityId: number, request: PaginatedRequest): Observable<BaseResponse<AuditLog[]>> {
    const m = this.getAuditLogByEntityId.name;

    const path = environment.getEndpoint(ApiPath.AuditLog.LOG_DETAILS(entityKind, entityId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    const retVal = this.http.get<BaseResponse<AuditLog[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getAuditLogByEntityIdSpreadsheet(entityKind: string, entityId: number, request: PaginatedRequest) {
    const m = this.getAuditLogByEntityIdSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.AuditLog.LOG_DETAILS_SPREADSHEET(entityKind, entityId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  constructor(private http: HttpClient) {}
}
