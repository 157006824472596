import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, throwError, withLatestFrom } from 'rxjs';
import { LicenseDataService } from 'src/app/services/license-data.service';
import { AddConsumersToAssetWizardActions } from '.';
import { selectInitStep } from './selectors';
import { State } from './state';

@Injectable()
export class AddConsumersToAssetWizardEffects {
  // loadReplaceProductWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(AddConsumersToAssetWizardActions.selectInitStep),
  //       tap(() => this.router.navigateByUrl('/pages/allocation-wizards/order'))
  //     ),
  //   { dispatch: false }
  // );
  // exitAddConsumersToAssetAllocationWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(AddConsumersToAssetWizardActions.exitAllocationAddConsumersToAssetWizard),
  //       tap(() => this.router.navigateByUrl('/pages/consumers'))
  //     ),
  //   { dispatch: false }
  // );

  newAddConsumersToAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddConsumersToAssetWizardActions.createNewAddConsumersToAsset),
      withLatestFrom(this.store.select(selectInitStep)),
      concatMap(([{ request }, initStep]) =>
        this.licenseDataService.saveLicenseAssetPeriodAllocations(initStep.assetId, request).pipe(
          map((response) => {
            if (response) {
              return AddConsumersToAssetWizardActions.createNewAddConsumersToAssetSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            let errorMessage = '';
            if (error instanceof HttpErrorResponse) {
              errorMessage = error.error.errorKey;
            } else {
              errorMessage = error;
            }
            return of(AddConsumersToAssetWizardActions.createNewAddConsumersToAssetFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<State>, private licenseDataService: LicenseDataService) {}
}
