import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProductConfigurationStoreModule } from '../product-type-config-store';
import { ReactivateAssetWizardEffects } from './effects';
import * as fromReactivateAssetWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromReactivateAssetWizard.reactivateAssetWizardFeatureKey, fromReactivateAssetWizard.reducer),
    EffectsModule.forFeature([ReactivateAssetWizardEffects]),
    ProductConfigurationStoreModule,
  ],
})
export class ReactivateAssetWizardStoreModule {}
