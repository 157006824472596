import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { AuthToken } from '../../core/auth/auth-token';

export interface TokenPack {
  createdAt: number;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class RestrictedTokenService {
  private key = 'restricted_auth_app_token';
  protected token$: BehaviorSubject<AuthToken> = new BehaviorSubject(null);

  constructor() {
    this.publishStoredToken();
  }

  set(token: AuthToken) {
    this.setAuthToken(token);
    this.publishStoredToken();
  }

  get(): Observable<AuthToken> {
    return observableOf(this.getAuthToken());
  }

  clear() {
    sessionStorage.removeItem(this.key);
    this.publishStoredToken();
  }

  tokenChange(): Observable<AuthToken> {
    return this.token$.pipe(
      filter((value) => !!value),
      share()
    );
  }

  private wrap(token: AuthToken): string {
    return JSON.stringify({
      createdAt: token.getCreatedAt().getTime(),
      value: token.toString(),
    });
  }

  private unwrap(value: string): AuthToken {
    const tokenPack: TokenPack = this.parseTokenPack(value);
    let tokenValue = '';
    let tokenCreatedAt: Date = null;
    if (tokenPack) {
      tokenValue = tokenPack.value;
      tokenCreatedAt = new Date(Number(tokenPack.createdAt));
    }
    return new AuthToken(tokenValue);
  }

  getAuthToken(): AuthToken {
    const raw = sessionStorage.getItem(this.key);
    return this.unwrap(raw);
  }

  private setAuthToken(token: AuthToken) {
    const raw = this.wrap(token);
    sessionStorage.setItem(this.key, raw);
  }

  private publishStoredToken() {
    this.token$.next(this.getAuthToken());
  }

  private parseTokenPack(value): TokenPack {
    try {
      return JSON.parse(value);
    } catch (e) {}
    return null;
  }
}
