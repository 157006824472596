import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appFileDragDrop]',
})
export class FileDragDropDirective {
  @Input() dropDisabled = false;
  @Input() backgroundColor = 'inherit';
  @Input() dragOverBackgroundColor = 'inherit';
  @Input() borderColor = '';
  @Input() dragOverBorderColor = '';
  @Input() dragOverBorder = '';
  @Output() fileDropped = new EventEmitter<any>();
  @Output() folderDropped = new EventEmitter<any>();

  borderWidth = this.borderColor === '' ? '' : 'inherit';
  borderStyle = this.borderColor === '' ? '' : 'inherit';

  @HostBinding('style.background-color') sBackground = this.backgroundColor;
  @HostBinding('style.border-color') sBorderColor = this.borderColor;
  @HostBinding('style.border-width') sBorderWidth = this.borderWidth;
  @HostBinding('style.border-style') sBorderStyle = this.borderStyle;
  @HostBinding('style.opacity') sOpacity = '1';

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.dropDisabled) {
      this.sBackground = this.dragOverBackgroundColor;
      this.sBorderColor = this.dragOverBorderColor;
      this.sBorderWidth = '2px';
      this.sBorderStyle = 'solid';
      this.sOpacity = '0.8';
    }
  }
  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.dropDisabled) {
      this.sBackground = this.backgroundColor;
      this.sBorderColor = this.borderColor;
      this.sBorderWidth = this.borderWidth;
      this.sBorderStyle = this.borderStyle;
      this.sOpacity = '1';
    }
  }
  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.dropDisabled) {
      this.sBackground = this.backgroundColor;
      this.sBorderColor = this.borderColor;
      this.sBorderWidth = this.borderWidth;
      this.sBorderStyle = this.borderStyle;
      this.sOpacity = '1';
      const items = evt.dataTransfer.items;
      for (let i = 0; i < items.length; i++) {
        const entry = items[i].webkitGetAsEntry();
        if (entry.isDirectory) {
          this.folderDropped.emit();
          return;
        }
      }
      const files = evt.dataTransfer.files;
      if (files.length > 0) {
        this.fileDropped.emit(files);
      }
      // const items = evt.dataTransfer.items;
      // for (let i = 0; i < items.length; i++) {
      //   const item = items[i];
      //   if (item.kind === 'file') {
      //     const entry = item.webkitGetAsEntry();
      //     if (entry.isFile) {
      //       const file = parseFileEntry(entry);
      //       file.then((f) => console.log(f));
      //     } else if (entry.isDirectory) {
      //       const dirContent = parseDirectoryEntry(entry);
      //       dirContent
      //         .then((value) => console.log(value))
      //         .catch()
      //         .finally();
      //     }
      //   }
      // }
    }
  }
}
function parseFileEntry(fileEntry) {
  return new Promise((resolve, reject) => {
    fileEntry.file(
      (file) => {
        resolve(file);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
function parseDirectoryEntry(directoryEntry) {
  const directoryReader = directoryEntry.createReader();
  return new Promise((resolve, reject) => {
    directoryReader.readEntries(
      (entries) => {
        resolve(entries);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
