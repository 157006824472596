import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GenericFormField } from 'src/app/models/forms/form-field';
import * as fromProductConfiguration from './reducer';
import { State, featureAdapter } from './state';

export const selectProductConfigurationState = createFeatureSelector<State>(fromProductConfiguration.productConfigurationsFeatureKey);

export const selectAllProductConfigurationItems: (state: object) => GenericFormField[] =
  featureAdapter.getSelectors(selectProductConfigurationState).selectAll;

export const selectAllProductConfigurationsError = createSelector(selectProductConfigurationState, (state: State): any => state.error);

export const selectAllProductConfigurationsIsLoading = createSelector(
  selectProductConfigurationState,
  (state: State): boolean => state.isLoading
);
