import { Action, createReducer, on } from '@ngrx/store';
import { RequestValidationResponse } from 'src/app/models/request';
import * as RequestsActions from './actions';
import { State, initialState } from './state';

export const requestsFeatureKey = 'foRequests';

const requestsReducer = createReducer(
  initialState,
  // Request Validation
  on(RequestsActions.validateRequest, (state) => ({
    ...state,
    requestIsValid: false,
  })),
  on(RequestsActions.validateRequestResult, (state, { validationResult }) => ({
    ...state,
    validationResult,
  })),
  on(RequestsActions.validateRequestError, (state, { error }) => ({
    ...state,
    validationResult: {
      ...new RequestValidationResponse(),
      requestError: error,
    },
  })),
  // Request Actions (execution, rejection)
  on(RequestsActions.actionRequestDone, (state) => ({
    ...state,
    executionStatus: false,
    rejectionStatus: false,
    approveStatus: false,
    rejectionApprovalStatus: false,
    actionError: null,
  })),
  on(RequestsActions.executionRequestResult, (state, { executionStatus }) => ({
    ...state,
    executionStatus,
  })),
  on(RequestsActions.rejectionRequestResult, (state, { rejectionStatus }) => ({
    ...state,
    rejectionStatus,
  })),
  on(RequestsActions.approveRequestResult, (state, { approveStatus }) => ({
    ...state,
    approveStatus,
  })),
  on(RequestsActions.rejectionApprovalRequestResult, (state, { rejectionApprovalStatus }) => ({
    ...state,
    rejectionApprovalStatus,
  })),
  on(RequestsActions.actionRequestError, (state, { error }) => ({
    ...state,
    actionError: error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return requestsReducer(state, action);
}
