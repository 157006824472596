import { ActionResponse } from 'src/app/models/action-response';
import { PackageTreeNode } from 'src/app/models/package';
import { AddBillingitemTopackageDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-billingitems-topackage-wizard/activation-step/addbillingitems-due-date-info';
import { AddBillingitemTopackageInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-billingitems-topackage-wizard/init-step/init-step';

export interface State {
  initStep: AddBillingitemTopackageInitStep;
  dueDatesStep: AddBillingitemTopackageDueDates;
  billingitemPackageStep: PackageTreeNode[];
  // request: BillingitemParticularRequest;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  dueDatesStep: null,
  billingitemPackageStep: null,
  response: null,
  errorMessage: null,
};
