import { CancelAssetOptions } from 'src/app/models/cancel-asset-options';
import { AdditionalInfo } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/additional-step/additional-info';
import { CancelLicenseServicesRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/confirmation-step/cancel-license-services-request';
import { CancelLicenseServicesResponse } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/confirmation-step/cancel-license-services-response';
import { CancelDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/dates-step/cancel-due-dates';
import { InitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/init-step/init-step';

export interface State {
  initStep: InitStep;
  dueDatesStep: CancelDueDates;
  licenseStep: CancelAssetOptions[];
  addInfoStep: AdditionalInfo;
  cancelRequest: CancelLicenseServicesRequest;
  cancelLicenseServicesResponse: CancelLicenseServicesResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  dueDatesStep: null,
  licenseStep: null,
  addInfoStep: null,
  cancelRequest: null,
  cancelLicenseServicesResponse: null,
  errorMessage: null,
};
