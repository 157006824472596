import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseParams, PaginatedRequest } from 'src/app/models/paginated-request';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { Entity } from '../models/entity';
import {
  Invoice,
  InvoiceCreditNote,
  InvoiceParticulars,
  InvoiceParticularsItem,
  InvoiceParticularsItemQuantity,
  InvoiceWorkflow,
  getInvoiceRequestParams,
} from '../models/invoice';
import { InvoiceOutstanding } from '../models/invoice-outstanding';
import { InvoiceRequest } from '../pages/invoice/invoice-particulars/requests/invoice-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceDataService {
  getInvoices(request: PaginatedRequest, linkable: boolean): Observable<BaseResponse<Invoice[]>> {
    const m = this.getInvoices.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'request', request);
    const retVal = this.http.get<BaseResponse<Invoice[]>>(path, {
      params: getInvoiceRequestParams(request, linkable),
    });

    return retVal;
  }

  getExpectedInvoices(request: PaginatedRequest): Observable<BaseResponse<Invoice[]>> {
    const m = this.getExpectedInvoices.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'request', request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = params.append('filterIsexpectedIds', 1);
    const retVal = this.http.get<BaseResponse<Invoice[]>>(path, {
      params,
    });

    return retVal;
  }

  getCreditNoteInvoices(request: PaginatedRequest, invoiceId: number): Observable<BaseResponse<InvoiceCreditNote[]>> {
    const m = this.getCreditNoteInvoices.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_CREDITNOTES(invoiceId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'request', request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<InvoiceCreditNote[]>>(path, {
      params,
    });

    return retVal;
  }

  dismissOutstandingInvoice(invoiceId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.dismissOutstandingInvoice.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_OUTSTANDINGS_DISMISS(invoiceId));
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, LogService.REQUEST, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  getCreateInvoiceFieldSortingOrder(): Observable<BaseResponse<string[]>> {
    const m = this.getCreateInvoiceFieldSortingOrder.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_PARTICULARS_DEFAULTS_SORTORDER);
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.REQUEST, null);
    const retVal = this.http.get<BaseResponse<string[]>>(path);

    return retVal;
  }

  postCreateInvoiceFieldSortingOrder(sortorders: string[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.getCreateInvoiceFieldSortingOrder.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_PARTICULARS_DEFAULTS_SORTORDER);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, null);
    const params = new HttpParams().set('sortorders', sortorders.join(','));
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getInvoicesOutstandings(optionId: number, days: number, nofItems: number): Observable<BaseResponse<InvoiceOutstanding[]>> {
    const m = this.getInvoicesOutstandings.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_OUTSTANDINGS);
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams().set('optionId', optionId).set('days', days).set('nofItems', nofItems);
    const retVal = this.http.get<BaseResponse<InvoiceOutstanding[]>>(path, {
      params,
    });
    return retVal;
  }

  createInvoiceByVendoraccountId(billingaccountId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.createInvoiceByVendoraccountId.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_CREATION_BYBILLINGACCOUNTID(billingaccountId));
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  getInvoicesSpreadsheet(request: PaginatedRequest, linkable: boolean) {
    const m = this.getInvoicesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: getInvoiceRequestParams(request, linkable),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  changeInvoicesStatus(invoiceIds: number[], status: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.changeInvoicesStatus.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_CHANGESTATUS);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, {
      params: new HttpParams().set('invoiceIds', invoiceIds.join(',')).set('status', status),
    });

    return retVal;
  }

  getInvoiceStatus(): Observable<BaseResponse<Entity[]>> {
    const m = this.getInvoiceStatus.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_STATUS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<Entity[]>>(path, {
      params: new HttpParams().set(BaseParams.ORDER_BY, 'entity_name'),
    });

    return retVal;
  }

  getInvoiceVendors(): Observable<BaseResponse<Entity[]>> {
    const m = this.getInvoiceVendors.name;

    const path = environment.getEndpoint(ApiPath.Vendors.VENDOR_ENTITIES);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<Entity[]>>(path, {
      params: new HttpParams().set(BaseParams.ORDER_BY, 'entity_name'),
    });

    return retVal;
  }

  getInvoiceParticulars(apiPath: string): Observable<BaseResponse<InvoiceParticulars>> {
    const m = this.getInvoiceParticulars.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<InvoiceParticulars>>(path);

    return retVal;
  }

  getInvoiceSpreadsheet(apiPath: string) {
    const m = this.getInvoiceSpreadsheet.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + apiPath, null);

    return this.http
      .get(path, {
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getInvoicePdf(apiPath: string) {
    const m = this.getInvoiceSpreadsheet.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + apiPath, null);

    return this.http
      .get(path, {
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  startSaveNewInvoiceParticulars(request: InvoiceRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.startSaveNewInvoiceParticulars.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_PARTICULARS);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  startSaveInvoiceParticulars(request: InvoiceRequest, invoiceId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.startSaveInvoiceParticulars.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_PARTICULARS_ID(invoiceId));
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getInvoiceParticularsItems(apiPath: string): Observable<BaseResponse<InvoiceParticularsItem[]>> {
    const m = this.getInvoiceParticularsItems.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<InvoiceParticularsItem[]>>(path);

    return retVal;
  }

  getInvoiceParticularsItemDefaultValue(invoiceId: number, billingitemId: number): Observable<BaseResponse<InvoiceParticularsItem>> {
    const m = this.getInvoiceParticularsItems.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_PARTICULARS_ITEM_DEFAULT_VALUES(invoiceId, billingitemId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<InvoiceParticularsItem>>(path);

    return retVal;
  }

  getInvoiceParticularsWorkflow(invoiceId: number): Observable<BaseResponse<InvoiceWorkflow>> {
    const m = this.getInvoiceParticularsWorkflow.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_PARTICULARS_WORKFLOW(invoiceId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<InvoiceWorkflow>>(path);

    return retVal;
  }

  putInvoiceParticularsWorkflow(invoiceId: number, request: InvoiceWorkflow): Observable<BaseResponse<ActionResponse>> {
    const m = this.putInvoiceParticularsWorkflow.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_PARTICULARS_WORKFLOW(invoiceId));
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  addInvoiceCreditNotes(invoiceId: number, creditnoteIds: number[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.addInvoiceCreditNotes.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_CREDITNOTES(invoiceId));
    LogService.info(this, m, LogService.POST + path, null);
    const params = new HttpParams().set('creditnoteIds', creditnoteIds.join(','));
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });
    return retVal;
  }

  getInvoiceItemQuantities(
    invoiceId: number,
    invoiceitemId: number,
    request: PaginatedRequest
  ): Observable<BaseResponse<InvoiceParticularsItemQuantity[]>> {
    const m = this.getInvoiceItemQuantities.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_PARTICULARS_ITEMS_QUANTITIES(invoiceId, invoiceitemId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<InvoiceParticularsItemQuantity[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  saveInvoiceItemQuantities(
    invoiceId: number,
    invoiceitemId: number,
    request: InvoiceParticularsItemQuantity[]
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.getInvoiceItemQuantities.name;

    const path = environment.getEndpoint(ApiPath.Invoices.INVOICES_PARTICULARS_ITEMS_QUANTITIES(invoiceId, invoiceitemId));
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);
    return retVal;
  }

  constructor(private http: HttpClient) {}
}
