import { KeyValue } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { select } from '@ngrx/store';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { ForeignEntity } from '../../../models/foreign-entity';
import { PaginatedRequest } from '../../../models/paginated-request';
import { PreferencesSelectors } from '../../../root-store/preferences-store';
import { CoreDataService } from '../../../services/core-data.service';

export interface DialogData {
  labelName: string;
  disableSorting?: boolean;
  basePath?: string;
  basePathParams?: string;
}

@Component({
  selector: 'app-custom-entity-modal',
  templateUrl: './custom-entity-modal.component.html',
  styleUrls: ['./custom-entity-modal.component.scss'],
})
export class CustomEntityModalComponent extends FormatComponent implements AfterViewInit {
  constructor(
    private coreDataService: CoreDataService,
    public dialogRef: MatDialogRef<CustomEntityModalComponent>,
    private crd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super();
  }

  @ViewChild('paginatorTable') paginatorTable: MatPaginator;
  @ViewChild('sortTable') sortTable: MatSort;
  isLoading = true;
  noEntityData = false;
  pageSizeList: number[] = [];
  pageTotalElements = 0;
  request: PaginatedRequest;
  filterValue: string;
  lastUsedFilters: string[];
  selectedEntity: ForeignEntity;

  dataSourceTable: MatTableDataSource<ForeignEntity> = new MatTableDataSource<ForeignEntity>();
  displayedColumnsTable: string[] = ['radio', 'entityName', 'entityDescription'];

  searchFilterrules: KeyValue<string, string>[];
  selectedFilterules: KeyValue<string, string>;
  selectedFilteruleId: number;

  ngAfterViewInit() {
    this.selectedFilteruleId = 0;
    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesFilterRulesOptions)), (filterRules) => {
      this.searchFilterrules = filterRules;
      this.selectedFilterules = this.searchFilterrules && this.searchFilterrules.length > 0 ? this.searchFilterrules[0] : null;
    });
    this.subscribe(this.store.select(PreferencesSelectors.selectPreferencesPaginatonsOptions), (pageSizeOpt) => {
      // Wait for selected product
      if (pageSizeOpt && pageSizeOpt.length !== 0) {
        this.pageSizeList = pageSizeOpt;
        this.paginatorTable.pageSize = this.pageSizeList[0];
        this.request = {
          pageIndex: 0,
          pageSize: pageSizeOpt[0],
          orderBy: this.data && this.data.disableSorting ? null : 'entityName',
          sort: this.data && this.data.disableSorting ? null : 'asc',
          filterRule: this.selectedFilteruleId != null ? this.selectedFilteruleId : this.castToInt(this.selectedFilterules?.key),
        };
        this.crd.detectChanges();
        // Table Initialization
        this.loadServicesData();
      }
    });
    if (this.data && !this.data.disableSorting) {
      this.sortTable.sortChange.subscribe(() => {
        this.setOrder();
        this.loadServicesData();
      });
    }
    this.paginatorTable.page.subscribe(() => {
      this.setPage();
      this.loadServicesData();
    });
  }

  loadServicesData() {
    this.dataSourceTable = new MatTableDataSource();
    this.isLoading = true;
    const apiPath = this.data.basePathParams ? this.data.basePath + this.data.basePathParams : this.data.basePath;
    this.coreDataService.getForeignEntity(apiPath, this.request).subscribe((response) => {
      this.lastUsedFilters = response.filters;
      this.dataSourceTable.data = response.data ? response.data : [];
      this.noEntityData = !response.data || response.data.length === 0;
      this.pageTotalElements = !response.data || response.data.length === 0 ? 0 : response.data[0].entityCount;
      this.isLoading = false;
      this.crd.detectChanges();
    });
  }

  isFiltersSetted(): boolean {
    return this.filterValue != '' || this.filterValue != null;
  }

  private setFilter(filter: string) {
    this.request = {
      ...this.request,
      filter,
    };
  }

  resetFilter() {
    if (this.filterValue) {
      this.filterValue = '';
      this.applyFilterTable(this.filterValue);
    }
  }

  applyFilterTable(filterValue: string) {
    const m = this.applyFilterTable.name;

    LogService.info(this, m, 'FILTER VALUE', null);

    if (filterValue && filterValue.length >= 3) {
      this.setFilter(filterValue);
      this.loadServicesData();
    } else {
      if (!filterValue || (filterValue && filterValue.length === 0)) {
        this.setFilter(null);
        this.loadServicesData();
      }
    }
  }

  private setOrder() {
    this.request = {
      ...this.request,
      orderBy: this.sortTable.active,
      sort: this.sortTable.direction,
    };
  }

  private setPage() {
    this.request = {
      ...this.request,
      pageSize: this.paginatorTable.pageSize,
      pageIndex: this.paginatorTable.pageIndex,
    };
  }

  protected setFilterRule(values?: number[]) {
    if (values) {
      const value = values.find((id) => id !== this.selectedFilteruleId);
      this.selectedFilteruleId = value != null ? value : 0;
    } else {
      this.selectedFilteruleId = 0;
    }
    if (this.paginatorTable) {
      this.paginatorTable.pageIndex = 0;
    }
    this.request = {
      ...this.request,
      pageIndex: 0,
      filterRule: this.selectedFilteruleId,
    };
    if (this.request.filter && this.request.filter.length >= 3) {
      this.loadServicesData();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onEntitySelected(row: ForeignEntity) {
    this.selectedEntity = row;
  }

  isEntitySelected() {
    return this.selectedEntity && this.selectedEntity.entityName !== '';
  }
}
