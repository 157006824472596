import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MoveBillingitemsWizardEffects } from './effects';
import * as fromMoveBillingitemsWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromMoveBillingitemsWizard.moveBillingitemsWizardFeatureKey, fromMoveBillingitemsWizard.reducer),
    EffectsModule.forFeature([MoveBillingitemsWizardEffects]),
  ],
})
export class MoveBillingitemsWizardStoreModule {}
