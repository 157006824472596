import { Action, createReducer, on } from '@ngrx/store';
import * as MoveWizardStoreActions from './actions';
import { State, initialState } from './state';

export const moveWizardStoreFeatureKey = 'moveWizardStore';

const moveWizardStoreReducer = createReducer(
  initialState,

  on(MoveWizardStoreActions.selectMoveInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(MoveWizardStoreActions.selectToDeskStep, (state, { toDeskStep }) => ({
    ...state,
    toDeskStep,
  })),
  on(MoveWizardStoreActions.selectFromDeskStep, (state, { fromDeskStep }) => ({
    ...state,
    fromDeskStep,
  })),
  on(MoveWizardStoreActions.selectLicenseStep, (state, { licenseStep }) => ({
    ...state,
    licenseStep,
  })),
  on(MoveWizardStoreActions.exitAllocationMoveWizard, (state) => ({
    ...state,
    initStep: null,
    fromDeskStep: null,
    toDeskStep: null,
    licenseStep: null,
    errorMessage: null,
  })),
  on(MoveWizardStoreActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    fromDeskStep: null,
    toDeskStep: null,
    licenseStep: null,
    errorMessage: null,
  }))
  // on(MoveWizardStoreActions.sendLicenseServicesCancelRequest, (state, { cancelRequest }) => ({
  //   ...state,
  //   cancelRequest,
  // })),
  // on(MoveWizardStoreActions.cancelRequestSuccess, (state, { response }) => ({
  //   ...state,
  //   cancelLicenseServicesResponse: response,
  // })),
  // on(MoveWizardStoreActions.cancelRequestFail, (state, { errorMessage }) => ({
  //   ...state,
  //   errorMessage,
  // }))
);

export function reducer(state: State | undefined, action: Action) {
  return moveWizardStoreReducer(state, action);
}
