<div (click)="onCellClick($event)">
  <ng-container *ngIf="is_expand_column">
    <div [ngStyle]="{ 'padding-left': row_data.leaf ? row_data.levelx * 20 + 'px' : row_data.levelx * 10 + 'px' }">
      <ng-container *ngIf="!expand_tracker[row_data.pathx]">
        <span (click)="expandRow($event)">
          <i
            [ngClass]="configs.css.expand_class"
            class="expand-icon"
            aria-hidden="true"
            [ngStyle]="{ visibility: row_data.expand_disabled ? 'hidden' : 'visible' }"
            *ngIf="show_expand_icon"
          ></i>
        </span>
      </ng-container>
      <ng-container *ngIf="expand_tracker[row_data.pathx]">
        <span *ngIf="row_data.is_loading" class="childred-loader"></span>
        <span (click)="collapseRow($event)" *ngIf="!row_data.is_loading">
          <i
            [ngClass]="configs.css.collapse_class"
            class="expand-icon"
            aria-hidden="true"
            [ngStyle]="{ visibility: row_data.expand_disabled ? 'hidden' : 'visible' }"
            *ngIf="show_expand_icon"
          ></i>
        </span>
      </ng-container>
      <ng-container *ngIf="!edit_on || !column.editable" [ngSwitch]="column.type">
        <db-custom-cell-component *ngSwitchCase="'custom'" [column]="column" [row_data]="row_data"> </db-custom-cell-component>
        <db-tree-cell-view *ngSwitchDefault [column]="column" [row_data]="row_data"> </db-tree-cell-view>
      </ng-container>
      <ng-container *ngIf="edit_on && column.editable">
        <db-custom-cell-editor-component
          *ngIf="column.editor"
          [cell_value]="cell_value"
          [column]="column"
          [row_data]="row_data"
          (canceledit)="canceledit.emit(row_data['pathx'])"
          (editcomplete)="onEditComplete($event)"
        >
        </db-custom-cell-editor-component>
        <db-tree-cell-editor
          *ngIf="!column.editor"
          [cell_value]="cell_value"
          [row_data]="row_data"
          [column]="column"
          [expandable_column]="true"
          (canceledit)="canceledit.emit(row_data['pathx'])"
          (editcomplete)="onEditComplete($event)"
        >
        </db-tree-cell-editor>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!is_expand_column">
    <ng-container *ngIf="!edit_on || !column.editable" [ngSwitch]="column.type">
      <db-custom-cell-component *ngSwitchCase="'custom'" [column]="column" [row_data]="row_data"> </db-custom-cell-component>
      <db-tree-cell-view *ngSwitchDefault [column]="column" [row_data]="row_data"> </db-tree-cell-view>
    </ng-container>

    <ng-container *ngIf="edit_on && column.editable">
      <db-custom-cell-editor-component
        *ngIf="column.editor"
        [cell_value]="cell_value"
        [column]="column"
        [row_data]="row_data"
        (canceledit)="canceledit.emit(row_data['pathx'])"
        (editcomplete)="onEditComplete($event)"
      >
      </db-custom-cell-editor-component>
      <db-tree-cell-editor
        *ngIf="!column.editor"
        [cell_value]="cell_value"
        [row_data]="row_data"
        [column]="column"
        [expandable_column]="false"
        (canceledit)="canceledit.emit(row_data['pathx'])"
        (editcomplete)="onEditComplete($event)"
      >
      </db-tree-cell-editor>
    </ng-container>
  </ng-container>
</div>
