import { Action, createReducer, on } from '@ngrx/store';
import * as SwapContractWizardStoreActions from './actions';
import { State, initialState } from './state';

export const swapContractWizardStoreFeatureKey = 'swapContractWizardStore';

const swapContractWizardStoreReducer = createReducer(
  initialState,

  on(SwapContractWizardStoreActions.selectSwapContractInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(SwapContractWizardStoreActions.selectAssetTobeCancelledStep, (state, { assetTobeCancelledStep }) => ({
    ...state,
    assetTobeCancelledStep,
  })),
  on(SwapContractWizardStoreActions.exitAllocationSwapContractWizard, (state) => ({
    ...state,
    initStep: null,
    assetTobeCancelledStep: null,
    errorMessage: null,
  })),
  on(SwapContractWizardStoreActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    assetTobeCancelledStep: null,
    errorMessage: null,
  })),
  on(SwapContractWizardStoreActions.validateSwapRequest, (state, { request }) => ({
    ...state,
    request,
  })),
  on(SwapContractWizardStoreActions.validateSwapRequestResult, (state, { response }) => ({
    ...state,
    validationResponse: response,
  })),
  on(SwapContractWizardStoreActions.validateSwapRequestFail, (state, { errorMessage }) => ({
    ...state,
    validationErrorMessage: errorMessage,
  })),
  on(SwapContractWizardStoreActions.createNewOrder, (state, { newOrder }) => ({
    ...state,
    newOrder,
  })),
  on(SwapContractWizardStoreActions.createNewOrderSuccess, (state, { response }) => ({
    ...state,
    newOrderResponse: response,
  })),
  on(SwapContractWizardStoreActions.createNewOrderFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return swapContractWizardStoreReducer(state, action);
}
