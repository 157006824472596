import { FormFieldConfigurationItem } from './form-configuration';

export enum FormFieldConfigurationKind {
  SEPARATOR = 1,
  TEXT = 2,
  SEARCH = 3,
  ENTITY_FIELD = 4,
  INPUT_FIELD = 5,
  STEP_CONTROL = 6,
  CHIPS = 7,
  MAILTEMPLATE = 8,
}

export class FormFieldConfigurationFieldKind {
  formfieldkindId: number;
  formfieldkindKind: string;
  formfieldkindName: string;
  formfieldkindEntityColumnId: number;
  formfieldkindMultiuseable: boolean;
  formfieldkindMinH: number;
  formfieldkindDefaultH: number;
  formfieldkindField?: FormFieldConfigurationItem;
}
