import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { BaseResponse } from '../models/base-response';
import { Contact, getContactRequestParams } from '../models/contact';
import { PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class ContactDataService {
  getContacts(request: PaginatedRequest): Observable<BaseResponse<Contact[]>> {
    const m = this.getContacts.name;

    const path = environment.getEndpoint(ApiPath.Contacts.CONTACTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Contact[]>>(path, {
      params: getContactRequestParams(request),
    });

    return retVal;
  }
  getContactsByIds(ids: number[]): Observable<BaseResponse<Contact[]>> {
    const m = this.getContacts.name;

    const path = environment.getEndpoint(ApiPath.Contacts.CONTACTS);
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams().set('filterIds', ids.join(','));
    const retVal = this.http.get<BaseResponse<Contact[]>>(path, {
      params,
    });

    return retVal;
  }

  getContactsSpreadsheet(request: PaginatedRequest) {
    const m = this.getContactsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Contacts.CONTACTS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: getContactRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  constructor(private http: HttpClient) {}
}
