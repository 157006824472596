import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { BillingItem, BillingitemPackageRequest } from 'src/app/models/billingitem';
import { AddServiceTopackageDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-service-topackage-wizard/activation-step/addservices-due-date-info';
import { AddServiceTopackageInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-service-topackage-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Add ServiceTopackage Information Step',
  props<{ initStep: AddServiceTopackageInitStep }>()
);

export const selectDueDatesStep = createAction(
  '[Wizard Due Date Step] Select Add ServiceTopackage Due Date Step',
  props<{ dueDatesStep: AddServiceTopackageDueDates }>()
);

export const selectServices = createAction(
  '[Wizard Billing account Step] Select Add ServiceTopackage ServicePackage Step',
  props<{ servicesStep: BillingItem[] }>()
);

export const exitAddServiceTopackageWizard = createAction('[AddServiceTopackage Wizard Page] Exit Add ServiceTopackage Wizard');

export const destroyWizard = createAction('[AddServiceTopackage Wizard Page] Destroy Wizard');

export const createNewAddServiceTopackage = createAction(
  '[Wizard Confirmation Step] Create Add ServiceTopackage Information Step',
  props<{ request: BillingitemPackageRequest }>()
);

export const createNewAddServiceTopackageSuccess = createAction(
  '[Wizard Confirmation Step] Create Add ServiceTopackage Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewAddServiceTopackageFail = createAction(
  '[Wizard Confirmation Step] Create Add ServiceTopackage Information Step Fail',
  props<{ errorMessage: string }>()
);
