import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { Entity } from '../models/entity';
import { EntityWizardResponse } from '../models/forms/entity-wizard';
import { PaginatedRequest } from '../models/paginated-request';
import {
  Report,
  ReportAutodistribution,
  ReportAutodistributionRequest,
  ReportAvailableField,
  ReportCategory,
  ReportFields,
  ReportFilters,
  ReportParams,
  ReportSettings,
  ReportSettingsRequest,
  ReportTemplate,
  ReportTemplateImage,
  getReportRequestParams,
} from '../models/report';
import { ReportStyle, ReportStyleItem } from '../models/report-style';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class ReportDataService {
  getReportsTemplate(request: PaginatedRequest, categoryId?: number, kindId?: number): Observable<BaseResponse<ReportTemplate[]>> {
    const m = this.getReportsTemplate.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_TEMPLATES);
    LogService.info(this, m, LogService.GET + path, null);
    let params = request != null ? PaginatedRequest.getBaseRequestParams(request) : new HttpParams();
    if (kindId) {
      params = params.append(ReportParams.FILTER_KIND_IDS, kindId);
    }
    if (categoryId) {
      params = params.append(ReportParams.FILTER_CATEGORY_IDS, categoryId);
    }
    const retVal = this.http.get<BaseResponse<ReportTemplate[]>>(path, { params });

    return retVal;
  }

  getReportsTemplateSpreadsheet(request: PaginatedRequest) {
    const m = this.getReportsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_TEMPLATES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getReports(request: PaginatedRequest, kindId: number): Observable<BaseResponse<Report[]>> {
    const m = this.getReports.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = getReportRequestParams(request);
    params = params.append(ReportParams.FILTER_KIND_IDS, kindId);
    const retVal = this.http.get<BaseResponse<Report[]>>(path, {
      params,
    });

    return retVal;
  }

  getFavoriteReports(kindId: number): Observable<BaseResponse<Report[]>> {
    const m = this.getFavoriteReports.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_FAVORITE);
    LogService.info(this, m, LogService.GET + path, null);

    const params = new HttpParams().set(ReportParams.FILTER_KIND_IDS, kindId);
    const retVal = this.http.get<BaseResponse<Report[]>>(path, { params });

    return retVal;
  }

  getRecentlyUsedReports(kindId: number): Observable<BaseResponse<Report[]>> {
    const m = this.getRecentlyUsedReports.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_RECENTLYUSED);
    LogService.info(this, m, LogService.GET + path, null);

    const params = new HttpParams().set(ReportParams.FILTER_KIND_IDS, kindId);
    const retVal = this.http.get<BaseResponse<Report[]>>(path, { params });

    return retVal;
  }

  getReportsSpreadsheet(request: PaginatedRequest, kindId: number) {
    const m = this.getReportsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    let params = getReportRequestParams(request);
    params = params.append(ReportParams.FILTER_KIND_IDS, kindId);
    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  // CONFIG API

  getReportsStyles(request: PaginatedRequest): Observable<BaseResponse<ReportStyle[]>> {
    const m = this.getReportsStyles.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_STYLES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<ReportStyle[]>>(path, {
      params: getReportRequestParams(request),
    });

    return retVal;
  }

  getStylePreviewData(styleId: number): Observable<BaseResponse<ReportStyleItem[]>> {
    const m = this.getReportsStyles.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_STYLES_PREVIEW(styleId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<ReportStyleItem[]>>(path);

    return retVal;
  }

  getReportCategories(request: PaginatedRequest, kindId?: number): Observable<BaseResponse<ReportCategory[]>> {
    const m = this.getReportCategories.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_CATEGORIES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = request != null ? PaginatedRequest.getBaseRequestParams(request) : new HttpParams();
    if (kindId) {
      params = params.append(ReportParams.FILTER_KIND_IDS, kindId);
    }
    const retVal = this.http.get<BaseResponse<ReportCategory[]>>(path, {
      params,
    });

    return retVal;
  }

  getReportTemplateImage(templateId: number): Observable<BaseResponse<ReportTemplateImage>> {
    const m = this.getReportTemplateImage.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_TEMPLATES_IMAGE(templateId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<ReportTemplateImage>>(path);
    return retVal;
  }

  getReportCategoriesSpreadsheet(request: PaginatedRequest) {
    const m = this.getReportCategoriesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_CATEGORIES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getReportCategoryLinks(request: PaginatedRequest, reportCategoryId: number): Observable<BaseResponse<ReportCategory[]>> {
    const m = this.getReportCategoryLinks.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_CATEGORY_LINKS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    if (reportCategoryId) {
      params = params.append(ReportParams.FILTER_CATEGORY_IDS, reportCategoryId);
    }
    const retVal = this.http.get<BaseResponse<ReportCategory[]>>(path, {
      params,
    });

    return retVal;
  }

  getReportCategoryLinksSpreadsheet(request: PaginatedRequest, reportCategoryId: number) {
    const m = this.getReportCategoryLinksSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_CATEGORY_LINKS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    if (reportCategoryId) {
      params = params.append(ReportParams.FILTER_CATEGORY_IDS, reportCategoryId);
    }
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  addCategoryReport(categoryId: number, templateIds: number[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.putReportSettings.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_ADD_CATEGORY_REPORT(categoryId));
    LogService.info(this, m, LogService.POST + path, null);
    const params = new HttpParams().set('templateIds', templateIds.join(','));
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  // DETAILS API

  // showReport(request: ReportSettingsRequest) {
  //   const m = this.showReport.name;
  //
  //   const path = environment.getEndpoint(ApiPath.Reports.REPORTS_SHOW);
  //   LogService.info(this, m, LogService.POST + path, null);
  //   LogService.info(this, m, 'Request ', request);
  //   return this.http
  //     .post(path, request, {
  //       observe: 'response',
  //       responseType: 'blob',
  //       headers: {
  //         'Access-Control-Expose-Headers': '*',
  //       },
  //     })
  //     .pipe(
  //       map((res) => {
  //         const filename = res.headers.get('Content-Disposition');
  //         const data = {
  //           file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
  //           filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
  //         };
  //         return data;
  //       })
  //     );
  // }

  startGeneratingReport(request: ReportSettingsRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.startGeneratingReport.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_GENERATION);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, 'Request ', request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);

    return retVal;
  }

  startGeneratingReportWithQueue(request: ReportSettingsRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.startGeneratingReport.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_GENERATION_QUEUE);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, 'Request ', request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);

    return retVal;
  }

  cancelGeneratingReport(generatingReportId: number): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.startGeneratingReport.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_GENERATION);
    LogService.info(this, m, LogService.DELETE + path, null);
    let params = new HttpParams().set('importId', generatingReportId.toString());
    const retVal = this.http.delete<BaseResponse<EntityWizardResponse>>(path, { params });

    return retVal;
  }

  getGeneratingReportStatus(importId: number): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.startGeneratingReport.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_GENERATION);
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams().set('importId', importId.toString());
    const retVal = this.http.get<BaseResponse<EntityWizardResponse>>(path, { params });

    return retVal;
  }

  getReportsDialog(request: PaginatedRequest): Observable<BaseResponse<ReportTemplate[]>> {
    const m = this.getReportsDialog.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_TEPLATES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<ReportTemplate[]>>(path, {
      params: getReportRequestParams(request),
    });

    return retVal;
  }

  getReportParticulars(reportId: number, templateId?: number): Observable<BaseResponse<ReportSettings>> {
    const m = this.getReportParticulars.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_PARTICULARS(reportId));
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams();
    params = templateId != null ? params.append('templateId', templateId.toString()) : null;
    const retVal = this.http.get<BaseResponse<ReportSettings>>(path, { params });

    return retVal;
  }

  putReportSettings(reportId: number, request: ReportSettingsRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.putReportSettings.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_PARTICULARS(reportId));
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, 'Request ', request);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  postReportSettings(request: ReportSettingsRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.putReportSettings.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_PARTICULARS_CREATE);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, 'Request ', request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getReportFilters(reportId: number): Observable<BaseResponse<ReportFilters>> {
    const m = this.getReportFilters.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_FILTERS(reportId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<ReportFilters>>(path);

    return retVal;
  }

  putReportFilters(reportId: number, reportFilters: ReportFilters): Observable<BaseResponse<ActionResponse>> {
    const m = this.putReportFilters.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_FILTERS(reportId));
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, 'Request ', reportFilters);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, reportFilters);

    return retVal;
  }

  getReportFields(reportId: number): Observable<BaseResponse<ReportFields[]>> {
    const m = this.getReportFields.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_FIELDS(reportId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<ReportFields[]>>(path);

    return retVal;
  }

  putReportFields(reportId: number, reportFields: ReportFields[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.putReportFields.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_FIELDS(reportId));
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, 'Request ', reportFields);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, reportFields);

    return retVal;
  }

  getReportAvailableFields(templateId: number): Observable<BaseResponse<ReportAvailableField[]>> {
    const m = this.getReportAvailableFields.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_TEMPLATE_AVAILABLE_FIELDS(templateId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<ReportAvailableField[]>>(path);

    return retVal;
  }

  getReportHideOptions(): Observable<BaseResponse<Entity[]>> {
    const m = this.getReportHideOptions.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_HIDE_OPTIONS);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<Entity[]>>(path);

    return retVal;
  }

  getReportFilterOptions(): Observable<BaseResponse<Entity[]>> {
    const m = this.getReportFilterOptions.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_FILTER_OPTIONS);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<Entity[]>>(path);

    return retVal;
  }

  getReportSortingOptions(): Observable<BaseResponse<Entity[]>> {
    const m = this.getReportSortingOptions.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_SORTING_OPTIONS);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<Entity[]>>(path);

    return retVal;
  }

  getReportAutodistribution(reportId: number): Observable<BaseResponse<ReportAutodistribution>> {
    const m = this.getReportAutodistribution.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_AUTODISTRIBUTIONS(reportId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<ReportAutodistribution>>(path);

    return retVal;
  }

  putReportAutodistribution(reportId: number, request: ReportAutodistributionRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.getReportAutodistribution.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_AUTODISTRIBUTIONS(reportId));
    LogService.info(this, m, LogService.PUT + path, null);

    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  // GRIDS API

  changeReportFavorite(reportId: number, favorite: boolean): Observable<BaseResponse<ActionResponse>> {
    const m = this.changeReportFavorite.name;

    const path = environment.getEndpoint(ApiPath.Reports.REPORTS_CHANGE_FAVORITE(reportId));
    LogService.info(this, m, LogService.PUT + path, null);
    const params = new HttpParams().set('favorited', favorite ? 'true' : 'false');
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
