import { HttpParams } from '@angular/common/http';
import { Entity } from './entity';
import { PaginatedRequest } from './paginated-request';

export class Contact extends Entity {
  contactId: number;
  contactSalutationId: number;
  contactSalutation: string;
  contactTitle: string;
  contactFirstname: string;
  contactLastname: string;
  contactPhone: string;
  contactMobile: string;
  contactEmail: string;
  contactFax: string;
  contactRemarks: string;
  deleted?: boolean;
}

export class ContactParams {
  public static FILTER_SALUTATION_IDS = 'filterSalutationIds';
  public static FILTER_BUSINESS_IDS = 'filterBusinessIds';
}

export function getContactRequestParams(request: PaginatedRequest): HttpParams {
  return PaginatedRequest.getBaseRequestParams(request);
}
