import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { AccessMode } from 'src/app/configs/access-mode';
import { Entities } from 'src/app/configs/entities';
import { EntityModalComponent } from 'src/app/core/modal/entity-modal/entity-modal.component';
import { Entity } from 'src/app/models/entity';
import { ForeignEntity, ForeignEntityRequest } from 'src/app/models/foreign-entity';
import { FormFieldDataRequest, GenericFormField, GenericMassEditRequest } from 'src/app/models/forms/form-field';
import { CoreDataService } from 'src/app/services/core-data.service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from '../../base-components/format-component';

export interface GenericMassEditDialogData {
  entities: Entity[];
}

@Component({
  selector: 'app-generic-mass-edit-dialog',
  templateUrl: './generic-mass-edit-dialog.component.html',
  styleUrls: ['./generic-mass-edit-dialog.component.scss'],
})
export class GenericMassEditDialogComponent extends FormatComponent implements OnInit {
  selectedEntities: Entity[];
  entityKind: string;
  entityKindTransation: string;
  formFields: GenericFormField[];
  dataSourceTable: MatTableDataSource<GenericFormField>;
  displayedColumnsTable: string[] = ['fieldname', 'currentvalue', 'newvalue'];

  foreignEntityOptions: ForeignEntity[];
  controls: UntypedFormArray;

  constructor(
    protected coreDataService: CoreDataService,
    protected messageNotifierService: MessageNotifierService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<GenericMassEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericMassEditDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedEntities = this.data.entities;
    this.entityKind = this.data.entities[0].entityKind;
    this.entityKindTransation = this.translate.instant(this.entityKind);
    this.dataSourceTable = new MatTableDataSource<GenericFormField>();
    this.subscribe(
      this.coreDataService.getMassEditForms(
        this.entityKind,
        this.selectedEntities.map((e) => e.entityId)
      ),
      ({ data }) => {
        this.formFields = JSON.parse(JSON.stringify(data));
        this.dataSourceTable.data = data;
        this.initForm();
      }
    );
  }

  getControl(formIndex: number, fieldName: string) {
    return this.controls.at(formIndex).get(fieldName) as UntypedFormControl;
  }

  private initForm() {
    if (this.dataSourceTable && this.dataSourceTable.data) {
      const toGroups = this.dataSourceTable.data.map((row) => {
        return new UntypedFormGroup(
          {
            formfieldEntityValue: new UntypedFormControl(null),
            formfieldEntityText: new UntypedFormControl(null),
            formfieldEntityType: new UntypedFormControl(row.formfieldEntityType),
            // Utility form field
            filterStringOptionValue: new UntypedFormControl(null),
          },
          { updateOn: 'change' }
        );
      });
      this.controls = new UntypedFormArray(toGroups);
    }
  }

  clearField(index: number, field: string) {
    // console.log('resetField', { index, field });
    this.getControl(index, field).setValue(null);
  }

  onChangeBoolean(index: number, $event: MatSlideToggleChange) {
    const boolean = $event.checked ? 1 : 0;
    this.getControl(index, 'formfieldEntityValue').setValue(boolean);
    if (this.dataSourceTable.filteredData[index]['formfieldEntityValue'] !== boolean) {
      this.getControl(index, 'formfieldEntityValue').markAsDirty();
    }
    this.dataSourceTable.filteredData[index]['formfieldEntityValue'] = boolean;
  }

  loadDefaultOptions(index: number, row: GenericFormField) {
    document.body.classList.add('waiting-mouse-cursor');
    const foreignEntityKind = row.formfieldEntityKind;
    let apiPath = `api/entities/${foreignEntityKind}?accessmode=${AccessMode.link}`;
    const request: ForeignEntityRequest = {
      ...new ForeignEntityRequest(),
      pageSize: 10,
      orderBy: 'entityName',
      filterEntityKind: foreignEntityKind === Entities.TYPES && row.formfieldEntityPrecision != null ? Entities.TYPES_KIND : null,
      filterEntityId: row.formfieldEntityPrecision,
    };
    this.subscribe(this.coreDataService.getForeignEntity(apiPath, request), ({ data }) => {
      if (
        this.getControl(index, 'filterStringOptionValue').value == null ||
        this.getControl(index, 'filterStringOptionValue').value === ''
      ) {
        this.foreignEntityOptions = data;
      } else {
        this.foreignEntityOptions = [
          {
            ...new ForeignEntity(),
            entityId: this.getControl(index, 'formfieldEntityValue').value,
            entityName: this.getControl(index, 'formfieldEntityText').value,
          },
          ...data.filter((d) => d.entityId !== this.getControl(index, 'formfieldEntityValue').value),
        ];
      }
      document.body.classList.remove('waiting-mouse-cursor');
    });
  }

  checkSelectedForeignOption(index: number) {
    const optionValue = this.getControl(index, 'filterStringOptionValue').value;
    if (!this.foreignEntityOptions.find((opt) => opt.entityId === optionValue)) {
      this.getControl(index, 'filterStringOptionValue').setValue(null);
      this.getControl(index, 'formfieldEntityValue').setValue(null);
    }
  }

  onForeignOptionSelectionChanged(entityName: string, index: number, dialogEntity?: ForeignEntity) {
    const entity = dialogEntity ? dialogEntity : this.foreignEntityOptions.find((fe) => fe.entityName === entityName);
    this.getControl(index, 'formfieldEntityValue').setValue(entity.entityId);
    this.getControl(index, 'formfieldEntityText').setValue(entity.entityName);
    if (this.dataSourceTable.filteredData[index]['formfieldEntityValue'] !== entity.entityId) {
      this.getControl(index, 'formfieldEntityValue').markAsDirty();
    }
    this.dataSourceTable.filteredData[index]['formfieldEntityValue'] = entity.entityId;
    this.dataSourceTable.filteredData[index]['formfieldEntityText'] = entity.entityName;
  }

  openForeignDialog(index: number, row: GenericFormField) {
    const foreignEntityKind = row.formfieldEntityKind;
    this.dialog
      .open(EntityModalComponent, {
        width: '60vw',
        minHeight: '60vh',
        data: {
          labelName: row.formfieldEntityKind,
          kind: row.formfieldEntityKind,
          linkableFlag: true,
          filterEntityKind: foreignEntityKind === Entities.TYPES && row.formfieldEntityPrecision != null ? Entities.TYPES_KIND : null,
          filterEntityId: row.formfieldEntityPrecision,
        },
      })
      .afterClosed()
      .subscribe((entity) => {
        if (entity) {
          this.foreignEntityOptions = [entity as ForeignEntity];
          this.onForeignOptionSelectionChanged(null, index, entity);
        }
      });
  }

  getFilteredOptions(index: number, row: GenericFormField) {
    const optionValue = this.getControl(index, 'filterStringOptionValue').value as string;
    return optionValue
      ? row.formfieldEntityOptions.filter((kv) => kv.value.toLowerCase().includes(optionValue.toLowerCase()))
      : row.formfieldEntityOptions;
  }

  onOptionSelectionChanged($event, index: number, row: GenericFormField) {
    const selectedKeyvalue = row.formfieldEntityOptions.find((kv) => kv.value === $event.option.value);
    this.getControl(index, 'filterStringOptionValue').setValue(selectedKeyvalue.value);
    this.getControl(index, 'formfieldEntityValue').setValue(selectedKeyvalue.key);
    if ($event && this.dataSourceTable.filteredData[index]['formfieldEntityValue'] !== this.castToInt(selectedKeyvalue.key)) {
      this.getControl(index, 'formfieldEntityValue').markAsDirty();
    }
    this.dataSourceTable.filteredData[index]['formfieldEntityValue'] = this.castToInt(selectedKeyvalue.key);
  }

  checkSelectedOption(index: number, row: GenericFormField) {
    const optionValue = this.getControl(index, 'filterStringOptionValue').value as string;
    if (!row.formfieldEntityOptions.find((kv) => kv.key === optionValue || kv.value === optionValue)) {
      this.getControl(index, 'filterStringOptionValue').setValue(null);
      this.getControl(index, 'formfieldEntityValue').setValue(null);
    }
  }

  updateDate($event, index: number, field: string) {
    const control = this.getControl(index, field);

    if (!this.isSameDay($event, this.dataSourceTable.filteredData[index][field])) {
      control.markAsDirty();
    }
    this.dataSourceTable.filteredData[index][field] = $event ? this.dateFormRequest($event) : null; // When enter is pressed, value is passed by event emitter
    if ($event) {
      control.setValue($event);
    }
    control.updateValueAndValidity();
  }

  updateDatetime($event, index: number, field: string) {
    const control = this.getControl(index, field);

    if (!this.isSameDatetime($event, this.dataSourceTable.filteredData[index][field])) {
      control.markAsDirty();
    }
    this.dataSourceTable.filteredData[index][field] = $event ? this.datetimeCastUTC($event) : null; // When enter is pressed, value is passed by event emitter
    if ($event) {
      control.setValue($event);
    }
    control.updateValueAndValidity();
  }

  updateTime($event, index: number, field: string) {
    const control = this.getControl(index, field);

    if (!this.isSameDatetime($event.target.value, this.dataSourceTable.filteredData[index][field])) {
      control.markAsDirty();
    }
    this.dataSourceTable.filteredData[index][field] = $event.target.value
      ? this.timeCast($event.target.value)
      : this.timeCast(control.value); // When enter is pressed, value is passed by event emitter
    if ($event.target.value) {
      control.setValue($event.target.value);
    }
    control.updateValueAndValidity();
  }

  updateFloatField(index: number, field: string, row: GenericFormField) {
    const value = this.getControl(index, field).value;
    if (value !== '') {
      if (!this.checkDecimalFormat(value, row.formfieldEntityScale, row.formfieldEntityPrecision)) {
        this.resetField(index, field);
      } else {
        this.updateField(null, index, field);
      }
    }
  }

  updateField($event, index: number, field: string) {
    // console.log('updateField', { $event, index, field });
    const control = this.getControl(index, field);

    if ($event && this.dataSourceTable.filteredData[index][field] !== $event) {
      control.markAsDirty();
    }
    this.dataSourceTable.filteredData[index][field] = $event ? $event : control.value; // When enter is pressed, value is passed by event emitter
    if ($event) {
      control.setValue($event);
    }
    control.updateValueAndValidity();
  }

  resetField(index: number, field: string) {
    // console.log('resetField', { index, field });
    const originalValue = this.dataSourceTable.filteredData[index][field];
    this.getControl(index, field).setValue(originalValue);
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    const request: GenericMassEditRequest = {
      entityIds: this.data.entities.map((e) => e.entityId),
      fields: this.dataSourceTable.data
        .filter(
          (d, index) =>
            this.getControl(index, 'formfieldEntityValue').value != null || this.getControl(index, 'formfieldEntityText').value != null
        )
        .map((d) => {
          return {
            ...new FormFieldDataRequest(d),
          };
        }),
    };
    this.subscribe(this.coreDataService.putMassEditForms(this.entityKind, request), ({ data }) => {
      if (data) {
        if (data.state) {
          this.messageNotifierService.showSuccessMessage(_('toastr_records_successully_modified'));
          this.dialogRef.close(true);
        } else {
          this.messageNotifierService.showWarningMessage(data.error);
        }
      } else {
        this.messageNotifierService.showWarningMessage(_('toastr_no_data'));
      }
    });
  }

  private checkDecimalFormat(value: any, scale: number, precision: number): boolean {
    const splitted = value.split('.');
    if (splitted.length > 2) {
      // Too much decimal dots
      this.messageNotifierService.showWarningMessage(_('decimal_error_decimal_format'));
      return false;
    }
    const nOfDecimalDigits = splitted[1] ? splitted[1].length : 0;
    if (nOfDecimalDigits > scale) {
      // Too much decimal digits
      this.messageNotifierService.showWarningMessage(_('decimal_error_decimal_digit'));
      return false;
    }
    if (splitted[0].length + nOfDecimalDigits > precision) {
      // Number is bigger than valid precision
      this.messageNotifierService.showWarningMessage(_('decimal_error_precision'));
      return false;
    }
    return true;
  }
}
