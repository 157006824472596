import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';

@Injectable()
export class AllocateConsumerWizardEffects {
  // loadAllocateConsumerWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(AllocateConsumerWizardActions.selectInitStep),
  //       tap(() => this.router.navigateByUrl('/pages/allocation-wizards/order'))
  //     ),
  //   { dispatch: false }
  // );
  // exitAllocateConsumerAllocationWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(AllocateConsumerWizardActions.exitAllocationAllocateConsumerWizard),
  //       tap(() => this.router.navigateByUrl('/pages/consumers'))
  //     ),
  //   { dispatch: false }
  // );

  // newAllocateConsumer$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AllocateConsumerWizardActions.createNewAllocateConsumer),
  //     concatMap(({ newAllocateConsumer }) =>
  //       this.productWizardService.addProductServices(newAllocateConsumer).pipe(
  //         map((response) => {
  //           if (response) {
  //             return AllocateConsumerWizardActions.createNewAllocateConsumerSuccess({
  //               response: response.data,
  //             });
  //           } else {
  //             throwError('No data received');
  //           }
  //         }),
  //         catchError((error) => {
  //           let errorMessage = '';
  //           if (error instanceof HttpErrorResponse) {
  //             errorMessage = error.error.errorKey;
  //           } else {
  //             errorMessage = error;
  //           }
  //           return of(AllocateConsumerWizardActions.createNewAllocateConsumerFail({ errorMessage }));
  //         })
  //       )
  //     )
  //   )
  // );

  constructor(private actions$: Actions, private router: Router) {}
}
