import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { PaginatedRequest } from '../models/paginated-request';
import { Product } from '../models/product';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class ProductDataService {
  deleteServiceProductForm(serviceId: number, formId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.deleteServiceProductForm.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_PRODUCTS_CONFIGURATION_FORM(serviceId, formId));
    LogService.info(this, m, LogService.DELETE + path, null);
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path);

    return retVal;
  }

  copyServiceProductConfigurationFrom(serviceFromId: number, productToId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.copyServiceProductConfigurationFrom.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_PRODUCTS_CONFIGURATION_COPY(serviceFromId, productToId));
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  getProducts(request: PaginatedRequest): Observable<BaseResponse<Product[]>> {
    const m = this.getProducts.name;

    const path = environment.getEndpoint(ApiPath.Products.PRODUCTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Product[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
