import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import * as saveAs from 'file-saver';
import { Observable } from 'rxjs';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entities } from 'src/app/configs/entities';
import { Entity } from 'src/app/models/entity';
import { FileGridNode } from 'src/app/models/file-grid-node';
import { RootStoreState } from 'src/app/root-store';
import { FilesService } from 'src/app/services/files.data.services';
import { GridBaseComponent } from 'src/app/shared/base-components/grid-base-component';
import { FileTabType } from 'src/app/shared/files/file-tab/file-tab-type';
import { TreeEntity } from 'src/app/shared/files/file-tree/file-tree.component';

@Component({
  selector: 'app-files-multiselection-table',
  templateUrl: './files-multiselection-table.component.html',
  styleUrls: ['./files-multiselection-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilesMultiselectionTableComponent extends GridBaseComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() tabType: FileTabType;
  @Input() treeEvent: Observable<TreeEntity>;
  @Input() isMultiselect: boolean;
  @Output() fileSelected = new EventEmitter<FileGridNode[]>();
  selectedTreeNode: TreeEntity;

  // subdirectory
  subdirectoryChecked = false;

  constructor(private filesService: FilesService, protected store: Store<RootStoreState.State>, protected cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  gbOnInit() {
    if (this.tabType === null || this.tabType === undefined) {
      throw new TypeError('The input ‘tabType’ is required');
    }
    this.subscribe(this.treeEvent, (entity) => {
      this.selectedTreeNode = entity;
      this.initRequest();
      this.selection.clear();
      this.gbLoadEntitiesData();
    });
    this.subscribe(this.selection.changed, () => {
      this.fileSelected.emit(this.selection.selected as FileGridNode[]);
    });
  }

  get FileTabType() {
    return FileTabType;
  }

  isSelected(row: FileGridNode) {
    return this.selection && this.selection.selected.find((r) => r.entityId === row.entityId);
  }

  /* GRID COMPONENT OVERRIDED METHOD */

  async gbAfterViewInitTable() {}

  gbGetDisplayColumnTable() {
    return this.isMultiselect
      ? ['select', 'filenodeName', 'filenodeType', 'filenodeSize', 'filenodeUploaded', 'filenodeUploadedby']
      : ['radio', 'filenodeName', 'filenodeType', 'filenodeSize', 'filenodeUploaded', 'filenodeUploadedby'];
  }

  gbGetInitialOrderBy() {
    return 'filenodeName';
  }

  onEntitySelected(row: FileGridNode) {
    this.selectedEntity = row;
    this.fileSelected.emit([row]);
  }

  getLoadApiEntitiesPath(): string {
    switch (this.tabType) {
      case FileTabType.generic:
        return ApiPath.Files.ENTITY_FILES_GRID(this.selectedTreeNode.entityKind, this.selectedTreeNode.entityId);
      case FileTabType.company:
      case FileTabType.user:
        return ApiPath.Files.FILES_GRID(this.tabType.key, this.selectedTreeNode.entityId, this.subdirectoryChecked);
    }
  }

  getUploadApiEntitiesPath(): string {
    switch (this.tabType) {
      case FileTabType.generic:
        return ApiPath.Files.ENTITY_FILES_GRID(this.selectedTreeNode.entityKind, this.selectedTreeNode.entityId);
      case FileTabType.company:
      case FileTabType.user:
        return ApiPath.Files.FILES_UPLOAD(this.tabType.key, this.selectedTreeNode.entityId);
    }
  }

  getLinkApiPath(fileId: number): string {
    switch (this.tabType) {
      case FileTabType.generic:
        return ApiPath.Files.ENTITY_FILES_LINK(this.selectedTreeNode.entityKind, this.selectedTreeNode.entityId, fileId);
      case FileTabType.company:
      case FileTabType.user:
        return ApiPath.Files.FILES_LINK(this.tabType.key, this.selectedTreeNode.entityId, fileId);
    }
  }

  gbLoadEntitiesData() {
    this.isLoading = true;
    this.dataSourceTable = new MatTableDataSource();
    this.noEntityData = false;
    if (this.selectedTreeNode) {
      if (this.gridLoadSubscription != null) {
        this.gridLoadSubscription.unsubscribe();
      }
      this.gridLoadSubscription = this.subscribe(
        this.filesService.getFilesList(this.getLoadApiEntitiesPath(), this.request),
        (response) => {
          this.lastUsedFilters = response.filters;
          if (response.data) {
            this.dataSourceTable.data = response.data as Entity[];
            this.pageTotalElements = response.data[0].entityCount;
          } else {
            this.pageTotalElements = 0;
            this.noEntityData = true;
          }
        },
        (error) => {
          /* HTTP Errors are managed on ServerErrorInterceptor */
        },
        () => (this.isLoading = false)
      );
    }
  }

  downloadFile(file: FileGridNode) {
    this.subscribe(this.filesService.downloadFile(Entities.FILE, file.filenodeFileid), (blob) => {
      console.log(blob.type);
      if (blob.type === 'text/plain' || blob.type.match(/image\/\w+/)) {
        const fileURL = URL.createObjectURL(blob);

        window.open(fileURL, '_blank');
      } else {
        saveAs(blob, file.filenodeName); // FileSaver;
      }
    });
  }
}
