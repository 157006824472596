<div class="entity-sidenav-container multiselection-container">
  <div id="license-table" class="entity-table">
    <div class="table-wrapper">
      <div class="table-search-paginate" [ngClass]="!enableFilter ? 'hidden-filters' : ''">
        <div class="table-search-paginate-left-wrapper">
          <mat-form-field appearance="outline" class="reset-outline-hint filter-field search">
            <button *ngIf="filter" mat-icon-button matSuffix class="small-icon" (click)="clearSearchFilter(); $event.stopPropagation()">
              <mat-icon>clear</mat-icon>
            </button>
            <mat-autocomplete
              (opened)="matAutocompletPanelOpened()"
              (closed)="matAutocompletPanelClosed()"
              #autoLastStringOption="matAutocomplete"
              (optionSelected)="applyFilterTable($event.option.value)"
            >
              <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
                {{ 'label_recent_searches' | translate }}
              </mat-option>
              <mat-option class="form-field-option" *ngFor="let opt of lastUsedFilters" [value]="opt" [class.mat-selected]="filter === opt">
                {{ opt }}
              </mat-option>
            </mat-autocomplete>
            <input
              #searchInputField
              #searchInputAutocompleteTrigger="matAutocompleteTrigger"
              (input)="searchInputAutocompleteTrigger.closePanel()"
              matInput
              [(ngModel)]="filter"
              [matAutocomplete]="autoLastStringOption"
              (debounceFilterInput)="applyFilterTable($event)"
              [debounceTime]="600"
              maxlength="1500"
              placeholder="{{ 'license_table_filter_placeholder' | translate }}"
            />
          </mat-form-field>
          <ng-container *ngIf="searchFilterrules">
            <span style="padding-right: 0.5rem"></span>
            <div class="search-filterrules">
              <mat-button-toggle-group
                class="topics"
                #kindButtonGroup="matButtonToggleGroup"
                [multiple]="true"
                [value]="[selectedFilteruleId]"
                (change)="setFilterRule($event.value)"
              >
                <mat-button-toggle [value]="1">
                  <span>{{ 'label_exact_match' | translate }}</span>
                </mat-button-toggle>
                <mat-button-toggle [value]="2">
                  <span>{{ 'label_begin_with' | translate }}</span>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </ng-container>
        </div>
        <div class="table-search-paginate-right">
          <ng-container *ngIf="showFilters">
            <button mat-button color="primary" (click)="enableFilter = !enableFilter">
              {{ enableFilter ? ('label_hide_filters' | translate) : ('label_more_filters' | translate) }}
            </button>
            <button mat-button color="warn" (click)="clearFilters()" *ngIf="isFiltersSetted()">
              {{ 'label_reset_filters' | translate }}
            </button>
          </ng-container>
        </div>
      </div>
      <div class="table-filters" [ngClass]="enableFilter ? 'visible' : 'hidden'">
        <generic-grid-filter-panel
          [clearFilter]="clearFilterSubject.asObservable()"
          [filtersMapSubj]="filtersMapSubject.asObservable()"
          [filterName]="UserlicenseParams.FILTER_ORGANISATION_IDS"
          [filterApiPath]="ApiPath.UserLicences.Filters.ORGANISATIONS"
          filterTitle="{{ 'filter_organisation' | translate }}"
          (filterIdsEvEm)="setFiltersIds($event, UserlicenseParams.FILTER_ORGANISATION_IDS)"
        ></generic-grid-filter-panel>
        <generic-grid-filter-panel
          [clearFilter]="clearFilterSubject.asObservable()"
          [filtersMapSubj]="filtersMapSubject.asObservable()"
          [filterName]="UserlicenseParams.FILTER_COSTCENTRE_IDS"
          [filterApiPath]="ApiPath.UserLicences.Filters.COSTCENTRES"
          filterTitle="{{ 'filter_costcentre' | translate }}"
          (filterIdsEvEm)="setFiltersIds($event, UserlicenseParams.FILTER_COSTCENTRE_IDS)"
        ></generic-grid-filter-panel>
        <generic-grid-filter-panel
          [clearFilter]="clearFilterSubject.asObservable()"
          [filtersMapSubj]="filtersMapSubject.asObservable()"
          [filterName]="UserlicenseParams.FILTER_LOCATION_IDS"
          [filterApiPath]="ApiPath.UserLicences.Filters.LOCATIONS"
          filterTitle="{{ 'filter_location' | translate }}"
          (filterIdsEvEm)="setFiltersIds($event, UserlicenseParams.FILTER_LOCATION_IDS)"
        ></generic-grid-filter-panel>
        <generic-grid-filter-panel
          [clearFilter]="clearFilterSubject.asObservable()"
          [filtersMapSubj]="filtersMapSubject.asObservable()"
          [filterName]="UserlicenseParams.FILTER_PRODUCTFAMILY_IDS"
          [filterApiPath]="ApiPath.UserLicences.Filters.PRODUCT_FAMILIES"
          filterTitle="{{ 'filter_product_family' | translate }}"
          (filterIdsEvEm)="setFiltersIds($event, UserlicenseParams.FILTER_PRODUCTFAMILY_IDS)"
        ></generic-grid-filter-panel>
        <generic-grid-filter-panel
          [clearFilter]="clearFilterSubject.asObservable()"
          [filtersMapSubj]="filtersMapSubject.asObservable()"
          [filterName]="UserlicenseParams.FILTER_SERVICE_IDS"
          [filterApiPath]="ApiPath.UserLicences.Filters.SERVICES"
          filterTitle="{{ 'filter_service' | translate }}"
          (filterIdsEvEm)="setFiltersIds($event, UserlicenseParams.FILTER_SERVICE_IDS)"
        ></generic-grid-filter-panel>
      </div>
      <div class="table-selection-container">
        <div class="table-buttons-container">
          <div class="selection-buttons">
            <button mat-raised-button color="primary" (click)="askAddAllFiltered()" [disabled]="isLoadingSelection">
              <span>{{ 'label_select_all' | translate }}</span>
            </button>
            <button
              mat-raised-button
              color="primary"
              (click)="addAllToSelection()"
              [disabled]="isLoadingSelection"
              style="margin-left: 0.5rem"
            >
              <span>{{ 'label_select_displayed' | translate }}</span>
            </button>
            <mat-progress-bar mode="indeterminate" *ngIf="isLoadingSelection"></mat-progress-bar>
            <button
              mat-raised-button
              color="primary"
              (click)="extraQuerySelectionEvEm.emit()"
              style="margin-left: auto"
              *ngIf="enableExtraQuerySelection"
            >
              <span>{{ extraQuerySelectionButtonKey | translate }}</span>
            </button>
          </div>
          <div class="table-container">
            <table
              class="table table-stripes table-truncate-text"
              mat-table
              [dataSource]="dataSourceTable"
              matSort
              #sortTable="matSort"
              [matSortActive]="gbGetInitialOrderBy()"
              [matSortDirection]="gbGetInitialSort()"
              matSortDisableClear
              cdkDropList
              cdkDropListSortingDisabled
              cdkDropListConnectedTo="generic-multigrid-multiselection-table"
              [cdkDropListData]="dataSourceTable"
            >
              <ng-container matColumnDef="selection">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                  >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? onCheckboxClicked(row) : null"
                    [checked]="selection.isSelected(row)"
                    [disabled]="isReadonly(row)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="userlicense_user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'userlicense_user' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.userlicenseUser" *matCellDef="let row">
                  {{ row.userlicenseUser }}
                </td>
              </ng-container>

              <ng-container matColumnDef="userlicense_asset">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'userlicense_asset' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.userlicenseAsset" *matCellDef="let row">
                  {{ row.userlicenseAsset }}
                </td>
              </ng-container>

              <ng-container matColumnDef="userlicense_organisation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'userlicense_organisation' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.userlicenseOrganisation" *matCellDef="let row">
                  {{ row.userlicenseOrganisation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="userlicense_costcentre">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'userlicense_costcentre' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.userlicenseCostcentre" *matCellDef="let row">
                  {{ row.userlicenseCostcentre }}
                </td>
              </ng-container>

              <ng-container matColumnDef="userlicense_product_family">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'userlicense_product_family' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.userlicenseAssetProductFamily" *matCellDef="let row">
                  {{ row.userlicenseAssetProductFamily }}
                </td>
              </ng-container>

              <ng-container matColumnDef="userlicense_asset_notice">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'userlicense_asset_notice' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="getDate(row.userlicenseAssetNotice)" *matCellDef="let row">
                  {{ getDate(row.userlicenseAssetNotice) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="userlicense_asset_rollover">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'userlicense_asset_rollover' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="getDate(row.userlicenseAssetRollover)" *matCellDef="let row">
                  {{ getDate(row.userlicenseAssetRollover) }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsTable"
                (click)="onRowClicked(row)"
                [ngClass]="selectedEntity.entityId === row.entityId ? 'selected-row' : ''"
                cdkDrag
                [cdkDragData]="row"
              >
                <!-- DRAG&DROP PREVIEW -->
                <mat-card class="drag-preview" *cdkDragPreview>
                  <mat-card-content class="card-content">
                    <span class="card-item-name"> {{ row.entityName }}</span>
                  </mat-card-content>
                </mat-card>
                <!-- DRAG&DROP PREVIEW -->
              </tr>
            </table>
            <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
              <mat-toolbar-row *ngIf="isLoading" class="spinner">
                <mat-spinner diameter="50"></mat-spinner>
              </mat-toolbar-row>
              <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
                <span>{{ 'license_table_error_nodata' | translate }}</span>
              </mat-toolbar-row>
            </mat-toolbar>
            <mat-toolbar class="table-footer-toolbar">
              <mat-toolbar-row>
                <mat-paginator
                  [disabled]="isLoading"
                  #paginatorTable
                  [pageSizeOptions]="pageSizeList"
                  [length]="pageTotalElements"
                  class="reset-paginator"
                ></mat-paginator>
              </mat-toolbar-row>
            </mat-toolbar>
          </div>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <!-- SELECTION GRID -->
        <div class="filters-selections table-wrapper">
          <div class="filters-selections-title">
            <span> {{ 'report_saved_filters_selection' | translate }} ({{ selectionDataSourceTable.filteredData.length }})</span>
            <button mat-raised-button color="primary" (click)="askRemoveAllFiltered()" [disabled]="isLoadingSelection">
              <mat-icon>delete_outline</mat-icon>
              <span>{{ 'label_remove_all' | translate }}</span>
            </button>
          </div>
          <div
            id="generic-multigrid-multiselection-table"
            cdkDropList
            #genericMultigridMultiselectionTable="cdkDropList"
            [cdkDropListData]="selectionDataSourceTable"
            (cdkDropListDropped)="dropEntity($event)"
          >
            <div class="table-container">
              <table class="table table-stripes table-truncate-text" mat-table [dataSource]="selectionDataSourceTable">
                <ng-container matColumnDef="entity_name">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'entity_name' | translate }}
                  </th>
                  <td mat-cell showIfTruncated [matTooltip]="row.entityName" *matCellDef="let row">
                    {{ row.entityName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="entity_remove">
                  <th mat-header-cell *matHeaderCellDef class="icondelete-header"></th>
                  <td mat-cell class="icondelete-cell" *matCellDef="let row">
                    <mat-icon
                      *ngIf="!row.isReadonly"
                      (click)="removeEntityFromSelection(row)"
                      style="color: red"
                      fontSet="fas"
                      fontIcon="fa-times"
                    ></mat-icon>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="selectionDisplayedColumnsTable; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: selectionDisplayedColumnsTable"></tr>
              </table>
              <mat-toolbar
                class="table-footer-toolbar"
                *ngIf="
                  selectionDataSourceTable && selectionDataSourceTable.filteredData && selectionDataSourceTable.filteredData.length === 0
                "
              >
                <mat-toolbar-row
                  *ngIf="
                    selectionDataSourceTable && selectionDataSourceTable.filteredData && selectionDataSourceTable.filteredData.length === 0
                  "
                  class="no-data-message"
                >
                  <span>{{ 'report_filters_table_error_nodata' | translate }}</span>
                </mat-toolbar-row>
              </mat-toolbar>
            </div>
          </div>
        </div>
      </div>
      <!-- SELECTION GRID -->
    </div>
  </div>
</div>
