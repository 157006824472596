import { ProgressMessage } from '../wizard-import-response';

export class FormWizard {
  wizardId: number;
  firstStep: number;
  error: string;
  steps: FormWizardStep[];
}

export class FormWizardStep {
  formstepId: number;
  formstepStep: number;
  formstepTextname: string;
  formstepName: string;
}

export class FormWizardResponse {
  state: boolean;
  id: number;
  progress?: number;
  nextStep: string;
  error: string;
  errorMessage: string;
  progressMessages: ProgressMessage[];
  data?: FormWizardData[];
}

export class FormWizardData {
  wizarddataId: number;
  wizarddataName: string;
  wizarddataKind: string;
  wizarddataLink: string;
  wizarddataError: string;
  wizarddataHint: string;
  wizarddataStatus: number;
}

export enum FormWizardDataStatus {
  success = 0,
  warning,
  error,
}
