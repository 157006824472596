import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { TerminateMultiAssetAssetStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/terminate-multi-asset-wizard/terminate-multi-asset-asset-step/terminate-multi-asset-asset-step-info';
import { TerminateMultiAssetCancelDetailsData } from 'src/app/shared-components/allocation-wizard-modal-dialogs/terminate-multi-asset-wizard/terminate-multi-asset-cancel-details-step/terminate-multi-asset-cancel-details-data';
import * as fromTerminateMultiAssetWizard from './reducer';
import { State } from './state';

export const selectTerminateMultiAssetWizardState = createFeatureSelector<State>(
  fromTerminateMultiAssetWizard.terminateMultiAssetWizardFeatureKey
);

export const selectAssetStep = createSelector(
  selectTerminateMultiAssetWizardState,
  (state: State): TerminateMultiAssetAssetStep => state.assetStep
);

export const selectCancelDetailsStep = createSelector(
  selectTerminateMultiAssetWizardState,
  (state: State): TerminateMultiAssetCancelDetailsData[] => state.cancelDetailsStep
);

export const selectTerminateMultiAssetState = createSelector(
  selectTerminateMultiAssetWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectTerminateMultiAssetWizardState, (state: State): string => state.errorMessage);
