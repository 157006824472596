import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
export const Days = {
  SUN: _('cron_editor_label_sunday'),
  MON: _('cron_editor_label_monday'),
  TUE: _('cron_editor_label_tuesday'),
  WED: _('cron_editor_label_wednesday'),
  THU: _('cron_editor_label_thursday'),
  FRI: _('cron_editor_label_friday'),
  SAT: _('cron_editor_label_saturday'),
};

export const Months = {
  1: _('cron_editor_label_month_january'),
  2: _('cron_editor_label_month_february'),
  3: _('cron_editor_label_month_march'),
  4: _('cron_editor_label_month_april'),
  5: _('cron_editor_label_month_may'),
  6: _('cron_editor_label_month_june'),
  7: _('cron_editor_label_month_july'),
  8: _('cron_editor_label_month_august'),
  9: _('cron_editor_label_month_september'),
  10: _('cron_editor_label_month_october'),
  11: _('cron_editor_label_month_november'),
  12: _('cron_editor_label_month_december'),
};
