import { ActionResponse } from 'src/app/models/action-response';
import { Desk, DeskCostcentreChangeRequest } from 'src/app/models/desk';
import { ChangeCostcentreAllocationsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/change-costcentre-allocations-wizard/activation-step/change-costcentre-allocations-due-date-info';
import { ChangeCostcentreAllocationsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/change-costcentre-allocations-wizard/init-step/init-step';

export interface State {
  initStep: ChangeCostcentreAllocationsInitStep;
  dueDatesStep: ChangeCostcentreAllocationsDueDates;
  allocationStep: Desk[];
  request: DeskCostcentreChangeRequest;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  dueDatesStep: null,
  allocationStep: null,
  request: null,
  response: null,
  errorMessage: null,
};
