import { KeyValue } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import Base64UploaderPlugin from 'src/@ckeditor/Base64Upload';
import {
  FormFieldInputfieldBooleanDisplayOption,
  FormFieldInputfieldOptionDisplayOption,
} from 'src/app/models/forms/form-configuration-option';
import { BaseFormFieldConfigComponent } from '../base-form-field-config/base-form-field-config-component';

@Component({
  selector: 'app-form-field-config-inputfield',
  templateUrl: './form-field-config-inputfield.component.html',
  styleUrls: ['./form-field-config-inputfield.component.scss'],
})
export class FormFieldConfigInputfieldComponent extends BaseFormFieldConfigComponent {
  @ViewChild('ckeditor') ckeditor: CKEditorComponent;
  @Input() rowHeight: number;

  public Editor = ClassicEditor;
  public config;

  constructor(private sanitizer: DomSanitizer) {
    super();
    this.config = {
      heading: {
        options: [
          { model: 'paragraph', title: this.translate.instant(_('ckeditor_paragraph')), class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: this.translate.instant(_('ckeditor_heading1')), class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: this.translate.instant(_('ckeditor_heading2')), class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: this.translate.instant(_('ckeditor_heading3')), class: 'ck-heading_heading3' },
        ],
      },
      language: this.translate.currentLang,
      extraPlugins: [Base64UploaderPlugin],
      link: { addTargetToExternalLinks: true },
    };
  }

  get FormFieldInputfieldBooleanDisplayOption() {
    return FormFieldInputfieldBooleanDisplayOption;
  }
  get FormFieldInputfieldOptionDisplayOption() {
    return FormFieldInputfieldOptionDisplayOption;
  }

  get InputFieldOptionOptions(): KeyValue<string, string>[] {
    return this.formFieldData.formfieldEntityOption
      ? this.formFieldData.options.formfieldoptionInputOptionOptions.find((kv) => kv.key === this.formFieldData.formfieldEntityOption).data
      : null;
  }

  readOnlyCheckbox() {
    return false;
  }

  // Call this method in the image source, it will sanitize it.
  transform(base64Image: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }

  onCkeditorReady(): void {
    // WORKAROUND - to fix ngModel binding does not set initial value https://github.com/ckeditor/ckeditor5-angular/issues/265
    if (this.ckeditor && this.formFieldData.formfieldEntityText) {
      setTimeout(() => {
        // this.ckeditor.editorInstance?.setData(this.formFieldData.formfieldEntityText);
      }, 0);
    }
  }
}
