import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { BaseResponse } from '../models/base-response';
import { Note } from '../models/note';
import { PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class NoteDataService {
  getNotes(entityKind: string, entityId: number, request: PaginatedRequest): Observable<BaseResponse<Note[]>> {
    const m = this.getNotes.name;

    const path = environment.getEndpoint(ApiPath.Note.NOTES(entityKind, entityId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    const retVal = this.http.get<BaseResponse<Note[]>>(path, { params: PaginatedRequest.getBaseRequestParams(request) });

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
