<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span>{{ 'mail_preview_title' | translate }}</span>
  <div class="buttons-container">
    <button (click)="onClose()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content class="mail-preview-dialog-content">
  <div class="mail-preview-container" [innerHTML]="sanitizeHtml(mailPreview)"></div>
</div>
