import { ActionResponse } from 'src/app/models/action-response';
import { CancelLicenseServicesRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/confirmation-step/cancel-license-services-request';
import { TerminateMultiAssetAssetStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/terminate-multi-asset-wizard/terminate-multi-asset-asset-step/terminate-multi-asset-asset-step-info';
import { TerminateMultiAssetCancelDetailsData } from 'src/app/shared-components/allocation-wizard-modal-dialogs/terminate-multi-asset-wizard/terminate-multi-asset-cancel-details-step/terminate-multi-asset-cancel-details-data';
export interface State {
  assetStep: TerminateMultiAssetAssetStep;
  cancelDetailsStep: TerminateMultiAssetCancelDetailsData[];
  request: CancelLicenseServicesRequest;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  assetStep: null,
  cancelDetailsStep: null,
  request: null,
  response: null,
  errorMessage: null,
};
