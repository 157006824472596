import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { Index, IndexesParticulars, IndexesParticularsRequest } from '../models';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { ContractTreeNode } from '../models/contract';
import { EntityWizardResponse } from '../models/forms/entity-wizard';
import { IndexGroup } from '../models/index-group';
import { IndexPackage } from '../models/index-package';
import { IndicePackageContent } from '../models/indice-package';
import { BaseParams, PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class IndexesDataService {
  getIndexes(request: PaginatedRequest, indiceId?: number): Observable<BaseResponse<Index[]>> {
    const m = this.getIndexes.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES);
    LogService.info(this, m, LogService.GET + path, null);

    let params = PaginatedRequest.getBaseRequestParams(request);
    params = indiceId != null ? params.append('indiceId', indiceId.toString()) : params;
    const retVal = this.http.get<BaseResponse<Index[]>>(path, {
      params,
    });

    return retVal;
  }

  getIndexesSpreadsheet(request: PaginatedRequest, indexId?: number, categoryId?: number) {
    const m = this.getIndexesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_GRID_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getIndexesParticularsDefault(): Observable<BaseResponse<IndexesParticulars>> {
    const m = this.getIndexesParticularsDefault.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_PARTICULARS_DEFAULTS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<IndexesParticulars>>(path);
    return retVal;
  }

  getIndexesParticulars(vendorId: number): Observable<BaseResponse<IndexesParticulars>> {
    const m = this.getIndexesParticulars.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_PARTICULARS_BYID(vendorId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<IndexesParticulars>>(path);
    return retVal;
  }

  putIndexesParticulars(vendorId: number, request: IndexesParticularsRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.putIndexesParticulars.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_PARTICULARS_BYID(vendorId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);
    return retVal;
  }

  createIndexesParticulars(request: IndexesParticularsRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.createIndexesParticulars.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_PARTICULARS);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);
    return retVal;
  }

  getIndexPackageContents(request: PaginatedRequest, packageId?: number): Observable<BaseResponse<IndicePackageContent[]>> {
    const m = this.getIndexes.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_PACKAGES_CONTENT_GRID(packageId));
    LogService.info(this, m, LogService.GET + path, null);

    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<IndicePackageContent[]>>(path, {
      params,
    });

    return retVal;
  }

  getIndexPackageContentsSpreadsheet(request: PaginatedRequest, packageId?: number) {
    const m = this.getIndexesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_PACKAGES_CONTENT_SPREADSHEET(packageId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getIndexPackageLicenses(request: PaginatedRequest, packageId?: number): Observable<BaseResponse<Index[]>> {
    const m = this.getIndexes.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_PACKAGES_LICENSE_GRID(packageId));
    LogService.info(this, m, LogService.GET + path, null);

    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<Index[]>>(path, {
      params,
    });

    return retVal;
  }

  getIndexPackageLicensesSpreadsheet(request: PaginatedRequest, packageId?: number) {
    const m = this.getIndexesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_PACKAGES_LICENSE_SPREADSHEET(packageId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getIndexLicensePackages(indiceId: number): Observable<BaseResponse<IndexPackage[]>> {
    const m = this.getIndexPackages.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_LICENSES(indiceId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<IndexPackage[]>>(path);

    return retVal;
  }

  activationIndex(indexId: number, activate: boolean): Observable<BaseResponse<ActionResponse>> {
    const m = this.activationIndex.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_ACTIVATION(indexId));
    LogService.info(this, m, LogService.PUT + path, null);
    const params = new HttpParams().set('activated', activate ? 'true' : 'false');
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getIndexPackages(request: PaginatedRequest, indiceId?: number): Observable<BaseResponse<IndexPackage[]>> {
    const m = this.getIndexPackages.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_PACKAGES_GRID);
    LogService.info(this, m, LogService.GET + path, null);

    let params = PaginatedRequest.getBaseRequestParams(request);
    params = indiceId != null ? params.append('indiceId', indiceId.toString()) : params;
    const retVal = this.http.get<BaseResponse<IndexPackage[]>>(path, {
      params,
    });

    return retVal;
  }

  getIndexPackagesSpreadsheet(request: PaginatedRequest, indexId?: number, categoryId?: number) {
    const m = this.getIndexPackagesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_PACKAGES_GRID_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  addIndexesToIndexesPackage(packageId: number, indexIds: number[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.createIndexesParticulars.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_PACKAGES_CONTENT_INDEXES(packageId));
    LogService.info(this, m, LogService.POST + path, null);
    const params = new HttpParams().set('indexIds', indexIds.join(','));
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });
    return retVal;
  }

  addLicensesToLicensesPackage(packageId: number, billingitemIds: number[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.createIndexesParticulars.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_PACKAGES_LICENSE_GRID(packageId));
    LogService.info(this, m, LogService.POST + path, null);
    const params = new HttpParams().set('billingitemIds', billingitemIds.join(','));
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });
    return retVal;
  }

  getIndexGroups(request: PaginatedRequest): Observable<BaseResponse<IndexGroup[]>> {
    const m = this.getIndexGroups.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES);
    LogService.info(this, m, LogService.GET + path, null);

    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<IndexGroup[]>>(path, {
      params,
    });

    return retVal;
  }

  getIndexContracts(indexId: number, orderBy?: string): Observable<BaseResponse<ContractTreeNode[]>> {
    const m = this.getIndexGroups.name;

    const path = environment.getEndpoint(ApiPath.Indexes.INDEXES_CONTRACT_GRID(indexId));
    LogService.info(this, m, LogService.GET + path, null);

    let params = null;
    if (orderBy) params = new HttpParams().set(BaseParams.ORDER_BY, orderBy.toString());

    const retVal = this.http.get<BaseResponse<ContractTreeNode[]>>(path, { params });
    return retVal;
  }

  constructor(private http: HttpClient) {}
}
