import { createAction, props } from '@ngrx/store';
import { NotificationCount } from 'src/app/models/notification';

export const startPollingNotificationsCount = createAction('[Notifications] Start Polling Notifications Count');

export const storePollingNotificationsCount = createAction(
  '[Notifications] Store Polling Notifications Count',
  props<{ count: NotificationCount }>()
);

export const finishPollingNotificationsCount = createAction('[Notifications] Finish Polling Notifications Count');

export const refreshNotificationCount = createAction('[Notifications] Finish Refresh Notifications Count');

export const notificationsError = createAction('[Notifications] Error', props<{ error: any }>());
