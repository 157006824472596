<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div id="remove-records-modal" mat-dialog-container>
  <mat-toolbar color="primary" class="mat-elevation-z2">
    <mat-card-header>{{ 'entities_remove_records_dialog_confirm_title' | translate }}</mat-card-header>
  </mat-toolbar>
  <div class="confirm-message">
    <!-- TITLES -->
    <span *ngIf="isProgressing && !progressErrorMessage">
      {{ 'entities_remove_records_dialog_progress_message' | translate }}
    </span>
    <span *ngIf="!isProgressing && !progressErrorMessage">
      {{ 'entities_remove_records_dialog_confirm_message' | translate }}
    </span>
    <span *ngIf="progressErrorMessage">&nbsp;<!-- placeholder--></span>
    <!-- BUTTONS -->
    <div *ngIf="progressErrorMessage">
      <button mat-raised-button color="primary" (click)="onNo()">
        {{ 'label_close' | translate }}
      </button>
    </div>
    <div *ngIf="isProgressing && !progressErrorMessage">
      <button mat-raised-button color="primary" (click)="onCancel()">
        {{ 'label_cancel' | translate }}
      </button>
    </div>
    <div *ngIf="!isProgressing && progressSuccess && !progressErrorMessage">
      <button mat-raised-button color="primary" (click)="onYes()">
        {{ 'label_yes' | translate }}
      </button>
      <span style="padding-right: 5px"></span>
      <button mat-raised-button color="primary" (click)="onNo()">
        {{ 'label_no' | translate }}
      </button>
    </div>
  </div>
  <div class="progress-error" *ngIf="progressErrorMessage">
    <span class="error" [innerHTML]="progressErrorMessage | translate"></span>
  </div>
  <mat-progress-bar
    *ngIf="!progressErrorMessage"
    [mode]="progressSuccess ? 'determinate' : 'buffer'"
    color="primary"
    [value]="progressPercentage"
  ></mat-progress-bar>
  <div mat-dialog-content>
    <!-- TABLE -->
    <div id="remove-entities-table" *ngIf="!progressErrorMessage">
      <div class="container-fluid">
        <div class="table-wrapper table-entity-wrapper">
          <div class="table-container">
            <table class="table table-stripes table-truncate-text" mat-table [dataSource]="dataSourceTable">
              <!-- kind column -->
              <ng-container matColumnDef="entityKind">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'entity_kind' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.wizarddataKind" showIfTruncated *matCellDef="let row">
                  {{ row.wizarddataKind | translate }}
                </td>
              </ng-container>
              <!-- name column -->
              <ng-container matColumnDef="entityName">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'entity_name' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.wizarddataName" showIfTruncated *matCellDef="let row">
                  {{ row.wizarddataName }}
                </td>
              </ng-container>
              <!-- description column -->
              <ng-container matColumnDef="entityDescription">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'entity_description' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.wizarddataHint" showIfTruncated *matCellDef="let row">
                  {{ row.wizarddataHint }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="spinner-container" *ngIf="isDeleting">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
