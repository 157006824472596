import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, throwError, withLatestFrom } from 'rxjs';
import { AddBillingitemsWizardDataService } from 'src/app/services/wizards/add-billingitems-wizard.service';
import { AddServiceTopackageWizardActions } from '.';
import { selectInitStep } from './selectors';
import { State } from './state';

@Injectable()
export class AddServiceTopackageWizardEffects {
  // loadAddProductWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(AddServiceTopackageWizardActions.selectInitStep),
  //       tap(() => this.router.navigateByUrl('/pages/allocation-wizards/order'))
  //     ),
  //   { dispatch: false }
  // );
  // exitAddServiceTopackageAllocationWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(AddServiceTopackageWizardActions.exitAllocationAddServiceTopackageWizard),
  //       tap(() => this.router.navigateByUrl('/pages/consumers'))
  //     ),
  //   { dispatch: false }
  // );

  newAddServiceTopackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddServiceTopackageWizardActions.createNewAddServiceTopackage),
      withLatestFrom(this.store.select(selectInitStep)),
      concatMap(([{ request }, initStep]) =>
        this.addBillingitemsWizardService.addBillingitemsToPackage(initStep.packageId, request).pipe(
          map((response) => {
            if (response) {
              return AddServiceTopackageWizardActions.createNewAddServiceTopackageSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            let errorMessage = '';
            if (error instanceof HttpErrorResponse) {
              errorMessage = error.error.errorKey;
            } else {
              errorMessage = error;
            }
            return of(AddServiceTopackageWizardActions.createNewAddServiceTopackageFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private addBillingitemsWizardService: AddBillingitemsWizardDataService
  ) {}
}
