import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { ConsumerService } from 'src/app/models/consumerservice';
import { Entity } from 'src/app/models/entity';
import { OrderMarketDataInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/order-market-data-wizard/init-step/init-step';
import * as fromOrderWizard from './reducer';
import { State } from './state';

export const selectOrderMarketDataWizardState = createFeatureSelector<State>(fromOrderWizard.orderMarketDataWizardFeatureKey);

export const selectInitStep = createSelector(selectOrderMarketDataWizardState, (state: State): OrderMarketDataInitStep => state.initStep);

export const selectUserSelectionStep = createSelector(selectOrderMarketDataWizardState, (state: State): Entity => state.userSelectionStep);

export const selectReferenceProfileServicesStep = createSelector(
  selectOrderMarketDataWizardState,
  (state: State): ConsumerService[] => state.referenceProfileServicesStep
);

export const selectFineTuneServicesStep = createSelector(
  selectOrderMarketDataWizardState,
  (state: State): number[] => state.fineTuneServicesStep
);

// export const selectAdditionaInfoStep = createSelector(
//   selectOrderMarketDataWizardState,
//   (state: State): OrderMarketDataAdditionalInfo => state.addInfoStep
// );

export const selectNewOrderState = createSelector(
  selectOrderMarketDataWizardState,
  (state: State): ActionResponse => state.newOrderResponse
);

export const selectErrorMessage = createSelector(selectOrderMarketDataWizardState, (state: State): any => state.errorMessage);
