import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { LicenseAssetPeriodAllocation } from 'src/app/models/asset-allocation';
import { MoveSharedAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-shared-asset-wizard/init-step/init-step';
import { MoveSharedAssetMovingToStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-shared-asset-wizard/move-shared-asset-movingto-step/move-shared-asset-movingto-step';
import * as fromMoveSharedAssetWizard from './reducer';
import { State } from './state';

export const selectMoveSharedAssetWizardState = createFeatureSelector<State>(fromMoveSharedAssetWizard.moveSharedAssetWizardFeatureKey);

export const selectInitStep = createSelector(selectMoveSharedAssetWizardState, (state: State): MoveSharedAssetInitStep => state.initStep);

export const selectMovingToStep = createSelector(
  selectMoveSharedAssetWizardState,
  (state: State): MoveSharedAssetMovingToStep => state.movingTo
);

export const selectAllocationUpdateStep = createSelector(
  selectMoveSharedAssetWizardState,
  (state: State): LicenseAssetPeriodAllocation[] => state.allocationUpdate
);

export const selectMoveSharedAssetState = createSelector(
  selectMoveSharedAssetWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectMoveSharedAssetWizardState, (state: State): any => state.errorMessage);
