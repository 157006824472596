import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Store, select } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { EntityIdValue } from '../../../models/entity';

export interface DialogData {
  labelName: string;
  selected: EntityIdValue[];
  options: EntityIdValue[];
}

@Component({
  selector: 'app-options-modal',
  templateUrl: './options-modal.component.html',
  styleUrls: ['./options-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OptionsModalComponent extends FormatComponent implements OnInit, AfterViewInit {
  @ViewChild('paginatorTable') paginator: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<OptionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    protected store: Store<RootStoreState.State>,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  displayOptions: EntityIdValue[];
  selectedOption: EntityIdValue = { id: null, value: null };

  dataSourceTable: MatTableDataSource<EntityIdValue> = new MatTableDataSource<EntityIdValue>();
  displayedColumnsTable: string[] = ['select', 'optionName'];
  pageSizeList: number[] = [];
  pageSize: number;
  filter: string;
  selection = new SelectionModel<EntityIdValue>(true, []);

  ngAfterViewInit() {}

  ngOnInit() {
    this.dataSourceTable = new MatTableDataSource();
    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesPaginatonsOptions)), (pageSizeOpt) => {
      this.pageSizeList = pageSizeOpt;
      this.cdRef.detectChanges();
      if (this.paginator) {
        this.paginator.pageSize = this.pageSizeList ? this.pageSizeList[0] : 20;
      }
    });
    this.loadData();
  }

  loadData() {
    this.displayOptions = this.data.options.filter((iv1) => !this.data.selected.some((iv2) => iv1.id === iv2.id));
    this.dataSourceTable.data = this.displayOptions;
    this.dataSourceTable.paginator = this.paginator;
    if (this.dataSourceTable.paginator) {
      this.dataSourceTable.paginator.firstPage();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSelect() {
    this.dialogRef.close(this.selection.selected);
  }

  onOptionSelected(option: EntityIdValue) {
    this.selectedOption = option;
  }

  applyFilterTable(filterValue: string) {
    if (filterValue && filterValue.length >= 3) {
      // this.setFilter(filterValue);
      this.dataSourceTable.filter = filterValue.trim().toLocaleLowerCase();
    } else {
      if (!filterValue || (filterValue && filterValue.length === 0)) {
        this.dataSourceTable.filter = null;
      }
    }
  }

  resetFilter() {
    if (this.filter) {
      this.filter = '';
      this.applyFilterTable(this.filter);
    }
  }

  isOptionSelected() {
    return this.selectedOption && this.selectedOption.value !== '';
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceTable.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSourceTable.data.forEach((row) => this.selection.select(row));
  }

  onCheckboxClicked(row: EntityIdValue) {
    this.selection.toggle(row);
  }

  clearSelection() {
    // this.selectedEntitiesIds = [];
    this.selection.clear();
  }
}
