import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { BaseResponse } from '../models/base-response';
import { Costcentre, CostcentreTransition, CostcentreTreeNode } from '../models/costcentre';
import { PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class CostcentreDataService {
  getCostcentres(request: PaginatedRequest): Observable<BaseResponse<Costcentre[]>> {
    const m = this.getCostcentres.name;

    const path = environment.getEndpoint(ApiPath.Costcentres.COSTCENTRES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Costcentre[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getCostcentresSpreadsheet(request: PaginatedRequest) {
    const m = this.getCostcentresSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Costcentres.COSTCENTRES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getCostcentreNodes(request: PaginatedRequest, parentId?: number): Observable<BaseResponse<CostcentreTreeNode[]>> {
    const m = this.getCostcentreNodes.name;

    const costcentreId = parentId != null ? parentId : -1;
    const path = `${environment.getEndpoint(ApiPath.Costcentres.COSTCENTRES)}/${costcentreId}/nodes`;
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'costcentreId', costcentreId);
    const retVal = this.http.get<BaseResponse<CostcentreTreeNode[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getTransitionByCostcentreId(costcentreId: number): Observable<BaseResponse<CostcentreTransition>> {
    const m = this.getTransitionByCostcentreId.name;
    const path = environment.getEndpoint(ApiPath.Costcentres.COSTCENTRES_TRANSITION(costcentreId));
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, 'costcentreId', costcentreId);
    const retVal = this.http.get<BaseResponse<CostcentreTransition>>(path);

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
