<div class="base-form-field form-field-search" [ngClass]="[!formFieldData.formfieldLabelShow ? 'justify-center' : '']">
  <div class="label-container" *ngIf="formFieldData.formfieldLabelShow">
    <ng-container *ngIf="formFieldData.formfieldLabelDisplay">
      <span class="label" showIfTruncated matTooltip="{{ formFieldData.formfieldTextname | translate }}"
        >{{ formFieldData.formfieldTextname | translate }}
      </span>
      <ng-container *ngIf="!isViewMode">
        <span
          *ngIf="
            formFieldData.formfieldRequired === GenericFormFieldRequiredType.required && !formFieldData.isReadonlyField() && !isViewMode
          "
          [ngClass]="formFieldData.isRequiredControlValid ? 'requiredField' : 'requiredFieldInvalid'"
          >*</span
        >
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!formFieldData.formfieldLabelDisplay">
      <span class="label">&nbsp;</span>
    </ng-container>
  </div>
  <div class="value-container">
    <span class="value">
      <mat-form-field
        appearance="outline"
        class="form-field-input reset-outline-hint"
        (click)="formFieldData.isReadonlyField() ? null : loadDefaultOptions()"
        [ngClass]="!formFieldData.isRequiredControlValid && isSavePressed ? 'invalid-field' : ''"
      >
        <input
          type="text"
          matInput
          [disabled]="formFieldData.isReadonlyField() || isViewMode"
          [(ngModel)]="formFieldData.formfieldEntityText"
          (matInputAutocompleteFromApi)="foreignEntityOptions = $event"
          [debounceTime]="600"
          maxlength="1500"
          [customPath]="ApiPath.Forms.FORMS_SEARCH_BY_FORMFIELDID(formFieldData.formfieldId)"
          (blur)="formFieldData.isReadonlyField() ? null : checkSelectedOption()"
          [matAutocomplete]="autoStringOption"
        />
        <mat-autocomplete
          (opened)="matAutocompletPanelOpened()"
          (closed)="matAutocompletPanelClosed()"
          matAutocompleteScrollToOption
          #autoStringOption="matAutocomplete"
          autoActiveFirstOption
          (optionSelected)="onForeignOptionSelectionChanged($event.option.value)"
        >
          <mat-option
            class="form-field-option"
            *ngFor="let opt of foreignEntityOptions"
            [value]="opt.entityName"
            [class.mat-selected]="formFieldData.formfieldEntityText === opt.entityName"
          >
            {{ opt.entityName }}
          </mat-option>
          <mat-option class="form-field-option create-button">
            <button mat-raised-button color="primary" style="margin: 0.25rem" (click)="createForeignEntity(); $event.stopPropagation()">
              <mat-icon>add</mat-icon> <span>{{ 'label_create' | translate }}</span>
            </button>
          </mat-option>
        </mat-autocomplete>
        <button mat-icon-button matSuffix class="small-icon" (click)="clear(); $event.stopPropagation()">
          <mat-icon>clear</mat-icon>
        </button>
        <button mat-icon-button matSuffix class="small-icon" (click)="openDialog(); $event.stopPropagation()">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </span>
  </div>
</div>
