import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { select } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entity } from 'src/app/models/entity';
import { GenericEntityFormFieldEvent, GenericEntityFormFieldStep } from 'src/app/models/forms/form-field';
import { EntityFieldsListContentCallBack, GenericEntityField } from 'src/app/models/generic-entity-field';
import { AuthStoreSelectors } from 'src/app/root-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from '../../base-components/format-component';

export interface GenericEntityFormModalDialogData {
  selectedEntity: Entity;
  selectedRelatedEntity?: Entity;
  eventDataChangedCallback?: (event: GenericEntityFormFieldEvent) => void;
}

@Component({
  selector: 'app-generic-entity-form-modal-dialog',
  templateUrl: './generic-entity-form-modal-dialog.component.html',
  styleUrls: ['./generic-entity-form-modal-dialog.component.scss'],
})
export class GenericEntityFormModalDialogComponent extends FormatComponent implements OnInit {
  @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;

  entitySubj: BehaviorSubject<Entity> = new BehaviorSubject<Entity>(null);
  entityRelatedSubj: BehaviorSubject<Entity> = new BehaviorSubject<Entity>(null);
  selectedEntity: Entity;
  selectedRelatedEntity: Entity;
  atomicDetailsSteps: GenericEntityFormFieldStep[];
  atomicDetailsData: GenericEntityField[];
  atomicRelatedDetailsSteps: GenericEntityFormFieldStep[];
  atomicRelatedDetailsData: GenericEntityField[];
  isTenantAdmin = false;
  atomicCommonIsEditing = false;
  atomicCommonIsLoading = false;
  atomicStartTabIndex = 0;
  atomicActualTabIndex = 0; // must be Configured if Details are not the first tab

  constructor(
    protected coreDataService: CoreDataService,
    protected messageNotifierService: MessageNotifierService,
    public dialogRef: MatDialogRef<GenericEntityFormModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericEntityFormModalDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin)), (isTenantAdmin) => {
      this.isTenantAdmin = isTenantAdmin;
    });
    this.selectedEntity = this.data.selectedEntity;
    this.entitySubj.next(this.selectedEntity);
    this.loadEntityFormFieldSteps();
    if (this.data.selectedRelatedEntity) {
      this.selectedRelatedEntity = this.data.selectedRelatedEntity;
      this.entityRelatedSubj.next(this.selectedRelatedEntity);
      this.loadRelatedEntityFormFieldSteps();
    }
  }
  close() {
    this.dialogRef.close();
  }

  /** GENERIC FORM FIELDS API-PATH */

  async loadEntityFormFieldSteps() {
    if (this.selectedEntity) {
      this.atomicCommonIsLoading = true;
      const { data } = await firstValueFrom(
        this.coreDataService.getGenericEntityFormFieldSteps(
          this.selectedEntity.entityKind,
          this.selectedEntity.entityId,
          this.isTenantAdmin
        )
      );
      if (data) {
        this.atomicDetailsSteps = data;
      }
      this.atomicCommonIsLoading = false;
    }
  }

  getMainAPIFormFieldsPath(formId: number) {
    return ApiPath.Entities.ENTITY_GET_PUT_FORMFIELDS(this.selectedEntity.entityKind, this.selectedEntity.entityId, formId);
  }

  putMainAPIFormFieldsPath(formId: number) {
    return ApiPath.Entities.ENTITY_GET_PUT_FORMFIELDS(this.selectedEntity.entityKind, this.selectedEntity.entityId, formId);
  }

  /** GENERIC FORM FIELDS API-PATH */

  async loadRelatedEntityFormFieldSteps() {
    if (this.selectedRelatedEntity) {
      this.atomicCommonIsLoading = true;
      const { data } = await firstValueFrom(
        this.coreDataService.getGenericEntityFormFieldSteps(
          this.selectedRelatedEntity.entityKind,
          this.selectedRelatedEntity.entityId,
          this.isTenantAdmin
        )
      );
      if (data) {
        this.atomicRelatedDetailsSteps = data;
      }
      this.atomicCommonIsLoading = false;
    }
  }

  getMainRelatedAPIFormFieldsPath(formId: number) {
    return ApiPath.Entities.ENTITY_GET_PUT_FORMFIELDS(this.selectedRelatedEntity.entityKind, this.selectedRelatedEntity.entityId, formId);
  }

  putMainRelatedAPIFormFieldsPath(formId: number) {
    return ApiPath.Entities.ENTITY_GET_PUT_FORMFIELDS(this.selectedRelatedEntity.entityKind, this.selectedRelatedEntity.entityId, formId);
  }

  createUpdateDetailsCallbackEvEm(callback: EntityFieldsListContentCallBack) {
    const m = this.createUpdateDetailsCallbackEvEm.name;

    LogService.debug(this, m, 'CREATE/UPDATE callback.isSuccess: ' + callback.isSuccess + ' - Callback Object:', callback);
    this.atomicCommonIsEditing = callback.isEditing;
    // ? this.isNewEntity = callback.isNewEntity;
  }
}
