import { SelectionModel } from '@angular/cdk/collections';
import { KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { AppInjector } from 'src/app/app-injector.service';
import { ApiPath } from 'src/app/configs/api-paths';
import { DefaultColsTable } from 'src/app/configs/default-cols-table';
import { Entities } from 'src/app/configs/entities';
import { RemoveRecordsModalComponent } from 'src/app/core/modal/remove-records-modal/remove-records-modal.component';
import { Entity } from 'src/app/models/entity';
import { GridFilter, GridFilterType, PropertySearchElement } from 'src/app/models/grid-filter';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { AuthStoreSelectors, RootStoreState } from 'src/app/root-store';
import { ErrorHandlingStoreSelectors } from 'src/app/root-store/error-handling-store';
import { GlobalDateFilterStoreSelectors } from 'src/app/root-store/global-date-filter-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import { GlobalSearchService } from 'src/app/services/global-search-service';
import { LogService } from 'src/app/services/log-service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { GenericDynamicGridSearchElementsComponent } from '../generic-components/generic-dynamic-grid-search-elements/generic-dynamic-grid-search-elements.component';
import { GenericMassEditDialogComponent } from '../generic-components/generic-mass-edit-dialog/generic-mass-edit-dialog.component';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class GridBaseComponent extends FormatComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('paginatorTable') paginatorTable: MatPaginator;
  @ViewChild('sortTable') sortTable: MatSort;
  @ViewChild(MatSidenav) sideNav: MatSidenav;
  @ViewChild('searchInputField') searchInputField: ElementRef;
  @ViewChild('searchInputAutocompleteTrigger') autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild(GenericDynamicGridSearchElementsComponent) gridSearchElement: GenericDynamicGridSearchElementsComponent;
  @Input() tabIndex: number;
  @Input() showFilters = true;
  @Input() showSearchPageSize = true;
  @Input() useExternalDetails = false;
  @Input() externalSidenavOpened = false;
  @Input() showActionButtonsMenu = true;
  @Input() externalFilters: Map<string, any[]>;
  @Output() externalRowClickedEvEm = new EventEmitter<Entity>();
  @Output() externalSidenavOpenedEvEm = new EventEmitter<boolean>();
  @Output() externalFiltersEvEm = new EventEmitter<Map<string, any[]>>();

  // Dependencies
  protected dialog: MatDialog;
  protected coreDataService: CoreDataService;
  protected searchDataService: GlobalSearchService;
  protected messageNotifierService: MessageNotifierService;
  protected router: Router;

  isTenantAdmin = false;

  // Sidenav Property
  isOpen = false;
  isEditing = false;
  isNewEntity = false;

  // Entity Property elements
  selectedEntity = new Entity();
  selectedEntitySubject: BehaviorSubject<Entity> = new BehaviorSubject<Entity>(null);
  copySelectedEntitySubject: BehaviorSubject<Entity> = new BehaviorSubject<Entity>(null);
  filtersMapSubject: BehaviorSubject<Map<string, any[]>> = new BehaviorSubject<Map<string, any[]>>(null);
  selection = new SelectionModel<Entity>(true, []);
  selectedDeletableEntities: Entity[];
  selectedUpdatableEntities: Entity[];

  // Table elements
  GRID_NAME: string;
  gridEntityKind: string;
  isCopyEnabled = false;
  isDeleteEnabled = false;
  isUpdateEnabled = false;
  lastUsedFilters: string[];
  filter: string;
  pageSizeList: number[] = [];
  pageSize: number;
  pageTotalElements = 0;
  displayedColumnsTable: string[];
  dataSourceTable: MatTableDataSource<Entity>;
  isLoading = false;
  noEntityData = false;
  request: PaginatedRequest;

  isSpreadsheetLoading = false;
  isSvgIconLoaded = false;

  searchFilterrules: KeyValue<string, string>[];
  selectedFilterules: KeyValue<string, string>;
  selectedFilteruleId: number;
  selectedSearchElements: string[] = [];
  globalDateFilter: Date;

  gridLoadSubscription: Subscription;

  // Filters
  searchElements: PropertySearchElement[];
  dynamicFilters: GridFilter[];
  disableDefaultFilter = false;
  enableFilter = true;
  clearFilterSubject: Subject<void> = new Subject<void>();
  filtersMap = new Map<string, any[]>();
  filtersMapStatic = new Map<string, any[]>();

  clearFilters() {
    this.clearFilterSubject.next();
    // this.filtersMap = this.filtersMapStatic;
    this.filtersMap.clear();
    for (let [key, value] of this.filtersMapStatic) {
      this.filtersMap.set(key, value);
    }
    LogService.debug(this, this.clearFilters.name, 'FiltersMap', this.filtersMap);
    this.selectedSearchElements = [];
    this.resetFilter();
    this.initRequest();
    this.gbLoadEntitiesData();
  }

  resetFilter() {
    if (this.filter) {
      this.filter = '';
      this.setFilter(null);
    }
  }

  clearSearchFilter() {
    this.resetFilter();
    this.initRequest();
    this.gbLoadEntitiesData();
  }

  setFiltersIds($event: number[], filterName: string) {
    const staticNumbers = this.filtersMapStatic.get(filterName);

    if ($event && $event.length > 0) {
      if (staticNumbers != null && staticNumbers.length > 0) {
        this.filtersMap.set(filterName, $event.concat(staticNumbers));
      } else {
        this.filtersMap.set(filterName, $event);
      }
    } else {
      if (staticNumbers != null && staticNumbers.length > 0) {
        this.filtersMap.set(filterName, staticNumbers);
      } else {
        this.filtersMap.delete(filterName);
      }
    }

    LogService.debug(this, this.clearFilters.name, 'FiltersMap', this.filtersMap);
    if (this.paginatorTable) {
      this.paginatorTable.pageIndex = 0;
    }
    this.request = { ...this.request, pageIndex: 0, filterMap: this.filtersMap };
    this.gbLoadEntitiesData();
  }

  setDynamicFiltersIds(filterMap: Map<string, any[]>) {
    LogService.debug(this, this.clearFilters.name, 'FiltersMap', this.filtersMap);
    if (this.paginatorTable) {
      this.paginatorTable.pageIndex = 0;
    }
    this.filtersMap = filterMap;
    for (let [key, value] of this.filtersMapStatic) {
      this.filtersMap.set(key, value);
    }
    this.request = { ...this.request, pageIndex: 0, filterMap };
    this.gbLoadEntitiesData();
  }

  isFiltersSetted(): boolean {
    return this.filtersMap.size > 0 || this.filter != '';
  }

  protected setStaticFilter(filterName: string, filterValues: number[]) {
    this.filtersMapStatic.set(filterName, filterValues);
  }

  changeGlobalDateFilter() {
    this.gbLoadEntitiesData();
  }

  constructor(protected store: Store<RootStoreState.State>, protected cdRef: ChangeDetectorRef) {
    super();
    // Manually retrieve the dependencies from the injector
    // so that constructor has no dependencies that must be passed in from child
    // this.dataSourceTable = new MatTableDataSource();
    const injector = AppInjector.getInjector();
    this.dialog = injector.get(MatDialog);
    this.coreDataService = injector.get(CoreDataService);
    this.messageNotifierService = injector.get(MessageNotifierService);
    this.router = injector.get(Router);
    this.searchDataService = injector.get(GlobalSearchService);
  }

  ngOnInit() {
    // this.selectedFilteruleId = 0;
    this.dataSourceTable = new MatTableDataSource();
    this.displayedColumnsTable = this.gbGetDisplayColumnTable();
    this.translate.onLangChange.subscribe(() => {
      if (this.sideNav && this.sideNav.opened) {
        this.sideNav.toggle();
      }
    });

    this.subscribe(
      combineLatest([
        this.store.pipe(select(GlobalDateFilterStoreSelectors.selectGlobalDateFilter)),
        this.store.pipe(select(GlobalDateFilterStoreSelectors.selectConfigurationGlobalDateFilter)),
        this.store.pipe(select(GlobalDateFilterStoreSelectors.selectIsConfigurationGlobalDateFilter)),
      ]),
      ([global, configGlobal, isConfig]) => {
        if (isConfig != null) {
          if (
            this.globalDateFilter !== undefined &&
            !moment(this.globalDateFilter).isSame(isConfig ? moment(configGlobal) : moment(global))
          ) {
            this.changeGlobalDateFilter();
          }
          this.globalDateFilter = isConfig ? configGlobal : global;
        }
      }
    );
    this.subscribe(this.store.pipe(select(ErrorHandlingStoreSelectors.selectIsSvgErrorOccurred)), (isSvgErrorOccurred) => {
      this.isSvgIconLoaded = !isSvgErrorOccurred;
    });
    this.gbOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.externalSidenavOpenedEvEm.emit(false);
  }

  ngAfterViewInit() {
    if (this.searchInputField) {
      setTimeout(() => {
        this.searchInputField.nativeElement.focus();
        if (this.autocompleteTrigger) {
          this.autocompleteTrigger.closePanel();
          this.matAutocompletPanelClosed();
        } else {
          this.matAutocompletPanelClosed();
        }
      }, 200);
    }
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin)), (isTenantAdmin) => {
      this.isTenantAdmin = isTenantAdmin;
    });
    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesPaginatonsOptions)), (pageSizeOpt) => {
      this.pageSizeList = pageSizeOpt;
      if (this.paginatorTable) {
        this.paginatorTable.pageSize = pageSizeOpt[0];
      }
    });
    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesFilterRulesOptions)), (filterRules) => {
      this.searchFilterrules = filterRules;
      this.selectedFilterules = this.searchFilterrules && this.searchFilterrules.length > 0 ? this.searchFilterrules[0] : null;
      this.selectedFilteruleId = this.castToInt(this.selectedFilterules.key);
    });
    if (this.sortTable) {
      this.subscribe(this.sortTable.sortChange, () => {
        this.setOrder();
        this.gbLoadEntitiesData();
      });
    }
    if (this.paginatorTable) {
      this.subscribe(this.paginatorTable.page, () => {
        this.setPage();
        this.gbLoadEntitiesData();
      });
    }
    if (this.sideNav) {
      this.subscribe(this.sideNav.openedChange, (o) => this.externalSidenavOpenedEvEm.emit(o));
    }

    this.gbAfterViewInitTable();

    this.cdRef.detectChanges();
  }

  /************************ METHODS TO OVERRIDE ************************/

  gbOnInit() {}

  gbSetDefaultFilters() {}

  async gbLoadDynamicFilters() {
    if (this.GRID_NAME != null) {
      const { data } = await firstValueFrom(this.coreDataService.getDynamicFiltersByGridName(this.GRID_NAME));
      this.dynamicFilters = data.filters;
      this.searchElements = data.searchElements;
      const filtersWithDefaults = this.dynamicFilters.filter(
        (f) => f.gridfilterFavorited && f.gridfilterDefaultValues != null && f.gridfilterDefaultValues.length > 0
      );
      if (!this.disableDefaultFilter && filtersWithDefaults.length > 0) {
        filtersWithDefaults.forEach((f) => {
          let defaultValues: any[];
          switch (f.gridfilterType) {
            case GridFilterType.integer:
              defaultValues = f.gridfilterDefaultValues.map((v) => this.castToInt(v));
              break;
            default:
              defaultValues = f.gridfilterDefaultValues;
              break;
          }
          this.filtersMap.set(f.gridfilterVariable, defaultValues);
        });
      }
    }
  }

  async gbAfterViewInitTable() {
    if (this.filtersMap.size == 0 && this.filtersMapStatic.size > 0) {
      for (let [key, value] of this.filtersMapStatic) {
        this.filtersMap.set(key, value);
      }
    }
    this.gbSetDefaultFilters();
    await this.gbLoadDynamicFilters();
    this.initRequest();
    this.gbLoadEntitiesData();
  }

  /************************ TABLE METHODS TO OVERRIDE ************************/
  gbGetApiEntitiesPath?(): string;

  gbGetSpreadsheetApiEntitiesPath?(): string;

  gbGetDisplayColumnTable(): string[] {
    return ['select', ...DefaultColsTable.ENTITY_COLUMS];
  }

  gbGetInitialOrderBy() {
    return null;
  }

  gbGetInitialSort() {
    return 'asc';
  }

  gbSetSelectedEntity(row: Entity) {
    this.selectedEntity = row;
  }

  gbLoadEntitiesData() {
    this.clearSelection();
    const m = this.gbLoadEntitiesData.name;

    this.isLoading = true;
    this.dataSourceTable = new MatTableDataSource();
    this.noEntityData = false;
    this.subscribe(
      this.coreDataService.getEntities(this.request, this.gbGetApiEntitiesPath()),
      (response) => {
        this.lastUsedFilters = response.filters;
        this.setFlagsByResponse(response);
        if (response.data) {
          this.dataSourceTable.data = response.data as Entity[];
          this.pageTotalElements = response.data[0].entityCount;
        } else {
          this.pageTotalElements = 0;
          this.dataSourceTable.data = [];
          this.noEntityData = true;
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      },
      () => (this.isLoading = false)
    );
  }

  loadSpreadsheetFile() {
    this.setSpreadsheetLoading(true);
    const pr: PaginatedRequest = { ...this.request, pageSize: null };
    this.subscribe(
      this.coreDataService.getEntitiesSpreadsheet(pr, this.gbGetSpreadsheetApiEntitiesPath()),
      (blob) => {
        this.setSpreadsheetLoading(false);
        FileSaver.saveAs(blob.file, blob.filename); // FileSaver;
      },
      (error) => this.setSpreadsheetLoading(false)
    );
  }

  /************************ DETAILS METHODS TO OVERRIDE ************************/

  onRowClicked(row: Entity, isDetailsReload?: boolean) {
    if (
      !this.useExternalDetails &&
      !isDetailsReload &&
      this.selectedEntity &&
      this.selectedEntity.entityId === row.entityId &&
      this.selectedEntity.entityKind === row.entityKind &&
      this.selectedEntity.entityParentId === row.entityParentId &&
      this.sideNav.opened
    ) {
      this.onClose();
      return;
    }
    row.hasNoteFlag = this.isNoteEnabled;
    if (this.useExternalDetails) {
      this.gbSetSelectedEntity(row);
      this.externalRowClickedEvEm.emit(row);
    } else {
      this.gbSetSelectedEntity(row);
      this.selectedEntitySubject.next(row);
    }
  }

  onAdd() {
    // to override
  }

  onCopy() {
    LogService.debug(this, this.onCopy.name, 'Copy selected entity', this.selection.selected[0]);
    if (this.selection && this.selection.selected.length === 1 && this.selection.selected[0].entityDelete) {
      this.copySelectedEntitySubject.next(this.selection.selected[0]);
    }
  }

  onCloseOverride() {
    // Not implemented;
  }

  onDetailsUpdated($entity) {
    this.gbLoadEntitiesData();
    this.onRowClicked($entity, true);
  }

  getSidenavTitle() {
    const entityId = this.isShowDetailsEntityid ? ` (${this.selectedEntity.entityId})` : '';
    return this.selectedEntity && this.selectedEntity.entityKind
      ? `${this.translate.instant(this.selectedEntity.entityKind)}${entityId}: ${this.selectedEntity.entityName}`
      : this.selectedEntity.entityName;
  }

  /************************ TABLE COMMON METHODS ************************/

  applyFilterTable(filterValue: string) {
    if (filterValue && filterValue.length >= 3) {
      this.setFilter(filterValue);
      this.gbLoadEntitiesData();
    } else {
      if (!filterValue || (filterValue && filterValue.length === 0)) {
        this.setFilter(null);
        this.gbLoadEntitiesData();
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceTable.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSourceTable.data.forEach((row) => this.selection.select(row));
    this.selectedDeletableEntities = this.selection.selected.filter((entity) => entity.entityDelete);
    this.selectedUpdatableEntities = this.selection.selected.filter((entity) => entity.entityUpdate);
    // this.selectedEntitiesIds = this.selection.selected.filter((entity) => entity.entityDelete).map((entity) => entity.entityId);
    this.isDeleteEnabled = this.selectedDeletableEntities.length > 0;
    this.isUpdateEnabled = this.selectedUpdatableEntities.length > 0;
    this.isCopyEnabled = this.selection.selected.length === 1 && this.selection.selected[0].entityDelete;
  }

  onCheckboxClicked(row: Entity) {
    this.selection.toggle(row);
    this.onClose();
    this.selectedDeletableEntities = this.selection.selected.filter((entity) => entity.entityDelete);
    this.selectedUpdatableEntities = this.selection.selected.filter((entity) => entity.entityUpdate);
    // this.selectedEntitiesIds = this.selection.selected.filter((entity) => entity.entityDelete).map((entity) => entity.entityId);
    this.isDeleteEnabled = this.selectedDeletableEntities.length > 0;
    this.isUpdateEnabled = this.selectedUpdatableEntities.length > 0;
    this.isCopyEnabled = this.selection.selected.length === 1 && this.selection.selected[0].entityDelete;
  }

  clearSelection() {
    this.isDeleteEnabled = false;
    this.isUpdateEnabled = false;
    this.isCopyEnabled = false;
    this.selectedDeletableEntities = [];
    this.selectedUpdatableEntities = [];
    // this.selectedEntitiesIds = [];
    this.selection.clear();
  }

  findEntity(entityId: number): Entity {
    return null;
  }

  initRequest() {
    this.filter = '';
    if (this.pageSizeList && this.pageSizeList.length !== 0) {
      this.request = {
        pageIndex: 0,
        pageSize: this.pageSize ? this.pageSize : this.pageSizeList[0],
        orderBy: this.gbGetInitialOrderBy(),
        sort: this.gbGetInitialSort(),
        filter: this.filter,
        filterRule: this.selectedFilteruleId != null ? this.selectedFilteruleId : this.castToInt(this.selectedFilterules?.key),
        filterElements: this.selectedSearchElements,
        filterMap: this.filtersMap,
      };
    } else {
      this.request = {
        pageIndex: 0,
        pageSize: 20,
        orderBy: this.gbGetInitialOrderBy(),
        sort: this.gbGetInitialSort(),
        filter: this.filter,
        filterRule: this.selectedFilteruleId != null ? this.selectedFilteruleId : this.castToInt(this.selectedFilterules?.key),
        filterElements: this.selectedSearchElements,
        filterMap: this.filtersMap,
      };
    }
  }

  protected setOrder() {
    if (this.paginatorTable) {
      this.paginatorTable.pageIndex = 0;
    }
    this.request = {
      ...this.request,
      pageIndex: 0,
      orderBy: this.sortTable.direction != null && this.sortTable.direction !== '' ? this.sortTable.active : null,
      sort: this.sortTable.direction,
    };
  }

  protected setPage() {
    this.request = {
      ...this.request,
      pageIndex: this.paginatorTable.pageIndex,
      pageSize: this.paginatorTable.pageSize,
    };
  }

  protected setFilter(filter: string) {
    if (this.paginatorTable) {
      this.paginatorTable.pageIndex = 0;
    }
    this.request = {
      ...this.request,
      pageIndex: 0,
      filter,
    };
  }

  protected setFilterRule(values?: number[]) {
    if (values) {
      const value = values.find((id) => id !== this.selectedFilteruleId);
      this.selectedFilteruleId = value != null ? value : 0;
    } else {
      this.selectedFilteruleId = 0;
    }
    if (this.paginatorTable) {
      this.paginatorTable.pageIndex = 0;
    }
    this.request = {
      ...this.request,
      pageIndex: 0,
      filterRule: this.selectedFilteruleId,
    };
    if (this.request.filter && this.request.filter.length >= 3) {
      this.gbLoadEntitiesData();
    }
  }

  protected setClipboardFilter(filter: string) {
    if (this.gridSearchElement) {
      // SET
      if (this.paginatorTable) {
        this.paginatorTable.pageIndex = 0;
      }
      this.selectedFilteruleId = this.gridSearchElement.selectedFilteruleId;
      this.request = {
        ...this.request,
        pageIndex: 0,
        filterRule: this.selectedFilteruleId,
        filter,
      };
      this.gbLoadEntitiesData();
    }
  }

  protected setFilterElements(values?: string[]) {
    this.selectedSearchElements = values;
    if (this.paginatorTable) {
      this.paginatorTable.pageIndex = 0;
    }
    this.request = {
      ...this.request,
      pageIndex: 0,
      filterElements: this.selectedSearchElements,
    };
    if (this.request.filter && this.request.filter.length >= 3) {
      this.gbLoadEntitiesData();
    }
  }

  /************************ DETAILS COMMON METHODS ************************/

  onClose() {
    if (this.sideNav && !this.isEditing) {
      this.sideNav.close();
      this.titleService.setTitle(this.browserTitleService.currentTitle);
      this.externalSidenavOpenedEvEm.emit(false);
    }
    this.resetSelectedEntity();
    this.onCloseOverride();
  }

  resetSelectedEntity($event?: any) {
    if ($event && $event === 'click') {
      return;
    }
    this.selectedEntity = new Entity();
  }

  onEditingChange(editing: boolean) {
    this.isEditing = editing;
  }

  /***************** OTHER COMMON METHODS *********************/

  setSpreadsheetLoading(isLoading: boolean) {
    this.isSpreadsheetLoading = true;
    if (isLoading) {
      document.body.classList.add('waiting-mouse-cursor');
    } else {
      document.body.classList.remove('waiting-mouse-cursor');
    }
  }

  removeMultipleRecords() {
    // const entitiesIds: number[] = this.selectedEntities;
    // const entityKind = this.selection.selected[0].entityKind;
    this.subscribe(
      this.dialog
        .open(RemoveRecordsModalComponent, {
          width: '70vw',
          minHeight: '60vh',
          autoFocus: false,
          disableClose: true,
          data: {
            entities: this.selectedDeletableEntities,
            isTenantAdmin: this.isTenantAdmin,
          },
        })
        .afterClosed(),
      (result) => {
        if (result) {
          this.clearSelection();
          this.gbLoadEntitiesData();
        }
      }
    );
  }

  massEditForm() {
    this.subscribe(
      this.dialog
        .open(GenericMassEditDialogComponent, {
          width: '50vw',
          maxHeight: '60vh',
          autoFocus: false,
          disableClose: true,
          panelClass: 'generic-mass-edit-dialog',
          data: {
            entities: this.selectedUpdatableEntities,
          },
        })
        .afterClosed(),
      (result) => {
        if (result) {
          this.clearSelection();
          this.gbLoadEntitiesData();
        }
      }
    );
  }

  get ApiPath() {
    return ApiPath;
  }

  get Entities() {
    return Entities;
  }
}
