<ng-container *ngIf="currentPeriod">
  <div class="period-selector-container" [ngStyle]="{ visibility: showPeriodScroll ? 'visible' : 'hidden' }">
    <div class="period-selector" (click)="$event.stopPropagation()">
      <div class="selector">
        <button mat-icon-button (click)="previousPeriod(); $event.stopPropagation()" [disabled]="isPreviousDisabled()">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <span cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="$event.stopPropagation(); openOverlay()">
          {{ getDate(currentPeriod.periodStarts) }} -
          {{ currentPeriod.periodEnds ? getDate(currentPeriod.periodEnds) : ('generic_period_open' | translate) }}
        </span>
        <button mat-icon-button (click)="nextPeriod(); $event.stopPropagation()" [disabled]="isNextDisabled()">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>

      <!-- Feature #5478 All users: License Count Feature - Requirement 5: No longer show license usage on asset level. Instead it must be shown on service level.  -->
      <!-- <span
        class="period-counter"
        *ngIf="showPeriodCounter && currentPeriod && currentPeriod.periodItemNumber != null && currentPeriod.periodItemUsed != null"
        [ngClass]="currentPeriod.periodItemNumber - currentPeriod.periodItemUsed < 0 ? 'negative' : ''"
        >{{ itemNumberLabel | translate }}: {{ currentPeriod.periodItemNumber }} | {{ itemUsedLabel | translate }}:
        {{ currentPeriod.periodItemUsed }} | {{ itemAvailableLabel | translate }}:
        {{ currentPeriod.periodItemNumber - currentPeriod.periodItemUsed }}</span
      > -->
    </div>
  </div>
  <ng-template
    #filterSingleselectionOverlay="cdkConnectedOverlay"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOverlayOpen"
    [cdkConnectedOverlayPositions]="overlayPositions"
    (overlayOutsideClick)="closeOverlay()"
  >
    <div class="grid-filter-options-overlay">
      <div class="table-wrapper">
        <div class="table-container items">
          <table class="table table-stripes table-truncate-text" mat-table [dataSource]="dataSourceTable">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row" class="radio-button-wrapper">
                <mat-radio-button
                  class="table-radio-button"
                  (change)="onPeriodSelected(row)"
                  [disableRipple]="true"
                  [checked]="currentPeriod && currentPeriod.periodId === row.periodId"
                ></mat-radio-button>
              </td>
            </ng-container>

            <ng-container matColumnDef="entityName">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row">
                {{ getDate(row.periodStarts) }} -
                {{ row.periodEnds ? getDate(row.periodEnds) : ('generic_period_open' | translate) }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['select', 'entityName']; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: ['select', 'entityName']"></tr>
          </table>
        </div>
        <div class="table-search-paginate">
          <div>&nbsp;</div>
          <mat-paginator
            [hidePageSize]="true"
            #paginatorTable
            [pageSizeOptions]="[10]"
            class="reset-paginator hidden-labels"
          ></mat-paginator>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
