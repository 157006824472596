import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserLicenseServices } from 'src/app/models/product-services';
import * as fromUserLicenseServices from './reducer';
import { State, featureAdapter } from './state';

export const selectUserLicenseServicesState = createFeatureSelector<State>(fromUserLicenseServices.fromUserLicenseServicesFeatureKey);

export const selectAllUserLicenseServicesItems: (state: object) => UserLicenseServices[] =
  featureAdapter.getSelectors(selectUserLicenseServicesState).selectAll;

export const selectUserLicenseServicesError = createSelector(selectUserLicenseServicesState, (state: State): any => state.error);

export const selectUserLicenseServicesIsLoading = createSelector(
  selectUserLicenseServicesState,
  (state: State): boolean => state.isLoading
);

export const selectUserLicenseServicesNoData = createSelector(selectUserLicenseServicesState, (state: State): boolean => state.noData);
