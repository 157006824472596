import { DatePipe } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { HttpHeaders } from 'src/app/configs/http-headers';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';

@Injectable()
export class DateHeaderInterceptor implements HttpInterceptor {
  constructor(private datePipe: DatePipe, private store: Store<RootStoreState.State>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(PreferencesSelectors.selectPreferencesHelp).pipe(
      first(),
      switchMap((helpSetting) => {
        if (!helpSetting || !req.url.match(helpSetting.settingHelpUrl)) {
          const now = new Date();
          req = req.clone({
            headers: req.headers
              .set(HttpHeaders.DATETIME_FILTER, this.datePipe.transform(now, 'yyyy-MM-dd HH:mm:ss'))
              .set(HttpHeaders.TIMEZONE, Intl.DateTimeFormat().resolvedOptions().timeZone),
          });
          return next.handle(req);
        } else {
          return next.handle(req);
        }
      })
    );
  }
}
