import { KeyValue } from '@angular/common';
import { Entity, EntityRecord } from './entity';

export class BillingAccount extends Entity {
  billingaccountId: number;
  billingaccountName: string;
  billingaccountType: string;
  billingaccountBillingvendor: string;
  billingaccountBillingvendorId: number;
  billingaccountInvoicePeriod: string;
  billingaccountInvoicePeriodId: number;
  billingaccountCurrency: string;
  billingaccountCurrencyId: number;
  billingaccountSubscriptionCount: number;
  billingaccountRemarks: string;
  billingaccountStarts: Date;
  billingaccountEnds: Date;
  billingaccountDefault: boolean;
  billingaccountTenant: string;
  billingaccountTenantLedger: string;
  billingaccountProductfamilies: EntityRecord[];
  deleted?: boolean; //used into catalog wizard
  isReadOnly?: boolean; //used into catalog wizardbillingaccountAccount: string,
}

export class BillingAccountParticulars {
  billingaccountAccount: string;
  billingaccountAccountId: number;
  billingaccountAddress: string;
  billingaccountAddressId: number;
  billingaccountBiller: string;
  billingaccountBillerId: number;
  billingaccountBudget: string;
  billingaccountBudgetId: number;
  billingaccountContract: string;
  billingaccountContractId: number;
  billingaccountCostbudget: string;
  billingaccountCostbudgetId: number;
  billingaccountCurrency: string;
  billingaccountCurrencyId: number;
  billingaccountEnds: Date;
  billingaccountExpectedFirstInvoice: Date;
  billingaccountId: number;
  billingaccountInvoiceruleAccount: string;
  billingaccountInvoiceruleAccountId: number;
  billingaccountInvoiceruleAccrue: boolean;
  billingaccountInvoiceruleBooking: string;
  billingaccountInvoiceruleBookingId: number;
  billingaccountInvoiceruleBookingOptions: KeyValue<string, string>[];
  billingaccountInvoiceruleCalcscheme: string;
  billingaccountInvoiceruleCalcschemeId: number;
  billingaccountInvoiceruleCalcschemeOptions: KeyValue<string, string>[];
  billingaccountInvoiceruleCurrency: string;
  billingaccountInvoiceruleCurrencyId: number;
  billingaccountInvoiceruleDefaultPeriod: string;
  billingaccountInvoiceruleDefaultPeriodId: number;
  billingaccountInvoiceruleDefaultPeriodOptions: KeyValue<string, string>[];
  billingaccountInvoiceruleId: number;
  billingaccountInvoiceruleLineItemPeriod: string;
  billingaccountInvoiceruleLineItemPeriodId: number; // use billingaccountInvoiceruleDefaultPeriodOptions
  billingaccountInvoiceruleMisuEnabled: boolean;
  billingaccountInvoiceruleNumberRecurrent: number;
  billingaccountInvoicerulePeriodMonths: number;
  billingaccountInvoiceruleRoundscheme: string;
  billingaccountInvoiceruleRoundschemeId: number;
  billingaccountInvoiceruleRoundschemeOptions: KeyValue<string, string>[];
  billingaccountInvoiceruleStarts: Date;
  billingaccountInvoiceruleTaxOnInvoice: boolean;
  billingaccountInvoiceruleTaxscheme: string;
  billingaccountInvoiceruleTaxschemeId: number;
  billingaccountInvoiceruleTicket: string;
  billingaccountLedger: string;
  billingaccountLocation: string;
  billingaccountLocationId: number;
  billingaccountRestrictConsumerToLocation: boolean;
  billingaccountName: string;
  billingaccountProductFamilies: EntityRecord[];
  billingaccountRemarks: string;
  billingaccountServiceTypeRestrictions: EntityRecord[];
  billingaccountSourceName: string;
  billingaccountStarts: Date;
  billingaccountTenantLedger: string;
  billingaccountTenantLedgerId: number;
  billingaccountTicket: string;
  billingaccountType: string;
  billingaccountTypeId: number;
  billingaccountTypeOptions: KeyValue<string, string>[];
  billingaccountImportReference: string;
  public constructor(init?: Partial<BillingAccountParticulars>) {
    Object.assign(this, init);
  }
}

export class BillingAccountParticularsRequest {
  billingaccountAccountId: number;
  billingaccountAddressId: number;
  billingaccountBillerId: number;
  billingaccountBudgetId: number;
  billingaccountContractId: number;
  billingaccountCostbudgetId: number;
  billingaccountCurrencyId: number;
  billingaccountEnds: string;
  billingaccountInvoiceruleAccountId: number;
  billingaccountInvoiceruleAccrue: boolean;
  billingaccountInvoiceruleBookingId: number;
  billingaccountInvoiceruleCalcschemeId: number;
  billingaccountInvoiceruleCurrencyId: number;
  billingaccountInvoiceruleDefaultPeriodId: number;
  billingaccountInvoiceruleId: number;
  billingaccountInvoiceruleLineItemPeriodId: number;
  billingaccountInvoiceruleMisuEnabled: boolean;
  billingaccountInvoiceruleNumberRecurrent: number;
  billingaccountInvoicerulePeriodMonths: number;
  billingaccountInvoiceruleRoundschemeId: number;
  billingaccountInvoiceruleStarts: string;
  billingaccountInvoiceruleTaxOnInvoice: boolean;
  billingaccountInvoiceruleTaxschemeId: number;
  billingaccountLedger: string;
  billingaccountLocationId: number;
  billingaccountRestrictConsumerToLocation: boolean;
  billingaccountName: string;
  billingaccountProductFamilies: EntityRecord[];
  billingaccountRemarks: string;
  billingaccountServiceTypeRestrictions: EntityRecord[];
  billingaccountSourceName: string;
  billingaccountStarts: string;
  billingaccountTenantLedgerId: number;
  billingaccountTicket: string;
  billingaccountTypeId: number;
  billingaccountImportReference: string;
  billingaccountExpectedFirstInvoice: string;
  billingaccountInvoiceruleTicket: string;
}

export class BillingAccountMisuService extends Entity {
  billingaccountmisuserviceBillingaccount: string;
  billingaccountmisuserviceBillingaccountId: number;
  billingaccountmisuserviceEnds: Date;
  billingaccountmisuserviceId: number;
  billingaccountmisuservicePriority: number;
  billingaccountmisuserviceService: string;
  billingaccountmisuserviceServiceId: number;
  billingaccountmisuserviceStarts: Date;
}
export class BillingAccountParams {
  public static FILTER_TYPE_IDS = 'filterTypeIds';
  public static FILTER_BILLERALIAS_IDS = 'filterBilleraliasIds';
  public static FILTER_PRODUCTFAMILY_IDS = 'filterProductfamilyIds';
  public static FILTER_LOCATION_IDS = 'filterLocationIds';
  public static FILTER_ADDRESS_IDS = 'filterAddressIds';
  public static FILTER_START_DATE = 'filterStartDate';
  public static FILTER_END_DATE = 'filterEndDate';
  public static FILTER_TENANT_IDS = 'filterTenantIds';
  public static FILTER_TENANTLEDGER_IDS = 'filterTenantledgerIds';
  public static FILTER_EXCLUDE_DELIVERYITEM_IDS = 'filterExcludeDeliveryitemIds';
  public static FILTER_EXCLUDE_IDS = 'filterExcludeIds';
  public static FILTER_IDS = 'filterIds';
}
