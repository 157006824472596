import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { OrderWizardDataService } from 'src/app/services/wizards/order-wizard-data.service';
import * as CopyAssetWizardActions from './actions';

@Injectable()
export class CopyAssetWizardEffects {
  newOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CopyAssetWizardActions.createNewCopyAsset),
      concatMap(({ newOrder }) =>
        this.productWizardService.copyProductServices(newOrder).pipe(
          map((response) => {
            if (response) {
              return CopyAssetWizardActions.createNewOrderSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            let errorMessage = '';
            if (error instanceof HttpErrorResponse) {
              errorMessage = error.error.errorKey;
            } else {
              errorMessage = error;
            }
            return of(CopyAssetWizardActions.createNewOrderFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private router: Router, private productWizardService: OrderWizardDataService) {}
}
