import { Entity } from './entity';

export class Userlicense extends Entity {
  userlicenseActivationDate: Date;
  userlicenseAsset: string;
  userlicenseAssetActivationDate: Date;
  userlicenseAssetDeactivationDate: Date;
  userlicenseAssetBillingaccountId: number;
  userlicenseAssetBillingaccount: string;
  userlicenseAssetCatalogitemcategory: string;
  userlicenseAssetCatalogitemcategoryId: number;
  userlicenseAssetId: number;
  userlicenseAssetNotice: Date;
  userlicenseAssetProductFamily: string;
  userlicenseAssetProductFamilyId: number;
  userlicenseAssetProductservice: string;
  userlicenseAssetProductserviceId: number;
  userlicenseAssetRollover: Date;
  userlicenseAssetUseMainBillingAccount: boolean;
  userlicenseBillingStarts: Date;
  userlicenseBillingEnds: Date;
  userlicenseCostcentre: string;
  userlicenseCostcentreId: number;
  userlicenseDeactivationDate: Date;
  userlicenseFreeOfCharge: boolean;
  userlicenseId: number;
  userlicenseLocation: string;
  userlicenseLocationId: number;
  userlicenseOrganisation: string;
  userlicenseOrganisationId: number;
  userlicenseProductType: string;
  userlicenseProductTypeId: number;
  userlicenseSharedUserCount: number;
  userlicenseUser: string;
  userlicenseUserId: number;
  userlicenseDesk: string;
  userlicenseDeskId: number;
  userlicenseAllowContractSwap: boolean;
  userlicenseAllowMoveBillingAccount: boolean;
  userlicenseAssetBillingEnds: Date;
  userlicenseAssetBillingStarts: Date;
  userlicenseAssetIdentification: string;
}

export class UserlicenseParams {
  public static FILTER_COSTCENTRE_IDS = 'filterCostcentreIds';
  public static FILTER_ORGANISATION_IDS = 'filterOrganisationIds';
  public static FILTER_LOCATION_IDS = 'filterLocationIds';
  public static FILTER_PRODUCTFAMILY_IDS = 'filterProductfamilyIds';
  public static FILTER_SERVICE_IDS = 'filterServiceIds';
}
