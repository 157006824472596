<div id="generic-grid-view" class="entity-table">
  <div class="table-wrapper">
    <div class="table-search-paginate" [ngStyle]="{ display: gridEnableSearch ? '' : 'none' }">
      <div class="table-search-paginate-left-wrapper">
        <mat-form-field appearance="outline" class="reset-outline-hint filter-field search"
          ><button *ngIf="filter" mat-icon-button matSuffix class="small-icon" (click)="clearSearchFilter(); $event.stopPropagation()">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-autocomplete
            (opened)="matAutocompletPanelOpened()"
            (closed)="matAutocompletPanelClosed()"
            #autoLastStringOption="matAutocomplete"
            (optionSelected)="applyFilterTable($event.option.value)"
          >
            <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
              {{ 'label_recent_searches' | translate }}
            </mat-option>
            <mat-option class="form-field-option" *ngFor="let opt of lastUsedFilters" [value]="opt" [class.mat-selected]="filter === opt">
              {{ opt }}
            </mat-option>
          </mat-autocomplete>
          <input
            #searchInputField
            #searchInputAutocompleteTrigger="matAutocompleteTrigger"
            (input)="searchInputAutocompleteTrigger.closePanel()"
            matInput
            [(ngModel)]="filter"
            [matAutocomplete]="autoLastStringOption"
            (debounceFilterInput)="applyFilterTable($event)"
            [debounceTime]="600"
            maxlength="1500"
            placeholder="{{ 'generic_grid_view_filter_placeholder' | translate }}"
          />
        </mat-form-field>
      </div>
      <div class="table-search-paginate-right">
        <ng-container>
          <button mat-button color="warn" (click)="resetFilter()" *ngIf="filter">
            {{ 'label_reset_filters' | translate }}
          </button>
        </ng-container>
      </div>
    </div>

    <div class="table-container">
      <span class="table-title">{{ gridTitle | translate }}</span>
      <table
        *ngIf="isConfigurationLoaded"
        class="table table-stripes table-truncate-text"
        [ngClass]="!gridEnableHeader ? 'no-header-table' : ''"
        mat-table
        [dataSource]="dataSourceTable"
        matSort
        [matSortActive]="selectedColumnForOrdering ? selectedColumnForOrdering.entityField : null"
        [matSortDirection]="selectedColumnForOrdering ? (selectedColumnForOrdering.isOrdesAsc ? 'asc' : 'desc') : null"
        #sortTable="matSort"
        [matSortActive]="gbGetInitialOrderBy()"
        [matSortDirection]="gbGetInitialSort()"
        matSortDisableClear
      >
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              *ngIf="gridEnableHeader"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? onCheckboxClicked(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <span *ngFor="let c of gridColumnsConfig">
          <ng-container matColumnDef="{{ c.entityField }}">
            <ng-container *ngIf="gridEnableHeader"
              ><th mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }">
                {{ c.columnTitleKey | translate }}
              </th></ng-container
            >
            <ng-container *ngIf="!gridEnableHeader">
              <th mat-header-cell *matHeaderCellDef></th>
            </ng-container>
            <td
              mat-cell
              *matCellDef="let row"
              [matTooltip]="getTooltipString(c, row)"
              showIfTruncated
              [ngSwitch]="c.columnType"
              [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }"
            >
              <span *ngSwitchCase="'date'">{{ getDate(row[c.entityField]) }}</span>
              <span *ngSwitchCase="'timestamp'">{{ getTimestamp(row[c.entityField]) }}</span>
              <span *ngSwitchCase="'price'">{{
                row[c.entityField] != null ? getMoney(row[c.entityField]) : row[c.columnPriceSwitchField]
              }}</span>
              <span *ngSwitchCase="'factor'">{{ getFactor(row[c.entityField]) }}</span>
              <span *ngSwitchCase="'percentage'">{{ getPercentageNormalized(row[c.entityField]) }}</span>
              <span *ngSwitchDefault>{{ row[c.entityField] }}</span>
            </td>
          </ng-container>
        </span>

        <ng-container matColumnDef="auditlog">
          <th mat-header-cell class="icon-header" *matHeaderCellDef></th>
          <td mat-cell class="icon-cell" *matCellDef="let row">
            <mat-icon
              *ngIf="row.entityId != null"
              (click)="$event.stopPropagation(); openAuditLog(row)"
              fontSet="fas"
              fontIcon="fa-file-alt"
              matTooltip="{{ 'label_show_audit_log' | translate }}"
              [matTooltipClass]="'fo-mat-tooltip'"
            ></mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsTable"
          (click)="onRowClicked(row)"
          [ngClass]="selectedRow !== undefined && selectedRow.entityId === row.entityId ? 'selected-row' : ''"
        ></tr>
      </table>
      <mat-toolbar class="table-footer-toolbar" *ngIf="gridEnablePagination">
        <mat-toolbar-row>
          <mat-paginator
            [disabled]="isLoading"
            #paginatorTable
            [pageSizeOptions]="[gridPageSize]"
            [length]="pageTotalElements"
            class="reset-paginator"
          ></mat-paginator>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  </div>
</div>

<!-- <div *ngIf="isLoading || noEntityData" class="spinner-container">
  <div *ngIf="isLoading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <div *ngIf="noEntityData" class="no-data-message">
    <span *ngIf="gridNoDataMessage">{{ gridNoDataMessage }}</span>
    <span *ngIf="!gridNoDataMessage">{{ 'generic_grid_view_error_no_data' | translate }}</span>
  </div>
</div> -->
