import { Action, createReducer, on } from '@ngrx/store';
import * as MoveAllDesksWizardActions from './actions';
import { State, initialState } from './state';

export const moveAllDesksWizardFeatureKey = 'moveAllDesksWizard';

const moveAllDesksWizardReducer = createReducer(
  initialState,

  on(MoveAllDesksWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(MoveAllDesksWizardActions.loadAllBelongingDesks, (state, { belongingDesks }) => ({
    ...state,
    belongingDesks,
  })),
  on(MoveAllDesksWizardActions.selectMovingDetailsStep, (state, { movingDetails }) => ({
    ...state,
    movingDetails,
  })),
  on(MoveAllDesksWizardActions.selectConsumersStep, (state, { consumersStep }) => ({
    ...state,
    consumersStep,
  })),
  on(MoveAllDesksWizardActions.exitMoveAllDesksWizard, (state) => ({
    ...state,
    initStep: null,
    belongingDesks: null,
    movingDetails: null,
    consumersStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(MoveAllDesksWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    belongingDesks: null,
    movingDetails: null,
    consumersStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(MoveAllDesksWizardActions.createNewMoveAllDesks, (state, { request }) => ({
    ...state,
    request,
  })),
  on(MoveAllDesksWizardActions.createNewMoveAllDesksSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(MoveAllDesksWizardActions.createNewMoveAllDesksFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return moveAllDesksWizardReducer(state, action);
}
