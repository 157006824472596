import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReplaceProductServiceWizardEffects } from './effects';
import * as fromReplaceProductServiceWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromReplaceProductServiceWizard.replaceProductServiceWizardFeatureKey, fromReplaceProductServiceWizard.reducer),
    EffectsModule.forFeature([ReplaceProductServiceWizardEffects]),
  ],
})
export class ReplaceProductServiceWizardStoreModule {}
