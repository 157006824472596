import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { MoveAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-wizard/init-step/init-step';
import { MoveAssetWizardRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-wizard/move-asset-confirm-step/move-asset-wizard-request';
import { MoveAssetMovingToStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-wizard/move-asset-movingto-step/move-asset-movingto-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Move Asset Information Step',
  props<{ initStep: MoveAssetInitStep }>()
);

export const selectMovingToStep = createAction(
  '[MoveAsset Wizard Page] Move Asset Wizard - Moving to Step',
  props<{ movingTo: MoveAssetMovingToStep }>()
);

export const exitMoveAssetWizard = createAction('[MoveAsset Wizard Page] Exit Move Asset Wizard');

export const destroyWizard = createAction('[MoveAsset Wizard Page] Destroy Wizard');

export const createNewMoveAsset = createAction(
  '[Wizard Confirmation Step] Create Move Asset Information Step',
  props<{ request: MoveAssetWizardRequest }>()
);

export const createNewMoveAssetSuccess = createAction(
  '[Wizard Confirmation Step] Create Move Asset Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewMoveAssetFail = createAction(
  '[Wizard Confirmation Step] Create Move Asset Information Step Fail',
  props<{ errorMessage: any }>()
);
