import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, throwError } from 'rxjs';
import { AddSubscriptionsWizardService } from 'src/app/services/wizards/add-subscriptions-wizard.service';
import { AddSubscriptionsWizardActions } from '.';
import { State } from './state';

@Injectable()
export class AddSubscriptionsWizardEffects {
  newAddSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddSubscriptionsWizardActions.createNewAddSubscriptions),
      concatMap(({ request }) =>
        this.addSubscriptionsWizardService.addSubscriptions(request).pipe(
          map((response) => {
            if (response) {
              return AddSubscriptionsWizardActions.createNewAddSubscriptionsSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            const errorMessage = error;
            return of(AddSubscriptionsWizardActions.createNewAddSubscriptionsFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private addSubscriptionsWizardService: AddSubscriptionsWizardService
  ) {}
}
