import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CancelUserWizardStoreEffects } from './effects';
import { FoUserLicenseServicesStoreModule } from './fo-user-product-services-store';
import * as fromCancelUserWizardStore from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCancelUserWizardStore.cancelWizardStoreFeatureKey, fromCancelUserWizardStore.reducer),
    EffectsModule.forFeature([CancelUserWizardStoreEffects]),
    FoUserLicenseServicesStoreModule,
  ],
})
export class AllocationCancelWizardModule {}
