import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { BillingItem } from 'src/app/models/billingitem';
import { Desk } from 'src/app/models/desk';
import { FeedItem } from 'src/app/models/feeditem';
import { ReactivateAssetDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/reactivate-asset-wizard/activation-step/reactivate-asset-due-date-info';
import { ReactivateAssetAdditionalInfo } from 'src/app/shared-components/allocation-wizard-modal-dialogs/reactivate-asset-wizard/additional-step/additional-info';
import { ReactivateAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/reactivate-asset-wizard/init-step/init-step';
import * as fromOrderWizard from './reducer';
import { State } from './state';

export const selectReactivateAssetWizardState = createFeatureSelector<State>(fromOrderWizard.reactivateAssetWizardFeatureKey);

export const selectInitStep = createSelector(selectReactivateAssetWizardState, (state: State): ReactivateAssetInitStep => state.initStep);

export const selectAssetServicesInitStep = createSelector(
  selectReactivateAssetWizardState,
  (state: State): BillingItem[] => state.initAssetServiceStep
);

export const selectDeskStep = createSelector(selectReactivateAssetWizardState, (state: State): Desk => state.deskStep);

export const selectProductStep = createSelector(selectReactivateAssetWizardState, (state: State): FeedItem => state.productStep);

export const selectDueDateStep = createSelector(
  selectReactivateAssetWizardState,
  (state: State): ReactivateAssetDueDates => state.dueDatesStep
);

export const selectServiceStep = createSelector(selectReactivateAssetWizardState, (state: State): FeedItem[] => state.serviceStep);

export const selectAdditionaInfoStep = createSelector(
  selectReactivateAssetWizardState,
  (state: State): ReactivateAssetAdditionalInfo => state.addInfoStep
);

export const selectNewOrderState = createSelector(
  selectReactivateAssetWizardState,
  (state: State): ActionResponse => state.newOrderResponse
);

export const selectErrorMessage = createSelector(selectReactivateAssetWizardState, (state: State): string => state.errorMessage);
