import { createAction, props } from '@ngrx/store';
import { BaseResponse } from '../../models/base-response';
import { SidemenuItem } from '../../models/module-item';

export const loadModuleItems = createAction('[App Component] Load ModuleItems', props<{ isAuth: boolean; isTenantAdmin: boolean }>());

// export const loadSuccess = createAction('[ModuleItem API] Load Success', props<{ response: BaseResponse<ModuleItem[]> }>());

export const loadSuccess = createAction('[ModuleItem API] Load Success', props<{ response: BaseResponse<SidemenuItem[]> }>());

export const loadEmptySuccess = createAction('[ModuleItem API] Load Empty Success');

export const loadFailure = createAction('[ModuleItem API] Load Failure', props<{ error: string }>());
