import { AuthToken } from './auth-token';
import { AuthUser } from './auth-user';

export class AuthResult {
  private token: AuthToken;
  private authUser: AuthUser = null;

  // TODO: better pass object
  constructor(protected success: boolean, protected response?: any, public error: AuthError = null, token: AuthToken = null) {
    if (token) {
      this.token = token;
      this.authUser = token.getUser();
    }
  }

  getResponse(): any {
    return this.response;
  }

  getToken(): AuthToken {
    return this.token;
  }

  getAuthUser(): AuthUser {
    return this.authUser;
  }

  isSuccess(): boolean {
    return this.success;
  }

  isFailure(): boolean {
    return !this.success;
  }
}

export class AuthError {
  constructor(public errorKey: string = null, public errorMessage: string = null) {}
}
