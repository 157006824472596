import { ActionResponse } from 'src/app/models/action-response';
import { BillingItem } from 'src/app/models/billingitem';
import { Desk } from 'src/app/models/desk';
import { FeedItem } from 'src/app/models/feeditem';
import { ReactivateAssetDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/reactivate-asset-wizard/activation-step/reactivate-asset-due-date-info';
import { ReactivateAssetAdditionalInfo } from 'src/app/shared-components/allocation-wizard-modal-dialogs/reactivate-asset-wizard/additional-step/additional-info';
import { ReactivateAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/reactivate-asset-wizard/init-step/init-step';

export interface State {
  initStep: ReactivateAssetInitStep;
  initAssetServiceStep: BillingItem[];
  deskStep: Desk;
  productStep: FeedItem;
  dueDatesStep: ReactivateAssetDueDates;
  serviceStep: FeedItem[];
  addInfoStep: ReactivateAssetAdditionalInfo;
  newOrderResponse: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  initAssetServiceStep: null,
  deskStep: null,
  productStep: null,
  dueDatesStep: null,
  serviceStep: null,
  addInfoStep: null,
  newOrderResponse: null,
  errorMessage: null,
};
