import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { Entity, EntitySelection } from 'src/app/models/entity';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { RootStoreState } from 'src/app/root-store';
import { MessageBox, MessageBoxButton, MessageBoxStyle, MessageBoxType } from 'src/app/shared/message-box';
import {
  GenericEntityWizardModalDialogComponent,
  GenericEntityWizardModalDialogData,
} from '../../../generic-entity-wizard-modal-dialog/generic-entity-wizard-modal-dialog.component';
import { MultiselectionGridBaseComponent } from '../multiselection-grid-base-component';

@Component({
  selector: 'app-multiselection-generic-grid',
  templateUrl: './multiselection-generic-grid.component.html',
  styleUrls: ['./multiselection-generic-grid.component.scss'],
})
export class MultiselectionGenericGridComponent extends MultiselectionGridBaseComponent {
  @Input() entityKind: string;
  @Input() relationEntityKind: string;
  @Input() apiPath: string;
  @Input() enableCreation: boolean;
  @Input() createFieldTextnames: string[];
  @Input() createFieldValues: any[];

  constructor(protected store: Store<RootStoreState.State>, protected cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  gbGetDisplayColumnTable() {
    return ['selection', 'entityName', 'entityDescription'];
  }

  gbGetInitialOrderBy() {
    return 'entityName';
  }

  gbLoadEntitiesData() {
    this.clearSelection();

    this.isLoading = true;
    this.isLoadingEvEm.emit(this.isLoading);
    this.dataSourceTable = new MatTableDataSource();
    this.noEntityData = false;
    if (this.apiPath) {
      this.subscribe(
        this.coreDataService.getEntitiesByApipath(this.request, this.apiPath),
        (response) => {
          this.lastUsedFilters = response.filters;
          this.entityKind = response?.entityKind;
          if (response.data) {
            this.dataSourceTable.data = response.data as Entity[];
            this.pageTotalElements = response.data[0].entityCount;
            this.initSelection();
          } else {
            this.pageTotalElements = 0;
            this.noEntityData = true;
          }
          if (this.addModeSelectAllEnabled) {
            this.addSelectAllEntry();
          }
        },
        (error) => {
          /* HTTP Errors are managed on ServerErrorInterceptor */
        },
        () => {
          this.isLoading = false;
          this.isLoadingEvEm.emit(this.isLoading);
        }
      );
    } else {
      this.subscribe(
        this.coreDataService.getEntitiesByEntityKind(this.request, this.entityKind, this.relationEntityKind, this.isTenantAdmin),
        (response) => {
          this.lastUsedFilters = response.filters;
          if (response.data) {
            this.dataSourceTable.data = response.data as Entity[];
            this.pageTotalElements = response.data[0].entityCount;
            this.initSelection();
          } else {
            this.pageTotalElements = 0;
            this.noEntityData = true;
          }
          if (this.addModeSelectAllEnabled) {
            this.addSelectAllEntry();
          }
        },
        (error) => {
          /* HTTP Errors are managed on ServerErrorInterceptor */
        },
        () => {
          this.isLoading = false;
          this.isLoadingEvEm.emit(this.isLoading);
        }
      );
    }
  }

  askRemoveAllFiltered() {
    MessageBox.show(
      this.dialog,
      this.translate.instant(_('label_filter_confirm_remove_all_filtered'), { value: this.selectionDataSourceTable.filteredData.length }),
      this.translate.instant('label_confirm'),
      null,
      MessageBoxType.Comfirm,
      MessageBoxButton.YesNo,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'yes') {
        this.clearSelectionEvEm.emit();
      }
    });
  }

  askAddAllFiltered() {
    MessageBox.show(
      this.dialog,
      this.translate.instant(_('label_filter_confirm_add_all_filtered'), { value: this.pageTotalElements }),
      this.translate.instant('label_confirm'),
      null,
      MessageBoxType.Comfirm,
      MessageBoxButton.YesNo,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'yes') {
        this.addAllFiltered();
      }
    });
  }

  addAllFiltered() {
    this.isLoadingSelection = true;
    this.isLoadingSelectionEvEm.emit(this.isLoadingSelection);
    const noPaginationRequest: PaginatedRequest = Object.assign({}, this.request);
    noPaginationRequest.pageSize = null;
    if (this.apiPath) {
      this.subscribe(this.coreDataService.getEntitiesByApipath(noPaginationRequest, this.apiPath), (response) => {
        this.lastUsedFilters = response.filters;
        if (response.data) {
          const entities: EntitySelection[] = response.data.map((row) => {
            return { ...(row as Entity), deleted: false };
          });
          this.entitiesSelectedEvEm.emit(entities);
        }
        this.isLoadingSelection = false;
        this.isLoadingSelectionEvEm.emit(this.isLoadingSelection);
      });
    } else {
      this.subscribe(
        this.coreDataService.getEntitiesByEntityKind(noPaginationRequest, this.entityKind, this.relationEntityKind, this.isTenantAdmin),
        (response) => {
          this.lastUsedFilters = response.filters;
          if (response.data) {
            const entities: EntitySelection[] = response.data.map((row) => {
              return { ...(row as Entity), deleted: false };
            });
            this.entitiesSelectedEvEm.emit(entities);
          }
          this.isLoadingSelection = false;
          this.isLoadingSelectionEvEm.emit(this.isLoadingSelection);
        }
      );
    }
  }

  addSelectAllEntry() {
    const allRelations: Entity = {
      ...new Entity(),
      entityId: -1,
      entityName: `${this.translate.instant('label_none')}`,
    };
    this.dataSourceTable.data.unshift(allRelations);
    this.dataSourceTable._updateChangeSubscription();
  }

  createNewEntity() {
    const dialogRef = this.dialog.open(GenericEntityWizardModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'generic-entity-wizard-modal-dialog',
      disableClose: true,

      data: {
        wizardName: this.entityKind,
        fieldTextnames: this.createFieldTextnames ? this.createFieldTextnames : null,
        fieldValues: this.createFieldValues ? this.createFieldValues : null,
        isDocumentsTabEnabled: this.isDocumentEnabled,
      } as GenericEntityWizardModalDialogData,
    });
    this.subscribe(dialogRef.afterClosed(), (entity) => {
      if (entity) {
        this.gbLoadEntitiesData();
      }
    });
  }
}
