import { Action, createReducer, on } from '@ngrx/store';
import * as ConfigurationSearchStoreActions from './actions';
import { State, initialState } from './state';

export const configurationSearchStoreFeatureKey = 'configurationSearchStore';

const configurationSearchStoreReducer = createReducer(
  initialState,
  on(ConfigurationSearchStoreActions.requestNewConfigurationSearch, (state, { filter }) => ({ ...state, newFilter: filter })),
  on(ConfigurationSearchStoreActions.resetConfigurationSearch, (state) => ({ ...state, newFilter: null }))
);

export function reducer(state: State | undefined, action: Action) {
  return configurationSearchStoreReducer(state, action);
}
