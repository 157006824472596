import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { Desk } from 'src/app/models/desk';
import { MoveAllDesksInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-all-desks-wizard/init-step/init-step';
import { MoveAllDesksMovingDetails } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-all-desks-wizard/moving-details-step/move-all-desks-moving-details-step';
import * as fromMoveAllDesksWizard from './reducer';
import { State } from './state';

export const selectMoveAllDesksWizardState = createFeatureSelector<State>(fromMoveAllDesksWizard.moveAllDesksWizardFeatureKey);

export const selectInitStep = createSelector(selectMoveAllDesksWizardState, (state: State): MoveAllDesksInitStep => state.initStep);

export const selectBelongingDesks = createSelector(selectMoveAllDesksWizardState, (state: State): Desk[] => state.belongingDesks);

export const selectMovingDetailsStep = createSelector(
  selectMoveAllDesksWizardState,
  (state: State): MoveAllDesksMovingDetails => state.movingDetails
);

export const selectConsumersStep = createSelector(selectMoveAllDesksWizardState, (state: State): Desk[] => state.consumersStep);

export const selectMoveAllDesksState = createSelector(selectMoveAllDesksWizardState, (state: State): ActionResponse => state.response);

export const selectErrorMessage = createSelector(selectMoveAllDesksWizardState, (state: State): any => state.errorMessage);
