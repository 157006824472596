import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

@Component({
  selector: 'app-generic-demo',
  templateUrl: './generic-demo.component.html',
  styleUrls: ['./generic-demo.component.scss'],
})
export class GenericDemoComponent extends FormatComponent {
  @Input() helpHtml: string;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    super();
  }

  finofficeContactUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://finoffice-reloaded.com/#comp-kg6m9617');
  }
}
