<div
  class="login-oauth-container"
  [ngStyle]="{
    'background-image': publicPageSettings && publicPageSettings.backgroungImage ? 'url(' + publicPageSettings.backgroungImage + ')' : ''
  }"
>
  <div class="modal-login mat-elevation-z24">
    <div class="modal-login-form">
      <!-- <img class="brand-logo" src="./../../../../assets/imgs/PoweredByFinOffice.png" alt="FinOffice powered by BST" /> -->
      <img *ngIf="headerImage" class="brand-logo" [src]="transform(headerImage)" alt="BST" />
      <!--<div class="login-divider">
        <hr class="line" />
      </div>-->
      <div *ngIf="!isMultiTenant; else multiTenant">
        <div *ngIf="!isTwoFactorMethod; else twoFactorMethod">
          <div *ngIf="!isAuthenticated; else loggedIn">
            <div *ngIf="isSubmitted && authError">
              <app-alert status="error" [message]="authError.errorKey | translate" [tooltip]="authError.errorMessage"></app-alert>
              <a [routerLink]="['auth/login']" class="text-button">
                {{ 'login_back_to_login_form' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="footer"
    [ngStyle]="{
      'background-color': publicPageSettings && publicPageSettings.footerBackgroundColor ? publicPageSettings.footerBackgroundColor : ''
    }"
  >
    <span
      *ngIf="publicPageSettings"
      [ngStyle]="{
        color: publicPageSettings && publicPageSettings.footerFontColor ? publicPageSettings.footerFontColor : ''
      }"
      >{{ publicPageSettings?.footerText }}
      <a
        [href]="publicPageSettings.footerLink"
        [ngStyle]="{
          color: publicPageSettings && publicPageSettings.footerLinkColor ? publicPageSettings.footerLinkColor : ''
        }"
        >{{ publicPageSettings?.footerLinkText }}</a
      >
      {{ publicPageSettings?.footerCopyright }}
    </span>
  </div>
</div>
<ng-template #multiTenant
  ><span class="modal-title">{{ 'login_tenant_title' | translate }}</span>
  <form [formGroup]="loginMultitenantForm" (ngSubmit)="selectTenant()" class="login-form">
    <mat-form-field>
      <mat-select
        disableOptionCentering
        panelClass="finoffice-matselect-panel"
        panelClass="finoffice-matselect-panel"
        formControlName="tenant"
        required
      >
        <mat-option class="form-field-option" *ngFor="let tenant of tenants" [value]="tenant.key">
          {{ tenant.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="login-btn" *ngIf="!isLoading">
      <button mat-flat-button color="primary" [disabled]="isSubmitted && isAuthenticated">
        {{ 'login_tenant_button' | translate }}
      </button>
    </div>
    <div *ngIf="isLoading" class="login-spinner-container">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div *ngIf="isSubmitted && authError">
      <app-alert status="error" [message]="authError.errorKey | translate" [tooltip]="authError.errorMessage"></app-alert>
    </div>
    <!-- <a [routerLink]="[]" (click)="resetLoginFormsStatus()" class="text-button">
      {{ 'login_back_to_login_form' | translate }}
    </a> -->
  </form>
</ng-template>
<ng-template #twoFactorMethod>
  <!-- ONE TIME PIN TWO FACTOR METHOD -->
  <ng-container *ngIf="isOneTimePinTwoFactorMethod">
    <span class="modal-title">{{ 'login_onetimepasscode_title' | translate }}</span>
    <form [formGroup]="loginOneTimePinForm" (ngSubmit)="sendOTP()" class="login-form">
      <mat-form-field>
        <mat-label>{{ 'login_placeholder_otp' | translate }}</mat-label>
        <input matInput type="text" formControlName="otp" autocomplete="one-time-code" autofocus />
        <mat-error *ngIf="isSubmitted && otpFormControls.otp.errors">
          {{ 'login_error_required_otp' | translate }}
        </mat-error>
      </mat-form-field>
      <div class="login-btn" *ngIf="!isLoading">
        <button mat-flat-button color="primary" [disabled]="isSubmitted && isAuthenticated">
          {{ 'login_otp_button' | translate }}
        </button>
      </div>
      <div *ngIf="isLoading" class="login-spinner-container">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <div *ngIf="isSubmitted && authError">
        <app-alert status="error" [message]="authError.errorKey | translate" [tooltip]="authError.errorMessage"></app-alert>
      </div>
      <a [routerLink]="[]" (click)="resendOTP()" class="text-button">
        {{ 'login_resend_otp_button' | translate }}
      </a>
      <!-- <a [routerLink]="[]" (click)="resetLoginFormsStatus()" class="text-button">
        {{ 'login_back_to_login_form' | translate }}
      </a> -->
    </form>
  </ng-container>
</ng-template>
<ng-template #loggedIn>
  <app-alert status="success" [message]="'login_message_success' | translate"></app-alert>
</ng-template>
