import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AddBillingitemsWizardDataService } from 'src/app/services/wizards/add-billingitems-wizard.service';
import * as AddBillingitemsWizardActions from './actions';

@Injectable()
export class AddBillingitemsWizardEffects {
  // loadAddProductWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(AddBillingitemsWizardActions.selectInitStep),
  //       tap(() => this.router.navigateByUrl('/pages/allocation-wizards/order'))
  //     ),
  //   { dispatch: false }
  // );
  // exitAddBillingitemsAllocationWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(AddBillingitemsWizardActions.exitAllocationAddBillingitemsWizard),
  //       tap(() => this.router.navigateByUrl('/pages/consumers'))
  //     ),
  //   { dispatch: false }
  // );

  newAddBillingitems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddBillingitemsWizardActions.createNewAddBillingitems),
      concatMap(({ request }) =>
        this.addBillingitemsWizardService.addBillingitems(request).pipe(
          map((response) => {
            if (response) {
              return AddBillingitemsWizardActions.createNewAddBillingitemsSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            let errorMessage = '';
            if (error instanceof HttpErrorResponse) {
              errorMessage = error.error.errorKey;
            } else {
              errorMessage = error;
            }
            return of(AddBillingitemsWizardActions.createNewAddBillingitemsFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private router: Router, private addBillingitemsWizardService: AddBillingitemsWizardDataService) {}
}
