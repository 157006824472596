import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { BaseResponse } from '../models/base-response';
import { PaginatedRequest } from '../models/paginated-request';
import { Userlicense } from '../models/userlicense';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class UserlicensesDataService {
  getUserlicenses(request: PaginatedRequest, filterStarts?: string): Observable<BaseResponse<Userlicense[]>> {
    const m = this.getUserlicenses.name;

    const path = environment.getEndpoint(ApiPath.UserLicences.USERLICENSES);
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    if (filterStarts) {
      params = params.append('filterStarts', filterStarts);
    }
    const retVal = this.http.get<BaseResponse<Userlicense[]>>(path, {
      params,
    });

    return retVal;
  }

  getUserlicensesByDeskId(deskId: number, assetId: number, filterEnds: string): Observable<BaseResponse<Userlicense[]>> {
    const m = this.getUserlicensesByDeskId.name;

    const path = environment.getEndpoint(ApiPath.UserLicences.USERLICENSES);
    LogService.info(this, m, LogService.GET + path, null);

    const params = new HttpParams().set('filterDeskIds', deskId).set('filterAssetIds', assetId).set('filterEnds', filterEnds);
    const retVal = this.http.get<BaseResponse<Userlicense[]>>(path, {
      params,
    });

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
