<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span>{{ selectedEntity?.entityName }}</span>
  <div class="buttons-container">
    <button mat-icon-button (click)="$event.stopPropagation(); close()" [disabled]="atomicCommonIsEditing">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content class="generic-entity-form-modal-dialog-content">
  <div class="atomic-sidenav-detail-content">
    <div class="spinner-container" *ngIf="atomicCommonIsLoading">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <ng-container *ngIf="selectedEntity">
      <mat-tab-group
        class="atomic-sidenav-detail-tab-group flying-button-custom-container"
        *ngIf="selectedEntity && !atomicCommonIsLoading"
        [selectedIndex]="atomicStartTabIndex"
        (selectedIndexChange)="atomicActualTabIndex = $event"
      >
        <mat-tab *ngFor="let step of atomicDetailsSteps" [disabled]="atomicCommonIsEditing">
          <ng-template mat-tab-label>
            {{ step.formstepName }}
          </ng-template>
          <ng-template matTabContent>
            <div class="flying-button-custom-container">
              <generic-entity-form
                [isFormDialog]="true"
                [entitySubj]="entitySubj"
                [getAPIPath]="getMainAPIFormFieldsPath(step.formstepId)"
                [putAPIPath]="putMainAPIFormFieldsPath(step.formstepId)"
                (createUpdateCallbackEvEm)="createUpdateDetailsCallbackEvEm($event)"
                (eventDataChanged)="data.eventDataChangedCallback != null ? data.eventDataChangedCallback($event) : null"
                [isEditing]="atomicCommonIsEditing"
                (isEditingChange)="atomicCommonIsEditing = $event"
                [leftButtonEnabled]="selectedEntity.entityUpdate"
                leftButtonKey="label_edit"
              ></generic-entity-form>
            </div>
          </ng-template>
        </mat-tab>
        <ng-container *ngIf="selectedRelatedEntity">
          <mat-tab *ngFor="let step of atomicRelatedDetailsSteps" [disabled]="atomicCommonIsEditing">
            <ng-template mat-tab-label>
              {{ step.formstepName }}
            </ng-template>
            <ng-template matTabContent>
              <div class="flying-button-custom-container">
                <generic-entity-form
                  [isFormDialog]="true"
                  [entitySubj]="entityRelatedSubj"
                  [getAPIPath]="getMainRelatedAPIFormFieldsPath(step.formstepId)"
                  [putAPIPath]="putMainRelatedAPIFormFieldsPath(step.formstepId)"
                  (createUpdateCallbackEvEm)="createUpdateDetailsCallbackEvEm($event)"
                  (eventDataChanged)="data.eventDataChangedCallback != null ? data.eventDataChangedCallback($event) : null"
                  [isEditing]="atomicCommonIsEditing"
                  (isEditingChange)="atomicCommonIsEditing = $event"
                  [leftButtonEnabled]="selectedRelatedEntity.entityUpdate"
                  leftButtonKey="label_edit"
                ></generic-entity-form>
              </div>
            </ng-template>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </ng-container>
  </div>
</div>
