import { CollectionViewer, SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, map, merge } from 'rxjs';
import { TypeDataService } from '../services/type-data.service';
import { Entity } from './entity';
import { PaginatedRequest } from './paginated-request';
import { GenericTreeNode } from './tree-view-flat-node';

export class TypeTreeNode extends GenericTreeNode {
  typeId: number;
  typeKindId: number;
  typeKind: string;
  typeName: string;
  typeParentId: number;
  typeParentName: string;
  typeIcon: string;
  typeRelatedEntityKind: string;
  typeRelated: Entity[];
}

export class TypeDynamicFlatNode {
  constructor(
    public item: TypeTreeNode,
    public level = 1,
    public expandable = false,
    public isLoading = false,
    public isIndeterminate = false,
    parentId?: number
  ) {
    this.item.parentId = parentId;
  }
}

@Injectable()
export class TypeDynamicDataSource {
  dataChange = new BehaviorSubject<TypeDynamicFlatNode[]>([]);
  nodeSelection = new SelectionModel<TypeTreeNode>(true, []);
  baseApiPath: string;
  nodeName: string;

  isManualLoading = false;

  get data(): TypeDynamicFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: TypeDynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private treeControl: FlatTreeControl<TypeDynamicFlatNode>,
    private database: TypeDynamicDatabase,
    private typeDataService: TypeDataService,
    private request: PaginatedRequest
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<TypeDynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if ((change as SelectionChange<TypeDynamicFlatNode>).added || (change as SelectionChange<TypeDynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<TypeDynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(): void {}

  handleTreeControl(change: SelectionChange<TypeDynamicFlatNode>) {
    if (change.added && !this.isManualLoading) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed && !this.isManualLoading) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  toggleNode(node: TypeDynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);

    node.isLoading = true;

    if (expand) {
      const childs = this.database.getChildren(node.item);
      if (childs) {
        const nodes = childs.map(
          (item) => new TypeDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId)
        );
        this.data.splice(index + 1, 0, ...nodes);
        // notify the change
        this.dataChange.next(this.data);
        node.isLoading = false;
      } else {
        this.typeDataService.getTypeGridNodes(this.request, node.item.typeKindId, node.item.typeId).subscribe(({ data }) => {
          if (data) {
            const nodes = data.map(
              (item) => new TypeDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId)
            );
            this.database.setChildren(node.item, data);
            this.data.splice(index + 1, 0, ...nodes);
            // notify the change
            this.dataChange.next(this.data);
          } else {
            node.expandable = false;
          }
          node.isLoading = false;
        });
      }
    } else {
      let count = 0;
      // eslint-disable-next-line no-empty
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
      this.data.splice(index + 1, count);
      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }
  }

  async refreshNodeChildren(item: TypeTreeNode) {
    const node = this.data.find((n) => n.item.entityId === item.entityId && n.item.entityKind === item.entityKind);
    const index = this.data.indexOf(node);
    // remove all childs
    let count = 0;
    // eslint-disable-next-line no-empty
    for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
    this.data.splice(index + 1, count);
    this.database.removeAllChildren(node.item);

    const { data } = await firstValueFrom(this.typeDataService.getTypeGridNodes(this.request, node.item.typeKindId, node.item.typeId));
    if (data && index !== -1) {
      const nodes = data.map(
        (item) => new TypeDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId)
      );

      this.isManualLoading = true;
      node.expandable = true;
      node.item.entityChilds++;
      this.database.setChildren(node.item, data);
      this.data.splice(index + 1, 0, ...nodes);
      this.treeControl.expansionModel.select(...[node]);
      this.isManualLoading = false;

      // notify the change
      this.dataChange.next(this.data);
    }
  }

  async refreshNodeData(item: TypeTreeNode) {
    const node = this.data.find((n) => n.item.entityId === item.entityId && n.item.entityKind === item.entityKind);
    const parentNode = this.getParentNode(node);
    const index = this.data.indexOf(node);
    const { data } = await firstValueFrom(
      this.typeDataService.getTypeGridNodes(this.request, parentNode.item.typeKindId, parentNode.item.typeId, node.item.entityId)
    );
    if (data && index !== -1) {
      const updatedNode = data.map(
        (item) => new TypeDynamicFlatNode(item, node.level, this.database.isExpandable(item), false, false, node.item.parentId)
      )[0];
      this.data[index] = updatedNode;
      this.database.refreshChildren(parentNode.item, updatedNode.item);
      // notify the change
      this.dataChange.next(this.data);
    }
  }

  async removeNodeData(item: TypeTreeNode) {
    const node = this.data.find((n) => n.item.entityId === item.entityId && n.item.entityKind === item.entityKind);
    if (node.item.entityChilds > 0) {
      const index = this.data.indexOf(node);
      // remove all childs
      let count = 0;
      // eslint-disable-next-line no-empty
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
      this.data.splice(index + 1, count);
      this.database.removeAllChildren(node.item);
    }
    const parentNode = this.getParentNode(node);
    const index = this.data.indexOf(node);
    if (index !== -1) {
      this.data.splice(index, 1);
      this.database.removeChildren(parentNode.item, node.item);
      parentNode.item.entityChilds--;
      parentNode.expandable = parentNode.item.entityChilds > 0;
      // notify the change
      this.dataChange.next(this.data);
    }
  }

  isNodeSelected(node: TypeDynamicFlatNode): boolean {
    return this.nodeSelection.isSelected(node.item);
  }

  isNodeChecked(node: TypeDynamicFlatNode): boolean {
    return node.expandable && this.treeControl.isExpanded(node)
      ? this.descendantsAllSelected(node)
      : this.nodeSelection.isSelected(node.item);
  }

  getSelectedNodes() {
    return this.nodeSelection.selected;
  }

  toggleNodeSelection(node: TypeDynamicFlatNode) {
    this.nodeSelection.toggle(node.item);
    const descendants = this.database.getChildren(node.item);
    this.toggleDescendants(this.isNodeSelected(node), descendants);
    this.checkAllParentsSelection(node);
  }

  toggleDescendants(parentNodeSelected: boolean, descendants: TypeTreeNode[]) {
    if (descendants) {
      parentNodeSelected ? this.nodeSelection.select(...descendants) : this.nodeSelection.deselect(...descendants);
      descendants.forEach((childNode) => this.toggleDescendants(parentNodeSelected, this.database.getChildren(childNode)));
    }
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TypeDynamicFlatNode): boolean {
    const descendants = this.database.getChildren(node.item);
    const descAllSelected =
      descendants &&
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.nodeSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TypeDynamicFlatNode): boolean {
    if (node.expandable && this.treeControl.isExpanded(node)) {
      const descendants = this.database.getChildren(node.item);
      const result = descendants ? descendants.some((child) => this.nodeSelection.isSelected(child)) : false;
      const isIndeterminate = result && !this.descendantsAllSelected(node);
      node.isIndeterminate = isIndeterminate;
    }
    return node.isIndeterminate;
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TypeDynamicFlatNode): void {
    let parent: TypeDynamicFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TypeDynamicFlatNode): void {
    const nodeSelected = this.isNodeSelected(node);
    const descendants = this.database.getChildren(node.item);
    const descAllSelected =
      descendants &&
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.nodeSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.nodeSelection.deselect(node.item);
    } else if (!nodeSelected && descAllSelected) {
      this.nodeSelection.select(node.item);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TypeDynamicFlatNode): TypeDynamicFlatNode | null {
    const currentLevel = node.level;
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}

@Injectable()
export class TypeDynamicDatabase {
  dataMap = new Map<TypeTreeNode, TypeTreeNode[]>([]);

  initialData(rootLevelNodes: TypeTreeNode[]): TypeDynamicFlatNode[] {
    const initialNodes: TypeDynamicFlatNode[] = [];
    rootLevelNodes.forEach((node) => {
      initialNodes.push(new TypeDynamicFlatNode(node, 0, this.isExpandable(node), false));
    });

    return initialNodes;
  }

  setChildren(parentNode: TypeTreeNode, children: TypeTreeNode[]) {
    this.dataMap.set(parentNode, children);
  }

  getChildren(node: TypeTreeNode): TypeTreeNode[] | undefined {
    return this.dataMap.get(node);
  }

  isExpandable(node: TypeTreeNode): boolean {
    return node.entityChilds > 0;
  }

  refreshChildren(parentNode: TypeTreeNode, node: TypeTreeNode) {
    const values: TypeTreeNode[] = this.dataMap.get(parentNode);
    const index: number = values.indexOf(node);
    if (index !== -1) {
      values[index] = node;
    }
  }

  removeAllChildren(parentNode: TypeTreeNode) {
    this.dataMap.delete(parentNode);
  }

  removeChildren(parentNode: TypeTreeNode, node: TypeTreeNode) {
    const values: TypeTreeNode[] = this.dataMap.get(parentNode);
    const index: number = values.indexOf(node);
    if (index !== -1) {
      values.splice(index, 1);
    }
  }
}
