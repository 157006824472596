import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import {
  CustomConfigurableModalColumn,
  CustomConfigurableModalFilter,
} from '../core/modal/custom-configurable-modal/custom-configurable-modal.component';
import { AddressParams } from '../models/address';
import { BillingItemParams } from '../models/billingitem';
import { DeskParams } from '../models/desk';
import { PropertyColumnParams } from '../models/property';
import { UserParams } from '../models/user';
import { BillingAccountParams } from '../models/vendor-account';
import { ApiPath } from './api-paths';

export class EntityModalConfig {
  static Addresses = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'addressSalutation',
        columnTitleKey: _('address_salutation'),
        columnWidth: '10%',
      },
      {
        columnType: 'string',
        entityField: 'addressUser',
        columnTitleKey: _('address_user'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'addressBusinesspartner',
        columnTitleKey: _('address_businesspartner'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'addressAddress',
        columnTitleKey: _('address_address'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'addressCity',
        columnTitleKey: _('address_city'),
        columnWidth: '15%',
      },
      {
        columnType: 'string',
        entityField: 'addressCountry',
        columnTitleKey: _('address_country'),
        columnWidth: '15%',
      },
    ];
    public static FILTERS: CustomConfigurableModalFilter[] = [
      {
        filterApiPath: ApiPath.Addresses.Filters.SALUTATIONS,
        filterParams: AddressParams.FILTER_SALUTATION_IDS,
        filterTitle: _('filter_salutation'),
      },
      {
        filterApiPath: ApiPath.Addresses.Filters.BUSINESSES_PARTNERS,
        filterParams: AddressParams.FILTER_BUSINESSPARTNER_IDS,
        filterTitle: _('filter_business_partner'),
      },
      {
        filterApiPath: ApiPath.Addresses.Filters.BUSINESSES,
        filterParams: AddressParams.FILTER_BUSINESS_IDS,
        filterTitle: _('filter_business'),
      },
    ];
  };
  static BillingAccount = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'billingaccountBillingvendor',
        columnTitleKey: _('cu_billingvendor'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'billingaccountName',
        columnTitleKey: _('billingaccount_name'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'billingaccountRemarks',
        columnTitleKey: _('billingaccount_remarks'),
        columnWidth: '20%',
      },
      {
        columnType: 'date',
        entityField: 'billingaccountStarts',
        columnTitleKey: _('billingaccount_starts'),
        columnWidth: '10%',
      },
      {
        columnType: 'date',
        entityField: 'billingaccountEnds',
        columnTitleKey: _('billingaccount_ends'),
        columnWidth: '10%',
      },
      {
        columnType: 'string',
        entityField: 'billingaccountTenant',
        columnTitleKey: _('billingaccount_tenant'),
        columnWidth: '10%',
      },
      {
        columnType: 'string',
        entityField: 'billingaccountTenantLedger',
        columnTitleKey: _('billingaccount_tenant_ledger'),
        columnWidth: '10%',
      },
    ];
    public static FILTERS: CustomConfigurableModalFilter[] = [
      {
        filterApiPath: ApiPath.BillingAccounts.Filters.TYPES,
        filterParams: BillingAccountParams.FILTER_TYPE_IDS,
        filterTitle: _('filter_type'),
      },
      {
        filterApiPath: ApiPath.BillingAccounts.Filters.VENDORALIASES,
        filterParams: BillingAccountParams.FILTER_BILLERALIAS_IDS,
        filterTitle: _('filter_billingvendor'),
      },
      {
        filterApiPath: ApiPath.BillingAccounts.Filters.LOCATIONS,
        filterParams: BillingAccountParams.FILTER_LOCATION_IDS,
        filterTitle: _('filter_location'),
      },
      {
        filterApiPath: ApiPath.BillingAccounts.Filters.ADDRESSES,
        filterParams: BillingAccountParams.FILTER_ADDRESS_IDS,
        filterTitle: _('filter_address'),
      },
      {
        filterApiPath: null,
        filterParams: BillingAccountParams.FILTER_START_DATE,
        filterTitle: _('filter_start_date'),
        isDateFilter: true,
      },
      {
        filterApiPath: null,
        filterParams: BillingAccountParams.FILTER_END_DATE,
        filterTitle: _('filter_end_date'),
        isDateFilter: true,
      },
      {
        filterApiPath: ApiPath.BillingAccounts.Filters.TENANT,
        filterParams: BillingAccountParams.FILTER_TENANT_IDS,
        filterTitle: _('filter_tenant'),
      },
      {
        filterApiPath: ApiPath.BillingAccounts.Filters.TENANT_LEDGER,
        filterParams: BillingAccountParams.FILTER_TENANTLEDGER_IDS,
        filterTitle: _('filter_tenantledger'),
      },
    ];
  };
  static BillingItems = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'billingitemBiller',
        columnTitleKey: _('entity_billingitem_biller'),
        columnWidth: '25%',
      },
      {
        columnType: 'string',
        entityField: 'billingitemBillingaccount',
        columnTitleKey: _('entity_billingitem_billingaccount'),
        columnWidth: '25%',
      },
      {
        columnType: 'string',
        entityField: 'billingitemService',
        columnTitleKey: _('entity_billingitem_service'),
        columnWidth: '50%',
      },
      {
        columnType: 'tooltip',
        entityField: 'entityInfo',
        columnTitleKey: null,
        columnWidth: '32px',
      },
    ];
    public static COLUMNS_INVOICE: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'billingitemService',
        columnTitleKey: _('invoice_item_service'),
        columnWidth: '40%',
      },
      {
        columnType: 'string',
        entityField: 'billingitemServiceCode',
        columnTitleKey: _('invoice_item_servicecode'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'billingitemBillingaccount',
        columnTitleKey: _('invoice_item_vendoraccount'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'billingitemPermissioningSystem',
        columnTitleKey: _('invoice_item_platform'),
        columnWidth: '20%',
      },
    ];
    public static FILTERS: CustomConfigurableModalFilter[] = [
      {
        filterApiPath: ApiPath.BillingItems.Filters.BILLINGACCOUNTS,
        filterParams: BillingItemParams.FILTER_BILLINGACCOUNT_IDS,
        filterTitle: _('filter_billingaccount'),
      },
      {
        filterApiPath: ApiPath.BillingItems.Filters.DELIVERYITEMS,
        filterParams: BillingItemParams.FILTER_DELIVERYITEM_IDS,
        filterTitle: _('filter_feeditem'),
      },
      {
        filterApiPath: ApiPath.BillingItems.Filters.SUGESTOPTDATES,
        filterParams: BillingItemParams.FILTER_SUGESTOPTDATE_IDS,
        filterTitle: _('filter_sugestoptdate'),
      },
      {
        filterApiPath: ApiPath.BillingItems.Filters.PRICECALCS,
        filterParams: BillingItemParams.FILTER_PRICECALC_IDS,
        filterTitle: _('filter_pricecalc'),
      },
      {
        filterApiPath: ApiPath.BillingItems.Filters.STATUSES,
        filterParams: BillingItemParams.FILTER_STATUS_IDS,
        filterTitle: _('filter_status'),
      },
      {
        filterApiPath: ApiPath.BillingItems.Filters.CATEGORIES,
        filterParams: BillingItemParams.FILTER_CATEGORY_IDS,
        filterTitle: _('filter_category'),
      },
      {
        filterApiPath: ApiPath.BillingItems.Filters.STEERABLES,
        filterParams: BillingItemParams.FILTER_STEERABLE_IDS,
        filterTitle: _('filter_steerable'),
      },
    ];
  };
  static Category = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'servicecategoryName',
        columnTitleKey: _('servicecategory_name'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'servicecategoryDescription',
        columnTitleKey: _('servicecategory_description'),
        columnWidth: '40%',
      },
      {
        columnType: 'string',
        entityField: 'servicecategoryExample',
        columnTitleKey: _('servicecategory_example'),
        columnWidth: '40%',
      },
    ];
  };
  static Contract = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'contractName',
        columnTitleKey: _('contract_name'),
        columnWidth: '40%',
      },
      {
        columnType: 'string',
        entityField: 'contractType',
        columnTitleKey: _('contract_type'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'contractNumber',
        columnTitleKey: _('contract_number'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'contractDescription',
        columnTitleKey: _('contract_description'),
        columnWidth: '20%',
      },
    ];
  };
  static Currency = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'currencyDescription',
        columnTitleKey: _('currency_name'),
        columnWidth: '45%',
      },
      {
        columnType: 'string',
        entityField: 'currencyIso',
        columnTitleKey: _('currency_iso'),
        columnWidth: '55%',
      },
    ];
  };
  static Desks = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'deskUser',
        columnTitleKey: _('desk_user'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'deskOrganisation',
        columnTitleKey: _('desk_organisation'),
        columnWidth: '40%',
      },
      {
        columnType: 'string',
        entityField: 'deskCostcentre',
        columnTitleKey: _('desk_costcentre'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'deskLocation',
        columnTitleKey: _('desk_location'),
        columnWidth: '20%',
      },
    ];
    public static FILTERS: CustomConfigurableModalFilter[] = [
      {
        filterApiPath: null,
        filterParams: DeskParams.FILTER_STARTS,
        filterTitle: _('filter_start_date'),
        isDateFilter: true,
      },
      {
        filterApiPath: null,
        filterParams: DeskParams.FILTER_ENDS,
        filterTitle: _('filter_start_date'),
        isDateFilter: true,
      },
      {
        filterApiPath: ApiPath.Desks.Filters.USERS,
        filterParams: DeskParams.FILTER_USER_IDS,
        filterTitle: _('filter_user'),
      },
      {
        filterApiPath: ApiPath.Desks.Filters.ORGANISATIONS,
        filterParams: DeskParams.FILTER_ORGANISATION_IDS,
        filterTitle: _('filter_organisation'),
      },
      {
        filterApiPath: ApiPath.Desks.Filters.COSTCENTRES,
        filterParams: DeskParams.FILTER_COSTCENTRE_IDS,
        filterTitle: _('filter_costcentre'),
      },
      {
        filterApiPath: ApiPath.Desks.Filters.LOCATIONS,
        filterParams: DeskParams.FILTER_LOCATION_IDS,
        filterTitle: _('filter_location'),
      },
      {
        filterApiPath: ApiPath.Desks.Filters.ISORGANISATIONDESKS,
        filterParams: DeskParams.FILTER_ISORGANISATIONDESK_IDS,
        filterTitle: _('filter_isorganisationdesk'),
      },
    ];
  };
  static Location = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'locationName',
        columnTitleKey: _('location_name'),
        columnWidth: '25%',
      },
      {
        columnType: 'string',
        entityField: 'locationAbb',
        columnTitleKey: _('location_abb'),
        columnWidth: '25%',
      },
      {
        columnType: 'string',
        entityField: 'locationParentName',
        columnTitleKey: _('location_parent_name'),
        columnWidth: '25%',
      },
      {
        columnType: 'string',
        entityField: 'locationParentAbb',
        columnTitleKey: _('location_parent_abb'),
        columnWidth: '25%',
      },
    ];
  };
  static Organisation = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'organisationName',
        columnTitleKey: _('organisation_name'),
        columnWidth: '25%',
      },
      {
        columnType: 'string',
        entityField: 'organisationAbb',
        columnTitleKey: _('organisation_abb'),
        columnWidth: '25%',
      },
      {
        columnType: 'string',
        entityField: 'organisationNo',
        columnTitleKey: _('organisation_no'),
        columnWidth: '25%',
      },
      {
        columnType: 'string',
        entityField: 'organisationParent',
        columnTitleKey: _('organisation_parent'),
        columnWidth: '25%',
      },
    ];
  };
  static PropertyColumns = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'propertycolumnProperty',
        columnTitleKey: _('propertycolumn_property'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'propertycolumnColumn',
        columnTitleKey: _('propertycolumn_column'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'propertycolumnName',
        columnTitleKey: _('propertycolumn_name'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'propertycolumnType',
        columnTitleKey: _('propertycolumn_type'),
        columnWidth: '10%',
      },
      {
        columnType: 'string',
        entityField: 'propertycolumnForeignProperty',
        columnTitleKey: _('propertycolumn_foreign_property'),
        columnWidth: '15%',
      },
      {
        columnType: 'string',
        entityField: 'propertycolumnOption',
        columnTitleKey: _('propertycolumn_option'),
        columnWidth: '15%',
      },
    ];
    public static FILTERS: CustomConfigurableModalFilter[] = [
      {
        filterApiPath: ApiPath.Properties.PROPERTY_COLUMNS_PROPERTIES,
        filterParams: PropertyColumnParams.FILTER_PROPERTY_IDS,
        filterTitle: _('filter_property'),
      },
      {
        filterApiPath: ApiPath.Properties.PROPERTY_COLUMNS_TYPES,
        filterParams: PropertyColumnParams.FILTER_TYPE_IDS,
        filterTitle: _('filter_type'),
      },
    ];
  };
  static ServiceAlias = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'servicealiasCode',
        columnTitleKey: _('servicealias_code'),
        columnWidth: '15%',
      },
      {
        columnType: 'string',
        entityField: 'servicealiasName',
        columnTitleKey: _('servicealias_name'),
        columnWidth: '35%',
      },
      {
        columnType: 'string',
        entityField: 'servicealiasServiceCode',
        columnTitleKey: _('servicealias_service_code'),
        columnWidth: '15%',
      },
      {
        columnType: 'string',
        entityField: 'servicealiasServiceName',
        columnTitleKey: _('servicealias_service_name'),
        columnWidth: '35%',
      },
    ];
  };
  static Users = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'userName',
        columnTitleKey: _('user_lastname'),
        columnWidth: '10%',
      },
      {
        columnType: 'string',
        entityField: 'userFirstname',
        columnTitleKey: _('user_firstname'),
        columnWidth: '10%',
      },
      {
        columnType: 'string',
        entityField: 'userPno',
        columnTitleKey: _('user_pno'),
        columnWidth: '8%',
      },
      {
        columnType: 'string',
        entityField: 'userOrganisation',
        columnTitleKey: _('user_organisation'),
        columnWidth: '14%',
      },
      {
        columnType: 'string',
        entityField: 'userCostcentre',
        columnTitleKey: _('user_costcentre'),
        columnWidth: '14%',
      },
      {
        columnType: 'string',
        entityField: 'userLocation',
        columnTitleKey: _('user_location'),
        columnWidth: '14%',
      },
      {
        columnType: 'string',
        entityField: 'userRole',
        columnTitleKey: _('user_role'),
        columnWidth: '14%',
      },
      {
        columnType: 'date',
        entityField: 'userStarts',
        columnTitleKey: _('user_starts'),
        columnWidth: '8%',
      },
      {
        columnType: 'date',
        entityField: 'userEnds',
        columnTitleKey: _('user_ends'),
        columnWidth: '8%',
      },
      {
        columnType: 'tooltip',
        entityField: 'userEnabled',
        columnTitleKey: '',
        columnWidth: '32px',
      },
    ];
    public static FILTERS: CustomConfigurableModalFilter[] = [
      {
        filterApiPath: ApiPath.Users.Filters.HASALLOCATIONS,
        filterParams: UserParams.FILTER_HASALLOCATION_IDS,
        filterTitle: _('filter_allocation'),
      },
      {
        filterApiPath: ApiPath.Users.Filters.SHAREDS,
        filterParams: UserParams.FILTER_SHARED_IDS,
        filterTitle: _('filter_shared'),
      },
      {
        filterApiPath: ApiPath.Users.Filters.ORGANISATIONS,
        filterParams: UserParams.FILTER_ORGANISATION_IDS,
        filterTitle: _('filter_organisation'),
      },
      {
        filterApiPath: ApiPath.Users.Filters.COSTCENTRES,
        filterParams: UserParams.FILTER_COSTCENTRE_IDS,
        filterTitle: _('filter_costcentre'),
      },
      {
        filterApiPath: ApiPath.Users.Filters.LOCATIONS,
        filterParams: UserParams.FILTER_LOCATION_IDS,
        filterTitle: _('filter_location'),
      },
      {
        filterApiPath: ApiPath.Users.Filters.ROLES,
        filterParams: UserParams.FILTER_ROLE_IDS,
        filterTitle: _('filter_role'),
      },
      {
        filterApiPath: ApiPath.Users.Filters.ASSETS,
        filterParams: UserParams.FILTER_ASSET_IDS,
        filterTitle: _('filter_asset'),
      },
      {
        filterApiPath: ApiPath.Users.Filters.PRODUCT_FAMILIES,
        filterParams: UserParams.FILTER_PRODUCTFAMILY_IDS,
        filterTitle: _('filter_product_family'),
      },
      {
        filterApiPath: ApiPath.Users.Filters.BILLINGACCOUNTS,
        filterParams: UserParams.FILTER_BILLINGACCOUNT_IDS,
        filterTitle: _('filter_billingaccount'),
      },
      {
        filterApiPath: ApiPath.Users.Filters.SERVICES,
        filterParams: UserParams.FILTER_SERVICE_IDS,
        filterTitle: _('filter_service'),
      },
      {
        filterApiPath: ApiPath.Users.Filters.TYPES,
        filterParams: UserParams.FILTER_TYPES,
        filterTitle: _('filter_user_type'),
      },
      {
        filterApiPath: ApiPath.Users.Filters.GROUP_TYPES,
        filterParams: UserParams.FILTER_GROUP_TYPES,
        filterTitle: _('filter_user_group'),
      },
    ];
  };
  static Vendor = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'vendorName',
        columnTitleKey: _('vendor_name'),
        columnWidth: '40%',
      },
      {
        columnType: 'string',
        entityField: 'vendorAddress',
        columnTitleKey: _('vendor_address'),
        columnWidth: '60%',
      },
    ];
    public static COLUMNS_CONTRACT: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'vendorName',
        columnTitleKey: _('vendor_name'),
        columnWidth: '40%',
      },
      {
        columnType: 'string',
        entityField: 'vendorAddress',
        columnTitleKey: _('contract_vendor_address'),
        columnWidth: '60%',
      },
    ];
  };
  static VendorAlias = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'vendoraliasName',
        columnTitleKey: _('vendoralias_name'),
        columnWidth: '50%',
      },
      {
        columnType: 'string',
        entityField: 'vendoraliasVendor',
        columnTitleKey: _('vendoralias_vendor_name'),
        columnWidth: '50%',
      },
    ];
  };
  static VendorThreediProfiler = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'threedivendorName',
        columnTitleKey: _('threedivendor_name'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'threedivendorTechnicalArchitecture',
        columnTitleKey: _('threedivendor_technical_architecture'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'threedivendorSynopsis',
        columnTitleKey: _('threedivendor_synopsis'),
        columnWidth: '60%',
      },
    ];
  };
  static ProductThreediProfiler = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'threediproductName',
        columnTitleKey: _('threediproduct_name'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'threediproductDelivery',
        columnTitleKey: _('threediproduct_delivery'),
        columnWidth: '20%',
      },
      {
        columnType: 'string',
        entityField: 'threediproductSynopsis',
        columnTitleKey: _('threediproduct_synopsis'),
        columnWidth: '60%',
      },
    ];
  };
  static ExchangeThreediProfiler = class {
    public static COLUMNS: CustomConfigurableModalColumn[] = [
      {
        columnType: 'string',
        entityField: 'threediexchangeName',
        columnTitleKey: _('threediexchange_name'),
        columnWidth: '100%',
      },
      // {
      //   columnType: 'string',
      //   entityField: 'threedivendorTechnicalArchitecture',
      //   columnTitleKey: _('threedivendor_technical_architecture'),
      //   columnWidth: '20%',
      // },
      // {
      //   columnType: 'string',
      //   entityField: 'threedivendorSynopsis',
      //   columnTitleKey: _('threedivendor_synopsis'),
      //   columnWidth: '60%',
      // },
    ];
  };
}
