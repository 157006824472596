import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SidemenuItem } from '../../models/module-item';

export const featureAdapter: EntityAdapter<SidemenuItem> = createEntityAdapter<SidemenuItem>({
  selectId: (model) => model.sidemenuId,
  sortComparer: false,
});

export interface State extends EntityState<SidemenuItem> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
