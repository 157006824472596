import { Directive, HostListener } from '@angular/core';
import { EditableComponent } from './editable.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[editableOnButtonClick]',
})
export class EditableOnButtonClickDirective {
  constructor(private editable: EditableComponent) {}

  @HostListener('click', ['$event'])
  onClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.editable.toViewMode(true);
  }
}
