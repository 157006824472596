import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ImageFullscreenViewerEffects } from './effects';
import * as fromImageFullscreenViewer from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromImageFullscreenViewer.errorHandlingFeatureKey, fromImageFullscreenViewer.reducer),
    EffectsModule.forFeature([ImageFullscreenViewerEffects]),
  ],
})
export class ImageFullscreenViewerStoreModule {}
