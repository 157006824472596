import { MoveDeskData } from 'src/app/models/move-desk-data';
import { MoveInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-wizard/init-step/move-init-step';
import { MoveLicenseOptions } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-wizard/license-step/move-license-options';

export interface State {
  initStep: MoveInitStep;
  fromDeskStep: MoveDeskData;
  toDeskStep: MoveDeskData;
  licenseStep: MoveLicenseOptions[];
  // addInfoStep: AdditionalInfo;
  // cancelRequest: CancelLicenseServicesRequest;
  // cancelLicenseServicesResponse: CancelLicenseServicesResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  fromDeskStep: null,
  toDeskStep: null,
  licenseStep: null,
  // addInfoStep: null,
  // cancelRequest: null,
  // cancelLicenseServicesResponse: null,
  errorMessage: null,
};
