import { CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, map, merge } from 'rxjs';
import { DynamicFlatNode, GenericTreeNode } from 'src/app/models/tree-view-flat-node';
import { CoreDataService } from 'src/app/services/core-data.service';

@Injectable()
export class FileTreeViewDatasource {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);
  baseApiPath: string;

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: DynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private treeControl: FlatTreeControl<DynamicFlatNode>,
    private database: FileTreeViewDatabase,
    private coreDataService: CoreDataService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if ((change as SelectionChange<DynamicFlatNode>).added || (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(): void {}

  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  async fetchChilds(node: DynamicFlatNode): Promise<GenericTreeNode[]> {
    const { data } = await firstValueFrom(this.coreDataService.getGenericTreeNodeChilds(this.baseApiPath, node.item.entityId, 'nodes'));
    return data;
  }

  async addChildrenToParentNode(node: DynamicFlatNode) {
    const parentNode = this.data.find((n) => n.item.entityId === node.item.entityId);
    if (this.treeControl.isExpanded(parentNode)) {
      const index = this.data.indexOf(parentNode);
      parentNode.expandable = true;
      parentNode.item.entityChilds = node.item.entityChilds + 1;
      const childs = await this.fetchChilds(parentNode);
      if (childs) {
        this.database.setChildren(parentNode.item, childs);
        const nodes = childs.map(
          (item) =>
            new DynamicFlatNode(item, parentNode.level + 1, this.database.isExpandable(item), false, false, parentNode.item.entityId)
        );
        this.data.splice(
          index + 1,
          0,
          ...nodes.filter((node) => !this.data.find((dataNode) => node.item.entityId === dataNode.item.entityId))
        );
        // notify the change
        this.dataChange.next(this.data);
        node.isLoading = false;
      }
    } else {
      this.database.removeAllChildren(parentNode.item);
      parentNode.expandable = true;
      parentNode.item.entityChilds = node.item.entityChilds + 1;
      this.treeControl.toggle(parentNode);
    }
  }

  removeChildrenToParentNode(child: DynamicFlatNode) {
    //Child
    this.database.removeAllChildren(child.item);
    const index = this.data.indexOf(child);
    let count = 0;
    // remove childs of child
    for (let i = index + 1; i < this.data.length && this.data[i].level > child.level; i++, count++) {}
    this.data.splice(index + 1, count);
    // remove child
    this.data.splice(index, 1);
    // notify the change
    this.dataChange.next(this.data);

    //Parent
    const parentNode = this.data.find((n) => n.item.entityId === child.item.parentId);
    if (parentNode) {
      parentNode.item.entityChilds--;
      this.database.removeChildren(parentNode.item, child.item);
      if (parentNode.item.entityChilds === 0) {
        this.treeControl.toggle(parentNode);
        parentNode.expandable = false;
      }
    }
  }

  toggleNode(node: DynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);

    node.isLoading = true;

    if (expand) {
      const childs = this.database.getChildren(node.item);
      if (childs) {
        const nodes = childs.map(
          (item) => new DynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId)
        );
        this.data.splice(index + 1, 0, ...nodes);
        // notify the change
        this.dataChange.next(this.data);
        node.isLoading = false;
      } else {
        this.coreDataService.getGenericTreeNodeChilds(this.baseApiPath, node.item.entityId, 'nodes').subscribe(({ data }) => {
          if (data) {
            const nodes = data.map(
              (item) => new DynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId)
            );
            if (node.item.entityId === -1) {
              // static root node
              node.item.entityChilds = data.length;
            }
            this.database.setChildren(node.item, data);
            this.data.splice(index + 1, 0, ...nodes);
            // notify the change
            this.dataChange.next(this.data);
          }
          node.isLoading = false;
        });
      }
    } else {
      let count = 0;
      // eslint-disable-next-line no-empty
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
      this.data.splice(index + 1, count);
      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }
  }
}

@Injectable()
export class FileTreeViewDatabase {
  dataMap = new Map<GenericTreeNode, GenericTreeNode[]>([]);

  initialData(rootLevelNodes: GenericTreeNode[]): DynamicFlatNode[] {
    return rootLevelNodes.map((node) => new DynamicFlatNode(node, 0, node.entityChilds > 0));
  }

  setChildren(parentNode: GenericTreeNode, children: GenericTreeNode[]) {
    this.dataMap.set(parentNode, children);
  }

  removeChildren(parentNode: GenericTreeNode, node: GenericTreeNode) {
    if (parentNode) {
      const values: GenericTreeNode[] = this.dataMap.get(parentNode);
      const index: number = values.indexOf(node);
      if (index !== -1) {
        values.splice(index, 1);
      }
    } else {
      this.dataMap.delete(node);
    }
  }

  removeAllChildren(parentNode: GenericTreeNode) {
    this.dataMap.delete(parentNode);
  }

  getChildren(node: GenericTreeNode): GenericTreeNode[] | undefined {
    return this.dataMap.get(node);
  }

  isExpandable(node: GenericTreeNode): boolean {
    return node.entityChilds > 0;
  }
}
