import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

export enum CronHoursOptions {
  everyHour = 1,
  everyNHourStartFrom,
  specificHours,
  rangeHours,
}

@Component({
  selector: 'app-cron-hours-tab, cron-hours-tab',
  templateUrl: './cron-hours-tab.component.html',
  styleUrls: ['./cron-hours-tab.component.scss'],
})
export class CronHoursTabComponent implements OnInit {
  @Input() cronExpression: string;
  @Input() isEditing: boolean;
  @Output() hoursExpressionEvEm = new EventEmitter<string>();
  selectedOption: CronHoursOptions;
  hoursExpression: string;

  rangeHoursOptions = this.getRange(0, 23);
  everyNHourOptions = this.getRange(1, 24);

  everyNHour: number;
  everyNHourStartFrom: number;

  //specificHours
  specificHours: number[] = [];

  //rangeHours
  rangeHoursStarts: number;
  rangeHoursEnds: number;

  constructor() {}

  ngOnInit(): void {
    this.everyNHour = this.everyNHourOptions[0];
    this.everyNHourStartFrom = this.rangeHoursOptions[0];
    this.rangeHoursStarts = this.rangeHoursOptions[0];
    this.rangeHoursEnds = this.rangeHoursOptions[0];
    this.parseCronExpression();
  }

  parseCronExpression() {
    this.hoursExpression = this.cronExpression.split(' ')[2];
    if (this.hoursExpression === '*') {
      this.selectedOption = CronHoursOptions.everyHour;
    } else if (this.hoursExpression.match(/^\d{1,2}\/\d{1,2}$/)) {
      this.selectedOption = CronHoursOptions.everyNHourStartFrom;
      const cron_parts = this.hoursExpression.split('/');
      this.everyNHour = parseInt(cron_parts[1]);
      this.everyNHourStartFrom = parseInt(cron_parts[0]);
    } else if (this.hoursExpression.match(/^\d{1,2}\-\d{1,2}$/)) {
      this.selectedOption = CronHoursOptions.rangeHours;
      const cron_parts = this.hoursExpression.split('-');
      this.rangeHoursStarts = parseInt(cron_parts[0]);
      this.rangeHoursEnds = parseInt(cron_parts[1]);
    } else if (this.hoursExpression.match(/^\d{1,2}$|^(\d{1,2},)+\d{1,2}$/)) {
      this.selectedOption = CronHoursOptions.specificHours;
      this.specificHours = this.hoursExpression.split(',').map((n) => parseInt(n));
    }
  }

  selectAllCheckboxes() {
    this.specificHours = JSON.parse(JSON.stringify(this.rangeHoursOptions));
    this.regenerateCron();
  }

  resetCheckboxes() {
    this.specificHours = [];
    this.regenerateCron();
  }

  checkboxSelectionChanged($event: MatCheckboxChange) {
    this.selectedOption = CronHoursOptions.specificHours;
    const value = parseInt($event.source.value);
    const newChecked = $event.checked;
    if (newChecked) {
      this.specificHours.push(value);
      this.specificHours.sort(function (a, b) {
        return a - b;
      });
    } else {
      const index = this.specificHours.indexOf(value, 0);
      if (index > -1) {
        this.specificHours.splice(index, 1);
      }
    }
    this.regenerateCron();
  }

  regenerateCron() {
    switch (this.selectedOption) {
      case CronHoursOptions.everyHour:
        this.hoursExpression = '*';
        break;

      case CronHoursOptions.everyNHourStartFrom:
        this.hoursExpression = `${this.everyNHourStartFrom}/${this.everyNHour}`;
        break;

      case CronHoursOptions.rangeHours:
        this.hoursExpression = `${this.rangeHoursStarts}-${this.rangeHoursEnds}`;
        break;

      case CronHoursOptions.specificHours:
        this.hoursExpression = this.specificHours.length > 0 ? this.specificHours.join(',') : '0';
        break;
    }
    this.hoursExpressionEvEm.emit(this.hoursExpression);
  }

  isCheckboxChecked(n: number): boolean {
    return this.specificHours.includes(n);
  }

  get CronHoursOptions() {
    return CronHoursOptions;
  }

  private getRange(start: number, end: number): number[] {
    const length = end - start + 1;
    return Array.apply(null, Array(length)).map((__, i) => i + start);
  }
}
