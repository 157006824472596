import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

export interface AuthLicenseRenewalDialogData {
  settingLicenseRenewal: string;
}

@Component({
  selector: 'app-auth-license-renewal-dialog',
  templateUrl: './auth-license-renewal-dialog.component.html',
  styleUrls: ['./auth-license-renewal-dialog.component.scss'],
})
export class AuthLicenseRenewalDialogComponent extends FormatComponent implements OnInit {
  counter = 10;
  constructor(
    public dialogRef: MatDialogRef<AuthLicenseRenewalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AuthLicenseRenewalDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    setInterval(() => {
      this.counter = this.counter - 1;
      if (this.counter === 0) {
        this.dialogRef.close();
      }
    }, 1000);
  }
}
