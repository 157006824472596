import { KeyValue } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { Entity, EntityRecord, EntitySelection } from './entity';
import { MailTemplateFrame } from './mailtemplate/mail-template-particular';

export const REPORT_FORMATTED_TYPE = 1;
export const REPORT_PERIOD_CUSTOM = 0;
export const REPORT_PERIOD_ANY = 4;
export const REPORT_PDF_FORMAT = 4;
export const REPORT_CSV_FORMAT = 8;
export const REPORT_EXCEL_FORMAT = 16;
export enum ReportKindId {
  costreport = 1,
  snapshot,
  chargeback,
  dataquality,
}

export class ReportTemplate extends Entity {
  reporttemplateId: number;
  reporttemplateName: string;
  reporttemplateTypeId: number;
  reporttemplateType: string;
  reporttemplateKind: string;
  reporttemplateKindId: number;
  reporttemplateCustom: string;
  reporttemplateThumbnail: string;
}

export class ReportTemplateImage {
  image: string;
  thumbnail: string;
}

export class Report extends Entity {
  reportId: number;
  reportName: string;
  reportTemplate: string;
  reportTemplateId: number;
  reportType: string;
  reportTypeId: number;
  reportKind: string;
  reportKindId: number;
  reportAutoreport: string;
  reportFlyreport: string;
  reportCustom: string;
  reportOwner: string;
  reportCreated: Date;
  reportLastexecuted: Date;
  reportAutodistribution: boolean;
  reportFavorited: boolean;
}

export class ReportType {
  reporttypeId: number;
  reporttypeText: string;
}

export class ReportDetails {
  reportId: number;
}

export class ReportFilters {
  reportfilterId = -1;
  reportfilterBillingitemId: number;
  reportfilterBillingitems: ReportFiltersItem[] = [];
  reportfilterCostcentreId: number;
  reportfilterCostcentres: ReportFiltersItem[] = [];
  reportfilterDevicetypeId: number;
  reportfilterDevicetypes: ReportFiltersItem[] = [];
  reportfilterLicenseId: number;
  reportfilterLicenses: ReportFiltersItem[] = [];
  reportfilterLocationId: number;
  reportfilterLocations: ReportFiltersItem[] = [];
  reportfilterOrganisationId: number;
  reportfilterOrganisations: ReportFiltersItem[] = [];
  reportfilterPlatformId: number;
  reportfilterPlatforms: ReportFiltersItem[] = [];
  reportfilterServiceId: number;
  reportfilterServices: ReportFiltersItem[] = [];
  reportfilterServicetypeId: number;
  reportfilterServicetypes: ReportFiltersItem[] = [];
  reportfilterSiteId: number;
  reportfilterSites: ReportFiltersItem[] = [];
  reportfilterUserId: number;
  reportfilterUsers: ReportFiltersItem[] = [];
  reportfilterVendorId: number;
  reportfilterVendors: ReportFiltersItem[] = [];
  reportfilterVendoraccountId: number;
  reportfilterBillingaccounts: ReportFiltersItem[] = [];
  reportfilterBillingaccountId: number;
  reportfilterBillingaccounttypes: ReportFiltersItem[] = [];
  reportfilterBillingaccounttypeId: number;
  reportfilterCostcentretypes: ReportFiltersItem[] = [];
  reportfilterCostcentretypeId: number;
  reportfilterOrganisationtypes: ReportFiltersItem[] = [];
  reportfilterOrganisationtypeId: number;
  reportfilterTenantledgers: ReportFiltersItem[] = [];
  reportfilterTenantledgerId: number;
  reportfilterTenants: ReportFiltersItem[] = [];
  reportfilterTenantId: number;
}

export class ReportFiltersItem extends EntitySelection {
  reportfilteritemId: number;
}

export class ReportFields extends Entity {
  reportfieldId: number;
  reportfieldDbname: string;
  reportfieldDisplayname: string;
  reportfieldHide: string;
  reportfieldHideId: number;
  reportfieldFiltertype: string;
  reportfieldFiltertypeId: number;
  reportfieldSort: string;
  reportfieldSortId: number;
  reportfieldFiltervalue: string;
  reportfieldSortorder: number;
  reportfieldDeleted: boolean;
}

export class ReportAutodistribution {
  reportautodistributionId: number;
  reportautodistributionFirstSendout: Date;
  reportautodistributionDatePeriod: string;
  reportautodistributionDatePeriodId: number;
  reportautodistributionDatePeriodOptions: KeyValue<string, string>[];
  reportautodistributionEmailSchedule: boolean;
  reportautodistributionEmailBcc: EntityRecord[];
  reportautodistributionEmailCc: EntityRecord[];
  reportautodistributionEmailTo: EntityRecord[];
  reportautodistributionMailframe: MailTemplateFrame;
  reportautodistributionMailframeId: number;
  reportautodistributionEmailTimestamp: boolean;
  reportautodistributionEmailTimestampFormatId: number;
  reportautodistributionEmailTimestampFormat: string;
  reportautodistributionEmailZipFile: boolean;
  reportautodistributionFtpSchedule: boolean;
  reportautodistributionFtpConnection: string;
  reportautodistributionFtpConnectionId: number;
  reportautodistributionFtpPath: string;
  reportautodistributionFtpTimestamp: boolean;
  reportautodistributionFtpTimestampFormatId: number;
  reportautodistributionFtpTimestampFormat: string;
  reportautodistributionFtpZipFile: boolean;
  reportautodistributionFileSchedule: boolean;
  reportautodistributionFileServerPath: string;
  reportautodistributionFileTimestamp: boolean;
  reportautodistributionFileTimestampFormatId: number;
  reportautodistributionFileTimestampFormat: string;
  reportautodistributionFileZipFile: boolean;
  reportautodistributionEnabled: boolean;
  reportautodistributionTimestampFormatOptions: KeyValue<string, string>[];
  reportautodistributionTimezone: string;
  //not used into mockup
  reportautodistributionFtpLogin: string;
  reportautodistributionFtpPort: number;
  reportautodistributionFtpProtocol: string;
  reportautodistributionFtpProtocolId: number;
  reportautodistributionFtpServer: string;
  reportautodistributionJob: string;
  reportautodistributionJobId: number;
  reportautodistributionSchedule: string;
  reportautodistributionScheduleCronpattern: string;
  reportautodistributionScheduleId: number;
}

export class ReportAutodistributionRequest {
  reportautodistributionId: number;
  reportautodistributionFirstSendout: string;
  reportautodistributionDatePeriodId: number;
  reportautodistributionEmailSchedule: boolean;
  reportautodistributionEmailBcc: EntityRecord[];
  reportautodistributionEmailCc: EntityRecord[];
  reportautodistributionEmailTo: EntityRecord[];
  reportautodistributionMailframe: MailTemplateFrame;
  reportautodistributionEmailTimestamp: boolean;
  reportautodistributionEmailTimestampFormatId: number;
  reportautodistributionEmailZipFile: boolean;
  reportautodistributionFtpSchedule: boolean;
  reportautodistributionFtpConnectionId: number;
  reportautodistributionFtpPath: string;
  reportautodistributionFtpTimestamp: boolean;
  reportautodistributionFtpTimestampFormatId: number;
  reportautodistributionFtpZipFile: boolean;
  reportautodistributionFileSchedule: boolean;
  reportautodistributionFileServerPath: string;
  reportautodistributionFileTimestamp: boolean;
  reportautodistributionFileTimestampFormatId: number;
  reportautodistributionFileZipFile: boolean;
  reportautodistributionTimezone: string;
}

export class ReportAvailableField {
  category: string;
  reportavailablefieldId?: number;
  reportavailablefieldName?: string;
  reportavailablefieldDbname?: string;
  reportavailablefieldDisplayname?: string;
  children?: ReportAvailableField[];
}

export class ReportSettings {
  reportsettingId: number;
  reportsettingName: string;
  reportsettingReportId: number;
  reportsettingReport: string;
  reportsettingTitle: string;
  reportsettingShowtitle: boolean;
  reportsettingShowzerocosts: boolean;
  reportsettingShowtotalrow: boolean;
  reportsettingShowlogo: boolean;
  reportsettingShowheader: boolean;
  reportsettingShowfooter: boolean;
  reportsettingShowusefeeddates: boolean;
  reportsettingUsefeeddates: boolean;
  reportsettingShowcosttype: boolean;
  reportsettingCosttype: number;
  reportsettingCosttypeoptions: KeyValue<string, string>[];
  reportsettingFormat: number;
  reportsettingFormatoptions: KeyValue<string, string>[];
  reportsettingCsvDateFormat: string;
  reportsettingCsvDelimiter: string;
  reportsettingDecimalSeparator: string;
  reportsettingTextQuote: string;
  reportsettingTextEncoding: string;
  reportsettingTextEncodingId: number;
  reportsettingTextEncodingOptions: KeyValue<string, string>[];
  reportsettingShowcurrency: boolean;
  reportsettingCurrencyId: number;
  reportsettingShowperiod: boolean;
  reportsettingPeriodtype: number;
  reportsettingPeriodtypeoptions: KeyValue<string, string>[];
  reportsettingPeriodkind: number;
  reportsettingPeriodkindoptions: KeyValue<string, string>[];
  reportsettingShowstarts: boolean;
  reportsettingStarts: Date;
  reportsettingShowends: boolean;
  reportsettingEnds: Date;
  reportsettingLoginInTeam: boolean;
  reportsettingAssignedRole: string;
  reportsettingAssignedRoleId: number;
  reportsettingAssignedUser: string;
  reportsettingAssignedUserId: number;
  reportsettingAssignedUserTakeOwnership: boolean;
  reportsettingAssignedRoleUsers: KeyValue<string, string>[];
  reportsettingAutofilterheader: boolean;
  reportsettingAnonymise: boolean;
  reportsettingDateFormat: string;
  reportsettingPageSize: string;
  reportsettingPageSizeId: number;
  reportsettingPageSizeOptions: KeyValue<string, string>[];
  reportsettingTemplateExcelFile: string;
  reportsettingTemplateExcelFileId: number;
  reportsettingTemplateExcelSheetName: string;
}

export class ReportSettingsRequest {
  reportSourceId: number;
  reportName: string;
  reportReportId: number;
  reportTitle: string;
  reportShowtitle: boolean;
  reportShowzerocosts: boolean;
  reportShowtotalrow: boolean;
  reportShowlogo: boolean;
  reportShowheader: boolean;
  reportShowfooter: boolean;
  reportUsefeeddates: boolean;
  reportAutofilterheader: boolean;
  reportAnonymise: boolean;
  reportCosttype: number;
  reportFormat: number;
  reportCsvDateFormat: string;
  reportCsvDelimiter: string;
  reportDecimalSeparator: string;
  reportTextQuote: string;
  reportTextEncodingId: number;
  reportCurrencyId: number;
  reportPeriodtype: number;
  reportPeriodkind: number;
  reportStarts: string;
  reportEnds: string;
  reportFields: ReportFields[];
  reportFilter: ReportFilters;
  reportAssignedRoleId: number;
  reportAssignedUserId: number;
  reportPageSizeId: number;
  reportTemplateExcelFileId: number;
  reportTemplateExcelSheetName: string;
}

export class ReportCategory extends Entity {
  reportcategoryDefault: boolean;
  reportcategoryCustom: boolean;
  reportcategoryId: number;
  reportcategoryKind: string;
  reportcategoryKindId: number;
  reportcategoryName: string;
}

export class ReportCategoryLink extends Entity {
  reportcategorylinkCategory: string;
  reportcategorylinkCategoryId: number;
  reportcategorylinkId: number;
  reportcategorylinkKind: string;
  reportcategorylinkKindId: number;
  reportcategorylinkTemplate: string;
  reportcategorylinkTemplateId: number;
}

export class ReportParams {
  public static FILTER_AUTODISTRIBUTION_IDS = 'filterAutodistributionIds';
  public static FILTER_CATEGORY_IDS = 'filterCategoryIds';
  public static FILTER_CURRENCY_IDS = 'filterCurrencyIds';
  public static FILTER_FORMAT_IDS = 'filterFormatIds';
  public static FILTER_KIND_IDS = 'filterKindIds';
  public static FILTER_OUTPUT_IDS = 'filterOutputIds';
  public static FILTER_OWNER_IDS = 'filterOwnerIds';
  public static FILTER_REPORT_IDS = 'filterReportIds';
  public static FILTER_TEMPLATE_IDS = 'filterTemplateIds';
  public static FILTER_TYPE_IDS = 'filterTypeIds';
}

export class ReportKind {
  public static REPORT_COSTREPORT = 'costreport';
  public static REPORT_SNAPSHOT = 'snapshot';
  public static REPORT_CHARGEBACK = 'chargeback';
  public static REPORT_DATAQUALITY = 'dataquality';
}

export function getReportRequestParams(request: PaginatedRequest): HttpParams {
  return PaginatedRequest.getBaseRequestParams(request);
}
