import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { FeedItem } from 'src/app/models/feeditem';
import { AddSubscriptionsBillingNode } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-subscriptions-wizard/add-subscriptions-billing-step/add-subscriptions-billing-node';
import { AddSubscriptionsRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-subscriptions-wizard/add-subscriptions-confirm-step/add-subscriptions-request';
import { AddSubscriptionsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-subscriptions-wizard/add-subscriptions-duedate-step/add-subscriptions-duedate-step';
import { AddSubscriptionsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-subscriptions-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Add Subscriptions Information Step',
  props<{ initStep: AddSubscriptionsInitStep }>()
);

export const selectDueDatesStep = createAction(
  '[Wizard Due Date Step] Select Add Subscriptions Due Date Step',
  props<{ dueDatesStep: AddSubscriptionsDueDates }>()
);

export const selectServicesStep = createAction(
  '[Wizard Services Step] Select Add Subscriptions Services Step',
  props<{ servicesStep: FeedItem[] }>()
);

export const selectBillingStep = createAction(
  '[Wizard Billing Step] Select Add Subscriptions Billing Step',
  props<{ billingStep: AddSubscriptionsBillingNode[] }>()
);

export const selectValidationStep = createAction(
  '[Wizard Validation Step] Select Add Subscriptions Validation Step',
  props<{ validationStep: AddSubscriptionsBillingNode[] }>()
);

export const exitAddSubscriptionsWizard = createAction('[AddSubscriptions Wizard Page] Exit Add Subscriptions Wizard');

export const destroyWizard = createAction('[AddSubscriptions Wizard Page] Destroy Wizard');

export const createNewAddSubscriptions = createAction(
  '[Wizard Confirmation Step] Create Add Subscriptions Information Step',
  props<{ request: AddSubscriptionsRequest }>()
);

export const createNewAddSubscriptionsSuccess = createAction(
  '[Wizard Confirmation Step] Create Add Subscriptions Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewAddSubscriptionsFail = createAction(
  '[Wizard Confirmation Step] Create Add Subscriptions Information Step Fail',
  props<{ errorMessage: string }>()
);
