import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of, throwError } from 'rxjs';
import { SwapcontractWizardDataService } from 'src/app/services/wizards/swapcontract-wizard-data.service';
import { SwapContractWizardActions } from '.';

@Injectable()
export class SwapContractWizardStoreEffects {
  validation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SwapContractWizardActions.validateSwapRequest),
      concatMap(({ request }) =>
        this.swapContractWizardService.validateSwapLicense(request).pipe(
          map((response) => {
            if (response) {
              return SwapContractWizardActions.validateSwapRequestResult({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            let errorMessage = '';
            if (error instanceof HttpErrorResponse) {
              errorMessage = error.error.errorKey;
            } else {
              errorMessage = error;
            }
            return of(SwapContractWizardActions.validateSwapRequestFail({ errorMessage }));
          })
        )
      )
    )
  );

  newOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SwapContractWizardActions.createNewOrder),
      concatMap(({ newOrder }) =>
        this.swapContractWizardService.swapLicense(newOrder).pipe(
          map((response) => {
            if (response) {
              return SwapContractWizardActions.createNewOrderSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            let errorMessage = '';
            if (error instanceof HttpErrorResponse) {
              errorMessage = error.error.errorKey;
            } else {
              errorMessage = error;
            }
            return of(SwapContractWizardActions.createNewOrderFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private swapContractWizardService: SwapcontractWizardDataService) {}
}
