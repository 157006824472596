import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select } from '@ngrx/store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

@Component({
  selector: 'app-demopage',
  templateUrl: './demopage.component.html',
  styleUrls: ['./demopage.component.scss'],
})
export class DemoPageComponent extends FormatComponent implements OnInit {
  demoHtml = `<p>Coming soon<p>`;

  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesDemoHtml)), (demoHtml) => {
      LogService.debug(this, this.ngOnInit.name, 'Preference demo html', demoHtml);
      if (demoHtml) {
        this.demoHtml = demoHtml;
      }
    });
  }
}
