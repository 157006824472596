import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { BillingItemUsage, BillingItemUsageRequest } from 'src/app/models/billing-item-usage';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { UsageDataService } from 'src/app/services/usage-data.service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

export interface UsageDialogData {
  isEditing: boolean;
  labelName: string;
  invoiceId: number;
  billingItemId: number;
  starts: Date;
  ends: Date;
}

@Component({
  selector: 'app-service-usage-modal',
  templateUrl: './service-usage-modal.component.html',
  styleUrls: ['./service-usage-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceUsageModalComponent extends FormatComponent implements AfterViewInit {
  @ViewChild('paginatorTable') paginatorTable: MatPaginator;
  @ViewChild('sortTable') sortTable: MatSort;
  @ViewChild('confirmCancelButton') confirmCancelButton: MatButton;

  noEntityData = false;
  isLoading = true;
  pageTotalElements = 0;
  pageSizeList: number[] = [];
  request: PaginatedRequest;
  filterValue: string;
  enableConfirmCancel = false;
  isEditing: boolean;

  dataSourceTable: MatTableDataSource<BillingItemUsage> = new MatTableDataSource<BillingItemUsage>();
  displayedColumnsTable: string[] = [
    'usageAsset',
    'usageStarts',
    'usageEnds',
    'usageUnits',
    // 'usageUnitscredit',
    'usagePrice',
    // 'usagePricecredit',
  ];

  controls: UntypedFormArray;

  constructor(
    private usageDataService: UsageDataService,
    public dialogRef: MatDialogRef<ServiceUsageModalComponent>,
    private crd: ChangeDetectorRef,
    private messageNotifierService: MessageNotifierService,
    @Inject(MAT_DIALOG_DATA) public data: UsageDialogData
  ) {
    super();
  }

  ngAfterViewInit() {
    this.isEditing = this.data.isEditing;
    this.subscribe(this.store.select(PreferencesSelectors.selectPreferencesPaginatonsOptions), (pageSizeOpt) => {
      // Wait for selected product
      if (pageSizeOpt && pageSizeOpt.length !== 0) {
        this.pageSizeList = pageSizeOpt;
        this.paginatorTable.pageSize = this.pageSizeList[0];
        this.request = {
          pageIndex: 0,
          pageSize: pageSizeOpt[0],
          orderBy: this.gbGetInitialOrderBy(),
          sort: this.gbGetInitialSort(),
        };
        this.crd.detectChanges();
        // Table Initialization
        this.loadServicesData();
      }
    });
    this.sortTable.sortChange.subscribe(() => {
      this.setOrder();
      this.loadServicesData();
    });
    this.paginatorTable.page.subscribe(() => {
      this.setPage();
      this.loadServicesData();
    });
  }

  gbGetInitialOrderBy() {
    return 'entityName';
  }

  gbGetInitialSort() {
    return 'asc';
  }

  loadServicesData() {
    this.dataSourceTable = new MatTableDataSource();
    this.isLoading = true;
    this.usageDataService
      .getBillingItemsUsages(this.request, this.data.billingItemId, null, this.dateCast(this.data.starts), this.dateCast(this.data.ends))
      .subscribe(({ data }) => {
        this.isLoading = false;
        if (data && data.length > 0) {
          this.dataSourceTable.data = data;
          this.pageTotalElements = data[0].entityCount;
          this.crd.detectChanges();
          this.initForm();
        } else {
          this.pageTotalElements = 0;
          this.noEntityData = true;
        }
      });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onCancel($event) {
    if (this.controls && this.controls.dirty) {
      this.enableConfirmCancel = true;
      this.crd.detectChanges();
      this.confirmCancelButton.focus();
    } else {
      this.onConfirmCancel($event);
    }
    $event.stopPropagation();
  }

  onConfirmCancel($event) {
    this.dialogRef.close();
    this.enableConfirmCancel = false;
    $event.stopPropagation();
  }

  /* INLINE EDITOR */

  isDirtyRow(index: number) {
    return this.controls && this.controls.at(index).dirty;
  }

  isValidRow(index: number) {
    return this.controls && this.controls.at(index).valid;
  }

  isValorized(value: number): boolean {
    return value !== null && value !== undefined;
  }

  isNegativeId(row: BillingItemUsage) {
    return row.usageId < 0;
  }

  updateField($event, index: number, field: string) {
    // console.log('updateField', { $event, index, field });
    // console.log('updateField', this.dataSourceTable.data);
    const control = this.getControl(index, field);
    if ($event && this.dataSourceTable.data[index][field] !== $event) {
      control.markAsDirty();
    }
    if (field === 'usagePrice') {
      this.dataSourceTable.data[index][field] =
        $event != null
          ? $event != ''
            ? this.castToFloat2Decimal($event)
            : null
          : control.value != ''
          ? this.castToFloat2Decimal(control.value)
          : null;
      if ($event != null) {
        control.setValue($event != '' ? $event : null);
        control.updateValueAndValidity();
      }
    } else {
      this.dataSourceTable.filteredData[index][field] = $event ? $event : control.value; // When enter is pressed, value is passed by event emitter
      if ($event) {
        control.setValue($event);
      }
    }
  }

  resetField(index: number, field: string) {
    console.log('resetField', { index, field });
    const originalValue = this.dataSourceTable.data[index][field];
    this.getControl(index, field).setValue(originalValue);
  }

  getControl(index: number, fieldName: string) {
    const a = this.controls.at(index).get(fieldName) as UntypedFormControl;
    return this.controls.at(index).get(fieldName) as UntypedFormControl;
  }

  private initForm() {
    const toGroups = this.dataSourceTable.data.map((row) => {
      return new UntypedFormGroup(
        {
          usageUnits: new UntypedFormControl(row.usageUnits, Validators.required),
          // usageUnitscredit: new FormControl(row.usageUnitscredit, Validators.required),
          usagePrice: new UntypedFormControl(this.getMoneyEdit(row.usagePrice), Validators.required),
          // usagePricecredit: new FormControl(row.usagePricecredit, Validators.required),
        },
        { updateOn: 'blur' }
      );
    });
    this.controls = new UntypedFormArray(toGroups);
  }

  saveRows() {
    // if (row.usageId > 0) {
    //   this.updateRow(row);
    // } else {
    //   this.createRow(row);
    // }
    const dirtyRows = this.dataSourceTable.data.filter((r, i) => this.isDirtyRow(i));
    this.subscribe(this.usageDataService.saveUsages(dirtyRows.map((dr) => this.buildRowRequest(dr))), (response) => {
      if (response.data) {
        if (response.data) {
          if (response.data.state) {
            this.messageNotifierService.showSuccessMessage(_('toastr_service_usage_success_created'));
            this.dialogRef.close(true);
          } else if (response.data.error) {
            // Fail
            this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
          }
        } else {
          this.messageNotifierService.showWarningMessage(_('toastr_no_data'));
        }
      }
    });
  }

  private buildRowRequest(row: BillingItemUsage): BillingItemUsageRequest {
    return {
      usageId: row.usageId,
      usageInvoiceId: this.data.invoiceId,
      usageBillingitemId: row.usageBillingitemId,
      usageLicenseId: row.usageLicenseId,
      usageStarts: row.usageStarts,
      usageEnds: row.usageEnds,
      usagePrice: row.usagePrice,
      usagePricecredit: row.usagePricecredit,
      usageUnits: row.usageUnits,
      usageUnitscredit: row.usageUnitscredit,
    };
  }

  /* TABLE METHODS */

  private setFilter(filter: string) {
    this.request = {
      ...this.request,
      filter,
    };
  }

  resetFilter() {
    if (this.filterValue) {
      this.filterValue = '';
      this.applyFilterTable(this.filterValue);
    }
  }

  applyFilterTable(filterValue: string) {
    const m = this.applyFilterTable.name;
    if (filterValue && filterValue.length >= 3) {
      this.setFilter(filterValue);
      this.loadServicesData();
    } else {
      if (!filterValue || (filterValue && filterValue.length === 0)) {
        this.setFilter(null);
        this.loadServicesData();
      }
    }
  }

  private setOrder() {
    this.request = {
      ...this.request,
      orderBy: this.sortTable.active,
      sort: this.sortTable.direction,
    };
  }

  private setPage() {
    this.request = {
      ...this.request,
      pageSize: this.paginatorTable.pageSize,
      pageIndex: this.paginatorTable.pageIndex,
    };
  }
}
