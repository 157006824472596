<div id="file-modal" class="entity-sidenav-container">
  <mat-tab-group class="entity-tab-group" id="file-tab-group">
    <!-- GENERIC TAB -->
    <!-- <mat-tab label="{{ 'files_generic_tree' | translate }}">
      <ng-template matTabContent>
        <app-file-tab [tabType]="FileTabType.generic" (fileSelected)="fileSelected($event)" [isModal]="true"></app-file-tab>
      </ng-template>
    </mat-tab> -->
    <!-- COMPANY TAB -->
    <mat-tab label="{{ 'files_company_tree' | translate }}">
      <ng-template matTabContent>
        <app-file-tab [tabType]="FileTabType.company" (fileSelected)="fileSelected($event)" [isModal]="true"></app-file-tab>
      </ng-template>
    </mat-tab>
    <!-- USER TAB -->
    <mat-tab label="{{ 'files_user_tree' | translate }}">
      <ng-template matTabContent>
        <app-file-tab [tabType]="FileTabType.user" (fileSelected)="fileSelected($event)" [isModal]="true"></app-file-tab>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div class="button-wrapper" mat-dialog-actions>
    <button (click)="onCancel()" mat-raised-button color="primary">
      {{ 'label_cancel' | translate }}
    </button>
    <button [mat-dialog-close]="selectedFile" mat-raised-button [disabled]="!selectedFile" color="primary">
      {{ 'label_select' | translate }}
    </button>
  </div>
</div>
