// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LogLevel } from 'src/app/configs/log-level';

export const environment = {
  production: false,
  poweredByFull: 'provided by BST',
  poweredByReduced: 'BST',

  logLevel: LogLevel.DEBUG,
  hideOutletUrl: false,
  getEndpoint(path: string) {
    return `${path}`;
  },
  globalSearchPollingIntervall: 1000,
  serverUnreachablePollingIntervall: 15000, //15 seconds
  notificationPollingIntervall: 300000, //5 minutes
  favicon: '../assets/favicon/default/favicon.ico',
  serverUnreachable: '../assets/imgs/default/serverdown.png',
  defaultTitle: 'FinOffice',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
