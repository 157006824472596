import { Component } from '@angular/core';
import { FormFieldChipsOptionDisplayOption } from 'src/app/models/forms/form-configuration-option';
import { BaseFormFieldConfigComponent } from '../base-form-field-config/base-form-field-config-component';

@Component({
  selector: 'app-form-field-config-chips',
  templateUrl: './form-field-config-chips.component.html',
  styleUrls: ['./form-field-config-chips.component.scss'],
})
export class FormFieldConfigChipsComponent extends BaseFormFieldConfigComponent {
  constructor() {
    super();
  }

  displayedColumnsTable = ['entity_name'];
  mockEntities: string[] = ['Entity 1', 'Entity 2', 'Entity 3'];

  get FormFieldChipsOptionDisplayOption() {
    return FormFieldChipsOptionDisplayOption;
  }
}
