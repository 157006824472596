import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GenericFormField } from 'src/app/models/forms/form-field';

export const featureAdapter: EntityAdapter<GenericFormField> = createEntityAdapter<GenericFormField>({
  selectId: (model) => model.formfieldId,
});

export interface State extends EntityState<GenericFormField> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
