import { KeyValueAddon2 } from './core/key-value-data';
import { Entity } from './entity';

export class GridFilterResponse {
  filters: GridFilter[];
  searchElements: PropertySearchElement[];
}

export class PropertySearchElement extends Entity {
  propertysearchelementId: number;
  propertysearchelementName: string;
  propertysearchelementProperty: string;
  propertysearchelementPropertyId: number;
  propertysearchelementElement: string;
  propertysearchelementActive: boolean;
  propertysearchelementColumn: string;
  propertysearchelementPropertyColumn: string;
  propertysearchelementPropertyColumnId: number;
  propertysearchelementSortorder: number;
  propertysearchelementTableAlias: string;
  propertysearchelementTranslations: KeyValueAddon2<string, string, string>[];
}

export class GridFilter extends Entity {
  gridfilterFavorited: boolean;
  gridfilterId: number;
  gridfilterName: string;
  gridfilterPath: string;
  gridfilterSortorder: number;
  gridfilterType: string;
  gridfilterVariable: string;
  gridfilterDefaultValues: string[];
  gridfilterGrid: string;
  gridfilterReferenceFilterIds: string;
}

export enum GridFilterType {
  integer = 'integer',
  string = 'string',
  date = 'date',
  datetime = 'datetime',
}
