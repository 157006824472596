import { Action, createReducer, on } from '@ngrx/store';
import * as GlobalSearchStoreActions from './actions';
import { State, featureAdapter, initialState } from './state';

export const globalSearchStoreFeatureKey = 'globalSearchStore';

const globalSearchStoreReducer = createReducer(
  initialState,

  on(GlobalSearchStoreActions.requestNewGlobalSearch, (state, { filter }) => {
    const oldFilter = JSON.parse(JSON.stringify(state.newFilter));
    return featureAdapter.removeAll({ ...state, oldFilter, newFilter: filter, error: null });
  }),
  on(GlobalSearchStoreActions.stopPreviousSearch, (state) => ({ ...state })),
  on(GlobalSearchStoreActions.stopPreviousSearchSuccess, (state) => ({ ...state, isFinished: true })),
  on(GlobalSearchStoreActions.startNewSearch, (state, { filter }) => ({ ...state, newFilter: filter })),
  on(GlobalSearchStoreActions.startNewSearchSuccess, (state, { searchId }) => ({ ...state, searchId, isFinished: false })),
  on(GlobalSearchStoreActions.startPollingSearchResult, (state) => ({ ...state })),
  on(GlobalSearchStoreActions.storePollingSearchResultData, (state, { response }) =>
    featureAdapter.addMany(response.data.results, { ...state, isFinished: response.data.finished })
  ),
  on(GlobalSearchStoreActions.setFilterEntityKinds, (state, { filterEntityKind }) => ({ ...state, filterEntityKind })),
  on(GlobalSearchStoreActions.globalSearchError, (state, { error }) => ({ ...state, error, isFinished: true })),
  on(GlobalSearchStoreActions.destroySearch, (state) => ({
    ...state,
    oldFilter: null,
    newFilter: null,
    filterEntityKind: null,
    isFinished: true,
    error: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return globalSearchStoreReducer(state, action);
}
