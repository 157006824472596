<div
  id="entity-files-table"
  class="entity-table"
  appFileDragDrop
  borderColor="#dddddd"
  [dragOverBorderColor]="$dragOverBorderColor"
  (fileDropped)="uploadFiles($event)"
  (folderDropped)="showDropFolderError()"
  [dropDisabled]="isUploadDisabled()"
>
  <div class="table-wrapper">
    <div class="table-search-paginate" [ngClass]="!enableFilter ? 'hidden-filters' : ''">
      <div class="table-search-paginate-left-wrapper">
        <mat-form-field appearance="outline" class="reset-outline-hint filter-field search"
          ><button *ngIf="filter" mat-icon-button matSuffix class="small-icon" (click)="clearSearchFilter(); $event.stopPropagation()">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-autocomplete
            (opened)="matAutocompletPanelOpened()"
            (closed)="matAutocompletPanelClosed()"
            #autoLastStringOption="matAutocomplete"
            (optionSelected)="applyFilterTable($event.option.value)"
          >
            <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
              {{ 'label_recent_searches' | translate }}
            </mat-option>
            <mat-option class="form-field-option" *ngFor="let opt of lastUsedFilters" [value]="opt" [class.mat-selected]="filter === opt">
              {{ opt }}
            </mat-option>
          </mat-autocomplete>
          <input
            #searchInputField
            #searchInputAutocompleteTrigger="matAutocompleteTrigger"
            (input)="searchInputAutocompleteTrigger.closePanel()"
            matInput
            [(ngModel)]="filter"
            [matAutocomplete]="autoLastStringOption"
            (debounceFilterInput)="applyFilterTable($event)"
            [debounceTime]="600"
            maxlength="1500"
            placeholder="{{ 'entity_files_table_filter_placeholder' | translate }}"
          />
        </mat-form-field>
        <span style="padding-right: 0.5rem"></span>
        <!-- BUTTONS WRAPPER-->
        <div class="files-buttons-container" *ngIf="!isModal">
          <mat-checkbox
            *ngIf="tabType !== FileTabType.generic"
            [(ngModel)]="subdirectoryChecked"
            (change)="reloadData()"
            [disabled]="!selectedTreeNode"
            >{{ 'files_table_subdirectory_checked' | translate }}</mat-checkbox
          >
        </div>
      </div>
      <!-- BUTTONS WRAPPER-->
      <div class="table-search-paginate-right">
        <input hidden type="file" #fileInput (change)="uploadFiles($event.target.files)" multiple />
        <mat-button-toggle-group [(ngModel)]="showThumbnail">
          <mat-button-toggle [value]="false" class="view-toggle">
            <mat-icon>view_list</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle [value]="true" class="view-toggle">
            <mat-icon>view_module</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="table-container">
      <table
        *ngIf="!showThumbnail"
        class="table table-stripes table-truncate-text"
        mat-table
        [dataSource]="dataSourceTable"
        matSort
        #sortTable="matSort"
        [matSortActive]="gbGetInitialOrderBy()"
        [matSortDirection]="gbGetInitialSort()"
        matSortDisableClear
      >
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? onCheckboxClicked(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="radio">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="radio-button-wrapper">
            <mat-radio-button
              class="table-radio-button"
              (change)="onEntitySelected(row)"
              disableRipple="true"
              [checked]="selectedEntity && selectedEntity.entityId === row.entityId"
            ></mat-radio-button>
          </td>
        </ng-container>
        <ng-container matColumnDef="filenodeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'entity_file_name' | translate }}
          </th>
          <td
            mat-cell
            showIfTruncated
            [matTooltip]="row.filenodeName"
            *matCellDef="let row"
            (click)="$event.stopPropagation(); downloadFile(row)"
          >
            <a href="javascript:void(0)">{{ row.filenodeName }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="filenodeType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'entity_file_type' | translate }}
          </th>
          <td mat-cell showIfTruncated [matTooltip]="row.filenodeType" *matCellDef="let row">{{ row.filenodeType }}</td>
        </ng-container>

        <ng-container matColumnDef="filenodeSize">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'entity_file_size' | translate }}
          </th>
          <td mat-cell showIfTruncated matTooltip="{{ row.filenodeSize | filesize }}" *matCellDef="let row">
            {{ row.filenodeSize | filesize }}
          </td>
        </ng-container>

        <ng-container matColumnDef="filenodeUploaded">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'entity_file_upload_date' | translate }}
          </th>
          <td mat-cell showIfTruncated [matTooltip]="getDatetime(row.filenodeUploaded)" *matCellDef="let row">
            {{ getDatetime(row.filenodeUploaded) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="filenodeUploadedby">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'entity_file_uploaded_by' | translate }}
          </th>
          <td mat-cell showIfTruncated [matTooltip]="row.filenodeUploadedby" *matCellDef="let row">{{ row.filenodeUploadedby }}</td>
        </ng-container>

        <ng-container matColumnDef="actionButtons">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="icondelete-header"></th>
          <td mat-cell *matCellDef="let row" class="icondelete-cell">
            <mat-icon
              *ngIf="tabType !== FileTabType.generic && row.entityDelete"
              (click)="deleteFile(row); $event.stopPropagation()"
              style="color: red"
              fontSet="fas"
              fontIcon="fa-times"
              matTooltip="{{ 'label_action_remove' | translate }}"
              [matTooltipClass]="'fo-mat-tooltip'"
            ></mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsTable"
          (click)="displayDetails(row)"
          [ngClass]="selectedEntity.entityId === row.entityId ? 'selected-row' : ''"
        ></tr>
      </table>
      <div class="thumbnails-container" *ngIf="showThumbnail && dataSourceTable.data.length > 0">
        <mat-card *ngFor="let file of dataSourceTable.data" class="thumbnail-card">
          <div class="main-container">
            <div class="title-container">
              <span class="title">{{ file.filenodeName }}</span>
            </div>
            <div class="image-container">
              <ng-container *ngIf="file.filenodeThumbnail != null">
                <img
                  class="image"
                  [src]="transform(file.filenodeThumbnail)"
                  alt="File Preview Thumbnail"
                  (click)="$event.stopPropagation(); previewFile(file)"
                />
              </ng-container>
              <ng-container *ngIf="file.filenodeThumbnail == null">
                <span>{{ 'label_no_file_preview_available' | translate }}</span>
              </ng-container>
            </div>
            <div class="button-container">
              <button mat-stroked-button color="primary" (click)="$event.stopPropagation(); downloadFile(file)">
                <span>{{ 'label_download' | translate }}</span>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
      <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
        <mat-toolbar-row *ngIf="isLoading" class="spinner">
          <mat-spinner diameter="50"></mat-spinner>
        </mat-toolbar-row>
        <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
          <span>{{ 'entity_files_table_error_nodata' | translate }}</span>
        </mat-toolbar-row>
      </mat-toolbar>
      <mat-toolbar class="table-footer-toolbar">
        <mat-toolbar-row>
          <mat-paginator
            [disabled]="isLoading"
            #paginatorTable
            [pageSizeOptions]="pageSizeList"
            [length]="pageTotalElements"
            class="reset-paginator"
          ></mat-paginator>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  </div>
</div>
