import { ActionResponse } from 'src/app/models/action-response';
import { AssetSubscription } from 'src/app/models/asset-subscription';
import { Desk } from 'src/app/models/desk';
import { FeedItem } from 'src/app/models/feeditem';
import { CopyAssetDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-asset-wizard/activation-step/copy-asset-due-date-info';
import { CopyAssetAdditionalInfo } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-asset-wizard/additional-step/additional-info';
import { CopyAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-asset-wizard/init-step/init-step';

export interface State {
  initStep: CopyAssetInitStep;
  initAssetServiceStep: AssetSubscription[];
  deskStep: Desk;
  dueDatesStep: CopyAssetDueDates;
  serviceStep: FeedItem[];
  addInfoStep: CopyAssetAdditionalInfo;
  newOrderResponse: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  initAssetServiceStep: null,
  deskStep: null,
  dueDatesStep: null,
  serviceStep: null,
  addInfoStep: null,
  newOrderResponse: null,
  errorMessage: null,
};
