import { Action, createReducer, on } from '@ngrx/store';
import * as OrderMarketDataWizardActions from './actions';
import { State, initialState } from './state';

export const orderMarketDataWizardFeatureKey = 'orderMarketDataWizard';

const addProductWizardReducer = createReducer(
  initialState,

  on(OrderMarketDataWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(OrderMarketDataWizardActions.selectUserSelectionStep, (state, { userSelectionStep }) => ({
    ...state,
    userSelectionStep,
  })),
  on(OrderMarketDataWizardActions.selectReferenceProfileServicesStep, (state, { referenceProfileServicesStep }) => ({
    ...state,
    referenceProfileServicesStep,
  })),
  on(OrderMarketDataWizardActions.selectFineTuneServicesStep, (state, { fineTuneServicesStep }) => ({
    ...state,
    fineTuneServicesStep,
  })),
  // on(OrderMarketDataWizardActions.selectServiceStep, (state, { serviceStep }) => ({
  //   ...state,
  //   serviceStep,
  // })),
  // on(OrderMarketDataWizardActions.deleteServiceStep, (state) => ({
  //   ...state,
  //   serviceStep: null,
  // })),
  // on(OrderMarketDataWizardActions.selectAdditionalStep, (state, { addInfoStep }) => ({
  //   ...state,
  //   addInfoStep,
  // })),
  // on(OrderMarketDataWizardActions.deleteAdditionalStep, (state) => ({
  //   ...state,
  //   addInfoStep: null,
  // })),
  on(OrderMarketDataWizardActions.exitOrderMarketDataWizard, (state) => ({
    ...state,
    initStep: null,
    userSelectionStep: null,
    referenceProfileServicesStep: null,
    // dueDatesStep: null,
    // serviceStep: null,
    // addInfoStep: null,
    errorMessage: null,
  })),
  on(OrderMarketDataWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    userSelectionStep: null,
    referenceProfileServicesStep: null,
    // dueDatesStep: null,
    // serviceStep: null,
    // addInfoStep: null,
    errorMessage: null,
  })),
  on(OrderMarketDataWizardActions.createNewOrderMarketData, (state, { request }) => ({
    ...state,
    request,
  })),
  on(OrderMarketDataWizardActions.createNewOrderSuccess, (state, { response }) => ({
    ...state,
    newOrderResponse: response,
  })),
  on(OrderMarketDataWizardActions.createNewOrderFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return addProductWizardReducer(state, action);
}
