<mat-form-field
  appearance="outline"
  class="form-field-input reset-outline-hint"
  (click)="formFieldData.isReadonlyField() ? null : loadDefaultOptions()"
  [ngClass]="[
    formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '',
    (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
      ? 'invalid-field'
      : '',
    isViewMode ? 'view-mode' : '',
    isDirtyEnabled && originalFormFieldData.formfieldEntityText !== formFieldData.formfieldEntityText ? DIRTY_CLASSNAME : ''
  ]"
>
  <input
    type="text"
    matInput
    #optionSelect
    [disabled]="formFieldData.isReadonlyField() || isViewMode"
    [(ngModel)]="formFieldData.formfieldEntityText"
    (matInputAutocompleteFromApi)="foreignEntityOptions = $event"
    [filterEntityKind]="foreignFilterEntityKind"
    [filterEntityId]="foreignFilterEntityId"
    [debounceTime]="600"
    maxlength="1500"
    [entityKind]="formFieldData.formfieldEntityKind"
    [isTenantAdmin]="isTenantAdmin"
    (blur)="formFieldData.isReadonlyField() ? null : checkSelectedOption()"
    [matAutocomplete]="autoForeignOption"
  />
  <mat-autocomplete
    (opened)="matAutocompletPanelOpened()"
    (closed)="matAutocompletPanelClosed()"
    matAutocompleteScrollToOption
    #autoForeignOption="matAutocomplete"
    autoActiveFirstOption
    (optionSelected)="onForeignOptionSelectionChanged($event.option.value)"
  >
    <mat-option
      class="form-field-option"
      *ngFor="let opt of foreignEntityOptions"
      [value]="opt.entityName"
      [class.mat-selected]="formFieldData.formfieldEntityText === opt.entityName"
    >
      {{ opt.entityName }}
    </mat-option>
    <mat-option class="form-field-option create-button">
      <button mat-raised-button color="primary" style="margin: 0.25rem" (click)="createForeignEntity(); $event.stopPropagation()">
        <mat-icon>add</mat-icon> <span>{{ 'label_create' | translate }}</span>
      </button>
    </mat-option>
  </mat-autocomplete>
  <button
    *ngIf="formFieldData.formfieldEntityText && !formFieldData.isReadonlyField() && !isViewMode"
    mat-icon-button
    matSuffix
    class="small-icon"
    (click)="clearInput(); optionSelect.click(); $event.stopPropagation()"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <button
    *ngIf="!isViewMode"
    [disabled]="formFieldData.isReadonlyField()"
    mat-icon-button
    matSuffix
    class="small-icon"
    (click)="openDialog(); $event.stopPropagation()"
  >
    <mat-icon>search</mat-icon>
  </button>
  <button
    *ngIf="formFieldData.formfieldEntityValue != null && formFieldData.formfieldEntityText != null && showForeignDetailsButton"
    mat-icon-button
    matSuffix
    class="small-icon material-icons-outlined"
    (click)="openForeignDetailsDialog(); $event.stopPropagation()"
    matTooltip="{{ 'label_show_details' | translate }}"
    [matTooltipClass]="'fo-mat-tooltip'"
  >
    <mat-icon class="material-icons-outlined">description</mat-icon>
  </button>
</mat-form-field>
