import { KeyValue } from '@angular/common';
import { KeyValueAddon2, KeyValueData } from '../core/key-value-data';

export class FormFieldConfigurationFieldOptions {
  formfieldoptionId: number;
  formfieldoptionTextKindOptions: KeyValue<string, string>[];
  formfieldoptionTextSizeOptions: KeyValue<string, string>[];
  formfieldoptionTextWeightOptions: KeyValue<string, string>[];
  formfieldoptionSearchEntityKindOptions: KeyValue<string, string>[];
  formfieldoptionStepEntityKindOptions: KeyValue<string, string>[];
  formfieldoptionRelatedEntityKindOptions: KeyValue<string, string>[];
  formfieldoptionEntityColumnOptions: KeyValueAddon2<string, string, string>[];
  formfieldoptionEntityTypeOptions: KeyValue<string, string>[];
  formfieldoptionEntityDateOptions: KeyValue<string, string>[];
  formfieldoptionInputOptionOptions: KeyValueData<string, string, string, string, string>[];
  formfieldoptionEntityOptionOptions: KeyValueData<string, string, string, string, string>[];
  formfieldoptionEntityKindOptions: KeyValue<string, string>[];
  formfieldoptionDependencyfieldOptions: KeyValue<string, string>[];
  formfieldoptionLanguageOptions: KeyValue<string, string>[];
  formfieldoptionDisplayBooleanTypeOptions: KeyValue<string, string>[];
  formfieldoptionDisplayOptionTypeOptions: KeyValue<string, string>[];
  formfieldoptionDisplayRelationTypeOptions: KeyValue<string, string>[];
  formfieldoptionRequiredOptions: KeyValue<string, string>[];
  formfieldoptionHideOptions: KeyValue<string, string>[];
}

export enum FormFieldInputfieldBooleanDisplayOption {
  TOGGLE = 1,
  CHECKBOX,
}
export enum FormFieldInputfieldOptionDisplayOption {
  COMBOBOX = 1,
  RADIO,
}
export enum FormFieldChipsOptionDisplayOption {
  CHIPS = 1,
  LIST,
  MULTISELECTION_CHIPS,
  MULTISELECTION_LIST,
}
