<div class="grid-filter-panel-input">
  <mat-form-field
    appearance="outline"
    class="form-field-input reset-outline-hint grid-filter-dropdown filter-defined"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    [matTooltip]="filterTooltip"
  >
    <input type="text" matInput #optionSelect [value]="filterString" readonly (click)="$event.stopPropagation(); openOverlay()" />
    <button *ngIf="!isOverlayOpen" mat-icon-button matSuffix (click)="$event.stopPropagation(); openOverlay()">
      <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
    </button>
    <button *ngIf="isOverlayOpen" mat-icon-button matSuffix>
      <mat-icon class="dropdown-icon">arrow_drop_up</mat-icon>
    </button>
  </mat-form-field>
</div>
<ng-template
  #filterSingleselectionOverlay="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOverlayOpen"
  [cdkConnectedOverlayPositions]="overlayPositions"
  (overlayOutsideClick)="closeOverlay()"
>
  <div class="grid-filter-options-overlay" ngResizable rzHandles="e,edrag" [rzMinWidth]="300">
    <div class="table-wrapper">
      <!-- <mat-expansion-panel
        *ngIf="selectedEntity"
        class="detail-expansion-panel selected-items-expansion-panel"
        [expanded]="true"
        [@.disabled]="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> {{ 'grid_filter_options_selected' | translate }}</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="table-container">
          <table class="table table-stripes table-truncate-text" mat-table [dataSource]="[selectedEntity]">
            <ng-container matColumnDef="entityName">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell showIfTruncated [matTooltip]="row.entityName" *matCellDef="let row">
                {{ row.entityName }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsSelectionTable"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsSelectionTable"></tr>
          </table>
        </div>
      </mat-expansion-panel> -->
      <div class="table-search-paginate" *ngIf="showSearchBar">
        <!-- Search Filter Field-->
        <mat-form-field appearance="outline" class="reset-outline-hint filter-field search">
          <mat-autocomplete
            (opened)="matAutocompletPanelOpened()"
            (closed)="matAutocompletPanelClosed()"
            #autoLastStringOption="matAutocomplete"
            (optionSelected)="applyFilterTable($event.option.value)"
          >
            <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
              {{ 'label_recent_searches' | translate }}
            </mat-option>
            <mat-option class="form-field-option" *ngFor="let opt of lastUsedFilters" [value]="opt" [class.mat-selected]="filter === opt">
              {{ opt }}
            </mat-option>
          </mat-autocomplete>
          <input
            matInput
            #filterInput
            #searchInputAutocompleteTrigger="matAutocompleteTrigger"
            [matAutocomplete]="autoLastStringOption"
            (input)="searchInputAutocompleteTrigger.closePanel()"
            [(ngModel)]="filter"
            (debounceFilterInput)="applyFilterTable($event)"
            [debounceTime]="600"
            maxlength="1500"
            placeholder="{{ 'grid_filter_options_table_filter_placeholder' | translate }}"
          />
          <button mat-icon-button (click)="resetFilter()" matSuffix>
            <mat-icon class="reset-filter-icon"> close </mat-icon>
          </button>
        </mat-form-field>
        <!-- <span
          >({{ dataSourceTable.filteredData.length > pageSizeList[0] ? pageSizeList[0] : dataSourceTable.filteredData.length }}/{{
            totalFilter
          }})</span
        > -->
        <mat-paginator
          [disabled]="isLoading"
          [hidePageSize]="true"
          #paginatorTable
          [pageSizeOptions]="pageSizeList"
          class="reset-paginator hidden-labels"
        ></mat-paginator>
      </div>
      <div class="table-container items">
        <table class="table table-stripes table-truncate-text" mat-table [dataSource]="dataSourceTable">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="radio-button-wrapper">
              <mat-radio-button
                class="table-radio-button"
                (change)="onEntitySelected(row)"
                [disableRipple]="true"
                [checked]="selectedEntity && selectedEntity.entityId === row.entityId"
              ></mat-radio-button>
            </td>
          </ng-container>

          <ng-container matColumnDef="entityName">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell showIfTruncated [matTooltip]="row.entityName" *matCellDef="let row">
              {{ row.entityName }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
        </table>
        <mat-toolbar class="grid-filter-footer-toolbar" *ngIf="noEntityData || isLoading">
          <mat-toolbar-row *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="50"></mat-spinner>
          </mat-toolbar-row>
          <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
            <span>{{ 'generic_filter_error_nodata' | translate }}</span>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
      <div class="close-button-container">
        <button mat-flat-button color="primary" (click)="closeOverlay()">
          <ng-container> {{ 'label_close' | translate }}</ng-container>
        </button>
      </div>
    </div>
  </div>
</ng-template>
