<div id="files-tree-container">
  <input hidden type="file" #fileFolderInput (change)="uploadFilesAndFolders($event.target.files)" multiple webkitdirectory />
  <app-file-tree-view
    #filesTreeView
    class="files-tree"
    (nodeClick)="onNodeClicked($event)"
    [rootNodeLabel]="rootNodeLabel"
    [fileTabType]="this.tabType.key"
    [defaultRootNode]="rootNode"
    [autoselectEmptyNode]="autoselectEmptyNode"
  ></app-file-tree-view>
  <!-- <div class="directory-control-buttons" *ngIf="showDirectoryButtons()">
    <button mat-raised-button color="primary" (click)="createDirectory()" [disabled]="!selectedTreeNode">
      <mat-icon>add_circle</mat-icon>
      {{ 'files_add_directory_button' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="modifyDirectory()"
      [disabled]="!selectedTreeNode || selectedTreeNode.entityId === -1"
    >
      <mat-icon>edit</mat-icon>
      {{ 'files_edit_directory_button' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="deleteDirectory()"
      [disabled]="!selectedTreeNode || selectedTreeNode.entityId === -1"
    >
      <mat-icon>cancel</mat-icon>
      {{ 'files_cancel_directory_button' | translate }}
    </button>
  </div> -->
</div>
