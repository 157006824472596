import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import * as saveAs from 'file-saver';
import { interval, switchMap, takeWhile } from 'rxjs';
import { Entities } from 'src/app/configs/entities';
import { ProgressMessage } from 'src/app/models/wizard-import-response';
import { FilesService } from 'src/app/services/files.data.services';
import { ReportDataService } from 'src/app/services/report-data.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

export interface SpreadsheetGenerationDialogData {
  importId: number;
  progressTitleKey: string;
  progressTitleMessageKey: string;
}

@Component({
  selector: 'app-spreadsheet-generation-dialog',
  templateUrl: './spreadsheet-generation-dialog.component.html',
  styleUrls: ['./spreadsheet-generation-dialog.component.scss'],
})
export class SpreadsheetGenerationDialogComponent extends FormatComponent implements OnInit {
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;
  importId: number;
  progressTitleKey: string;
  progressTitleMessageKey: string;
  isProgressing = false;
  progressPercentage = 0;
  progressCompleted = false;
  progressErrorMessage: string;
  progressErrorMessageDescription: string;
  progressMessages: ProgressMessage[] = [];
  progressDisplayedColumnsTable = ['progressTimestamp', 'progressText'];
  progressMsgDatasource: MatTableDataSource<ProgressMessage>;

  constructor(
    private reportService: ReportDataService,
    protected filesService: FilesService,
    public dialogRef: MatDialogRef<SpreadsheetGenerationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SpreadsheetGenerationDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.importId = this.data.importId;
    this.progressTitleKey = this.data.progressTitleKey;
    this.progressTitleMessageKey = this.data.progressTitleMessageKey;
    this.progressMsgDatasource = new MatTableDataSource(this.progressMessages);
    this.subscribe(
      interval(2000).pipe(
        takeWhile(() => !this.progressCompleted),
        switchMap(() => this.reportService.getGeneratingReportStatus(this.importId))
      ),
      (response) => {
        if (response.data) {
          if (response.data.progressMessages && response.data.progressMessages.length > 0) {
            this.progressMsgDatasource.data.push(...response.data.progressMessages);
            this.progressMsgDatasource._updateChangeSubscription();
            this.scrollToBottom();
          } else {
            // only for dev
            // for (let index = 0; index < 1; index++) {
            //   this.progressMsgDatasource.data.push(`Progess message ${this.progressPercentage}`);
            // }
            // this.progressMsgDatasource._updateChangeSubscription();
            // this.scrollToBottom();
          }
          if (!response.data.error) {
            this.progressPercentage = response.data.progress;
            this.progressCompleted = response.data.state;
            if (this.progressCompleted && !this.progressErrorMessage) {
              // do something
              this.isProgressing = false;
              this.downloadFile(response.data.data[0].wizarddataId, response.data.data[0].wizarddataName);
            }
          } else {
            this.progressErrorMessage = response.data.error;
            this.progressErrorMessageDescription = response.data.errorMessage;
            this.progressPercentage = 100;
            this.progressCompleted = true;
            this.isProgressing = false;
          }
        }
      },
      (error) => {
        this.progressErrorMessage = _('label_internal_server_error');
        this.isProgressing = false;
      }
    );
  }

  cancelGeneratingSpreadsheet() {
    this.subscribe(
      this.reportService.cancelGeneratingReport(this.importId),
      ({ data }) => {
        this.progressCompleted = true;
        this.isProgressing = false;
      },
      (error) => {
        this.progressCompleted = true;
        this.isProgressing = false;
      }
    );
  }

  scrollToBottom(): void {
    try {
      if (this.messagesContainer) {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      }
    } catch (err) {
      //
    }
  }

  downloadFile(fileId: number, fileName: string) {
    this.subscribe(
      this.filesService.downloadFile(Entities.FILE, fileId),
      (blob) => {
        saveAs(blob, fileName); // FileSaver;
        this.dialogRef.close();
      },
      (error) => {
        this.progressErrorMessage = _('label_internal_server_error');
      }
    );
  }
}
