<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div mat-dialog-container class="message-box-container">
  <mat-toolbar *ngIf="style === 1" color="primary" class="mat-elevation-z2">
    <mat-card-header *ngIf="!isHtmlTitle">{{ title }}</mat-card-header>
    <mat-card-header *ngIf="isHtmlTitle"><span [innerHTML]="title | translate"></span></mat-card-header>
  </mat-toolbar>
  <div class="message" *ngIf="type === 0" [ngClass]="style === 0 ? 'centered' : ''">
    <p *ngIf="!isHtmlTitle">
      {{ message }}
    </p>
    <p *ngIf="isHtmlTitle" [innerHTML]="message | translate"></p>
  </div>
  <div class="info" *ngIf="type === 0 && information !== ''">
    <p>{{ information }}</p>
  </div>
  <div class="input" *ngIf="type === 1 && message !== ''">
    <mat-dialog-content [formGroup]="form">
      <mat-form-field>
        <input matInput [placeholder]="message" formControlName="inputText" type="text" [required]="true" />
      </mat-form-field>
    </mat-dialog-content>
  </div>
  <div style="padding: 5px; margin-left: auto" *ngIf="button !== 5">
    <div *ngIf="button === 0">
      <button mat-raised-button class="messagebox-button" color="primary" (click)="onOk()" tabindex="-1">
        {{ 'label_ok' | translate }}
      </button>
    </div>
    <div *ngIf="button === 1">
      <button mat-raised-button class="messagebox-button" color="primary" (click)="onOk()" tabindex="-1">
        {{ 'label_ok' | translate }}
      </button>
      <button mat-raised-button class="messagebox-button" color="primary" (click)="onCancel()" tabindex="-1">
        {{ 'label_cancel' | translate }}
      </button>
    </div>
    <div *ngIf="button === 2">
      <button mat-raised-button class="messagebox-button" color="primary" (click)="onYes()" tabindex="-1">
        {{ 'label_yes' | translate }}
      </button>
      <button mat-raised-button class="messagebox-button" color="primary" (click)="onNo()" tabindex="-1">
        {{ 'label_no' | translate }}
      </button>
    </div>
    <div *ngIf="button === 3">
      <button mat-raised-button class="messagebox-button" color="primary" (click)="onAccept()" tabindex="-1">
        {{ 'label_accept' | translate }}
      </button>
      <button mat-raised-button class="messagebox-button" color="primary" (click)="onReject()" tabindex="-1">
        {{ 'label_reject' | translate }}
      </button>
    </div>
    <div *ngIf="button === 4">
      <button mat-raised-button class="messagebox-button" color="primary" (click)="onCancel()" tabindex="-1">
        {{ 'label_cancel' | translate }}
      </button>
      <button mat-raised-button class="messagebox-button" color="primary" (click)="onNext()" tabindex="-1">
        {{ 'label_next' | translate }}
      </button>
    </div>
  </div>
  <div style="padding: 5px; margin: auto" *ngIf="button === 5">
    <button mat-stroked-button class="messagebox-button" color="primary" (click)="onCancel()" tabindex="-1">
      {{ 'label_close' | translate }}
    </button>
  </div>
</div>
