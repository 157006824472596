import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { BillingItem, BillingitemCopyRequest } from 'src/app/models/billingitem';
import { BillingAccount } from 'src/app/models/vendor-account';
import { CopyBillingitemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-billingitems-wizard/activation-step/copybillingitems-due-date-info';
import { CopyBillingitemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-billingitems-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Copy Billingitems Information Step',
  props<{ initStep: CopyBillingitemsInitStep }>()
);

export const selectBillingitemStep = createAction(
  '[Catalog Page] Select Copy Billingitems Billingitem Step',
  props<{ billingitemStep: BillingItem }>()
);

export const selectDueDatesStep = createAction(
  '[Wizard Due Date Step] Select Copy Billingitems Due Date Step',
  props<{ dueDatesStep: CopyBillingitemsDueDates }>()
);

export const selectBillingaccountStep = createAction(
  '[Wizard Billing account Step] Select Copy Billingitems Billing account Step',
  props<{ billingaccountStep: BillingAccount[] }>()
);

export const deleteBillingitemStep = createAction('[Wizard Billing item Step] Delete Copy Billingitems Billing item Step');

export const deleteBillingaccountStep = createAction('[Wizard Billing account Step] Delete Copy Billingitems Billing account Step');

export const exitCopyBillingitemsWizard = createAction('[CopyBillingitems Wizard Page] Exit Copy Billingitems Wizard');

export const destroyWizard = createAction('[CopyBillingitems Wizard Page] Destroy Wizard');

export const createNewCopyBillingitems = createAction(
  '[Wizard Confirmation Step] Create Copy Billingitems Information Step',
  props<{ request: BillingitemCopyRequest }>()
);

export const createNewCopyBillingitemsSuccess = createAction(
  '[Wizard Confirmation Step] Create Copy Billingitems Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewCopyBillingitemsFail = createAction(
  '[Wizard Confirmation Step] Create Copy Billingitems Information Step Fail',
  props<{ errorMessage: string }>()
);
