import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProductConfigurationStoreModule } from '../product-type-config-store';
import { CopyAssetWizardEffects } from './effects';
import * as fromCopyAssetWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCopyAssetWizard.copyAssetWizardFeatureKey, fromCopyAssetWizard.reducer),
    EffectsModule.forFeature([CopyAssetWizardEffects]),
    ProductConfigurationStoreModule,
  ],
})
export class CopyAssetWizardStoreModule {}
