import { Action, createReducer, on } from '@ngrx/store';
import * as CancelSharedAssetWizardActions from './actions';
import { State, initialState } from './state';

export const cancelSharedAssetWizardFeatureKey = 'cancelSharedAssetWizard';

const cancelSharedAssetWizardReducer = createReducer(
  initialState,

  on(CancelSharedAssetWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(CancelSharedAssetWizardActions.selectAllocationUpdateStep, (state, { allocationUpdate }) => ({
    ...state,
    allocationUpdate,
  })),
  on(CancelSharedAssetWizardActions.deleteAllocationUpdateStep, (state) => ({
    ...state,
    allocationUpdate: null,
  })),
  on(CancelSharedAssetWizardActions.exitCancelSharedAssetWizard, (state) => ({
    ...state,
    initStep: null,
    errorMessage: null,
    allocationUpdate: null,
    request: null,
    response: null,
  })),
  on(CancelSharedAssetWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    errorMessage: null,
    allocationUpdate: null,
    request: null,
    response: null,
  })),
  on(CancelSharedAssetWizardActions.createNewCancelSharedAsset, (state, { request }) => ({
    ...state,
    request,
  })),
  on(CancelSharedAssetWizardActions.createNewCancelSharedAssetSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(CancelSharedAssetWizardActions.createNewCancelSharedAssetFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return cancelSharedAssetWizardReducer(state, action);
}
