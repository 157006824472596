import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core';
import { DemoAuthComponent } from './core/auth/demo-auth/demo-auth.component';
import { DirectLoginOauthComponent } from './core/auth/direct-login-oauth/direct-login-oauth.component';
import { LoginOauthComponent } from './core/auth/login-oauth-okta/login-oauth-okta.component';
import { ResetPasswordComponent } from './core/auth/reset-password/reset-password.component';
import { ViewAsUserAuthComponent } from './core/auth/viewasuser-auth/viewasuser-auth.component';
import { HomepageComponent } from './core/homepage/homepage.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { DemoGuardService } from './services/auth/demo-guard.service';
import { TranslationLoaderResolver } from './services/resolver/translation-loader-resolver';

// const routes: Routes = [
//   {
//     path: 'pages/budget',
//     component: DemoPageComponent,
//     canActivate: [AuthGuardService, RoleGuardService],
//     data: {
//       authTitle: _('sidemenu_budget'),
//       noauthTitle: _('sidemenu_budget'),
//     },
//   },
// ];

const newroutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: HomepageComponent,
    pathMatch: 'full',
  },
  {
    path: 'demo',
    component: DemoAuthComponent,
    canActivate: [DemoGuardService],
  },
  {
    path: 'view-as-user',
    component: ViewAsUserAuthComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  },
  {
    path: 'auth/direct-login/:registrationId',
    component: DirectLoginOauthComponent,
  },
  {
    path: 'auth/openauthorizationadmin/:registrationId',
    data: { admin: true },
    component: LoginOauthComponent,
  },
  {
    path: 'auth/openauthorization/:registrationId',
    data: { admin: false },
    component: LoginOauthComponent,
  },
  {
    path: 'auth/resetpwd',
    component: ResetPasswordComponent,
  },
  {
    path: 'pages',
    resolve: { model: TranslationLoaderResolver },
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'restricted',
    resolve: { model: TranslationLoaderResolver },
    loadChildren: () => import('./restricted-pages/restricted-pages.module').then((m) => m.RestrictedPagesModule),
  },
  {
    path: 'configurations',
    outlet: 'configurationOutlet',
    resolve: { model: TranslationLoaderResolver },
    loadChildren: () => import('./configurations-pages/configurations-pages.module').then((m) => m.ConfigurationsPagesModule),
  },
  {
    path: 'help',
    outlet: 'helpOutlet',
    resolve: { model: TranslationLoaderResolver },
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
  },
  {
    path: 'usecases',
    outlet: 'usecasesOutlet',
    resolve: { model: TranslationLoaderResolver },
    loadChildren: () => import('./use-cases/use-cases.module').then((m) => m.UseCasesModule),
  },
  {
    path: 'tenant-invoice',
    loadChildren: () => import('./pages/interfaces/tenant-invoice/tenant-invoice.module').then((m) => m.TenantInvoiceModule),
  },
  { path: '**', redirectTo: '/' },
  // { path: '**', redirectTo: '/pages/dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(newroutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
