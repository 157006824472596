import { Entity } from './entity';

export class DynamicFlatNode {
  constructor(
    public item: GenericTreeNode,
    public level = 1,
    public expandable = false,
    public isLoading = false,
    public isIndeterminate = false,
    parentId?: number
  ) {
    this.item.parentId = parentId;
  }
}

export class GenericTreeNode extends Entity {
  entityChilds: number;
  parentId?: number;
}
