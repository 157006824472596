import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { Entities } from '../configs/entities';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { CatalogActivationRequest, CatalogFilters, CatalogTreeNode } from '../models/catalog';
import { CatalogAllocationTreeNode } from '../models/catalog-allocation';
import {
  CatalogItemParticulars,
  CatalogItemParticularsRequest,
  CatalogItemPriceParticulars,
  CatalogItemPriceParticularsRequest,
  CatalogItemProductFamily,
} from '../models/catalog-item-particulars';
import { CatalogStyle } from '../models/catalog-style';
import { EntityWizardResponse } from '../models/forms/entity-wizard';
import { PaginatedRequest } from '../models/paginated-request';
import { Policy } from '../models/policy';
import { PricePeriod } from '../models/price-period';
import { Service } from '../models/service';
import { TypeTreeNode } from '../models/type-node';
import { BillingAccount } from '../models/vendor-account';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class CatalogDataService {
  getCatalogFilters(): Observable<BaseResponse<CatalogFilters>> {
    const m = this.getCatalogFilters.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_FILTERS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<CatalogFilters>>(path);

    return retVal;
  }

  getCatalogItemKinds(): Observable<BaseResponse<TypeTreeNode[]>> {
    const m = this.getCatalogItemKinds.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_ITEMS_KINDS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<TypeTreeNode[]>>(path);

    return retVal;
  }

  getCatalogItemStyles(): Observable<BaseResponse<CatalogStyle[]>> {
    const m = this.getCatalogItemStyles.name;

    let params = new HttpParams().set('filterEnabledIds', '1');

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_STYLES);
    LogService.info(this, m, LogService.GET + path, params);
    const retVal = this.http.get<BaseResponse<CatalogStyle[]>>(path, { params });

    return retVal;
  }

  getCatalogStyles(request: PaginatedRequest): Observable<BaseResponse<CatalogStyle[]>> {
    const m = this.getCatalogStyles.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_STYLES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<CatalogStyle[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getCatalogStylesSpreadsheet(request: PaginatedRequest) {
    const m = this.getCatalogStylesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_STYLES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getCatalogItemParticularsDefaults(
    catalogItemkindId: number,
    catalogItemcategoryId: number,
    catalogItemtypeId: number,
    catalogServiceId: number,
    catalogDeliveryitemId: number
  ): Observable<BaseResponse<CatalogItemParticulars>> {
    const m = this.getCatalogItemParticularsDefaults.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_PARTICULARS_DEFAULTS);
    let params: HttpParams;
    if (catalogServiceId == null) {
      params = new HttpParams()
        .set('catalogItemkindId', catalogItemkindId)
        .set('catalogItemcategoryId', catalogItemcategoryId)
        .set('catalogItemtypeId', catalogItemtypeId);
    } else {
      params = new HttpParams().set('catalogId', catalogServiceId);
      if (catalogDeliveryitemId != null) {
        params = params.append('catalogDeliveryitemId', catalogDeliveryitemId);
      }
    }
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<CatalogItemParticulars>>(path, { params });

    return retVal;
  }

  activateCatalogItem(request: CatalogActivationRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.activateCatalogItem.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_ACTIVATION);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  deactivateCatalogItem(request: CatalogActivationRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.deactivateCatalogItem.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_DEACTIVATION);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  createCatalogItem(request: CatalogItemParticularsRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.createCatalogItem.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_PARTICULARS);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);

    return retVal;
  }

  updateCatalogItem(catalogId: number, request: CatalogItemParticularsRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.createCatalogItem.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_PARTICULARS_BYID(catalogId));
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getExistingServicesByCatalogRequest(request: CatalogItemParticularsRequest): Observable<BaseResponse<Service[]>> {
    const m = this.getExistingServicesByCatalogRequest.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_PARTICULARS_REVIEW);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<Service[]>>(path, request);

    return retVal;
  }

  getCatalogItemProductFamilies(ids: number[]): Observable<BaseResponse<CatalogItemProductFamily[]>> {
    const m = this.getCatalogItemProductFamilies.name;

    const path = environment.getEndpoint(ApiPath.ProductFamily.PRODUCTFAMILIES);
    const params = new HttpParams().set('filterIds', ids.join(','));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<CatalogItemProductFamily[]>>(path, { params });

    return retVal;
  }

  getCatalogItemIssueProviderDefaulPolicies(vendorId: number, catalogItemtypeId: number): Observable<BaseResponse<Policy[]>> {
    const m = this.getCatalogItemIssueProviderDefaulPolicies.name;

    const path = environment.getEndpoint(ApiPath.Policies.POLICIES);
    const params = new HttpParams().set('filterVendorIds', vendorId).set('filterCatalogitemtypeIds', catalogItemtypeId);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<Policy[]>>(path, { params });

    return retVal;
  }

  getCatalogNodes(
    request: PaginatedRequest,
    serviceId?: number,
    feeditemId?: number,
    billingitemId?: number,
    nodeId?: number
  ): Observable<BaseResponse<CatalogTreeNode[]>> {
    const m = this.getCatalogNodes.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_NODES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'serviceId', serviceId);
    LogService.info(this, m, 'feeditemId', feeditemId);
    LogService.info(this, m, 'billingitemId', billingitemId);
    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = serviceId != null ? params.append('serviceId', serviceId.toString()) : params;
    params = feeditemId != null ? params.append('feeditemId', feeditemId.toString()) : params;
    params = billingitemId != null ? params.append('billingitemId', billingitemId.toString()) : params;
    params = nodeId != null ? params.append('filterIds', nodeId.toString()) : params;
    const retVal = this.http.get<BaseResponse<CatalogTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getCatalogSelectionNodes(
    request: PaginatedRequest,
    selectedServiceIds: number[],
    selectedDeliveryitemIds: number[],
    selectedBillingitemIds: number[],
    serviceId?: number,
    deliveryitemId?: number,
    billingitemId?: number
  ): Observable<BaseResponse<CatalogTreeNode[]>> {
    const m = this.getCatalogNodes.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_SELECTION_NODES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'serviceId', serviceId);
    LogService.info(this, m, 'deliveryitemId', deliveryitemId);
    LogService.info(this, m, 'billingitemId', billingitemId);
    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params =
      selectedServiceIds != null && selectedServiceIds.length > 0
        ? params.append('selectedServiceIds', selectedServiceIds.join(','))
        : params;
    params =
      selectedDeliveryitemIds != null && selectedDeliveryitemIds.length > 0
        ? params.append('selectedDeliveryitemIds', selectedDeliveryitemIds.join(','))
        : params;
    params =
      selectedBillingitemIds != null && selectedBillingitemIds.length > 0
        ? params.append('selectedBillingitemIds', selectedBillingitemIds.join(','))
        : params;
    params = serviceId != null ? params.append('serviceId', serviceId.toString()) : params;
    params = deliveryitemId != null ? params.append('deliveryitemId', deliveryitemId.toString()) : params;
    params = billingitemId != null ? params.append('billingitemId', billingitemId.toString()) : params;
    const retVal = this.http.get<BaseResponse<CatalogTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getVendorAccounts(filterRestrictedservicetypeIds: number[]): Observable<BaseResponse<BillingAccount[]>> {
    const m = this.getVendorAccounts.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS);
    LogService.info(this, m, LogService.GET + path, null);

    const params = new HttpParams().set('filterRestrictedservicetypeIds', filterRestrictedservicetypeIds.join(','));
    const retVal = this.http.get<BaseResponse<BillingAccount[]>>(path, {
      params,
    });

    return retVal;
  }

  getVendorAccountsProductFamilyId(
    filterProductfamilyIds: number[],
    filterRestrictedservicetypeIds: number[]
  ): Observable<BaseResponse<BillingAccount[]>> {
    const m = this.getVendorAccountsProductFamilyId.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS);
    LogService.info(this, m, LogService.GET + path, null);

    const params = new HttpParams()
      .set('filterProductfamilyIds', filterProductfamilyIds.join(','))
      .set('filterRestrictedservicetypeIds', filterRestrictedservicetypeIds.join(','));
    const retVal = this.http.get<BaseResponse<BillingAccount[]>>(path, {
      params,
    });

    return retVal;
  }

  startGeneratingCatalogNodesSpreadsheet(request: PaginatedRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.startGeneratingCatalogNodesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_NODES_SPREADSHEET);
    LogService.info(this, m, LogService.POST + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, null, { params });

    return retVal;
  }

  getCatalogServiceByProviderNodes(
    request: PaginatedRequest,
    billerId?: number,
    providerId?: number,
    feeditemId?: number,
    nodeId?: number
  ): Observable<BaseResponse<CatalogTreeNode[]>> {
    const m = this.getCatalogServiceByProviderNodes.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_SERVICE_BY_PROVIDER_NODES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'billingvendorId', billerId);
    LogService.info(this, m, 'providerId', providerId);
    LogService.info(this, m, 'feeditemId', feeditemId);
    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = billerId != null ? params.append('billerId', billerId.toString()) : params;
    params = providerId != null ? params.append('providerId', providerId.toString()) : params;
    params = feeditemId != null ? params.append('feeditemId', feeditemId.toString()) : params;
    params = nodeId != null ? params.append('filterIds', nodeId.toString()) : params;
    const retVal = this.http.get<BaseResponse<CatalogTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  startGeneratingCatalogServiceByProviderNodesSpreadsheet(request: PaginatedRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.startGeneratingCatalogServiceByProviderNodesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_SERVICE_BY_PROVIDER_NODES_SPREADSHEET);
    LogService.info(this, m, LogService.POST + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, null, { params });

    return retVal;
  }

  getCatalogServiceByBillingaccountNodes(
    request: PaginatedRequest,
    billerId?: number,
    billingaccountId?: number,
    billingitemId?: number,
    nodeId?: number
  ): Observable<BaseResponse<CatalogTreeNode[]>> {
    const m = this.getCatalogServiceByBillingaccountNodes.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_SERVICE_BY_BILLINGACCOUNT_NODES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'billingvendorId', billerId);
    LogService.info(this, m, 'billingaccountId', billingaccountId);
    LogService.info(this, m, 'billingitemId', billingitemId);
    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = billerId != null ? params.append('billerId', billerId.toString()) : params;
    params = billingaccountId != null ? params.append('billingaccountId', billingaccountId.toString()) : params;
    params = billingitemId != null ? params.append('billingitemId', billingitemId.toString()) : params;
    params = nodeId != null ? params.append('filterIds', nodeId.toString()) : params;
    const retVal = this.http.get<BaseResponse<CatalogTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  startGeneratingCatalogServiceByBillingaccountNodesSpreadsheet(request: PaginatedRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.startGeneratingCatalogServiceByBillingaccountNodesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_SERVICE_BY_BILLINGACCOUNT_NODES_SPREADSHEET);
    LogService.info(this, m, LogService.POST + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, null, { params });

    return retVal;
  }

  getCatalogAllocationNodes(
    request: PaginatedRequest,
    billingitemId: number,
    licenseId?: number
  ): Observable<BaseResponse<CatalogAllocationTreeNode[]>> {
    const m = this.getCatalogNodes.name;

    const path = environment.getEndpoint(ApiPath.Catalog.CATALOG_ALLOCATION_NODES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'billingitemId', billingitemId);
    LogService.info(this, m, 'licenseId', licenseId);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = params.append('billingitemId', billingitemId.toString());
    params = licenseId != null ? params.append('licenseId', licenseId.toString()) : params;
    const retVal = this.http.get<BaseResponse<CatalogAllocationTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getCatalogPricePeriods(entityKind: string, entityId: number, request: PaginatedRequest): Observable<BaseResponse<PricePeriod[]>> {
    const m = this.getCatalogPricePeriods.name;

    let apipath;
    if (entityKind === Entities.SERVICE) {
      apipath = ApiPath.Services.SERVICE_PRICES(entityId);
    } else if (entityKind === Entities.FEEDITEM) {
      apipath = ApiPath.FeedItems.FEEDITEMS_PRICES(entityId);
    } else {
      apipath = ApiPath.BillingItems.BILLING_ITEMS_PRICES(entityId);
    }

    const path = environment.getEndpoint(apipath);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<PricePeriod[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getCatalogPriceParticulars(entityKind: string, entityId: number, priceId: number): Observable<BaseResponse<CatalogItemPriceParticulars>> {
    const m = this.getCatalogPriceParticulars.name;
    let apipath;
    if (entityKind === Entities.SERVICE) {
      apipath = ApiPath.Services.SERVICE_PRICE_PARTICULARS(entityId, priceId);
    } else if (entityKind === Entities.FEEDITEM) {
      apipath = ApiPath.FeedItems.FEEDITEMS_PRICE_PARTICULARS(entityId, priceId);
    } else {
      apipath = ApiPath.BillingItems.BILLING_ITEMS_PRICE_PARTICULARS(entityId, priceId);
    }

    const path = environment.getEndpoint(apipath);
    // const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_PRICE_PARTICULARS(billingitemId, priceId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<CatalogItemPriceParticulars>>(path);

    return retVal;
  }

  putCatalogPriceParticulars(
    entityKind: string,
    entityId: number,
    priceId: number,
    request: CatalogItemPriceParticularsRequest
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.putCatalogPriceParticulars.name;

    let apipath;
    if (entityKind === Entities.SERVICE) {
      apipath = ApiPath.Services.SERVICE_PRICE_PARTICULARS(entityId, priceId);
    } else if (entityKind === Entities.FEEDITEM) {
      apipath = ApiPath.FeedItems.FEEDITEMS_PRICE_PARTICULARS(entityId, priceId);
    } else {
      apipath = ApiPath.BillingItems.BILLING_ITEMS_PRICE_PARTICULARS(entityId, priceId);
    }

    const path = environment.getEndpoint(apipath);
    // const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_PRICE_PARTICULARS(billingitemId, priceId));
    LogService.info(this, m, LogService.PUT + path, null);

    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getCatalogPriceParticularsDefault(
    entityKind: string,
    entityId: number,
    starts: string
  ): Observable<BaseResponse<CatalogItemPriceParticulars>> {
    const m = this.getCatalogPriceParticulars.name;

    let apipath;
    if (entityKind === Entities.SERVICE) {
      apipath = ApiPath.Services.SERVICE_PRICE_PARTICULARS_DEFAULT(entityId);
    } else if (entityKind === Entities.FEEDITEM) {
      apipath = ApiPath.FeedItems.FEEDITEMS_PRICE_PARTICULARS_DEFAULT(entityId);
    } else {
      apipath = ApiPath.BillingItems.BILLING_ITEMS_PRICE_PARTICULARS_DEFAULT(entityId);
    }
    const path = environment.getEndpoint(apipath);
    // const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_PRICE_PARTICULARS_DEFAULT(billingitemId));
    LogService.info(this, m, LogService.GET + path, null);
    const params = new HttpParams().set('starts', starts);

    const retVal = this.http.get<BaseResponse<CatalogItemPriceParticulars>>(path, { params });

    return retVal;
  }

  createCatalogPriceParticulars(
    entityKind: string,
    entityId: number,
    request: CatalogItemPriceParticularsRequest
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.getCatalogPriceParticulars.name;

    let apipath;
    if (entityKind === Entities.SERVICE) {
      apipath = ApiPath.Services.SERVICE_PRICE_PARTICULARS_CREATE(entityId);
    } else if (entityKind === Entities.FEEDITEM) {
      apipath = ApiPath.FeedItems.FEEDITEMS_PRICE_PARTICULARS_CREATE(entityId);
    } else {
      apipath = ApiPath.BillingItems.BILLING_ITEMS_PRICE_PARTICULARS_CREATE(entityId);
    }
    const path = environment.getEndpoint(apipath);
    // const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_PRICE_PARTICULARS_CREATE(billingitemId));
    LogService.info(this, m, LogService.POST + path, null);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
