import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

const validateRegex =
  (regexToMatch: RegExp): ValidatorFn =>
  (control) =>
    regexToMatch.test(control.value.toString()) ? null : { regexError: control.value };

@Directive({
  selector: '[appMatchesRegex]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RegexValidationDirective, multi: true }],
})
export class RegexValidationDirective implements Validator {
  @Input('appMatchesRegex') regexToMatch: string;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.regexToMatch ? validateRegex(new RegExp(this.regexToMatch, 'g'))(control) : null;
  }
}
