import { createAction, props } from '@ngrx/store';
import { LicenseAssetPeriodsAllocationWizardRequest } from 'src/app/models/asset-allocation';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { Userlicense } from 'src/app/models/userlicense';
import { CancelConsumersFromAssetConsumerStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-consumers-from-asset-wizard/cancel-consumers-from-asset-consumers-step/cancel-consumers-from-asset-consumers.step';
import { CancelConsumersFromAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-consumers-from-asset-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Wizard Init Step] Select Cancel Consumers From Asset Init Step',
  props<{ initStep: CancelConsumersFromAssetInitStep }>()
);

export const selectConsumersStep = createAction(
  '[Wizard Consumerss Step] Select Cancel Consumers From Asset Consumers Step',
  props<{ consumersStep: CancelConsumersFromAssetConsumerStep }>()
);

export const deleteConsumersStep = createAction('[Wizard CancelDetails Step] Delete Cancel Consumers From Asset Consumers Step');

export const selectBillingStep = createAction(
  '[Wizard Billing Step] Select Cancel Consumers From Asset Billing Step',
  props<{ billingStep: Userlicense[] }>()
);

export const deleteBillingStep = createAction('[Wizard CancelDetails Step] Delete Cancel Consumers From Asset Billing Step');

export const exitCancelConsumersFromAssetWizard = createAction(
  '[CancelConsumersFromAsset Wizard Page] Exit Cancel Consumers From Asset Wizard'
);

export const destroyWizard = createAction('[CancelConsumersFromAsset Wizard Page] Destroy Wizard');

export const createNewCancelConsumersFromAsset = createAction(
  '[Wizard Confirmation Step] Create Cancel Consumers From Asset Information Step',
  props<{ request: LicenseAssetPeriodsAllocationWizardRequest }>()
);

export const createNewCancelConsumersFromAssetRequestExecution = createAction(
  '[Wizard Confirmation Step] Create Cancel Consumers From Asset Request Execution Information Step',
  props<{ request: LicenseAssetPeriodsAllocationWizardRequest }>()
);

export const createNewCancelConsumersFromAssetSuccess = createAction(
  '[Wizard Confirmation Step] Create Cancel Consumers From Asset Information Step Success',
  props<{ response: EntityWizardResponse }>()
);

export const createNewCancelConsumersFromAssetFail = createAction(
  '[Wizard Confirmation Step] Create Cancel Consumers From Asset Information Step Fail',
  props<{ errorMessage: string }>()
);
