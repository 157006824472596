import { Action, createReducer, on } from '@ngrx/store';
import * as ReplaceFeeditemsWizardActions from './actions';
import { State, initialState } from './state';

export const replaceFeeditemsWizardFeatureKey = 'replaceFeeditemsWizard';

const replaceFeeditemsWizardReducer = createReducer(
  initialState,

  on(ReplaceFeeditemsWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(ReplaceFeeditemsWizardActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(ReplaceFeeditemsWizardActions.selectServiceStep, (state, { serviceStep }) => ({
    ...state,
    serviceStep,
  })),
  on(ReplaceFeeditemsWizardActions.deleteServiceStep, (state) => ({
    ...state,
    serviceStep: null,
  })),
  on(ReplaceFeeditemsWizardActions.exitReplaceFeeditemsWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    serviceStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(ReplaceFeeditemsWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    serviceStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(ReplaceFeeditemsWizardActions.createNewReplaceFeeditems, (state, { request }) => ({
    ...state,
    request,
  })),
  on(ReplaceFeeditemsWizardActions.createNewReplaceFeeditemsSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(ReplaceFeeditemsWizardActions.createNewReplaceFeeditemsFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return replaceFeeditemsWizardReducer(state, action);
}
