import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { CancelSharedAssetAllocationUpdateStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-shared-asset-wizard/cancel-shared-asset-allocation-update-step/cancel-shared-asset-allocation-update-step';
import { CancelSharedAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-shared-asset-wizard/init-step/init-step';
import * as fromCancelSharedAssetWizard from './reducer';
import { State } from './state';

export const selectCancelSharedAssetWizardState = createFeatureSelector<State>(
  fromCancelSharedAssetWizard.cancelSharedAssetWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectCancelSharedAssetWizardState,
  (state: State): CancelSharedAssetInitStep => state.initStep
);

export const selectAllocationUpdateStep = createSelector(
  selectCancelSharedAssetWizardState,
  (state: State): CancelSharedAssetAllocationUpdateStep => state.allocationUpdate
);

export const selectCancelSharedAssetState = createSelector(
  selectCancelSharedAssetWizardState,
  (state: State): EntityWizardResponse => state.response
);

export const selectErrorMessage = createSelector(selectCancelSharedAssetWizardState, (state: State): any => state.errorMessage);
