export class FileTabType {
  static readonly generic = new FileTabType('generic', false, true);
  static readonly company = new FileTabType('company', false, true);
  static readonly user = new FileTabType('user', false, true);
  // static readonly generic = new FileTabType('generic', false, false);
  // static readonly company = new FileTabType('company', true, false);
  // static readonly user = new FileTabType('user', true, false);

  // private to disallow creating other instances of this type
  private constructor(public readonly key: string, public readonly rootNode: boolean, public readonly autoselectEmptyNode: boolean) {}

  toString() {
    return this.key;
  }
}
