import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormatComponent } from '../../base-components/format-component';

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss'],
})
export class SimpleDialogComponent extends FormatComponent implements OnInit {
  style: number;
  title: string;
  message: string;
  information: string;
  type: number;
  button: number;
  allowOutsideClick: boolean;
  isHtmlTitle: boolean;
  form: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SimpleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    super();
    this.style = data.style || 0;
    this.title = data.title;
    this.message = data.message;
    this.information = data.information;
    this.type = data.type;
    this.button = data.button;
    this.dialogRef.disableClose = !data.allowOutsideClick || false;
    this.isHtmlTitle = data.isHtmlTitle;
  }

  ngOnInit() {
    this.form = this.fb.group({
      inputText: [this.information, [Validators.required]],
    });
  }

  onOk() {
    if (this.type === 1 && this.form.valid) {
      this.dialogRef.close({ result: 'ok', inputText: this.form.controls.inputText.value });
    } else if (this.type === 1 && !this.form.valid) {
      return;
    } else {
      this.dialogRef.close({ result: 'ok' });
    }
  }
  onCancel() {
    this.dialogRef.close({ result: 'cancel' });
  }
  onNext() {
    this.dialogRef.close({ result: 'next' });
  }
  onYes() {
    this.dialogRef.close({ result: 'yes' });
  }
  onNo() {
    this.dialogRef.close({ result: 'no' });
  }
  onAccept() {
    this.dialogRef.close({ result: 'accept' });
  }
  onReject() {
    this.dialogRef.close({ result: 'reject' });
  }
}
