<div class="cron-tab-container">
  <mat-radio-group [disabled]="!isEditing" [(ngModel)]="selectedOption" (change)="regenerateCron()">
    <mat-radio-button [value]="CronDaysOptions.everyDay">
      <label>{{ 'cron_editor_label_every_day' | translate }}</label>
    </mat-radio-button>

    <mat-radio-button [value]="CronDaysOptions.everyNDayStartFromWeekDay">
      <label>{{ 'cron_editor_label_every' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronDaysOptions.everyNDayStartFromWeekDay"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="everyNWeekDays"
        >
          <mat-option class="form-field-option" *ngFor="let every of weekDaysRange" [value]="every">
            {{ every }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_every_day_startingon' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-string">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronDaysOptions.everyNDayStartFromWeekDay"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="everyNWeekDaysStartFrom"
        >
          <mat-option class="form-field-option" *ngFor="let day of weekDays" [value]="day">
            {{ weekDayDisplay(day) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-radio-button>

    <mat-radio-button [value]="CronDaysOptions.everyNDayStartFromMonthDay">
      <label>{{ 'cron_editor_label_every' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronDaysOptions.everyNDayStartFromMonthDay"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="everyNMonthDays"
        >
          <mat-option class="form-field-option" *ngFor="let every of monthDaysRange" [value]="every">
            {{ every }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_every_day_startingonthe' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-string">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronDaysOptions.everyNDayStartFromMonthDay"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="everyNMonthDaysStartFrom"
        >
          <mat-option class="form-field-option" *ngFor="let day of monthDaysRange" [value]="day">
            {{ monthDayDisplay(day) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_day_ofthemonth' | translate }}</label>
    </mat-radio-button>

    <mat-radio-button [value]="CronDaysOptions.specificWeekDays">
      <label>{{ 'cron_editor_label_specific_weekday' | translate }}</label>
      <ng-container *ngIf="isEditing">
        <span style="margin-left: 0.5rem"></span>
        <button
          mat-raised-button
          color="primary"
          [disabled]="selectedOption !== CronDaysOptions.specificWeekDays"
          (click)="selectAllWeekdayCheckboxes()"
        >
          {{ 'label_select_all' | translate }}
        </button>
        <span style="margin-left: 0.5rem"></span>
        <button
          mat-raised-button
          color="primary"
          [disabled]="selectedOption !== CronDaysOptions.specificWeekDays"
          (click)="resetWeekdayCheckboxes()"
        >
          {{ 'label_reset' | translate }}
        </button>
      </ng-container>
    </mat-radio-button>
    <div class="cron-input-specific-checkboxes-container">
      <mat-checkbox
        [disabled]="!isEditing || selectedOption !== CronDaysOptions.specificWeekDays"
        *ngFor="let day of weekDays"
        [checked]="isWeekdayCheckboxChecked(day)"
        (change)="weekdayCheckboxSelectionChanged($event)"
        [value]="day"
        class="string-label"
      >
        {{ weekDayDisplay(day) | translate }}</mat-checkbox
      >
    </div>

    <mat-radio-button [value]="CronDaysOptions.specificMonthDays">
      <label>{{ 'cron_editor_label_specific_monthday' | translate }}</label>
      <ng-container *ngIf="isEditing">
        <span style="margin-left: 0.5rem"></span>
        <button
          mat-raised-button
          color="primary"
          [disabled]="selectedOption !== CronDaysOptions.specificMonthDays"
          (click)="selectAllMonthdayCheckboxes()"
        >
          {{ 'label_select_all' | translate }}
        </button>
        <span style="margin-left: 0.5rem"></span>
        <button
          mat-raised-button
          color="primary"
          [disabled]="selectedOption !== CronDaysOptions.specificMonthDays"
          (click)="resetMonthdayCheckboxes()"
        >
          {{ 'label_reset' | translate }}
        </button>
      </ng-container>
    </mat-radio-button>
    <div class="cron-input-specific-checkboxes-container">
      <mat-checkbox
        [disabled]="!isEditing || selectedOption !== CronDaysOptions.specificMonthDays"
        *ngFor="let day of monthDaysRange"
        [checked]="isMonthdayCheckboxChecked(day)"
        (change)="monthdayCheckboxSelectionChanged($event)"
        [value]="day"
      >
        {{ day | number : '2.0' }}</mat-checkbox
      >
    </div>

    <mat-radio-button [value]="CronDaysOptions.lastDayOfMonth">
      <label>{{ 'cron_editor_label_last_day_ofmonth' | translate }}</label>
    </mat-radio-button>

    <mat-radio-button [value]="CronDaysOptions.lastWeekdayOfMonth">
      <label>{{ 'cron_editor_label_last_weekday_ofmonth' | translate }}</label>
    </mat-radio-button>

    <mat-radio-button [value]="CronDaysOptions.lastSpecificWeekDayOfMonth">
      <label>{{ 'cron_editor_label_onthelast' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-string">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronDaysOptions.lastSpecificWeekDayOfMonth"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="lastSpecificWeekDayOfMonth"
        >
          <mat-option class="form-field-option" *ngFor="let day of weekDaysRange" [value]="day">
            {{ weekDayFromNumberDisplay(day) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_day_ofthemonth' | translate }}</label>
    </mat-radio-button>

    <mat-radio-button [value]="CronDaysOptions.nDaysBeforeEndOfMonth">
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits no-left-padding">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronDaysOptions.nDaysBeforeEndOfMonth"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="nDaysBeforeEndOfMonth"
        >
          <mat-option class="form-field-option" *ngFor="let day of monthDaysRange" [value]="day">
            {{ day | number : '2.0' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_day_before_end_ofmonth' | translate }}</label>
    </mat-radio-button>

    <mat-radio-button [value]="CronDaysOptions.nearestWeekdayToNOfMonth">
      <label>{{ 'cron_editor_label_day_nearest_weekday_tothe' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-string">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronDaysOptions.nearestWeekdayToNOfMonth"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="nearestWeekdayToNOfMonth"
        >
          <mat-option class="form-field-option" *ngFor="let day of monthDaysRange" [value]="day">
            {{ monthDayDisplay(day) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_day_ofthemonth' | translate }}</label>
    </mat-radio-button>

    <mat-radio-button [value]="CronDaysOptions.onTheNWeekdayOfMonth">
      <label>{{ 'cron_editor_label_onthe' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronDaysOptions.onTheNWeekdayOfMonth"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="onTheNWeekdayOfMonthOrdinal"
        >
          <mat-option class="form-field-option" *ngFor="let n of monthWeek" [value]="n">
            {{ ordinalDisplay(n) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-string no-left-padding">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronDaysOptions.onTheNWeekdayOfMonth"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="onTheNWeekdayOfMonthWeekday"
        >
          <mat-option class="form-field-option" *ngFor="let day of weekDaysRange" [value]="day">
            {{ weekDayFromNumberDisplay(day) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_day_ofthemonth' | translate }}</label>
    </mat-radio-button>
  </mat-radio-group>
  <!-- <h3>Cron expression - weekdays {{ weekdaysExpression }}</h3>
  <h3>Cron expression - monthdays {{ monthdaysExpression }}</h3> -->
</div>
