import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { EntityRequest } from '../models/entity';
import { MailFrame, MailTemplate, MailTemplateTreeNode } from '../models/mailtemplate/mail-template';
import { MailTemplateFrame, MailTemplateStaticsOptions } from '../models/mailtemplate/mail-template-particular';
import { Email, EmailRequest, MailHistory } from '../models/mailtemplate/mail-template-preview';
import { PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class MailTemplatesDataService {
  getMailTemplates(request: PaginatedRequest): Observable<BaseResponse<MailTemplate[]>> {
    const m = this.getMailTemplates.name;

    const path = environment.getEndpoint(ApiPath.MailTemplates.MAIL_TEMPLATES);
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<MailTemplate[]>>(path, {
      params,
    });

    return retVal;
  }

  getMailTemplatesSpreadsheet(request: PaginatedRequest) {
    const m = this.getMailTemplatesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.MailTemplates.MAIL_TEMPLATES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getMailTemplatesFrames(request: PaginatedRequest): Observable<BaseResponse<MailFrame[]>> {
    const m = this.getMailTemplatesFrames.name;

    const path = environment.getEndpoint(ApiPath.MailTemplates.MAIL_TEMPLATES_FRAMES);
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<MailFrame[]>>(path, {
      params,
    });

    return retVal;
  }

  getMailTemplatesFramesSpreadsheet(request: PaginatedRequest) {
    const m = this.getMailTemplatesFramesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.MailTemplates.MAIL_TEMPLATES_FRAMES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getMailTemplateNodes(request: PaginatedRequest, mailtemplatedefaultId?: number): Observable<BaseResponse<MailTemplateTreeNode[]>> {
    const m = this.getMailTemplateNodes.name;

    const path = environment.getEndpoint(ApiPath.MailTemplates.MAIL_TEMPLATES_DEFAULT);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'mailtemplatedefaultId', mailtemplatedefaultId);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = mailtemplatedefaultId != null ? params.append('mailtemplatedefaultId', mailtemplatedefaultId.toString()) : params;
    const retVal = this.http.get<BaseResponse<MailTemplateTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getMailTemplateNodesSpreadsheet(request: PaginatedRequest) {
    const m = this.getMailTemplateNodesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.MailTemplates.MAIL_TEMPLATES_DEFAULT_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getMailFrameBodies(frameId: number): Observable<BaseResponse<MailTemplateFrame>> {
    const m = this.getMailFrameBodies.name;

    const path = environment.getEndpoint(ApiPath.MailTemplates.MAIL_TEMPLATES_FRAMES_BODIES(frameId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<MailTemplateFrame>>(path);

    return retVal;
  }

  saveMailFrameBodies(frameId: number, bodies: MailTemplateFrame): Observable<BaseResponse<ActionResponse>> {
    const m = this.saveMailFrameBodies.name;

    const path = environment.getEndpoint(ApiPath.MailTemplates.MAIL_TEMPLATES_FRAMES_BODIES(frameId));
    LogService.info(this, m, LogService.PUT + path, null);

    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, bodies);

    return retVal;
  }

  getMailFrameStaticsOptions(frameId: number, entityKind: string): Observable<BaseResponse<MailTemplateStaticsOptions>> {
    const m = this.getMailFrameStaticsOptions.name;

    const path = environment.getEndpoint(ApiPath.MailTemplates.MAIL_TEMPLATES_FRAMES_STATIC_OPTIONS(frameId, entityKind));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<MailTemplateStaticsOptions>>(path);

    return retVal;
  }

  getMailTemplateStaticsOptions(entityKind: string): Observable<BaseResponse<MailTemplateStaticsOptions>> {
    const m = this.getMailTemplateStaticsOptions.name;

    const path = environment.getEndpoint(ApiPath.MailTemplates.MAIL_TEMPLATES_STATIC_OPTIONS(entityKind));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<MailTemplateStaticsOptions>>(path);

    return retVal;
  }

  getMailTemplatePreview(
    mailframedefaultId: number,
    mailframeId: number,
    mailoutgoingaccountId: number,
    mailtemplateId: number,
    stageId: number,
    languageId: number,
    fontfamilyId: number,
    subject: string,
    body: string
  ): Observable<BaseResponse<string>> {
    const m = this.getMailTemplatePreview.name;

    const path = environment.getEndpoint(ApiPath.MailTemplates.MAIL_TEMPLATES_PREVIEW);
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams();
    params =
      mailframeId != null
        ? params.append('mailframeId', mailframeId.toString())
        : mailframedefaultId != null
        ? params.append('mailframedefaultId', mailframedefaultId.toString())
        : params;
    params = mailoutgoingaccountId != null ? params.append('mailoutgoingaccountId', mailtemplateId.toString()) : params;
    params = mailtemplateId != null ? params.append('templateId', mailtemplateId.toString()) : params;
    params = stageId != null ? params.append('stageId', stageId.toString()) : params;
    params = languageId != null ? params.append('languageId', languageId.toString()) : params;
    params = fontfamilyId != null ? params.append('fontfamilyId', fontfamilyId.toString()) : params;
    params = subject != null ? params.append('subject', subject) : params;
    params = body != null ? params.append('body', body) : params;
    const retVal = this.http.get<BaseResponse<string>>(path, { params });

    return retVal;
  }

  getEmailByEntityKindEntityId(
    entityKind: string,
    entityId: number,
    filterEntityKind?: string,
    filterEntityId?: number
  ): Observable<BaseResponse<Email[]>> {
    const m = this.getEmailByEntityKindEntityId.name;

    const path = environment.getEndpoint(ApiPath.Emails.EMAILS_SEND(entityKind, entityId));
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams();
    params = filterEntityKind != null ? params.append('filterEntityKind', filterEntityKind) : params;
    params = filterEntityId != null ? params.append('filterEntityIds', filterEntityId) : params;

    const retVal = this.http.get<BaseResponse<Email[]>>(path, { params });

    return retVal;
  }

  sendEmailByEntityKindEntityId(entityKind: string, entityId: number, request: EmailRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.sendEmailByEntityKindEntityId.name;

    const path = environment.getEndpoint(ApiPath.Emails.EMAILS_SEND(entityKind, entityId));
    LogService.info(this, m, LogService.POST + path, null);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  resendEmailByEntityKindEntityId(emailhistoryId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.sendEmailByEntityKindEntityId.name;

    const path = environment.getEndpoint(ApiPath.Emails.EMAILS_RESEND(emailhistoryId));
    LogService.info(this, m, LogService.POST + path, null);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  getMailHistoryByEntities(entities: EntityRequest[], request: PaginatedRequest): Observable<BaseResponse<MailHistory[]>> {
    const m = this.getMailHistoryByEntities.name;

    const path = environment.getEndpoint(ApiPath.Emails.EMAILS_MAIL_HISTORY);
    LogService.info(this, m, LogService.GET + path, entities);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.post<BaseResponse<MailHistory[]>>(path, entities, { params });
    return retVal;
  }

  getMailHistoryByEntitiesSpreadsheet(entities: EntityRequest[], request: PaginatedRequest) {
    const m = this.getMailHistoryByEntitiesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Emails.EMAILS_MAIL_HISTORY_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, entities);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    return this.http
      .post(path, entities, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getMailHistoryByEntityKindEntityId(
    entityKind: string,
    entityId: number,
    request: PaginatedRequest
  ): Observable<BaseResponse<MailHistory[]>> {
    const m = this.getMailHistoryByEntityKindEntityId.name;

    const path = environment.getEndpoint(ApiPath.Emails.EMAILS_MAIL_HISTORY_DEPRECATED(entityKind, entityId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<MailHistory[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getMailHistoryByApipath(apipath: string, request: PaginatedRequest): Observable<BaseResponse<MailHistory[]>> {
    const m = this.getMailHistoryByEntityKindEntityId.name;

    const path = environment.getEndpoint(apipath);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<MailHistory[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getMailHistoryParticulars(mailhistoryId: number): Observable<BaseResponse<Email>> {
    const m = this.getMailHistoryParticulars.name;

    const path = environment.getEndpoint(ApiPath.Emails.EMAILS_MAIL_HISTORY_PARTICULARS(mailhistoryId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<Email>>(path);

    return retVal;
  }

  getMailHistoryByEntityKindEntityIdParticulars(
    entityKind: string,
    entityId: number,
    mailhistoryId: number
  ): Observable<BaseResponse<Email>> {
    const m = this.getMailHistoryByEntityKindEntityId.name;

    const path = environment.getEndpoint(ApiPath.Emails.EMAILS_MAIL_HISTORY_PARTICULARS_BY_ENTITY(entityKind, entityId, mailhistoryId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<Email>>(path);

    return retVal;
  }

  getMailHistoryByEntityKindEntityIdSpreadsheet(entityKind: string, entityId: number, request: PaginatedRequest) {
    const m = this.getMailHistoryByEntityKindEntityIdSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Emails.EMAILS_MAIL_HISTORY_DEPRECATED_SPREADSHEET(entityKind, entityId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getMailHistoryByApipathSpreadsheet(apipath: string, request: PaginatedRequest) {
    const m = this.getMailHistoryByApipathSpreadsheet.name;

    const path = environment.getEndpoint(apipath);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  constructor(private http: HttpClient) {}
}
