import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sidenav-details-title-bar',
  templateUrl: './sidenav-details-title-bar.component.html',
  styleUrls: ['./sidenav-details-title-bar.component.scss'],
})
export class SidenavDetailsTitleBarComponent {
  @Input() detailsTitle: string;
  @Input() detailsIsEditing: boolean;
  @Input() detailsRemarks: string;
  @Input() isRemarksError: boolean;
  @Input() isRemarksValid: boolean;

  @Output() detailsCloseEvEm = new EventEmitter();

  constructor() {}
}
