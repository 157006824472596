import { createAction, props } from '@ngrx/store';
import { Userlicense } from 'src/app/models/userlicense';
import { AllocateConsumerAddonsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-addons-wizard/dates-step/allocate-consumer-addons-date-info';
import { AllocateConsumerAddonsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-addons-wizard/init-step/allocate-consumers-addons-init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Allocate Consumer Addon Init Information Step',
  props<{ initStep: AllocateConsumerAddonsInitStep }>()
);

export const selectDatesStep = createAction(
  '[Wizard Dates Step] Select Allocate Consumer Addon Dates Step',
  props<{ datesStep: AllocateConsumerAddonsDueDates }>()
);

export const selectAssetsStep = createAction(
  '[Wizard Asset Step] Select Allocate Consumer Addon Assets Step',
  props<{ assetsStep: Userlicense[] }>()
);

export const exitAllocateConsumerAddonsWizard = createAction('[Allocate Consumer Wizard Page] Exit Allocate Consumer Wizard');

export const destroyWizard = createAction('[Allocate Consumer Wizard Page] Destroy Wizard');
