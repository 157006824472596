<div id="step-value-control-table" class="entity-table">
  <div class="table-wrapper" [ngClass]="isEditing && isStepValuesEmpty() && isSavePressed ? 'invalid' : ''">
    <!-- <div class="title controls">
      <div class="label-container">
        <span class="label">{{ 'step_value_control_title' | translate }}</span>
      </div>
      <div class="icon-container" *ngIf="isEditing">
        <button
          mat-icon-button
          class="small-icon"
          matTooltip="{{ 'label_action_add' | translate }}"
          [matTooltipClass]="'fo-mat-tooltip'"
          [disabled]="isAddDisabled()"
          (click)="addStepValue()"
        >
          <mat-icon>add_circle</mat-icon>
        </button>
        <button
          mat-icon-button
          class="small-icon"
          matTooltip="{{ 'label_action_remove' | translate }}"
          [matTooltipClass]="'fo-mat-tooltip'"
          [disabled]="isRemoveDisabled()"
          (click)="removeStepValue()"
        >
          <mat-icon>remove_circle</mat-icon>
        </button>
      </div>
    </div> -->
    <form style="height: 100%">
      <div class="table-container">
        <table
          class="table table-stripes"
          mat-table
          [dataSource]="dataSourceTable"
          [ngClass]="isEditing && !disableShowDirty && isDirty() ? 'dirty' : ''"
        >
          <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'step_value_control_from' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.from }}
            </td>
          </ng-container>

          <ng-container matColumnDef="to">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'step_value_control_to' | translate }}
            </th>
            <td mat-cell *matCellDef="let row; let index = index">
              <ng-container *ngIf="!isEditing">{{ row.to }}</ng-container>
              <editable
                class="editable"
                *ngIf="isEditing"
                (update)="updateField($event, index, 'to')"
                (resetField)="resetField(index, 'to')"
              >
                <ng-template viewMode>
                  <div *ngIf="row.to">{{ row.to }}</div>
                  <div *ngIf="!row.to">-</div>
                </ng-template>
                <ng-template editMode>
                  <mat-form-field>
                    <input type="number" matInput [formControl]="getControl(index, 'to')" focusable resetOnEsc />
                  </mat-form-field>
                </ng-template>
              </editable>
            </td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'step_value_control_value' | translate }}
            </th>
            <td mat-cell *matCellDef="let row; let index = index; let value">
              <ng-container *ngIf="!isEditing">{{ getMoney(row.value) }}</ng-container>
              <editable
                class="editable"
                *ngIf="isEditing"
                (update)="updateField($event, index, 'value')"
                (resetField)="resetField(index, 'value')"
              >
                <ng-template viewMode>
                  <div *ngIf="row.value != null">
                    {{ getMoney(row.value) }}
                  </div>
                  <div *ngIf="row.value == null" class="invalid">-</div>
                </ng-template>
                <ng-template editMode class="value-control">
                  <mat-form-field>
                    <input type="text" matInput mat-input-decimal [formControl]="getControl(index, 'value')" focusable resetOnEsc />
                  </mat-form-field>
                </ng-template>
              </editable>
            </td>
          </ng-container>

          <ng-container matColumnDef="header-buttons">
            <th mat-header-cell *matHeaderCellDef>
              <div class="icon-container" *ngIf="isEditing">
                <button
                  mat-icon-button
                  class="small-icon"
                  matTooltip="{{ 'label_action_add' | translate }}"
                  [matTooltipClass]="'fo-mat-tooltip'"
                  [disabled]="isAddDisabled()"
                  (click)="addStepValue()"
                >
                  <mat-icon>add_circle</mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="small-icon"
                  matTooltip="{{ 'label_action_remove' | translate }}"
                  [matTooltipClass]="'fo-mat-tooltip'"
                  [disabled]="isRemoveDisabled()"
                  (click)="removeStepValue()"
                >
                  <mat-icon>remove_circle</mat-icon>
                </button>
              </div>
            </th>
            <td mat-cell *matCellDef="let row"></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsTable"
            (click)="onRowClicked(row)"
            [ngClass]="selectedStepValue && selectedStepValue.id === row.id && isEditing ? 'selected-row' : ''"
          ></tr>
        </table>
      </div>
      <div *ngIf="isStepValuesEmpty()" class="nodata">
        <span> {{ 'step_value_control_table_error_nodata' | translate }}</span>
      </div>
    </form>
  </div>
</div>
