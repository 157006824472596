import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { AssetSubscription } from 'src/app/models/asset-subscription';
import { Desk } from 'src/app/models/desk';
import { FeedItem } from 'src/app/models/feeditem';
import { CopyAssetDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-asset-wizard/activation-step/copy-asset-due-date-info';
import { CopyAssetAdditionalInfo } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-asset-wizard/additional-step/additional-info';
import { CopyAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-asset-wizard/init-step/init-step';
import * as fromOrderWizard from './reducer';
import { State } from './state';

export const selectCopyAssetWizardState = createFeatureSelector<State>(fromOrderWizard.copyAssetWizardFeatureKey);

export const selectInitStep = createSelector(selectCopyAssetWizardState, (state: State): CopyAssetInitStep => state.initStep);

export const selectAssetServicesInitStep = createSelector(
  selectCopyAssetWizardState,
  (state: State): AssetSubscription[] => state.initAssetServiceStep
);

export const selectDeskStep = createSelector(selectCopyAssetWizardState, (state: State): Desk => state.deskStep);

export const selectDueDateStep = createSelector(selectCopyAssetWizardState, (state: State): CopyAssetDueDates => state.dueDatesStep);

export const selectServiceStep = createSelector(selectCopyAssetWizardState, (state: State): FeedItem[] => state.serviceStep);

export const selectAdditionaInfoStep = createSelector(
  selectCopyAssetWizardState,
  (state: State): CopyAssetAdditionalInfo => state.addInfoStep
);

export const selectNewOrderState = createSelector(selectCopyAssetWizardState, (state: State): ActionResponse => state.newOrderResponse);

export const selectErrorMessage = createSelector(selectCopyAssetWizardState, (state: State): string => state.errorMessage);
