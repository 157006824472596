import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entities } from 'src/app/configs/entities';
import { ForeignEntity } from 'src/app/models/foreign-entity';
import { RootStoreState } from 'src/app/root-store';
import { GridBaseComponent } from 'src/app/shared/base-components/grid-base-component';

export interface ThreediModalDialogData {
  threediprofileId: number;
  kind: ThreediModalDialogKind;
}
export enum ThreediModalDialogKind {
  product,
  exchange,
  vendor,
}

@Component({
  selector: 'app-threedi-modal-dialog',
  templateUrl: './threedi-modal-dialog.component.html',
  styleUrls: ['./threedi-modal-dialog.component.scss'],
})
export class ThreediModalDialogComponent extends GridBaseComponent {
  constructor(
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ThreediModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ThreediModalDialogData
  ) {
    super(store, cdRef);
  }

  gbOnInit() {
    if (this.data.threediprofileId != null) {
      this.filtersMapStatic.set('filterVuidIds', [this.data.threediprofileId]);
    }
  }

  gbGetDisplayColumnTable() {
    switch (this.data.kind) {
      case ThreediModalDialogKind.product:
        return ['radio', 'threediproductName', 'threediproductDelivery', 'threediproductSynopsis'];
      case ThreediModalDialogKind.exchange:
        return ['radio', 'threediexchangeName'];
      case ThreediModalDialogKind.vendor:
        return ['radio', 'threedivendorName', 'threedivendorTechnicalArchitecture', 'threedivendorSynopsis'];
    }
  }

  gbGetInitialOrderBy() {
    return 'threediproductName';
  }

  gbLoadEntitiesData() {
    let apiPath;
    switch (this.data.kind) {
      case ThreediModalDialogKind.product:
        apiPath = ApiPath.Profiler.PROFILER_3DI_PRODUCTS;
        break;
      case ThreediModalDialogKind.exchange:
        apiPath = ApiPath.Profiler.PROFILER_3DI_EXCHANGES;
        break;
      case ThreediModalDialogKind.vendor:
        apiPath = ApiPath.Profiler.PROFILER_3DI_VENDORS;
        break;
    }
    this.clearSelection();
    this.isLoading = true;
    this.dataSourceTable = new MatTableDataSource();
    this.noEntityData = false;
    if (this.gridLoadSubscription != null) {
      this.gridLoadSubscription.unsubscribe();
    }
    this.gridLoadSubscription = this.subscribe(
      this.coreDataService.getForeignEntity(apiPath, this.request),
      (response) => {
        this.lastUsedFilters = response.filters;
        this.dataSourceTable.data = response.data ? response.data : [];
        this.noEntityData = !response.data || response.data.length === 0;
        this.pageTotalElements = !response.data || response.data.length === 0 ? 0 : response.data[0].entityCount;
        this.isLoading = false;
        this.cdRef.detectChanges();
      },
      (error) => {
        this.isLoading = false;
        this.noEntityData = true;
      }
    );
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onEntitySelected(row: ForeignEntity) {
    this.selectedEntity = row;
  }

  isEntitySelected() {
    return this.selectedEntity && this.selectedEntity.entityName !== '';
  }

  get Entities() {
    return Entities;
  }

  get ThreediModalDialogKind() {
    return ThreediModalDialogKind;
  }
}
