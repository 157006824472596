import { ActionResponse } from 'src/app/models/action-response';
import { CancelAssetCancellationDetailsStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-asset-wizard/cancel-asset-cancellation-details-step/cancel-asset-cancellation-details-step';
import { CancelAssetWizardRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-asset-wizard/cancel-asset-confirm-step/cancel-asset-services-request';
import { CancelAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-asset-wizard/init-step/init-step';

export interface State {
  initStep: CancelAssetInitStep;
  cancellationDetails: CancelAssetCancellationDetailsStep;
  request: CancelAssetWizardRequest;
  response: ActionResponse;
  errorMessage: any;
}

export const initialState: State = {
  initStep: null,
  cancellationDetails: null,
  request: null,
  response: null,
  errorMessage: null,
};
