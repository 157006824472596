import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LogService } from '../log-service';
import { PreferenceDataService } from '../preference-data.service';

@Injectable({ providedIn: 'root' })
export class DemoGuardService implements CanActivate {
  constructor(private router: Router, private preferenceSettingsService: PreferenceDataService) {}

  canActivate() {
    return this.preferenceSettingsService.getPreferenceSettings(false, false).pipe(
      switchMap(({ data }) => {
        const canDemoLogin = data.settingAllowDemoLogin;
        LogService.debug(this, this.canActivate.name, 'demo:', canDemoLogin);
        if (canDemoLogin) {
          return of(true);
        } else {
          this.router.navigate(['/']);
          return of(false);
        }
      })
    );
  }
}
