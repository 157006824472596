import { Action, createReducer, on } from '@ngrx/store';
import * as ChangeCostcentreAllocationsWizardActions from './actions';
import { State, initialState } from './state';

export const changeCostcentreAllocationsWizardFeatureKey = 'changeCostcentreAllocationsWizard';

const changeCostcentreAllocationsWizardReducer = createReducer(
  initialState,

  on(ChangeCostcentreAllocationsWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(ChangeCostcentreAllocationsWizardActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(ChangeCostcentreAllocationsWizardActions.selectDestinationStep, (state, { allocationStep }) => ({
    ...state,
    allocationStep,
  })),
  on(ChangeCostcentreAllocationsWizardActions.exitChangeCostcentreAllocationsWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    allocationStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(ChangeCostcentreAllocationsWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    allocationStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(ChangeCostcentreAllocationsWizardActions.createNewChangeCostcentreAllocations, (state, { request }) => ({
    ...state,
    request,
  })),
  on(ChangeCostcentreAllocationsWizardActions.createNewChangeCostcentreAllocationsSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(ChangeCostcentreAllocationsWizardActions.createNewChangeCostcentreAllocationsFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return changeCostcentreAllocationsWizardReducer(state, action);
}
