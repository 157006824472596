import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { ServiceAlias } from 'src/app/models/service';
import { ReplaceBillingitemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-billingitems-wizard/activation-step/replacebillingitems-due-date-info';
import { ReplaceBillingitemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-billingitems-wizard/init-step/init-step';
import * as fromReplaceBillingitemsWizard from './reducer';
import { State } from './state';

export const selectReplaceBillingitemsWizardState = createFeatureSelector<State>(
  fromReplaceBillingitemsWizard.replaceBillingitemsWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectReplaceBillingitemsWizardState,
  (state: State): ReplaceBillingitemsInitStep => state.initStep
);

export const selectDueDateStep = createSelector(
  selectReplaceBillingitemsWizardState,
  (state: State): ReplaceBillingitemsDueDates => state.dueDatesStep
);

export const selectServicesStep = createSelector(selectReplaceBillingitemsWizardState, (state: State): ServiceAlias[] => state.serviceStep);

export const selectReplaceBillingitemsState = createSelector(
  selectReplaceBillingitemsWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectReplaceBillingitemsWizardState, (state: State): string => state.errorMessage);
