import { Action, createReducer, on } from '@ngrx/store';
import * as AddServiceTopackageWizardActions from './actions';
import { State, initialState } from './state';

export const addServiceTopackageWizardFeatureKey = 'addServiceTopackageWizard';

const addServiceTopackageWizardReducer = createReducer(
  initialState,

  on(AddServiceTopackageWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(AddServiceTopackageWizardActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(AddServiceTopackageWizardActions.selectServices, (state, { servicesStep }) => ({
    ...state,
    servicesStep,
  })),
  on(AddServiceTopackageWizardActions.exitAddServiceTopackageWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    servicesStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(AddServiceTopackageWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    servicesStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  // on(AddServiceTopackageWizardActions.createNewAddServiceTopackage, (state, { request }) => ({
  //   ...state,
  //   request,
  // })),
  on(AddServiceTopackageWizardActions.createNewAddServiceTopackageSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(AddServiceTopackageWizardActions.createNewAddServiceTopackageFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return addServiceTopackageWizardReducer(state, action);
}
