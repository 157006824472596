import { ActionResponse } from 'src/app/models/action-response';
import { AddonCancelWizardRequest as TerminateMultiAddonsWizardRequest } from 'src/app/models/asset-subscription';
import { Service } from 'src/app/models/service';
import { TerminateMultiAddonsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/terminate-multi-addons-wizard/init-step/init-step';
export interface State {
  initStep: TerminateMultiAddonsInitStep;
  serviceStep: Service[];
  request: TerminateMultiAddonsWizardRequest;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  serviceStep: null,
  request: null,
  response: null,
  errorMessage: null,
};
