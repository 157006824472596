/* eslint-disable prettier/prettier */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Entity } from 'src/app/models/entity';
import { RootStoreState } from 'src/app/root-store';
import { GlobalSearchStoreAction, GlobalSearchStoreSelectors } from 'src/app/root-store/global-search-store';
import { GlobalSearchService } from 'src/app/services/global-search-service';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

@Component({
  selector: 'app-global-search-bar, global-search-bar',
  templateUrl: './global-search-bar.component.html',
  styleUrls: ['./global-search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalSearchBarComponent extends FormatComponent implements OnInit, AfterViewInit {
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @ViewChild('searchInputField') searchInputField: ElementRef;
  @Input() isDisabled: boolean;

  SEARCH_ALL_ENTITY_FILTER = _('global_search_filter_entity_all');
  LAST_SEARCH_NUMBER = 5;

  filter: string;
  selectedEntityKind: string = null;
  entityKindOptions: Entity[];
  enableSearch = false;

  lastSearchOptions: string[];

  constructor(
    private globarSearchService: GlobalSearchService,
    protected store: Store<RootStoreState.State>,
    protected translate: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    // this.loadEntities();
    // this.translate.onLangChange.subscribe(() => {
    //   this.loadEntities();
    // });
    this.subscribe(this.store.select(GlobalSearchStoreSelectors.selectIsFinished), (isFinished) => {
      if (isFinished) {
        this.loadLastsSearch();
      }
    });
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
    // this.searchInputField.focus();
    window.addEventListener('keydown', (e) => {
      this.onKeyDown(e);
    });
  }

  // loadEntities() {
  //   this.subscribe(this.globarSearchService.getGlobalSearchEntities(), (response) => {
  //     if (response && response.data) {
  //       this.entityKindOptions = [];
  //       const emptyEntityTypeFilter = new Entity();
  //       emptyEntityTypeFilter.entityKind = this.SEARCH_ALL_ENTITY_FILTER;
  //       this.entityKindOptions.push(emptyEntityTypeFilter);
  //       this.entityKindOptions.push(...response.data);
  //     }
  //   });
  // }

  loadLastsSearch() {
    this.lastSearchOptions = [];
    this.subscribe(this.globarSearchService.getGlobalSearchLastSearchs(this.LAST_SEARCH_NUMBER), (response) => {
      if (response && response.data) {
        this.lastSearchOptions = response.data;
      }
    });
  }

  applyFilterTable(filterValue: string) {
    this.autocomplete.closePanel();

    if (filterValue && filterValue.length >= 3) {
      this.enableSearch = true;
    } else {
      this.enableSearch = false;
    }
  }

  doSearch() {
    if (this.enableSearch) {
      LogService.debug(this, this.doSearch.name, 'filter', this.filter);
      LogService.debug(this, this.doSearch.name, 'selectedEntityKind', this.selectedEntityKind);
      // const entityKind = this.selectedEntityKind === this.SEARCH_ALL_ENTITY_FILTER ? null : this.selectedEntityKind;
      this.store.dispatch(GlobalSearchStoreAction.requestNewGlobalSearch({ filter: this.filter }));
    }
  }

  onKeyDown($event): void {
    // Detect platform
    if (navigator.platform.match('Mac')) {
      this.handleMacKeyEvents($event);
    } else {
      this.handleWindowsKeyEvents($event);
    }
  }

  handleMacKeyEvents($event) {
    // MetaKey documentation
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/metaKey
    let charCode = String.fromCharCode($event.which).toLowerCase();
    if ($event.metaKey && charCode === 'f') {
      // Action on Cmd + F
      if (!this.searchInputField.nativeElement.focused) {
        $event.preventDefault();
        this.searchInputField.nativeElement.focus();
      }
    }
  }

  handleWindowsKeyEvents($event) {
    let charCode = String.fromCharCode($event.which).toLowerCase();
    if ($event.ctrlKey && charCode === 'f') {
      // Action on Ctrl + F
      if (!this.searchInputField.nativeElement.focused) {
        $event.preventDefault();
        this.searchInputField.nativeElement.focus();
      }
    }
  }
}
