import { Action, createReducer, on } from '@ngrx/store';
import * as FoModuleActions from './actions';
import { State, initialState } from './state';

export const coreUiInterfaceFeatureKey = 'coreUiInterface';

const coreUiInterfaceReducer = createReducer(
  initialState,

  on(FoModuleActions.disableCoreUiInterface, (state) => ({
    ...state,
    isUiDisabled: true,
  })),
  on(FoModuleActions.enableCoreUiInterface, (state) => ({
    ...state,
    isUiDisabled: false,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return coreUiInterfaceReducer(state, action);
}
