import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormatComponent } from '../../base-components/format-component';

export interface GenericFormWizardModalDialogData {
  wizardName: string;
}

@Component({
  selector: 'app-generic-form-wizard-modal-dialog',
  templateUrl: './generic-form-wizard-modal-dialog.component.html',
  styleUrls: ['./generic-form-wizard-modal-dialog.component.scss'],
})
export class GenericFormWizardModalDialogComponent extends FormatComponent implements OnInit {
  wizardName: string;

  constructor(
    public dialogRef: MatDialogRef<GenericFormWizardModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericFormWizardModalDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.wizardName = this.data.wizardName;
  }

  onFinishCreateEntity() {
    this.dialogRef.close();
  }

  onCancelCreateEntity() {
    this.dialogRef.close();
  }
}
