<div *ngIf="fileUrl" class="custom-image-main">
  <img class="image" *ngIf="type === IMAGE && fileUrl" [src]="fileUrl" [alt]="alt" [title]="title" />
  <iframe class="youtube" *ngIf="type === YOUTUBE && fileUrl" [src]="fileUrl" frameborder="0" allowfullscreen></iframe>
  <video
    class="video"
    (click)="videoClickHandler($event)"
    [autoplay]="videoAutoPlay"
    *ngIf="type === VIDEO"
    type="video/mp4"
    [attr.controls]="showVideoControls ? showVideoControls : null"
    controlsList="nodownload"
  >
    <source [src]="fileUrl" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <div [dir]="direction" *ngIf="!fileUrl" class="invalid-msg">Invalid file format</div>
  <span *ngIf="type === YOUTUBE || type === VIDEO || isVideo" class="youtubedrag"></span>
</div>
