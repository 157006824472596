<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span>{{ progressTitleKey | translate }}</span>
  <div class="buttons-container">
    <span style="margin-left: 0.5rem"></span>
    <button *ngIf="!progressSuccess" mat-raised-button color="primary" mat-dialog-close (click)="cancelSaveEntity()">
      <mat-icon>cancel</mat-icon>
      <span>{{ 'label_cancel' | translate }}</span>
    </button>
    <button *ngIf="progressSuccess" mat-raised-button color="primary" (click)="close()">
      <mat-icon>check</mat-icon>
      <span>{{ 'label_ok' | translate }}</span>
    </button>
  </div>
</div>
<div mat-dialog-content>
  <div class="progress-error" *ngIf="progressErrorMessage">
    <span class="error" [innerHTML]="progressErrorMessage | translate"></span>
  </div>
  <div class="progress-error-description" *ngIf="progressErrorMessage">
    <pre class="error" [innerHTML]="progressErrorMessageDescription | translate"></pre>
  </div>
  <div class="progress-success" *ngIf="showSuccessMessage && progressSuccess">
    <span class="success">{{ successMessage | translate }}</span>
  </div>
  <ng-container *ngIf="!progressErrorMessage">
    <div class="progress-message-title" *ngIf="!(showSuccessMessage && progressSuccess)">
      <span>{{ progressTitleMessageKey | translate }}</span>
      <mat-progress-bar
        [mode]="progressErrorMessage ? 'determinate' : 'buffer'"
        color="primary"
        [value]="progressPercentage"
      ></mat-progress-bar>
    </div>
    <div class="progress-messages" *ngIf="progressMsgDatasource && progressMsgDatasource.data.length > 0">
      <div class="entity-table">
        <div class="table-wrapper">
          <div #messagesContainer class="table-container">
            <table class="table table-stripes table-truncate-text" mat-table [dataSource]="progressMsgDatasource">
              <ng-container matColumnDef="progressTimestamp">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'progressstep_progresstimestamp' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.progressTimestamp" *matCellDef="let row">
                  {{ getTimestamp(row.progressTimestamp) }}
                </td>
              </ng-container>
              <ng-container matColumnDef="progressText">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'progressstep_progressmessage' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.progressText" *matCellDef="let row">{{ row.progressText }}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="progressDisplayedColumnsTable; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: progressDisplayedColumnsTable"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
