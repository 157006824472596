import { createAction, props } from '@ngrx/store';
import { FlyingButtonSettings, PreferenceSettings } from 'src/app/models/preference-settings';

// export const loadPageSizes = createAction('[Preferences] Load Page Sizes', props<{ isAuth: boolean; isTenantAdmin: boolean }>());

// export const loadPageSizesSuccess = createAction('[Preferences] Load Page Sizes Success', props<{ paginatorOptions: number[] }>());

// export const loadPageSizesFailure = createAction('[Preferences] Load Page Sizes Failure', props<{ error: string }>());

// export const loadLanguageRegion = createAction('[Preferences] Load Language and Region', props<{ isTenantAdmin: boolean }>());

// export const loadLanguageRegionSuccess = createAction(
//   '[Preferences] Load Language and Region Success',
//   props<{ languageRegion: LanguageRegion }>()
// );

// export const loadLanguageRegionFailure = createAction('[Preferences] Load Language and Region Failure', props<{ error: string }>());

export const loadPreferenceSettings = createAction(
  '[Preferences] Preference Settings',
  props<{ isAuth: boolean; isTenantAdmin: boolean }>()
);

export const loadPreferenceSettingsSuccess = createAction(
  '[Preferences] Preference Settings Success',
  props<{ preferenceSettings: PreferenceSettings }>()
);

export const loadPreferenceSettingsFailure = createAction('[Preferences] Preference Settings Failure', props<{ error: string }>());

export const loadFlyingButtonSettings = createAction('[Preferences] Flying Button Settings', props<{ isTenantAdmin: boolean }>());

export const loadFlyingButtonSettingsSuccess = createAction(
  '[Preferences] Flying Button Settings Success',
  props<{ flyingButtonSettings: FlyingButtonSettings }>()
);

export const loadFlyingButtonSettingsFailure = createAction('[Preferences] Flying Button Settings Failure', props<{ error: string }>());

export const storeFlyingButtonSettings = createAction(
  '[FormatComponent] Store Flying Button Settings',
  props<{ isTenantAdmin: boolean; flyingButtonSettings: FlyingButtonSettings }>()
);

export const loadEnabledPaths = createAction('[Preferences] User Enabled Paths');

export const loadEnabledPathsSuccess = createAction('[Preferences] User Enabled Paths Success', props<{ enabledPaths: string[] }>());

export const loadEnabledPathsFailure = createAction('[Preferences] User Enabled Paths Failure', props<{ error: string }>());
