import { MatDialog } from '@angular/material/dialog';
import { SimpleDialogComponent } from './generic-components/simple-dialog/simple-dialog.component';

export class MessageBox {
  static show(
    dialog: MatDialog,
    message,
    title = 'Alert',
    information = '',
    type = 0,
    button = 0,
    allowOutsideClick = false,
    style = 0,
    width = '200px',
    isHtmlTitle?: boolean
  ) {
    const dialogRef = dialog.open(SimpleDialogComponent, {
      data: {
        title: title || 'Alert',
        isHtmlTitle: isHtmlTitle || false,
        message,
        information,
        type: type || 0,
        button: button || 0,
        style: style || 0,
        allowOutsideClick: allowOutsideClick || false,
      },
      width,
    });
    return dialogRef.afterClosed();
  }
}

export enum MessageBoxButton {
  Ok = 0,
  OkCancel = 1,
  YesNo = 2,
  AcceptReject = 3,
  NextCancel = 4,
  Close = 5,
}

export enum MessageBoxType {
  Comfirm = 0,
  Input = 1,
}

export enum MessageBoxStyle {
  Simple = 0,
  Full = 1,
}
