import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { FormatComponent } from '../../base-components/format-component';
import { StepValue } from './step-value';

@Component({
  selector: 'app-step-value-control',
  templateUrl: './step-value-control.component.html',
  styleUrls: ['./step-value-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StepValueControlComponent extends FormatComponent implements OnInit {
  @Input() isEditing: boolean;
  @Input() stepValues: StepValue[] = [];
  @Input() valueCustomLabel = _('step_value_control_value');
  @Input() disableShowDirty = false;
  @Input() isSavePressed = true;
  @Output() isValidEvEm = new EventEmitter<boolean>();
  @Output() changeValueEvEm = new EventEmitter<StepValue[]>();
  @Output() loseFocusEvEm = new EventEmitter();

  stepValuesData: StepValue[] = [];
  deletedSteps: StepValue[] = [];

  selectedStepValue: StepValue;

  dataSourceTable: MatTableDataSource<StepValue>;
  displayedColumnsTable = ['from', 'to', 'value', 'header-buttons'];
  controls: UntypedFormArray;
  dirtyForm = false;

  newStepId = -1;

  constructor(private cdref: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.deletedSteps = [];
    this.dirtyForm = false;
    this.stepValuesData = this.cloneStepValues();
    this.dataSourceTable = new MatTableDataSource(this.stepValuesData);
    this.dataSourceTable.filterPredicate = (data) => {
      return !data.deleted;
    };
    this.dataSourceTable.filter = 'true';
    // Table Form
    this.initForm();
  }

  isValid() {
    if (!this.isStepValuesEmpty()) {
      const lenght = this.stepValuesData.length;
      const lastLine = this.stepValuesData[lenght - 1];
      return this.controls.valid && lastLine.to == null;
    } else {
      return false;
    }
  }

  buildStepValuesRequest() {
    return this.deletedSteps.concat(this.stepValuesData);
  }

  onRowClicked(row: StepValue) {
    if (this.isEditing) {
      this.selectedStepValue = { ...row };
    } else {
      this.selectedStepValue = null;
    }
  }

  addStepValue() {
    const lastStep = this.stepValuesData[this.stepValuesData.length - 1];
    const newStep = { id: this.newStepId--, from: lastStep ? lastStep.to + 1 : 1, to: null, value: null, deleted: false };
    this.stepValuesData.splice(this.stepValuesData.length, 0, newStep);
    console.log(this.stepValuesData);
    this.initForm();
    this.dataSourceTable._updateChangeSubscription();
    this.cdref.detectChanges();
  }

  removeStepValue() {
    const selectedStepIndex = this.stepValuesData.findIndex((step) => this.selectedStepValue.id === step.id);
    const removedStep = this.stepValuesData[selectedStepIndex];
    removedStep.deleted = true;
    if (this.stepValuesData.length > 1) {
      if (selectedStepIndex === this.stepValuesData.length - 1) {
        this.stepValuesData[selectedStepIndex - 1].to = null;
      } else {
        this.updateNextStep(selectedStepIndex, removedStep.from);
      }
    }
    this.deletedSteps.push(removedStep);
    this.stepValuesData.splice(selectedStepIndex, 1);
    this.initForm();
    this.dataSourceTable._updateChangeSubscription();
  }

  updateNextStep(index: number, previousStepTo: number) {
    const nextStep = this.stepValuesData[index + 1];
    if (nextStep && previousStepTo) {
      if (nextStep.to == null || nextStep.to >= previousStepTo + 1) {
        nextStep.from = previousStepTo === 1 ? 1 : previousStepTo + 1;
        this.initForm();
        this.dataSourceTable._updateChangeSubscription();
      } else {
        this.removeStepsAfterUpdate(index + 1, previousStepTo);
      }
    } else if (nextStep && !previousStepTo) {
      this.removeStepsAfterUpdate(index + 1, previousStepTo);
    }
  }

  private removeStepsAfterUpdate(index: number, previousStepTo: number) {
    const removedStep = this.stepValuesData[index];
    removedStep.deleted = true;
    this.deletedSteps.push(removedStep);
    this.stepValuesData.splice(index, 1);
    const nextStep = this.stepValuesData[index];
    if (nextStep) {
      if (previousStepTo && (nextStep.to == null || nextStep.to >= previousStepTo + 1)) {
        nextStep.from = previousStepTo + 1;
        this.initForm();
        this.dataSourceTable._updateChangeSubscription();
      } else {
        this.removeStepsAfterUpdate(index, previousStepTo);
      }
    } else {
      this.initForm();
      this.dataSourceTable._updateChangeSubscription();
    }
  }

  /* Table form methods */

  isDirty() {
    return this.dirtyForm || this.deletedSteps.length > 0 || this.dataSourceTable.data.filter((sp) => sp.id < 0).length > 0;
  }

  updateField($event, index: number, field: string) {
    // console.log('updateField', { $event, index, field });
    // console.log('updateField', this.stepValuesData);
    if ($event === '') {
      return;
    }
    if ($event && this.dataSourceTable.data[index][field] !== $event) {
      // $event is valorized when user digits into input cell.
      this.dirtyForm = true;
      console.log('updateField', field);
      if (field === 'value') {
        this.stepValuesData[index][field] = this.castToFloat2Decimal($event);
      }
      if (field === 'to') {
        this.stepValuesData[index][field] = this.castToInt($event);
      }
      const isValid = this.isValid();
      this.isValidEvEm.emit(isValid);
      this.changeValueEvEm.emit(this.buildStepValuesRequest());
    } else {
      // $event is null when user blur input cell.
      const control = this.getControl(index, field);
      // Update table with form control value.
      this.stepValuesData[index][field] =
        field === 'value' ? this.castToFloat2Decimal(control.value) : control.value ? this.castToInt(control.value) : null; // When enter is pressed, value is passed by event emitter
      // Update of other rows
      if (field === 'to') {
        if (index < this.stepValuesData.length - 1) {
          // Middle row
          const rowTo = control.value ? this.castToInt(control.value) : null;
          this.updateNextStep(index, rowTo);
        } else {
          // Last row
          if (this.stepValuesData[index].to !== null) {
            // Create new row. Last row To must be equal to null
            this.initForm();
            this.addStepValue();
          } else {
            this.initForm();
          }
        }
      } else {
        this.initForm();
      }
    }
  }

  resetField(index: number, field: string) {
    console.log('resetField', { index, field });
    const originalValue = this.stepValuesData[index][field];
    this.getControl(index, field).setValue(originalValue);
  }

  getControl(index: number, fieldName: string) {
    const a = this.controls.at(index).get(fieldName) as UntypedFormControl;
    return this.controls.at(index).get(fieldName) as UntypedFormControl;
  }

  private initForm() {
    const toGroups = this.stepValuesData.map((step) => {
      return new UntypedFormGroup(
        {
          id: new UntypedFormControl(step.id, Validators.required),
          from: new UntypedFormControl(step.from, Validators.required),
          to: new UntypedFormControl(step.to),
          value: new UntypedFormControl(this.getMoneyEdit(step.value), Validators.required),
          deleted: new UntypedFormControl(step.deleted, Validators.required),
        },
        { updateOn: 'change' }
      );
    });
    this.controls = new UntypedFormArray(toGroups);
    this.isValidEvEm.emit(this.isValid());
    this.changeValueEvEm.emit(this.buildStepValuesRequest());
  }

  resetStepValueData() {
    this.ngOnInit();
  }

  private cloneStepValues() {
    // fast way to deep copy an array avoiding reference update.
    return JSON.parse(JSON.stringify(this.stepValues));
  }

  /* View controller */

  isStepValuesEmpty() {
    return this.stepValuesData.length === 0;
  }

  isAddDisabled(): boolean {
    if (this.isStepValuesEmpty()) {
      return false;
    } else {
      return this.stepValuesData[this.stepValuesData.length - 1].to == null;
    }
  }

  isRemoveDisabled(): boolean {
    return this.stepValuesData.length === 0 || !this.selectedStepValue;
  }
}
