import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AddSubscriptionsWizardEffects } from './effects';
import * as fromAddSubscriptionsWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAddSubscriptionsWizard.addSubscriptionsWizardFeatureKey, fromAddSubscriptionsWizard.reducer),
    EffectsModule.forFeature([AddSubscriptionsWizardEffects]),
  ],
})
export class AddSubscriptionsWizardStoreModule {}
