import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { PaginatedRequest } from '../models/paginated-request';
import { PolicyRelation, PolicyRelationRequest } from '../models/policy';
import { ProductFamily, getProductFamilyRequestParams } from '../models/productfamily';
import { ProductFamilyMailTemplate } from '../models/productfamily-mailtemplate';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class ProductFamilyDataService {
  getProductFamilies(request: PaginatedRequest): Observable<BaseResponse<ProductFamily[]>> {
    const m = this.getProductFamilies.name;

    const path = environment.getEndpoint(ApiPath.ProductFamily.PRODUCTFAMILIES);
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<ProductFamily[]>>(path, {
      params: getProductFamilyRequestParams(request),
    });

    return retVal;
  }

  getProductFamiliesSpreadsheet(request: PaginatedRequest) {
    const m = this.getProductFamiliesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.ProductFamily.PRODUCTFAMILIES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: getProductFamilyRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getProductfamilyMailTemplates(productfamilyId: number, request: PaginatedRequest): Observable<BaseResponse<ProductFamilyMailTemplate[]>> {
    const m = this.getProductfamilyMailTemplates.name;

    const path = environment.getEndpoint(ApiPath.ProductFamily.PRODUCTFAMILIES_MAILTEMPLATES(productfamilyId));
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<ProductFamilyMailTemplate[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getProductfamilyMailTemplatesSpreadsheet(productfamilyId: number, request: PaginatedRequest) {
    const m = this.getProductfamilyMailTemplatesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.ProductFamily.PRODUCTFAMILIES_MAILTEMPLATES_SPREADSHEET(productfamilyId));
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: getProductFamilyRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  deleteProductfamilyForm(productfamilyId: number, formId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.deleteProductfamilyForm.name;

    const path = environment.getEndpoint(ApiPath.ProductFamily.PRODUCTFAMILIES_CONFIGURATION_FORM(productfamilyId, formId));
    LogService.info(this, m, LogService.DELETE + path, null);
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path);

    return retVal;
  }

  copyProductfamilyConfigurationFrom(productFromId: number, productToId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.copyProductfamilyConfigurationFrom.name;

    const path = environment.getEndpoint(ApiPath.ProductFamily.PRODUCTFAMILIES_CONFIGURATION_COPY(productFromId, productToId));
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  getProductfamilyPolicies(vendorId: number): Observable<BaseResponse<PolicyRelation[]>> {
    const m = this.getProductfamilyPolicies.name;

    const path = environment.getEndpoint(ApiPath.ProductFamily.PRODUCTFAMILIES_POLICIES(vendorId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<PolicyRelation[]>>(path);

    return retVal;
  }

  saveProductfamilyPolicies(vendorId: number, request: PolicyRelationRequest[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.saveProductfamilyPolicies.name;

    const path = environment.getEndpoint(ApiPath.ProductFamily.PRODUCTFAMILIES_POLICIES(vendorId));
    LogService.info(this, m, LogService.POST + path, null);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
