import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { GenericFormFieldRequiredType } from 'src/app/models/forms/form-field';
import { StepValue } from 'src/app/shared/inputs/step-value-control/step-value';
import { BaseFormFieldComponent } from '../base-form-field/base-form-field-component';

@Component({
  selector: 'app-form-field-step-control',
  templateUrl: './form-field-step-control.component.html',
  styleUrls: ['./form-field-step-control.component.scss'],
})
export class FormFieldStepControlComponent extends BaseFormFieldComponent implements OnInit {
  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (this.saveSubj) {
      this.subscribe(this.saveSubj, () => (this.isSavePressed = true));
    }
  }

  mockedData: StepValue[] = [
    { id: 1, from: 1, to: 100, value: 100 },
    { id: 2, from: 101, to: 150, value: 80 },
    { id: 3, from: 151, to: null, value: 70 },
  ];

  setValid($event: boolean) {
    this.formFieldData.isStepControlValid = $event;
    this.cdRef.detectChanges();
  }
  get GenericFormFieldRequiredType() {
    return GenericFormFieldRequiredType;
  }
}
