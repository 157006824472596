/** RESTRICTED AUTH */

import { createAction, props } from '@ngrx/store';
import { AuthResult } from 'src/app/core/auth/auth-result';

export const restrictedAutoAuthenticate = createAction(
  '[Restricted Home Component] Restricted Auto Authenticate',
  props<{ token: string }>()
);

export const restrictedAuthSuccess = createAction('[Restricted Home Component] Restricted Auth Success', props<{ result: AuthResult }>());

export const restrictedAuthFailure = createAction('[Restricted Home Component] Restricted Auth Failure', props<{ result: AuthResult }>());
