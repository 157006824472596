<div class="grid-filter-panel-input">
  <mat-form-field
    appearance="outline"
    class="form-field-input reset-outline-hint grid-filter-dropdown"
    [ngClass]="selection.selected.length > 0 ? 'filter-defined' : ''"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    [matTooltip]="filterTooltip"
  >
    <input type="text" matInput #optionSelect [value]="filterString" readonly (click)="$event.stopPropagation(); openOverlay()" />
    <button *ngIf="!isOverlayOpen" mat-icon-button matSuffix (click)="$event.stopPropagation(); openOverlay()">
      <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
    </button>
    <button *ngIf="!isOverlayOpen && selection.selected.length > 0" mat-icon-button matSuffix (click)="reset()">
      <mat-icon>close</mat-icon>
    </button>
    <button *ngIf="isOverlayOpen" mat-icon-button matSuffix>
      <mat-icon class="dropdown-icon">arrow_drop_up</mat-icon>
    </button>
  </mat-form-field>
</div>
<ng-template
  #filterOverlay="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOverlayOpen"
  [cdkConnectedOverlayPositions]="overlayPositions"
  (overlayOutsideClick)="closeOverlay()"
>
  <div class="grid-filter-options-overlay" ngResizable rzHandles="e,edrag" [rzMinWidth]="300">
    <div class="grid-filter-options-container">
      <mat-expansion-panel
        *ngIf="selection && selection.selected.length > 0"
        class="detail-expansion-panel selected-items-expansion-panel"
        [expanded]="true"
        [@.disabled]="true"
        [hideToggle]="true"
        [disabled]="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> {{ 'grid_filter_options_selected' | translate }} ({{ selection.selected.length }}) </mat-panel-title>
          <!-- <mat-panel-description (click)="reset(); $event.stopPropagation()">Reset</mat-panel-description> -->
        </mat-expansion-panel-header>
        <div class="table-wrapper">
          <div class="table-container">
            <table class="table table-stripes table-truncate-text" mat-table [dataSource]="selection.selected">
              <ng-container matColumnDef="entityName">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell showIfTruncated [matTooltip]="row.entityName" *matCellDef="let row">
                  {{ row.entityName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="cancel">
                <th mat-header-cell *matHeaderCellDef class="icondelete-header"></th>
                <td mat-cell class="icondelete-cell" *matCellDef="let row">
                  <mat-icon (click)="onCheckboxClicked(row)" style="color: red" fontSet="fas" fontIcon="fa-times"></mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsSelectionTable"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsSelectionTable"></tr>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
      <div class="options-container">
        <div class="table-wrapper">
          <div class="table-search-paginate">
            <div class="row" *ngIf="showSearchBar" style="margin-bottom: 4px">
              <mat-form-field appearance="outline" class="reset-outline-hint filter-field search">
                <mat-autocomplete
                  (opened)="matAutocompletPanelOpened()"
                  (closed)="matAutocompletPanelClosed()"
                  #autoLastStringOption="matAutocomplete"
                  (optionSelected)="applyFilterTable($event.option.value)"
                >
                  <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
                    {{ 'label_recent_searches' | translate }}
                  </mat-option>
                  <mat-option
                    class="form-field-option"
                    *ngFor="let opt of lastUsedFilters"
                    [value]="opt"
                    [class.mat-selected]="filter === opt"
                  >
                    {{ opt }}
                  </mat-option>
                </mat-autocomplete>
                <input
                  matInput
                  #filterInput
                  #searchInputAutocompleteTrigger="matAutocompleteTrigger"
                  [matAutocomplete]="autoLastStringOption"
                  (input)="searchInputAutocompleteTrigger.closePanel()"
                  [(ngModel)]="filter"
                  (debounceFilterInput)="applyFilterTable($event)"
                  [debounceTime]="600"
                  maxlength="1500"
                  placeholder="{{ 'grid_filter_options_table_filter_placeholder' | translate }}"
                />
                <button mat-icon-button (click)="resetFilter()" matSuffix>
                  <mat-icon class="reset-filter-icon"> close </mat-icon>
                </button>
              </mat-form-field>
              <mat-paginator
                [disabled]="isLoading"
                [hidePageSize]="true"
                #paginatorTable
                [pageSizeOptions]="pageSizeList"
                [length]="pageTotalElements"
                (page)="onPageChanged()"
                class="reset-paginator hidden-labels"
              ></mat-paginator>
            </div>
            <div class="row">
              <button mat-button color="primary" (click)="selectDisplayed()" class="filter-selection-button">
                {{ 'label_select_displayed' | translate }}
              </button>
              <button mat-button color="primary" (click)="selectAll()" class="filter-selection-button" *ngIf="showSearchBar">
                {{ 'label_select_all' | translate }}
              </button>
              <button mat-button color="primary" (click)="reset()" *ngIf="selection && selection.selected.length > 0">
                {{ 'label_reset' | translate }}
              </button>
            </div>
            <div class="row" style="margin-top: 4px">
              <button mat-flat-button color="primary" (click)="openTreeSelectionDialog(treeSelectionDialog)" style="width: 100%">
                {{ 'label_show_tree' | translate }}
              </button>
            </div>
          </div>
          <div class="table-container items">
            <table class="table table-stripes table-truncate-text" mat-table [dataSource]="dataSourceTable" *ngIf="!isLoading">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? onCheckboxClicked(row) : null"
                    [checked]="isRowSelected(row)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="entityName">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell showIfTruncated [matTooltip]="row.entityName" *matCellDef="let row">
                  {{ row.entityName }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
            </table>
            <mat-toolbar class="grid-filter-footer-toolbar" *ngIf="noEntityData || isLoading">
              <mat-toolbar-row *ngIf="isLoading" class="spinner">
                <mat-spinner diameter="50"></mat-spinner>
              </mat-toolbar-row>
              <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
                <span>{{ 'generic_filter_error_nodata' | translate }}</span>
              </mat-toolbar-row>
            </mat-toolbar>
          </div>
          <div class="close-button-container">
            <button mat-flat-button color="primary" (click)="closeOverlay()">
              <ng-container> {{ 'label_close' | translate }}</ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #treeSelectionDialog>
  <div
    class="dialog-draggable-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
  >
    <mat-icon>drag_indicator</mat-icon>
  </div>
  <div class="dialog-header">
    <span>{{ 'label_filter' | translate }} {{ filterTitle }}</span>
    <div class="buttons-container">
      <button mat-raised-button color="primary" [disabled]="isLoading" (click)="closeTreeSelectionDialog()">
        <span>{{ 'label_close' | translate }}</span>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="grid-filter-treeselection-dialog-content">
    <div class="entity-table">
      <div class="table-wrapper">
        <div class="table-search-paginate hidden-filters">
          <div class="table-search-paginate-left-wrapper">
            <mat-form-field appearance="outline" class="reset-outline-hint filter-field search">
              <input
                matInput
                [(ngModel)]="filterTree"
                (debounceFilterInput)="applyFilterTableTree($event)"
                [debounceTime]="600"
                maxlength="1500"
                placeholder="{{ 'organisation_table_filter_placeholder' | translate }}"
              />
            </mat-form-field>
            <button mat-button color="primary" (click)="resetFilterTree()" *ngIf="filterTree != ''">
              {{ 'label_reset_filters' | translate }}
            </button>
          </div>
          <div class="table-search-paginate-right"></div>
        </div>
        <div class="table-filters hidden"></div>

        <div class="table-container">
          <table
            class="table table-stripes table-truncate-text"
            mat-table
            [dataSource]="dataSourceTree"
            matSort
            #sortTable="matSort"
            matSortDisableClear
            [ngStyle]="{ visibility: !isLoading ? '' : 'hidden' }"
          >
            <ng-container matColumnDef="entityName">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'entity_name' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">
                <div class="toggle-checkbox-name">
                  <div class="icon-toggle-cell">
                    <button
                      mat-icon-button
                      [style.visibility]="!row.expandable ? 'hidden' : ''"
                      [style.marginLeft.px]="row.level * 16"
                      (click)="treeControl.toggle(row); $event.stopPropagation()"
                    >
                      <mat-icon class="mat-icon-rtl-mirror expand-icon">
                        {{ treeControl.isExpanded(row) ? 'remove' : 'add' }}
                      </mat-icon>
                    </button>
                  </div>
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? onCheckboxClicked(row.item) : null"
                    [checked]="isRowSelected(row.item)"
                  >
                  </mat-checkbox>
                  <span class="toggle-name" showIfTruncated [matTooltip]="row.item.entityName" [ngClass]="row.isLoading ? 'loading' : ''">{{
                    row.item.entityName
                  }}</span>
                  <mat-progress-bar *ngIf="row.isLoading" mode="indeterminate" class="example-tree-progress-bar"> </mat-progress-bar>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsTableTree; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTableTree"></tr>
          </table>
          <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityDataTree || isLoadingTree">
            <mat-toolbar-row *ngIf="isLoadingTree" class="spinner">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-toolbar-row>
            <mat-toolbar-row *ngIf="noEntityDataTree" class="no-data-message">
              <span>{{ 'generic_filter_error_nodata' | translate }}</span>
            </mat-toolbar-row>
          </mat-toolbar>
          <mat-toolbar class="table-footer-toolbar">
            <mat-toolbar-row>
              <mat-paginator
                [disabled]="isLoadingTree"
                #paginatorTree
                [pageSizeOptions]="pageSizeList"
                [length]="pageTotalElementsTree"
                class="reset-paginator"
                (page)="onPageChangedTree()"
              ></mat-paginator>
            </mat-toolbar-row>
          </mat-toolbar>
        </div>
      </div>
    </div>
  </div>
</ng-template>
