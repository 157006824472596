<div id="generic-form-wizard-form-step" style="height: 100%">
  <ng-container *ngIf="!isLoading && formWizardStepFields">
    <app-generic-form
      [entityKind]="entityKind"
      [saveSubj]="saveSubj"
      [formFields]="formWizardStepFields"
      [fixedContainerSize]="true"
      [isWizardCss]="true"
      (searchChangeValueEvEm)="searchChangeValueEvEm.emit(formWizardStepFields)"
      (changeValueEvEm)="changeValueEvEm.emit(formWizardStepFields)"
    ></app-generic-form>
  </ng-container>
  <div *ngIf="isLoading" class="spinner-container">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
