<div id="form-step">
  <div
    *ngIf="!isLoading && entityWizardStepFields"
    class="step-container"
    appFileDragDrop
    [dragOverBorderColor]="$dragOverBorderColor"
    [dropDisabled]="!isDocumentsTabEnabled"
    (fileDropped)="uploadFiles($event)"
  >
    <app-generic-form
      [entityKind]="entityKind"
      [saveSubj]="saveSubj"
      [formFields]="entityWizardStepFields"
      [fixedContainerSize]="true"
      [isWizardCss]="true"
      [foreignFilterEntityKind]="foreignFilterEntityKind"
      [foreignFilterEntityId]="foreignFilterEntityId"
      (searchChangeValueEvEm)="searchChangeValueEvEm.emit(entityWizardStepFields)"
      (changeValueEvEm)="changeValueEvEm.emit(entityWizardStepFields)"
      (autoUpdateEvEm)="eventDataChangedCallback != null ? eventDataChangedCallback($event) : null"
    ></app-generic-form>
    <div class="documents-column" (click)="fileInput.click(); $event.stopPropagation()" *ngIf="isDocumentsTabEnabled">
      <input hidden type="file" #fileInput (click)="$event.stopPropagation()" (change)="uploadFiles($event.target.files)" multiple />
      <div class="documents-background">{{ 'entity_wizard_documents_dropzone' | translate }}</div>
      <div class="documents-tiles">
        <mat-card *ngFor="let item of fileList" class="item-card">
          <mat-card-header>
            <div mat-card-avatar>
              <i class="menu-item-icon far fa-file-alt"></i>
            </div>
            <mat-card-title>{{ item.filenodeName | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
              {{ item.filenodeSize | filesize }}
            </p>
          </mat-card-content>
          <button class="remove-button" (click)="removeFile(item); $event.stopPropagation()" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-card>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading" class="spinner-container">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
