import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Entities } from 'src/app/configs/entities';
import { Entity } from 'src/app/models/entity';
import { EntityWizard, EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { FormFieldConfigurationKind } from 'src/app/models/forms/form-configuration-kind';
import { FormFieldDataRequest, GenericEntityFormFieldEvent, GenericFormField } from 'src/app/models/forms/form-field';
import { GenericEntityRequiredType } from 'src/app/models/generic-entity-field';
import { RootStoreState } from 'src/app/root-store';
import { AuthStoreSelectors } from 'src/app/root-store/auth-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { DecimalCheckerService } from 'src/app/services/utils/decimal-checker.service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from '../../base-components/format-component';
import { GenericEntityWizardFormStepComponent } from './generic-entity-wizard-form-step/generic-entity-wizard-form-step.component';

@Component({
  selector: 'app-generic-entity-wizard',
  templateUrl: './generic-entity-wizard.component.html',
  styleUrls: ['./generic-entity-wizard.component.scss'],
})
export class GenericEntityWizardComponent extends FormatComponent implements OnInit, OnDestroy {
  @ViewChild('entityWizardStepper') stepper: MatStepper;
  @ViewChild(GenericEntityWizardFormStepComponent) formStep: GenericEntityWizardFormStepComponent;
  @Input() wizardName: string;
  @Input() relatedEntityKinds: string;
  @Input() relatedEntityIds: string;
  @Input() propertyTemplateId: number;
  @Input() isWizardDialog = false;
  @Input() inputValue: string;
  @Input() fieldTextnames: string;
  @Input() fieldValues: string;
  @Input() foreignFilterEntityKind: string;
  @Input() foreignFilterEntityId: number;
  @Input() isDocumentsTabEnabled: boolean;
  @Input() eventDataChangedCallback?: (event: GenericEntityFormFieldEvent) => void;
  @Output() finishWizardEvEm = new EventEmitter<Entity>();
  @Output() entityWizardResponseEvEm = new EventEmitter<EntityWizardResponse>();
  @Output() cancelWizardEvEm = new EventEmitter<void>();

  isTenantAdmin = false;
  entityWizardData: EntityWizard;
  saveSubj: Subject<void> = new Subject<void>();

  wizardId = -1;
  isFinished = false;
  error: string;
  errorMessage: string;

  isCurrentStepValid = false;
  currentStepData: GenericFormField[];

  constructor(
    private cdRef: ChangeDetectorRef,
    private coreService: CoreDataService,
    private dialog: MatDialog,
    private decimalCheckerService: DecimalCheckerService,
    protected messageNotifierService: MessageNotifierService,
    protected store: Store<RootStoreState.State>
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin)), (isTenantAdmin) => {
      this.isTenantAdmin = isTenantAdmin;
    });
    if (this.wizardName) {
      LogService.debug(this, this.ngOnInit.name, 'Create new wizard', this.wizardName);
      this.initEntityWizard();
    } else {
      LogService.error(this, this.ngOnInit.name, 'No wizard name specified', null);
    }
  }

  ngOnDestroy() {
    this.subscribe(this.coreService.cancelEntityWizard(this.entityWizardData.wizardId, this.isTenantAdmin), (response) => {
      if (response.data && response.data.state) {
        LogService.debug(this, this.onCancel.name, `Wizard with wizardId ${this.entityWizardData.wizardId} cancelled`, null);
      } else {
        LogService.debug(this, this.onCancel.name, 'Cancel Wizard error', response.data?.error);
      }
    });
  }

  initEntityWizard() {
    this.subscribe(
      this.coreService.createEntityWizard(
        this.wizardName,
        this.relatedEntityKinds,
        this.relatedEntityIds,
        this.inputValue,
        this.fieldTextnames,
        this.fieldValues,
        this.isTenantAdmin,
        this.propertyTemplateId
      ),
      ({ data }) => {
        LogService.debug(this, this.ngOnInit.name, 'New wizard created', data);
        this.entityWizardData = data;
        this.wizardId = data.wizardId;
        this.cdRef.detectChanges();
        // this.stepper.selectedIndex = 1; // ONLY FOR DEV (start from 0)
      }
    );
  }

  getStepperIcon(index: number): string {
    let icon = '';
    if (this.stepper.selectedIndex > index) {
      icon = 'check';
    } else if (this.stepper.selectedIndex < index) {
      icon = 'edit';
    }
    return icon;
  }

  openDialogWithRef(ref: TemplateRef<any>) {
    this.dialog.open(ref, { autoFocus: false, width: '50%' });
  }

  /** WIZARD STEPPER METHODS */
  apply(formStepData: GenericFormField[], index: number) {
    const request: FormFieldDataRequest[] = this.buildRequest(formStepData);
    this.subscribe(
      this.coreService.putEntityWizardStepData(
        this.wizardId,
        this.entityWizardData.steps[index].formstepStep,
        request,
        this.isTenantAdmin,
        true
      ),
      (response) => {
        if (response.data && response.data.state) {
          this.formStep.loadStepData();
        } else if (response.data && !response.data.state) {
          this.error = response.data.error;
          this.errorMessage = response.data.errorMessage;
        } else {
          this.error = 'Empty Response';
        }
      }
    );
  }

  validateStepData() {
    let hasError = false;
    // Check for required and decimal value
    this.saveSubj.next();
    hasError = this.hasError(this.currentStepData);
    // Regex check
    this.currentStepData.forEach(({ formfieldEntityRegex, formfieldEntityRegexerrormsg, formfieldEntityText }) => {
      if (formfieldEntityText && formfieldEntityRegex) {
        const re = new RegExp(formfieldEntityRegex);
        const matched = formfieldEntityText.toString().match(re);
        if (!matched) {
          this.messageNotifierService.showWarningMessage(formfieldEntityRegexerrormsg);
          hasError = true;
        }
      }
    });

    if (!hasError) {
      const request: FormFieldDataRequest[] = this.buildRequest(this.currentStepData);
      this.subscribe(
        this.coreService.putEntityWizardStepData(
          this.wizardId,
          this.entityWizardData.steps[this.stepper.selectedIndex].formstepStep,
          request,
          this.isTenantAdmin,
          false
        ),
        (response) => {
          if (response.data && response.data.state) {
            if (!this.isFinishStep()) {
              this.goToSelectedNextStep();
            } else {
              const data = response.data.data[0];
              const newEntity = {
                ...new Entity(),
                entityId: data.wizarddataId,
                entityName: data.wizarddataName,
                entityKind: data.wizarddataKind,
                entityUpdate: true,
                entityDelete: true,
              };
              this.subscribe(this.translate.reloadLang(this.translate.currentLang), async () => {
                this.finishWizardEvEm.emit(newEntity);
                this.entityWizardResponseEvEm.emit(response.data);
              });
              this.messageNotifierService.showSuccessMessage('toastr_success');
            }
          } else if (response.data && !response.data.state) {
            this.error = response.data.error;
            this.errorMessage = response.data.errorMessage;
          } else {
            this.error = 'Empty Response';
          }
        }
      );
    } else {
      return null;
    }
  }

  checkValidity(formStepData: GenericFormField[], index: number) {
    // LogService.debug(this, this.checkValidity.name, 'Check changes validity', formStepData);
    this.currentStepData = formStepData;
    // // GET REQUIRED FIELDS
    // const requiredGenericFields = this.currentStepData
    //   .filter(
    //     (fsd) =>
    //       fsd.formfieldKindId === FormFieldConfigurationKind.SEARCH ||
    //       fsd.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
    //       fsd.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
    //       fsd.formfieldKindId === FormFieldConfigurationKind.CHIPS
    //   )
    //   .filter((fsd) => fsd.formfieldRequired === GenericFormFieldRequiredType.required && !fsd.isHidedField() && !fsd.isReadonlyField());
    // const requiredStepControlFields = this.currentStepData
    //   .filter((fsd) => fsd.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL)
    //   .filter((fsd) => !fsd.isHidedField() && !fsd.isReadonlyField());

    // const isRequiredValid = requiredGenericFields.every((fsd) => fsd.isRequiredControlValid);
    // const isStepControlValid = requiredStepControlFields.every((fsd) => fsd.isStepControlValid);
    // this.isCurrentStepValid = isRequiredValid && isStepControlValid;
    this.cdRef.detectChanges();
  }

  private buildRequest(formStepData: GenericFormField[]): FormFieldDataRequest[] {
    return formStepData
      .filter(
        (fsd) =>
          fsd.formfieldKindId === FormFieldConfigurationKind.SEARCH ||
          fsd.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
          fsd.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
          fsd.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL ||
          fsd.formfieldKindId === FormFieldConfigurationKind.CHIPS ||
          fsd.formfieldKindId === FormFieldConfigurationKind.MAILTEMPLATE
      )
      .map((fsd) => {
        return new FormFieldDataRequest(fsd);
      });
  }

  private hasError(formStepData: GenericFormField[]): boolean {
    const m = this.hasError.name;

    let hasError = false;
    let hasRequiredError = false;
    formStepData.forEach((field) => {
      if (
        field.formfieldEntityText &&
        field.formfieldEntityType === 'decimal' &&
        this.decimalCheckerService.hasErrorFormField(field.formfieldEntityText, field)
      ) {
        hasError = true;
      }
      if (
        field.formfieldRequired === GenericEntityRequiredType.required &&
        !field.isHidedField() &&
        !field.isReadonlyField() &&
        (field.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
          field.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
          field.formfieldKindId === FormFieldConfigurationKind.CHIPS) &&
        !field.isRequiredControlValid
      ) {
        LogService.warning(this, m, `Missing required value: ${this.translate.instant(field.formfieldTextname)}`, null);
        hasError = true;
        hasRequiredError = true;
      }
      if (
        field.formfieldRequired === GenericEntityRequiredType.required &&
        !field.isHidedField() &&
        !field.isReadonlyField() &&
        field.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL &&
        !field.isStepControlValid
      ) {
        LogService.warning(this, m, `Missing required value: ${this.translate.instant(field.formfieldTextname)}`, null);
        hasError = true;
        hasRequiredError = true;
      }
    });
    if (hasRequiredError) {
      this.messageNotifierService.showWarningMessage(`${this.translate.instant('toastr_missing_required_value')}`);
    }

    return hasError;
  }

  /* Stepper Controller */
  private goToSelectedPreviousStep() {
    const m = this.goToSelectedPreviousStep.name;
    // if (this.isFinishStep()) {
    //   this.stepper.selectedIndex = this.entityWizardData.steps.length - 1; //selectedIndex start from 0
    // } else {
    //   this.stepper.previous();
    // }
    this.stepper.previous();
    this.cdRef.detectChanges();
  }
  private goToSelectedNextStep() {
    const m = this.goToSelectedNextStep.name;
    this.stepper.next();
    this.cdRef.detectChanges();
  }

  onBack() {
    this.error = null;
    this.isFinished = false;
    this.goToSelectedPreviousStep();
  }

  onNext() {
    this.validateStepData();
    // setTimeout(() => {
    //   this.validateStepData();
    // }, 100);
  }

  onCancel() {
    this.cancelWizardEvEm.emit();
  }

  isNextDisabled() {
    // return !this.isCurrentStepValid;
    return false;
  }

  isFirstStep() {
    return this.stepper && this.stepper.selectedIndex === 0;
  }

  isFinishStep() {
    return (
      this.stepper &&
      this.entityWizardData &&
      this.entityWizardData.steps &&
      this.stepper.selectedIndex + 1 === this.entityWizardData.steps.length
    ); // selectedIndex start from 0
  }

  get Entities() {
    return Entities;
  }
}
