import { FlatTreeControl } from '@angular/cdk/tree';
import { KeyValue } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { select } from '@ngrx/store';
import { Entities } from 'src/app/configs/entities';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import {
  ForeignEntity,
  ForeignEntityDynamicDataSource,
  ForeignEntityDynamicDatabase,
  ForeignEntityDynamicFlatNode,
  ForeignEntityRequest,
} from '../../../models/foreign-entity';
import { PreferencesSelectors } from '../../../root-store/preferences-store';
import { CoreDataService } from '../../../services/core-data.service';

export interface DialogData {
  labelName: string;
  kind?: string;
  linkableFlag?: boolean;
  disableSorting?: boolean;
  filterIds?: number[];
  filterName?: string;
  filterEntityKind?: string;
  filterEntityId?: number;
  filterColumn1?: string;
  filterColumn1Values?: number[];
  filterColumn2?: string;
  filterColumn2Values?: number[];
}

@Component({
  selector: 'app-entity-modal',
  templateUrl: './entity-modal.component.html',
  styleUrls: ['./entity-modal.component.scss'],
  providers: [ForeignEntityDynamicDatabase],
})
export class EntityModalComponent extends FormatComponent implements AfterViewInit {
  constructor(
    private database: ForeignEntityDynamicDatabase,
    private coreDataService: CoreDataService,
    public dialogRef: MatDialogRef<EntityModalComponent>,
    private crd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super();
  }

  //common
  @ViewChildren('paginatorTable') Paginator: QueryList<MatPaginator>;
  @ViewChildren('sortTable') SortTable: QueryList<MatSort>;
  paginatorTable: MatPaginator;
  sortTable: MatSort;
  isLoading = true;
  noEntityData = false;
  pageSizeList: number[] = [];
  pageTotalElements = 0;
  request: ForeignEntityRequest;
  filterValue: string;
  lastUsedFilters: string[];
  selectedEntity: ForeignEntity;
  isGridView = true;
  isGridTreeSelectionActive = true;
  //grid
  dataSourceTable: MatTableDataSource<ForeignEntity> = new MatTableDataSource<ForeignEntity>();
  displayedColumnsTable: string[] = ['radio', 'entityName', 'entityDescription'];
  //tree
  showTreeToggle = true;
  dataSource: ForeignEntityDynamicDataSource;
  treeControl: FlatTreeControl<ForeignEntityDynamicFlatNode>;
  displayedColumnsTreeTable: string[] = ['entityName', 'entityDescription'];

  searchFilterrules: KeyValue<string, string>[];
  selectedFilterules: KeyValue<string, string>;
  selectedFilteruleId: number;

  ngAfterViewInit() {
    this.selectedFilteruleId = 0;
    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesFilterRulesOptions)), (filterRules) => {
      this.searchFilterrules = filterRules;
      this.selectedFilterules = this.searchFilterrules && this.searchFilterrules.length > 0 ? this.searchFilterrules[0] : null;
    });
    const option = this.data.filterEntityKind === Entities.TYPES_KIND ? this.data.filterEntityId : null;
    this.subscribe(this.coreDataService.getEntityTreeenabledByEntityKind(this.data.kind, this.isTenantAdmin, option), ({ data }) => {
      this.isGridTreeSelectionActive = data;
    });
    this.subscribe(this.store.select(PreferencesSelectors.selectPreferencesPaginatonsOptions), (pageSizeOpt) => {
      // Wait for selected product
      if (pageSizeOpt && pageSizeOpt.length !== 0) {
        this.pageSizeList = pageSizeOpt;
        this.request = {
          pageIndex: 0,
          pageSize: pageSizeOpt[0],
          orderBy: this.data && this.data.disableSorting ? null : 'entityName',
          sort: this.data && this.data.disableSorting ? null : 'asc',
          kind: this.data.kind,
          linkableFlag: this.data.linkableFlag,
          filterIds: this.data.filterIds,
          filterName: this.data.filterName,
          filterEntityKind: this.data.filterEntityKind,
          filterEntityId: this.data.filterEntityId,
          filterColumn1: this.data.filterColumn1,
          filterColumn1Values: this.data.filterColumn1Values,
          filterColumn2: this.data.filterColumn2,
          filterColumn2Values: this.data.filterColumn2Values,
          isTenantAdmin: this.isTenantAdmin,
          filterRule: this.selectedFilteruleId != null ? this.selectedFilteruleId : this.castToInt(this.selectedFilterules?.key),
        };
        this.crd.detectChanges();
        // Table Initialization
        this.loadServicesData();
      }
    });
    this.crd.detectChanges();
    this.paginatorTable = this.Paginator.first;
    this.paginatorTable.pageSize = this.pageSizeList[0];
    if (this.paginatorTable) {
      this.subscribe(this.paginatorTable.page, () => {
        this.setPage();
        this.loadServicesData();
      });
    }
    this.crd.detectChanges();
    this.sortTable = this.SortTable.first;
    if (this.sortTable) {
      this.subscribe(this.sortTable.sortChange, () => {
        this.setOrder();
        this.loadServicesData();
      });
    }
    this.subscribe(this.Paginator.changes, (p: QueryList<MatPaginator>) => {
      this.paginatorTable = p.first;
      this.paginatorTable.pageSize = this.pageSizeList[0];
      if (this.paginatorTable) {
        this.subscribe(this.paginatorTable.page, () => {
          this.setPage();
          this.loadServicesData();
        });
      }
    });
    this.subscribe(this.SortTable.changes, (p: QueryList<MatSort>) => {
      this.sortTable = p.first;
      if (this.sortTable) {
        this.subscribe(this.sortTable.sortChange, () => {
          this.setOrder();
          this.loadServicesData();
        });
      }
    });
  }

  loadServicesData() {
    this.isLoading = true;
    if (this.isGridView) {
      this.dataSourceTable = new MatTableDataSource();
      const apiPath = this.isTenantAdmin ? `api/tenantadministration/entities/${this.data.kind}` : `api/entities/${this.data.kind}`;
      this.subscribe(
        this.coreDataService.getForeignEntity(apiPath, this.request),
        (response) => {
          this.lastUsedFilters = response.filters;
          this.dataSourceTable.data = response.data ? response.data : [];
          this.noEntityData = !response.data || response.data.length === 0;
          this.pageTotalElements = !response.data || response.data.length === 0 ? 0 : response.data[0].entityCount;
          this.isLoading = false;
          this.crd.detectChanges();
        },
        (error) => {
          this.isLoading = false;
          this.noEntityData = true;
        }
      );
    } else {
      this.treeControl = new FlatTreeControl<ForeignEntityDynamicFlatNode>(this.getLevel, this.isExpandable);
      this.dataSource = new ForeignEntityDynamicDataSource(this.treeControl, this.database, this.coreDataService, this.request);
      const apiPath = this.isTenantAdmin
        ? `api/tenantadministration/entities/${this.data.kind}/-1/nodes`
        : `api/entities/${this.data.kind}/-1/nodes`;
      this.subscribe(
        this.coreDataService.getForeignEntity(apiPath, this.request),
        (response) => {
          this.lastUsedFilters = response.filters;
          this.dataSource.data = response.data ? this.database.initialData(response.data) : [];
          this.noEntityData = !response.data || response.data.length === 0;
          this.pageTotalElements = !response.data || response.data.length === 0 ? 0 : response.data[0].entityCount;
          this.showTreeToggle = response.data ? response.data.some((e) => e.entityChilds > 0) : false;
          this.isLoading = false;
          this.crd.detectChanges();
        },
        (error) => {
          this.isLoading = false;
          this.noEntityData = true;
        }
      );
    }
  }

  onGridTreeSelectionChanged($event: MatRadioChange) {
    this.isGridView = $event.value;
    this.crd.detectChanges();
    // Table Initialization
    this.loadServicesData();
  }

  isFiltersSetted(): boolean {
    return this.filterValue != '' || this.filterValue != null;
  }

  private setFilter(filter: string) {
    this.request = {
      ...this.request,
      filter,
    };
  }

  resetFilter() {
    if (this.filterValue) {
      this.filterValue = '';
      this.applyFilterTable(this.filterValue);
    }
  }

  applyFilterTable(filterValue: string) {
    const m = this.applyFilterTable.name;

    LogService.info(this, m, 'FILTER VALUE', null);

    if (filterValue && filterValue.length >= 3) {
      this.setFilter(filterValue);
      this.loadServicesData();
    } else {
      if (!filterValue || (filterValue && filterValue.length === 0)) {
        this.setFilter(null);
        this.loadServicesData();
      }
    }
  }

  private setOrder() {
    this.request = {
      ...this.request,
      orderBy: this.sortTable.active,
      sort: this.sortTable.direction,
    };
  }

  private setPage() {
    this.request = {
      ...this.request,
      pageSize: this.paginatorTable.pageSize,
      pageIndex: this.paginatorTable.pageIndex,
    };
  }

  protected setFilterRule(values?: number[]) {
    if (values) {
      const value = values.find((id) => id !== this.selectedFilteruleId);
      this.selectedFilteruleId = value != null ? value : 0;
    } else {
      this.selectedFilteruleId = 0;
    }
    if (this.paginatorTable) {
      this.paginatorTable.pageIndex = 0;
    }
    this.request = {
      ...this.request,
      pageIndex: 0,
      filterRule: this.selectedFilteruleId,
    };
    if (this.request.filter && this.request.filter.length >= 3) {
      this.loadServicesData();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onEntitySelected(row: ForeignEntity) {
    this.selectedEntity = row;
  }

  isEntitySelected() {
    return this.selectedEntity && this.selectedEntity.entityName !== '';
  }

  /* TREE METHODS */
  getLevel = (node: ForeignEntityDynamicFlatNode) => node.level;

  isExpandable = (node: ForeignEntityDynamicFlatNode) => node.expandable;

  hasChild = (x: number, nodeData: ForeignEntityDynamicFlatNode) => nodeData.expandable;
}
