import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { PreferenceDataService } from 'src/app/services/preference-data.service';
import * as PreferencesActions from './actions';

@Injectable()
export class FoPropertyEffects {
  // loadPageSizes$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(PreferencesActions.loadPageSizes),
  //     concatMap(({ isAuth, isTenantAdmin }) =>
  //       this.preferencesService.getPageSizes(isAuth, isTenantAdmin).pipe(
  //         map(({ data }) =>
  //           PreferencesActions.loadPageSizesSuccess({
  //             paginatorOptions: data,
  //           })
  //         ),
  //         catchError((error) => of(PreferencesActions.loadPageSizesFailure({ error })))
  //       )
  //     )
  //   )
  // );

  // loadLanguageRegion$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(PreferencesActions.loadLanguageRegion),
  //     concatMap(({ isTenantAdmin }) =>
  //       isTenantAdmin
  //         ? of(
  //             PreferencesActions.loadLanguageRegionSuccess({
  //               languageRegion: { language: 'en', region: 'en' },
  //             })
  //           )
  //         : this.preferencesService.getLanguageRegion().pipe(
  //             map(({ data }) =>
  //               PreferencesActions.loadLanguageRegionSuccess({
  //                 languageRegion: data,
  //               })
  //             ),
  //             catchError((error) => of(PreferencesActions.loadLanguageRegionFailure({ error })))
  //           )
  //     )
  //   )
  // );

  loadPublicPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActions.loadPreferenceSettings),
      concatMap(({ isAuth, isTenantAdmin }) =>
        this.preferencesService.getPreferenceSettings(isAuth, isTenantAdmin).pipe(
          map(({ data }) =>
            PreferencesActions.loadPreferenceSettingsSuccess({
              preferenceSettings: data,
            })
          ),
          catchError((error) => of(PreferencesActions.loadPreferenceSettingsFailure({ error })))
        )
      )
    )
  );

  loadFlyingbuttonPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActions.loadFlyingButtonSettings),
      concatMap(({ isTenantAdmin }) =>
        this.preferencesService.getFlyingButtonSettings(isTenantAdmin).pipe(
          map(({ data }) =>
            PreferencesActions.loadFlyingButtonSettingsSuccess({
              flyingButtonSettings: data,
            })
          ),
          catchError((error) => of(PreferencesActions.loadFlyingButtonSettingsFailure({ error })))
        )
      )
    )
  );

  saveFlyingbuttonPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActions.storeFlyingButtonSettings),
      concatMap(({ isTenantAdmin, flyingButtonSettings }) =>
        this.preferencesService.setFlyingButtonSettings(isTenantAdmin, flyingButtonSettings).pipe(
          map(({ data }) =>
            PreferencesActions.loadFlyingButtonSettingsSuccess({
              flyingButtonSettings,
            })
          ),
          catchError((error) => of(PreferencesActions.loadFlyingButtonSettingsFailure({ error })))
        )
      )
    )
  );

  loadEnabledPaths$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActions.loadEnabledPaths),
      concatMap(() =>
        this.preferencesService.getEnabledPaths().pipe(
          map(({ data }) =>
            PreferencesActions.loadEnabledPathsSuccess({
              enabledPaths: data,
            })
          ),
          catchError((error) => of(PreferencesActions.loadEnabledPathsFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private preferencesService: PreferenceDataService) {}
}
