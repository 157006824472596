import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AddConsumersToAssetWizardEffects } from './effects';
import * as fromAddConsumersToAssetWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAddConsumersToAssetWizard.addConsumersToAssetWizardFeatureKey, fromAddConsumersToAssetWizard.reducer),
    EffectsModule.forFeature([AddConsumersToAssetWizardEffects]),
  ],
})
export class AddConsumersToAssetWizardStoreModule {}
