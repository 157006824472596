import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AllocateServiceWizardRequest } from 'src/app/models/allocate-service-request';
import { AssetTreeNode } from 'src/app/models/asset';
import { AssetSubscription } from 'src/app/models/asset-subscription';
import { CatalogItemType } from 'src/app/models/catalog-item-particulars';
import { GenericFormField } from 'src/app/models/forms/form-field';
import { ProductFamilyWaitingRoomTreeNode } from 'src/app/models/productfamily-waitingroom';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../../configs/api-paths';
import { ActionResponse } from '../../models/action-response';
import { BaseResponse } from '../../models/base-response';
import { BillingItem } from '../../models/billingitem';
import { Entity } from '../../models/entity';
import { FeedItem, FeedItemParams, LicenseServicesRequest } from '../../models/feeditem';
import { License } from '../../models/license';
import { PaginatedRequest } from '../../models/paginated-request';
import { ServiceBillingAccounts, ServiceCostRequest, ServiceCostResponse } from '../../models/service-calc-units';
import { BillingAccount } from '../../models/vendor-account';
import { AddAssetOrderRequest } from '../../models/wizards/add-asset-order-request';
import { CopyAssetRequest } from '../../shared-components/allocation-wizard-modal-dialogs/copy-asset-wizard/confirmation-step/copy-asset-request';
import { LogService } from '../log-service';

@Injectable({
  providedIn: 'root',
})
export class OrderWizardDataService {
  getProductFamiliesWaitingRoomNodes(
    productfamilyId: number,
    productserviceId: number,
    activationDate: Date,
    deactivationDate: Date,
    filter: string
  ): Observable<BaseResponse<ProductFamilyWaitingRoomTreeNode[]>> {
    const m = this.getServicesBillingAccounts.name;

    const path = environment.getEndpoint(ApiPath.ProductFamily.PRODUCTFAMILIES_WAITINGROOM_NODES);
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams().set('filterStarts', moment(activationDate).utc(true).format('YYYY-MM-DD'));
    if (moment(deactivationDate).isValid()) {
      params = params.append('filterEnds', moment(deactivationDate).utc(true).format('YYYY-MM-DD'));
    }
    if (productfamilyId != null) {
      params = params.append('productfamilyId', productfamilyId);
    }
    if (productserviceId != null) {
      params = params.append('productserviceId', productserviceId);
    }
    if (filter != null && filter != '') {
      params = params.append('filter', filter);
    }
    const retVal = this.http.get<BaseResponse<ProductFamilyWaitingRoomTreeNode[]>>(path, { params });

    return retVal;
  }

  getExistingAssetsByServiceIdAndDeskId(serviceId: number, deskId: number, current: boolean): Observable<BaseResponse<AssetTreeNode[]>> {
    const m = this.getExistingAssetsByServiceIdAndDeskId.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_ASSETS(serviceId));
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams().set('current', current ? true : false).set('filterDeskIds', deskId);
    const retVal = this.http.get<BaseResponse<AssetTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getExistingWaitingRoomsByServiceIdAndDeskId(
    serviceId: number,
    deskId: number,
    current: boolean
  ): Observable<BaseResponse<AssetTreeNode[]>> {
    const m = this.getExistingAssetsByServiceIdAndDeskId.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_ASSETS_WAITINGROOMS(serviceId));
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams().set('current', current ? true : false).set('filterDeskIds', deskId);
    const retVal = this.http.get<BaseResponse<AssetTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getExistingSharedsByServiceIdAndDeskId(serviceId: number, deskId: number, current: boolean): Observable<BaseResponse<AssetTreeNode[]>> {
    const m = this.getExistingSharedsByServiceIdAndDeskId.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_ASSETS_SHAREDS(serviceId));
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams().set('current', current ? true : false).set('filterDeskIds', deskId);
    const retVal = this.http.get<BaseResponse<AssetTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getServicesBillingAccounts(
    isOrderWizard: boolean,
    excludeExisting: boolean,
    productId: number,
    licenseId: number,
    serviceIds: string,
    billingaccountId: number,
    activationDate: Date,
    deactivationDate: Date
  ): Observable<BaseResponse<ServiceBillingAccounts[]>> {
    const m = this.getServicesBillingAccounts.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS_BILLINGACCOUNTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'serviceIds', serviceIds);
    let params = new HttpParams().set('add', isOrderWizard ? true : false).set('serviceIds', serviceIds);
    params = params.append('excludeExisting', excludeExisting ? true : false);
    if (productId != null) {
      params = params.append('productId', productId.toString());
    }
    if (licenseId != null) {
      params = params.append('licenseId', licenseId.toString());
    }
    if (billingaccountId != null) {
      params = params.append('billingaccountId', billingaccountId.toString());
    }
    if (moment(activationDate).isValid()) {
      params = params.append('activationDate', moment(activationDate).utc(true).format('YYYY-MM-DD'));
    }
    if (moment(deactivationDate).isValid()) {
      params = params.append('deactivationDate', moment(deactivationDate).utc(true).format('YYYY-MM-DD'));
    }
    const retVal = this.http.get<BaseResponse<ServiceBillingAccounts[]>>(path, {
      params,
    });

    return retVal;
  }

  getOrderServicesBillingAccounts(
    isOrderWizard: boolean,
    excludeExisting: boolean,
    productserviceId: number,
    productfamilyId: number,
    licenseId: number,
    locationId: number,
    serviceIds: string,
    billingaccountId: number,
    activationDate: Date,
    deactivationDate: Date
  ): Observable<BaseResponse<ServiceBillingAccounts[]>> {
    const m = this.getServicesBillingAccounts.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS_BILLINGACCOUNTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'serviceIds', serviceIds);
    let params = new HttpParams().set('add', isOrderWizard ? true : false).set('serviceIds', serviceIds);
    params = params.append('excludeExisting', excludeExisting ? true : false);
    if (productserviceId != null) {
      params = params.append('productserviceId', productserviceId.toString());
    }
    if (productfamilyId != null) {
      params = params.append('productfamilyId', productfamilyId.toString());
    }
    if (licenseId != null) {
      params = params.append('licenseId', licenseId.toString());
    }
    if (billingaccountId != null) {
      params = params.append('billingaccountId', billingaccountId.toString());
    }
    if (locationId != null) {
      params = params.append('locationId', locationId.toString());
    }
    if (moment(activationDate).isValid()) {
      params = params.append('activationDate', moment(activationDate).utc(true).format('YYYY-MM-DD'));
    }
    if (moment(deactivationDate).isValid()) {
      params = params.append('deactivationDate', moment(deactivationDate).utc(true).format('YYYY-MM-DD'));
    }
    const retVal = this.http.get<BaseResponse<ServiceBillingAccounts[]>>(path, {
      params,
    });

    return retVal;
  }

  getServiceBillingAccounts(productserviceId?: number, licenseId?: number): Observable<BaseResponse<BillingAccount[]>> {
    const m = this.getServiceBillingAccounts.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_BILLINGACCOUNTS);
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams();
    if (productserviceId != null) {
      params = params.append('productserviceId', productserviceId.toString());
    }
    if (licenseId) {
      params = params.append('licenseId', licenseId.toString());
    }
    // else {
    //   throw Error('Incorrect call to service method ' + m);
    // }
    const retVal = this.http.get<BaseResponse<BillingAccount[]>>(path, {
      params,
    });

    return retVal;
  }

  getAddonsBillingAccounts(
    isOrderWizard: boolean,
    excludeExisting: boolean,
    productserviceId: number,
    licenseId: number,
    locationId: number,
    serviceIds: string,
    mainBillingaccountId: number,
    activationDate: Date,
    deactivationDate: Date,
    isGenericService: boolean
  ): Observable<BaseResponse<ServiceBillingAccounts[]>> {
    const m = this.getServicesBillingAccounts.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS_BILLINGACCOUNTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'serviceIds', serviceIds);
    let params = new HttpParams()
      .set('add', isOrderWizard ? true : false)
      .set('serviceIds', serviceIds)
      .set('excludeInpackageOnly', true);
    params = params.append('excludeExisting', excludeExisting ? true : false);
    if (productserviceId != null) {
      params = params.append('productserviceId', productserviceId.toString());
    }
    if (licenseId != null) {
      params = params.append('licenseId', licenseId.toString());
    }
    if (locationId != null) {
      params = params.append('locationId', locationId.toString());
    }
    if (mainBillingaccountId != null) {
      params = params.append('billingaccountId', mainBillingaccountId.toString());
    }
    if (moment(activationDate).isValid()) {
      params = params.append('activationDate', moment(activationDate).utc(true).format('YYYY-MM-DD'));
    }
    if (moment(deactivationDate).isValid()) {
      params = params.append('deactivationDate', moment(deactivationDate).utc(true).format('YYYY-MM-DD'));
    }
    if (isGenericService) {
      params = params.append('productfamilyId', 0);
    }
    const retVal = this.http.get<BaseResponse<ServiceBillingAccounts[]>>(path, {
      params,
    });

    return retVal;
  }

  getProductBillingaccounts(productserviceId?: number, licenseId?: number): Observable<BaseResponse<BillingAccount[]>> {
    const m = this.getProductBillingaccounts.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS);
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams();
    if (productserviceId != null) {
      params = params.append('productserviceIds', productserviceId.toString());
      LogService.info(this, m, 'productserviceIds', productserviceId);
    }
    if (licenseId != null) {
      params = params.append('licenseIds', licenseId.toString());
      LogService.info(this, m, 'licenseIds', licenseId);
    }
    // else {
    //   throw Error('Incorrect call to service method ' + m);
    // }
    const retVal = this.http.get<BaseResponse<BillingAccount[]>>(path, {
      params,
    });

    return retVal;
  }

  getProductserviceConfigurationAccounts(productserviceId?: number, productfamilyId?: number): Observable<BaseResponse<BillingAccount[]>> {
    const m = this.getProductserviceConfigurationAccounts.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_BILLINGACCOUNTS);
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams();
    if (productserviceId != null) {
      params = params.append('productserviceId', productserviceId.toString());
    }
    if (productfamilyId != null) {
      params = params.append('productfamilyId', productfamilyId.toString());
    }
    // if (licenseId) {
    //   params = params.append('licenseId', licenseId.toString());
    // }
    // else {
    //   throw Error('Incorrect call to service method ' + m);
    // }
    const retVal = this.http.get<BaseResponse<BillingAccount[]>>(path, {
      params,
    });

    return retVal;
  }

  getLicenses(request: PaginatedRequest): Observable<BaseResponse<License[]>> {
    const m = this.getLicenses.name;

    const path = environment.getEndpoint(ApiPath.Licenses.LICENSES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<License[]>>(path, {
      params,
    });

    return retVal;
  }

  getProducts(request: PaginatedRequest): Observable<BaseResponse<Entity[]>> {
    const m = this.getProducts.name;

    const path = environment.getEndpoint(ApiPath.Licenses.PRODUCTS_TYPES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Entity[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getFeeditems(request: LicenseServicesRequest): Observable<BaseResponse<FeedItem[]>> {
    const m = this.getFeeditems.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = LicenseServicesRequest.appendParamToBaseRequestParams(request, params);
    const retVal = this.http.get<BaseResponse<FeedItem[]>>(path, {
      params,
    });

    return retVal;
  }

  getProductFeeditems(request: PaginatedRequest, productfamilyId: number): Observable<BaseResponse<Entity[]>> {
    const m = this.getProducts.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS);
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = params
      .append(FeedItemParams.FILTER_ENABLED_FOR_ORDER_IDS, 1)
      .append(FeedItemParams.FILTER_CATALOGITEM_TYPE_IDS, CatalogItemType.terminal)
      .append(FeedItemParams.FILTER_PRODUCTFAMILY_IDS, productfamilyId);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Entity[]>>(path, {
      params,
    });

    return retVal;
  }

  getLicenseAllocations(assetId: number, filterStarts: string): Observable<BaseResponse<AssetSubscription[]>> {
    const m = this.getLicenseAllocations.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SUBSCRIPTIONS_BYID(assetId));
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams().set('filterStarts', filterStarts);
    const retVal = this.http.get<BaseResponse<AssetSubscription[]>>(path, {
      params,
    });

    return retVal;
  }

  getBaseCatalogItemsByProductserviceId(productserviceId: number, filterStarts: string): Observable<BaseResponse<FeedItem[]>> {
    const m = this.getBaseCatalogItemsByProductserviceId.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_BASE_CATALOG_ITEMS(productserviceId));
    let params = new HttpParams().set('filterStarts', filterStarts);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<FeedItem[]>>(path, {
      params,
    });

    return retVal;
  }

  getBaseServicesByProductId(productId: number): Observable<BaseResponse<FeedItem[]>> {
    const m = this.getBaseServicesByProductId.name;

    const path = environment.getEndpoint(ApiPath.Products.PRODUCTS_BASE_SERVICES(productId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<FeedItem[]>>(path);

    return retVal;
  }

  getBaseServicesByLicenseId(assetId: number): Observable<BaseResponse<FeedItem[]>> {
    const m = this.getBaseServicesByLicenseId.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_BASE_SERVICES(assetId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<FeedItem[]>>(path);

    return retVal;
  }

  getAssetServicesByAssetId(assetId, assetReactivation: boolean): Observable<BaseResponse<BillingItem[]>> {
    const m = this.getAssetServicesByAssetId.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SERVICES(assetId));
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams();
    if (assetReactivation) {
      params = params.set('filterReactivation', 'true');
    }
    const retVal = this.http.get<BaseResponse<BillingItem[]>>(path, { params });

    return retVal;
  }

  addProductServices(request: AddAssetOrderRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.addProductServices.name;

    const path = environment.getEndpoint(ApiPath.Licenses.ADD_LICENSE_WIZARD);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  allocateServiceAddons(request: AllocateServiceWizardRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.addProductServices.name;

    const path = environment.getEndpoint(ApiPath.Licenses.ADD_LICENSE_WIZARD);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  copyProductServices(request: CopyAssetRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.copyProductServices.name;

    const path = environment.getEndpoint(ApiPath.Licenses.ADD_LICENSE_WIZARD); // Add product and copy asset share same API
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  reactivateProductServices(request: AddAssetOrderRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.reactivateProductServices.name;

    const path = environment.getEndpoint(ApiPath.Licenses.ADD_LICENSE_WIZARD); // Add product and reactivate asset share same API
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getServiceDetailsFormFields(
    serviceId: number,
    relatedEntityKinds?: string[],
    relatedEntityIds?: number[]
  ): Observable<BaseResponse<GenericFormField[]>> {
    const m = this.getServiceDetailsFormFields.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_PRODUCTS_CONFIGURATION(serviceId));
    let params = new HttpParams();
    if (relatedEntityKinds != null && relatedEntityKinds.length > 0 && relatedEntityIds != null && relatedEntityIds.length > 0) {
      params = params.append('relatedEntityKinds', relatedEntityKinds.join(','));
      params = params.append('relatedEntityIds', relatedEntityIds.join(','));
    }
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<GenericFormField[]>>(path, { params });

    return retVal;
  }

  getGenericServiceDetailsFormFields(
    relatedEntityKinds?: string[],
    relatedEntityIds?: number[]
  ): Observable<BaseResponse<GenericFormField[]>> {
    const m = this.getServiceDetailsFormFields.name;

    const path = environment.getEndpoint(ApiPath.ProductFamily.PRODUCTFAMILIES_PRODUCTS_CONFIGURATION(0));
    let params = new HttpParams();
    if (relatedEntityKinds != null && relatedEntityKinds.length > 0 && relatedEntityIds != null && relatedEntityIds.length > 0) {
      params = params.append('relatedEntityKinds', relatedEntityKinds.join(','));
      params = params.append('relatedEntityIds', relatedEntityIds.join(','));
    }
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<GenericFormField[]>>(path, { params });

    return retVal;
  }

  getTrialPeriodOptions(): Observable<BaseResponse<Entity[]>> {
    const m = this.getTrialPeriodOptions.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_TRIAL_PERIOD);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<Entity[]>>(path);

    return retVal;
  }

  calculateServiceCosts(request: ServiceCostRequest): Observable<BaseResponse<ServiceCostResponse[]>> {
    const m = this.calculateServiceCosts.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_COSTS);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ServiceCostResponse[]>>(path, request);

    return retVal;
  }

  addOrderServiceAddons(request: AllocateServiceWizardRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.addOrderServiceAddons.name;

    const path = environment.getEndpoint(ApiPath.Licenses.ADD_LICENSE_WIZARD);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
