import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Entity } from 'src/app/models/entity';

export const featureAdapter: EntityAdapter<Entity> = createEntityAdapter<Entity>({
  selectId: (model) => model.entityId,
  sortComparer: false,
});

export interface State extends EntityState<Entity> {
  isLoading?: boolean;
  newFilter: string;
  oldFilter: string;
  filterEntityKind?: string[];
  searchId: number;
  isFinished: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  newFilter: null,
  oldFilter: null,
  filterEntityKind: null,
  searchId: -1,
  isFinished: true,
  error: null,
});
