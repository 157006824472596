import { ChangeDetectorRef, Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[focusable]',
})
export class FocusableDirective implements OnInit {
  constructor(private host: ElementRef, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.host.nativeElement.focus();
    this.host.nativeElement.select();
    this.cdRef.detectChanges();
  }
}
