import { Action, createReducer, on } from '@ngrx/store';
import * as DeactivateBillingitemAssetWizardActions from './actions';
import { State, initialState } from './state';

export const deactivateBillingitemAssetWizardFeatureKey = 'deactivateBillingitemAssetWizard';

const addProductWizardReducer = createReducer(
  initialState,

  on(DeactivateBillingitemAssetWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(DeactivateBillingitemAssetWizardActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(DeactivateBillingitemAssetWizardActions.selectCancelSubscriptions, (state, { subscriptionStep, packageContentStep }) => ({
    ...state,
    subscriptionStep,
    packageContentStep,
  })),
  on(DeactivateBillingitemAssetWizardActions.exitDeactivateBillingitemAssetWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    subscriptionStep: null,
    packageContentStep: null,
    errorMessage: null,
  })),
  on(DeactivateBillingitemAssetWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    subscriptionStep: null,
    packageContentStep: null,
    errorMessage: null,
  })),
  on(DeactivateBillingitemAssetWizardActions.sendDeactivateBillingitemAssetRequest, (state, { request }) => ({
    ...state,
    request,
  })),
  on(DeactivateBillingitemAssetWizardActions.sendDeactivateBillingitemAssetRequestSuccess, (state, { response }) => ({
    ...state,
    deactivationResponse: response,
  })),
  on(DeactivateBillingitemAssetWizardActions.sendDeactivateBillingitemAssetRequestFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return addProductWizardReducer(state, action);
}
