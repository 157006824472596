import { AllocateConsumerDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-wizard/dates-step/allocate-consumer-date-info';
import { AllocateConsumerInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-wizard/init-step/allocate-consumers-init-step';
import { AllocateConsumerMiscellaneousRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-wizard/miscellaneous-step/allocate-consumer-miscellaneous-request';
import { ConsumerDesk } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-wizard/users-step/consumer-desk';

export interface State {
  initStep: AllocateConsumerInitStep;
  datesStep: AllocateConsumerDueDates;
  usersStep: ConsumerDesk[];
  miscellaneousStep: AllocateConsumerMiscellaneousRequest;
}

export const initialState: State = {
  initStep: null,
  datesStep: null,
  usersStep: null,
  miscellaneousStep: null,
};
