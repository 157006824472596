<div id="global-search-bar">
  <div class="search-input">
    <mat-form-field appearance="outline" class="reset-outline-hint filter-field search">
      <input
        #searchInputField
        #searchInputAutocompleteTrigger="matAutocompleteTrigger"
        (input)="searchInputAutocompleteTrigger.closePanel()"
        matInput
        [(ngModel)]="filter"
        (debounceFilterInput)="applyFilterTable($event)"
        [debounceTime]="0"
        placeholder="{{ 'global_search_filter_placeholder' | translate }}"
        (keydown.enter)="doSearch()"
        [matAutocomplete]="autoLastStringOption"
        (click)="$event.target.select()"
        [disabled]="isDisabled"
      />
      <button mat-icon-button (click)="$event.stopPropagation(); doSearch()" matSuffix *ngIf="enableSearch && !isDisabled">
        <mat-icon>search</mat-icon>
      </button>
      <mat-autocomplete
        (opened)="matAutocompletPanelOpened()"
        (closed)="matAutocompletPanelClosed()"
        #autoLastStringOption="matAutocomplete"
        (optionSelected)="applyFilterTable($event.option.value); doSearch()"
      >
        <mat-option class="form-field-option" *ngFor="let opt of lastSearchOptions" [value]="opt" [class.mat-selected]="filter === opt">
          {{ opt }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <!-- <div class="entity-type-input">
    <mat-form-field>
      <mat-select disableOptionCentering panelClass="finoffice-matselect-panel"
        [(value)]="selectedEntityKind"
        placeholder="{{ 'global_search_entity_type_placeholder' | translate }}"
        (selectionChange)="doSearch()"
        [panelClass]="'entity-type-select-panel'"
      >
        <mat-option class="form-field-option" *ngFor="let opt of entityKindOptions" [value]="opt.entityKind">
          {{ opt.entityKind | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div> -->
</div>
