import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Entities } from 'src/app/configs/entities';
import { FeedItem, FeedItemParams } from 'src/app/models/feeditem';
import { RootStoreState } from 'src/app/root-store';
import { FeedItemDataService } from 'src/app/services/feeditem-data.service';
import { GridBaseComponent } from 'src/app/shared/base-components/grid-base-component';

export interface FeeditemEntityModalDialogData {
  filterRelatedByLicenseIds: number[];
  filterExcludeDeskIds: number[];
  filterLicenseIds: number[];
  filterProductfamilyIds: number[];
}

@Component({
  selector: 'app-feeditem-entity-modal',
  templateUrl: './feeditem-entity-modal.component.html',
  styleUrls: ['./feeditem-entity-modal.component.scss'],
})
export class FeeditemEntityModalComponent extends GridBaseComponent {
  constructor(
    private feeditemDataService: FeedItemDataService,
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<FeeditemEntityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FeeditemEntityModalDialogData
  ) {
    super(store, cdRef);
  }

  gbOnInit() {
    if (this.data.filterRelatedByLicenseIds && this.data.filterRelatedByLicenseIds.length > 0) {
      this.filtersMapStatic.set('filterRelatedByLicenseIds', this.data.filterRelatedByLicenseIds);
    }
    if (this.data.filterExcludeDeskIds && this.data.filterExcludeDeskIds.length > 0) {
      this.filtersMapStatic.set('filterExcludeDeskIds', this.data.filterExcludeDeskIds);
    }
    if (this.data.filterLicenseIds && this.data.filterLicenseIds.length > 0) {
      this.filtersMapStatic.set('filterLicenseIds', this.data.filterLicenseIds);
    }
    if (this.data.filterProductfamilyIds && this.data.filterProductfamilyIds.length > 0) {
      this.filtersMapStatic.set('filterProductfamilyIds', this.data.filterProductfamilyIds);
    }
  }

  gbGetDisplayColumnTable() {
    return ['radio', 'feeditemName', 'feeditemCode', 'feeditemServiceProviderCode', 'feeditemType', 'feeditemProvider', 'info'];
  }

  gbGetInitialOrderBy() {
    return 'feeditemName';
  }

  gbLoadEntitiesData() {
    this.clearSelection();
    this.isLoading = true;
    this.dataSourceTable = new MatTableDataSource();
    this.noEntityData = false;
    if (this.gridLoadSubscription != null) {
      this.gridLoadSubscription.unsubscribe();
    }
    this.gridLoadSubscription = this.subscribe(
      this.feeditemDataService.getFeedItems(this.request),
      (response) => {
        this.lastUsedFilters = response.filters;
        if (response.data) {
          this.dataSourceTable.data = response.data;
          this.pageTotalElements = response.data[0].entityCount;
        } else {
          this.pageTotalElements = 0;
          this.noEntityData = true;
          this.dataSourceTable.data = [];
        }
      },
      (error) => {
        this.noEntityData = true;
        this.dataSourceTable.data = [];
      },
      () => {
        this.dataSourceTable._updateChangeSubscription();
        this.isLoading = false;
      }
    );
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onEntitySelected(row: FeedItem) {
    this.selectedEntity = row;
  }

  isEntitySelected() {
    return this.selectedEntity && this.selectedEntity.entityName !== '';
  }

  get FeedItemParams() {
    return FeedItemParams;
  }

  get Entities() {
    return Entities;
  }
}
