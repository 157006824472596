import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import {
  TaricsEntitlementChange,
  TaricsEntitlements,
  TaricsNotificationCount,
} from 'src/app/models/interfaces/tarics/tarics-entitlement-change';
import { NotificationsStoreAction } from 'src/app/root-store/notifications-store';
import { LogService } from 'src/app/services/log-service';
import { TaricsInterfaceDataService } from 'src/app/services/tarics-interface-data.service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

@Component({
  selector: 'app-tarics-send-entitlements',
  templateUrl: './tarics-send-entitlements.component.html',
  styleUrls: ['./tarics-send-entitlements.component.scss'],
})
export class TaricsSendEntitlementsComponent extends FormatComponent implements OnInit {
  @Input() taricsCount: TaricsNotificationCount[];
  @Input() isDisabled: boolean;
  taricsChanges: TaricsEntitlements;

  isLoading = false;
  userAddTitle = _('taricsrequest_user_add');
  userUpdateTitle = _('taricsrequest_user_update');
  userRemoveTitle = _('taricsrequest_user_remove');
  serviceAddTitle = _('taricsrequest_service_add');
  serviceUpdateTitle = _('taricsrequest_service_update');
  serviceRemoveTitle = _('taricsrequest_service_remove');
  entitlementAddTitle = _('taricsrequest_entitlement_add');
  entitlementUpdateTitle = _('taricsrequest_entitlement_update');
  entitlementRemoveTitle = _('taricsrequest_entitlement_remove');

  selectedTaricsPlatform: TaricsNotificationCount;
  sendingError: string;

  selectionTaricsPlatformDialog: MatDialogRef<any, any>;
  sendingTaricsDialog: MatDialogRef<any, any>;

  constructor(
    private dialog: MatDialog,
    private taricsDataService: TaricsInterfaceDataService,
    protected messageNotifierService: MessageNotifierService
  ) {
    super();
  }

  displayedColumnsTable = ['entitlementchangeMessage'];

  ngOnInit() {
    if (this.taricsCount && this.taricsCount.length > 0) {
      this.selectedTaricsPlatform = this.taricsCount[0];
    }
  }

  taricsNotifications(ref: TemplateRef<any>) {
    this.selectionTaricsPlatformDialog = this.dialog.open(ref, { autoFocus: false, width: '800px' });
  }

  openTaricsSendEntitlementDialog(ref: TemplateRef<any>) {
    this.selectionTaricsPlatformDialog.close();
    this.isLoading = true;
    this.taricsChanges = null;
    this.sendingError = null;
    this.sendingTaricsDialog = this.dialog.open(ref, {
      autoFocus: false,
      width: '800px',
      disableClose: true,
    });
    this.subscribe(
      this.taricsDataService.getTaricsEntitlementChanges(this.selectedTaricsPlatform.id),
      ({ data }) => {
        this.taricsChanges = data;
        // this.mock();
        this.isLoading = false;
      },
      (error) => {
        this.sendingTaricsDialog.disableClose = false;
      }
    );
  }

  sendTaricsNotification() {
    const orderId: number = this.taricsChanges.orderId;
    this.subscribe(this.taricsDataService.sendTaricsEntitlementChanges(orderId), (response) => {
      LogService.debug(this, this.sendTaricsNotification.name, 'SEND', response);
      // this.sendingError = 'Trigger Error Mock'
      if (response && response.data.state) {
        this.messageNotifierService.showSuccessMessage(_('label_tarics_send_entitlements_successfully_sent'));
        this.store.dispatch(NotificationsStoreAction.refreshNotificationCount());
        this.sendingTaricsDialog.close();
      } else if (response && !response.data.state) {
        this.sendingError = response.data.error;
      } else {
        this.messageNotifierService.showWarningMessage(_('toastr_no_data'));
      }
    });
  }

  mock() {
    this.taricsChanges.error = null;
    this.taricsChanges.userAdd = [
      { ...new TaricsEntitlementChange(), entitlementchangeMessage: 'test message' },
      { ...new TaricsEntitlementChange(), entitlementchangeMessage: 'test message 2' },
      { ...new TaricsEntitlementChange(), entitlementchangeMessage: 'test message 3' },
      { ...new TaricsEntitlementChange(), entitlementchangeMessage: 'test message 4' },
    ];
    this.taricsChanges.userRemove = [{ ...new TaricsEntitlementChange(), entitlementchangeMessage: 'test message' }];
    this.taricsChanges.userUpdate = [{ ...new TaricsEntitlementChange(), entitlementchangeMessage: 'test message' }];
    this.taricsChanges.entitlementAdd = [{ ...new TaricsEntitlementChange(), entitlementchangeMessage: 'test message' }];
    this.taricsChanges.entitlementRemove = [{ ...new TaricsEntitlementChange(), entitlementchangeMessage: 'test message' }];
    this.taricsChanges.entitlementUpdate = [{ ...new TaricsEntitlementChange(), entitlementchangeMessage: 'test message' }];
    this.taricsChanges.serviceChangeAdd = [{ ...new TaricsEntitlementChange(), entitlementchangeMessage: 'test message' }];
    this.taricsChanges.serviceChangeRemove = [{ ...new TaricsEntitlementChange(), entitlementchangeMessage: 'test message' }];
    this.taricsChanges.serviceChangeUpdate = [{ ...new TaricsEntitlementChange(), entitlementchangeMessage: 'test message' }];
  }
}
