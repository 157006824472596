import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, mergeMap, switchMap } from 'rxjs/operators';
import { HttpHeaders } from 'src/app/configs/http-headers';
import { AuthToken } from 'src/app/core/auth/auth-token';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { AuthService } from '../auth/auth.service';
import { LogService } from '../log-service';

@Injectable()
export class AuthJWTInterceptor implements HttpInterceptor {
  constructor(private store: Store<RootStoreState.State>, private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // do not intercept request whose urls are filtered by the injected filter
    // if (!this.filter(req)) {}
    // else {
    //     return next.handle(req);
    // }

    const m = this.intercept.name;

    return this.store.select(PreferencesSelectors.selectPreferencesHelp).pipe(
      first(),
      mergeMap((helpSetting) => {
        if (!req.url.match(/api\/restricted\//) && (!helpSetting || !req.url.match(helpSetting.settingHelpUrl))) {
          const retVal = this.authService.isAuthenticated().pipe(
            switchMap((authenticated) => {
              if (authenticated) {
                return this.authService.getToken().pipe(
                  switchMap((token: AuthToken) => {
                    const JWT = `${HttpHeaders.AUTH_BEARER} ${token.toString()}`;
                    req = req.clone({
                      setHeaders: {
                        Authorization: JWT,
                      },
                    });
                    LogService.info(this, m, 'Auth Http request', req);
                    return next.handle(req);
                  })
                );
              } else {
                // Request is sent to server without authentication so that the client code
                // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
                LogService.info(this, m, 'Http request', req);
                return next.handle(req);
              }
            })
          );

          return retVal;
        } else {
          return next.handle(req);
        }
      })
    );
  }

  private get authService(): AuthService {
    return this.injector.get(AuthService);
  }
}
