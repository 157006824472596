import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigurationSearchStoreEffects } from './effects';
import * as fromConfigurationSearchStore from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromConfigurationSearchStore.configurationSearchStoreFeatureKey, fromConfigurationSearchStore.reducer),
    EffectsModule.forFeature([ConfigurationSearchStoreEffects]),
  ],
})
export class ConfigurationSearchStoreModule {}
