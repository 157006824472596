import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { AssetSubscription } from 'src/app/models/asset-subscription';
import { CatalogPackageContent } from 'src/app/models/catalog-package-particulars';
import { DeactivateBillingitemAssetDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/deactivate-billingitem-asset-wizard/deactivation-step/deactivate-billingitem-asset-due-date-info';
import { DeactivateBillingitemAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/deactivate-billingitem-asset-wizard/init-step/deactivate-billingitem-asset-init-step';
import * as fromOrderWizard from './reducer';
import { State } from './state';

export const selectDeactivateBillingitemAssetWizardState = createFeatureSelector<State>(
  fromOrderWizard.deactivateBillingitemAssetWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectDeactivateBillingitemAssetWizardState,
  (state: State): DeactivateBillingitemAssetInitStep => state.initStep
);

export const selectDueDateStep = createSelector(
  selectDeactivateBillingitemAssetWizardState,
  (state: State): DeactivateBillingitemAssetDueDates => state.dueDatesStep
);

export const selectCancelSubscriptions = createSelector(
  selectDeactivateBillingitemAssetWizardState,
  (state: State): AssetSubscription[] => state.subscriptionStep
);

export const selectCancelPackageContents = createSelector(
  selectDeactivateBillingitemAssetWizardState,
  (state: State): CatalogPackageContent[] => state.packageContentStep
);

export const selectDeactivationState = createSelector(
  selectDeactivateBillingitemAssetWizardState,
  (state: State): ActionResponse => state.deactivationResponse
);

export const selectErrorMessage = createSelector(selectDeactivateBillingitemAssetWizardState, (state: State): any => state.errorMessage);
