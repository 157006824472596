import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SidemenuItem } from '../../models/module-item';
import * as fromModuleItem from './reducer';
import { State, featureAdapter } from './state';

export const selectModuleItemState = createFeatureSelector<State>(fromModuleItem.foModuleFeatureKey);

// export const selectAllModuleItems: (
//   state: object
// ) => ModuleItem[] = featureAdapter.getSelectors(selectModuleItemState)
//   .selectAll;

export const selectAllModuleItems: (state: object) => SidemenuItem[] = featureAdapter.getSelectors(selectModuleItemState).selectAll;

export const selectModuleItemError = createSelector(selectModuleItemState, (state: State): any => state.error);

export const selectModuleItemIsLoading = createSelector(selectModuleItemState, (state: State): boolean => state.isLoading);
