import { HttpParams } from '@angular/common/http';
import { Entity } from './entity';
import { PaginatedRequest } from './paginated-request';

export class BillingItemUsage extends Entity {
  usageId: number;
  usageBillingitemId: number;
  usageLicenseId: number;
  usageAsset: string;
  usageFeeditem: string;
  usageStarts: Date;
  usageEnds: Date;
  usageUnits: number;
  usageUnitscredit: number;
  usagePrice: number;
  usagePricecredit: number;
  usageHasusage: boolean;
  usageUpdatefuture: boolean;
}

export class BillingItemUsagePeriod {
  usageperiodId: number;
  usageperiodStarts: Date;
  usageperiodEnds: Date;
  usageperiodLocked: boolean;
}

export class BillingItemUsageRequest {
  usageId: number;
  usageInvoiceId: number;
  usageBillingitemId: number;
  usageLicenseId: number;
  usageStarts: Date;
  usageEnds: Date;
  usageUnits: number;
  usageUnitscredit: number;
  usagePrice: number;
  usagePricecredit: number;
  usageUpdatefuture?: boolean;
}

export class BillingItemUsageRequestParams {
  public static ASSET_ID = 'assetId';
  public static BILLING_ITEM_ID = 'billingitemId';
  public static FILTER_ASSET_ID = 'filterBillingitemId';
  public static FILTER_BILLING_ITEM_ID = 'filterBillingitemId';
  public static FILTER_STARTS = 'filterStarts';
  public static FILTER_ENDS = 'filterEnds';
}

export function getBillingItemUsageRequestParams(
  request: PaginatedRequest,
  billingitemId: number,
  assetId: number,
  starts: string,
  ends: string
): HttpParams {
  let params = PaginatedRequest.getBaseRequestParams(request);
  if (billingitemId) {
    params = params.append(BillingItemUsageRequestParams.BILLING_ITEM_ID, billingitemId.toString());
  }
  if (assetId) {
    params = params.append(BillingItemUsageRequestParams.ASSET_ID, assetId.toString());
  }

  if (starts && ends) {
    params = params.append(BillingItemUsageRequestParams.FILTER_STARTS, starts);
    params = params.append(BillingItemUsageRequestParams.FILTER_ENDS, ends);
  } else {
    throw Error('Missing required parameters');
  }
  return params;
}
