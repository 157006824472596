import { Action, createReducer, on } from '@ngrx/store';
import * as AddBillingitemsWizardActions from './actions';
import { State, initialState } from './state';

export const addBillingitemsWizardFeatureKey = 'addBillingitemsWizard';

const addBillingitemsWizardReducer = createReducer(
  initialState,

  on(AddBillingitemsWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(AddBillingitemsWizardActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(AddBillingitemsWizardActions.selectBillingaccountStep, (state, { billingaccountStep }) => ({
    ...state,
    billingaccountStep,
  })),
  on(AddBillingitemsWizardActions.selectPricingStep, (state, { pricingStep }) => ({
    ...state,
    pricingStep,
  })),
  on(AddBillingitemsWizardActions.deleteBillingaccountStep, (state) => ({
    ...state,
    billingaccountStep: null,
  })),
  on(AddBillingitemsWizardActions.deletePricingStep, (state) => ({
    ...state,
    pricingStep: null,
  })),
  on(AddBillingitemsWizardActions.exitAddBillingitemsWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    billingaccountStep: null,
    pricingStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(AddBillingitemsWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    billingaccountStep: null,
    pricingStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(AddBillingitemsWizardActions.createNewAddBillingitems, (state, { request }) => ({
    ...state,
    request,
  })),
  on(AddBillingitemsWizardActions.createNewAddBillingitemsSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(AddBillingitemsWizardActions.createNewAddBillingitemsFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return addBillingitemsWizardReducer(state, action);
}
