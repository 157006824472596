import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { LogService } from 'src/app/services/log-service';
import { BaseComponent } from 'src/app/shared/base-components/base-component';

@Component({ template: '' })
export class HomepageComponent extends BaseComponent implements OnInit {
  constructor(private store: Store<RootStoreState.State>, private router: Router) {
    super();
  }

  ngOnInit() {
    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesInitialPageLink)), (page) => {
      if (page) {
        this.router
          .navigate([page])
          .then(() => {
            LogService.debug(this, this.ngOnInit.name, 'Preference page', page);
          })
          .catch(() => {
            LogService.debug(this, this.ngOnInit.name, 'Preference page not exists', page);
            this.router.navigate(['']);
          });
      }
    });
  }
}
