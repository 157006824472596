import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { ReplaceProductServiceWizardDataService } from 'src/app/services/wizards/replace-billingitems-wizard.service';
import * as ReplaceProductServiceWizardActions from './actions';
import { selectInitStep } from './selectors';
import { State } from './state';

@Injectable()
export class ReplaceProductServiceWizardEffects {
  // loadReplaceProductWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(ReplaceProductServiceWizardActions.selectInitStep),
  //       tap(() => this.router.navigateByUrl('/pages/allocation-wizards/order'))
  //     ),
  //   { dispatch: false }
  // );
  // exitReplaceProductServiceAllocationWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(ReplaceProductServiceWizardActions.exitAllocationReplaceProductServiceWizard),
  //       tap(() => this.router.navigateByUrl('/pages/consumers'))
  //     ),
  //   { dispatch: false }
  // );

  newReplaceProductService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReplaceProductServiceWizardActions.createNewReplaceProductService),
      withLatestFrom(this.store.select(selectInitStep)),
      concatMap(([{ request }, initStep]) =>
        this.replaceProductServiceWizardService.replaceProductService(initStep.assetId, initStep.subscriptionId, request).pipe(
          map((response) => {
            if (response) {
              return ReplaceProductServiceWizardActions.createNewReplaceProductServiceSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            const errorMessage = error;
            return of(ReplaceProductServiceWizardActions.createNewReplaceProductServiceFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private replaceProductServiceWizardService: ReplaceProductServiceWizardDataService
  ) {}
}
