import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { CancelWizardDataService } from 'src/app/services/wizards/cancel-user-wizard-data.service';
import * as FoUserLicenseServicesStoreAction from './actions';

@Injectable()
export class FoUserLicenseServicesEffects {
  loadLicenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FoUserLicenseServicesStoreAction.loadUserLicenceServices),
      concatMap(({ userId, deskId, licenseId, activationDate, deactivationDate, cancelService }) =>
        this.service.getLicenceServices(activationDate, deactivationDate, cancelService, userId, deskId, licenseId).pipe(
          map((response) => {
            if (response.data) {
              return FoUserLicenseServicesStoreAction.loadSuccess({ response });
            } else {
              return FoUserLicenseServicesStoreAction.noData();
            }
          }),
          catchError((error) => of(FoUserLicenseServicesStoreAction.loadFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private service: CancelWizardDataService) {}
}
