import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SidemenuItem } from 'src/app/models/module-item';
import { TopNavbarSettings } from 'src/app/models/preference-settings';
import { AuthStoreAction, AuthStoreSelectors, ModuleItemStoreAction, ModuleItemStoreSelectors, RootStoreState } from 'src/app/root-store';
import { CoreUiInterfaceStoreSelectors } from 'src/app/root-store/core-ui-interface.store';
import { ErrorHandlingStoreAction, ErrorHandlingStoreSelectors } from 'src/app/root-store/error-handling-store';
import { GlobalDateFilterStoreAction } from 'src/app/root-store/global-date-filter-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { PreferenceDataService } from 'src/app/services/preference-data.service';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../../shared/base-components/base-component';
import { isHexLightColor } from '../../../shared/utils/style-functions';
import { AuthUser } from '../../auth/auth-user';
import { ResponsiveBreakpointsService } from '../../responsive-breakpoints/responsive-breakpoints.service';
import { SideMenuService } from '../side-menu.service';

@Component({
  selector: 'app-side-menu-content',
  templateUrl: './side-menu-content.component.html',
  styleUrls: ['./side-menu-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideMenuContentComponent extends BaseComponent implements OnInit {
  sidemenuItems: SidemenuItem[] = [];
  // userProfileVisible = false;
  authUser: AuthUser;
  isAuthenticated = false;
  isTenantAdminAuthenticated = false;
  feedbackEmail: string;
  navbarSettings: TopNavbarSettings;
  quoteText: string;
  quoteAuthor: string;
  isMenuHeaderColorLight = false;
  isServerUnreachableError = false;
  isMenuDisabled = false;

  openNewTab(page: string) {
    this.store.dispatch(GlobalDateFilterStoreAction.saveGlobalDateFilterStatus());
    const urlTree = this.router.createUrlTree([page]);
    const url = this.router.serializeUrl(urlTree);
    window.open(url, '_blank');
  }

  private initialData() {
    return combineLatest([
      this.store.pipe(select(AuthStoreSelectors.selectAuthUser)),
      this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticated)),
      this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin)),
    ]);
  }

  ngOnInit(): void {
    this.subscribe(this.initialData(), ([authUser, isAuth, isTenantAdminAuth]) => {
      this.authUser = authUser;
      this.isAuthenticated = isAuth;
      this.isTenantAdminAuthenticated = isTenantAdminAuth;
      if ((authUser && (isAuth || isTenantAdminAuth)) || (!authUser && !isAuth && !isTenantAdminAuth)) {
        this.store.dispatch(ModuleItemStoreAction.loadModuleItems({ isAuth, isTenantAdmin: isTenantAdminAuth }));
        // this.store.dispatch(PreferencesAction.loadPageSizes({ isAuth, isTenantAdmin: isTenantAdminAuth })); MOVED INTO LOAD PREFERENCE SETTINGS
      }
      if (authUser && (isAuth || isTenantAdminAuth)) {
        // this.store.dispatch(PreferencesAction.loadLanguageRegion({ isTenantAdmin: isTenantAdminAuth })); MOVED INTO LOAD PREFERENCE SETTINGS
      }
      if (authUser && isAuth) {
        this.subscribe(this.preferencesDataService.getPreferenceFeedbackEmail(), ({ data }) => {
          this.feedbackEmail = data;
        });
      } else {
        this.feedbackEmail = null;
      }
    });
    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesQuote)), (quote) => {
      if (quote) {
        this.quoteText = quote.quote;
        this.quoteAuthor = quote.quoteAuthor;
      }
    });
    this.subscribe(this.store.pipe(select(ModuleItemStoreSelectors.selectAllModuleItems)), (modules) => {
      modules.every((m, index) => {
        if (m.sidemenuModuleId == null) {
          m.hide = true;
          // modules.splice(index, 1);
          return true;
        } else {
          return false;
        }
      });
      this.sidemenuItems = modules.filter((m) => !m.hide).map((i) => Object.assign(new SidemenuItem(), i));
    });
    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesTopNavBarSettings)), (navbarSettings) => {
      this.navbarSettings = navbarSettings;
      if (this.navbarSettings != null) {
        this.isMenuHeaderColorLight = isHexLightColor(this.navbarSettings.headerImageBackgroundColor);
      }
    });
    this.subscribe(
      this.store.pipe(select(CoreUiInterfaceStoreSelectors.selectCoreUiInterfaceIsUiDisabled)),
      (isDisabled) => (this.isMenuDisabled = isDisabled)
    );

    this.subscribe(this.store.select(ErrorHandlingStoreSelectors.selectServerUnreachableErrorOccurred), (isError) => {
      this.isServerUnreachableError = isError;
    });
  }

  // Call this method in the image source, it will sanitize it.
  transform(base64Image: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }

  logout() {
    this.store.dispatch(AuthStoreAction.logout());
  }

  resetServerErrorFlag() {
    if (this.isServerUnreachableError) {
      this.store.dispatch(ErrorHandlingStoreAction.resetServerUnreachableError());
    }
  }

  openExternalLink(link: string) {
    window.open(link, '_blank');
  }

  toggleSideMenu(): void {
    if (this.sideMenuService.sidemenuExpanded) {
      this.collapseMenuItems();
    }
    this.sideMenuService.sidemenuExpanded = !this.sideMenuService.sidemenuExpanded;
  }

  collapseMenuItems() {
    //this.sidemenuItems.forEach((mmi) => (mmi.moduleExpanded = false));
  }

  constructor(
    private store: Store<RootStoreState.State>,
    private responsiveService: ResponsiveBreakpointsService,
    private preferencesDataService: PreferenceDataService,
    private sanitizer: DomSanitizer,
    public sideMenuService: SideMenuService,
    public dialog: MatDialog,
    private router: Router
  ) {
    super();
    this.responsiveService.responsiveSubject.pipe(filter((breakpoint) => breakpoint.screen === 'xs-or-sm')).subscribe((breakpoint) => {
      if (breakpoint.active) {
        // this.userProfileVisible = true;
      } else {
        // this.userProfileVisible = false;
      }
    });
  }

  get environtment() {
    return environment;
  }
}
