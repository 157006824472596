import { Action, createReducer, on } from '@ngrx/store';
import * as CancelUserWizardStoreActions from './actions';
import { State, initialState } from './state';

export const cancelWizardStoreFeatureKey = 'cancelWizardStore';

const cancelUserWizardStoreReducer = createReducer(
  initialState,

  on(CancelUserWizardStoreActions.selectInitStep, (state, { initStep: userStep }) => ({
    ...state,
    initStep: userStep,
  })),
  on(CancelUserWizardStoreActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(CancelUserWizardStoreActions.selectLicenseStep, (state, { licenseStep }) => ({
    ...state,
    licenseStep,
  })),
  on(CancelUserWizardStoreActions.selectAdditionalStep, (state, { addInfoStep }) => ({
    ...state,
    addInfoStep,
  })),
  on(CancelUserWizardStoreActions.exitAllocationCancelWizard, (state) => ({
    ...state,
    initStep: null,
    licenseStep: null,
    addInfoStep: null,
    cancelRequest: null,
    cancelLicenseServicesResponse: null,
    errorMessage: null,
  })),
  on(CancelUserWizardStoreActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    licenseStep: null,
    addInfoStep: null,
    cancelRequest: null,
    cancelLicenseServicesResponse: null,
    errorMessage: null,
  })),
  on(CancelUserWizardStoreActions.sendLicenseServicesCancelRequest, (state, { cancelRequest }) => ({
    ...state,
    cancelRequest,
  })),
  on(CancelUserWizardStoreActions.cancelRequestSuccess, (state, { response }) => ({
    ...state,
    cancelLicenseServicesResponse: response,
  })),
  on(CancelUserWizardStoreActions.cancelRequestFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return cancelUserWizardStoreReducer(state, action);
}
