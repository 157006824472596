import { KeyValueAddon2 } from '../core/key-value-data';
import { Entity } from '../entity';

export class Email extends Entity {
  emailAttachmentsReadonly: boolean;
  emailBccs: EmailAddress[];
  emailBccsReadonly: boolean;
  emailBody: string;
  emailBodyReadonly: boolean;
  emailCcs: EmailAddress[];
  emailCcsReadonly: boolean;
  emailColorBackground: string;
  emailColorButton: string;
  emailColorFrame: string;
  emailDateFormat: string;
  emailDateTimeFormat: string;
  emailFontfamily: string;
  emailFontfamilyId: number;
  emailFooter: string;
  emailFooterImage: string;
  emailHeaderImage: string;
  emailId: number;
  emailLanguage: string;
  emailLanguageId: number;
  emailMailoutgoingaccountId: number;
  emailMoneyFormat: string;
  emailNumberFormat: string;
  emailStage: string;
  emailStageId: number;
  emailSubject: string;
  emailSubjectReadonly: boolean;
  emailBodyTags: KeyValueAddon2<string, string, string>[];
  emailSubjectTags: KeyValueAddon2<string, string, string>[];
  emailTimeFormat: string;
  emailTos: EmailAddress[];
  emailTosReadonly: boolean;
  emailWidthContent: string;
  emailWidthFrame: string;
  emailTemplate: string;
  emailTemplateId: number;
  emailAttachments: EmailFile[];
  emailShowFooterText: boolean;
  emailShowQuote: boolean;
}

export class EmailAddress extends Entity {
  emailaddressEmail: string;
  emailaddressFirstname: string;
  emailaddressFullname: string;
  emailaddressId: number;
  emailaddressLastname: string;
  emailaddressDeleted: boolean;
}

export class EmailFile {
  fileId: number;
  fileName: string;
  fileContenttype: string;
  fileData: string;
  fileExt: string;
}

export class EmailRequest {
  emailMailoutgoingaccountId: number;
  emailBccs: EmailAddress[];
  emailBody: string;
  emailBodyTags: KeyValueAddon2<string, string, string>[];
  emailCcs: EmailAddress[];
  emailColorBackground: string;
  emailColorButton: string;
  emailColorFrame: string;
  emailFontfamily: string;
  emailFooter: string;
  emailFooterImage: string;
  emailHeaderImage: string;
  emailSubject: string;
  emailTos: EmailAddress[];
  emailWidthContent: string;
  emailWidthFrame: string;
  // emailEntityId: number;
  // emailEntityKind: string;
  emailFileIds: number[];
  emailSubjectTags: KeyValueAddon2<string, string, string>[];
  emailDateFormat: string;
  emailDateTimeFormat: string;
  emailMoneyFormat: string;
  emailNumberFormat: string;
  emailTimeFormat: string;
  emailShowFooterText: boolean;
  emailShowQuote: boolean;
  emailTemplateId: number;
}

export class MailHistory extends Entity {
  emailhistoryAttachments: EmailFile[];
  emailhistoryBccs: EmailAddress[];
  emailhistoryBody: string;
  emailhistoryCcs: EmailAddress[];
  emailhistoryEntity: string;
  emailhistoryEntityId: number;
  emailhistoryError: string;
  emailhistoryId: number;
  emailhistoryProperty: string;
  emailhistoryPropertyId: number;
  emailhistoryRead: Date;
  emailhistorySent: Date;
  emailhistorySubject: string;
  emailhistoryTos: string;
  entityDeleted?: boolean;
}
