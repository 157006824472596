import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { PublicPageSettings } from 'src/app/models/preference-settings';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesAction, PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { TokenService } from 'src/app/services/auth/token.service';
import { BaseComponent } from 'src/app/shared/base-components/base-component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-direct-login-oauth',
  templateUrl: './direct-login-oauth.component.html',
})
export class DirectLoginOauthComponent extends BaseComponent implements OnInit {
  publicPageSettings: PublicPageSettings;
  returnUrl: string;
  registrationId: string;

  constructor(
    private store: Store<RootStoreState.State>,
    private route: ActivatedRoute,
    private tokenService: TokenService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.registrationId = this.route.snapshot.paramMap.get('registrationId');
    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesPublicPageSettings)), (publicSettings) => {
      this.publicPageSettings = publicSettings;
      const microsoft = publicSettings.openAuthorizations.find((o) => o.type === this.registrationId);
      if (microsoft) {
        location.href = `${environment.getEndpoint(microsoft.link)}`;
      } else {
        this.tokenService.clear();
        // this.router.navigate(['/auth/login']);
        this.router.navigate(['/auth/login']).then(() => {
          this.store.dispatch(PreferencesAction.loadPreferenceSettings({ isAuth: false, isTenantAdmin: false }));
        });
      }
    });
  }
}
