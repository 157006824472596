import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  DoCheck,
  ElementRef,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Subject } from 'rxjs';
import { LogService } from 'src/app/services/log-service';

@Component({
  selector: 'app-email-dropper',
  templateUrl: './email-dropper.component.html',
  styleUrls: ['./email-dropper.component.scss'],
  encapsulation: ViewEncapsulation.None,

  providers: [
    { provide: MatFormFieldControl, useExisting: EmailDropperComponent },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailDropperComponent),
      multi: true,
    },
  ],
})
export class EmailDropperComponent implements MatFormFieldControl<string>, ControlValueAccessor, OnDestroy, OnInit, DoCheck {
  static nextId = 0;
  // Actual fileContents
  fileContent: string = undefined;
  stateChanges = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _placeholder: string;
  focused = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _required = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _disabled = false;
  errorState = true;
  @HostBinding() id = `file-dropper-${EmailDropperComponent.nextId++}`;
  @HostBinding('attr.aria-describedBy') describedBy = '';

  controlType = 'file-dropper';
  private touched: boolean;
  ngControl: NgControl;

  constructor(public injector: Injector, private fm: FocusMonitor, private elementRef: ElementRef) {
    fm.monitor(elementRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange = (newValue) => {
    this.fileContent = newValue;
  };

  onTouched = () => {
    this.touched = true;
  };

  writeValue(obj: any): void {
    this.value = obj;
    // throw new Error('Method not implemented.');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    // throw new Error('Method not implemented.');
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    // throw new Error('Method not implemented.');
  }

  /*
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }
  */

  // Expose value for the MatFormFieldControl
  @Input() get value(): string | null {
    return this.fileContent;
  }

  set value(newValue: string | null) {
    this.fileContent = newValue;
    this.onChange(newValue);
    this.onTouched();
    // Notify state change
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elementRef.nativeElement);
  }

  get empty() {
    return this.value.length === 0;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join();
  }

  public fileOver(event) {}

  public fileLeave(event) {}

  onContainerClick(event: MouseEvent): void {
    this.stateChanges.next();
  }

  public dropped(files: NgxFileDropEntry[]) {
    const m = this.dropped.name;

    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          try {
            // get all file as blob
            const blob = file.slice(0, -1);
            // create a file reader
            const reader = new FileReader();
            // read blob content as text
            reader.readAsText(blob);
            // attach a callback once reader has finished
            reader.onload = () => {
              const text = typeof reader.result === 'string' ? reader.result : '';
              // notify change
              this.value = this.parseEml(text);
            };
          } catch (e) {
            const msg = 'Catched an error on try catch statement';
            LogService.error(this, m, msg, e);
          }
        });
      }
    }
  }

  /**
   * Gets the body of an EML format mail
   * Reference: https://tools.ietf.org/html/rfc822
   * @param mail the mail to parse
   */
  private parseEml(mail: string): string {
    // Index of first boudary appareance
    const boundaryStart = mail.indexOf('----');
    // Index boudary end
    const boundaryEnd = mail.indexOf('\n', boundaryStart);
    // Unique id of mail
    const mailId = mail.substring(boundaryStart, boundaryEnd);
    // String array of bounded text
    const boundedMails = mail.split(mailId);
    // Body of mail
    const firstMailText = boundedMails[1];
    // Splitted mail on eol to get encoding information of the mail
    const firstMailLines = firstMailText.split('\n');
    // Headers
    const contentTypeAndCharset = firstMailLines[1].split(';');
    const isPlainText = contentTypeAndCharset[0].includes('text/plain');
    const isHTML = contentTypeAndCharset[0].includes('text/html');
    if (isPlainText || isHTML) {
      const charset = contentTypeAndCharset[1] || 'utf-8';
      const isBase64 = firstMailLines[2].includes('base64');
      // Message of mail
      let mailBody = firstMailLines.slice(3, -1).join('');
      if (isBase64) {
        mailBody = atob(mailBody);
      }
      return mailBody;
    }
    return '';
  }

  changeHandler(text: string) {
    this.value = text;
    this.onTouched();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched && !this.focused;
      this.stateChanges.next();
    }
  }
}
