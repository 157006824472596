import { Action, createReducer, on } from '@ngrx/store';
import * as CancelAssetWizardActions from './actions';
import { State, initialState } from './state';

export const cancelAssetWizardFeatureKey = 'cancelAssetWizard';

const cancelAssetWizardReducer = createReducer(
  initialState,

  on(CancelAssetWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(CancelAssetWizardActions.selectCancellationDetailsStep, (state, { cancellationDetails }) => ({
    ...state,
    cancellationDetails,
  })),
  on(CancelAssetWizardActions.deleteCancellationDetailsStep, (state) => ({
    ...state,
    cancellationDetails: null,
  })),
  on(CancelAssetWizardActions.exitCancelAssetWizard, (state) => ({
    ...state,
    initStep: null,
    errorMessage: null,
    cancellationDetails: null,
    request: null,
    response: null,
  })),
  on(CancelAssetWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    errorMessage: null,
    cancellationDetails: null,
    request: null,
    response: null,
  })),
  on(CancelAssetWizardActions.createNewCancelAsset, (state, { request }) => ({
    ...state,
    request,
  })),
  on(CancelAssetWizardActions.createNewCancelAssetSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(CancelAssetWizardActions.createNewCancelAssetFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return cancelAssetWizardReducer(state, action);
}
