import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { AssetSubscription } from 'src/app/models/asset-subscription';
import { CatalogActivationRequest } from 'src/app/models/catalog';
import { CatalogPackageContent } from 'src/app/models/catalog-package-particulars';
import { DeactivateBillingitemAssetDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/deactivate-billingitem-asset-wizard/deactivation-step/deactivate-billingitem-asset-due-date-info';
import { DeactivateBillingitemAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/deactivate-billingitem-asset-wizard/init-step/deactivate-billingitem-asset-init-step';

export const selectInitStep = createAction(
  '[Catalog Grid] Select Deactivate Billingitem Asset Init Information Step',
  props<{ initStep: DeactivateBillingitemAssetInitStep }>()
);

export const selectDueDatesStep = createAction(
  '[Deactivate Billingitem Asset Due Date Step] Select Due Date Step',
  props<{ dueDatesStep: DeactivateBillingitemAssetDueDates }>()
);

export const exitDeactivateBillingitemAssetWizard = createAction(
  '[Deactivate Billingitem Asset Wizard Page] Exit Deactivate Billingitem Asset Wizard'
);

export const selectCancelSubscriptions = createAction(
  '[Deactivate Billingitem Asset Validation Step] Select Validation Step',
  props<{ subscriptionStep: AssetSubscription[]; packageContentStep: CatalogPackageContent[] }>()
);

export const destroyWizard = createAction('[Deactivate Billingitem Asset Wizard Page] Destroy Wizard');

export const sendDeactivateBillingitemAssetRequest = createAction(
  '[Deactivate Billingitem Asset Confirmation Step] Deactivate Billingitem Asset Information Step',
  props<{ request: CatalogActivationRequest }>()
);

export const sendDeactivateBillingitemAssetRequestSuccess = createAction(
  '[DeactivateBillingitem Asset Confirmation Step] DeactivateBillingitem Asset Information Step Success',
  props<{ response: ActionResponse }>()
);

export const sendDeactivateBillingitemAssetRequestFail = createAction(
  '[DeactivateBillingitem Asset Confirmation Step] DeactivateBillingitem Asset Information Step Fail',
  props<{ errorMessage: any }>()
);
