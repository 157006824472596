<ng-container *ngIf="dynamicFilters">
  <div class="generic-dynamic-grid-filters-container">
    <div class="generic-dynamic-grid-filters">
      <ng-container *ngFor="let filter of availableFilters">
        <generic-grid-filter-panel
          *ngIf="filter.gridfilterType === GridFilterType.integer"
          [clearFilter]="clearFilterSubject"
          [filtersMapSubj]="filtersMapSubject.asObservable()"
          [filterName]="filter.gridfilterVariable"
          [filterApiPath]="apiPath(filter)"
          [filterTitle]="filter.gridfilterName"
          [gridName]="gridName"
          [gridfilterId]="filter.gridfilterId"
          (filterIdsEvEm)="emitFilterIds($event, filter.gridfilterVariable)"
        ></generic-grid-filter-panel>
        <generic-grid-filter-panel
          *ngIf="filter.gridfilterType === GridFilterType.string"
          [clearFilter]="clearFilterSubject"
          [filtersMapSubj]="filtersMapSubject.asObservable()"
          [filterName]="filter.gridfilterVariable"
          [filterApiPath]="apiPath(filter)"
          [filterTitle]="filter.gridfilterName"
          [isStringFilter]="true"
          [gridName]="gridName"
          [gridfilterId]="filter.gridfilterId"
          (filterIdsEvEm)="emitFilterIds($event, filter.gridfilterVariable)"
        ></generic-grid-filter-panel>
        <generic-grid-filter-date-panel
          *ngIf="filter.gridfilterType === GridFilterType.date"
          [clearFilter]="clearFilterSubject"
          [filtersMapSubj]="filtersMapSubject.asObservable()"
          [filterName]="filter.gridfilterVariable"
          [filterTitle]="filter.gridfilterName"
          [gridName]="gridName"
          [gridfilterId]="filter.gridfilterId"
          (filterDatesEvEm)="emitFilterIds($event, filter.gridfilterVariable)"
        ></generic-grid-filter-date-panel>
        <generic-grid-filter-datetime-panel
          *ngIf="filter.gridfilterType === GridFilterType.datetime"
          [clearFilter]="clearFilterSubject"
          [filtersMapSubj]="filtersMapSubject.asObservable()"
          [filterName]="filter.gridfilterVariable"
          [filterTitle]="filter.gridfilterName"
          [gridName]="gridName"
          [gridfilterId]="filter.gridfilterId"
          (filterDatesEvEm)="emitFilterIds($event, filter.gridfilterVariable)"
        ></generic-grid-filter-datetime-panel>
      </ng-container>
    </div>

    <button
      mat-flat-button
      color="primary"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="switchOverlay(); $event.stopPropagation()"
    >
      {{ 'label_more_filters' | translate }}
    </button>
  </div>

  <ng-template
    #morefilterOverlay="cdkConnectedOverlay"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOverlayOpen"
    [cdkConnectedOverlayPositions]="overlayPositions"
    (overlayOutsideClick)="closeOverlay(); $event.stopPropagation()"
  >
    <div class="generic-dynamic-grid-more-filters-overlay">
      <div class="grid-filter-options-container">
        <div class="options-container">
          <div class="table-wrapper">
            <div class="table-container items">
              <table
                class="table table-stripes table-truncate-text"
                mat-table
                [dataSource]="dataSourceTable"
                cdkDropList
                [cdkDropListData]="dataSourceTable.data"
                (cdkDropListDropped)="dropStep($event)"
              >
                <ng-container matColumnDef="gridfilterName">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell showIfTruncated [matTooltip]="row.gridfilterName" *matCellDef="let row">
                    {{ row.gridfilterName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>{{ 'label_always_show' | translate }}</th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      (change)="$event ? changeFavorited(row) : null"
                      [checked]="row.gridfilterFavorited"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="down">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row; let index = index" class="icon-cell">
                    <mat-icon
                      *ngIf="index !== dataSourceTable.data.length - 1"
                      (click)="$event.stopPropagation(); moveDown(index)"
                      fontSet="fas"
                      fontIcon="fa-arrow-down"
                    ></mat-icon>
                  </td>
                </ng-container>

                <ng-container matColumnDef="up">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row; let index = index" class="icon-cell">
                    <mat-icon
                      *ngIf="index !== 0"
                      (click)="$event.stopPropagation(); moveUp(index)"
                      fontSet="fas"
                      fontIcon="fa-arrow-up"
                    ></mat-icon>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsTable"
                  cdkDragLockAxis="y"
                  cdkDrag
                  [cdkDragData]="row"
                  [cdkDragDisabled]="disableSort"
                >
                  <!-- Preview -->
                  <div *cdkDragPreview matchSize="true" class="container dragdrop-placeholder">
                    {{ row.gridfilterName }}
                  </div>
                </tr>
              </table>
            </div>
            <!-- <div class="close-button-container">
              <button mat-flat-button color="primary" (click)="closeOverlay()">
                <ng-container> {{ 'label_close' | translate }}</ng-container>
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
