import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromErrorHandling from './reducer';
import { State } from './state';

export const errorHandling = createFeatureSelector<State>(fromErrorHandling.errorHandlingFeatureKey);

export const selectIsErrorOccurred = createSelector(errorHandling, (state: State): boolean => state.isErrorOccurred);

export const selectServerUnreachableErrorOccurred = createSelector(
  errorHandling,
  (state: State): boolean => state.isServerUnreachableError
);

export const selectIsSvgErrorOccurred = createSelector(errorHandling, (state: State): boolean => state.isSvgLoadErrorOccurred);
