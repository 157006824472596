import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { AuthError, AuthResult } from 'src/app/core/auth/auth-result';
import { AuthIllegalJWTTokenError, AuthToken, OauthIdToken } from 'src/app/core/auth/auth-token';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/auth/token.service';
import * as AuthActions from './actions';
import { selectAuthAttempt } from './selectors';
import { State } from './state';

@Injectable()
export class AuthEffects {
  authentication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authenticate),
      concatMap(({ auth }) =>
        this.authService.authenticate(auth).pipe(
          map((res) => {
            if (res.data && res.data.token) {
              const token = new AuthToken(res.data.token);
              const oauthToken = new OauthIdToken(res.data.idToken);
              if (!token.isValid()) {
                throw new AuthIllegalJWTTokenError('Token is empty or invalid.');
              }
              const result = new AuthResult(true, res, null, token);
              this.tokenService.set(token, oauthToken);
              return AuthActions.authSuccess({ result });
            } else if (res.data && res.data.tenants && res.data.tenants.length > 0) {
              return AuthActions.authMultiTenant({
                tenants: res.data.tenants,
                logintryId: res.data.logintryid,
                accessToken: res.data.accessToken,
                idToken: res.data.idToken,
              });
            } else if (res.data && res.data.twofactorauthentication != null && res.data.error == null) {
              return AuthActions.authTwoFactorAuth({
                twoFactorMethod: res.data.twofactorauthentication,
                logintryId: res.data.logintryid,
                accessToken: res.data.accessToken,
                idToken: res.data.idToken,
              });
            } else if (res.data && res.data.error != null) {
              return AuthActions.authFailure({ error: new AuthResult(false, null, new AuthError(res.data.error, null)) });
            } else {
              return AuthActions.authFailure({
                error: new AuthResult(false, null, new AuthError('Something went wrong')),
              });
            }
          }),
          catchError((err) => {
            let error: AuthResult = null;
            if (err instanceof HttpErrorResponse) {
              error = new AuthResult(false, err, new AuthError(err.error.errorKey, err.error.errorMessage));
            } else if (err instanceof AuthIllegalJWTTokenError) {
              error = new AuthResult(false, err, new AuthError('err.error.errorKey', err.message));
            } else {
              error = new AuthResult(false, err, new AuthError(err.message ? err.message : 'Something went wrong'));
            }
            return of(AuthActions.authFailure({ error }));
          })
        )
      )
    )
  );

  authenticationTenantAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authTenantAdmin),
      concatMap(({ auth }) =>
        this.authService.authenticateTenantAdmin(auth).pipe(
          map((res) => {
            if (res.data && res.data.token) {
              const token = new AuthToken(res.data.token);
              if (!token.isValid()) {
                throw new AuthIllegalJWTTokenError('Token is empty or invalid.');
              }
              const result = new AuthResult(true, res, null, token);
              this.tokenService.set(result.getToken(), null);
              return AuthActions.authSuccessTenantAdmin({ result });
            } else {
              throwError('Something went wrong.');
            }
          }),
          catchError((err) => {
            let error: AuthResult = null;
            if (err instanceof HttpErrorResponse) {
              error = new AuthResult(false, err, new AuthError(err.error.errorKey, err.error.errorMessage));
            } else if (err instanceof AuthIllegalJWTTokenError) {
              error = new AuthResult(false, err, new AuthError('err.error.errorKey', err.message));
            } else {
              error = new AuthResult(false, err, new AuthError(err.message ? err.message : 'Something went wrong'));
            }
            return of(AuthActions.authFailureTenantAdmin({ error }));
          })
        )
      )
    )
  );

  demoAutoAutentication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.demoAutoAuthenticate),
      concatMap(({ token }) => {
        const authtoken = new AuthToken(token);
        if (!authtoken.isValid()) {
          throw new AuthIllegalJWTTokenError('Token is empty or invalid.');
        }
        const result = new AuthResult(true, null, null, authtoken);
        this.tokenService.set(result.getToken(), null);
        return of(AuthActions.authSuccess({ result }));
      })
    )
  );

  demoAutoAuthSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.demoAutoAuthSuccess),
        tap(({ result }) => {
          this.router.navigateByUrl('');
        })
      ),
    { dispatch: false }
  );

  authAsUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authAsUser),
      concatMap(({ token }) => {
        const authtoken = new AuthToken(token);
        if (!authtoken.isValid()) {
          throw new AuthIllegalJWTTokenError('Token is empty or invalid.');
        }
        const result = new AuthResult(true, null, null, authtoken);
        this.tokenService.set(result.getToken(), null);
        return of(AuthActions.authSuccess({ result }));
      })
    )
  );

  authAsUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.authAsUserSuccess),
        tap(({ result }) => {
          this.router.navigateByUrl('');
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      concatMap(() => {
        const token = this.tokenService.getAuthToken();
        const idToken = this.tokenService.getOAuthToken();
        const logout = token.getOauthLogout();
        if (idToken && logout) {
          this.tokenService.clear();
          location.href = `${logout}?id_token_hint=${idToken}&post_logout_redirect_uri=${location.origin}`;
          this.router.navigate(['']);
          return of(AuthActions.logoutExecuted());
          // const iss = idToken.getTokenIss();
          // return this.authService.oauthEndpoint(iss).pipe(
          //   map((res) => {
          //     location.href = `${res.end_session_endpoint}?id_token_hint=${idToken}&post_logout_redirect_uri=${location.origin}`;
          //     return AuthActions.logoutExecuted();
          //   }),
          //   catchError((err) => {
          //     this.tokenService.clear();
          //     this.router.navigate(['/auth/login']);
          //     return of(AuthActions.logoutExecuted());
          //   })
          // );
        } else {
          this.tokenService.clear();
          this.router.navigate(['/auth/login']);
          return of(AuthActions.logoutExecuted());
        }
      })
    )
  );

  changePwd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changePwd),
      concatMap(({ changePwd }) =>
        this.authService.changePassword(changePwd).pipe(
          map((res) => {
            if (!res.data.state) {
              return AuthActions.changePwdFailure({ error: new AuthError(res.data.error) });
            }
            return AuthActions.changePwdSuccess();
          }),
          catchError((err) => {
            let error: AuthError = null;
            if (err instanceof HttpErrorResponse) {
              error = new AuthError(err.error.errorKey, err.error.errorMessage);
            } else if (err instanceof AuthIllegalJWTTokenError) {
              error = new AuthError(err.message);
            } else {
              error = new AuthError(err.message ? err.message : 'Something went wrong');
            }
            return of(AuthActions.changePwdFailure({ error }));
          })
        )
      )
    )
  );

  forgotPwd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgotPwd),
      concatMap(({ forgotPwd }) =>
        this.authService.forgotPassword(forgotPwd).pipe(
          map((res) => {
            if (!res.data) {
              throw new Error();
            }
            return AuthActions.forgotPwdSuccess();
          }),
          catchError((err) => {
            let error: AuthError = null;
            if (err instanceof HttpErrorResponse) {
              error = new AuthError(err.error.errorKey, err.error.errorMessage);
            } else if (err instanceof AuthIllegalJWTTokenError) {
              error = new AuthError('err.error.errorKey', err.message);
            } else {
              error = new AuthError(err.message ? err.message : 'Something went wrong');
            }
            return of(AuthActions.forgotPwdFailure({ error }));
          })
        )
      )
    )
  );

  resetPwd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPwd),
      concatMap(({ token, resetPwd }) =>
        this.authService.resetPassword(token, resetPwd).pipe(
          map((res) => {
            if (!res.data.state) {
              throw new Error(res.data.error);
            }
            return AuthActions.resetPwdSuccess();
          }),
          catchError((err) => {
            let error: AuthError = null;
            if (err instanceof HttpErrorResponse) {
              error = new AuthError(err.error.errorKey, err.error.errorMessage);
            } else if (err instanceof AuthIllegalJWTTokenError) {
              error = new AuthError('err.error.errorKey', err.message);
            } else {
              error = new AuthError(err.message ? err.message : 'Something went wrong');
            }
            return of(AuthActions.resetPwdFailure({ error }));
          })
        )
      )
    )
  );

  authSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.authSuccess),
        withLatestFrom(this.store.select(selectAuthAttempt)),
        tap(([{ result }, authAttempt]) => {
          if (authAttempt?.returnUrl) {
            this.router.navigateByUrl(authAttempt.returnUrl);
          } else {
            this.router.navigateByUrl(''); // homepage component
          }
        })
      ),
    { dispatch: false }
  );

  authSuccessTenantAdmin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.authSuccessTenantAdmin),
        tap(({ result }) => {
          this.router.navigateByUrl(''); // homepage component
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router
  ) {}
}
