import { ActionResponse } from 'src/app/models/action-response';
import { ConsumerService } from 'src/app/models/consumerservice';
import { Entity } from 'src/app/models/entity';
import { OrderMarketDataInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/order-market-data-wizard/init-step/init-step';

export interface State {
  initStep: OrderMarketDataInitStep;
  userSelectionStep: Entity;
  referenceProfileServicesStep: ConsumerService[];
  fineTuneServicesStep: number[];
  // serviceStep: FeedItem[];
  // addInfoStep: OrderMarketDataAdditionalInfo;
  newOrderResponse: ActionResponse;
  errorMessage: any;
}

export const initialState: State = {
  initStep: null,
  userSelectionStep: null,
  referenceProfileServicesStep: null,
  fineTuneServicesStep: null,
  // dueDatesStep: null,
  // serviceStep: null,
  // addInfoStep: null,
  newOrderResponse: null,
  errorMessage: null,
};
