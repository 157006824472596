import { Directive, EventEmitter, Output } from '@angular/core';

export interface Editor {
  canceledit: EventEmitter<any>;
  editcomplete: EventEmitter<any>;
  cellclick: EventEmitter<any>;
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class DefaultEditor implements Editor {
  @Output() canceledit = new EventEmitter<any>();
  @Output() editcomplete = new EventEmitter<any>();
  @Output() cellclick = new EventEmitter<any>();
}
