import { HttpParams } from '@angular/common/http';
import { Entity } from './entity';
import { PaginatedRequest } from './paginated-request';

export class Address extends Entity {
  addressAddress: string;
  addressAddress2: string;
  addressBusiness: string;
  addressBusinessId: number;
  addressBusinesspartner: string;
  addressBusinesspartnerId: number;
  addressCity: string;
  addressCountry: string;
  addressDepartement: string;
  addressEmail: string;
  addressFax: string;
  addressFirstname: string;
  addressId: number;
  addressLastname: string;
  addressMobile: string;
  addressPhone: string;
  addressPhoneDirect: string;
  addressPobox: string;
  addressRegion: string;
  addressRemarks: string;
  addressSalutation: string;
  addressSalutationId: number;
  addressTitle: string;
  addressWebpage: string;
  addressZip: string;
}

export class AddressParams {
  public static FILTER_BUSINESS_IDS = 'filterBusinessIds';
  public static FILTER_SALUTATION_IDS = 'filterSalutationIds';
  public static FILTER_BUSINESSPARTNER_IDS = 'filterBusinesspartnerIds';
}

export function getAddressRequestParams(request: PaginatedRequest): HttpParams {
  return PaginatedRequest.getBaseRequestParams(request);
}
