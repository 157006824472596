import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { catchError, concatMap, map, of, throwError, withLatestFrom } from 'rxjs';
import { DeskParams } from 'src/app/models/desk';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { DeskDataService } from 'src/app/services/desk-data.service';
import { MoveAllDesksWizardActions } from '.';
import { selectInitStep } from './selectors';
import { State } from './state';

@Injectable()
export class MoveAllDesksWizardEffects {
  loadAllBelongingDesks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoveAllDesksWizardActions.selectMovingDetailsStep),
      withLatestFrom(this.store.select(selectInitStep)),
      concatMap(([{ movingDetails }, initStep]) => {
        const request: PaginatedRequest = {
          pageIndex: 0,
          pageSize: null,
          orderBy: 'deskUser',
          sort: 'asc',
          filter: null,
          filterRule: null,
          filterMap: new Map<string, any[]>().set('filterEnds', [moment(movingDetails.moveDate).utc(true).format('YYYY-MM-DD')]),
        };

        if (initStep.organisationId != null) {
          request.filterMap.set(DeskParams.FILTER_ORGANISATION_IDS, [initStep.organisationId]);
        }
        if (initStep.costcentreId != null) {
          request.filterMap.set(DeskParams.FILTER_COSTCENTRE_IDS, [initStep.costcentreId]);
        }
        if (initStep.locationId != null) {
          request.filterMap.set(DeskParams.FILTER_LOCATION_IDS, [initStep.locationId]);
        }
        return this.deskDataervice.getDesks(request).pipe(
          map(({ data }) => {
            return MoveAllDesksWizardActions.loadAllBelongingDesks({ belongingDesks: data !== undefined ? data : [] });
          }),
          catchError((error) => {
            return of(
              MoveAllDesksWizardActions.loadAllBelongingDesks({
                belongingDesks: [],
              })
            );
          })
        );
      })
    )
  );

  newMoveAllDesks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoveAllDesksWizardActions.createNewMoveAllDesks),
      concatMap(({ request }) =>
        this.deskDataervice.moveAllDesks(request).pipe(
          map((response) => {
            if (response) {
              return MoveAllDesksWizardActions.createNewMoveAllDesksSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            const errorMessage = error;
            return of(MoveAllDesksWizardActions.createNewMoveAllDesksFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<State>, private deskDataervice: DeskDataService) {}
}
