import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { BaseResponse } from '../models/base-response';
import { PaginatedRequest } from '../models/paginated-request';
import { getPlatformRequestParams, Platform } from '../models/platform';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class PlatformDataService {
  getPlatforms(request: PaginatedRequest): Observable<BaseResponse<Platform[]>> {
    const m = this.getPlatforms.name;

    const path = environment.getEndpoint(ApiPath.Platforms.PLATFORMS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Platform[]>>(path, {
      params: getPlatformRequestParams(request),
    });

    return retVal;
  }

  getPlatformsSpreadsheet(request: PaginatedRequest) {
    const m = this.getPlatformsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Platforms.PLATFORMS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: getPlatformRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  constructor(private http: HttpClient) {}
}
