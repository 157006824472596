/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormFieldConfigurationItem } from 'src/app/models/forms/form-configuration';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { BaseFormFieldConfigInterface } from './base-form-field-config-interface';

@Directive()
export class BaseFormFieldConfigComponent extends FormatComponent implements OnInit, BaseFormFieldConfigInterface {
  @Input() isEditMode: boolean;
  @Input() public formFieldData: FormFieldConfigurationItem;
  @Output() itemClickEvEm = new EventEmitter();
  @Output() itemRemoveEvEm = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit(): void {
    LogService.debug(this, this.ngOnInit.name, '[formfieldId: ' + this.formFieldData.formfieldId + ']', null);
  }

  onItemClick() {
    if (this.isEditMode) {
      LogService.debug(this, this.onItemClick.name, '[Click on Item: ' + this.formFieldData.formfieldId + ']', this.formFieldData);
      this.itemClickEvEm.emit();
    }
  }

  remove() {
    LogService.debug(this, this.onItemClick.name, '[Remove Item: ' + this.formFieldData.formfieldId + ']', this.formFieldData);
    this.itemRemoveEvEm.emit();
    // this.formFieldData.formfieldRemoved = true;
    // this.formFieldData.formfieldW = 0;
    // this.formFieldData.formfieldH = 0;
  }

  // labelWidthPercentage() {
  //   if (this.formFieldData.formfieldLabelSize !== 0) {
  //     const percentage = (this.formFieldData.formfieldLabelSize / this.formFieldData.cols) * 100;
  //     // console.log(`LABEL WIDTH: ${this.formFieldData.formfieldLabelSize}, COLS: ${this.formFieldData.cols}, PERCENTAGE: ${percentage}`);
  //     return percentage;
  //   }
  //   if (this.formFieldData.formfieldLabelSize === 0) {
  //     return 0;
  //   } else {
  //     return 40;
  //   }
  // }
}
