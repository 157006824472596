import { ActionResponse } from 'src/app/models/action-response';
import { BillingitemCopyRequest } from 'src/app/models/billingitem';
import { BillingAccount } from 'src/app/models/vendor-account';
import { CopyBillingitemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-billingitems-wizard/activation-step/copybillingitems-due-date-info';
import { CopyBillingitemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-billingitems-wizard/init-step/init-step';

export interface State {
  initStep: CopyBillingitemsInitStep;
  dueDatesStep: CopyBillingitemsDueDates;
  billingaccountStep: BillingAccount[];
  request: BillingitemCopyRequest;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  dueDatesStep: null,
  billingaccountStep: null,
  request: null,
  response: null,
  errorMessage: null,
};
