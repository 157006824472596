import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { MoveAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-wizard/init-step/init-step';
import { MoveAssetMovingToStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-wizard/move-asset-movingto-step/move-asset-movingto-step';
import * as fromMoveAssetWizard from './reducer';
import { State } from './state';

export const selectMoveAssetWizardState = createFeatureSelector<State>(fromMoveAssetWizard.moveAssetWizardFeatureKey);

export const selectInitStep = createSelector(selectMoveAssetWizardState, (state: State): MoveAssetInitStep => state.initStep);

export const selectMovingToStep = createSelector(selectMoveAssetWizardState, (state: State): MoveAssetMovingToStep => state.movingTo);

export const selectMoveAssetState = createSelector(selectMoveAssetWizardState, (state: State): ActionResponse => state.response);

export const selectErrorMessage = createSelector(selectMoveAssetWizardState, (state: State): any => state.errorMessage);
