import { Directive, ElementRef, HostListener } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[matTooltip][showIfTruncated]',
})
export class ShowIfTruncatedDirective {
  constructor(private matTooltip: MatTooltip, private elementRef: ElementRef<HTMLElement>) {}

  @HostListener('mouseenter')
  setTooltipState() {
    const element = this.elementRef.nativeElement;
    this.matTooltip.disabled = element.scrollWidth <= element.clientWidth;
  }
}
