<div class="entity-sidenav-container multiselection-container">
  <div id="billingitems-table" class="entity-table">
    <div class="table-wrapper">
      <generic-dynamic-grid-search-elements
        *ngIf="searchElements"
        [gridName]="GRID_NAME"
        [searchElements]="searchElements"
        [lastUsedFilters]="lastUsedFilters"
        [filtersMap]="filtersMap"
        (setFilterEvEm)="applyFilterTable($event)"
        (setFilterRuleEvEm)="setFilterRule($event)"
        (setClipboardFilterEvEm)="setClipboardFilter($event)"
        (setSearchElementsEvEm)="setFilterElements($event)"
        (clearFilterEvEm)="clearSearchFilter()"
        (clearAllFiltersEvEm)="clearFilters()"
      ></generic-dynamic-grid-search-elements>
      <div class="table-filters" [ngClass]="enableFilter ? 'visible' : 'hidden'">
        <generic-dynamic-grid-filters
          *ngIf="dynamicFilters && dynamicFilters.length > 0"
          [dynamicFilters]="dynamicFilters"
          [disableDefaultFilter]="disableDefaultFilter"
          [clearFilterSubject]="clearFilterSubject.asObservable()"
          [filtersMapSubject]="filtersMapSubject"
          [gridName]="GRID_NAME"
          (filterIdsEvEm)="setDynamicFiltersIds($event)"
        ></generic-dynamic-grid-filters>
      </div>
      <div class="table-selection-container">
        <div class="table-buttons-container">
          <div class="selection-buttons">
            <button mat-raised-button color="primary" (click)="askAddAllFiltered()" [disabled]="isLoadingSelection">
              <span>{{ 'label_select_all' | translate }}</span>
            </button>
            <button
              mat-raised-button
              color="primary"
              (click)="addAllToSelection()"
              [disabled]="isLoadingSelection"
              style="margin-left: 0.5rem"
            >
              <span>{{ 'label_select_displayed' | translate }}</span>
            </button>
            <mat-progress-bar mode="indeterminate" *ngIf="isLoadingSelection"></mat-progress-bar>
            <button
              mat-raised-button
              color="primary"
              (click)="extraQuerySelectionEvEm.emit()"
              style="margin-left: auto"
              *ngIf="enableExtraQuerySelection"
            >
              <span>{{ extraQuerySelectionButtonKey | translate }}</span>
            </button>
          </div>
          <div class="table-container">
            <table
              class="table table-stripes table-truncate-text"
              mat-table
              [dataSource]="dataSourceTable"
              matSort
              #sortTable="matSort"
              [matSortActive]="gbGetInitialOrderBy()"
              [matSortDirection]="gbGetInitialSort()"
              matSortDisableClear
              cdkDropList
              cdkDropListSortingDisabled
              cdkDropListConnectedTo="generic-multigrid-multiselection-table"
              [cdkDropListData]="dataSourceTable"
            >
              <ng-container matColumnDef="selection">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                  >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? onCheckboxClicked(row) : null"
                    [checked]="selection.isSelected(row)"
                    [disabled]="isReadonly(row)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="billingitemService">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'entity_billingitem_service' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.billingitemService" *matCellDef="let row">{{ row.billingitemService }}</td>
              </ng-container>

              <ng-container matColumnDef="billingitemServiceCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'entity_billingitem_service_code' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.billingitemServiceCode" *matCellDef="let row">
                  {{ row.billingitemServiceCode }}
                </td>
              </ng-container>

              <ng-container matColumnDef="billingitemBiller">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'entity_billingitem_biller' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.billingitemBiller" *matCellDef="let row">{{ row.billingitemBiller }}</td>
              </ng-container>

              <ng-container matColumnDef="billingitemBillingaccount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'entity_billingitem_billingaccount' | translate }}
                </th>
                <td mat-cell showIfTruncated [matTooltip]="row.billingitemBillingaccount" *matCellDef="let row">
                  {{ row.billingitemBillingaccount }}
                </td>
              </ng-container>

              <ng-container matColumnDef="billingitemTooltip">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell class="tooltip-cell" *matCellDef="let row">
                  <mat-icon *ngIf="row.entityInfo" matTooltip="{{ row.entityInfo }}" [matTooltipClass]="'fo-mat-tooltip'">info</mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsTable"
                (click)="onRowClicked(row)"
                [ngClass]="selectedEntity.entityId === row.entityId ? 'selected-row' : ''"
                cdkDrag
                [cdkDragData]="row"
              >
                <!-- DRAG&DROP PREVIEW -->
                <mat-card class="drag-preview" *cdkDragPreview>
                  <mat-card-content class="card-content">
                    <span class="card-item-name"> {{ row.entityName }}</span>
                  </mat-card-content>
                </mat-card>
                <!-- DRAG&DROP PREVIEW -->
              </tr>
            </table>
            <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
              <mat-toolbar-row *ngIf="isLoading" class="spinner">
                <mat-spinner diameter="50"></mat-spinner>
              </mat-toolbar-row>
              <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
                <span>{{ 'billingitems_table_error_nodata' | translate }}</span>
              </mat-toolbar-row>
            </mat-toolbar>
            <mat-toolbar class="table-footer-toolbar">
              <mat-toolbar-row>
                <mat-paginator
                  [disabled]="isLoading"
                  #paginatorTable
                  [pageSizeOptions]="pageSizeList"
                  [length]="pageTotalElements"
                  class="reset-paginator"
                ></mat-paginator>
              </mat-toolbar-row>
            </mat-toolbar>
          </div>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <!-- SELECTION GRID -->
        <div class="filters-selections table-wrapper">
          <div class="filters-selections-title">
            <span> {{ 'report_saved_filters_selection' | translate }} ({{ selectionDataSourceTable.filteredData.length }})</span>
            <button mat-raised-button color="primary" (click)="askRemoveAllFiltered()" [disabled]="isLoadingSelection">
              <mat-icon>delete_outline</mat-icon>
              <span>{{ 'label_remove_all' | translate }}</span>
            </button>
          </div>
          <div
            id="generic-multigrid-multiselection-table"
            cdkDropList
            #genericMultigridMultiselectionTable="cdkDropList"
            [cdkDropListData]="selectionDataSourceTable"
            (cdkDropListDropped)="dropEntity($event)"
          >
            <div class="table-container">
              <table class="table table-stripes table-truncate-text" mat-table [dataSource]="selectionDataSourceTable">
                <ng-container matColumnDef="entity_name">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'entity_name' | translate }}
                  </th>
                  <td mat-cell showIfTruncated [matTooltip]="row.entityName" *matCellDef="let row">
                    {{ row.entityName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="entity_remove">
                  <th mat-header-cell *matHeaderCellDef class="icondelete-header"></th>
                  <td mat-cell class="icondelete-cell" *matCellDef="let row">
                    <mat-icon
                      *ngIf="!row.isReadonly"
                      (click)="removeEntityFromSelection(row)"
                      style="color: red"
                      fontSet="fas"
                      fontIcon="fa-times"
                    ></mat-icon>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="selectionDisplayedColumnsTable; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: selectionDisplayedColumnsTable"></tr>
              </table>
              <mat-toolbar
                class="table-footer-toolbar"
                *ngIf="
                  selectionDataSourceTable && selectionDataSourceTable.filteredData && selectionDataSourceTable.filteredData.length === 0
                "
              >
                <mat-toolbar-row
                  *ngIf="
                    selectionDataSourceTable && selectionDataSourceTable.filteredData && selectionDataSourceTable.filteredData.length === 0
                  "
                  class="no-data-message"
                >
                  <span>{{ 'report_filters_table_error_nodata' | translate }}</span>
                </mat-toolbar-row>
              </mat-toolbar>
            </div>
          </div>
        </div>
      </div>
      <!-- SELECTION GRID -->
    </div>
  </div>
</div>
