import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { Desk } from 'src/app/models/desk';
import { FeedItem } from 'src/app/models/feeditem';
import { AddAssetOrderRequest } from 'src/app/models/wizards/add-asset-order-request';
import { ReactivateAssetDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/reactivate-asset-wizard/activation-step/reactivate-asset-due-date-info';
import { ReactivateAssetAdditionalInfo } from 'src/app/shared-components/allocation-wizard-modal-dialogs/reactivate-asset-wizard/additional-step/additional-info';
import { ReactivateAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/reactivate-asset-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Asset Grid] Select Reactivate Init Information Step',
  props<{ initStep: ReactivateAssetInitStep }>()
);

export const selectAssetServicesInit = createAction(
  '[Reactivate Asset Init Step] Select Asset Services Init Information Step',
  props<{ initAssetServiceStep: any }>()
);

export const selectDueDatesStep = createAction(
  '[Reactivate Asset Due Date Step] Select Due Date Step',
  props<{ dueDatesStep: ReactivateAssetDueDates }>()
);

export const selectDeskStep = createAction('[Reactivate Asset Desk Step] Select Desk Step', props<{ deskStep: Desk }>());

export const selectProductStep = createAction('[Reactivate Asset Product Step] Select Product Step', props<{ productStep: FeedItem }>());

export const selectServiceStep = createAction(
  '[Reactivate Asset Service Step] Select Service Information Step',
  props<{ serviceStep: FeedItem[] }>()
);

export const deleteServiceStep = createAction('[Reactivate Asset Service Step] Delete Service Information Step');

export const selectAdditionalStep = createAction(
  '[Reactivate Asset AddInfo Step] Select Additional Information Step',
  props<{ addInfoStep: ReactivateAssetAdditionalInfo }>()
);

export const deleteAdditionalStep = createAction('[Reactivate Asset AddInfo Step] Delete Additional Information Step');

export const exitReactivateAssetWizard = createAction('[Reactivate Asset Wizard Page] Exit Allocation Reactivate Asset Wizard');

export const destroyWizard = createAction('[Reactivate Asset Wizard Page] Destroy Wizard');

export const createNewReactivateAsset = createAction(
  '[Reactivate Asset Confirmation Step] Reactivate Asset Information Step',
  props<{ newOrder: AddAssetOrderRequest }>()
);

export const createNewOrderSuccess = createAction(
  '[Reactivate Asset Confirmation Step] Reactivate Asset Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewOrderFail = createAction(
  '[Reactivate Asset Confirmation Step] Reactivate Asset Information Step Fail',
  props<{ errorMessage: string }>()
);
