import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import {
  BillingItemUsage,
  BillingItemUsagePeriod,
  BillingItemUsageRequest,
  BillingItemUsageRequestParams,
  getBillingItemUsageRequestParams,
} from '../models/billing-item-usage';
import { PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class UsageDataService {
  getUsagePeriods(billingitemId: number, assetId: number): Observable<BaseResponse<BillingItemUsagePeriod[]>> {
    const m = this.getUsagePeriods.name;

    const path = environment.getEndpoint(ApiPath.Usages.USAGE_PERIODS);
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams();
    if (billingitemId != null) {
      params = params.append('billingitemId', billingitemId);
    }
    if (assetId != null) {
      params = params.append('assetId', assetId);
    }

    const retVal = this.http.get<BaseResponse<BillingItemUsagePeriod[]>>(path, { params });

    return retVal;
  }

  createUsagePeriod(billingitemId: number, assetId: number, starts: string, ends: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.createUsagePeriod.name;

    const path = environment.getEndpoint(ApiPath.Usages.USAGE_PERIODS);
    LogService.info(this, m, LogService.POST + path, null);
    let params = new HttpParams();
    if (billingitemId != null) {
      params = params.append('billingitemId', billingitemId);
    }
    if (assetId != null) {
      params = params.append('assetId', assetId);
    }

    if (starts && ends) {
      params = params.append('starts', starts);
      params = params.append('ends', ends);
    } else {
      throw Error('Missing required parameters');
    }

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  lockUsagePeriod(billingitemId: number, assetId: number, starts: string, ends: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.lockUsagePeriod.name;

    const path = environment.getEndpoint(ApiPath.Usages.USAGE_PERIODS_LOCK);
    LogService.info(this, m, LogService.POST + path, null);
    let params = new HttpParams();
    if (billingitemId != null) {
      params = params.append('billingitemId', billingitemId);
    }
    if (assetId != null) {
      params = params.append('assetId', assetId);
    }

    if (starts && ends) {
      params = params.append('starts', starts);
      params = params.append('ends', ends);
    } else {
      throw Error('Missing required parameters');
    }

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  unlockUsagePeriod(billingitemId: number, assetId: number, starts: string, ends: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.unlockUsagePeriod.name;

    const path = environment.getEndpoint(ApiPath.Usages.USAGE_PERIODS_UNLOCK);
    LogService.info(this, m, LogService.POST + path, null);
    let params = new HttpParams();
    if (billingitemId != null) {
      params = params.append('billingitemId', billingitemId);
    }
    if (assetId != null) {
      params = params.append('assetId', assetId);
    }

    if (starts && ends) {
      params = params.append('starts', starts);
      params = params.append('ends', ends);
    } else {
      throw Error('Missing required parameters');
    }

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getBillingItemsUsages(
    request: PaginatedRequest,
    billingitemId: number,
    assetId: number,
    starts: string,
    ends: string
  ): Observable<BaseResponse<BillingItemUsage[]>> {
    const m = this.getBillingItemsUsages.name;

    const path = environment.getEndpoint(ApiPath.Usages.USAGES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'starts: ', starts);
    LogService.info(this, m, 'ends: ', ends);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<BillingItemUsage[]>>(path, {
      params: getBillingItemUsageRequestParams(request, billingitemId, assetId, starts, ends),
    });

    return retVal;
  }

  saveUsages(request: BillingItemUsageRequest[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.saveUsages.name;

    const path = environment.getEndpoint(ApiPath.Usages.USAGES);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  deleteUsages(usageIds: number[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.deleteUsages.name;

    const path = environment.getEndpoint(ApiPath.Usages.USAGES);
    LogService.info(this, m, LogService.DELETE + path, null);
    const params = new HttpParams().set('usageIds', usageIds.join(','));
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path, { params });

    return retVal;
  }

  getShowUsageSpreadsheet(request: PaginatedRequest, assetIds: number[], billingItemIds: number[], starts: string, ends: string) {
    const m = this.getShowUsageSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Usages.USAGES_SHOW_USAGES);
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    if (assetIds != null && assetIds.length > 0) {
      params = params.append('filterAssetIds', assetIds.join(','));
    }
    if (billingItemIds != null && billingItemIds.length > 0) {
      params = params.append('filterBillingitemIds', billingItemIds.join(','));
    }
    if (starts && ends) {
      params = params.append(BillingItemUsageRequestParams.FILTER_STARTS, starts);
      params = params.append(BillingItemUsageRequestParams.FILTER_ENDS, ends);
    } else {
      throw Error('Missing required parameters');
    }

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  constructor(private http: HttpClient) {}
}
