export interface State {
  globalDateFilter?: Date;
  configurationGlobalDateFilter?: Date;
  isConfiguration?: boolean;
}

export const initialState: State = {
  globalDateFilter: new Date(),
  configurationGlobalDateFilter: null,
  isConfiguration: false,
};
