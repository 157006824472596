<div class="mat-calendar-custom-header">
  <button mat-icon-button class="mat-calendar-custom-double-arrow" (click)="previousClicked('year')">
    <mat-icon class="mat-calendar-custom-icon">keyboard_arrow_left</mat-icon>
    <mat-icon class="mat-calendar-custom-icon">keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button (click)="previousClicked('month')">
    <mat-icon class="mat-calendar-custom-icon">keyboard_arrow_left</mat-icon>
  </button>
  <span class="example-header-label">{{ periodLabel }}</span>

  <button mat-icon-button class="mat-calendar-custom-now" (click)="todayClicked()">
    <mat-icon class="mat-calendar-custom-icon">access_time</mat-icon>
  </button>

  <button mat-icon-button (click)="nextClicked('month')">
    <mat-icon class="mat-calendar-custom-icon">keyboard_arrow_right</mat-icon>
  </button>
  <button mat-icon-button class="mat-calendar-custom-double-arrow" (click)="nextClicked('year')">
    <mat-icon class="mat-calendar-custom-icon">keyboard_arrow_right</mat-icon>
    <mat-icon class="mat-calendar-custom-icon">keyboard_arrow_right</mat-icon>
  </button>
</div>

<!-- <div class="mat-calendar-header">
  <div class="mat-calendar-controls">
    <button mat-button type="button" class="mat-calendar-period-button"
            (click)="currentPeriodClicked()" [attr.aria-label]="periodButtonLabel"
            [attr.aria-describedby]="_buttonDescriptionId"
            cdkAriaLive="polite">
      <span [attr.id]="_buttonDescriptionId">{{periodButtonText}}</span>
      <div class="mat-calendar-arrow"
           [class.mat-calendar-invert]="calendar.currentView !== 'month'"></div>
    </button>

    <div class="mat-calendar-spacer"></div>

    <ng-content></ng-content>

    <button mat-icon-button type="button" class="mat-calendar-previous-button"
            [disabled]="!previousEnabled()" (click)="previousClicked()"
            [attr.aria-label]="prevButtonLabel">
    </button>

    <button mat-icon-button type="button" class="mat-calendar-next-button"
            [disabled]="!nextEnabled()" (click)="nextClicked()"
            [attr.aria-label]="nextButtonLabel">
    </button>
  </div>
</div> -->
