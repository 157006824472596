<div class="base-form-field form-field-mailtemplate" (click)="onItemClick()">
  <div class="mailtemplate-row">
    <div class="base-form-field form-field-inputfield onethird">
      <div class="label-container">
        <span class="label">{{ 'mailbody_languageid' | translate }}</span>
      </div>
      <div class="value-container">
        <span class="value">
          <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
            <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
          </mat-form-field>
        </span>
      </div>
    </div>
    <div class="base-form-field form-field-inputfield onethird">
      <div class="label-container">
        <span class="label">{{ 'mailbody_stage' | translate }}</span>
      </div>
      <div class="value-container">
        <span class="value">
          <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
            <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
          </mat-form-field>
        </span>
      </div>
    </div>
  </div>
  <div class="mailtemplate-row">
    <div class="base-form-field form-field-inputfield">
      <div class="label-container">
        <span class="label">{{ 'mailbody_subject' | translate }}</span>
        <span class="requiredField">*</span>
      </div>
      <div class="value-container">
        <span class="value">
          <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
            <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
          </mat-form-field>
        </span>
      </div>
    </div>
  </div>
  <div class="mailtemplate-editor-row">
    <div class="base-form-field form-field-inputfield multiline">
      <div class="label-container">
        <span class="label">{{ 'mailbody_body' | translate }}</span>
        <span class="requiredField">*</span>
      </div>
      <div class="value-container">
        <span class="value"
          ><ckeditor
            #ckeditor
            [editor]="Editor"
            [(ngModel)]="formFieldData.formfieldEntityText"
            [disabled]="true"
            [config]="config"
          ></ckeditor>
        </span>
      </div>
    </div>
  </div>
  <button class="remove-button" (click)="remove(); $event.stopPropagation()" mat-icon-button *ngIf="isEditMode">
    <mat-icon>close</mat-icon>
  </button>
</div>
