/* eslint-disable @angular-eslint/directive-selector */
import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { AccessMode } from 'src/app/configs/access-mode';
import { ForeignEntity } from 'src/app/models/foreign-entity';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { CoreDataService } from 'src/app/services/core-data.service';

@Directive({
  selector: '[matInputAutocompleteFromApi]',
})
export class MatInputAutocompleteFromApiDirective implements OnInit, OnDestroy {
  private search$: Subject<string> = new Subject<string>();
  private alive = true;
  @Output() matInputAutocompleteFromApi = new EventEmitter<ForeignEntity[]>();
  @Input() debounceTime = 600;
  @Input() customPath?: string;
  @Input() entityKind?: string;
  @Input() filterEntityKind?: string;
  @Input() filterEntityId?: number;
  @Input() filterColumn1?: string;
  @Input() filterColumn1Values?: number[];
  @Input() isTenantAdmin?: boolean;
  request: PaginatedRequest;

  constructor(private coreDataService: CoreDataService) {}

  ngOnInit() {
    this.request = { ...new PaginatedRequest(), pageSize: 10, orderBy: 'entityName' };
    this.search$
      .pipe(
        takeWhile(() => this.alive),
        debounceTime(this.debounceTime),
        distinctUntilChanged()
      )
      .subscribe((query: string) => {
        // this.autocompleteOptions.emit(query);
        document.body.classList.add('waiting-mouse-cursor');
        this.request.filter = query;
        const apiPathPrefix = this.isTenantAdmin ? 'api/tenantadministration/entities' : 'api/entities';
        const apiPath = this.entityKind
          ? this.filterEntityKind && this.filterEntityId
            ? `${apiPathPrefix}/${this.entityKind}?accessmode=${AccessMode.link}&filterEntityKind=${this.filterEntityKind}&filterEntityId=${this.filterEntityId}`
            : this.filterColumn1 && this.filterColumn1Values
            ? `${apiPathPrefix}/${this.entityKind}?accessmode=${AccessMode.link}&filterColumn=${
                this.filterColumn1
              }&filterColumnValue=${this.filterColumn1Values.join(',')}`
            : `${apiPathPrefix}/${this.entityKind}?accessmode=${AccessMode.link}`
          : this.customPath;
        this.coreDataService
          .getForeignEntity(apiPath, this.request)
          .pipe(takeWhile(() => this.alive))
          .subscribe(({ data }) => {
            // console.log('getForeignEntity.subscribe', data);
            this.matInputAutocompleteFromApi.emit(data ? data : []);
            document.body.classList.remove('waiting-mouse-cursor');
          });
      });
  }

  ngOnDestroy() {
    this.alive = false;
    this.search$.complete();
  }

  @HostListener('input', ['$event'])
  filter(event) {
    this.search$.next(event.target.value);
  }

  @HostListener('paste', ['$event'])
  filterByPaste(event) {
    this.search$.next(event.target.value);
  }
}
