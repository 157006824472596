import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Entity } from 'src/app/models/entity';
import { NotificationCount } from 'src/app/models/notification';

export const featureAdapter: EntityAdapter<Entity> = createEntityAdapter<Entity>({
  selectId: (model) => model.entityId,
  sortComparer: false,
});

export interface State extends EntityState<Entity> {
  count: NotificationCount;
  isFinished: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  count: { countNotification: 0, countEmail: 0, countTaricsEntitlement: null, toastrMessages: null },
  isFinished: false,
  error: null,
});
