import { ActionResponse } from 'src/app/models/action-response';
import { Desk } from 'src/app/models/desk';
import { MoveAllDesksRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-all-desks-wizard/confirmation-step/move-all-desks-request';
import { MoveAllDesksInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-all-desks-wizard/init-step/init-step';
import { MoveAllDesksMovingDetails } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-all-desks-wizard/moving-details-step/move-all-desks-moving-details-step';

export interface State {
  initStep: MoveAllDesksInitStep;
  belongingDesks: Desk[];
  movingDetails: MoveAllDesksMovingDetails;
  consumersStep: Desk[];
  request: MoveAllDesksRequest;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  belongingDesks: null,
  movingDetails: null,
  consumersStep: null,
  request: null,
  response: null,
  errorMessage: null,
};
