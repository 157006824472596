import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { AccessMode } from '../configs/access-mode';
import { Entity } from './entity';

export class FeedItem extends Entity {
  deleted?: boolean;
  feeditemCode: string;
  feeditemId: number;
  feeditemName: string;
  feeditemPlatform: string;
  feeditemIssuer: string;
  feeditemIssuerId: number;
  feeditemProductFamily: string;
  feeditemProductFamilyId: number;
  feeditemProvider: string;
  feeditemProviderId: number;
  feeditemService: string;
  feeditemServiceCode: string;
  feeditemServiceId: number;
  feeditemServiceProviderCode: string;
  feeditemServiceProviderId: number;
  feeditemServiceProviderName: string;
  feeditemServiceType: string;
  feeditemSite: string;
  feeditemProductUseMainBillingAccount: boolean;
  feeditemServiceCatalogItemCategory: string;
  feeditemServiceCatalogItemCategoryId: number;
  feeditemServiceCatalogItemKind: string;
  feeditemServiceCatalogItemKindId: number;
  feeditemServiceCatalogItemType: string;
  feeditemServiceCatalogItemTypeId: number;
}

export class DeliveryitemParticulars {
  deliveryitemActivationDate: Date;
  deliveryitemDeactivationDate: Date;
  deliveryitemEid: string;
  deliveryitemExport: boolean;
  deliveryitemId: number;
  deliveryitemActive: boolean;
  deliveryitemNetted: boolean;
  deliveryitemOnPermissioningsystem: boolean;
  deliveryitemPermissioningsystem: string;
  deliveryitemPermissioningsystemId: number;
  deliveryitemProductfamily: string;
  deliveryitemProductfamilyId: number;
  deliveryitemProvider: string;
  deliveryitemProviderAccount: string;
  deliveryitemProviderId: number;
  deliveryitemProviderMainService: string;
  deliveryitemProviderType: string;
  deliveryitemProvideralias: string;
  deliveryitemProvideraliasId: number;
  deliveryitemService: string;
  deliveryitemServiceId: number;
  deliveryitemServicealias: string;
  deliveryitemServicealiasId: number;
  deliveryitemSite: string;
  deliveryitemSiteId: number;
  deliveryitemStatus: string;
  deliveryitemStatusId: number;
  deliveryitemSubscribed: boolean;
  public constructor(init?: Partial<DeliveryitemParticulars>) {
    Object.assign(this, init);
  }
}

export class DeliveryitemParticularsRequest {
  deliveryitemActivationDate: string;
  deliveryitemDeactivationDate: string;
  deliveryitemEid: string;
  deliveryitemExport: boolean;
  deliveryitemActive: boolean;
  deliveryitemNetted: boolean;
  deliveryitemOnPermissioningsystem: boolean;
  deliveryitemPermissioningsystemId: number;
  deliveryitemProductfamilyId: number;
  deliveryitemProviderAccount: string;
  deliveryitemProviderMainService: string;
  deliveryitemProviderType: string;
  deliveryitemProvideraliasId: number;
  deliveryitemServicealiasId: number;
  deliveryitemSiteId: number;
  deliveryitemStatusId: number;
  deliveryitemSubscribed: boolean;
}

export class FeeditemReplaceRequest {
  replaceDate: Date;
  servicealiasIds: number[];
}

export class LicenseServicesRequest extends PaginatedRequest {
  licenseId?: number;
  excludeLicenseId?: number;
  productId?: number;
  productserviceId?: number;
  productfamilyIds?: number[];
  filterCatalogitemtypeIds?: number[];
  deskId?: number;
  filterDate?: Date;

  static appendParamToBaseRequestParams(request: LicenseServicesRequest, baseRequest: HttpParams) {
    if (request) {
      if (request.licenseId) {
        baseRequest = baseRequest.append(FeedItemParams.FILTER_LICENSE_IDS, request.licenseId.toString());
      }
      if (request.productId) {
        baseRequest = baseRequest.append(FeedItemParams.FILTER_PRODUCT_IDS, request.productId.toString());
      }
      if (request.productserviceId) {
        baseRequest = baseRequest.append(FeedItemParams.FILTER_PRODUCTSERVICE_IDS, request.productserviceId.toString());
      }
      if (request.productfamilyIds) {
        baseRequest = baseRequest.append(FeedItemParams.FILTER_PRODUCTFAMILY_IDS, request.productfamilyIds.join(','));
      }
      if (request.filterCatalogitemtypeIds) {
        baseRequest = baseRequest.append(FeedItemParams.FILTER_CATALOGITEM_TYPE_IDS, request.filterCatalogitemtypeIds.join(','));
      }
      baseRequest = baseRequest.append(FeedItemParams.FILTER_ISBASESERVICE_IDS, '0');
      if (request.excludeLicenseId) {
        baseRequest = baseRequest.append(FeedItemParams.FILTER_EXCLUDE_LICENSE_IDS, request.excludeLicenseId.toString());
      }
      if (request.deskId) {
        baseRequest = baseRequest.append(FeedItemParams.FILTER_DESK_IDS, request.deskId.toString());
      }
      if (request.filterDate) {
        baseRequest = baseRequest.append(
          FeedItemParams.FILTER_STARTDATE,
          request.filterDate === null || request.filterDate === undefined
            ? null
            : moment(request.filterDate).isValid()
              ? moment(request.filterDate).format('YYYY-MM-DD')
              : null
        );
      }
    }
    baseRequest = baseRequest.append(FeedItemParams.FILTER_ACCESSMODE, AccessMode.none.toString());
    return baseRequest;
  }
}

export function getFeedItemRequestParams(request: PaginatedRequest): HttpParams {
  return PaginatedRequest.getBaseRequestParams(request);
}

export class FeedItemParams {
  public static FILTER_ACCESSMODE = 'accessmode';
  public static FILTER_SITE_IDS = 'filterSiteIds';
  public static FILTER_PLATFORM_IDS = 'filterPlatformIds';
  public static FILTER_STATUS_IDS = 'filterStatusIds';
  public static FILTER_LICENSE_IDS = 'filterLicenseIds';
  public static FILTER_EXCLUDE_LICENSE_IDS = 'filterExcludeLicenseIds';
  public static FILTER_PRODUCT_IDS = 'filterProductIds';
  public static FILTER_PRODUCTSERVICE_IDS = 'filterProductserviceIds';
  public static FILTER_PRODUCTFAMILY_IDS = 'filterProductfamilyIds';
  public static FILTER_DESK_IDS = 'filterExcludeDeskIds';
  public static FILTER_ISBASESERVICE_IDS = 'filterIsbaseserviceIds';
  public static FILTER_RELATED_LICENSE_IDS = 'filterRelatedByLicenseIds';
  public static FILTER_RELATED_FOR_ADD = 'filterRelatedForAdd';
  public static FILTER_PROVIDER_IDS = 'filterProviderIds';
  public static filter_issuer_IDS = 'filterIssuerIds';
  public static FILTER_SERVICETYPE_IDS = 'filterServicetypeIds';
  public static FILTER_SERVICEKIND_IDS = 'filterServicekindIds';
  public static FILTER_STARTDATE = 'filterStarts';
  public static FILTER_ENDDATE = 'filterEnds';
  public static FILTER_CATALOGITEM_TYPE_IDS = 'filterCatalogitemtypeIds';
  public static FILTER_ENABLED_FOR_ORDER_IDS = 'filterEnabledfororderIds';
}
