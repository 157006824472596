import { Directive, HostListener } from '@angular/core';
import { EditableComponent } from './editable.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[resetOnEsc]',
})
export class ResetOnEscDirective {
  constructor(private editable: EditableComponent) {}

  @HostListener('keyup.escape', ['$event'])
  onEnter(e: KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.editable.toViewMode(false);
  }
}
