<div class="alert-wrapper" [ngClass]="status">
  <div class="icon">
    <div [ngSwitch]="status">
      <i *ngSwitchCase="'error'" class="fa fa-exclamation fa-2x icon"></i>
      <i *ngSwitchCase="'warning'" class="fa fa-question fa-2x icon"></i>
      <i *ngSwitchCase="'success'" class="fa fa-check fa-2x icon"></i>
    </div>
  </div>
  <div class="message">
    {{ message }}
  </div>
  <div class="button" *ngIf="showButton">
    &nbsp;&nbsp;
    <button mat-raised-button color="primary" (click)="buttonClickEvEm.emit()">
      {{ buttonKey | translate }}
    </button>
  </div>
  <div *ngIf="tooltip" class="tooltip">
    <i class="fa fa-info-circle fa-2x icon" [matTooltip]="tooltip"></i>
  </div>
</div>
