import { Action, createReducer, on } from '@ngrx/store';
import * as NotificationsStoreActions from './actions';
import { State, initialState } from './state';

export const notificationsStoreFeatureKey = 'notificationsStore';

const notificationsStoreReducer = createReducer(
  initialState,

  on(NotificationsStoreActions.startPollingNotificationsCount, (state) => ({
    ...state,
    isFinished: false,
    count: { countNotification: 0, countEmail: 0, countTaricsEntitlement: null, toastrMessages: null },
  })),
  on(NotificationsStoreActions.refreshNotificationCount, (state) => ({ ...state })),
  on(NotificationsStoreActions.storePollingNotificationsCount, (state, { count }) => ({ ...state, count })),
  on(NotificationsStoreActions.notificationsError, (state, { error }) => ({
    ...state,
    error,
    isFinished: false,
    count: { countNotification: 0, countEmail: 0, countTaricsEntitlement: null, toastrMessages: null },
  })),
  on(NotificationsStoreActions.finishPollingNotificationsCount, (state) => ({
    ...state,
    isFinished: true,
    count: { countNotification: 0, countEmail: 0, countTaricsEntitlement: null, toastrMessages: null },
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return notificationsStoreReducer(state, action);
}
