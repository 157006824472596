import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { FormFieldDataRequest, GenericFormField } from '../models/forms/form-field';
import { FormWizard, FormWizardResponse } from '../models/forms/form-wizard';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  createFormWizard(wizardName: string): Observable<BaseResponse<FormWizard>> {
    const m = this.createFormWizard.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD);
    LogService.info(this, m, LogService.POST + path, null);
    const params = new HttpParams().set('wizardName', wizardName);
    const retVal = this.http.post<BaseResponse<FormWizard>>(path, null, { params });

    return retVal;
  }

  cancelFormWizard(wizardId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.createFormWizard.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD);
    LogService.info(this, m, LogService.DELETE + path, null);
    const params = new HttpParams().set('wizardId', wizardId.toString());
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path, { params });

    return retVal;
  }

  getFormWizardStepData(wizardId: number, step: number): Observable<BaseResponse<GenericFormField[]>> {
    const m = this.getFormWizardStepData.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD_STEP);
    LogService.info(this, m, LogService.GET + path, null);
    const params = new HttpParams().set('wizardId', wizardId.toString()).set('step', step.toString());
    const retVal = this.http.get<BaseResponse<GenericFormField[]>>(path, { params });

    return retVal;
  }

  putFormWizardStepData(
    wizardId: number,
    step: number,
    request: FormFieldDataRequest[],
    isApply?: boolean
  ): Observable<BaseResponse<FormWizardResponse>> {
    const m = this.getFormWizardStepData.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD_STEP);
    LogService.info(this, m, LogService.PUT + path, null);
    let params = new HttpParams().set('wizardId', wizardId.toString()).set('step', step.toString());
    if (isApply) {
      params = params.append('applyOnly', 'true');
    }
    const retVal = this.http.put<BaseResponse<FormWizardResponse>>(path, request, { params });

    return retVal;
  }

  commitFormData(wizardId: number): Observable<BaseResponse<FormWizardResponse>> {
    const m = this.commitFormData.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD_COMMIT);
    LogService.info(this, m, LogService.POST + path, null);
    let params = new HttpParams().set('wizardId', wizardId.toString());
    const retVal = this.http.post<BaseResponse<FormWizardResponse>>(path, null, { params });

    return retVal;
  }

  getValidationStatus(wizardId: number): Observable<BaseResponse<FormWizardResponse>> {
    const m = this.getValidationStatus.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD_VALIDATION);
    const params = new HttpParams().set('wizardId', wizardId.toString());
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.PARAMS, params);
    const retVal = this.http.get<BaseResponse<FormWizardResponse>>(path, {
      params,
    });
    return retVal;
  }

  getFinishStatus(wizardId: number): Observable<BaseResponse<FormWizardResponse>> {
    const m = this.getFinishStatus.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD_WRITE);
    const params = new HttpParams().set('wizardId', wizardId.toString());
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.PARAMS, params);
    const retVal = this.http.get<BaseResponse<FormWizardResponse>>(path, {
      params,
    });
    return retVal;
  }

  constructor(private http: HttpClient) {}
}
