import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { Desk, DeskCostcentreChangeRequest } from 'src/app/models/desk';
import { ChangeCostcentreAllocationsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/change-costcentre-allocations-wizard/activation-step/change-costcentre-allocations-due-date-info';
import { ChangeCostcentreAllocationsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/change-costcentre-allocations-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Change Costcentre Allocation Information Step',
  props<{ initStep: ChangeCostcentreAllocationsInitStep }>()
);

export const selectDueDatesStep = createAction(
  '[Wizard Due Date Step] Select Change Costcentre Allocation Due Date Step',
  props<{ dueDatesStep: ChangeCostcentreAllocationsDueDates }>()
);

export const selectDestinationStep = createAction(
  '[Wizard Due Date Step] Select Change Costcentre Allocation Destination Step',
  props<{ allocationStep: Desk[] }>()
);

export const exitChangeCostcentreAllocationsWizard = createAction(
  '[ChangeCostcentreAllocations Wizard Page] Exit Change Costcentre Allocation Wizard'
);

export const destroyWizard = createAction('[ChangeCostcentreAllocations Wizard Page] Destroy Wizard');

export const createNewChangeCostcentreAllocations = createAction(
  '[Wizard Confirmation Step] Create Change Costcentre Allocation Information Step',
  props<{ request: DeskCostcentreChangeRequest }>()
);

export const createNewChangeCostcentreAllocationsSuccess = createAction(
  '[Wizard Confirmation Step] Create Change Costcentre Allocation Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewChangeCostcentreAllocationsFail = createAction(
  '[Wizard Confirmation Step] Create Change Costcentre Allocation Information Step Fail',
  props<{ errorMessage: string }>()
);
