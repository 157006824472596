<div id="file-tree-view-table" class="entity-table">
  <div class="table-wrapper">
    <div class="table-container" [ngClass]="dataSource && dataSource.data.length > 0 ? '' : 'no-border'">
      <table
        class="table table-stripes table-truncate-text no-header-table"
        mat-table
        [dataSource]="dataSource"
        [ngStyle]="{ visibility: !isLoading ? '' : 'hidden' }"
      >
        <ng-container matColumnDef="entityName">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" draggable="true" (dragstart)="handleDragStart($event, row)">
            <div class="toggle-checkbox-name">
              <div class="icon-toggle-cell">
                <button
                  mat-icon-button
                  [style.visibility]="!row.expandable ? 'hidden' : ''"
                  [style.marginLeft.px]="row.level * 16"
                  (click)="treeControl.toggle(row); $event.stopPropagation()"
                >
                  <mat-icon class="mat-icon-rtl-mirror expand-icon">
                    {{ treeControl.isExpanded(row) ? 'remove' : 'add' }}
                  </mat-icon>
                </button>
              </div>
              <span class="toggle-name" showIfTruncated [matTooltip]="row.item.entityName" [ngClass]="row.isLoading ? 'loading' : ''">{{
                row.item.entityName
              }}</span>
              <mat-progress-bar *ngIf="row.isLoading" mode="indeterminate" class="example-tree-progress-bar"> </mat-progress-bar>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsTable"
          (click)="onClick(row)"
          [ngClass]="[
            selectedNode?.item.entityId === row.item.entityId ? 'selected-row' : '',
            dragNode === row ? 'selected-row' : '',
            getStyle(row)
          ]"
          (dragover)="handleDragOver($event, row)"
          (dragend)="handleDragEnd($event)"
          (drop)="handleDrop($event, row)"
        ></tr>
      </table>
      <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
        <mat-toolbar-row *ngIf="isLoading" class="spinner">
          <mat-spinner diameter="50"></mat-spinner>
        </mat-toolbar-row>
        <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
          <span>{{ 'generic_table_error_nodata' | translate }}</span>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  </div>
</div>
