import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { interval, switchMap, takeWhile } from 'rxjs';
import { Entity } from 'src/app/models/entity';
import { EntityWizardData } from 'src/app/models/forms/entity-wizard';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

export interface RemoveRecordsDialogData {
  entities: Entity[];
  isTenantAdmin?: boolean;
  customDelete: boolean;
  customDeleteApipath?: string;
}

@Component({
  selector: 'app-remove-records-modal',
  templateUrl: './remove-records-modal.component.html',
  styleUrls: ['./remove-records-modal.component.scss'],
})
export class RemoveRecordsModalComponent extends FormatComponent implements OnInit {
  dataSourceTable: MatTableDataSource<EntityWizardData> = new MatTableDataSource<EntityWizardData>();
  displayedColumnsTable: string[] = ['entityKind', 'entityName', 'entityDescription'];
  isDeleting = false;
  isProgressing = false;
  progressPercentage = 0;
  progressCompleted = false;
  progressSuccess = false;
  deletePrecastResults: EntityWizardData[] = [];
  progressErrorMessage: string;
  importId: number;

  constructor(
    public dialogRef: MatDialogRef<RemoveRecordsModalComponent>,
    private coreDataService: CoreDataService,
    private messageNotifierService: MessageNotifierService,
    @Inject(MAT_DIALOG_DATA) public data: RemoveRecordsDialogData
  ) {
    super();
  }

  ngOnInit() {
    LogService.debug(this, this.ngOnInit.name, 'RemoveRecordsDialogData', this.data);
    this.isProgressing = true;
    this.subscribe(
      this.coreDataService.startDeletionPrecast(
        this.data.entities.map((e) => {
          return { entityId: e.entityId, entityKind: e.entityKind };
        }),
        this.data.isTenantAdmin
      ),
      ({ data }) => {
        if (data && data.state) {
          this.importId = data.id;
          this.getDeletePrecastStatus();
        } else {
          // ERROR
          this.isProgressing = false;
        }
      },
      (error) => {
        // ERROR
        this.isProgressing = false;
      }
    );
  }

  getDeletePrecastStatus() {
    this.dataSourceTable = new MatTableDataSource(this.deletePrecastResults);
    this.subscribe(
      interval(2000).pipe(
        takeWhile(() => !this.progressCompleted),
        switchMap(() => this.coreDataService.getDeletionPrecastStatus(this.importId, this.data.isTenantAdmin))
      ),
      (response) => {
        if (response.data) {
          if (response.data.data && response.data.data.length > 0) {
            this.dataSourceTable.data.push(...response.data.data);
            this.dataSourceTable._updateChangeSubscription();
          } else {
            // only for dev
            // for (let index = 0; index < 1; index++) {
            //   this.dataSourceTable.data.push(`Progess message ${this.progressPercentage}`);
            // }
            // this.dataSourceTable._updateChangeSubscription();
            // this.scrollToBottom();
          }
          if (!response.data.error) {
            this.progressPercentage = response.data.progress;
            this.progressCompleted = response.data.state;
            if (this.progressCompleted && !this.progressErrorMessage) {
              // do something
              this.progressSuccess = true;
              this.isProgressing = false;
            }
          } else {
            this.progressErrorMessage = response.data.error;
            this.progressPercentage = 100;
            this.progressCompleted = true;
            this.isProgressing = false;
          }
        }
      },
      (error) => {
        this.progressErrorMessage = _('label_internal_server_error');
        this.isProgressing = false;
      }
    );
  }

  onCancel() {
    this.subscribe(
      this.coreDataService.stopDeletionPrecast(this.importId),
      ({ data }) => {
        this.progressCompleted = true;
        this.isProgressing = false;
        this.dialogRef.close(false);
      },
      (error) => {
        this.progressCompleted = true;
        this.isProgressing = false;
        this.dialogRef.close(false);
      }
    );
  }

  onYes() {
    this.isDeleting = true;
    document.body.classList.add('waiting-mouse-cursor');
    if (this.data.customDelete && this.data.customDeleteApipath != null) {
      this.subscribe(
        this.coreDataService.deleteEntityByCustomApi(this.data.customDeleteApipath),
        (response) => {
          if (response && response.data.state) {
            this.messageNotifierService.showSuccessMessage(_('toastr_success_removed'));
            this.dialogRef.close(true);
            this.isDeleting = false;
            document.body.classList.remove('waiting-mouse-cursor');
          } else if (response && !response.data.state) {
            this.messageNotifierService.showErrorMessage(response.data.error);
            this.isDeleting = false;
            document.body.classList.remove('waiting-mouse-cursor');
          } else {
            this.messageNotifierService.showWarningMessage(_('toastr_no_data'));
            this.isDeleting = false;
            document.body.classList.remove('waiting-mouse-cursor');
          }
        },
        (error) => {
          this.isDeleting = false;
          document.body.classList.remove('waiting-mouse-cursor');
        }
      );
    } else {
      this.subscribe(
        this.coreDataService.deletionEntities(
          this.data.entities.map((e) => {
            return { entityId: e.entityId, entityKind: e.entityKind };
          }),
          this.data.isTenantAdmin
        ),
        (response) => {
          if (response && response.data.state) {
            this.messageNotifierService.showSuccessMessage(_('toastr_success_removed'));
            this.dialogRef.close(true);
            this.isDeleting = false;
            document.body.classList.remove('waiting-mouse-cursor');
          } else if (response && !response.data.state) {
            this.messageNotifierService.showErrorMessage(response.data.error);
            this.isDeleting = false;
            document.body.classList.remove('waiting-mouse-cursor');
          } else {
            this.messageNotifierService.showWarningMessage(_('toastr_no_data'));
            this.isDeleting = false;
            document.body.classList.remove('waiting-mouse-cursor');
          }
        },
        (error) => {
          this.isDeleting = false;
          document.body.classList.remove('waiting-mouse-cursor');
        }
      );
    }
  }

  onNo() {
    this.dialogRef.close(false);
  }
}
