<div
  class="base-form-field form-field-config-search"
  [ngClass]="[!formFieldData.formfieldLabelShow ? 'justify-center' : '']"
  (click)="onItemClick()"
>
  <div class="label-container" *ngIf="formFieldData.formfieldLabelShow">
    <ng-container *ngIf="formFieldData.formfieldLabelDisplay">
      <span class="label" showIfTruncated matTooltip="{{ formFieldData.formfieldTextname | translate }}"
        >{{ formFieldData.formfieldTextname | translate }}
      </span>
      <span *ngIf="formFieldData.formfieldRequired" class="requiredField">*</span>
    </ng-container>
    <ng-container *ngIf="!formFieldData.formfieldLabelDisplay">
      <span class="label">&nbsp;</span>
    </ng-container>
  </div>
  <div class="value-container">
    <span class="value">
      <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
        <input type="text" matInput [value]="formFieldData.formfieldTextname | translate" [disabled]="true" />
        <button [disabled]="true" mat-icon-button matSuffix class="reset-filter">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </span>
  </div>
  <button class="remove-button" (click)="remove(); $event.stopPropagation()" mat-icon-button *ngIf="isEditMode">
    <mat-icon>close</mat-icon>
  </button>
</div>
