import { CollectionViewer, SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreDataService } from '../services/core-data.service';
import { PaginatedRequest } from './paginated-request';
import { GenericTreeNode } from './tree-view-flat-node';

export class ForeignEntity extends GenericTreeNode {
  entityId: number;
  entityName: string;
  entityKind: string;
  entityDescription: string;
}

export class ForeignEntityRequest extends PaginatedRequest {
  kind?: string;
  linkableFlag?: boolean;
  filterIds?: any[];
  filterName?: string;
  filterEntityKind?: string;
  filterEntityId?: number;
  filterColumn1?: string;
  filterColumn1Values?: number[];
  filterColumn2?: string;
  filterColumn2Values?: number[];
  baseEntityKind?: string;
  baseEntityId?: number;
  excludeIds?: number[];
  isTenantAdmin?: boolean;
}
export class ForeignEntityDynamicFlatNode {
  constructor(
    public item: ForeignEntity,
    public level = 1,
    public expandable = false,
    public isLoading = false,
    public isIndeterminate = false,
    parentId?: number
  ) {
    this.item.parentId = parentId;
  }
}

@Injectable()
export class ForeignEntityDynamicDataSource {
  dataChange = new BehaviorSubject<ForeignEntityDynamicFlatNode[]>([]);
  nodeSelection = new SelectionModel<ForeignEntity>(true, []);
  baseApiPath: string;
  nodeName: string;

  get data(): ForeignEntityDynamicFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: ForeignEntityDynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private treeControl: FlatTreeControl<ForeignEntityDynamicFlatNode>,
    private database: ForeignEntityDynamicDatabase,
    private coreDataService: CoreDataService,
    private request: ForeignEntityRequest
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<ForeignEntityDynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if (
        (change as SelectionChange<ForeignEntityDynamicFlatNode>).added ||
        (change as SelectionChange<ForeignEntityDynamicFlatNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<ForeignEntityDynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(): void {}

  handleTreeControl(change: SelectionChange<ForeignEntityDynamicFlatNode>) {
    if (change.added) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  toggleNode(node: ForeignEntityDynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);

    node.isLoading = true;

    if (expand) {
      const childs = this.database.getChildren(node.item);
      if (childs) {
        const nodes = childs.map(
          (item) =>
            new ForeignEntityDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId)
        );
        this.data.splice(index + 1, 0, ...nodes);
        // notify the change
        this.dataChange.next(this.data);
        node.isLoading = false;
      } else {
        const apiPath = this.request.isTenantAdmin
          ? `api/tenantadministration/entities/${this.request.kind}/${node.item.entityId}/nodes`
          : `api/entities/${this.request.kind}/${node.item.entityId}/nodes`;
        this.coreDataService.getForeignEntity(apiPath, this.request).subscribe(({ data }) => {
          if (data) {
            const nodes = data.map(
              (item) =>
                new ForeignEntityDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId)
            );
            if (this.isNodeSelected(node)) {
              this.nodeSelection.select(...data);
            }
            this.database.setChildren(node.item, data);
            this.data.splice(index + 1, 0, ...nodes);
            // notify the change
            this.dataChange.next(this.data);
          } else {
            node.expandable = false;
          }
          node.isLoading = false;
        });
      }
    } else {
      let count = 0;
      // eslint-disable-next-line no-empty
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
      this.data.splice(index + 1, count);
      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }
  }

  /* SELECTION MANAGEMENT */

  isNodeSelected(node: ForeignEntityDynamicFlatNode): boolean {
    return this.nodeSelection.isSelected(node.item);
  }

  isNodeChecked(node: ForeignEntityDynamicFlatNode): boolean {
    return node.expandable && this.treeControl.isExpanded(node)
      ? this.descendantsAllSelected(node)
      : this.nodeSelection.isSelected(node.item);
  }

  getSelectedNodes() {
    return this.nodeSelection.selected;
  }

  toggleNodeSelection(node: ForeignEntityDynamicFlatNode) {
    this.nodeSelection.toggle(node.item);
    const descendants = this.database.getChildren(node.item);
    this.toggleDescendants(this.isNodeSelected(node), descendants);
    this.checkAllParentsSelection(node);
  }

  toggleDescendants(parentNodeSelected: boolean, descendants: ForeignEntity[]) {
    if (descendants) {
      parentNodeSelected ? this.nodeSelection.select(...descendants) : this.nodeSelection.deselect(...descendants);
      descendants.forEach((childNode) => this.toggleDescendants(parentNodeSelected, this.database.getChildren(childNode)));
    }
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ForeignEntityDynamicFlatNode): boolean {
    const descendants = this.database.getChildren(node.item);
    const descAllSelected =
      descendants &&
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.nodeSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ForeignEntityDynamicFlatNode): boolean {
    if (node.expandable && this.treeControl.isExpanded(node)) {
      const descendants = this.database.getChildren(node.item);
      const result = descendants ? descendants.some((child) => this.nodeSelection.isSelected(child)) : false;
      const isIndeterminate = result && !this.descendantsAllSelected(node);
      node.isIndeterminate = isIndeterminate;
    }
    return node.isIndeterminate;
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ForeignEntityDynamicFlatNode): void {
    let parent: ForeignEntityDynamicFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ForeignEntityDynamicFlatNode): void {
    const nodeSelected = this.isNodeSelected(node);
    const descendants = this.database.getChildren(node.item);
    const descAllSelected =
      descendants &&
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.nodeSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.nodeSelection.deselect(node.item);
    } else if (!nodeSelected && descAllSelected) {
      this.nodeSelection.select(node.item);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ForeignEntityDynamicFlatNode): ForeignEntityDynamicFlatNode | null {
    const currentLevel = node.level;
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}

@Injectable()
export class ForeignEntityDynamicDatabase {
  dataMap = new Map<ForeignEntity, ForeignEntity[]>([]);

  initialData(rootLevelNodes: ForeignEntity[]): ForeignEntityDynamicFlatNode[] {
    const initialNodes: ForeignEntityDynamicFlatNode[] = [];
    rootLevelNodes.forEach((node) => {
      initialNodes.push(new ForeignEntityDynamicFlatNode(node, 0, this.isExpandable(node), false));
    });

    return initialNodes;
  }

  setChildren(parentNode: ForeignEntity, children: ForeignEntity[]) {
    this.dataMap.set(parentNode, children);
  }

  getChildren(node: ForeignEntity): ForeignEntity[] | undefined {
    return this.dataMap.get(node);
  }

  isExpandable(node: ForeignEntity): boolean {
    return node.entityChilds > 0;
  }
}
