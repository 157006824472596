import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { AddonCancelWizardRequest } from 'src/app/models/asset-subscription';
import { Service } from 'src/app/models/service';
import { TerminateMultiAddonsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/terminate-multi-addons-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Terminate Multi Addons Information Step',
  props<{ initStep: TerminateMultiAddonsInitStep }>()
);

export const selectServiceStep = createAction(
  '[Wizard Service Step] Select Terminate Multi Addons Service Step',
  props<{ serviceStep: Service[] }>()
);

export const deleteServiceStep = createAction('[Wizard Service Step] Delete Terminate Multi Addons Service Step');

export const exitTerminateMultiAddonsWizard = createAction('[TerminateMultiAddons Wizard Page] Exit Terminate Multi Addons Wizard');

export const destroyWizard = createAction('[TerminateMultiAddons Wizard Page] Destroy Wizard');

export const createNewTerminateMultiAddons = createAction(
  '[Wizard Confirmation Step] Create Terminate Multi Addons Information Step',
  props<{ request: AddonCancelWizardRequest }>()
);

export const createNewTerminateMultiAddonsSuccess = createAction(
  '[Wizard Confirmation Step] Create Terminate Multi Addons Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewTerminateMultiAddonsFail = createAction(
  '[Wizard Confirmation Step] Create Terminate Multi Addons Information Step Fail',
  props<{ errorMessage: string }>()
);
