import { Action, createReducer, on } from '@ngrx/store';
import * as CopyAssetWizardActions from './actions';
import { State, initialState } from './state';

export const copyAssetWizardFeatureKey = 'copyAssetWizard';

const addProductWizardReducer = createReducer(
  initialState,

  on(CopyAssetWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(CopyAssetWizardActions.selectAssetServicesInit, (state, { initAssetServiceStep }) => ({
    ...state,
    initAssetServiceStep,
  })),
  on(CopyAssetWizardActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(CopyAssetWizardActions.selectDeskStep, (state, { deskStep }) => ({
    ...state,
    deskStep,
  })),
  on(CopyAssetWizardActions.selectServiceStep, (state, { serviceStep }) => ({
    ...state,
    serviceStep,
  })),
  on(CopyAssetWizardActions.deleteServiceStep, (state) => ({
    ...state,
    serviceStep: null,
  })),
  on(CopyAssetWizardActions.selectAdditionalStep, (state, { addInfoStep }) => ({
    ...state,
    addInfoStep,
  })),
  on(CopyAssetWizardActions.deleteAdditionalStep, (state) => ({
    ...state,
    addInfoStep: null,
  })),
  on(CopyAssetWizardActions.exitCopyAssetWizard, (state) => ({
    ...state,
    initStep: null,
    initAssetServiceStep: null,
    dueDatesStep: null,
    deskStep: null,
    serviceStep: null,
    addInfoStep: null,
    errorMessage: null,
  })),
  on(CopyAssetWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    initAssetServiceStep: null,
    dueDatesStep: null,
    deskStep: null,
    serviceStep: null,
    addInfoStep: null,
    errorMessage: null,
  })),
  on(CopyAssetWizardActions.createNewCopyAsset, (state, { newOrder }) => ({
    ...state,
    newOrder,
  })),
  on(CopyAssetWizardActions.createNewOrderSuccess, (state, { response }) => ({
    ...state,
    newOrderResponse: response,
  })),
  on(CopyAssetWizardActions.createNewOrderFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return addProductWizardReducer(state, action);
}
