import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store, select } from '@ngrx/store';
import { Entity, EntitySelection } from 'src/app/models/entity';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { MessageBox, MessageBoxButton, MessageBoxStyle, MessageBoxType } from 'src/app/shared/message-box';
import { MultiselectionGridBaseComponent } from '../multiselection-grid-base-component';

@Component({
  selector: 'app-multiselection-static-options-grid',
  templateUrl: './multiselection-static-options-grid.component.html',
  styleUrls: ['./multiselection-static-options-grid.component.scss'],
})
export class MultiselectionStaticOptionsGridComponent extends MultiselectionGridBaseComponent {
  @Input() staticDataOptions: KeyValue<string, string>[] = [];
  @Input() entityKind: string;

  constructor(protected store: Store<RootStoreState.State>, protected cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  ngAfterViewInit() {
    this.dataSourceTable = new MatTableDataSource();
    this.dataSourceTable.filterPredicate = (data, filter: string): boolean => data.entityName.toLowerCase().includes(filter);
    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesPaginatonsOptions)), (pageSizeOpt) => {
      this.pageSizeList = pageSizeOpt;
      if (this.paginatorTable) {
        this.paginatorTable.pageSize = pageSizeOpt[0];
        this.dataSourceTable.paginator = this.paginatorTable;
      }
    });

    this.gbAfterViewInitTable();

    this.cdRef.detectChanges();
  }

  gbGetDisplayColumnTable() {
    return ['selection', 'entityName'];
  }

  gbLoadEntitiesData() {
    this.clearSelection();

    this.isLoading = true;
    this.isLoadingEvEm.emit(this.isLoading);
    if (this.staticDataOptions && this.staticDataOptions.length > 0) {
      this.noEntityData = false;
      this.dataSourceTable.data = this.staticDataOptions.map((o) => {
        return { ...new Entity(), entityId: this.castToInt(o.key), entityName: o.value, entityKind: this.entityKind };
      }) as Entity[];
      this.dataSourceTable._updateChangeSubscription();
      this.pageTotalElements = this.staticDataOptions.length;
      this.initSelection();
    } else {
      this.pageTotalElements = 0;
      this.noEntityData = true;
    }
    this.isLoading = false;
  }

  applyFilterTable(filterValue: string) {
    if (filterValue && filterValue.length >= 3) {
      // this.setFilter(filterValue);
      this.dataSourceTable.filter = filterValue.trim().toLocaleLowerCase();
    } else {
      if (!filterValue || (filterValue && filterValue.length === 0)) {
        this.dataSourceTable.filter = null;
      }
    }
  }

  resetFilter() {
    if (this.filter) {
      this.filter = '';
      this.applyFilterTable(this.filter);
    }
  }

  askRemoveAllFiltered() {
    MessageBox.show(
      this.dialog,
      this.translate.instant(_('label_filter_confirm_remove_all_filtered'), { value: this.selectionDataSourceTable.filteredData.length }),
      this.translate.instant('label_confirm'),
      null,
      MessageBoxType.Comfirm,
      MessageBoxButton.YesNo,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'yes') {
        this.clearSelectionEvEm.emit();
      }
    });
  }

  askAddAllFiltered() {
    MessageBox.show(
      this.dialog,
      this.translate.instant(_('label_filter_confirm_add_all_filtered'), { value: this.pageTotalElements }),
      this.translate.instant('label_confirm'),
      null,
      MessageBoxType.Comfirm,
      MessageBoxButton.YesNo,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'yes') {
        this.addAllFiltered();
      }
    });
  }

  addAllFiltered() {
    this.isLoadingSelection = true;
    this.isLoadingSelectionEvEm.emit(this.isLoadingSelection);
    const entities: EntitySelection[] = this.dataSourceTable.data.map((row) => {
      return { ...(row as Entity), deleted: false };
    });
    this.entitiesSelectedEvEm.emit(entities);
    this.isLoadingSelection = false;
    this.isLoadingSelectionEvEm.emit(this.isLoadingSelection);
  }

  addAllToSelection() {
    const pageSize = this.paginatorTable.pageSize;
    const pageIndex = this.paginatorTable.pageIndex;
    const start = pageIndex * pageSize;
    const end = (pageIndex + 1) * pageSize;
    const rowsToBeSelected = this.dataSourceTable.filteredData.slice(start, end).filter((row) => !this.isRowSelected(row));
    rowsToBeSelected.forEach((row) => this.selection.select(row));
    this.entitiesSelectedEvEm.emit(rowsToBeSelected);
  }
}
