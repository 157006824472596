import { DatePipe } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { HttpHeaders } from 'src/app/configs/http-headers';
import { RootStoreState } from 'src/app/root-store';
import { GlobalDateFilterStoreSelectors } from 'src/app/root-store/global-date-filter-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';

@Injectable()
export class GlobalFilterInterceptor implements HttpInterceptor {
  constructor(private datePipe: DatePipe, private store: Store<RootStoreState.State>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(PreferencesSelectors.selectPreferencesHelp).pipe(
      first(),
      mergeMap((helpSetting) => {
        if (!helpSetting || !req.url.match(helpSetting.settingHelpUrl)) {
          return this.store.select(GlobalDateFilterStoreSelectors.selectIsConfigurationGlobalDateFilter).pipe(
            first(),
            mergeMap((isConfiguration) => {
              if (isConfiguration) {
                return this.store.select(GlobalDateFilterStoreSelectors.selectConfigurationGlobalDateFilter).pipe(
                  first(),
                  mergeMap((globalDateFilter) => {
                    if (globalDateFilter) {
                      req = req.clone({
                        headers: req.headers.set(HttpHeaders.GLOBAL_FILTER, this.datePipe.transform(globalDateFilter, 'yyyy-MM-dd')),
                      });
                    }
                    return next.handle(req);
                  })
                );
              } else {
                return this.store.select(GlobalDateFilterStoreSelectors.selectGlobalDateFilter).pipe(
                  first(),
                  mergeMap((globalDateFilter) => {
                    if (globalDateFilter) {
                      req = req.clone({
                        headers: req.headers.set(HttpHeaders.GLOBAL_FILTER, this.datePipe.transform(globalDateFilter, 'yyyy-MM-dd')),
                      });
                    }
                    return next.handle(req);
                  })
                );
              }
            })
          );
        } else {
          return next.handle(req);
        }
      })
    );
  }
}
