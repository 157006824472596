<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <h1 mat-dialog-title>
    {{ 'modal_dialog_please_select' | translate }}
    <ng-container [ngSwitch]="data.kind">
      <ng-container *ngSwitchCase="ThreediModalDialogKind.product">{{ Entities.PROFILER_3DI_PRODUCTS | translate }}</ng-container>
      <ng-container *ngSwitchCase="ThreediModalDialogKind.exchange">{{ Entities.PROFILER_3DI_EXCHANGES | translate }}</ng-container>
      <ng-container *ngSwitchCase="ThreediModalDialogKind.vendor">{{ Entities.PROFILER_3DI_VENDORS | translate }}</ng-container>
    </ng-container>
  </h1>

  <div class="button-wrapper">
    <button [mat-dialog-close]="selectedEntity" mat-raised-button [disabled]="!isEntitySelected()" color="primary">
      {{ 'label_select' | translate }}
    </button>
    <span style="padding-right: 5px"></span>
    <button [mat-dialog-close]="null" mat-raised-button color="primary">
      {{ 'label_cancel' | translate }}
    </button>
  </div>
</div>
<div mat-dialog-content>
  <!-- DIALOG CONTENT -->
  <div id="threedi-table">
    <!-- SEARCH & PAGINATOR START-->
    <div class="table-search-paginate hidden-filters">
      <div class="table-search-paginate-left-wrapper">
        <mat-form-field appearance="outline" class="reset-outline-hint filter-field search"
          ><button *ngIf="filter" mat-icon-button matSuffix class="small-icon" (click)="clearSearchFilter(); $event.stopPropagation()">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-autocomplete
            (opened)="matAutocompletPanelOpened()"
            (closed)="matAutocompletPanelClosed()"
            #autoLastStringOption="matAutocomplete"
            (optionSelected)="applyFilterTable($event.option.value)"
          >
            <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
              {{ 'label_recent_searches' | translate }}
            </mat-option>
            <mat-option class="form-field-option" *ngFor="let opt of lastUsedFilters" [value]="opt" [class.mat-selected]="filter === opt">
              {{ opt }}
            </mat-option>
          </mat-autocomplete>
          <input
            #searchInputField
            #searchInputAutocompleteTrigger="matAutocompleteTrigger"
            (input)="searchInputAutocompleteTrigger.closePanel()"
            matInput
            [(ngModel)]="filter"
            [matAutocomplete]="autoLastStringOption"
            (debounceFilterInput)="applyFilterTable($event)"
            [debounceTime]="600"
            maxlength="1500"
            placeholder="{{ 'table_filter_placeholder' | translate }}"
          />
        </mat-form-field>
        <ng-container *ngIf="searchFilterrules">
          <span style="padding-right: 0.5rem"></span>
          <div class="search-filterrules">
            <mat-button-toggle-group
              class="topics"
              #kindButtonGroup="matButtonToggleGroup"
              [multiple]="true"
              [value]="[selectedFilteruleId]"
              (change)="setFilterRule($event.value)"
            >
              <mat-button-toggle [value]="1">
                <span>{{ 'label_exact_match' | translate }}</span>
              </mat-button-toggle>
              <mat-button-toggle [value]="2">
                <span>{{ 'label_begin_with' | translate }}</span>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </ng-container>
      </div>
      <div class="table-search-paginate-right">
        <ng-container *ngIf="showFilters">
          <button mat-button color="primary" (click)="enableFilter = !enableFilter">
            {{ enableFilter ? ('label_hide_filters' | translate) : ('label_more_filters' | translate) }}
          </button>
          <button mat-button color="warn" (click)="clearFilters()" *ngIf="isFiltersSetted()">
            {{ 'label_reset_filters' | translate }}
          </button>
        </ng-container>
      </div>
    </div>
    <div class="table-filters hidden"></div>
    <!-- SEARCH & PAGINATOR END-->
    <div class="container-fluid">
      <div class="table-wrapper table-entity-wrapper">
        <div class="table-container">
          <table
            class="table table-stripes table-truncate-text"
            mat-table
            [dataSource]="dataSourceTable"
            matSort
            #sortTable="matSort"
            [matSortActive]="gbGetInitialOrderBy()"
            [matSortDirection]="gbGetInitialSort()"
            matSortDisableClear
          >
            <!-- radio button column -->
            <ng-container matColumnDef="radio">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row" class="radio-button-wrapper">
                <mat-radio-button
                  class="table-radio-button"
                  (change)="onEntitySelected(row)"
                  disableRipple="true"
                  [checked]="selectedEntity && selectedEntity.entityId === row.entityId"
                ></mat-radio-button>
              </td>
            </ng-container>
            <ng-container matColumnDef="threediproductName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'threediproduct_name' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">
                <span [matTooltip]="row.threediproductName">{{ row.threediproductName }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="threediexchangeName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'threediexchange_name' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">
                <span [matTooltip]="row.threediexchangeName">{{ row.threediexchangeName }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="threediproductDelivery">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'threediproduct_delivery' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">
                <span [matTooltip]="row.threediproductDelivery">{{ row.threediproductDelivery }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="threediproductSynopsis">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'threediproduct_synopsis' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">
                <span [matTooltip]="row.threediproductSynopsis">{{ row.threediproductSynopsis }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="threedivendorName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'threedivendor_name' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">
                <span [matTooltip]="row.threedivendorName">{{ row.threedivendorName }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="threedivendorTechnicalArchitecture">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'threedivendor_technical_architecture' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">
                <span [matTooltip]="row.threedivendorTechnicalArchitecture">{{ row.threedivendorTechnicalArchitecture }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="threedivendorSynopsis">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'threedivendor_synopsis' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">
                <span [matTooltip]="row.threedivendorSynopsis">{{ row.threedivendorSynopsis }}</span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
            <tr
              mat-row
              (click)="onEntitySelected(row)"
              *matRowDef="let row; columns: displayedColumnsTable"
              [ngClass]="selectedEntity && selectedEntity.entityId === row.entityId ? 'selected-row' : ''"
            ></tr>
          </table>
          <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
            <mat-toolbar-row *ngIf="isLoading" class="spinner">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-toolbar-row>
            <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
              <span>{{ 'generic_table_error_nodata' | translate }}</span>
            </mat-toolbar-row>
          </mat-toolbar>
          <mat-toolbar class="table-footer-toolbar">
            <mat-toolbar-row>
              <mat-paginator
                [disabled]="isLoading"
                #paginatorTable
                [pageSizeOptions]="pageSizeList"
                [length]="pageTotalElements"
                class="reset-paginator"
              ></mat-paginator>
            </mat-toolbar-row>
          </mat-toolbar>
        </div>
      </div>
    </div>
  </div>
  <!-- END DIALOG CONTENT -->
</div>
