import { RequestValidationResponse } from 'src/app/models/request';

export interface State {
  validationResult: RequestValidationResponse;
  executionStatus: boolean;
  rejectionStatus: boolean;
  approveStatus: boolean;
  rejectionApprovalStatus: boolean;
  actionError: string;
}

export const initialState: State = {
  validationResult: null,
  executionStatus: false,
  rejectionStatus: false,
  approveStatus: false,
  rejectionApprovalStatus: false,
  actionError: null,
};
