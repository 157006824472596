import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HelpAuthToken } from 'src/app/core/auth/help-auth';
import { LogService } from '../log-service';

@Injectable({
  providedIn: 'root',
})
export class HelpAuthService {
  authHelpWordpress(helpPath: string, username: string, password: string): Observable<HelpAuthToken> {
    const m = this.authHelpWordpress.name;

    const path = `${helpPath}/wp-json/api/v1/mo-jwt`;
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<HelpAuthToken>(path, {
      username: username,
      password: password,
    });

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
