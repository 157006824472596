import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, timer } from 'rxjs';
import { catchError, map, switchMap, takeWhile, withLatestFrom } from 'rxjs/operators';
import { NotificationDataService } from 'src/app/services/notification-data.service';
import { environment } from 'src/environments/environment';
import * as NotificationsStoreActions from './actions';
import { selectNotificationsIsFinished } from './selectors';
import { State } from './state';

@Injectable()
export class NotificationsStoreEffects {
  pollingNotificationCountData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsStoreActions.startPollingNotificationsCount),
      switchMap(() =>
        timer(0, environment.notificationPollingIntervall).pipe(
          withLatestFrom(this.store.select(selectNotificationsIsFinished)),
          takeWhile(([searchid, isFinished]) => !isFinished),
          switchMap(() =>
            this.notificationsService.getNotificationCount().pipe(
              map((response) => {
                if (response.data) {
                  return NotificationsStoreActions.storePollingNotificationsCount({ count: response.data });
                } else {
                  return NotificationsStoreActions.storePollingNotificationsCount({
                    count: { countNotification: 0, countEmail: 0, countTaricsEntitlement: null, toastrMessages: null },
                  });
                }
              }),
              catchError((error) => {
                return of(NotificationsStoreActions.notificationsError({ error }));
              })
            )
          )
        )
      )
    )
  );

  refreshNotificationCountData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsStoreActions.refreshNotificationCount),
      switchMap(() =>
        this.notificationsService.getNotificationCount().pipe(
          map((response) => {
            if (response.data) {
              return NotificationsStoreActions.storePollingNotificationsCount({ count: response.data });
            } else {
              return NotificationsStoreActions.storePollingNotificationsCount({
                count: { countNotification: 0, countEmail: 0, countTaricsEntitlement: null, toastrMessages: null },
              });
            }
          }),
          catchError((error) => {
            return of(NotificationsStoreActions.notificationsError({ error }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<State>, private notificationsService: NotificationDataService) {}
}
