import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Userlicense } from 'src/app/models/userlicense';
import { AllocateConsumerAddonsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-addons-wizard/dates-step/allocate-consumer-addons-date-info';
import { AllocateConsumerAddonsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-addons-wizard/init-step/allocate-consumers-addons-init-step';
import * as fromAllocateConsumerAddonsWizard from './reducer';
import { State } from './state';

export const selectAllocateConsumerAddonsWizardState = createFeatureSelector<State>(
  fromAllocateConsumerAddonsWizard.allocateConsumerAddonWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectAllocateConsumerAddonsWizardState,
  (state: State): AllocateConsumerAddonsInitStep => state.initStep
);

export const selectDatesStep = createSelector(
  selectAllocateConsumerAddonsWizardState,
  (state: State): AllocateConsumerAddonsDueDates => state.datesStep
);

export const selectAssetsStep = createSelector(selectAllocateConsumerAddonsWizardState, (state: State): Userlicense[] => state.assetsStep);

// export const selectProductList = createSelector(selectAllocateConsumerAddonsWizardState, (state: State): Product[] => state.productList);
