import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { ApiPath } from 'src/app/configs/api-paths';
import { DefaultColsTable } from 'src/app/configs/default-cols-table';
import { Entities } from 'src/app/configs/entities';
import {
  FilesUploadProgressDialogComponent,
  FilesUploadProgressDialogData,
} from 'src/app/core/modal/files-upload-progress-dialog/files-upload-progress-dialog.component';
import { ExtendedFileModel } from 'src/app/models/core/extended-file';
import { Entity } from 'src/app/models/entity';
import { FileGridNode } from 'src/app/models/file-grid-node';
import { EntityFieldsListContentCallBack } from 'src/app/models/generic-entity-field';
import { EmailFile } from 'src/app/models/mailtemplate/mail-template-preview';
import { RootStoreState } from 'src/app/root-store';
import { ImageFullscreenViewerStoreAction } from 'src/app/root-store/image-fullscreen-viewer-store';
import { FilesService } from 'src/app/services/files.data.services';
import { LogService } from 'src/app/services/log-service';
import { GridBaseComponent } from 'src/app/shared/base-components/grid-base-component';
import { FileTabType } from 'src/app/shared/files/file-tab/file-tab-type';
import { MessageBox, MessageBoxButton, MessageBoxStyle, MessageBoxType } from 'src/app/shared/message-box';
import { FileModalComponent } from '../../files/file-modal/file-modal.component';
import {
  GenericEntityFormModalDialogComponent,
  GenericEntityFormModalDialogData,
} from '../generic-entity-form-modal-dialog/generic-entity-form-modal-dialog.component';
import {
  GenericSendEmailDialogComponent,
  GenericSendEmailDialogData,
} from '../generic-send-email-dialog/generic-send-email-dialog.component';

@Component({
  selector: 'app-generic-entity-file-upload',
  templateUrl: './generic-entity-file-upload.component.html',
  styleUrls: ['./generic-entity-file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GenericEntityFileUploadComponent extends GridBaseComponent implements OnInit, OnChanges {
  @Input() entity: Entity;
  @Input() sendSingleAttachment = false;
  @Input() sendMultipleAttachments = false;

  showThumbnail = false;
  selection = new SelectionModel<FileGridNode>(true, []);

  dataSourceTable: MatTableDataSource<FileGridNode>;

  constructor(
    private sanitizer: DomSanitizer,
    private filesService: FilesService,
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef
  ) {
    super(store, cdRef);
  }

  gbOnInit() {
    this.initRequest();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity && changes.entity.currentValue !== changes.entity.previousValue) {
      this.entity = changes.entity.currentValue;
      this.initRequest();
      this.gbLoadEntitiesData();
    }
  }

  /* GRID COMPONENT OVERRIDED METHOD */

  async gbAfterViewInitTable() {}

  gbGetDisplayColumnTable() {
    if (this.sendSingleAttachment) {
      return this.sendMultipleAttachments
        ? ['select', ...DefaultColsTable.ENTITY_FILES_WITH_SEND_COLUMN]
        : [...DefaultColsTable.ENTITY_FILES_WITH_SEND_COLUMN];
    } else {
      return this.sendMultipleAttachments ? ['select', ...DefaultColsTable.ENTITY_FILES_COLUMN] : [...DefaultColsTable.ENTITY_FILES_COLUMN];
    }
  }

  gbGetInitialOrderBy() {
    return 'filenodeName';
  }

  gbSetSelectedEntity(row: Entity) {
    this.selectedEntity = row;
  }

  gbLoadEntitiesData() {
    this.clearSelection();
    this.isLoading = true;
    this.dataSourceTable = new MatTableDataSource();
    this.noEntityData = false;
    if (this.gridLoadSubscription != null) {
      this.gridLoadSubscription.unsubscribe();
    }
    this.gridLoadSubscription = this.subscribe(
      this.filesService.getFilesList(ApiPath.Files.ENTITY_FILES_GRID(this.entity.entityKind, this.entity.entityId), this.request),
      (response) => {
        this.lastUsedFilters = response.filters;
        if (response.data) {
          this.dataSourceTable.data = response.data;
          this.pageTotalElements = response.data[0].entityCount;
        } else {
          this.pageTotalElements = 0;
          this.noEntityData = true;
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      },
      () => (this.isLoading = false)
    );
  }

  createUpdateDetailsCallbackEvEm(callback: EntityFieldsListContentCallBack) {
    const m = this.createUpdateDetailsCallbackEvEm.name;

    LogService.debug(this, m, 'CREATE/UPDATE callback.isSuccess: ' + callback.isSuccess + ' - Callback Object:', callback);
    if (callback.isSuccess) {
      this.gbLoadEntitiesData();
    }
    // this.switchEditDetails(callback.isEditing);
    // ? this.isNewEntity = callback.isNewEntity;
  }

  onRowClicked(row: Entity) {
    return;
  }

  /* other */

  uploadFiles(fileList: FileList) {
    this.subscribe(
      this.dialog
        .open<FilesUploadProgressDialogComponent, FilesUploadProgressDialogData, ExtendedFileModel[]>(FilesUploadProgressDialogComponent, {
          autoFocus: false,
          width: '800px',
          height: '60vh',
          panelClass: 'files-upload-progress-dialog',
          hasBackdrop: true,
          disableClose: true,
          data: {
            fileList: fileList,
            apipath: ApiPath.Files.ENTITY_FILES_GRID(this.entity.entityKind, this.entity.entityId),
          } as FilesUploadProgressDialogData,
        })
        .afterClosed(),
      () => {
        this.gbLoadEntitiesData();
      }
    );
  }

  addLink() {
    this.dialog
      .open(FileModalComponent, { height: '80vh', width: '80vw' })
      .afterClosed()
      .subscribe((selectedFile) => {
        if (selectedFile) {
          LogService.debug(this, this.addLink.name, 'Selected file', selectedFile);
          this.subscribe(
            this.filesService.addFileLinkToEntity(this.entity.entityKind, this.entity.entityId, selectedFile.filenodeFileid),
            (response) => {
              if (response.data) {
                if (response.data) {
                  if (response.data.state) {
                    this.messageNotifierService.showSuccessMessage('toastr_link_success_created');
                    // RELOAD DATA INTO TABLE COMPONENT
                    this.gbLoadEntitiesData();
                  } else if (response.data.error) {
                    // Fail
                    this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
                  } else {
                    this.messageNotifierService.showWarningMessage('toastr_no_data');
                  }
                }
              }
            }
          );
        }
      });
  }

  downloadFile(file: FileGridNode) {
    this.subscribe(this.filesService.downloadFile(Entities.FILE, file.filenodeFileid), (blob) => {
      console.log(blob.type);
      if (blob.type === 'text/plain' || blob.type.match(/image\/\w+/)) {
        const fileURL = URL.createObjectURL(blob);

        window.open(fileURL, '_blank');
      } else {
        saveAs(blob, file.filenodeName); // FileSaver;
      }
    });
  }

  fileDetails(file: FileGridNode) {
    const entity = {
      ...new Entity(),
      entityKind: Entities.FILE,
      entityId: file.filenodeFileid,
      entityName: file.entityName,
      entityUpdate: file.entityUpdate,
      entityDelete: file.entityDelete,
    };
    this.dialog.open(GenericEntityFormModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'generic-entity-form-modal-dialog',
      disableClose: true,
      data: {
        selectedEntity: entity,
      } as GenericEntityFormModalDialogData,
    });
  }

  deleteFile(file: FileGridNode) {
    const selectedEntityApiPath = ApiPath.Files.FILES_DELETE(FileTabType.generic.key, file.filenodeId);
    this.gbSetSelectedEntity(file);
    MessageBox.show(
      this.dialog,
      this.translate.instant(this.selectedEntity.entityKind) + ': ' + this.selectedEntity.entityName,
      this.translate.instant('label_confirm'),
      this.translate.instant('label_really_delete_record'),
      MessageBoxType.Comfirm,
      MessageBoxButton.YesNo,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'yes') {
        this.removeRecord(selectedEntityApiPath);
      }
    });
  }

  protected removeRecord(apiPath: string) {
    this.subscribe(
      this.coreDataService.removeEntity(apiPath),
      (response) => {
        if (response.data) {
          if (response.data.state) {
            // Success
            this.messageNotifierService.showSuccessMessage('toastr_success_removed');
            this.gbLoadEntitiesData();
          } else if (response.data.error) {
            // Fail
            this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
          } else {
            this.messageNotifierService.showWarningMessage('toastr_no_data');
          }
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      }
    );
  }

  previewFile(file: FileGridNode) {
    this.subscribe(this.filesService.getFilePreviewImage(file.filenodeFileid), ({ data }) => {
      if (data) {
        const imageObject = [
          {
            image: data.image,
            title: file.filenodeName,
          },
        ];
        this.store.dispatch(ImageFullscreenViewerStoreAction.setImage({ imageObject }));
      }
    });
  }

  sendFileEmail(file: FileGridNode) {
    const files: EmailFile[] =
      file != null
        ? [{ ...new EmailFile(), fileId: file.filenodeFileid, fileName: file.filenodeName }]
        : this.selection.selected.map((s) => {
            return { ...new EmailFile(), fileId: s.filenodeFileid, fileName: s.filenodeName };
          });
    this.subscribe(
      this.dialog
        .open(GenericSendEmailDialogComponent, {
          width: '90vw',
          maxWidth: '90vw',
          maxHeight: '95vh',
          autoFocus: false,
          disableClose: true,
          panelClass: 'generic-send-email-dialog',
          data: {
            entityKind: this.entity.entityKind,
            entityId: this.entity.entityId,
            entityName: this.entity.entityName,
            files,
          } as GenericSendEmailDialogData,
        })
        .afterClosed(),
      (result) => {
        // if (result) {
        //   this.clearSelection();
        this.gbLoadEntitiesData();
        // }
      }
    );
  }

  // Call this method in the image source, it will sanitize it.
  transform(base64Image: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }
}
