import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entity, EntitySelection } from 'src/app/models/entity';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { Type, TypeParams } from 'src/app/models/type';
import { TypeDynamicDataSource, TypeDynamicDatabase, TypeDynamicFlatNode, TypeTreeNode } from 'src/app/models/type-node';
import { RootStoreState } from 'src/app/root-store';
import { TypeDataService } from 'src/app/services/type-data.service';
import { MessageBox, MessageBoxButton, MessageBoxStyle, MessageBoxType } from 'src/app/shared/message-box';
import { MultiselectionTreeGridBaseComponent } from '../multiselection-treegrid-base-component';

@Component({
  selector: 'app-multiselection-types-grid',
  templateUrl: './multiselection-types-grid.component.html',
  styleUrls: ['./multiselection-types-grid.component.scss'],
  providers: [TypeDynamicDatabase],
})
export class MultiselectionTypesGridComponent extends MultiselectionTreeGridBaseComponent {
  @Input() typeKindId: number = null;

  typeNodeData: TypeTreeNode[];
  selectedNodes: TypeTreeNode[];
  dataSource: TypeDynamicDataSource;
  treeControl: FlatTreeControl<TypeDynamicFlatNode>;
  typeSelection = new SelectionModel<number>(true, []);

  constructor(
    private database: TypeDynamicDatabase,
    private typeDataService: TypeDataService,
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef
  ) {
    super(store, cdRef);
  }

  GRID_NAME = 'tab_grid_type_filter';

  /* GRID COMPONENT OVERRIDED METHOD */

  // gbOnInit ()  {
  //   if (this.typeKindId != null) {
  //     this.filtersMapStatic.set(RequestParams.FILTER_KIND_IDS, [this.typeKindId]);
  //   }
  //   super.gbOnInit();
  // }

  gbGetDisplayColumnTable() {
    return ['type_name'];
  }

  gbGetInitialOrderBy() {
    return 'type_name';
  }

  gbGetInitialSort() {
    return 'asc';
  }

  gbLoadEntitiesData() {
    this.clearSelection();

    this.isLoading = true;
    this.isLoadingEvEm.emit(this.isLoading);
    this.treeControl = new FlatTreeControl<TypeDynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new TypeDynamicDataSource(this.treeControl, this.database, this.typeDataService, this.request);
    this.noEntityData = false;
    if (this.gridLoadSubscription != null) {
      this.gridLoadSubscription.unsubscribe();
    }
    this.gridLoadSubscription = this.subscribe(
      this.typeDataService.getTypeNodes(this.request, this.typeKindId),
      (response) => {
        this.lastUsedFilters = response.filters;
        if (response.data) {
          this.typeNodeData = response.data;
          this.dataSource.data = this.database.initialData(response.data);
          this.pageTotalElements = response.data[0].entityCount;
          this.initSelection();
        } else {
          this.pageTotalElements = 0;
          this.noEntityData = true;
        }
        if (this.addModeSelectAllEnabled) {
          this.addSelectAllEntry();
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      },
      () => {
        this.isLoading = false;
        this.isLoadingEvEm.emit(this.isLoading);
      }
    );
  }

  initSelection() {
    if (this.dataSource && this.dataSource.data) {
      this.typeSelection.clear();
      this.dataSelection.forEach((e) => {
        this.typeSelection.toggle(e.entityId);
      });
      // this.dataSource.data.forEach((row) => {
      //   if (this.isRowSelected(row.item)) {
      //     this.typeSelection.toggle(row.item.entityId);
      //   }
      // });
    }
  }

  async onCheckboxClicked(row: TypeTreeNode) {
    this.typeSelection.toggle(row.entityId);
    const hasChild = row.entityChilds > 0;
    if (this.typeSelection.isSelected(row.entityId)) {
      if (hasChild) {
        const { data } = await firstValueFrom(this.typeDataService.getTypeNodeChildsByTypeId(row.entityId));
        data ? this.entitiesSelectedEvEm.emit([row, ...data]) : this.entitiesSelectedEvEm.emit([row]);
      } else {
        this.entitiesSelectedEvEm.emit([row]);
      }
    } else {
      if (hasChild) {
        const { data } = await firstValueFrom(this.typeDataService.getTypeNodeChildsByTypeId(row.entityId));
        data ? this.entitiesDeselectedEvEm.emit([row, ...data]) : this.entitiesDeselectedEvEm.emit([row]);
      } else {
        this.entitiesDeselectedEvEm.emit([row]);
      }
    }
  }

  masterToggle() {
    this.isAllSelected() ? this.removeAllFromSelection() : this.addAllToSelection();
  }

  isAllSelected() {
    const numSelected = this.typeSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  addAllToSelection() {
    const rowsToBeSelected = this.dataSource.data.filter((row) => !this.isRowSelected(row.item)).map((r) => r.item);
    rowsToBeSelected.forEach((row) => this.typeSelection.select(row.entityId));
    this.entitiesSelectedEvEm.emit(rowsToBeSelected);
  }

  askRemoveAllFiltered() {
    MessageBox.show(
      this.dialog,
      this.translate.instant(_('label_filter_confirm_remove_all_filtered'), { value: this.selectionDataSourceTable.filteredData.length }),
      this.translate.instant('label_confirm'),
      null,
      MessageBoxType.Comfirm,
      MessageBoxButton.YesNo,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'yes') {
        this.clearSelectionEvEm.emit();
      }
    });
  }

  askAddAllFiltered() {
    MessageBox.show(
      this.dialog,
      this.translate.instant(_('label_filter_confirm_add_all_filtered'), { value: this.pageTotalElements }),
      this.translate.instant('label_confirm'),
      null,
      MessageBoxType.Comfirm,
      MessageBoxButton.YesNo,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'yes') {
        this.addAllFiltered();
      }
    });
  }

  addAllFiltered() {
    this.isLoadingSelection = true;
    this.isLoadingSelectionEvEm.emit(this.isLoadingSelection);
    const noPaginationRequest: PaginatedRequest = Object.assign({}, this.request);
    noPaginationRequest.pageSize = null;
    this.subscribe(this.typeDataService.getTypeNodes(noPaginationRequest, this.typeKindId), (response) => {
      this.lastUsedFilters = response.filters;
      if (response.data) {
        const entities: EntitySelection[] = response.data.map((row) => {
          return { ...(row as Entity), deleted: false };
        });
        this.entitiesSelectedEvEm.emit(entities);
      }
      this.isLoadingSelection = false;
      this.isLoadingSelectionEvEm.emit(this.isLoadingSelection);
    });
  }

  addSelectAllEntry() {
    const allRelations: Type = {
      ...new Type(),
      entityId: -1,
      entityName: `${this.translate.instant('label_none')}`,
      typeKind: `${this.translate.instant('label_none')}`,
    };
    this.dataSourceTable.data.unshift(allRelations);
    this.dataSourceTable._updateChangeSubscription();
  }

  /* TREE METHODS */
  getLevel = (node: TypeDynamicFlatNode) => node.level;

  isExpandable = (node: TypeDynamicFlatNode) => node.expandable;

  hasChild = (x: number, nodeData: TypeDynamicFlatNode) => nodeData.expandable;

  gbGetApiEntitiesPath() {
    return ApiPath.Types.TYPES;
  }

  tgbGetApiTreeEntitiesPath() {
    return null;
  }

  get TypeParams() {
    return TypeParams;
  }
}
