import { Action, createReducer, on } from '@ngrx/store';
import * as AllocateConsumerWizardActions from './actions';
import { State, initialState } from './state';

export const allocateConsumerWizardFeatureKey = 'allocateConsumerWizard';

const allocateConsumerWizardReducer = createReducer(
  initialState,

  on(AllocateConsumerWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(AllocateConsumerWizardActions.selectDatesStep, (state, { datesStep }) => ({
    ...state,
    datesStep,
  })),
  on(AllocateConsumerWizardActions.selectUsersStep, (state, { usersStep }) => ({
    ...state,
    usersStep,
  })),
  on(AllocateConsumerWizardActions.selectMiscellaneousStep, (state, { miscellaneousStep }) => ({
    ...state,
    miscellaneousStep,
  })),
  on(AllocateConsumerWizardActions.exitAllocateConsumerWizard, (state) => ({
    ...state,
    initStep: null,
    datesStep: null,
    usersStep: null,
    miscellaneousStep: null,
  })),
  on(AllocateConsumerWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    datesStep: null,
    usersStep: null,
    miscellaneousStep: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return allocateConsumerWizardReducer(state, action);
}
