<div class="cron-editor-input-container">
  <!-- Tabs -->
  <mat-tab-group *ngIf="cronExpression" [(selectedIndex)]="selectedTabIndex">
    <mat-tab label="{{ 'cron_editor_tab_seconds' | translate }}">
      <cron-seconds-tab
        [cronExpression]="cronExpression"
        [isEditing]="isEditing"
        (secondsExpressionEvEm)="setCronPart($event, 0)"
      ></cron-seconds-tab
    ></mat-tab>
    <mat-tab label="{{ 'cron_editor_tab_minutes' | translate }}">
      <cron-minutes-tab
        [cronExpression]="cronExpression"
        [isEditing]="isEditing"
        (minutesExpressionEvEm)="setCronPart($event, 1)"
      ></cron-minutes-tab
    ></mat-tab>
    <mat-tab label="{{ 'cron_editor_tab_hours' | translate }}">
      <cron-hours-tab
        [cronExpression]="cronExpression"
        [isEditing]="isEditing"
        (hoursExpressionEvEm)="setCronPart($event, 2)"
      ></cron-hours-tab
    ></mat-tab>
    <mat-tab label="{{ 'cron_editor_tab_day' | translate }}">
      <cron-day-tab
        [cronExpression]="cronExpression"
        [isEditing]="isEditing"
        (monthdaysExpressionEvEm)="setCronPart($event, 3)"
        (weekdaysExpressionEvEm)="setCronPart($event, 5)"
      ></cron-day-tab
    ></mat-tab>
    <mat-tab label="{{ 'cron_editor_tab_month' | translate }}">
      <cron-month-tab
        [cronExpression]="cronExpression"
        [isEditing]="isEditing"
        (monthsExpressionEvEm)="setCronPart($event, 4)"
      ></cron-month-tab
    ></mat-tab>
    <mat-tab label="{{ 'cron_editor_tab_year' | translate }}">
      <cron-year-tab
        [cronExpression]="cronExpression"
        [isEditing]="isEditing"
        (yearsExpressionEvEm)="setCronPart($event, 6)"
      ></cron-year-tab
    ></mat-tab>
  </mat-tab-group>
</div>
