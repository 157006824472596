import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { Entities } from 'src/app/configs/entities';
import { FormFieldConfigurationKind } from 'src/app/models/forms/form-configuration-kind';
import { FormFieldDataRequest, GenericFormField } from 'src/app/models/forms/form-field';
import { FormWizard, FormWizardResponse } from 'src/app/models/forms/form-wizard';
import { GenericEntityRequiredType } from 'src/app/models/generic-entity-field';
import { CoreDataService } from 'src/app/services/core-data.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { LogService } from 'src/app/services/log-service';
import { DecimalCheckerService } from 'src/app/services/utils/decimal-checker.service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from '../../base-components/format-component';
import { GenericFormWizardFinishStepComponent } from './generic-form-wizard-finish-step/generic-form-wizard-finish-step.component';
import { GenericFormWizardFormStepComponent } from './generic-form-wizard-form-step/generic-form-wizard-form-step.component';
import { GenericFormWizardValidationStepComponent } from './generic-form-wizard-validation-step/generic-form-wizard-validation-step.component';

@Component({
  selector: 'app-generic-form-wizard',
  templateUrl: './generic-form-wizard.component.html',
  styleUrls: ['./generic-form-wizard.component.scss'],
})
export class GenericFormWizardComponent extends FormatComponent implements OnInit, OnDestroy {
  @ViewChild('formWizardStepper') stepper: MatStepper;
  @ViewChild(GenericFormWizardValidationStepComponent) validationStep: GenericFormWizardValidationStepComponent;
  @ViewChild(GenericFormWizardFinishStepComponent) finishStep: GenericFormWizardFinishStepComponent;
  @ViewChild(GenericFormWizardFormStepComponent) formStep: GenericFormWizardFormStepComponent;
  @Input() isWizardDialog = false;
  @Input() wizardName: string;
  @Output() finishWizardEvEm = new EventEmitter<void>();
  @Output() cancelWizardEvEm = new EventEmitter<void>();

  formWizardData: FormWizard;
  saveSubj: Subject<void> = new Subject<void>();

  wizardId = -1;
  isFinished = false;
  isValidationFinished = false;
  error: string;
  errorMessage: string;

  isCurrentStepValid = false;
  currentStepData: GenericFormField[];

  constructor(
    private cdRef: ChangeDetectorRef,
    private formDataService: FormDataService,
    private coreService: CoreDataService,
    private dialog: MatDialog,
    private decimalCheckerService: DecimalCheckerService,
    protected messageNotifierService: MessageNotifierService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.wizardName) {
      LogService.debug(this, this.ngOnInit.name, 'Create new wizard', this.wizardName);
      this.initFormWizard();
    } else {
      LogService.error(this, this.ngOnInit.name, 'No wizard name specified', null);
    }
  }

  ngOnDestroy() {
    this.subscribe(this.formDataService.cancelFormWizard(this.formWizardData.wizardId), (response) => {
      if (response.data && response.data.state) {
        LogService.debug(this, this.onCancel.name, `Wizard with wizardId ${this.formWizardData.wizardId} cancelled`, null);
      } else {
        LogService.debug(this, this.onCancel.name, 'Cancel Wizard error', response.data?.error);
      }
    });
  }

  initFormWizard() {
    this.subscribe(this.formDataService.createFormWizard(this.wizardName), ({ data }) => {
      LogService.debug(this, this.ngOnInit.name, 'New wizard created', data);
      this.formWizardData = data;
      this.wizardId = data.wizardId;
      this.cdRef.detectChanges();
      // this.stepper.selectedIndex = 4; // ONLY FOR DEV
    });
  }

  getStepperIcon(index: number): string {
    let icon = '';
    if (this.stepper.selectedIndex > index) {
      icon = 'check';
    } else if (this.stepper.selectedIndex < index) {
      icon = 'edit';
    }
    return icon;
  }

  openDialogWithRef(ref: TemplateRef<any>) {
    this.dialog.open(ref, { autoFocus: false, width: '50%' });
  }

  /** WIZARD STEPPER METHODS */

  apply(formStepData: GenericFormField[], index: number) {
    // LogService.debug(this, this.apply.name, 'Apply data changes', formStepData);
    const request: FormFieldDataRequest[] = this.buildRequest(formStepData);
    this.subscribe(
      this.formDataService.putFormWizardStepData(this.wizardId, this.formWizardData.steps[index].formstepStep, request, true),
      (response) => {
        if (response.data && response.data.state) {
          this.formStep.loadStepData();
        } else if (response.data && !response.data.state) {
          this.error = response.data.error;
          this.errorMessage = response.data.errorMessage;
        } else {
          this.error = 'Empty Response';
        }
      }
    );
  }

  progressCompleted(response: FormWizardResponse) {
    if (response) {
      this.error = response.error;
      this.errorMessage = response.errorMessage;
      if (this.isValidationStep()) {
        this.isValidationFinished = this.validationStep.completed;
      } else if (this.isFinishStep()) {
        this.isFinished = this.finishStep.completed;
      }
    }
    // null response means http error handled by interceptor
  }

  validateStepData() {
    let hasError = false;
    // Check for required and decimal value
    this.saveSubj.next();
    hasError = this.hasError(this.currentStepData);
    // Regex check
    this.currentStepData.forEach(({ formfieldEntityRegex, formfieldEntityRegexerrormsg, formfieldEntityText }) => {
      if (formfieldEntityText && formfieldEntityRegex) {
        const re = new RegExp(formfieldEntityRegex);
        const matched = formfieldEntityText.toString().match(re);
        if (!matched) {
          this.messageNotifierService.showWarningMessage(formfieldEntityRegexerrormsg);
          hasError = true;
        }
      }
    });

    if (!hasError) {
      const request: FormFieldDataRequest[] = this.buildRequest(this.currentStepData);
      this.subscribe(
        this.formDataService.putFormWizardStepData(
          this.wizardId,
          this.formWizardData.steps[this.stepper.selectedIndex].formstepStep,
          request,
          false
        ),
        (response) => {
          if (response.data && response.data.state) {
            // this.previousStep = this.formWizardData.steps[this.stepper.selectedIndex];
            // this.nextStep = this.formWizardData.steps.find((s) => s.formstepStep === this.castToInt(response.data.nextStep));
            this.goToSelectedNextStep();
          } else if (response.data && !response.data.state) {
            this.error = response.data.error;
            this.errorMessage = response.data.errorMessage;
          } else {
            this.error = 'Empty Response';
          }
        }
      );
    } else {
      return null;
    }
  }

  commit() {
    this.subscribe(this.formDataService.commitFormData(this.wizardId), (response) => {
      if (response.data && response.data.state) {
        // this.previousStep = this.formWizardData.steps[this.stepper.selectedIndex];
        // this.nextStep = this.formWizardData.steps.find((s) => s.formstepStep === this.castToInt(response.data.nextStep));
        this.goToSelectedNextStep();
      } else if (response.data && !response.data.state) {
        this.error = response.data.error;
        this.errorMessage = response.data.errorMessage;
      } else {
        this.error = 'Empty Response';
      }
    });
  }

  checkValidity(formStepData: GenericFormField[], index: number) {
    // LogService.debug(this, this.checkValidity.name, 'Check changes validity', formStepData);
    this.currentStepData = formStepData;
    // // GET REQUIRED FIELDS
    // const requiredGenericFields = this.currentStepData
    //   .filter(
    //     (fsd) =>
    //       fsd.formfieldKindId === FormFieldConfigurationKind.SEARCH ||
    //       fsd.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
    //       fsd.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
    //       fsd.formfieldKindId === FormFieldConfigurationKind.CHIPS
    //   )
    //   .filter((fsd) => fsd.formfieldRequired === GenericFormFieldRequiredType.required && !fsd.isHidedField() && !fsd.isReadonlyField());
    // const requiredStepControlFields = this.currentStepData
    //   .filter((fsd) => fsd.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL)
    //   .filter((fsd) => !fsd.isHidedField() && !fsd.isReadonlyField());

    // const isRequiredValid = requiredGenericFields.every((fsd) => fsd.isRequiredControlValid);
    // const isStepControlValid = requiredStepControlFields.every((fsd) => fsd.isStepControlValid);
    // this.isCurrentStepValid = isRequiredValid && isStepControlValid;
    this.cdRef.detectChanges();
  }

  private buildRequest(formStepData: GenericFormField[]): FormFieldDataRequest[] {
    return formStepData
      .filter(
        (fsd) =>
          fsd.formfieldKindId === FormFieldConfigurationKind.SEARCH ||
          fsd.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
          fsd.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
          fsd.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL ||
          fsd.formfieldKindId === FormFieldConfigurationKind.CHIPS ||
          fsd.formfieldKindId === FormFieldConfigurationKind.MAILTEMPLATE
      )
      .map((fsd) => {
        return new FormFieldDataRequest(fsd);
      });
  }

  private hasError(formStepData: GenericFormField[]): boolean {
    const m = this.hasError.name;

    let hasError = false;
    let hasRequiredError = false;
    formStepData.forEach((field) => {
      if (
        field.formfieldEntityText &&
        field.formfieldEntityType === 'decimal' &&
        this.decimalCheckerService.hasErrorFormField(field.formfieldEntityText, field)
      ) {
        hasError = true;
      }
      if (
        field.formfieldRequired === GenericEntityRequiredType.required &&
        !field.isHidedField() &&
        !field.isReadonlyField() &&
        (field.formfieldKindId === FormFieldConfigurationKind.SEARCH ||
          field.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
          field.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
          field.formfieldKindId === FormFieldConfigurationKind.CHIPS) &&
        !field.isRequiredControlValid
      ) {
        LogService.warning(this, m, `Missing required value: ${this.translate.instant(field.formfieldTextname)}`, null);
        hasError = true;
        hasRequiredError = true;
      }
      if (
        field.formfieldRequired === GenericEntityRequiredType.required &&
        !field.isHidedField() &&
        !field.isReadonlyField() &&
        field.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL &&
        !field.isStepControlValid
      ) {
        LogService.warning(this, m, `Missing required value: ${this.translate.instant(field.formfieldTextname)}`, null);
        hasError = true;
        hasRequiredError = true;
      }
    });
    if (hasRequiredError) {
      this.messageNotifierService.showWarningMessage(`${this.translate.instant('toastr_missing_required_value')}`);
    }

    return hasError;
  }

  /* Stepper Controller */
  private goToSelectedPreviousStep() {
    const m = this.goToSelectedPreviousStep.name;
    if (this.isFinishStep()) {
      this.stepper.selectedIndex = this.formWizardData.steps.length - 1; //selectedIndex start from 0
    } else {
      this.stepper.previous();
    }
    this.cdRef.detectChanges();
  }
  private goToSelectedNextStep() {
    const m = this.goToSelectedNextStep.name;
    this.stepper.next();
    this.cdRef.detectChanges();
  }

  onBack() {
    this.error = null;
    this.isValidationFinished = false;
    this.isFinished = false;
    if (this.isFinishStep() && this.finishStep.createdEntityDatasource.data.length > 0) {
      this.subscribe(
        this.coreService.deletionEntities(
          this.finishStep.createdEntityDatasource.data.map((e) => {
            return { entityId: e.wizarddataId, entityKind: e.wizarddataKind };
          })
        ),
        () => {
          this.goToSelectedPreviousStep();
        }
      );
    } else {
      this.goToSelectedPreviousStep();
    }
  }

  onNext() {
    if (!this.isFinishStep() && !this.isValidationStep()) {
      // this.saveSubj.next();

      this.validateStepData();
      // setTimeout(() => {
      //   this.validateStepData();
      // }, 100);
    } else if (this.isValidationStep()) {
      // this.saveSubj.next();
      this.commit();
    } else {
      this.subscribe(this.translate.reloadLang(this.translate.currentLang), async () => {
        this.finishWizardEvEm.emit();
      });
      this.messageNotifierService.showSuccessMessage('toastr_success');
    }
  }

  onCancel() {
    this.cancelWizardEvEm.emit();
  }

  isNextDisabled() {
    if (!this.isFinishStep() && !this.isValidationStep()) {
      return false;
    } else if (this.isValidationStep()) {
      return !this.isValidationFinished || !this.validationStep.isValidationOk;
    } else if (this.isFinishStep()) {
      return !this.isFinished || !this.finishStep.isFinishOk;
    }
  }

  isFirstStep() {
    return this.stepper && this.stepper.selectedIndex === 0;
  }

  isValidationStep() {
    return this.stepper && this.formWizardData && this.stepper.selectedIndex + 1 === this.formWizardData.steps.length + 1; // selectedIndex start from 0
  }

  isFinishStep() {
    return this.stepper && this.formWizardData && this.stepper.selectedIndex + 1 === this.formWizardData.steps.length + 2; // selectedIndex start from 0
  }

  get Entities() {
    return Entities;
  }
}
