import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MoveAllDesksWizardEffects } from './effects';
import * as fromMoveAllDesksWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromMoveAllDesksWizard.moveAllDesksWizardFeatureKey, fromMoveAllDesksWizard.reducer),
    EffectsModule.forFeature([MoveAllDesksWizardEffects]),
  ],
})
export class MoveAllDesksWizardStoreModule {}
