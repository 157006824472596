import { CdkConnectedOverlay, ConnectedPosition, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Entity } from 'src/app/models/entity';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { RootStoreState } from 'src/app/root-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from '../../base-components/format-component';

@Component({
  selector: 'app-generic-grid-filter-singleselection-panel, generic-grid-filter-singleselection-panel',
  templateUrl: './generic-grid-filter-singleselection-panel.component.html',
  styleUrls: ['./generic-grid-filter-singleselection-panel.component.scss'],
})
export class GenericGridFilterSingleselectionPanelComponent extends FormatComponent implements OnInit {
  @ViewChild('filterSingleselectionOverlay') filterOverlay: CdkConnectedOverlay;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('searchInputAutocompleteTrigger') autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('filterInput', { static: false })
  set input(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
      if (this.autocompleteTrigger) {
        this.autocompleteTrigger.closePanel();
        this.matAutocompletPanelClosed();
      } else {
        this.matAutocompletPanelClosed();
      }
    }
  }
  @Input() filtersMapSubj: Observable<Map<string, any[]>>;
  @Input() filterApiPath: string;
  @Input() filterTitle: string;
  @Input() filterName: string;
  @Input() isStringFilter = false;
  @Input() emitDefaultFilter = false;
  @Output() filterIdsEvEm = new EventEmitter<any[]>();

  defaultSettedFilters: any[];
  filterString: string;
  filterTooltip: string;
  isOverlayOpen = false;
  overlayPositions: ConnectedPosition[] = [
    new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
  ];

  selectedfilterOptions: Entity[] = [];
  dataSourceTable: MatTableDataSource<Entity>;
  displayedColumnsTable: string[] = ['select', 'entityName'];
  displayedColumnsSelectionTable: string[] = ['entityName'];
  selectedEntity: Entity;

  pageSizeList: number[] = [];
  filter: string;
  showSearchBar = false;
  request: PaginatedRequest;
  totalFilter: number;
  isFirstLoaded = false;
  defaultFilters: number[];
  isLoading: boolean;
  noEntityData: boolean;
  lastUsedFilters: string[];

  constructor(private coreDataService: CoreDataService, protected store: Store<RootStoreState.State>, private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.filterApiPath === null || this.filterApiPath === undefined) {
      throw new TypeError(`The input 'filterApiPath' is required`);
    }
    this.filterString = this.filterTitle;
    this.filterTooltip = null;
    this.dataSourceTable = new MatTableDataSource();
    this.dataSourceTable.filterPredicate = (data, filter: string): boolean => data.entityName.toLowerCase().includes(filter);

    // #ISSUE #5983 Grid Filters Improvements
    // this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesPaginatonsOptions)), (pageSizeOpt) => {
    //   this.pageSizeList = pageSizeOpt;
    //   if (this.paginator) {
    //     this.paginator.pageSize = pageSizeOpt[0];
    //   }
    // });
    this.pageSizeList = [20]; //Fixed page size
    if (this.paginator) {
      this.paginator.pageSize = this.pageSizeList[0];
    }
    this.initRequest();
    this.loadFilterData();
    if (this.filtersMapSubj && this.filterName) {
      this.subscribe(this.filtersMapSubj, (map) => {
        if (map) {
          map.forEach((value, key) => {
            if (key === this.filterName) {
              this.defaultFilters = value;
            }
          });
        }
        if (this.defaultFilters) {
          this.defaultSettedFilters = this.defaultFilters;
          if (!this.isFirstLoaded) {
            this.loadFilterData();
          }
        }
      });
    }
  }

  openOverlay() {
    this.isOverlayOpen = true;
    if (!this.isFirstLoaded) {
      this.loadFilterData();
    }
    this.cdRef.detectChanges();
    if (this.filterOverlay) {
      const maxHeight = window.window.innerHeight - this.filterOverlay.overlayRef.overlayElement.offsetTop - 32;
      this.filterOverlay.overlayRef.updateSize({ maxHeight: maxHeight });
    }
    if (this.paginator) {
      this.paginator.pageSize = this.pageSizeList[0];
      this.dataSourceTable.paginator = this.paginator;
    }
  }

  closeOverlay() {
    this.isOverlayOpen = false;
    this.filter = '';
    this.setFilter(null);
  }

  protected setFilter(filter: string) {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.request = {
      ...this.request,
      pageIndex: 0,
      filter,
    };
  }

  loadFilterData() {
    this.isLoading = true;
    this.noEntityData = false;
    this.isFirstLoaded = true;
    this.subscribe(
      this.coreDataService.getFilterOptions(this.request, this.filterApiPath),
      (response) => {
        this.lastUsedFilters = response.filters;
        if (response.data) {
          this.showSearchBar = response.data && response.data.length >= this.pageSizeList[0];
          this.totalFilter = this.showSearchBar ? response.data.length : null;
          this.dataSourceTable.data = response.data;
          this.cdRef.detectChanges();

          if (this.filterOverlay && this.filterOverlay.overlayRef) {
            const maxHeight = window.window.innerHeight - this.filterOverlay.overlayRef.overlayElement.offsetTop - 32;
            this.filterOverlay.overlayRef.updateSize({ maxHeight: maxHeight });
          }
          if (this.paginator) {
            this.paginator.pageSize = this.pageSizeList[0];
            this.dataSourceTable.paginator = this.paginator;
          }
          if (this.defaultSettedFilters && this.defaultSettedFilters.length > 0) {
            const filter = this.dataSourceTable.data.find((f) =>
              this.isStringFilter ? f.entityName === this.defaultSettedFilters[0] : f.entityId === this.defaultSettedFilters[0]
            );

            if (filter) {
              this.selectedEntity = filter;
            } else {
              this.selectedEntity = this.dataSourceTable.data[0];
            }
            this.filterString = `${this.filterTitle}${this.selectedEntity != null ? ': ' : ''}${this.selectedEntity.entityName}`;
            this.filterTooltip = this.selectedEntity != null ? this.selectedEntity.entityName : null;
            // LogService.debug(this, this.filterTitle, 'filterIdsEvEm', this.selectedEntity.entityId);
            if (this.emitDefaultFilter) {
              this.filterIdsEvEm.emit(this.isStringFilter ? [this.selectedEntity.entityName] : [this.selectedEntity.entityId]);
            }
          } else {
            this.selectedEntity = this.dataSourceTable.data[0];
            this.filterString = `${this.filterTitle}${this.selectedEntity != null ? ': ' : ''}${this.selectedEntity.entityName}`;
            this.filterTooltip = this.selectedEntity != null ? this.selectedEntity.entityName : null;
            this.filterIdsEvEm.emit(this.isStringFilter ? [this.selectedEntity.entityName] : [this.selectedEntity.entityId]);
          }
          this.isLoading = false;
        } else {
          this.noEntityData = true;
          this.isLoading = false;
          this.isFirstLoaded = false;
        }
      },
      (error) => (this.isFirstLoaded = false)
    );
  }

  applyFilterTable(filterValue: string) {
    if (filterValue && filterValue.length >= 3) {
      // this.setFilter(filterValue);
      this.dataSourceTable.filter = filterValue.trim().toLocaleLowerCase();
    } else {
      if (!filterValue || (filterValue && filterValue.length === 0)) {
        this.dataSourceTable.filter = null;
      }
    }
  }

  initRequest() {
    this.filter = '';
    // let pagesize = 20;
    // if (this.pageSizeList && this.pageSizeList.length !== 0) {
    //   pagesize = this.pageSize ? this.pageSize : this.pageSizeList[0];
    // }

    this.request = {
      pageIndex: 0,
      pageSize: null,
      orderBy: 'entity_name',
      sort: 'asc',
      filter: this.filter,
      filterMap: null,
    };
  }

  resetFilter() {
    if (this.filter) {
      this.filter = '';
      this.applyFilterTable(this.filter);
    }
  }

  onEntitySelected(row: Entity) {
    this.selectedEntity = row;
    LogService.debug(this, this.filterTitle, 'filterIdsEvEm', this.selectedEntity.entityId);
    this.filterString = `${this.filterTitle}${this.selectedEntity != null ? ': ' : ''}${this.selectedEntity.entityName}`;
    this.filterTooltip = this.selectedEntity != null ? this.selectedEntity.entityName : null;
    this.filterIdsEvEm.emit(this.isStringFilter ? [this.selectedEntity.entityName] : [this.selectedEntity.entityId]);
  }

  // onCheckboxClicked(row: Entity) {
  //   this.selection.toggle(row);
  //   LogService.debug(
  //     this,
  //     this.filterTitle,
  //     'filterIdsEvEm',
  //     this.selection.selected.map((f) => f.entityId)
  //   );
  //   this.filterString = `${this.filterTitle}${this.selection.selected.length > 0 ? ': ' : ''}${this.selection.selected
  //     .map((f) => f.entityName)
  //     .join(', ')}`;
  //   this.filterTooltip = this.selection.selected.length > 0 ? this.selection.selected.map((f) => f.entityName).join(', ') : null;
  //   this.filterIdsEvEm.emit(this.selection.selected.map((f) => (this.isStringFilter ? f.entityName : f.entityId)));
  // }
}
