import { createAction } from '@ngrx/store';

export const setError = createAction('[ErrorHandling] Set Error');

export const resetError = createAction('[ErrorHandling] Reset Error');

export const setSvgLoadError = createAction('[ErrorHandling] Set Svg Error');

export const setServerUnreachableError = createAction('[ErrorHandling] Set Server Unreachable Error');

export const resetServerUnreachableError = createAction('[ErrorHandling] Reset Server Unreachable Error');
