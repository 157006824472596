<div class="base-form-field text" (click)="onItemClick()">
  <div class="label-container">
    <span
      [class.form-field-text-kind-normal]="formFieldData.formfieldTextKindId === FormFieldTextKind.DEFAULT"
      [class.form-field-text-kind-label]="formFieldData.formfieldTextKindId === FormFieldTextKind.LABEL"
      [class.form-field-text-size-normal]="formFieldData.formfieldTextSizeId === FormFieldTextSize.NORMAL"
      [class.form-field-text-size-medium]="formFieldData.formfieldTextSizeId === FormFieldTextSize.MEDIUM"
      [class.form-field-text-size-high]="formFieldData.formfieldTextSizeId === FormFieldTextSize.HIGH"
      [class.form-field-text-weight-normal]="formFieldData.formfieldTextWeightId === FormFieldTextWeight.NORMAL"
      [class.form-field-text-weight-bold]="formFieldData.formfieldTextWeightId === FormFieldTextWeight.BOLD"
    >
      {{ formFieldData.formfieldTextname | translate }}
    </span>
    <span *ngIf="formFieldData.formfieldTextKindId === FormFieldTextKind.LABEL && formFieldData.formfieldRequired" class="requiredField"
      >*</span
    >
  </div>
  <button class="remove-button" (click)="remove(); $event.stopPropagation()" mat-icon-button *ngIf="isEditMode">
    <mat-icon>close</mat-icon>
  </button>
</div>
