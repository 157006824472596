import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkConnectedOverlay, ConnectedPosition, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridFilter, GridFilterType } from 'src/app/models/grid-filter';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from '../../base-components/format-component';
import { GenericGridFilterPanelComponent } from '../generic-grid-filter-panel/generic-grid-filter-panel.component';
@Component({
  selector: 'app-generic-dynamic-grid-filters, generic-dynamic-grid-filters',
  templateUrl: './generic-dynamic-grid-filters.component.html',
  styleUrls: ['./generic-dynamic-grid-filters.component.scss'],
})
export class GenericDynamicGridFiltersComponent extends FormatComponent implements OnInit {
  @ViewChildren(GenericGridFilterPanelComponent) genericGridFilters: QueryList<GenericGridFilterPanelComponent>;
  @ViewChild('morefilterOverlay') filterOverlay: CdkConnectedOverlay;
  @Input() dynamicFilters: GridFilter[];
  @Input() gridName: string;
  @Input() pathId: number;
  @Input() queryParams: string;
  @Input() disableDefaultFilter: boolean;
  @Input() clearFilterSubject: Observable<void>;
  @Input() filtersMapSubject: BehaviorSubject<Map<string, any[]>>;
  @Output() filterIdsEvEm = new EventEmitter<Map<string, any[]>>();

  disableSort = false;
  filtersMap = new Map<string, any[]>();
  displayedColumnsTable: string[] = ['gridfilterName', 'select', 'down', 'up'];
  dataSourceTable: MatTableDataSource<GridFilter>;
  isOverlayOpen = false;
  overlayPositions: ConnectedPosition[] = [
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }),
  ];

  constructor(private coreDataService: CoreDataService, private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.dataSourceTable = new MatTableDataSource(this.dynamicFilters);
    this.cdRef.detectChanges();
    const filtersWithDefaults = this.dynamicFilters.filter(
      (f) => f.gridfilterFavorited && f.gridfilterDefaultValues != null && f.gridfilterDefaultValues.length > 0
    );
    if (!this.disableDefaultFilter && filtersWithDefaults.length > 0) {
      filtersWithDefaults.forEach((f) => {
        let defaultValues: any[];
        switch (f.gridfilterType) {
          case GridFilterType.integer:
            defaultValues = f.gridfilterDefaultValues.map((v) => this.castToInt(v));
            break;
          default:
            defaultValues = f.gridfilterDefaultValues;
            break;
        }
        this.filtersMap.set(f.gridfilterVariable, defaultValues);
      });
      this.filtersMapSubject.next(this.filtersMap);
      // this.filterIdsEvEm.emit(this.filtersMap);
    }
  }

  emitFilterIds(filterValues: any[], filterVariable: string) {
    if (filterValues && filterValues.length > 0) {
      this.filtersMap.set(filterVariable, filterValues);
    } else {
      this.filtersMap.delete(filterVariable);
    }
    // Clear dependent filters
    const dependantFilters = this.dynamicFilters.filter((df) => df.gridfilterReferenceFilterIds != null);
    if (dependantFilters.length > 0) {
      dependantFilters.forEach((df) => {
        if (df.gridfilterReferenceFilterIds === filterVariable) {
          this.filtersMap.delete(df.gridfilterVariable);
          const filter = this.genericGridFilters.find((ggf) => ggf.filterName === df.gridfilterVariable);
          if (filter) filter.clearFilterSelection();
        }
      });
    }
    this.filterIdsEvEm.emit(this.filtersMap);
    this.cdRef.detectChanges();
  }

  changeFavorited(row: GridFilter) {
    row.gridfilterFavorited = !row.gridfilterFavorited;
    this.subscribe(
      this.coreDataService.changeDynamicFiltersFavoriteByGridName(this.gridName, row.gridfilterId, row.gridfilterFavorited),
      (response) => {
        LogService.info(this, this.changeFavorited.name, 'Favorite changed', response);
      }
    );
  }

  switchOverlay() {
    this.isOverlayOpen = !this.isOverlayOpen;
  }

  closeOverlay() {
    this.isOverlayOpen = false;
  }

  apiPath(filter: GridFilter) {
    let path = filter.gridfilterPath;
    if (this.pathId != null) {
      path = path.replace('{pathId}', this.pathId.toString());
    }
    if (this.queryParams != null) {
      path = path.replace('{queryParams}', this.queryParams);
    }
    if (filter.gridfilterReferenceFilterIds != null) {
      const filtervalues = this.filtersMap.get(filter.gridfilterReferenceFilterIds);
      if (filtervalues && filtervalues.length > 0) {
        path =
          this.queryParams != null
            ? `${path}&${filter.gridfilterReferenceFilterIds}=${filtervalues.join(',')}`
            : `${path}?${filter.gridfilterReferenceFilterIds}=${filtervalues.join(',')}`;
      }
    }
    return path;
  }

  dropStep(event: CdkDragDrop<GridFilter[]>) {
    moveItemInArray(this.dataSourceTable.data, event.previousIndex, event.currentIndex);
    this.dataSourceTable._updateChangeSubscription();
    this.changeSort();
  }

  moveUp(index: number) {
    moveItemInArray(this.dataSourceTable.data, index, index - 1);
    this.dataSourceTable._updateChangeSubscription();
    this.changeSort();
  }
  moveDown(index: number) {
    moveItemInArray(this.dataSourceTable.data, index, index + 1);
    this.dataSourceTable._updateChangeSubscription();
    this.changeSort();
  }

  changeSort() {
    this.disableSort = true;
    const ids = this.dataSourceTable.data.map((r) => r.gridfilterId);
    this.subscribe(this.coreDataService.changeDynamicFiltersSortOrderByGridName(this.gridName, ids), (response) => {
      LogService.info(this, this.changeFavorited.name, 'Sorting changed', response);
      this.disableSort = false;
    });
  }

  get availableFilters() {
    return this.dynamicFilters != null ? this.dynamicFilters.filter((f) => f.gridfilterFavorited) : [];
  }

  get GridFilterType() {
    return GridFilterType;
  }
}
