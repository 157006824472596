import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConnectedPosition, ConnectionPositionPair } from '@angular/cdk/overlay';
import { KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store, select } from '@ngrx/store';
import { PropertySearchElement } from 'src/app/models/grid-filter';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from '../../base-components/format-component';

@Component({
  selector: 'app-generic-dynamic-grid-search-elements, generic-dynamic-grid-search-elements',
  templateUrl: './generic-dynamic-grid-search-elements.component.html',
  styleUrls: ['./generic-dynamic-grid-search-elements.component.scss'],
})
export class GenericDynamicGridSearchElementsComponent extends FormatComponent implements OnInit, AfterViewInit {
  @ViewChildren('searchElementButtons') searchElementButtons: QueryList<MatButtonToggle>;
  @ViewChild('searchInputField') searchInputField: ElementRef;
  @ViewChild('searchInputAutocompleteTrigger') autocompleteTrigger: MatAutocompleteTrigger;
  @Input() searchElements: PropertySearchElement[];
  @Input() gridName: string;
  @Input() lastUsedFilters: string[];
  @Input() filtersMap = new Map<string, any[]>();
  @Input() isExternalFilterSetted: boolean;
  @Input() disableAutofocus: boolean;
  @Output() setFilterEvEm = new EventEmitter<string>();
  @Output() setFilterRuleEvEm = new EventEmitter<number[]>();
  @Output() setClipboardFilterEvEm = new EventEmitter<string>();
  @Output() setSearchElementsEvEm = new EventEmitter<string[]>();
  @Output() clearFilterEvEm = new EventEmitter<void>();
  @Output() clearAllFiltersEvEm = new EventEmitter<void>();

  static FILTER_RULE_SEARCH_ANY_WORD: number = 3;

  filter: string = '';
  clipboardFilter: string;

  searchFilterrules: KeyValue<string, string>[];
  selectedFilterules: KeyValue<string, string>;
  selectedFilteruleId: number;

  selectedSearchElements: string[] = [];
  disableSort = false;
  displayedColumnsTable: string[] = ['propertysearchelementName', 'select', 'down', 'up'];
  dataSourceTable: MatTableDataSource<PropertySearchElement>;
  isOverlayOpen = false;
  overlayPositions: ConnectedPosition[] = [
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }),
  ];

  constructor(
    private dialog: MatDialog,
    protected store: Store<RootStoreState.State>,
    private cdRef: ChangeDetectorRef,
    private coreDataService: CoreDataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataSourceTable = new MatTableDataSource(this.searchElements);
    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesFilterRulesOptions)), (filterRules) => {
      this.searchFilterrules = filterRules;
      this.selectedFilterules = this.searchFilterrules && this.searchFilterrules.length > 0 ? this.searchFilterrules[0] : null;
      this.selectedFilteruleId = this.castToInt(this.selectedFilterules.key);
    });
  }

  ngAfterViewInit(): void {
    if (this.searchInputField && !this.disableAutofocus) {
      setTimeout(() => {
        this.searchInputField.nativeElement.focus();
        if (this.autocompleteTrigger) {
          this.autocompleteTrigger.closePanel();
          this.matAutocompletPanelClosed();
        } else {
          this.matAutocompletPanelClosed();
        }
      }, 200);
    }
  }

  applyFilterTable(filterValue: string) {
    if (filterValue && filterValue.length >= 3) {
      this.setFilterEvEm.emit(filterValue);
    } else {
      if (!filterValue || (filterValue && filterValue.length === 0)) {
        this.filter = '';
        this.clearFilterEvEm.emit();
      }
    }
  }

  async openClipboardFilterDialog(ref: TemplateRef<any>) {
    // if (this.clipboardFilter == null) {
    //   this.clipboardFilter = await navigator.clipboard.readText();
    // }
    this.dialog.open(ref, { autoFocus: false, width: '50%', height: '50%', panelClass: 'generic-dynamic-grid-clipboard-filter-dialog' });
  }

  setClipboardFilter() {
    this.filter = this.clipboardFilter;
    this.selectedFilterules =
      this.searchFilterrules && this.searchFilterrules.length > 0
        ? this.searchFilterrules.find(
            (r) => this.castToInt(r.key) === GenericDynamicGridSearchElementsComponent.FILTER_RULE_SEARCH_ANY_WORD
          )
        : null;
    this.selectedFilteruleId = GenericDynamicGridSearchElementsComponent.FILTER_RULE_SEARCH_ANY_WORD;
    this.setClipboardFilterEvEm.emit(this.clipboardFilter);
  }

  resetClipboardFilter() {
    this.clipboardFilter = null;
    this.filter = null;
    this.selectedFilterules = this.searchFilterrules && this.searchFilterrules.length > 0 ? this.searchFilterrules[0] : null;
    this.selectedFilteruleId = this.castToInt(this.selectedFilterules.key);
    this.setClipboardFilterEvEm.emit(this.clipboardFilter);
  }

  setFilterRule(values?: number[]) {
    if (values) {
      const value = values.find((id) => id !== this.selectedFilteruleId);
      this.selectedFilteruleId = value != null ? value : 0;
    } else {
      this.selectedFilteruleId = 0;
    }
    this.setFilterRuleEvEm.emit([this.selectedFilteruleId]);
  }

  setSearchElement(value?: string) {
    const i = this.selectedSearchElements.indexOf(value);
    if (i !== -1) {
      this.selectedSearchElements.splice(i, 1);
    } else {
      this.selectedSearchElements.push(value);
    }
    this.setSearchElementsEvEm.emit(this.selectedSearchElements);
  }

  isSearchElementChecked(value: string) {
    this.selectedSearchElements.includes(value);
  }

  isFiltersSetted(): boolean {
    return this.isExternalFilterSetted || this.filtersMap.size > 0 || this.filter != '';
  }

  clearSearchFilter() {
    if (this.filter) {
      this.filter = '';
      this.clearFilterEvEm.emit();
    }
  }

  clearAllFilters() {
    if (this.filter) {
      this.filter = '';
    }
    this.selectedSearchElements = [];
    if (this.searchElementButtons) {
      this.searchElementButtons.forEach((b) => (b.checked = false));
    }
    this.cdRef.detectChanges();
    this.clearAllFiltersEvEm.emit();
  }

  changeActivated(row: PropertySearchElement) {
    row.propertysearchelementActive = !row.propertysearchelementActive;
    this.subscribe(
      this.coreDataService.changeSearchElementActiveByGridName(this.gridName, row.propertysearchelementId, row.propertysearchelementActive),
      (response) => {
        LogService.info(this, this.changeActivated.name, 'Active changed', response);
      }
    );
    this.setSearchElementsEvEm.emit(
      this.dataSourceTable.data.filter((d) => d.propertysearchelementActive).map((d) => d.propertysearchelementElement)
    );
  }

  switchOverlay() {
    this.isOverlayOpen = !this.isOverlayOpen;
  }

  closeOverlay() {
    this.isOverlayOpen = false;
  }

  dropStep(event: CdkDragDrop<PropertySearchElement[]>) {
    moveItemInArray(this.dataSourceTable.data, event.previousIndex, event.currentIndex);
    this.dataSourceTable._updateChangeSubscription();
    this.changeSort();
  }

  moveUp(index: number) {
    moveItemInArray(this.dataSourceTable.data, index, index - 1);
    this.dataSourceTable._updateChangeSubscription();
    this.changeSort();
  }
  moveDown(index: number) {
    moveItemInArray(this.dataSourceTable.data, index, index + 1);
    this.dataSourceTable._updateChangeSubscription();
    this.changeSort();
  }

  changeSort() {
    this.disableSort = true;
    const ids = this.dataSourceTable.data.map((r) => r.propertysearchelementId);
    this.subscribe(this.coreDataService.changeSearchElementSortOrderByGridName(this.gridName, ids), (response) => {
      LogService.info(this, this.changeSort.name, 'Sorting changed', response);
      this.disableSort = false;
    });
  }
}
