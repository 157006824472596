import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { BillingItem } from 'src/app/models/billingitem';
import { AddServiceTopackageDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-service-topackage-wizard/activation-step/addservices-due-date-info';
import { AddServiceTopackageInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-service-topackage-wizard/init-step/init-step';
import * as fromAddServiceTopackageWizard from './reducer';
import { State } from './state';

export const selectAddServiceTopackageWizardState = createFeatureSelector<State>(
  fromAddServiceTopackageWizard.addServiceTopackageWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectAddServiceTopackageWizardState,
  (state: State): AddServiceTopackageInitStep => state.initStep
);

export const selectDueDateStep = createSelector(
  selectAddServiceTopackageWizardState,
  (state: State): AddServiceTopackageDueDates => state.dueDatesStep
);

export const selectServicesStep = createSelector(selectAddServiceTopackageWizardState, (state: State): BillingItem[] => state.servicesStep);

export const selectAddServiceTopackageState = createSelector(
  selectAddServiceTopackageWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectAddServiceTopackageWizardState, (state: State): string => state.errorMessage);
