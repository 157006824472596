import {
  WizardCommunicationStage,
  WizardCommunicationStageType,
} from '../shared/wizard-common-steps/wizard-communication-step/wizard-communication-step.component';
import { Entity } from './entity';
import { MailTemplateFrame } from './mailtemplate/mail-template-particular';

export class TenantLedger extends Entity {
  tenantledgerDescription: string;
  tenantledgerDocumentText: string;
  tenantledgerEmailSenderAddress: string;
  tenantledgerEmailSenderName: string;
  tenantledgerId: number;
  tenantledgerIndividualPeriods: boolean;
  tenantledgerItemText: string;
  tenantledgerItemVarianceText: string;
  tenantledgerLanguage: string;
  tenantledgerLanguageId: number;
  tenantledgerName: string;
  tenantledgerSendingCostCentre: string;
  tenantledgerSendingOrderNumber: string;
  tenantledgerSendingProject: string;
  tenantledgerTenantId: number;
  tenantledgerTenat: string;
}

export class TenantLedgerCharging extends Entity {
  tenantledgerchargingEmailCc: string;
  tenantledgerchargingEmailTo: string;
  tenantledgerchargingEnds: string;
  tenantledgerchargingId: number;
  tenantledgerchargingStarts: string;
  tenantledgerchargingTenantAreaId: number;
  tenantledgerchargingTenantId: number;
  tenantledgerchargingTenantLedgerId: number;
  tenantledgerchargingTenat: string;
  tenantledgerchargingTenatArea: string;
  tenantledgerchargingTenatLedger: string;
  tenantledgerchargingVendor: string;
  tenantledgerchargingVendorId: number;
}

export class TenantLedgerParticulars {
  tenantledgerAddress: string;
  tenantledgerAddressId: number;
  tenantledgerDescription: string;
  tenantledgerDocumentText: string;
  tenantledgerEmailReplytoAddress: string;
  tenantledgerEmailReplytoName: string;
  tenantledgerEmailSenderAddress: string;
  tenantledgerEmailSenderName: string;
  tenantledgerId: number;
  tenantledgerIndividualPeriods: boolean;
  tenantledgerItemText: string;
  tenantledgerItemVarianceText: string;
  tenantledgerLanguage: string;
  tenantledgerLanguageId: number;
  tenantledgerInvoiceMailframe: MailTemplateFrame;
  tenantledgerInvoiceMailframeId: number;
  tenantledgerChargingMailframe: MailTemplateFrame;
  tenantledgerChargingMailframeId: number;
  tenantledgerName: string;
  tenantledgerSendingCostCentre: string;
  tenantledgerSendingOrderNumber: string;
  tenantledgerSendingProject: string;
  tenantledgerStyleSheetFile: string;
  tenantledgerStyleSheetFileId: number;
  tenantledgerTenant: string;
  tenantledgerTenantId: number;

  public constructor(init?: Partial<TenantLedgerParticulars>) {
    Object.assign(this, init);
  }
}

export class TenantLedgerParticularsRequest {
  tenantledgerAddressId: number;
  tenantledgerDescription: string;
  tenantledgerDocumentText: string;
  tenantledgerEmailReplytoAddress: string;
  tenantledgerEmailReplytoName: string;
  tenantledgerEmailSenderAddress: string;
  tenantledgerEmailSenderName: string;
  tenantledgerId: number;
  tenantledgerIndividualPeriods: boolean;
  tenantledgerItemText: string;
  tenantledgerItemVarianceText: string;
  tenantledgerLanguageId: number;
  tenantledgerInvoiceMailframe: MailTemplateFrame;
  tenantledgerChargingMailframe: MailTemplateFrame;
  tenantledgerName: string;
  tenantledgerSendingCostCentre: string;
  tenantledgerSendingOrderNumber: string;
  tenantledgerSendingProject: string;
  tenantledgerStyleSheetFileId: number;
  tenantledgerTenantId: number;
}

export class TenantLedgerParticularsStages {
  public static stages: WizardCommunicationStage[] = [
    new WizardCommunicationStage(1050, true, WizardCommunicationStageType.info),
    new WizardCommunicationStage(1051, true, WizardCommunicationStageType.invoice),
  ];
}
