import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { BaseResponse } from '../models/base-response';
import { CatalogPackageContent, CatalogPackageParticulars, CatalogPackageParticularsRequest } from '../models/catalog-package-particulars';
import { EntityWizardResponse } from '../models/forms/entity-wizard';
import { PackageTreeNode } from '../models/package';
import { PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class PackageDataService {
  getPackages(request: PaginatedRequest, packageId?: number, nodeId?: number): Observable<BaseResponse<PackageTreeNode[]>> {
    const m = this.getPackages.name;

    const path = environment.getEndpoint(ApiPath.Packages.PACKAGES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = packageId != null ? params.append('packageId', packageId.toString()) : params;
    params = nodeId != null ? params.append('filterIds', nodeId.toString()) : params;
    const retVal = this.http.get<BaseResponse<PackageTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getPackagesSpreadsheet(request: PaginatedRequest) {
    const m = this.getPackagesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Packages.PACKAGES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getPackageParticulars(): Observable<BaseResponse<CatalogPackageParticulars>> {
    const m = this.getPackageParticulars.name;

    const path = environment.getEndpoint(ApiPath.Packages.PACKAGES_PARTICULARS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<CatalogPackageParticulars>>(path);

    return retVal;
  }

  createPackage(request: CatalogPackageParticularsRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.createPackage.name;

    const path = environment.getEndpoint(ApiPath.Packages.PACKAGES_PARTICULARS);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);

    return retVal;
  }

  getPackagesContents(request: PaginatedRequest): Observable<BaseResponse<CatalogPackageContent[]>> {
    const m = this.getPackagesContents.name;

    const path = environment.getEndpoint(ApiPath.Packages.PACKAGES_CONTENTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<CatalogPackageContent[]>>(path, {
      params,
    });

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
