import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReplaceFeeditemsWizardEffects } from './effects';
import * as fromReplaceFeeditemsWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromReplaceFeeditemsWizard.replaceFeeditemsWizardFeatureKey, fromReplaceFeeditemsWizard.reducer),
    EffectsModule.forFeature([ReplaceFeeditemsWizardEffects]),
  ],
})
export class ReplaceFeeditemsWizardStoreModule {}
