<div class="base-form-field form-field-stepcontrol" (click)="onItemClick()">
  <div class="label-container" *ngIf="formFieldData.formfieldLabelShow">
    <ng-container *ngIf="formFieldData.formfieldLabelDisplay">
      <span class="label" showIfTruncated matTooltip="{{ formFieldData.formfieldTextname | translate }}"
        >{{ formFieldData.formfieldTextname | translate }}
      </span>
      <span *ngIf="formFieldData.formfieldRequired" class="requiredField">*</span>
      <span
        class="tooltip"
        *ngIf="formFieldData.formfieldInfotextname"
        matTooltip="{{ formFieldData.formfieldInfotextname | translate }}"
        [matTooltipClass]="'fo-mat-tooltip'"
        ><mat-icon class="entityTooltipIcon">info</mat-icon></span
      >
    </ng-container>
    <ng-container *ngIf="!formFieldData.formfieldLabelDisplay">
      <span class="label">&nbsp;</span>
    </ng-container>
  </div>
  <div class="value-container">
    <span class="value">
      <app-step-value-control [isEditing]="false" [stepValues]="mockedData"></app-step-value-control>
    </span>
  </div>
  <button class="remove-button" (click)="remove(); $event.stopPropagation()" mat-icon-button *ngIf="isEditMode">
    <mat-icon>close</mat-icon>
  </button>
</div>
