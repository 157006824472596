import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { ConsumerService } from 'src/app/models/consumerservice';
import { Entity } from 'src/app/models/entity';
import { OrderMarketDataInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/order-market-data-wizard/init-step/init-step';
import { OrderMarketDataRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/order-market-data-wizard/summary-step/order-market-data-request';

export const selectInitStep = createAction(
  '[Order Market Data Widget] Select Init Information Step',
  props<{ initStep: OrderMarketDataInitStep }>()
);

export const selectUserSelectionStep = createAction(
  '[Order Market Data Desk Step] Select User Selection Step',
  props<{ userSelectionStep: Entity }>()
);

export const selectReferenceProfileServicesStep = createAction(
  '[Order Market Data Desk Step] Select Reference Profile Services Step',
  props<{ referenceProfileServicesStep: ConsumerService[] }>()
);

export const selectFineTuneServicesStep = createAction(
  '[Order Market Data Desk Step] Select Fine Tune Services Step',
  props<{ fineTuneServicesStep: number[] }>()
);

export const exitOrderMarketDataWizard = createAction('[Order Market Data Wizard Page] Exit Order Market Data Wizard');

export const destroyWizard = createAction('[Order Market Data Wizard Page] Destroy Wizard');

export const createNewOrderMarketData = createAction(
  '[Order Market Data Confirmation Step] Order Market Data Information Step',
  props<{ request: OrderMarketDataRequest }>()
);

export const createNewOrderSuccess = createAction(
  '[Order Market Data Confirmation Step] Order Market Data Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewOrderFail = createAction(
  '[Order Market Data Confirmation Step] Order Market Data Information Step Fail',
  props<{ errorMessage: any }>()
);
