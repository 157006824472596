import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompactType, DisplayGrid, GridType, GridsterConfig } from 'angular-gridster2';
import { ResizedEvent } from 'angular-resize-event';
import { Observable } from 'rxjs';
import { FormFieldConfigurationKind } from 'src/app/models/forms/form-configuration-kind';
import {
  GenericEntityFormFieldEvent,
  GenericFormField,
  GenericFormFieldItem,
  GenericFormFieldRequiredType,
} from 'src/app/models/forms/form-field';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from '../../base-components/format-component';

@Component({
  selector: 'app-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss'],
})
export class GenericFormComponent extends FormatComponent implements OnInit {
  @Input() formFields: GenericFormField[];
  @Input() fixedContainerSize = true;
  @Input() isProductConfiguration = false;
  @Input() isWizardCss = false;
  @Input() isViewMode = false;
  @Input() isDirtyEnabled = false;
  @Input() showForeignDetailsButton = false;
  @Input() entityKind: string;
  @Input() entityId: string;
  @Input() saveSubj: Observable<void> = new Observable<void>();
  @Input() validationSubj: Observable<boolean> = new Observable<boolean>();
  @Input() mailpreviewSubj: Observable<void> = new Observable<void>();
  @Input() foreignFilterEntityKind: string;
  @Input() foreignFilterEntityId: number;
  @Output() searchChangeValueEvEm = new EventEmitter();
  @Output() changeValueEvEm = new EventEmitter();
  @Output() validateValueEvEm = new EventEmitter<GenericFormField>();
  @Output() originalValueEvEm = new EventEmitter();
  @Output() autoUpdateEvEm = new EventEmitter<GenericEntityFormFieldEvent>();
  @ViewChild('gridsterContainer') set gridsterContainer(el: ElementRef) {
    if (el && this.colWidth == null) {
      this.colWidth = Math.floor(el.nativeElement.clientWidth / this.columnNumber);
      this.options = this.getOptions();
      this.cdRef.detectChanges();
    }
  }

  genericFormFieldItems: GenericFormFieldItem[];
  originalCoordinates: GenericFormFieldItem[];
  options: GridsterConfig;
  readonly columnNumber = 48;
  readonly rowHeight = 20;
  colWidth: number;
  currentRegion: string;
  THOUSANDS_SEPARATOR: string;

  constructor(private cdRef: ChangeDetectorRef, protected store: Store<RootStoreState.State>) {
    super();
    this.store
      .select(PreferencesSelectors.selectPreferencesLanguageRegion)
      // Replace required for toLocaleString. Accept only Locale in format xx-XX
      .subscribe((langRegion) => (this.currentRegion = langRegion && langRegion.region ? langRegion.region.replace('_', '-') : 'en'));
    this.THOUSANDS_SEPARATOR = this.dateFormatterService.THOUSANDS_SEPARATOR;
  }

  gridsterContainerResized(event: ResizedEvent) {
    const width = event.newRect.width;
    const colWidthFloor = Math.floor(width / this.columnNumber);
    // LogService.debug(this, 'GenericFormComponent.gridsterContainerResized', 'width', width);
    // LogService.debug(this, 'GenericFormComponent.getOptions', 'colWidth', width / this.columnNumber);
    if (this.colWidth !== colWidthFloor) {
      this.colWidth = colWidthFloor;
      // LogService.debug(this, 'GenericFormComponent.getOptions', 'colWidthFloor', this.colWidth);
      this.options.fixedColWidth = this.colWidth;
      this.options.api.optionsChanged();
      this.cdRef.detectChanges();
    }
  }

  ngOnInit(): void {
    this.setBindedFields();
    this.originalValueEvEm.emit();
    this.changeValueEvEm.emit();
    this.genericFormFieldItems = this.formFields.map((d) => new GenericFormFieldItem(d, this.currentRegion, this.THOUSANDS_SEPARATOR));
    LogService.debug(this, this.ngOnInit.name, 'Gridster Items', this.genericFormFieldItems);
  }

  // isHiddenItemToKeep(item: GenericFormFieldItem) {
  //   if (item.hideBindedField && item.hideBindedField.isHidedField()) {
  //     return !item.hideBindedField.formfieldHideoncompact || !item.formfieldHideoncompact;
  //   }
  //   if (item.isHidedField()) {
  //     return !item.formfieldHideoncompact;
  //   }
  //   return false;
  // }

  onSearchChangedValue(index: number) {
    // LogService.debug(this, this.onSearchChangedValue.name, 'Gridster Items', this.genericFormFieldItems);
    this.setRequiredValidity(index);
    this.formFields[index].formfieldEntityText = this.genericFormFieldItems[index].formfieldEntityText;
    this.formFields[index].formfieldEntityValue = this.genericFormFieldItems[index].formfieldEntityValue;
    this.formFields[index].isRequiredControlValid = this.genericFormFieldItems[index].isRequiredControlValid;
    this.searchChangeValueEvEm.emit();
    if (!this.isProductConfiguration) {
      this.restoreItemsCoordinates();
    }
  }

  onInputFieldChangedValue(index: number) {
    LogService.debug(this, this.onInputFieldChangedValue.name, 'Gridster Items', this.genericFormFieldItems);
    this.setRequiredValidity(index);
    if (this.genericFormFieldItems[index].formfieldEntityType === 'percentage') {
      if (this.genericFormFieldItems[index].formfieldEntityText != null && this.genericFormFieldItems[index].formfieldEntityText != '') {
        const number = +parseFloat(this.genericFormFieldItems[index].formfieldEntityText).toFixed(4);
        const normalizedPercentage = parseFloat((number / 100).toFixed(6));
        this.formFields[index].formfieldEntityText = normalizedPercentage.toString();
        this.formFields[index].formfieldEntityValue = normalizedPercentage;
      } else {
        this.formFields[index].formfieldEntityText = null;
        this.formFields[index].formfieldEntityValue = null;
      }
    } else {
      const text =
        this.genericFormFieldItems[index].formfieldEntityText != null &&
        typeof this.genericFormFieldItems[index].formfieldEntityText === 'string'
          ? this.genericFormFieldItems[index].formfieldEntityText.trim()
          : this.genericFormFieldItems[index].formfieldEntityText;
      this.formFields[index].formfieldEntityText = text !== '' ? text : null;
      this.formFields[index].formfieldEntityValue = this.genericFormFieldItems[index].formfieldEntityValue;
    }
    this.formFields[index].formfieldEntitySteps = this.genericFormFieldItems[index].formfieldEntitySteps;
    this.formFields[index].formfieldEntityRelations = this.genericFormFieldItems[index].formfieldEntityRelations;
    this.formFields[index].isRequiredControlValid = this.genericFormFieldItems[index].isRequiredControlValid;
    this.formFields[index].isStepControlValid = this.genericFormFieldItems[index].isStepControlValid;
    this.changeValueEvEm.emit();
    if (!this.isProductConfiguration) {
      this.restoreItemsCoordinates();
    }
  }

  validateUniquenessFieldValue(index: number) {
    if (this.formFields[index].formfieldUnique != null) {
      this.validateValueEvEm.emit(this.formFields[index]);
    }
  }

  setRequiredValidity(index: number) {
    const fsd = this.genericFormFieldItems[index];
    if (
      fsd.formfieldKindId === FormFieldConfigurationKind.SEARCH ||
      fsd.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
      fsd.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
      fsd.formfieldKindId === FormFieldConfigurationKind.CHIPS
    ) {
      if (fsd.formfieldRequired === GenericFormFieldRequiredType.required && !fsd.isHidedField() && !fsd.isReadonlyField()) {
        if (fsd.formfieldEntityType === 'translation') {
          fsd.isRequiredControlValid = fsd.formfieldEntityTranslations.some((kv) => kv != null);
        } else if (fsd.formfieldEntityType === 'foreign') {
          fsd.isRequiredControlValid = fsd.formfieldEntityValue != null;
        } else {
          fsd.isRequiredControlValid =
            (fsd.formfieldEntityText != null && fsd.formfieldEntityText !== '') ||
            fsd.formfieldEntityValue != null ||
            (fsd.formfieldEntityRelations != null && fsd.formfieldEntityRelations.length > 0);
        }
      }
    }
  }

  onAutoUpdateInputFieldChangedValue(index: number) {
    this.formFields[index].filterStringOption = this.genericFormFieldItems[index].filterStringOption;
    this.formFields[index].formfieldEntityText = this.genericFormFieldItems[index].formfieldEntityText;
    this.formFields[index].formfieldEntityValue = this.genericFormFieldItems[index].formfieldEntityValue;
    const eventItem = {
      changedField: this.formFields[index],
      genericEntityFormFields: this.formFields,
      changedFieldItem: this.genericFormFieldItems[index],
      genericEntityFormFieldItems: this.genericFormFieldItems,
    } as GenericEntityFormFieldEvent;
    this.autoUpdateEvEm.emit(eventItem);
  }

  setBindedFields() {
    // Initial values of flags
    this.formFields.forEach((fwsf) => {
      if (fwsf.formfieldHideonfield != null) {
        fwsf.hideBindedField = this.formFields.find((fws) => fws.formfieldId === fwsf.formfieldHideonfield);
      }
      if (fwsf.formfieldReadonlyonfield != null) {
        fwsf.readonlyBindedField = this.formFields.find((fws) => fws.formfieldId === fwsf.formfieldReadonlyonfield);
      }
      if (fwsf.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL) {
        fwsf.isStepControlValid = false;
        fwsf.isRequiredControlValid = true;
      } else {
        if (fwsf.formfieldRequired === GenericFormFieldRequiredType.required) {
          if (fwsf.formfieldEntityType === 'translation') {
            fwsf.isRequiredControlValid = fwsf.formfieldEntityTranslations.some((kv) => kv != null);
          } else if (fwsf.formfieldEntityType === 'foreign') {
            fwsf.isRequiredControlValid = fwsf.formfieldEntityValue != null;
          } else {
            fwsf.isRequiredControlValid =
              (fwsf.formfieldEntityText != null && fwsf.formfieldEntityText !== '') ||
              fwsf.formfieldEntityValue != null ||
              (fwsf.formfieldEntityRelations != null && fwsf.formfieldEntityRelations.length > 0);
          }
        } else {
          fwsf.isRequiredControlValid = true;
        }
      }
    });
  }

  /** GRIDSTER CONFIGURATION */

  private restoreItemsCoordinates() {
    this.cdRef.detectChanges();
    this.genericFormFieldItems.forEach((item) => {
      item.x = item.formfieldX;
      item.y = item.formfieldY;
      item.cols = item.formfieldW;
      // if (item.isHidedField() && item.formfieldY === item.hideBindedField.formfieldY) {
      //   const parent = this.genericFormFieldItems.find((gffi) => gffi.formfieldId === item.hideBindedField.formfieldId);
      //   parent.cols = item.formfieldW + parent.cols;
      // }
    });
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
      this.cdRef.detectChanges();
    }
  }

  private getOptions(): GridsterConfig {
    const m = this.getOptions.name;

    const retVal: GridsterConfig = {
      displayGrid: DisplayGrid.None,
      gridType: GridType.Fixed,
      compactType: CompactType.CompactUp,
      resizable: { enabled: false },
      draggable: { enabled: false, dropOverItems: false },
      pushItems: true,
      minCols: this.columnNumber,
      maxCols: this.columnNumber,
      maxItemCols: this.columnNumber,
      fixedRowHeight: this.rowHeight,
      fixedColWidth: this.colWidth,
      setGridSize: this.fixedContainerSize ? false : true,
      outerMargin: false,
      margin: 0,
    };

    return retVal;
  }

  get FormFieldConfigurationKind() {
    return FormFieldConfigurationKind;
  }
}
