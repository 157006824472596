import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

export enum CronYearsOptions {
  everyYear = 1,
  everyNYearStartFrom,
  specificYears,
  rangeYears,
}

@Component({
  selector: 'app-cron-year-tab, cron-year-tab',
  templateUrl: './cron-year-tab.component.html',
  styleUrls: ['./cron-year-tab.component.scss'],
})
export class CronYearTabComponent implements OnInit {
  @Input() cronExpression: string;
  @Input() isEditing: boolean;
  @Output() yearsExpressionEvEm = new EventEmitter<string>();
  selectedOption: CronYearsOptions;
  yearsExpression: string;

  startingYear: number;
  rangeYearsOptions: number[];
  everyNYearOptions: number[];

  everyNYear: number;
  everyNYearStartFrom: number;

  //specificYears
  specificYears: number[] = [];

  //rangeYears
  rangeYearsStarts: number;
  rangeYearsEnds: number;
  constructor() {}

  ngOnInit(): void {
    this.startingYear = new Date().getFullYear();
    this.rangeYearsOptions = this.getRange(this.startingYear, this.startingYear + 20 - 1);
    this.everyNYearOptions = this.getRange(1, 20);
    this.everyNYear = this.everyNYearOptions[0];
    this.everyNYearStartFrom = this.rangeYearsOptions[0];
    this.rangeYearsStarts = this.rangeYearsOptions[0];
    this.rangeYearsEnds = this.rangeYearsOptions[0];
    this.parseCronExpression();
  }

  parseCronExpression() {
    this.yearsExpression = this.cronExpression.split(' ')[6];
    if (this.yearsExpression === '*') {
      this.selectedOption = CronYearsOptions.everyYear;
    } else if (this.yearsExpression.match(/^\d{4}\/\d{1,2}$/)) {
      this.selectedOption = CronYearsOptions.everyNYearStartFrom;
      const cron_parts = this.yearsExpression.split('/');
      this.everyNYear = parseInt(cron_parts[1]);
      this.everyNYearStartFrom = parseInt(cron_parts[0]);
    } else if (this.yearsExpression.match(/^\d{4}\-\d{4}$/)) {
      this.selectedOption = CronYearsOptions.rangeYears;
      const cron_parts = this.yearsExpression.split('-');
      this.rangeYearsStarts = parseInt(cron_parts[0]);
      this.rangeYearsEnds = parseInt(cron_parts[1]);
    } else if (this.yearsExpression.match(/^\d{4}$|^(\d{4},)+\d{4}$/)) {
      this.selectedOption = CronYearsOptions.specificYears;
      this.specificYears = this.yearsExpression.split(',').map((n) => parseInt(n));
    }
  }

  selectAllCheckboxes() {
    this.specificYears = JSON.parse(JSON.stringify(this.rangeYearsOptions));
    this.regenerateCron();
  }

  resetCheckboxes() {
    this.specificYears = [];
    this.regenerateCron();
  }

  checkboxSelectionChanged($event: MatCheckboxChange) {
    this.selectedOption = CronYearsOptions.specificYears;
    const value = parseInt($event.source.value);
    const newChecked = $event.checked;
    if (newChecked) {
      this.specificYears.push(value);
      this.specificYears.sort(function (a, b) {
        return a - b;
      });
    } else {
      const index = this.specificYears.indexOf(value, 0);
      if (index > -1) {
        this.specificYears.splice(index, 1);
      }
    }
    this.regenerateCron();
  }

  regenerateCron() {
    switch (this.selectedOption) {
      case CronYearsOptions.everyYear:
        this.yearsExpression = '*';
        break;

      case CronYearsOptions.everyNYearStartFrom:
        this.yearsExpression = `${this.everyNYearStartFrom}/${this.everyNYear}`;
        break;

      case CronYearsOptions.rangeYears:
        this.yearsExpression = `${this.rangeYearsStarts}-${this.rangeYearsEnds}`;
        break;

      case CronYearsOptions.specificYears:
        this.yearsExpression = this.specificYears.length > 0 ? this.specificYears.join(',') : this.startingYear.toString();
        break;
    }
    this.yearsExpressionEvEm.emit(this.yearsExpression);
  }

  isCheckboxChecked(n: number): boolean {
    return this.specificYears.includes(n);
  }
  get CronYearsOptions() {
    return CronYearsOptions;
  }

  private getRange(start: number, end: number): number[] {
    const length = end - start + 1;
    return Array.apply(null, Array(length)).map((__, i) => i + start);
  }
}
