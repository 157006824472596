import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store, select } from '@ngrx/store';
import * as moment from 'moment';
import { AuthStoreSelectors, RootStoreState } from 'src/app/root-store';
import { GlobalDateFilterStoreAction, GlobalDateFilterStoreSelectors } from 'src/app/root-store/global-date-filter-store';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

export interface GlobalDateOptionType {
  value: number;
  title: string;
}

@Component({
  selector: 'app-global-date-grid-filter-panel',
  templateUrl: './global-date-grid-filter-panel.component.html',
  styleUrls: ['./global-date-grid-filter-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalDateGridFilterPanelComponent extends FormatComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Output() globalDateEvEm = new EventEmitter();
  selectedGlobalDate: Date;
  selectedConfigurationGlobalDate: Date;

  selectedGlobalDateOption = 0;
  selectedConfigurationGlobalDateOption = 0;
  isTenantAdminAuthenticated = false;
  isConfiguration = false;
  showit = false;

  constructor(protected store: Store<RootStoreState.State>, private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.subscribe(this.translate.get(_('translation_loader_resolver_dummy')), () => {
      this.showit = true;
    });
    this.subscribe(this.store.select(GlobalDateFilterStoreSelectors.selectIsConfigurationGlobalDateFilter), (isConfiguration) => {
      this.isConfiguration = isConfiguration;
      this.cdRef.detectChanges();
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin)), (isTenantAdminAuth) => {
      this.isTenantAdminAuthenticated = isTenantAdminAuth;
      // if (this.isTenantAdminAuthenticated) {
      //   this.showAll();
      // }
    });

    this.subscribe(this.store.select(GlobalDateFilterStoreSelectors.selectGlobalDateFilter), (globalDateFilter) => {
      const stringValue = localStorage.getItem('globalDateFilter');
      if (stringValue != null) {
        this.store.dispatch(GlobalDateFilterStoreAction.deleteGlobalDateFilterStatus());
        if (JSON.parse(stringValue) != null) {
          const mDate = moment(JSON.parse(stringValue)).toDate();
          this.store.dispatch(GlobalDateFilterStoreAction.setGlobalDateFilter({ date: mDate }));
        } else {
          this.store.dispatch(GlobalDateFilterStoreAction.resetGlobalDateFilter());
        }
        this.globalDateEvEm.emit();
      } else {
        this.selectedGlobalDate = globalDateFilter;
      }
      this.selectedGlobalDateOption = this.selectedGlobalDate !== null ? (this.isToday() ? 1 : 2) : 0;
      this.cdRef.detectChanges();
    });
    this.subscribe(this.store.select(GlobalDateFilterStoreSelectors.selectConfigurationGlobalDateFilter), (globalDateFilter) => {
      const stringValue = localStorage.getItem('globalConfigurationDateFilter');
      if (stringValue != null) {
        this.store.dispatch(GlobalDateFilterStoreAction.deleteConfigurationGlobalDateFilterStatus());
        if (JSON.parse(stringValue) != null) {
          const mDate = moment(JSON.parse(stringValue)).toDate();
          this.store.dispatch(GlobalDateFilterStoreAction.setConfigurationGlobalDateFilter({ date: mDate }));
        } else {
          this.store.dispatch(GlobalDateFilterStoreAction.resetConfigurationGlobalDateFilter());
        }
        this.globalDateEvEm.emit();
      } else {
        this.selectedConfigurationGlobalDate = globalDateFilter;
      }
      this.selectedConfigurationGlobalDateOption =
        this.selectedConfigurationGlobalDate !== null ? (this.isConfigurationToday() ? 1 : 2) : 0;
      this.cdRef.detectChanges();
    });
  }

  onOptionChange() {
    if (this.selectedGlobalDateOption === 1) {
      this.current(); // set global filter to now
    } else if (this.selectedGlobalDateOption === 0) {
      this.showAll(); // set global filter to null
    } else {
      this.selectedGlobalDate = new Date();
    }
  }

  onConfigurationOptionChange() {
    if (this.selectedConfigurationGlobalDateOption === 1) {
      this.current(); // set global filter to now
    } else if (this.selectedConfigurationGlobalDateOption === 0) {
      this.showAll(); // set global filter to null
    } else {
      this.selectedConfigurationGlobalDate = new Date();
    }
  }

  showAll() {
    if (this.isConfiguration) {
      this.store.dispatch(GlobalDateFilterStoreAction.resetConfigurationGlobalDateFilter());
    } else {
      this.store.dispatch(GlobalDateFilterStoreAction.resetGlobalDateFilter());
    }
    this.globalDateEvEm.emit();
  }

  current() {
    if (this.isConfiguration) {
      this.store.dispatch(GlobalDateFilterStoreAction.currentConfigurationGlobalDateFilter());
    } else {
      this.store.dispatch(GlobalDateFilterStoreAction.currentGlobalDateFilter());
    }
    this.globalDateEvEm.emit();
  }

  setDate(date: any) {
    const mDate = moment(date).toDate();
    if (this.isConfiguration) {
      this.store.dispatch(GlobalDateFilterStoreAction.setConfigurationGlobalDateFilter({ date: mDate }));
    } else {
      this.store.dispatch(GlobalDateFilterStoreAction.setGlobalDateFilter({ date: mDate }));
    }
    this.globalDateEvEm.emit();
  }

  isToday() {
    return this.selectedGlobalDate && this.selectedGlobalDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);
  }

  isConfigurationToday() {
    return (
      this.selectedConfigurationGlobalDate && this.selectedConfigurationGlobalDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
    );
  }
}
