import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { CancelAssetCancellationDetailsStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-asset-wizard/cancel-asset-cancellation-details-step/cancel-asset-cancellation-details-step';
import { CancelAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-asset-wizard/init-step/init-step';
import * as fromCancelAssetWizard from './reducer';
import { State } from './state';

export const selectCancelAssetWizardState = createFeatureSelector<State>(fromCancelAssetWizard.cancelAssetWizardFeatureKey);

export const selectInitStep = createSelector(selectCancelAssetWizardState, (state: State): CancelAssetInitStep => state.initStep);

export const selectCancellationDetailsStep = createSelector(
  selectCancelAssetWizardState,
  (state: State): CancelAssetCancellationDetailsStep => state.cancellationDetails
);

export const selectCancelAssetState = createSelector(selectCancelAssetWizardState, (state: State): ActionResponse => state.response);

export const selectErrorMessage = createSelector(selectCancelAssetWizardState, (state: State): any => state.errorMessage);
