import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[middleclick]',
})
export class MiddleClickDirective {
  @Output('middleclick') middleclick = new EventEmitter();

  constructor() {}

  @HostListener('mousedown', ['$event'])
  middleclickEvent(event: MouseEvent) {
    if (event.button === 1) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
      this.middleclick.emit(event);
    }
  }

  @HostListener('click', ['$event'])
  ctrlclickEvent(event: MouseEvent) {
    if (event.button === 0 && event.ctrlKey) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
      this.middleclick.emit(event);
    }
  }
}
