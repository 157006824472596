<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div mat-dialog-content class="generic-form-wizard-modal-dialog-content">
  <ng-container *ngIf="wizardName">
    <app-generic-form-wizard
      [isWizardDialog]="true"
      [wizardName]="wizardName"
      (finishWizardEvEm)="onFinishCreateEntity()"
      (cancelWizardEvEm)="onCancelCreateEntity()"
    >
    </app-generic-form-wizard>
  </ng-container>
</div>
