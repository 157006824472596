import { Action, createReducer, on } from '@ngrx/store';
import * as MoveBillingitemsWizardActions from './actions';
import { State, initialState } from './state';

export const moveBillingitemsWizardFeatureKey = 'moveBillingitemsWizard';

const moveBillingitemsWizardReducer = createReducer(
  initialState,

  on(MoveBillingitemsWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(MoveBillingitemsWizardActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(MoveBillingitemsWizardActions.selectBillingaccountStep, (state, { billingaccountStep }) => ({
    ...state,
    billingaccountStep,
  })),
  on(MoveBillingitemsWizardActions.deleteBillingaccountStep, (state) => ({
    ...state,
    billingaccountStep: null,
  })),
  on(MoveBillingitemsWizardActions.exitMoveBillingitemsWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    billingaccountStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(MoveBillingitemsWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    billingaccountStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(MoveBillingitemsWizardActions.createNewMoveBillingitems, (state, { request }) => ({
    ...state,
    request,
  })),
  on(MoveBillingitemsWizardActions.createNewMoveBillingitemsSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(MoveBillingitemsWizardActions.createNewMoveBillingitemsFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return moveBillingitemsWizardReducer(state, action);
}
