import { HttpParams } from '@angular/common/http';

export class BaseParams {
  public static ORDER_BY = 'orderBy';
  public static SORT = 'sort';
  public static FILTER = 'filter';
}
export class OrderedRequest {
  orderBy: string;
  sort: string;
  filter?: string;
  // extra filter for derived classes
  //   filterFirstId?: number;
  //   filterFirstText?: string;
  //   filterFirstDate?: Date;
  //   filterSecondId?: number;

  public static getBaseRequestParams(request: OrderedRequest): HttpParams {
    let params = new HttpParams().set(BaseParams.ORDER_BY, request.orderBy).set(BaseParams.SORT, request.sort);
    if (request.filter) {
      params = params.append(BaseParams.FILTER, request.filter);
    }
    return params;
  }
}
