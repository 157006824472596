/* eslint-disable no-useless-escape */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { AuthStoreAction, AuthStoreSelectors, RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { LogService } from 'src/app/services/log-service';
import { BaseComponent } from 'src/app/shared/base-components/base-component';
import { AuthError } from '../auth-result';
import { AuthToken } from '../auth-token';
import { AuthUser } from '../auth-user';
import { ResetPwdAttempt } from '../reset-pwd-attempt';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {
  authUser: AuthUser;
  resetPwdForm: UntypedFormGroup;
  resetPwdAttempt: ResetPwdAttempt;
  email: string;
  token: AuthToken;

  isSubmitted = false;
  isResetted = false;
  error: AuthError = null;
  headerImage: string;

  constructor(
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private store: Store<RootStoreState.State>,
    private formBuilder: UntypedFormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    const m = this.ngOnInit.name;

    this.subscribe(
      this.store.pipe(select(PreferencesSelectors.selectPreferencesLoginImage)),
      (headerImage) => (this.headerImage = headerImage)
    );
    this.subscribe(this.activeRoute.queryParamMap, (queryParams) => {
      const m2 = m + this.subscribe.name;

      try {
        this.token = new AuthToken(queryParams.get('reset-token'));
        if (this.token.isValid()) {
          this.authUser = this.token.getUser();
          this.email = this.authUser.email;
          this.resetPwdForm = this.formBuilder.group(
            {
              email: [this.email, Validators.required],
              newPassword: [
                '',
                Validators.compose([
                  Validators.required,
                  this.patternValidator(/\d/, {
                    hasNumber: true,
                  }),
                  this.patternValidator(/[A-Z]/, {
                    hasCapitalCase: true,
                  }),
                  this.patternValidator(/[a-z]/, {
                    hasSmallCase: true,
                  }),
                  this.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
                    hasSpecialCharacters: true,
                  }),
                  Validators.minLength(8),
                ]),
              ],
              confirmNewPassword: ['', Validators.required],
            },
            {
              // check whether our new password and confirm password match
              validator: this.passwordMatchValidator,
            }
          );
        } else {
          LogService.error(this, m2, 'Expired token', null);
          this.router.navigate(['']);
          this.showError(_('reset_password_expired_token_error'));
        }
      } catch (err) {
        LogService.error(this, m2, 'Invalid token', null);
        LogService.error(this, m2, LogService.ERROR, err);
        this.router.navigate(['']);
        this.showError(_('reset_password_invalid_token_error'));
      }
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectResetPwdError)), (error) => {
      if (error) {
        this.error = error;
      }
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectPwdResetted)), (isResetted) => {
      this.isResetted = isResetted;
      if (this.isResetted) {
        setTimeout(() => {
          this.store.dispatch(AuthStoreAction.resetPwdSuccessDone());
          this.router.navigate(['']);
        }, 3000);
      }
    });
  }

  private showError(errorKey: string) {
    this.translate
      .get(errorKey)
      .pipe(take(1))
      .subscribe((message) => this.toastr.error(message));
  }

  // Call this method in the image source, it will sanitize it.
  transform(base64Image: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }

  getErrorMessageNewPassword() {
    const { errors } = this.resetPwdForm.controls.newPassword;
    const errorMessage: string[] = [''];

    if (errors.required && this.resetPwdForm.value.newPassword) {
      errorMessage.push(_('resetpwd_error_required_newpwd'));
    }
    if (errors.minlength) {
      errorMessage.push(_('resetpwd_error_nominlength'));
    }
    if (errors.hasNumber) {
      errorMessage.push(_('resetpwd_error_nonumber'));
    }
    if (errors.hasCapitalCase) {
      errorMessage.push(_('resetpwd_error_nocapitalcase'));
    }
    if (errors.hasSmallCase) {
      errorMessage.push(_('resetpwd_error_nosmallcase'));
    }
    if (errors.hasSpecialCharacters) {
      errorMessage.push(_('resetpwd_error_nospecialchar'));
    }
    return errorMessage;
  }

  getErrorMessageConfirmNewPassword() {
    const { errors } = this.resetPwdForm.controls.confirmNewPassword;
    const errorMessage: string[] = [''];
    if (this.resetPwdForm.value.newPassword && this.resetPwdForm.value.confirmNewPassword) {
      if (errors.required) {
        errorMessage.push(_('resetpwd_error_required_confirmpwd'));
      }
      if (errors.passwordNotMatching) {
        errorMessage.push(_('resetpwd_error_nomatchpwd'));
      }
    }
    return errorMessage;
  }

  resetPwd(): void {
    this.error = null;
    this.isSubmitted = true;
    if (this.resetPwdForm.invalid) {
      return;
    }
    this.resetPwdAttempt = new ResetPwdAttempt(this.resetPwdForm.value.email, this.resetPwdForm.value.newPassword);
    this.store.dispatch(
      AuthStoreAction.resetPwd({
        token: this.token,
        resetPwd: this.resetPwdAttempt,
      })
    );
  }

  get formControls() {
    return this.resetPwdForm.controls;
  }

  private passwordMatchValidator(control: AbstractControl) {
    const newPassword: string = control.get('newPassword').value;
    const confirmPassword: string = control.get('confirmNewPassword').value;
    if (newPassword !== confirmPassword) {
      control.get('confirmNewPassword').setErrors({ passwordNotMatching: true });
    }
  }

  private patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }
}
