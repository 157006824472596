<div
  class="finoffice__toast finoffice__toast--error finoffice__toast"
  (click)="message != null && message != '' ? $event.stopPropagation() : tapToast()"
>
  <button *ngIf="options.closeButton" (click)="remove()" type="button" class="finoffice__close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="finoffice__wrapper">
    <div class="finoffice__icon">
      <i class="finoffice__icon--error"></i>
    </div>
    <div class="finoffice__message">
      <ng-container *ngIf="options.enableHtml; else notHtml">
        <span *ngIf="duplicatesCount != null"><span [innerHTML]="title"></span> [{{ duplicatesCount }}]</span>
        <span *ngIf="duplicatesCount == null" [innerHTML]="title"></span>
        <span class="message" *ngIf="message != null && message != ''" (click)="toastPackage.triggerTap()">{{ message }}</span>
      </ng-container>

      <ng-template #notHtml>
        <span *ngIf="duplicatesCount != null">{{ title }} [{{ duplicatesCount }}]</span>
        <span *ngIf="duplicatesCount == null">{{ title }}</span>
        <span class="message" *ngIf="message != null && message != ''" (click)="toastPackage.triggerTap()">{{ message }}</span>
      </ng-template>
    </div>
  </div>
  <div class="finoffice__ripple"></div>
</div>
