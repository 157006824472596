import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { Chat } from '../models/chat';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class CommentChatDataService {
  getChats(entityApiPath: string, entityId: number): Observable<BaseResponse<Chat[]>> {
    const m = this.getChats.name;

    const path = environment.getEndpoint(ApiPath.Chat.CHATS(entityApiPath, entityId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<Chat[]>>(path);

    return retVal;
  }

  sendChatMessage(entityApiPath: string, entityId: number, message: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.sendChatMessage.name;

    const path = environment.getEndpoint(ApiPath.Chat.CHATS(entityApiPath, entityId));
    LogService.info(this, m, LogService.GET + path, null);
    const params = new HttpParams().set('message', message);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  putChatMessage(entityApiPath: string, entityId: number, message: string, chatId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.sendChatMessage.name;

    const path = environment.getEndpoint(ApiPath.Chat.CHATS_BYID(entityApiPath, entityId, chatId));
    LogService.info(this, m, LogService.GET + path, null);
    const params = new HttpParams().set('message', message);

    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
