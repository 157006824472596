import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { CancelWizardDataService } from 'src/app/services/wizards/cancel-user-wizard-data.service';
import * as CancelUserWizardStoreActions from './actions';

@Injectable()
export class CancelUserWizardStoreEffects {
  // loadCancelUserWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(CancelUserWizardStoreActions.selectInitStep),
  //       tap(() => this.router.navigateByUrl('/pages/allocation-wizards/cancel'))
  //     ),
  //   { dispatch: false }
  // );
  // exitCancelAllocationWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(CancelUserWizardStoreActions.exitAllocationCancelWizard),
  //       tap(() => this.router.navigateByUrl('/pages/consumers'))
  //     ),
  //   { dispatch: false }
  // );
  cancelLicenseServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CancelUserWizardStoreActions.sendLicenseServicesCancelRequest),
      concatMap(({ cancelRequest }) =>
        this.cancelWizardService.cancelLicenseServices(cancelRequest).pipe(
          map((response) => {
            if (response) {
              return CancelUserWizardStoreActions.cancelRequestSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            let errorMessage = '';
            if (error instanceof HttpErrorResponse) {
              errorMessage = error.error.errorKey;
            } else {
              errorMessage = error;
            }
            return of(CancelUserWizardStoreActions.cancelRequestFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private router: Router, private cancelWizardService: CancelWizardDataService) {}
}
