import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiPath } from 'src/app/configs/api-paths';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { PropertyColumn, PropertyColumnParams } from 'src/app/models/property';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

@Component({
  selector: 'app-property-column-modal',
  templateUrl: './property-column-modal.component.html',
  styleUrls: ['./property-column-modal.component.scss'],
})
export class PropertyColumnModalComponent extends FormatComponent implements AfterViewInit {
  constructor(
    private coreDataService: CoreDataService,
    public dialogRef: MatDialogRef<PropertyColumnModalComponent>,
    private crd: ChangeDetectorRef
  ) {
    super();
  }

  @ViewChild('paginatorTable') paginatorTable: MatPaginator;
  @ViewChild('sortTable') sortTable: MatSort;
  isLoading = true;
  noEntityData = false;
  pageSizeList: number[] = [];
  pageTotalElements = 0;
  request: PaginatedRequest;
  filterValue: string;
  selectedEntity: any;
  // Filters
  enableFilter = true;
  clearFilterSubject: Subject<void> = new Subject<void>();
  filtersMap = new Map<string, any[]>();
  filtersMapSubject: BehaviorSubject<Map<string, any[]>> = new BehaviorSubject<Map<string, any[]>>(null);

  dataSourceTable: MatTableDataSource<PropertyColumn> = new MatTableDataSource<PropertyColumn>();
  displayedColumnsTable: string[] = [
    'radio',
    'propertycolumnProperty',
    'propertycolumnName',
    'propertycolumnType',
    'propertycolumnForeignProperty',
  ];

  ngAfterViewInit() {
    this.subscribe(this.store.select(PreferencesSelectors.selectPreferencesPaginatonsOptions), (pageSizeOpt) => {
      // Wait for selected product
      if (pageSizeOpt && pageSizeOpt.length !== 0) {
        this.pageSizeList = pageSizeOpt;
        this.paginatorTable.pageSize = this.pageSizeList[0];
        this.initRequest();
        this.crd.detectChanges();
        // Table Initialization
        this.loadServicesData();
      }
    });
    this.sortTable.sortChange.subscribe(() => {
      this.setOrder();
      this.loadServicesData();
    });
    this.paginatorTable.page.subscribe(() => {
      this.setPage();
      this.loadServicesData();
    });
  }

  loadServicesData() {
    this.dataSourceTable = new MatTableDataSource();
    this.isLoading = true;
    this.coreDataService.getPropertyColumns(this.request).subscribe(({ data }) => {
      this.dataSourceTable.data = data ? data : [];
      this.noEntityData = !data || data.length === 0;
      this.pageTotalElements = !data || data.length === 0 ? 0 : data[0].entityCount;
      this.isLoading = false;
      this.crd.detectChanges();
    });
  }

  isFiltersSetted(): boolean {
    return this.filterValue != '' || this.filterValue != null;
  }

  private setFilter(filter: string) {
    this.request = {
      ...this.request,
      filter,
    };
  }

  resetFilter() {
    if (this.filterValue) {
      this.filterValue = '';
      this.applyFilterTable(this.filterValue);
    }
  }

  clearFilters() {
    this.clearFilterSubject.next();
    // this.filtersMap = this.filtersMapStatic;
    this.filtersMap.clear();

    LogService.debug(this, this.clearFilters.name, 'FiltersMap', this.filtersMap);
    this.resetFilter();
    this.initRequest();
    this.loadServicesData();
  }

  initRequest() {
    this.filterValue = '';
    this.request = {
      pageIndex: 0,
      pageSize: this.pageSizeList[0],
      orderBy: this.gbGetInitialOrderBy(),
      sort: this.gbGetInitialSort(),
      filter: this.filterValue,
      filterMap: this.filtersMap,
    };
  }

  gbGetInitialOrderBy() {
    return 'propertycolumnProperty';
  }

  gbGetInitialSort() {
    return 'asc';
  }

  setFiltersIds($event: number[], filterName: string) {
    if ($event && $event.length > 0) {
      this.filtersMap.set(filterName, $event);
    } else {
      this.filtersMap.delete(filterName);
    }

    LogService.debug(this, this.clearFilters.name, 'FiltersMap', this.filtersMap);
    if (this.paginatorTable) {
      this.paginatorTable.pageIndex = 0;
    }
    this.request = { ...this.request, pageIndex: 0, filterMap: this.filtersMap };
    this.loadServicesData();
  }

  applyFilterTable(filterValue: string) {
    const m = this.applyFilterTable.name;

    LogService.info(this, m, 'FILTER VALUE', null);

    if (filterValue && filterValue.length >= 3) {
      this.setFilter(filterValue);
      this.loadServicesData();
    } else {
      if (!filterValue || (filterValue && filterValue.length === 0)) {
        this.setFilter(null);
        this.loadServicesData();
      }
    }
  }

  private setOrder() {
    this.request = {
      ...this.request,
      orderBy: this.sortTable.active,
      sort: this.sortTable.direction,
    };
  }

  private setPage() {
    this.request = {
      ...this.request,
      pageSize: this.paginatorTable.pageSize,
      pageIndex: this.paginatorTable.pageIndex,
    };
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onEntitySelected(row: any) {
    this.selectedEntity = row;
  }

  isEntitySelected() {
    return this.selectedEntity && this.selectedEntity.entityName !== '';
  }

  get PropertyColumnParams() {
    return PropertyColumnParams;
  }

  get ApiPath() {
    return ApiPath;
  }
}
