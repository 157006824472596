import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { Userlicense } from 'src/app/models/userlicense';
import { CancelConsumersFromAssetConsumerStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-consumers-from-asset-wizard/cancel-consumers-from-asset-consumers-step/cancel-consumers-from-asset-consumers.step';
import { CancelConsumersFromAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-consumers-from-asset-wizard/init-step/init-step';
import * as fromCancelConsumersFromAssetWizard from './reducer';
import { State } from './state';

export const selectCancelConsumersFromAssetWizardState = createFeatureSelector<State>(
  fromCancelConsumersFromAssetWizard.cancelConsumersFromAssetWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectCancelConsumersFromAssetWizardState,
  (state: State): CancelConsumersFromAssetInitStep => state.initStep
);

export const selectConsumersStep = createSelector(
  selectCancelConsumersFromAssetWizardState,
  (state: State): CancelConsumersFromAssetConsumerStep => state.consumersStep
);

export const selectBillingStep = createSelector(
  selectCancelConsumersFromAssetWizardState,
  (state: State): Userlicense[] => state.billingStep
);

export const selectCancelConsumersFromAssetState = createSelector(
  selectCancelConsumersFromAssetWizardState,
  (state: State): EntityWizardResponse => state.response
);

export const selectErrorMessage = createSelector(selectCancelConsumersFromAssetWizardState, (state: State): string => state.errorMessage);
