import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CoreUiInterfaceEffects } from './effects';
import * as fromCoreUiInterface from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCoreUiInterface.coreUiInterfaceFeatureKey, fromCoreUiInterface.reducer),
    EffectsModule.forFeature([CoreUiInterfaceEffects]),
  ],
})
export class CoreUiInterfaceStoreModule {}
