import { Action, createReducer, on } from '@ngrx/store';
import * as GlobalDateFilterActions from './actions';
import { State, initialState } from './state';

export const globalDateFilterFeatureKey = 'foGlobalDateFilter';

const globalDateFilterReducer = createReducer(
  initialState,
  on(GlobalDateFilterActions.setGlobalDateFilter, (state, { date }) => ({
    ...state,
    globalDateFilter: date,
  })),
  on(GlobalDateFilterActions.resetGlobalDateFilter, (state) => {
    return {
      ...state,
      globalDateFilter: null,
    };
  }),
  on(GlobalDateFilterActions.currentGlobalDateFilter, (state) => ({
    ...state,
    globalDateFilter: new Date(),
  })),
  on(GlobalDateFilterActions.setConfigurationGlobalDateFilter, (state, { date }) => ({
    ...state,
    configurationGlobalDateFilter: date,
  })),
  on(GlobalDateFilterActions.resetConfigurationGlobalDateFilter, (state) => {
    return {
      ...state,
      configurationGlobalDateFilter: null,
    };
  }),
  on(GlobalDateFilterActions.currentConfigurationGlobalDateFilter, (state) => ({
    ...state,
    configurationGlobalDateFilter: new Date(),
  })),
  on(GlobalDateFilterActions.useConfigurationGlobalDateFilter, (state) => ({
    ...state,
    configurationGlobalDateFilter: state.globalDateFilter,
    isConfiguration: true,
  })),
  on(GlobalDateFilterActions.leaveConfigurationGlobalDateFilter, (state) => ({
    ...state,
    isConfiguration: false,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return globalDateFilterReducer(state, action);
}
