import { Component } from '@angular/core';
import { FormFieldTextKind, FormFieldTextSize, FormFieldTextWeight } from 'src/app/models/forms/form-configuration-text';
import { BaseFormFieldConfigComponent } from '../base-form-field-config/base-form-field-config-component';

@Component({
  selector: 'app-form-field-config-text',
  templateUrl: './form-field-config-text.component.html',
  styleUrls: ['./form-field-config-text.component.scss'],
})
export class FormFieldConfigTextComponent extends BaseFormFieldConfigComponent {
  get FormFieldTextKind() {
    return FormFieldTextKind;
  }

  get FormFieldTextSize() {
    return FormFieldTextSize;
  }

  get FormFieldTextWeight() {
    return FormFieldTextWeight;
  }
}
