import { KeyValue } from '@angular/common';
import { createAction, props } from '@ngrx/store';
import { AuthAttempt } from 'src/app/core/auth/auth-attempt';
import { AuthError, AuthResult } from 'src/app/core/auth/auth-result';
import { AuthToken } from 'src/app/core/auth/auth-token';
import { ChangePwdAttempt } from 'src/app/core/auth/change-pwd-attempt';
import { ForgotPasswordAttempt } from 'src/app/core/auth/forgot-pwd-attempt';
import { ResetPwdAttempt } from 'src/app/core/auth/reset-pwd-attempt';

export const logout = createAction('[Top NavBar] Logout');

export const logoutExecuted = createAction('[Auth Api] Logout Executed');

export const getInitialAuthStatus = createAction('[App Component] Get Initial Auth Status', props<{ token: AuthToken }>());

export const resetLoginFormsStatus = createAction('[Login Component] Reset Login Forms status');

/** LOGIN */

export const authenticate = createAction('[Login Component] Authenticate', props<{ auth: AuthAttempt }>());

export const authMultiTenant = createAction(
  '[Auth API] Authenticate Multi Tenant',
  props<{ tenants: KeyValue<string, string>[]; logintryId: number; accessToken: string; idToken: string }>()
);

export const authTwoFactorAuth = createAction(
  '[Auth API] Authenticate 2 Factor"',
  props<{ twoFactorMethod: string; logintryId: number; accessToken: string; idToken: string }>()
);

export const demoAutoAuthenticate = createAction('[Login Component] Demo Auto Authenticate', props<{ token: string }>());
export const demoAutoAuthSuccess = createAction('[Login Component] Demo Auto Auth Success', props<{ result: AuthResult }>());

export const authSuccess = createAction('[Auth API] Auth Success', props<{ result: AuthResult }>());

export const authFailure = createAction('[Auth API] Auth Failure', props<{ error: AuthResult }>());

/** LOGIN TENTANT ADMINISTRATION */

export const authTenantAdmin = createAction('[Login Component] Authenticate Tenant Administration', props<{ auth: AuthAttempt }>());

export const authSuccessTenantAdmin = createAction('[Auth API] Auth Success Tenant Administration', props<{ result: AuthResult }>());

export const authFailureTenantAdmin = createAction('[Auth API] Auth Failure Tenant Administration', props<{ error: AuthResult }>());

/** CHANGE PASSWORD */

export const changePwd = createAction('[Change Password Component] Authenticate', props<{ changePwd: ChangePwdAttempt }>());

export const changePwdSuccess = createAction('[Auth API] Change Password Success');

export const changePwdSuccessDone = createAction('[Auth API] Change Password Success Done');

export const changePwdFailure = createAction('[Auth API] Change Password Failure', props<{ error: AuthError }>());

/** FORGOT PASSWORD */

export const forgotPwd = createAction(
  '[Forgot Password Component] Send Request for forgotten password',
  props<{ forgotPwd: ForgotPasswordAttempt }>()
);

export const forgotPwdSuccess = createAction('[Auth API] Forgot Password Success');

export const forgotPwdSuccessDone = createAction('[Auth API] Forgot Password Success Done');

export const forgotPwdFailure = createAction('[Auth API] Forgot Password Failure', props<{ error: AuthError }>());

/** RESET PASSWORD */

export const resetPwd = createAction(
  '[Reset Password Component] Send Request for reset password',
  props<{ token: AuthToken; resetPwd: ResetPwdAttempt }>()
);

export const resetPwdSuccess = createAction('[Auth API] Reset Password Success');

export const resetPwdSuccessDone = createAction('[Auth API] Reset Password Success Done');

export const resetPwdFailure = createAction('[Auth API] Reset Password Failure', props<{ error: AuthError }>());

/** Auth As User */

export const authAsUser = createAction('[Login Component] Authenticate as User', props<{ token: string }>());
export const authAsUserSuccess = createAction('[Login Component] Authenticate as User Success', props<{ result: AuthResult }>());
