import { KeyValue } from '@angular/common';
import { GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
import { FormFieldConfigurationFieldKind } from './form-configuration-kind';
import { FormFieldConfigurationFieldOptions } from './form-configuration-option';

export class FormFieldsOptions {
  fields: FormFieldConfiguration[];
  options: FormFieldConfigurationFieldOptions;
}

export class FormFieldConfiguration {
  formfieldId: number;
  formfieldKindId: number;
  formfieldKind: string;
  formfieldTextname: string;
  formfieldTextnameTranslations: KeyValue<string, string>[];
  formfieldInfotextname: string;
  formfieldInfotextnameTranslations: KeyValue<string, string>[];
  formfieldTextKindId: number;
  formfieldTextKind: string;
  formfieldTextSizeId: number;
  formfieldTextSize: string;
  formfieldTextWeightId: number;
  formfieldTextWeight: string;
  formfieldEntityColumnId: number;
  formfieldEntityColumn: string;
  formfieldEntityType: string;
  formfieldEntityTypeId: number;
  formfieldEntityText: string;
  formfieldEntityValue: number;
  formfieldEntityKind: string;
  formfieldEntityKindId: number;
  formfieldEntityRelatedKindId: number;
  formfieldEntityRelatedKind: string;
  formfieldEntityOtherKindId: number;
  formfieldEntityOtherKind: string;
  formfieldEntityOption: string;
  formfieldEntityRegex: string;
  formfieldEntityRegexerrormsg: string;
  formfieldEntityRegexerrormsgTranslations: KeyValue<string, string>[];
  formfieldEntityUnit: string;
  formfieldEntityUnitTranslations: KeyValue<string, string>[];
  formfieldEntityPrecision: number;
  formfieldEntityScale: number;
  formfieldEntitySpecification: string;

  // SEARCH CONFIG OPTION 1
  // formfieldFilterForeignKind: string;
  formfieldFilterOption1Column: string;
  // formfieldFilterForeignKindId: number;
  formfieldFilterOption1ColumnId: number;
  // formfieldFilterForeignText: string;
  // formfieldFilterForeignId: number;
  formfieldFilterOption1Values: number[];

  // SEARCH CONFIG OPTION 2
  // formfieldFilterOptionColumn: string;
  formfieldFilterOption2Column: string;
  // formfieldFilterOptionColumnId: number;
  formfieldFilterOption2ColumnId: number;
  // formfieldFilterOptionText: string;
  // formfieldFilterOptionId: number;
  formfieldFilterOption2Values: number[];

  formfieldHideonfield: number;
  formfieldHideontrue: boolean;
  formfieldHideoncompact: boolean;
  formfieldReadonlyonfield: number;
  formfieldReadonlyontrue: boolean;
  formfieldLabelShow: boolean;
  formfieldLabelDisplay: boolean;
  // formfieldDisplayBooleanType: number;
  // formfieldDisplayOptionType: number;
  formfieldDisplayType: number;
  formfieldRequired: number;
  formfieldHideId: number;
  formfieldHide: string;
  formfieldX: number;
  formfieldY: number;
  formfieldW: number;
  formfieldH: number;
  formfieldRemoved: boolean;
  formfieldRecordDeleted: boolean;
  constructor(ffc: FormFieldConfiguration) {
    Object.assign(this, ffc);
    this.formfieldRemoved = false;
    this.formfieldRecordDeleted = false;
  }
}

export class FormFieldConfigurationItem extends FormFieldConfiguration implements GridsterItem {
  options: FormFieldConfigurationFieldOptions;
  original: FormFieldConfiguration;
  isDirty: boolean;
  // [propName: string]: any;
  x: number;
  y: number;
  rows: number;
  cols: number;
  layerIndex?: number;
  initCallback?: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => void;
  dragEnabled?: boolean;
  resizeEnabled?: boolean;
  compactEnabled?: boolean;
  maxItemRows?: number;
  minItemRows?: number;
  maxItemCols?: number;
  minItemCols?: number;
  minItemArea?: number;
  maxItemArea?: number;

  constructor(ffc: FormFieldConfiguration, options: FormFieldConfigurationFieldOptions, fieldKind: FormFieldConfigurationFieldKind) {
    super(ffc);
    this.original = JSON.parse(JSON.stringify(ffc));
    this.x = ffc.formfieldX;
    this.y = ffc.formfieldY;
    this.rows = ffc.formfieldH;
    this.cols = ffc.formfieldW;
    this.minItemRows = fieldKind ? fieldKind.formfieldkindMinH : null;
    this.options = options;
  }
}

export class FormFieldConfigurationRequest {
  formfieldId: number;
  formfieldKindId: number;
  formfieldTextnameTranslations: KeyValue<string, string>[];
  formfieldInfotextnameTranslations: KeyValue<string, string>[];
  formfieldTextKindId: number;
  formfieldTextSizeId: number;
  formfieldTextWeightId: number;
  formfieldEntityColumnId: number;
  formfieldEntityTypeId: number;
  formfieldEntityText: string;
  formfieldEntityValue: number;
  formfieldEntityKindId: number;
  formfieldEntityRelatedKindId: number;
  formfieldEntityOtherKindId: number;
  formfieldEntityOption: string;
  formfieldEntityRegex: string;
  formfieldEntityRegexerrormsgTranslations: KeyValue<string, string>[];
  formfieldEntityUnit: string;
  formfieldEntityUnitTranslations: KeyValue<string, string>[];
  formfieldHideonfield: number;
  formfieldHideontrue: boolean;
  formfieldHideoncompact: boolean;
  formfieldReadonlyonfield: number;
  formfieldReadonlyontrue: boolean;
  formfieldLabelShow: boolean;
  formfieldLabelDisplay: boolean;
  // formfieldDisplayBooleanType: number;
  // formfieldDisplayOptionType: number;
  formfieldDisplayType: number;
  formfieldRequired: number;
  formfieldHideId: number;
  formfieldEntityPrecision: number;
  formfieldEntityScale: number;
  formfieldEntitySpecification: string;
  // formfieldFilterForeignId: number;
  formfieldFilterOption1Ids: number[];
  // formfieldFilterForeignEntityKindId: number;
  formfieldFilterOption1ColumnId: number;
  // formfieldFilterOptionId: number;
  formfieldFilterOption2Ids: number[];
  // formfieldFilterOptionColumnId: number;
  formfieldFilterOption2ColumnId: number;
  formfieldX: number;
  formfieldY: number;
  formfieldW: number;
  formfieldH: number;
  formfieldRemoved: boolean;
  constructor(ffc: FormFieldConfigurationItem) {
    this.formfieldId = ffc.formfieldId;
    this.formfieldKindId = ffc.formfieldKindId;
    this.formfieldTextnameTranslations = ffc.formfieldTextnameTranslations;
    this.formfieldInfotextnameTranslations = ffc.formfieldInfotextnameTranslations;
    this.formfieldTextKindId = ffc.formfieldTextKindId;
    this.formfieldTextSizeId = ffc.formfieldTextSizeId;
    this.formfieldTextWeightId = ffc.formfieldTextWeightId;
    this.formfieldEntityColumnId = ffc.formfieldEntityColumnId;
    this.formfieldEntityTypeId = ffc.formfieldEntityTypeId;
    this.formfieldEntityText = ffc.formfieldEntityText;
    this.formfieldEntityValue = ffc.formfieldEntityValue;
    this.formfieldEntityKindId = ffc.formfieldEntityKindId;
    this.formfieldEntityRelatedKindId = ffc.formfieldEntityRelatedKindId;
    this.formfieldEntityOtherKindId = ffc.formfieldEntityOtherKindId;
    this.formfieldEntityOption = ffc.formfieldEntityOption;
    this.formfieldEntityRegex = ffc.formfieldEntityRegex;
    this.formfieldEntityRegexerrormsgTranslations = ffc.formfieldEntityRegexerrormsgTranslations;
    this.formfieldEntityUnit = ffc.formfieldEntityUnit;
    this.formfieldEntityUnitTranslations = ffc.formfieldEntityUnitTranslations;
    this.formfieldHideonfield = ffc.formfieldHideonfield;
    this.formfieldHideontrue = ffc.formfieldHideontrue;
    this.formfieldHideoncompact = ffc.formfieldHideoncompact;
    this.formfieldReadonlyonfield = ffc.formfieldReadonlyonfield;
    this.formfieldReadonlyontrue = ffc.formfieldReadonlyontrue;
    this.formfieldLabelShow = ffc.formfieldLabelShow;
    this.formfieldLabelDisplay = ffc.formfieldLabelDisplay;
    // this.formfieldDisplayBooleanType = ffc.formfieldDisplayBooleanType;
    // this.formfieldDisplayOptionType = ffc.formfieldDisplayOptionType;
    this.formfieldDisplayType = ffc.formfieldDisplayType;
    this.formfieldRequired = ffc.formfieldRequired;
    this.formfieldHideId = ffc.formfieldHideId;
    this.formfieldEntityPrecision = ffc.formfieldEntityPrecision;
    this.formfieldEntityScale = ffc.formfieldEntityScale;
    this.formfieldEntitySpecification = ffc.formfieldEntitySpecification;
    this.formfieldFilterOption1Ids = ffc.formfieldFilterOption1Values;
    this.formfieldFilterOption1ColumnId = ffc.formfieldFilterOption1ColumnId;
    this.formfieldFilterOption2Ids = ffc.formfieldFilterOption2Values;
    this.formfieldFilterOption2ColumnId = ffc.formfieldFilterOption2ColumnId;
    this.formfieldX = ffc.x;
    this.formfieldY = ffc.y;
    this.formfieldW = ffc.formfieldRemoved ? 0 : ffc.cols;
    this.formfieldH = ffc.formfieldRemoved ? 0 : ffc.rows;
    this.formfieldRemoved = ffc.formfieldRemoved;
  }
}
