import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, throwError } from 'rxjs';
import { CancelWizardDataService } from 'src/app/services/wizards/cancel-user-wizard-data.service';
import { MoveAssetServicesWizardActions } from '.';
import { State } from './state';

@Injectable()
export class MoveAssetServicesWizardEffects {
  // loadMoveProductWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(MoveAssetServicesWizardActions.selectInitStep),
  //       tap(() => this.router.navigateByUrl('/pages/allocation-wizards/order'))
  //     ),
  //   { dispatch: false }
  // );
  // exitMoveAssetServicesAllocationWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(MoveAssetServicesWizardActions.exitAllocationMoveAssetServicesWizard),
  //       tap(() => this.router.navigateByUrl('/pages/consumers'))
  //     ),
  //   { dispatch: false }
  // );

  newMoveAssetServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoveAssetServicesWizardActions.createNewMoveAssetServices),
      concatMap(({ cancelRequest }) =>
        this.cancelWizardService.cancelLicenseServices(cancelRequest).pipe(
          map((response) => {
            if (response) {
              return MoveAssetServicesWizardActions.createNewMoveAssetServicesSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            let errorMessage = '';
            if (error instanceof HttpErrorResponse) {
              errorMessage = error.error.errorKey;
            } else {
              errorMessage = error;
            }
            return of(MoveAssetServicesWizardActions.createNewMoveAssetServicesFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<State>, private cancelWizardService: CancelWizardDataService) {}
}
