export class AuthAttempt {
  email: string;
  password: string;
  tenant?: string;
  tfacode?: string;
  logintryId?: number;
  returnUrl: string;
  authorizationRegistrationId?: string;
  authorizationAccessToken?: string;
  authorizationCode?: string;
  authorizationIdToken?: string;

  constructor(
    email: string,
    password: string,
    tenant: string,
    tfacode: string,
    logintryId: number,
    returnUrl: string,
    authorizationRegistrationId?: string,
    authorizationCode?: string,
    authorizationAccessToken?: string,
    authorizationIdToken?: string
  ) {
    this.email = email;
    this.password = password;
    this.tenant = tenant;
    this.tfacode = tfacode;
    this.logintryId = logintryId;
    this.returnUrl = returnUrl;
    this.authorizationRegistrationId = authorizationRegistrationId;
    this.authorizationAccessToken = authorizationAccessToken;
    this.authorizationCode = authorizationCode;
    this.authorizationIdToken = authorizationIdToken;
  }
}
