import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MoveAssetBillingaccountWizardEffects } from './effects';
import * as fromMoveAssetBillingaccountWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromMoveAssetBillingaccountWizard.moveAssetBillingaccountWizardFeatureKey,
      fromMoveAssetBillingaccountWizard.reducer
    ),
    EffectsModule.forFeature([MoveAssetBillingaccountWizardEffects]),
  ],
})
export class MoveAssetBillingaccountWizardStoreModule {}
