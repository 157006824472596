import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { MoveAssetBillingaccountInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-billingaccount-wizard/init-step/init-step';
import { MoveAssetBillingaccountMovingToStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-billingaccount-wizard/move-asset-billingaccount-movingto-step/move-asset-billingaccount-movingto-step';
import * as fromMoveAssetBillingaccountWizard from './reducer';
import { State } from './state';

export const selectMoveAssetBillingaccountWizardState = createFeatureSelector<State>(
  fromMoveAssetBillingaccountWizard.moveAssetBillingaccountWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectMoveAssetBillingaccountWizardState,
  (state: State): MoveAssetBillingaccountInitStep => state.initStep
);

export const selectMovingToStep = createSelector(
  selectMoveAssetBillingaccountWizardState,
  (state: State): MoveAssetBillingaccountMovingToStep => state.movingTo
);

export const selectMoveAssetBillingaccountState = createSelector(
  selectMoveAssetBillingaccountWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectMoveAssetBillingaccountWizardState, (state: State): any => state.errorMessage);
