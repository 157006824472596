export class AuthUser {
  tenant: string;
  tenantdescription: string;
  uid: number;
  email: string;
  firstname: string;
  lastname: string;
  fullname: string;
  adminrights: boolean;
  lang: string;
  region: string;

  constructor(payloadToken: any) {
    if (payloadToken) {
      this.tenant = payloadToken.tenant;
      this.tenantdescription = payloadToken.tenantdescription;
      this.uid = payloadToken.uid;
      this.email = payloadToken.email;
      this.firstname = payloadToken.firstname;
      this.lastname = payloadToken.lastname;
      this.fullname = payloadToken.fullname;
      this.adminrights = payloadToken.adminrights;
      this.lang = payloadToken.lang;
      this.region = payloadToken.region;
    }
  }
}
