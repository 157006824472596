import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SwapLicenseOverview } from 'src/app/models/swap-license-overview';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../../configs/api-paths';
import { ActionResponse } from '../../models/action-response';
import { BaseResponse } from '../../models/base-response';
import { License } from '../../models/license';
import { PaginatedRequest } from '../../models/paginated-request';
import { NewSwapAssetRequest } from '../../shared-components/allocation-wizard-modal-dialogs/swap-contract-wizard/summary-step/new-swap-asset-request';
import { LogService } from '../log-service';

@Injectable({
  providedIn: 'root',
})
export class SwapcontractWizardDataService {
  getLicenses(request: PaginatedRequest): Observable<BaseResponse<License[]>> {
    const m = this.getLicenses.name;

    const path = environment.getEndpoint(ApiPath.Licenses.LICENSES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<License[]>>(path, {
      params,
    });

    return retVal;
  }

  getLicenseSwapOverview(swapDate: string, swapAssetId: number, cancelAssetId: number): Observable<BaseResponse<SwapLicenseOverview>> {
    const m = this.getLicenses.name;

    const path = environment.getEndpoint(ApiPath.Licenses.SWAP_LICENSE_WIZARD);
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams().set('swapDate', swapDate).set('swapAssetId', swapAssetId).set('cancelAssetId', cancelAssetId);
    const retVal = this.http.get<BaseResponse<SwapLicenseOverview>>(path, {
      params,
    });

    return retVal;
  }

  validateSwapLicense(request: NewSwapAssetRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.swapLicense.name;

    const path = environment.getEndpoint(ApiPath.Licenses.SWAP_LICENSE_VALIDATION);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  swapLicense(request: NewSwapAssetRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.swapLicense.name;

    const path = environment.getEndpoint(ApiPath.Licenses.SWAP_LICENSE_WIZARD);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
