import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { Desk } from 'src/app/models/desk';
import { MoveAllDesksRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-all-desks-wizard/confirmation-step/move-all-desks-request';
import { MoveAllDesksInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-all-desks-wizard/init-step/init-step';
import { MoveAllDesksMovingDetails } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-all-desks-wizard/moving-details-step/move-all-desks-moving-details-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Move AllDesks Information Step',
  props<{ initStep: MoveAllDesksInitStep }>()
);

export const loadAllBelongingDesks = createAction(
  '[Catalog Page] Select Move AllDesks Belonging Desks Step',
  props<{ belongingDesks: Desk[] }>()
);

export const selectMovingDetailsStep = createAction(
  '[Wizard Due Date Step] Select Move AllDesks Moving Details Step',
  props<{ movingDetails: MoveAllDesksMovingDetails }>()
);

export const selectConsumersStep = createAction(
  '[Wizard Due Date Step] Select Move AllDesks Consumers Step',
  props<{ consumersStep: Desk[] }>()
);

export const exitMoveAllDesksWizard = createAction('[MoveAllDesks Wizard Page] Exit Move AllDesks Wizard');

export const destroyWizard = createAction('[MoveAllDesks Wizard Page] Destroy Wizard');

export const createNewMoveAllDesks = createAction(
  '[Wizard Confirmation Step] Create Move AllDesks Information Step',
  props<{ request: MoveAllDesksRequest }>()
);

export const createNewMoveAllDesksSuccess = createAction(
  '[Wizard Confirmation Step] Create Move AllDesks Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewMoveAllDesksFail = createAction(
  '[Wizard Confirmation Step] Create Move AllDesks Information Step Fail',
  props<{ errorMessage: string }>()
);
