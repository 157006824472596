import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { AuthError } from '../auth-result';
import { AuthIllegalJWTTokenError } from '../auth-token';
import { ChangePwdAttempt } from '../change-pwd-attempt';

export interface ResetPasswordByadminDialogData {
  userEmail: string;
}

@Component({
  selector: 'app-reset-password-byadmin-dialog',
  templateUrl: './reset-password-byadmin-dialog.component.html',
  styleUrls: ['./reset-password-byadmin-dialog.component.scss'],
})
export class ResetPasswordByadminDialogComponent extends FormatComponent implements OnInit {
  changePwdForm: UntypedFormGroup;
  changePwdAttempt: ChangePwdAttempt;
  email: string;

  isSubmitted = false;
  isChanged = false;
  error: AuthError = null;

  entityDetailsIsOpen = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ResetPasswordByadminDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResetPasswordByadminDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.email = this.data.userEmail;
    this.changePwdForm = this.formBuilder.group(
      {
        email: [this.email, Validators.required],
        newPassword: [
          null,
          Validators.compose([
            Validators.required,
            this.patternValidator(/\d/, {
              hasNumber: true,
            }),
            this.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            this.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            this.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
              hasSpecialCharacters: true,
            }),
            Validators.minLength(8),
          ]),
        ],
        confirmNewPassword: [null, Validators.required],
      },
      {
        // check whether our new password and confirm password match
        validators: [PasswordMatchValidator.validate],
      }
    );
    this.changePwdForm.updateValueAndValidity();
  }

  getErrorMessageNewPassword() {
    const { errors } = this.changePwdForm.controls.newPassword;
    const errorMessage: string[] = [];

    if (errors.required && this.changePwdForm.value.newPassword) {
      errorMessage.push(_('changepwd_error_required_newpwd'));
    }
    if (errors.minlength) {
      errorMessage.push(_('changepwd_error_nominlength'));
    }
    if (errors.hasNumber) {
      errorMessage.push(_('changepwd_error_nonumber'));
    }
    if (errors.hasCapitalCase) {
      errorMessage.push(_('changepwd_error_nocapitalcase'));
    }
    if (errors.hasSmallCase) {
      errorMessage.push(_('changepwd_error_nosmallcase'));
    }
    if (errors.hasSpecialCharacters) {
      errorMessage.push(_('changepwd_error_nospecialchar'));
    }
    return errorMessage.length > 0 ? errorMessage : null;
  }

  getErrorMessageConfirmNewPassword() {
    const { errors } = this.changePwdForm.controls.confirmNewPassword;
    const errorMessage: string[] = [];
    if (this.changePwdForm.value.newPassword && this.changePwdForm.value.confirmNewPassword) {
      if (errors.required) {
        errorMessage.push(_('changepwd_error_required_confirmpwd'));
      }
      if (errors.passwordNotMatching) {
        errorMessage.push(_('changepwd_error_nomatchpwd'));
      }
    }
    return errorMessage.length > 0 ? errorMessage : null;
  }

  changePwd(): void {
    const m = this.changePwd.name;

    this.error = null;
    this.isSubmitted = true;
    if (this.changePwdForm.invalid) {
      LogService.debug(this, m, 'changePwdForm is invalid', this.changePwdForm);
      return;
    }
    this.changePwdAttempt = new ChangePwdAttempt(this.changePwdForm.value.email, '@notneeded', this.changePwdForm.value.newPassword);
    this.subscribe(
      this.authService.setPassword(this.changePwdAttempt),
      (response) => {
        if (response) {
          if (response && response.data.state) {
            this.isChanged = true;
          } else {
            this.error = new AuthError(response.data.error);
          }
        } else {
          this.error = new AuthError('Something went wrong');
        }
      },
      (error) => {
        if (error instanceof HttpErrorResponse) {
          this.error = new AuthError(error.error.errorKey, error.error.errorMessage);
        } else if (error instanceof AuthIllegalJWTTokenError) {
          this.error = new AuthError(error.message);
        } else {
          this.error = new AuthError('Something went wrong');
        }
      }
    );
  }

  get formControls() {
    return this.changePwdForm.controls;
  }

  private patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  expandPanel($event) {
    this.entityDetailsIsOpen = !this.entityDetailsIsOpen;
    $event.stopPropagation();
  }
}

class PasswordMatchValidator {
  static validate(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const newPassword: string = control.get('newPassword').value;
      const confirmPassword: string = control.get('confirmNewPassword').value;
      if (newPassword !== confirmPassword) {
        control.get('confirmNewPassword').setErrors({ passwordNotMatching: true });
        return { passwordNotMatching: true };
      } else {
        control.get('confirmNewPassword').setErrors(null);
      }
      control.get('newPassword').setErrors(null);
    }
    return null;
  }
}
