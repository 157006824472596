<div id="generic-form-wizard-finish-step">
  <span class="step-title" [ngClass]="[completed && isFinishOk ? 'green' : '', completed && !isFinishOk ? 'red' : '']">
    {{ finishStepTitle | translate }}
  </span>
  <mat-progress-bar *ngIf="!completed" [mode]="'buffer'" color="primary" [value]="progressPercentage"></mat-progress-bar>
  <ng-container *ngIf="createdEntityDatasource && createdEntityDatasource.data.length > 0">
    <span class="summary-title">{{ 'form_wizard_finish_createdentities' | translate }}</span>
    <div id="summary-entities">
      <div class="entity-table">
        <div class="table-wrapper">
          <div class="table-container">
            <table class="table no-header-table table-truncate-text" mat-table [dataSource]="createdEntityDatasource">
              <ng-container matColumnDef="wizarddataKind">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell showIfTruncated [matTooltip]="row.wizarddataKind" *matCellDef="let row">
                  {{ row.wizarddataKind | translate }}
                </td>
              </ng-container>
              <ng-container matColumnDef="wizarddataName">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell showIfTruncated [matTooltip]="row.wizarddataName" *matCellDef="let row">{{ row.wizarddataName }}</td>
              </ng-container>
              <ng-container matColumnDef="wizarddataError">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell showIfTruncated [matTooltip]="row.wizarddataError | translate" *matCellDef="let row" class="error">
                  <!-- [ngClass]="row.entityInfo === 'record_already_exists' ? 'gold' : 'green'" -->
                  {{ row.wizarddataError | translate }}
                </td>
              </ng-container>
              <ng-container matColumnDef="wizarddataLink">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                  mat-cell
                  showIfTruncated
                  *matCellDef="let row"
                  class="link"
                  (click)="row.wizarddataLink ? goToLink(row.wizarddataLink) : null"
                >
                  <ng-container *ngIf="row.wizarddataLink">
                    <button mat-raised-button color="primary" (click)="goToLink(row.wizarddataLink)">
                      <span *ngIf="row.wizarddataStatus === FormWizardDataStatus.success">{{
                        'form_wizard_validation_gotolink_created' | translate
                      }}</span>
                      <span *ngIf="row.wizarddataStatus === FormWizardDataStatus.warning">{{
                        'form_wizard_validation_gotolink_existing' | translate
                      }}</span>
                    </button>
                  </ng-container>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="entitiesDisplayedColumnTable; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: entitiesDisplayedColumnTable"></tr>
            </table>
          </div>
        </div>
      </div></div
  ></ng-container>
  <ng-container *ngIf="hintDatasource && hintDatasource.data.length > 0">
    <span class="summary-title">{{ 'form_wizard_validation_hint' | translate }}</span>
    <div id="summary-entities">
      <div class="entity-table">
        <div class="table-wrapper">
          <div class="table-container">
            <table class="table no-header-table table-truncate-text" mat-table [dataSource]="hintDatasource">
              <ng-container matColumnDef="wizarddataHint">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell showIfTruncated [matTooltip]="row.wizarddataHint" *matCellDef="let row" class="hint">
                  {{ row.wizarddataHint | translate }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="hintsDisplayedColumnTable; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: hintsDisplayedColumnTable"></tr>
            </table>
          </div>
        </div>
      </div></div
  ></ng-container>
  <div class="progress-messages" *ngIf="progressMsgDatasource && progressMsgDatasource.data.length > 0">
    <div class="entity-table">
      <div class="table-wrapper">
        <div #messagesContainer class="table-container">
          <table class="table table-stripes table-truncate-text" mat-table [dataSource]="progressMsgDatasource">
            <ng-container matColumnDef="progressTimestamp">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'progressstep_progresstimestamp' | translate }}
              </th>
              <td mat-cell showIfTruncated [matTooltip]="row.progressTimestamp" *matCellDef="let row">
                {{ getTimestamp(row.progressTimestamp) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="progressText">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'progressstep_progressmessage' | translate }}
              </th>
              <td mat-cell showIfTruncated [matTooltip]="row.progressText" *matCellDef="let row">{{ row.progressText }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
