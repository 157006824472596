import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Months } from '../cron-editor-enums';

export enum CronMonthsOptions {
  everyMonth = 1,
  everyNMonthStartFrom,
  specificMonths,
  rangeMonths,
}

@Component({
  selector: 'app-cron-month-tab,cron-month-tab',
  templateUrl: './cron-month-tab.component.html',
  styleUrls: ['./cron-month-tab.component.scss'],
})
export class CronMonthTabComponent implements OnInit {
  @Input() cronExpression: string;
  @Input() isEditing: boolean;
  @Output() monthsExpressionEvEm = new EventEmitter<string>();
  selectedOption: CronMonthsOptions;
  monthsExpression: string;

  rangeMonthsOptions = this.getRange(1, 12);
  everyNMonthOptions = this.getRange(1, 12);

  everyNMonth: number;
  everyNMonthStartFrom: number;

  //specificMonths
  specificMonths: number[] = [];

  //rangeMonths
  rangeMonthsStarts: number;
  rangeMonthsEnds: number;

  constructor() {}

  ngOnInit(): void {
    this.everyNMonth = this.everyNMonthOptions[0];
    this.everyNMonthStartFrom = this.rangeMonthsOptions[0];
    this.rangeMonthsStarts = this.rangeMonthsOptions[0];
    this.rangeMonthsEnds = this.rangeMonthsOptions[0];
    this.parseCronExpression();
  }

  parseCronExpression() {
    this.monthsExpression = this.cronExpression.split(' ')[4];
    if (this.monthsExpression === '*') {
      this.selectedOption = CronMonthsOptions.everyMonth;
    } else if (this.monthsExpression.match(/^\d{1,2}\/\d{1,2}$/)) {
      this.selectedOption = CronMonthsOptions.everyNMonthStartFrom;
      const cron_parts = this.monthsExpression.split('/');
      this.everyNMonth = parseInt(cron_parts[1]);
      this.everyNMonthStartFrom = parseInt(cron_parts[0]);
    } else if (this.monthsExpression.match(/^\d{1,2}\-\d{1,2}$/)) {
      this.selectedOption = CronMonthsOptions.rangeMonths;
      const cron_parts = this.monthsExpression.split('-');
      this.rangeMonthsStarts = parseInt(cron_parts[0]);
      this.rangeMonthsEnds = parseInt(cron_parts[1]);
    } else if (this.monthsExpression.match(/^\d{1,2}$|^(\d{1,2},)+\d{1,2}$/)) {
      this.selectedOption = CronMonthsOptions.specificMonths;
      this.specificMonths = this.monthsExpression.split(',').map((n) => parseInt(n));
    }
  }

  selectAllCheckboxes() {
    this.specificMonths = JSON.parse(JSON.stringify(this.rangeMonthsOptions));
    this.regenerateCron();
  }

  resetCheckboxes() {
    this.specificMonths = [];
    this.regenerateCron();
  }

  checkboxSelectionChanged($event: MatCheckboxChange) {
    this.selectedOption = CronMonthsOptions.specificMonths;
    const value = parseInt($event.source.value);
    const newChecked = $event.checked;
    if (newChecked) {
      this.specificMonths.push(value);
      this.specificMonths.sort(function (a, b) {
        return a - b;
      });
    } else {
      const index = this.specificMonths.indexOf(value, 0);
      if (index > -1) {
        this.specificMonths.splice(index, 1);
      }
    }
    this.regenerateCron();
  }

  regenerateCron() {
    switch (this.selectedOption) {
      case CronMonthsOptions.everyMonth:
        this.monthsExpression = '*';
        break;

      case CronMonthsOptions.everyNMonthStartFrom:
        this.monthsExpression = `${this.everyNMonthStartFrom}/${this.everyNMonth}`;
        break;

      case CronMonthsOptions.rangeMonths:
        this.monthsExpression = `${this.rangeMonthsStarts}-${this.rangeMonthsEnds}`;
        break;

      case CronMonthsOptions.specificMonths:
        this.monthsExpression = this.specificMonths.length > 0 ? this.specificMonths.join(',') : '1';
        break;
    }
    this.monthsExpressionEvEm.emit(this.monthsExpression);
  }

  isCheckboxChecked(n: number): boolean {
    return this.specificMonths.includes(n);
  }

  get CronMonthsOptions() {
    return CronMonthsOptions;
  }

  private getRange(start: number, end: number): number[] {
    const length = end - start + 1;
    return Array.apply(null, Array(length)).map((__, i) => i + start);
  }

  public monthDisplay(month: number): string {
    return Months[month];
  }
}
