import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Observable } from 'rxjs';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from '../../base-components/format-component';

@Component({
  selector: 'app-generic-grid-filter-datetime-panel, generic-grid-filter-datetime-panel',
  templateUrl: './generic-grid-filter-datetime-panel.component.html',
  styleUrls: ['./generic-grid-filter-datetime-panel.component.scss'],
})
export class GenericGridFilterDatetimePanelComponent extends FormatComponent implements OnInit {
  @Input() filterTitle: string;
  @Input() filterName: string;
  @Input() clearFilter: Observable<void>;
  @Input() filtersMapSubj: Observable<Map<string, any[]>>;
  @Input() gridName: string;
  @Input() gridfilterId: number;
  @Output() filterDatesEvEm = new EventEmitter<any[]>();
  filterString: string;
  filterTooltip: string;
  selectedDate: Date;

  constructor(private coreDataService: CoreDataService, private messageNotifierService: MessageNotifierService) {
    super();
  }

  ngOnInit(): void {
    this.filterString = this.filterTitle;
    if (this.filtersMapSubj && this.filterName) {
      this.subscribe(this.filtersMapSubj, (map) => {
        if (map) {
          map.forEach((value, key) => {
            if (key === this.filterName) {
              this.selectedDate = new Date(value[0]);
              this.filterString = `${this.filterTitle}: ${this.getDatetime(this.selectedDate)}`;
              this.filterTooltip = this.getDatetime(this.selectedDate);
            }
          });
        }
      });
    }
    if (this.clearFilter) {
      this.subscribe(this.clearFilter, () => {
        this.clearFilterSelection();
      });
    }
  }

  reset() {
    this.clearFilterSelection();
    this.filterDatesEvEm.emit([]);
  }

  clearFilterSelection() {
    this.filterString = this.filterTitle;
    this.filterTooltip = null;
    this.selectedDate = null;
  }

  updateDate(date) {
    if (date) {
      this.selectedDate = date;
      this.filterString = `${this.filterTitle}: ${this.getDatetime(date)}`;
      this.filterTooltip = this.getDatetime(date);
      this.filterDatesEvEm.emit([this.datetimeCastUTC(date)]);
    }
  }

  changeDefaults() {
    this.subscribe(
      this.coreDataService.changeDynamicFiltersDefaultsByGridName(this.gridName, this.gridfilterId, [
        this.datetimeCastUTC(this.selectedDate),
      ]),
      (response) => {
        LogService.info(this, this.changeDefaults.name, 'Default changed', response);
        this.messageNotifierService.showSuccessMessage(_('label_default_filter_values_changed'));
      }
    );
  }

  getDatetimeFormat() {
    return `${this.filterTitle}: ${this.getDatetimeRegionalFormat()}`;
  }
}
