import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { PricingFormParticulars } from 'src/app/models/pricing-form-particulars';
import { BillingAccount } from 'src/app/models/vendor-account';
import { AddBillingitemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-billingitems-wizard/activation-step/addbillingitems-due-date-info';
import { AddBillingitemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-billingitems-wizard/init-step/init-step';
import * as fromAddBillingitemsWizard from './reducer';
import { State } from './state';

export const selectAddBillingitemsWizardState = createFeatureSelector<State>(fromAddBillingitemsWizard.addBillingitemsWizardFeatureKey);

export const selectInitStep = createSelector(selectAddBillingitemsWizardState, (state: State): AddBillingitemsInitStep => state.initStep);

export const selectDueDateStep = createSelector(
  selectAddBillingitemsWizardState,
  (state: State): AddBillingitemsDueDates => state.dueDatesStep
);

export const selectBillingaccountsStep = createSelector(
  selectAddBillingitemsWizardState,
  (state: State): BillingAccount[] => state.billingaccountStep
);

export const selectPricingStep = createSelector(
  selectAddBillingitemsWizardState,
  (state: State): PricingFormParticulars => state.pricingStep
);

export const selectAddBillingitemsState = createSelector(
  selectAddBillingitemsWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectAddBillingitemsWizardState, (state: State): string => state.errorMessage);
