<ng-container *ngIf="configs">
  <tr *ngIf="store.raw_data.length == 0">
    <td [innerHTML]="configs.data_loading_text" [attr.colspan]="columns.length + 1" style="text-align: center"></td>
  </tr>
  <tr
    db-filter-row
    [columns]="columns"
    [configs]="configs"
    [store]="store"
    [internal_configs]="internal_configs"
    *ngIf="configs.filter"
    [expand_tracker]="expand_tracker"
    [ngClass]="configs.css.row_filter_class"
  ></tr>
  <tr
    db-add-row
    [columns]="columns"
    [configs]="configs"
    [internal_configs]="internal_configs"
    [store]="store"
    (rowadd)="addRow($event)"
    *ngIf="internal_configs.show_add_row"
    class="{{ configs.row_class_function() }}"
  ></tr>
  <ng-container *ngIf="configs.subgrid">
    <tr
      db-subgrid
      *ngFor="let row_data of display_data"
      class="subgrid-row"
      [configs]="configs"
      [internal_configs]="internal_configs"
      [expand_tracker]="expand_tracker"
      [edit_tracker]="edit_tracker"
      [store]="store"
      [row_data]="row_data"
      [cellclick]="cellclick"
      [rowselect]="rowselect"
      [rowdeselect]="rowdeselect"
      [expand]="expand"
      [rowsave]="rowsave"
      [rowdelete]="rowdelete"
    ></tr>
  </ng-container>
  <ng-container *ngIf="!configs.subgrid">
    <tr
      *ngFor="let row_data of display_data"
      [attr.class]="configs.row_class_function(row_data) + ' ' + (row_data.row_selected ? configs.css.row_selection_class : '')"
      (click)="selectRow(row_data, $event)"
    >
      <ng-container *ngIf="expand_tracker[row_data.parent_pathx]">
        <td *ngIf="configs.multi_select" class="checkbox_column">
          <input
            type="checkbox"
            [checked]="row_data.row_selected"
            (click)="selectRowOnCheck(row_data, $event)"
            [attr.disabled]="row_data.selection_disabled"
          />
        </td>
        <td
          db-tree-cell-actions
          *ngIf="configs.actions.edit || configs.actions.delete || configs.actions.add"
          [row_data]="row_data"
          [configs]="configs"
          [store]="store"
          [edit_tracker]="edit_tracker"
          [internal_configs]="internal_configs"
          [rowdelete]="rowdelete"
          (canceledit)="cancelEdit($event)"
          (editcomplete)="saveRecord($event)"
        ></td>
        <td
          *ngFor="let column of columns; index as i"
          class="{{ column.css_class }}"
          [ngClass]="{ 'column-hide': column.hidden, 'expand-column': i == 0 }"
        >
          <db-tree-cell
            [configs]="configs"
            [column]="column"
            [index]="i"
            [row_data]="row_data"
            [expand_tracker]="expand_tracker"
            [edit_on]="edit_tracker[row_data[configs.id_field]]"
            [cellclick]="cellclick"
            (rowexpand)="onRowExpand($event)"
            (rowcollapse)="onRowCollapse($event)"
            (editcomplete)="saveRecord($event)"
          >
          </db-tree-cell>
        </td>
        <td *ngIf="configs.show_parent_on_edit && internal_configs.show_parent_col">
          <select *ngIf="edit_tracker[row_data[configs.id_field]]" [(ngModel)]="row_data[configs.parent_id_field]">
            <option *ngFor="let parent of parents" [value]="parent.id">{{ parent.value }}</option>
          </select>
        </td>

        <!-- Add column to fix UI issue when add row is enabled but don't show when edit is enabled for the row -->
        <td *ngIf="internal_configs.show_add_row && !(internal_configs.show_parent_col && configs.show_parent_on_edit)"></td>
      </ng-container>
    </tr>
    <tr *ngIf="configs.show_summary_row">
      <td *ngIf="configs.multi_select"></td>
      <td *ngIf="configs.actions.edit || configs.actions.delete || configs.actions.add"></td>
      <td *ngFor="let column of configs.columns">
        <div [innerHTML]="column.summary_renderer && column.summary_renderer(display_data)"></div>
      </td>
    </tr>
  </ng-container>
</ng-container>
