import { Component } from '@angular/core';
import { StepValue } from 'src/app/shared/inputs/step-value-control/step-value';
import { BaseFormFieldConfigComponent } from '../base-form-field-config/base-form-field-config-component';

@Component({
  selector: 'app-form-field-config-stepcontrol',
  templateUrl: './form-field-config-stepcontrol.component.html',
  styleUrls: ['./form-field-config-stepcontrol.component.scss'],
})
export class FormFieldConfigStepcontrolComponent extends BaseFormFieldConfigComponent {
  mockedData: StepValue[] = [
    { id: 1, from: 1, to: 100, value: 100 },
    { id: 2, from: 101, to: 150, value: 80 },
    { id: 3, from: 151, to: null, value: 70 },
  ];
}
