import { KeyValue } from '@angular/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthAttempt } from 'src/app/core/auth/auth-attempt';
import { AuthError } from 'src/app/core/auth/auth-result';
import { OauthTokens } from 'src/app/core/auth/auth-token';
import { AuthUser } from 'src/app/core/auth/auth-user';
import * as fromAuth from './reducer';
import { State } from './state';

export const selectAuthState = createFeatureSelector<State>(fromAuth.authFeatureKey);

export const selectIsAuthenticated = createSelector(selectAuthState, (state: State): boolean => state.isAuthenticated);
export const selectIsAuthenticatedTenantAdmin = createSelector(
  selectAuthState,
  (state: State): boolean => state.isAuthenticatedTenantAdmin
);
export const selectAuthAttempt = createSelector(selectAuthState, (state: State): AuthAttempt => state.authAttempt);

export const selectTenantList = createSelector(selectAuthState, (state: State): KeyValue<string, string>[] => state.tenants);

export const selectTwoFactorMethod = createSelector(selectAuthState, (state: State): string => state.twoFactorMethod);

export const selectOauthTokens = createSelector(selectAuthState, (state: State): OauthTokens => state.oauthTokens);

export const selectLoginTryId = createSelector(selectAuthState, (state: State): number => state.logintryId);

export const selectAuthError = createSelector(selectAuthState, (state: State): AuthError => state.error);

export const selectAuthUser = createSelector(selectAuthState, (state: State): AuthUser => state.authUser);

export const selectPwdChanged = createSelector(selectAuthState, (state: State): boolean => state.isChanged);

export const selectChangePwdError = createSelector(selectAuthState, (state: State): AuthError => state.changePwdErrorMessage);

export const selectPwdForgotten = createSelector(selectAuthState, (state: State): boolean => state.isPwdForgotten);

export const selectForgotPwdError = createSelector(selectAuthState, (state: State): AuthError => state.forgotPwdErrorMessage);

export const selectPwdResetted = createSelector(selectAuthState, (state: State): boolean => state.isResetted);

export const selectResetPwdError = createSelector(selectAuthState, (state: State): AuthError => state.resetPwdErrorMessage);
