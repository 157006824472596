import { CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, merge } from 'rxjs';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { GenericTreeNode } from 'src/app/models/tree-view-flat-node';
import { CoreDataService } from 'src/app/services/core-data.service';

export class GridFilterDynamicFlatNode {
  constructor(
    public item: GenericTreeNode,
    public level = 1,
    public expandable = false,
    public isLoading = false,
    public isIndeterminate = false,
    parentId?: number
  ) {
    this.item.parentId = parentId;
  }
}
@Injectable()
export class GridFilterDynamicDataSource {
  dataChange = new BehaviorSubject<GridFilterDynamicFlatNode[]>([]);
  baseApiPath: string;
  nodeName: string;
  filterTreeApiPath: (nodeId: number) => string;

  get data(): GridFilterDynamicFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: GridFilterDynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  set treeApiPath(filterTreeApiPath: (nodeId: number) => string) {
    this.filterTreeApiPath = filterTreeApiPath;
  }

  constructor(
    private treeControl: FlatTreeControl<GridFilterDynamicFlatNode>,
    private database: GridFilterDynamicDatabase,
    private coreDataService: CoreDataService,
    private request: PaginatedRequest
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<GridFilterDynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if ((change as SelectionChange<GridFilterDynamicFlatNode>).added || (change as SelectionChange<GridFilterDynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<GridFilterDynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(): void {}

  handleTreeControl(change: SelectionChange<GridFilterDynamicFlatNode>) {
    if (change.added) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  toggleNode(node: GridFilterDynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);

    node.isLoading = true;

    if (expand) {
      const childs = this.database.getChildren(node.item);
      if (childs) {
        const nodes = childs.map(
          (item) => new GridFilterDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId)
        );
        this.data.splice(index + 1, 0, ...nodes);
        // notify the change
        this.dataChange.next(this.data);
        node.isLoading = false;
      } else {
        this.coreDataService.getTreeFilterOptions(this.request, this.filterTreeApiPath(node.item.entityId)).subscribe(({ data }) => {
          if (data) {
            const nodes = data.map(
              (item) =>
                new GridFilterDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId)
            );
            this.database.setChildren(node.item, data);
            this.data.splice(index + 1, 0, ...nodes);
            // notify the change
            this.dataChange.next(this.data);
          } else {
            node.expandable = false;
          }
          node.isLoading = false;
        });
      }
    } else {
      let count = 0;
      // eslint-disable-next-line no-empty
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
      this.data.splice(index + 1, count);
      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }
  }

  /* SELECTION MANAGEMENT */
}

@Injectable()
export class GridFilterDynamicDatabase {
  dataMap = new Map<GenericTreeNode, GenericTreeNode[]>([]);

  initialData(rootLevelNodes: GenericTreeNode[]): GridFilterDynamicFlatNode[] {
    const initialNodes: GridFilterDynamicFlatNode[] = [];
    rootLevelNodes.forEach((node) => {
      initialNodes.push(new GridFilterDynamicFlatNode(node, 0, this.isExpandable(node), false));
    });

    return initialNodes;
  }

  setChildren(parentNode: GenericTreeNode, children: GenericTreeNode[]) {
    this.dataMap.set(parentNode, children);
  }

  getChildren(node: GenericTreeNode): GenericTreeNode[] | undefined {
    return this.dataMap.get(node);
  }

  isExpandable(node: GenericTreeNode): boolean {
    return node.entityChilds > 0;
  }
}
