import { Action, createReducer, on } from '@ngrx/store';
import * as ProductConfigurationsActions from './actions';
import { State, featureAdapter, initialState } from './state';

export const productConfigurationsFeatureKey = 'foProductConfigurations';

const productConfigurationsReducer = createReducer(
  initialState,

  on(ProductConfigurationsActions.loadProductConfigurations, (state) =>
    featureAdapter.removeAll({
      ...state,
      isLoading: true,
      error: null,
    })
  ),
  on(ProductConfigurationsActions.loadProductConfigurationsSuccess, (state, { data }) =>
    featureAdapter.setAll(data, {
      ...state,
      isLoading: false,
      error: null,
    })
  ),
  on(ProductConfigurationsActions.loadProductConfigurationsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(ProductConfigurationsActions.resetProductConfigurations, (state) =>
    featureAdapter.removeAll({
      ...state,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return productConfigurationsReducer(state, action);
}
