import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Entity } from 'src/app/models/entity';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { GenericEntityFormFieldEvent } from 'src/app/models/forms/form-field';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from '../../base-components/format-component';

export interface GenericEntityWizardModalDialogData {
  wizardName: string;
  isDocumentsTabEnabled: boolean;
  relatedEntityKinds: string[];
  relatedEntityIds: number[];
  foreignFilterEntityKind?: string;
  foreignFilterEntityId?: number;
  fieldTextnames?: string[];
  fieldValues?: any[];
  returnWizardResponse?: boolean;
  propertyTemplateId?: number;
  inputValue?: string;
  eventDataChangedCallback?: (event: GenericEntityFormFieldEvent) => void;
}

@Component({
  selector: 'app-generic-entity-wizard-modal-dialog',
  templateUrl: './generic-entity-wizard-modal-dialog.component.html',
  styleUrls: ['./generic-entity-wizard-modal-dialog.component.scss'],
})
export class GenericEntityWizardModalDialogComponent extends FormatComponent implements OnInit {
  wizardName: string;
  relatedEntityKinds: string;
  relatedEntityIds: string;
  foreignFilterEntityKind: string;
  foreignFilterEntityId: number;
  propertyTemplateId: number;
  inputValue: string;
  fieldTextnames: string;
  fieldValues: string;
  returnWizardResponse: boolean;
  isDocumentsTabEnabled: boolean;
  eventDataChangedCallback: (event: GenericEntityFormFieldEvent) => void;
  constructor(
    public dialogRef: MatDialogRef<GenericEntityWizardModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericEntityWizardModalDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.wizardName = this.data.wizardName;
    this.relatedEntityKinds = this.data.relatedEntityKinds ? this.data.relatedEntityKinds.join(',') : null;
    this.relatedEntityIds = this.data.relatedEntityIds ? this.data.relatedEntityIds.join(',') : null;
    this.fieldTextnames = this.data.fieldTextnames ? this.data.fieldTextnames.join(',') : null;
    this.fieldValues = this.data.fieldValues ? this.data.fieldValues.join(',') : null;
    this.foreignFilterEntityKind = this.data.foreignFilterEntityKind;
    this.foreignFilterEntityId = this.data.foreignFilterEntityId;
    this.propertyTemplateId = this.data.propertyTemplateId;
    this.inputValue = this.data.inputValue;
    this.isDocumentsTabEnabled = this.data.isDocumentsTabEnabled;
    this.returnWizardResponse = this.data.returnWizardResponse;
    this.eventDataChangedCallback = this.data.eventDataChangedCallback;
  }

  onFinishCreateEntity(entity: Entity) {
    LogService.debug(this, this.onFinishCreateEntity.name, 'Entity', entity);
    this.dialogRef.close(entity);
  }

  onFinishWizardResponse(response: EntityWizardResponse) {
    LogService.debug(this, this.onFinishWizardResponse.name, 'Response', response);
    this.dialogRef.close(response);
  }

  onCancelCreateEntity() {
    LogService.debug(this, this.onCancelCreateEntity.name, null, null);
    this.dialogRef.close();
  }
}
