import { createAction, props } from '@ngrx/store';
import { BaseResponse } from 'src/app/models/base-response';
import { UserLicenseServices } from 'src/app/models/product-services';

export const loadUserLicenceServices = createAction(
  '[Cancel Wizard License Tab] Load User License Services',
  props<{ userId: number; deskId?: number; licenseId?: number; activationDate: Date; deactivationDate: Date; cancelService: boolean }>()
);

export const loadSuccess = createAction(
  '[FoUserLicenseServices API] Load User License Services Success',
  props<{ response: BaseResponse<UserLicenseServices[]> }>()
);

export const loadFailure = createAction('[FoUserLicenseServices API] Load User License Services Failure', props<{ error: string }>());

export const noData = createAction('[FoUserLicenseServices API] Load User License Services No Data');
