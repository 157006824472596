import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { AdminText } from '../models/admin-text';
import { BaseResponse } from '../models/base-response';
import { ConfigurationItem } from '../models/configuration-item';
import { InterfaceItem } from '../models/interfaces/interface-item';
import { ModuleMenuItem, SidemenuItem } from '../models/module-item';
import { PaginatedRequest } from '../models/paginated-request';
import { Preference } from '../models/preference';
import { PreferenceNotification } from '../models/preference-notification';
import { FlyingButtonSettings, PreferenceSettings } from '../models/preference-settings';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class PreferenceDataService {
  getNotificationPreferences(request: PaginatedRequest): Observable<BaseResponse<PreferenceNotification[]>> {
    const m = this.getNotificationPreferences.name;

    const path = environment.getEndpoint(ApiPath.PREFERENCES_NOTIFICATIONS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<PreferenceNotification[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  postNotificationPreferences(request: PreferenceNotification[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.postNotificationPreferences.name;

    const path = environment.getEndpoint(ApiPath.PREFERENCES_NOTIFICATIONS);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getSystemSettings(request: PaginatedRequest): Observable<BaseResponse<Preference[]>> {
    const m = this.getSystemSettings.name;

    const path = environment.getEndpoint(ApiPath.Preferences.PREFERENCE_SYSTEM);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Preference[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getSystemSettingsSpreadsheet(request: PaginatedRequest) {
    const m = this.getSystemSettingsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Preferences.PREFERENCE_SYSTEM_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getPreferenceSettings(isAuth: boolean, isTenantAdmin: boolean): Observable<BaseResponse<PreferenceSettings>> {
    const m = this.getPreferenceSettings.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.PREFERENCES_SETTINGS)
      : isAuth
      ? environment.getEndpoint(ApiPath.PREFERENCES_SETTINGS)
      : environment.getEndpoint(ApiPath.Public.PREFERENCES_SETTINGS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<PreferenceSettings>>(path);

    return retVal;
  }

  getEnabledPaths(): Observable<BaseResponse<string[]>> {
    const m = this.getEnabledPaths.name;

    const path = environment.getEndpoint(ApiPath.PREFERENCES_ENABLED_PATHS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<string[]>>(path);

    return retVal;
  }

  getFlyingButtonSettings(isTenantAdmin: boolean): Observable<BaseResponse<FlyingButtonSettings>> {
    const m = this.getFlyingButtonSettings.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.FLYINGBUTTON_SETTINGS)
      : environment.getEndpoint(ApiPath.FLYINGBUTTON_SETTINGS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<FlyingButtonSettings>>(path);

    return retVal;
  }

  setFlyingButtonSettings(isTenantAdmin: boolean, flyingButtonSettings: FlyingButtonSettings): Observable<BaseResponse<ActionResponse>> {
    const m = this.getFlyingButtonSettings.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.FLYINGBUTTON_SETTINGS)
      : environment.getEndpoint(ApiPath.FLYINGBUTTON_SETTINGS);
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, LogService.REQUEST, flyingButtonSettings);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, flyingButtonSettings);

    return retVal;
  }

  getModuleItemByName(moduleName: string): Observable<BaseResponse<ModuleMenuItem>> {
    const m = this.getModuleItems.name;

    const path = environment.getEndpoint(ApiPath.PREFERENCES_MODULE_ITEM_BYMODULENAME(moduleName));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<ModuleMenuItem>>(path);

    return retVal;
  }

  getModuleItems(isAuth: boolean, isTenantAdmin: boolean): Observable<BaseResponse<SidemenuItem[]>> {
    const m = this.getModuleItems.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.PREFERENCES_SIDEMENUS)
      : isAuth
      ? environment.getEndpoint(ApiPath.PREFERENCES_SIDEMENUS)
      : environment.getEndpoint(ApiPath.Public.PREFERENCES_MODULE_ITEM);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<SidemenuItem[]>>(path);

    return retVal;
  }

  getConfigurationsItems(filter: string, filterTopicsIds: number[]): Observable<BaseResponse<ConfigurationItem[]>> {
    const m = this.getConfigurationsItems.name;

    const path = environment.getEndpoint(ApiPath.Preferences.PREFERENCE_CONFIGURATION_ITEMS);
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams();
    if (filter != null) {
      params = params.append('filter', filter);
    }
    if (filterTopicsIds != null && filterTopicsIds.length > 0) {
      params = params.append('filterTopicIds', filterTopicsIds.join(','));
    }
    const retVal = this.http.get<BaseResponse<ConfigurationItem[]>>(path, { params });

    return retVal;
  }

  getFavoriteConfigurationsItems(): Observable<BaseResponse<ConfigurationItem[]>> {
    const m = this.getFavoriteConfigurationsItems.name;

    const path = environment.getEndpoint(ApiPath.Preferences.PREFERENCE_CONFIGURATION_ITEMS_FAVORITES);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<ConfigurationItem[]>>(path);

    return retVal;
  }

  changeConfigurationFavorite(configurationId: number, favorite: boolean): Observable<BaseResponse<ActionResponse>> {
    const m = this.changeConfigurationFavorite.name;

    const path = environment.getEndpoint(ApiPath.Preferences.PREFERENCE_CONFIGURATION_ITEMS_FAVORITE_CHANGE(configurationId));
    LogService.info(this, m, LogService.PUT + path, null);
    const params = new HttpParams().set('favorited', favorite ? 'true' : 'false');
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getConfigurationTopics(): Observable<BaseResponse<ConfigurationItem[]>> {
    const m = this.getConfigurationsItems.name;

    const path = environment.getEndpoint(ApiPath.Preferences.PREFERENCE_CONFIGURATION_ITEMS_TOPICS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<ConfigurationItem[]>>(path);

    return retVal;
  }

  getInterfacesItems(): Observable<BaseResponse<InterfaceItem[]>> {
    const m = this.getInterfacesItems.name;

    const path = environment.getEndpoint(ApiPath.Preferences.PREFERENCE_INTERFACE_ITEMS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<InterfaceItem[]>>(path);

    return retVal;
  }

  getPreferenceFeedbackEmail(): Observable<BaseResponse<string>> {
    const m = this.getPreferenceFeedbackEmail.name;

    const path = environment.getEndpoint(ApiPath.PREFERENCES_FEEDBACK_EMAIL);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<string>>(path);

    return retVal;
  }

  // PREFERENCE'S PAGES

  getAdminTexts(request: PaginatedRequest): Observable<BaseResponse<AdminText[]>> {
    const m = this.getAdminTexts.name;

    const path = environment.getEndpoint(ApiPath.Administrations.ADMIN_TEXTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<AdminText[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
