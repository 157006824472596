import { Action, createReducer, on } from '@ngrx/store';
import * as TerminateMultiAddonsWizardActions from './actions';
import { State, initialState } from './state';

export const terminateMultiAddonsWizardFeatureKey = 'terminateMultiAddonsWizard';

const terminateMultiAddonsWizardReducer = createReducer(
  initialState,

  on(TerminateMultiAddonsWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(TerminateMultiAddonsWizardActions.selectServiceStep, (state, { serviceStep }) => ({
    ...state,
    serviceStep,
  })),
  on(TerminateMultiAddonsWizardActions.deleteServiceStep, (state) => ({
    ...state,
    serviceStep: null,
  })),
  on(TerminateMultiAddonsWizardActions.exitTerminateMultiAddonsWizard, (state) => ({
    ...state,
    initStep: null,
    serviceStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(TerminateMultiAddonsWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    serviceStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(TerminateMultiAddonsWizardActions.createNewTerminateMultiAddons, (state, { request }) => ({
    ...state,
    request,
  })),
  on(TerminateMultiAddonsWizardActions.createNewTerminateMultiAddonsSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(TerminateMultiAddonsWizardActions.createNewTerminateMultiAddonsFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return terminateMultiAddonsWizardReducer(state, action);
}
