import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { CoreDataService } from 'src/app/services/core-data.service';
import * as ProductConfigurationsActions from './actions';

@Injectable()
export class ProductsEffects {
  loadProductConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductConfigurationsActions.loadProductConfigurations),
      concatMap(({ apiPath, relatedEntityKinds, relatedEntityIds }) =>
        this.dataService.getGenericEntityFormFields(apiPath, relatedEntityKinds, relatedEntityIds).pipe(
          map(({ data }) =>
            data !== undefined
              ? ProductConfigurationsActions.loadProductConfigurationsSuccess({ data })
              : ProductConfigurationsActions.loadProductConfigurationsSuccess({
                  data: null,
                })
          ),
          catchError((error) =>
            of(
              ProductConfigurationsActions.loadProductConfigurationsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private dataService: CoreDataService) {}
}
