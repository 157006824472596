import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';

@Injectable()
export class MoveWizardStoreEffects {
  // loadMoveUserWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(MoveWizardStoreActions.selectMoveInitStep),
  //       tap(() => this.router.navigateByUrl('/pages/allocation-wizards/move'))
  //     ),
  //   { dispatch: false }
  // );
  // exitMoveAllocationWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(MoveWizardStoreActions.exitAllocationMoveWizard),
  //       tap(() => this.router.navigateByUrl('/pages/consumers'))
  //     ),
  //   { dispatch: false }
  // );
  // cancelLicenseServices$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(MoveUserWizardStoreActions.sendLicenseServicesMoveRequest),
  //     concatMap(({ cancelRequest }) =>
  //       this.cancelWizardService.cancelLicenseServices(cancelRequest).pipe(
  //         map((response) => {
  //           if (response) {
  //             return MoveUserWizardStoreActions.cancelRequestSuccess({
  //               response: response.data,
  //             });
  //           } else {
  //             throwError('No data received');
  //           }
  //         }),
  //         catchError((error) => {
  //           let errorMessage = '';
  //           if (error instanceof HttpErrorResponse) {
  //             errorMessage = error.error.errorKey;
  //           } else {
  //             errorMessage = error;
  //           }
  //           return of(MoveUserWizardStoreActions.cancelRequestFail({ errorMessage }));
  //         })
  //       )
  //     )
  //   )
  // );

  constructor(private actions$: Actions, private router: Router) {}
}
