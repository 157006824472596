import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Days } from '../cron-editor-enums';

export enum CronDaysOptions {
  everyDay = 1,
  everyNDayStartFromWeekDay,
  everyNDayStartFromMonthDay,
  specificWeekDays,
  specificMonthDays,
  lastWeekdayOfMonth,
  lastDayOfMonth,
  lastSpecificWeekDayOfMonth,
  nDaysBeforeEndOfMonth,
  nearestWeekdayToNOfMonth,
  onTheNWeekdayOfMonth,
}

@Component({
  selector: 'app-cron-day-tabm, cron-day-tab',
  templateUrl: './cron-day-tab.component.html',
  styleUrls: ['./cron-day-tab.component.scss'],
})
export class CronDayTabComponent implements OnInit {
  @Input() cronExpression: string;
  @Input() isEditing: boolean;
  @Output() monthdaysExpressionEvEm = new EventEmitter<string>();
  @Output() weekdaysExpressionEvEm = new EventEmitter<string>();
  selectedOption: CronDaysOptions;

  monthdaysExpression: string;
  weekdaysExpression: string;

  weekDaysRange = this.getRange(1, 7);
  weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  monthWeek = this.getRange(1, 5);
  monthDaysRange = this.getRange(1, 31);

  //everyNDayStartFromWeekDay
  everyNWeekDays: number;
  everyNWeekDaysStartFrom: string;

  //everyNDayStartFromMonthDay
  everyNMonthDays: number;
  everyNMonthDaysStartFrom: number;

  //specificWeekDays
  specificWeekdays: string[] = [];

  //specificMonthDays
  specificMonthdays: number[] = [];

  //lastWeekDayOfMonth
  lastSpecificWeekDayOfMonth: number;

  //nDaysBeforeEndOfMonth
  nDaysBeforeEndOfMonth: number;

  //nearestWeekdayToNOfMonth
  nearestWeekdayToNOfMonth: number;

  //onTheNWeekdayOfMonth
  onTheNWeekdayOfMonthOrdinal: number;
  onTheNWeekdayOfMonthWeekday: number;

  constructor() {}

  ngOnInit(): void {
    this.everyNWeekDays = this.weekDaysRange[0];
    this.everyNWeekDaysStartFrom = this.weekDays[0];
    this.everyNMonthDays = this.monthDaysRange[0];
    this.everyNMonthDaysStartFrom = this.monthDaysRange[0];
    this.lastSpecificWeekDayOfMonth = 1;
    this.nDaysBeforeEndOfMonth = this.monthDaysRange[0];
    this.nearestWeekdayToNOfMonth = this.monthDaysRange[0];
    this.onTheNWeekdayOfMonthOrdinal = this.monthWeek[0];
    this.onTheNWeekdayOfMonthWeekday = 1;
    this.parseCronExpression();
  }

  parseCronExpression() {
    const cronParts = this.cronExpression.split(' ');
    this.monthdaysExpression = cronParts[3];
    this.weekdaysExpression = cronParts[5];
    if (
      (this.monthdaysExpression === '?' && this.weekdaysExpression === '*') ||
      (this.monthdaysExpression === '*' && this.weekdaysExpression === '?')
    ) {
      this.selectedOption = CronDaysOptions.everyDay;
    }
    // WEEK DAY BRANCH
    else if (this.monthdaysExpression === '?') {
      if (this.weekdaysExpression.match(/^\d{1,2}\/\d{1,2}$/)) {
        this.selectedOption = CronDaysOptions.everyNDayStartFromWeekDay;
        const cron_parts = this.weekdaysExpression.split('/');
        this.everyNWeekDays = parseInt(cron_parts[1]);
        this.everyNWeekDaysStartFrom = this.weekDays[parseInt(cron_parts[0]) - 1];
      } else if (
        this.weekdaysExpression.match(/^(MON|TUE|WED|THU|FRI|SAT|SUN)$|^((MON|TUE|WED|THU|FRI|SAT|SUN),)+(MON|TUE|WED|THU|FRI|SAT|SUN)$/)
      ) {
        this.selectedOption = CronDaysOptions.specificWeekDays;
        this.specificWeekdays = this.weekdaysExpression.split(',');
      } else if (this.weekdaysExpression.match(/^[1-9]L$/)) {
        this.selectedOption = CronDaysOptions.lastSpecificWeekDayOfMonth;
        this.lastSpecificWeekDayOfMonth = parseInt(this.weekdaysExpression.replace('L', ''));
      } else if (this.weekdaysExpression.match(/^[1-5]#[1-7]$/)) {
        this.selectedOption = CronDaysOptions.onTheNWeekdayOfMonth;
        const cron_parts = this.weekdaysExpression.split('#');
        this.onTheNWeekdayOfMonthOrdinal = parseInt(cron_parts[0]);
        this.onTheNWeekdayOfMonthWeekday = parseInt(cron_parts[1]);
      }
    }
    // MONTH DAY BRANCH
    else if (this.weekdaysExpression === '?') {
      if (this.monthdaysExpression.match(/^\d{1,2}\/\d{1,2}$/)) {
        this.selectedOption = CronDaysOptions.everyNDayStartFromMonthDay;
        const cron_parts = this.monthdaysExpression.split('/');
        this.everyNMonthDays = parseInt(cron_parts[1]);
        this.everyNMonthDaysStartFrom = parseInt(cron_parts[0]);
      } else if (this.monthdaysExpression.match(/^\d{1,2}$|^(\d{1,2},)+\d{1,2}$/)) {
        this.selectedOption = CronDaysOptions.specificMonthDays;
        this.specificMonthdays = this.monthdaysExpression.split(',').map((n) => parseInt(n));
      } else if (this.monthdaysExpression === 'L') {
        this.selectedOption = CronDaysOptions.lastDayOfMonth;
      } else if (this.monthdaysExpression === 'LW') {
        this.selectedOption = CronDaysOptions.lastWeekdayOfMonth;
      } else if (this.monthdaysExpression.match(/^L-\d{1,2}$/)) {
        this.selectedOption = CronDaysOptions.nDaysBeforeEndOfMonth;
        this.nDaysBeforeEndOfMonth = parseInt(this.monthdaysExpression.replace('L-', ''));
      } else if (this.monthdaysExpression.match(/^\d{1,2}W$/)) {
        this.selectedOption = CronDaysOptions.nearestWeekdayToNOfMonth;
        this.nearestWeekdayToNOfMonth = parseInt(this.monthdaysExpression.replace('W', ''));
      }
    }
  }

  regenerateCron() {
    switch (this.selectedOption) {
      case CronDaysOptions.everyDay:
        this.monthdaysExpression = '*';
        this.weekdaysExpression = '?';
        break;
      case CronDaysOptions.everyNDayStartFromWeekDay:
        const startFrom = this.weekDays.findIndex((d) => d === this.everyNWeekDaysStartFrom);
        this.monthdaysExpression = '?';
        this.weekdaysExpression = `${startFrom + 1}/${this.everyNWeekDays}`;
        break;
      case CronDaysOptions.everyNDayStartFromMonthDay:
        this.monthdaysExpression = `${this.everyNMonthDaysStartFrom}/${this.everyNMonthDays}`;
        this.weekdaysExpression = '?';
        break;
      case CronDaysOptions.specificWeekDays:
        this.monthdaysExpression = '?';
        this.weekdaysExpression = this.specificWeekdays.length > 0 ? this.specificWeekdays.join(',') : 'SUN';
        break;
      case CronDaysOptions.specificMonthDays:
        this.monthdaysExpression = this.specificMonthdays.length > 0 ? this.specificMonthdays.join(',') : '1';
        this.weekdaysExpression = '?';
        break;
      case CronDaysOptions.lastDayOfMonth:
        this.monthdaysExpression = 'L';
        this.weekdaysExpression = '?';
        break;
      case CronDaysOptions.lastWeekdayOfMonth:
        this.monthdaysExpression = 'LW';
        this.weekdaysExpression = '?';
        break;
      case CronDaysOptions.lastSpecificWeekDayOfMonth:
        this.monthdaysExpression = '?';
        this.weekdaysExpression = `${this.lastSpecificWeekDayOfMonth}L`;
        break;
      case CronDaysOptions.nDaysBeforeEndOfMonth:
        this.monthdaysExpression = `L-${this.nDaysBeforeEndOfMonth}`;
        this.weekdaysExpression = '?';
        break;
      case CronDaysOptions.nearestWeekdayToNOfMonth:
        this.monthdaysExpression = `${this.nearestWeekdayToNOfMonth}W`;
        this.weekdaysExpression = '?';
        break;
      case CronDaysOptions.onTheNWeekdayOfMonth:
        this.monthdaysExpression = '?';
        this.weekdaysExpression = `${this.onTheNWeekdayOfMonthOrdinal}#${this.onTheNWeekdayOfMonthWeekday}`;
        break;
    }
    this.monthdaysExpressionEvEm.emit(this.monthdaysExpression);
    this.weekdaysExpressionEvEm.emit(this.weekdaysExpression);
  }

  selectAllWeekdayCheckboxes() {
    this.specificWeekdays = JSON.parse(JSON.stringify(this.weekDays));
    this.regenerateCron();
  }

  resetWeekdayCheckboxes() {
    this.specificWeekdays = [];
    this.regenerateCron();
  }

  selectAllMonthdayCheckboxes() {
    this.specificMonthdays = JSON.parse(JSON.stringify(this.monthDaysRange));
    this.regenerateCron();
  }

  resetMonthdayCheckboxes() {
    this.specificMonthdays = [];
    this.regenerateCron();
  }

  weekdayCheckboxSelectionChanged($event: MatCheckboxChange) {
    this.selectedOption = CronDaysOptions.specificWeekDays;
    const value = $event.source.value;
    const newChecked = $event.checked;
    if (newChecked) {
      const selectedWeekDays = Object.assign([], this.specificWeekdays);
      selectedWeekDays.push(value);
      this.specificWeekdays = this.weekDays.filter((d) => selectedWeekDays.includes(d));
    } else {
      const index = this.specificWeekdays.findIndex((d) => d === value);
      if (index > -1) {
        this.specificWeekdays.splice(index, 1);
      }
    }
    this.regenerateCron();
  }

  monthdayCheckboxSelectionChanged($event: MatCheckboxChange) {
    this.selectedOption = CronDaysOptions.specificMonthDays;
    const value = parseInt($event.source.value);
    const newChecked = $event.checked;
    if (newChecked) {
      this.specificMonthdays.push(value);
      this.specificMonthdays.sort(function (a, b) {
        return a - b;
      });
    } else {
      const index = this.specificMonthdays.indexOf(value, 0);
      if (index > -1) {
        this.specificMonthdays.splice(index, 1);
      }
    }
    this.regenerateCron();
  }

  isWeekdayCheckboxChecked(n: string): boolean {
    return this.specificWeekdays.includes(n);
  }

  isMonthdayCheckboxChecked(n: number): boolean {
    return this.specificMonthdays.includes(n);
  }

  get CronDaysOptions() {
    return CronDaysOptions;
  }

  private getRange(start: number, end: number): number[] {
    const length = end - start + 1;
    return Array.apply(null, Array(length)).map((__, i) => i + start);
  }

  public weekDayDisplay(day: string): string {
    return Days[day];
  }

  public weekDayFromNumberDisplay(day: number): string {
    return Days[this.weekDays[day - 1]];
  }

  public monthDayDisplay(day: number): string {
    switch (day) {
      case 1:
        return _('cron_editor_label_1st_day');
      case 2:
        return _('cron_editor_label_2nd_day');
      case 3:
        return _('cron_editor_label_3rd_day');
      case 4:
        return _('cron_editor_label_4th_day');
      case 5:
        return _('cron_editor_label_5th_day');
      case 6:
        return _('cron_editor_label_6th_day');
      case 7:
        return _('cron_editor_label_7th_day');
      case 8:
        return _('cron_editor_label_8th_day');
      case 9:
        return _('cron_editor_label_9th_day');
      case 10:
        return _('cron_editor_label_10th_day');
      case 11:
        return _('cron_editor_label_11th_day');
      case 12:
        return _('cron_editor_label_12th_day');
      case 13:
        return _('cron_editor_label_13th_day');
      case 14:
        return _('cron_editor_label_14th_day');
      case 15:
        return _('cron_editor_label_15th_day');
      case 16:
        return _('cron_editor_label_16th_day');
      case 17:
        return _('cron_editor_label_17th_day');
      case 18:
        return _('cron_editor_label_18th_day');
      case 19:
        return _('cron_editor_label_19th_day');
      case 20:
        return _('cron_editor_label_20th_day');
      case 21:
        return _('cron_editor_label_21st_day');
      case 22:
        return _('cron_editor_label_22nd_day');
      case 23:
        return _('cron_editor_label_23rd_day');
      case 24:
        return _('cron_editor_label_24th_day');
      case 25:
        return _('cron_editor_label_25th_day');
      case 26:
        return _('cron_editor_label_26th_day');
      case 27:
        return _('cron_editor_label_27th_day');
      case 28:
        return _('cron_editor_label_28th_day');
      case 29:
        return _('cron_editor_label_29th_day');
      case 30:
        return _('cron_editor_label_30th_day');
      case 31:
        return _('cron_editor_label_31st_day');
    }
  }

  public ordinalDisplay(day: number): string {
    switch (day) {
      case 1:
        return _('cron_editor_label_1st');
      case 2:
        return _('cron_editor_label_2nd');
      case 3:
        return _('cron_editor_label_3rd');
      case 4:
        return _('cron_editor_label_4th');
      case 5:
        return _('cron_editor_label_5th');
    }
  }
}
