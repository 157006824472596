import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ApiPath } from 'src/app/configs/api-paths';
import { LogService } from 'src/app/services/log-service';
import { BaseComponent } from 'src/app/shared/base-components/base-component';
import { FileTabType } from 'src/app/shared/files/file-tab/file-tab-type';
import { TreeEntity } from 'src/app/shared/files/file-tree/file-tree.component';
import { GenericTreeViewComponent } from 'src/app/shared/generic-components/generic-tree-view/generic-tree-view.component';

@Component({
  selector: 'app-files-multiselection-tree',
  templateUrl: './files-multiselection-tree.component.html',
  styleUrls: ['./files-multiselection-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilesMultiselectionTreeComponent extends BaseComponent implements OnInit {
  @ViewChild('filesTreeView') treeView: GenericTreeViewComponent;
  @Input() tabType: FileTabType;
  @Input() rootNodeLabel = 'label_root';
  @Output() nodeClick = new EventEmitter<TreeEntity>();

  selectedTreeNode: TreeEntity;

  treeViewBasePath: string;
  rootNode: boolean;
  autoselectEmptyNode: boolean;

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.tabType === null || this.tabType === undefined) {
      throw new TypeError('The input ‘tabType’ is required');
    }
    this.treeViewBasePath = ApiPath.Files.FILES_TREE(this.tabType.key);
    this.rootNode = this.tabType.rootNode;
    this.autoselectEmptyNode = this.tabType.autoselectEmptyNode;
  }

  onNodeClicked({ entityId, entityName, entityKind }) {
    const m = this.onNodeClicked.name;
    LogService.info(this, m, 'On Node Clicked', { entityId, entityName, entityKind });
    this.selectedTreeNode = { entityId, entityName, entityKind };
    this.nodeClick.emit({ entityId, entityName, entityKind });
  }
}
