import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DeactivateBillingitemAssetWizardEffects } from './effects';
import * as fromDeactivateBillingitemAssetWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromDeactivateBillingitemAssetWizard.deactivateBillingitemAssetWizardFeatureKey,
      fromDeactivateBillingitemAssetWizard.reducer
    ),
    EffectsModule.forFeature([DeactivateBillingitemAssetWizardEffects]),
  ],
})
export class DeactivateBillingitemAssetWizardStoreModule {}
