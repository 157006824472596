import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs';
import { GlobalDateFilterStoreAction } from '.';
import { selectConfigurationGlobalDateFilter, selectGlobalDateFilter } from './selectors';
import { State } from './state';

@Injectable()
export class GlobalDateFilterEffects {
  saveGlobalDateFilterStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalDateFilterStoreAction.saveGlobalDateFilterStatus),
        withLatestFrom(this.store.select(selectGlobalDateFilter), this.store.select(selectConfigurationGlobalDateFilter)),
        tap(([a, globalDateFilter, configurationGlobalDateFilter]) => {
          localStorage.setItem('globalConfigurationDateFilter', JSON.stringify(configurationGlobalDateFilter));
          localStorage.setItem('globalDateFilter', JSON.stringify(globalDateFilter));
        })
      ),
    { dispatch: false }
  );

  deleteGlobalDateFilterStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalDateFilterStoreAction.deleteGlobalDateFilterStatus),
        tap(() => {
          localStorage.removeItem('globalDateFilter');
        })
      ),
    { dispatch: false }
  );

  deleteConfigurationGlobalDateFilterStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalDateFilterStoreAction.deleteConfigurationGlobalDateFilterStatus),
        tap(() => {
          localStorage.removeItem('globalConfigurationDateFilter');
        })
      ),
    { dispatch: false }
  );
  constructor(private actions$: Actions, private store: Store<State>) {}
}
