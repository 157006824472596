<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span>
    {{ 'modal_dialog_please_select' | translate }}
    {{ Entities.FILE | translate }}
  </span>

  <div class="buttons-containe">
    <button (click)="onSelect(); $event.stopPropagation()" mat-raised-button [disabled]="!isValidSelection()" color="primary">
      {{ 'label_select' | translate }}
    </button>
    <span style="padding-right: 5px"></span>
    <button (click)="onCancel()" mat-raised-button color="primary">
      {{ 'label_cancel' | translate }}
    </button>
  </div>
</div>
<div mat-dialog-content class="files-multiselection-content">
  <div id="file-modal" class="entity-sidenav-container">
    <mat-tab-group class="entity-tab-group" id="file-tab-group">
      <!-- GENERIC TAB -->
      <!-- <mat-tab label="{{ 'files_generic_tree' | translate }}">
        <ng-template matTabContent>
          <app-files-multiselection-tab
            [tabType]="FileTabType.generic"
            [isMultiselect]="isMultiselect"
            (fileSelected)="fileSelected($event)"
          ></app-files-multiselection-tab>
        </ng-template>
      </mat-tab> -->
      <!-- COMPANY TAB -->
      <mat-tab label="{{ 'files_company_tree' | translate }}">
        <ng-template matTabContent>
          <app-files-multiselection-tab
            [tabType]="FileTabType.company"
            [isMultiselect]="isMultiselect"
            (fileSelected)="fileSelected($event)"
          ></app-files-multiselection-tab>
        </ng-template>
      </mat-tab>
      <!-- USER TAB -->
      <mat-tab label="{{ 'files_user_tree' | translate }}">
        <ng-template matTabContent>
          <app-files-multiselection-tab
            [tabType]="FileTabType.user"
            [isMultiselect]="isMultiselect"
            (fileSelected)="fileSelected($event)"
          ></app-files-multiselection-tab>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
