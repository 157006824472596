import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import { Entity } from 'src/app/models/entity';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { RootStoreState } from 'src/app/root-store';
import { AuditLogDataService } from 'src/app/services/audit-log-data.service';
import { GridBaseComponent } from '../../base-components/grid-base-component';

export interface GenericAuditLogModalDialogData {
  selectedEntity: Entity;
}

@Component({
  selector: 'app-generic-audit-log-modal-dialog',
  templateUrl: './generic-audit-log-modal-dialog.component.html',
  styleUrls: ['./generic-audit-log-modal-dialog.component.scss'],
})
export class GenericAuditLogModalDialogComponent extends GridBaseComponent {
  isPanelExpanded = true;
  showPanelTitleToggle = false;
  selectedEntity: Entity;
  hasData = false;

  constructor(
    private logDataService: AuditLogDataService,
    public dialogRef: MatDialogRef<GenericAuditLogModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericAuditLogModalDialogData,
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef
  ) {
    super(store, cdRef);
  }

  gbGetDisplayColumnTable() {
    return ['logEntityKind', 'logField', 'logKind', 'logFromvalue', 'logTovalue', 'logModified', 'logTransaction', 'logModifiedby'];
  }

  gbGetInitialOrderBy() {
    return 'logModified';
  }

  gbGetInitialSort() {
    return 'desc';
  }

  async gbAfterViewInitTable() {
    this.dataSourceTable = new MatTableDataSource();
    this.selectedEntity = this.data.selectedEntity;
    this.initRequest();
    this.gbLoadEntitiesData();
  }

  gbLoadEntitiesData() {
    this.isLoading = true;
    this.noEntityData = false;
    if (this.gridLoadSubscription != null) {
      this.gridLoadSubscription.unsubscribe();
    }
    this.gridLoadSubscription = this.subscribe(
      this.logDataService.getAuditLogByEntityId(this.selectedEntity.entityKind, this.selectedEntity.entityId, this.request),
      (response) => {
        if (response.data) {
          this.hasData = true;
          this.isLoading = false;
          this.dataSourceTable.data = response.data;
          this.pageTotalElements = response.data[0].entityCount;
        } else {
          this.dataSourceTable.data = [];
          this.pageTotalElements = 0;
          this.isLoading = false;
          this.hasData = false;
          this.noEntityData = true;
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      }
    );
  }

  loadSpreadsheetFile() {
    this.setSpreadsheetLoading(true);
    const pr: PaginatedRequest = { ...this.request, pageSize: null };
    this.subscribe(
      this.logDataService.getAuditLogByEntityIdSpreadsheet(this.selectedEntity.entityKind, this.selectedEntity.entityId, pr),
      (blob) => {
        this.setSpreadsheetLoading(false);
        FileSaver.saveAs(blob.file, blob.filename); // FileSaver;
      },
      (error) => this.setSpreadsheetLoading(false)
    );
  }
}
