<ng-container *ngIf="searchElements">
  <div class="generic-dynamic-grid-search-elements-container">
    <div class="generic-dynamic-grid-search-elements">
      <mat-form-field appearance="outline" class="reset-outline-hint filter-field search">
        <button
          *ngIf="filter && !clipboardFilter"
          mat-icon-button
          matSuffix
          class="small-icon"
          (click)="clearSearchFilter(); $event.stopPropagation()"
        >
          <mat-icon>clear</mat-icon>
        </button>
        <mat-autocomplete
          (opened)="matAutocompletPanelOpened()"
          (closed)="matAutocompletPanelClosed()"
          #autoLastStringOption="matAutocomplete"
          (optionSelected)="applyFilterTable($event.option.value)"
        >
          <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
            {{ 'label_recent_searches' | translate }}
          </mat-option>
          <mat-option class="form-field-option" *ngFor="let opt of lastUsedFilters" [value]="opt" [class.mat-selected]="filter === opt">
            {{ opt }}
          </mat-option>
        </mat-autocomplete>
        <input
          #searchInputField
          #searchInputAutocompleteTrigger="matAutocompleteTrigger"
          (input)="searchInputAutocompleteTrigger.closePanel()"
          matInput
          [(ngModel)]="filter"
          [matAutocomplete]="autoLastStringOption"
          (debounceFilterInput)="applyFilterTable($event)"
          [debounceTime]="600"
          maxlength="1500"
          placeholder="{{ 'label_table_filter_placeholder' | translate }}"
          [disabled]="clipboardFilter != null && filter === clipboardFilter"
        />
      </mat-form-field>
      <button
        class="clipboard-filter-button"
        mat-icon-button
        color="primary"
        (click)="$event.stopPropagation(); openClipboardFilterDialog(clipboardFilterDialog)"
        matTooltip="{{ 'label_clipboard_filter' | translate }}"
        [matTooltipClass]="'fo-mat-tooltip'"
      >
        <mat-icon fontSet="fas" fontIcon="fa-paste"></mat-icon>
      </button>
      <ng-container *ngIf="searchElements">
        <mat-button-toggle
          #searchElementButtons
          [value]="elem.propertysearchelementElement"
          *ngFor="let elem of searchElements"
          class="search-element"
          [checked]="elem.propertysearchelementActive"
          (click)="changeActivated(elem)"
        >
          <span>{{ elem.propertysearchelementName | translate }}</span>
        </mat-button-toggle>
      </ng-container>
    </div>
    <div class="generic-dynamic-grid-search-buttons">
      <button
        mat-flat-button
        color="primary"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        (click)="$event.stopPropagation(); switchOverlay()"
        style="margin-right: 8px"
      >
        {{ 'label_more' | translate }}
      </button>
      <ng-container *ngIf="searchFilterrules">
        <div class="search-filterrules">
          <mat-button-toggle-group
            class="topics"
            #kindButtonGroup="matButtonToggleGroup"
            [disabled]="clipboardFilter != null && filter === clipboardFilter"
            [multiple]="true"
            [value]="[selectedFilteruleId]"
            (change)="setFilterRule($event.value)"
          >
            <mat-button-toggle [value]="1">
              <span>{{ 'label_exact_match' | translate }}</span>
            </mat-button-toggle>
            <mat-button-toggle [value]="2">
              <span>{{ 'label_begin_with' | translate }}</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </ng-container>
      <button mat-button color="warn" (click)="clearAllFilters()" [disabled]="!isFiltersSetted()">
        {{ 'label_reset_filters' | translate }}
      </button>
    </div>
  </div>

  <ng-template
    #morefilterOverlay="cdkConnectedOverlay"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOverlayOpen"
    [cdkConnectedOverlayPositions]="overlayPositions"
    (overlayOutsideClick)="$event.stopPropagation(); closeOverlay()"
  >
    <div class="generic-dynamic-grid-more-filters-overlay">
      <div class="grid-filter-options-container">
        <div class="options-container">
          <div class="table-wrapper">
            <div class="table-container items">
              <table
                class="table table-stripes table-truncate-text"
                mat-table
                [dataSource]="dataSourceTable"
                cdkDropList
                [cdkDropListData]="dataSourceTable.data"
                (cdkDropListDropped)="dropStep($event)"
              >
                <ng-container matColumnDef="propertysearchelementName">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell showIfTruncated [matTooltip]="row.propertysearchelementName" *matCellDef="let row">
                    {{ row.propertysearchelementName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>{{ 'label_active' | translate }}</th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      (change)="$event ? changeActivated(row) : null"
                      [checked]="row.propertysearchelementActive"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="down">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row; let index = index" class="icon-cell">
                    <mat-icon
                      *ngIf="index !== dataSourceTable.data.length - 1"
                      (click)="$event.stopPropagation(); moveDown(index)"
                      fontSet="fas"
                      fontIcon="fa-arrow-down"
                    ></mat-icon>
                  </td>
                </ng-container>

                <ng-container matColumnDef="up">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row; let index = index" class="icon-cell">
                    <mat-icon
                      *ngIf="index !== 0"
                      (click)="$event.stopPropagation(); moveUp(index)"
                      fontSet="fas"
                      fontIcon="fa-arrow-up"
                    ></mat-icon>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsTable"
                  cdkDragLockAxis="y"
                  cdkDrag
                  [cdkDragData]="row"
                  [cdkDragDisabled]="disableSort"
                >
                  <!-- Preview -->
                  <div *cdkDragPreview matchSize="true" class="container dragdrop-placeholder">
                    {{ row.gridfilterName }}
                  </div>
                </tr>
              </table>
            </div>
            <!-- <div class="close-button-container">
              <button mat-flat-button color="primary" (click)="closeOverlay()">
                <ng-container> {{ 'label_close' | translate }}</ng-container>
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template id="generic-dynamic-grid-clipboard-filter-dialog" #clipboardFilterDialog>
  <div
    class="dialog-draggable-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
  >
    <mat-icon>drag_indicator</mat-icon>
  </div>

  <div class="dialog-header">
    <span>{{ 'label_clipboard_filter' | translate }}</span>
    <div class="buttons-container">
      <button mat-stroked-button color="primary" (click)="$event.stopPropagation(); resetClipboardFilter()" matDialogClose>
        <span>{{ 'label_reset' | translate }}</span>
      </button>
      <span style="margin-left: 0.5rem"></span>
      <button mat-flat-button color="primary" (click)="$event.stopPropagation(); setClipboardFilter()" matDialogClose>
        <span>{{ 'label_set' | translate }}</span>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="generic-dynamic-grid-clipboard-filter-dialog-content">
    <div class="particulars-form">
      <div class="particulars-row no-label">
        <div class="particulars-col">
          <div class="label-container">
            <span class="particulars-form-title">{{ 'label_clipboard_filter_hint' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="particulars-row clipboard-filter">
        <div class="particulars-col fullwidth multiline">
          <span class="control-value">
            <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
              <textarea matInput type="text" [(ngModel)]="clipboardFilter" cdkTextareaAutosize></textarea>
            </mat-form-field>
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
