import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { Desk } from 'src/app/models/desk';
import { ChangeCostcentreAllocationsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/change-costcentre-allocations-wizard/activation-step/change-costcentre-allocations-due-date-info';
import { ChangeCostcentreAllocationsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/change-costcentre-allocations-wizard/init-step/init-step';
import * as fromChangeCostcentreAllocationsWizard from './reducer';
import { State } from './state';

export const selectChangeCostcentreAllocationsWizardState = createFeatureSelector<State>(
  fromChangeCostcentreAllocationsWizard.changeCostcentreAllocationsWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectChangeCostcentreAllocationsWizardState,
  (state: State): ChangeCostcentreAllocationsInitStep => state.initStep
);

export const selectDueDateStep = createSelector(
  selectChangeCostcentreAllocationsWizardState,
  (state: State): ChangeCostcentreAllocationsDueDates => state.dueDatesStep
);

export const selectAllocationStep = createSelector(
  selectChangeCostcentreAllocationsWizardState,
  (state: State): Desk[] => state.allocationStep
);

export const selectChangeCostcentreAllocationsState = createSelector(
  selectChangeCostcentreAllocationsWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(
  selectChangeCostcentreAllocationsWizardState,
  (state: State): string => state.errorMessage
);
