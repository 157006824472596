import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, throwError, withLatestFrom } from 'rxjs';
import { CancelWizardDataService } from 'src/app/services/wizards/cancel-user-wizard-data.service';
import { CancelSharedAssetWizardActions } from '.';
import { selectInitStep } from './selectors';
import { State } from './state';

@Injectable()
export class CancelSharedAssetWizardEffects {
  newCancelSharedAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CancelSharedAssetWizardActions.createNewCancelSharedAsset),
      withLatestFrom(this.store.select(selectInitStep)),
      concatMap(([{ request }, initStep]) =>
        this.cancelWizardService.cancelSharedAssetLicenseServices(initStep.assetId, request).pipe(
          map((response) => {
            if (response) {
              return CancelSharedAssetWizardActions.createNewCancelSharedAssetSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            const errorMessage = error;
            return of(CancelSharedAssetWizardActions.createNewCancelSharedAssetFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<State>, private cancelWizardService: CancelWizardDataService) {}
}
