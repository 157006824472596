<mat-form-field
  appearance="outline"
  class="form-field-input reset-outline-hint"
  [ngClass]="[isViewMode ? 'view-mode' : '', isDirtyEnabled && isDirtyTranslations() ? DIRTY_CLASSNAME : '']"
>
  <input
    type="text"
    matInput
    [disabled]="isDisabled || isViewMode"
    (input)="setActualLanguageTraslation()"
    [(ngModel)]="actualLanguageTranslation"
  />

  <button mat-icon-button matSuffix class="small-icon" (click)="openTranslationsDialog()">
    <mat-icon>language</mat-icon>
  </button>
</mat-form-field>
