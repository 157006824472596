<div
  class="login-container"
  [ngStyle]="{
    'background-image': publicPageSettings && publicPageSettings.backgroungImage ? 'url(' + publicPageSettings.backgroungImage + ')' : ''
  }"
>
  <div class="modal-login mat-elevation-z24">
    <ng-container *ngIf="isAdminTentantCogs">
      <button mat-icon-button class="tenant-admin-cogs" (click)="goTenantAdmin()" *ngIf="!isAdminTenantAuth">
        <mat-icon fontSet="fa" fontIcon="fa-cogs"></mat-icon>
      </button>
      <button mat-icon-button class="tenant-admin-close-cogs" (click)="closeTenantAdmin()" *ngIf="isAdminTenantAuth && !isMultiTenant">
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>
    <div class="modal-login-form">
      <!-- <img class="brand-logo" src="./../../../../assets/imgs/PoweredByFinOffice.png" alt="FinOffice powered by BST" /> -->
      <img *ngIf="headerImage" class="brand-logo" [src]="transform(headerImage)" alt="BST" />
      <!--<div class="login-divider">
        <hr class="line" />
      </div>-->
      <div *ngIf="!isForgotForm; else forgotPasswordDiv">
        <div *ngIf="!isMultiTenant; else multiTenant">
          <div *ngIf="!isTwoFactorMethod; else twoFactorMethod">
            <div *ngIf="!isAuthenticated; else loggedIn">
              <span class="modal-title">{{ (this.isAdminTenantAuth ? 'login_tenantadmin_title' : 'login_title') | translate }}</span>
              <form [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">
                <mat-form-field>
                  <mat-label>{{ 'login_placeholder_email' | translate }}</mat-label>
                  <input matInput formControlName="email" autocomplete="username" autofocus />
                  <mat-error *ngIf="isSubmitted && formControls.email.errors">
                    {{ 'login_error_required_email' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{ 'login_placeholder_password' | translate }}</mat-label>
                  <input matInput type="password" formControlName="password" autocomplete="current-password" autofocus />
                  <mat-error *ngIf="isSubmitted && formControls.password.errors">
                    {{ 'login_error_required_password' | translate }}
                  </mat-error>
                </mat-form-field>
                <div class="login-btn" *ngIf="!isLoading">
                  <button mat-flat-button color="primary" [disabled]="isSubmitted && isAuthenticated">
                    {{ 'login_button' | translate }}
                  </button>
                </div>
                <div *ngIf="isLoading" class="login-spinner-container">
                  <mat-spinner diameter="50"></mat-spinner>
                </div>
                <div *ngIf="isSubmitted && authError">
                  <app-alert
                    status="error"
                    [message]="authError.errorKey | translate"
                    [tooltip]="authError.errorMessage !== authError.errorKey ? authError.errorMessage : null"
                  ></app-alert>
                </div>
                <a [routerLink]="[]" (click)="isForgotForm = true" class="text-button">
                  {{ 'login_forgot_password' | translate }}
                </a>
              </form>
              <ng-container *ngIf="!isAdminTenantAuth && getOpenAuth() && getOpenAuth().length > 0">
                <mat-divider></mat-divider>
                <div class="oauth-login">
                  <ng-container *ngFor="let auth of getOpenAuth()"
                    ><div class="oauth-logo" (click)="oauthLogin(auth.link)" *ngIf="auth.show">
                      <img [src]="transform(auth.image)" alt="BST" />
                      <span>{{ auth.name }}</span>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="isAdminTenantAuth && getAdminOpenAuth() && getAdminOpenAuth().length > 0">
                <mat-divider></mat-divider>
                <div class="oauth-login">
                  <ng-container *ngFor="let auth of getAdminOpenAuth()"
                    ><div class="oauth-logo" (click)="oauthLogin(auth.link)" *ngIf="auth.show">
                      <img [src]="transform(auth.image)" alt="BST" />
                      <span>{{ auth.name }}</span>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="footer"
    [ngStyle]="{
      'background-color': publicPageSettings && publicPageSettings.footerBackgroundColor ? publicPageSettings.footerBackgroundColor : ''
    }"
  >
    <span
      *ngIf="publicPageSettings"
      [ngStyle]="{
        color: publicPageSettings && publicPageSettings.footerFontColor ? publicPageSettings.footerFontColor : ''
      }"
      >{{ publicPageSettings?.footerText }}
      <a
        [href]="publicPageSettings.footerLink"
        [ngStyle]="{
          color: publicPageSettings && publicPageSettings.footerLinkColor ? publicPageSettings.footerLinkColor : ''
        }"
        >{{ publicPageSettings?.footerLinkText }}</a
      >
      {{ publicPageSettings?.footerCopyright }}
    </span>
  </div>
</div>
<ng-template #multiTenant
  ><span class="modal-title">{{ 'login_tenant_title' | translate }}</span>
  <form [formGroup]="loginMultitenantForm" (ngSubmit)="selectTenant()" class="login-form">
    <mat-form-field>
      <mat-select
        disableOptionCentering
        panelClass="finoffice-matselect-panel"
        panelClass="finoffice-matselect-panel"
        formControlName="tenant"
        required
      >
        <mat-option class="form-field-option" *ngFor="let tenant of tenants" [value]="tenant.key">
          {{ tenant.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="login-btn" *ngIf="!isLoading">
      <button mat-flat-button color="primary" [disabled]="isSubmitted && isAuthenticated">
        {{ 'login_tenant_button' | translate }}
      </button>
    </div>
    <div *ngIf="isLoading" class="login-spinner-container">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div *ngIf="isSubmitted && authError">
      <app-alert status="error" [message]="authError.errorKey | translate" [tooltip]="authError.errorMessage"></app-alert>
    </div>
    <a [routerLink]="[]" (click)="resetLoginFormsStatus()" class="text-button">
      {{ 'login_back_to_login_form' | translate }}
    </a>
  </form>
</ng-template>
<ng-template #twoFactorMethod>
  <!-- ONE TIME PIN TWO FACTOR METHOD -->
  <ng-container *ngIf="isOneTimePinTwoFactorMethod">
    <span class="modal-title">{{ 'login_onetimepasscode_title' | translate }}</span>
    <form [formGroup]="loginOneTimePinForm" (ngSubmit)="sendOTP()" class="login-form">
      <mat-form-field>
        <mat-label>{{ 'login_placeholder_otp' | translate }}</mat-label>
        <input matInput type="text" formControlName="otp" autocomplete="one-time-code" autofocus />
        <mat-error *ngIf="isSubmitted && otpFormControls.otp.errors">
          {{ 'login_error_required_otp' | translate }}
        </mat-error>
      </mat-form-field>
      <div class="login-btn" *ngIf="!isLoading">
        <button mat-flat-button color="primary" [disabled]="isSubmitted && isAuthenticated">
          {{ 'login_otp_button' | translate }}
        </button>
      </div>
      <div *ngIf="isLoading" class="login-spinner-container">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <div *ngIf="isSubmitted && authError">
        <app-alert status="error" [message]="authError.errorKey | translate" [tooltip]="authError.errorMessage"></app-alert>
      </div>
      <a [routerLink]="[]" (click)="resendOTP()" class="text-button">
        {{ 'login_resend_otp_button' | translate }}
      </a>
      <a [routerLink]="[]" (click)="resetLoginFormsStatus()" class="text-button">
        {{ 'login_back_to_login_form' | translate }}
      </a>
    </form>
  </ng-container>
</ng-template>
<ng-template #loggedIn>
  <app-alert status="success" [message]="'login_message_success' | translate"></app-alert>
</ng-template>
<ng-template #forgotPasswordDiv>
  <div *ngIf="!isForgotMailSent; else forgotMailSent">
    <span class="modal-title">{{ 'login_forgot_password_title' | translate }}</span>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()" class="login-form">
      <mat-form-field>
        <mat-label>{{ 'login_placeholder_email' | translate }}</mat-label>
        <input matInput formControlName="email" autocomplete="username" autofocus />
        <mat-error *ngIf="isForgotSubmitted && forgotFormControls.email.errors">
          {{ 'login_error_required_email' | translate }}
        </mat-error>
      </mat-form-field>
      <div class="login-btn">
        <button mat-flat-button color="primary" [disabled]="isForgotLoading()">
          {{ 'login_forgot_password_button' | translate }}
        </button>
      </div>

      <div class="spinner-container">
        <div *ngIf="isForgotLoading()">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
      </div>
      <div *ngIf="isForgotSubmitted && forgotError">
        <app-alert status="error" [message]="forgotError.errorKey | translate" [tooltip]="forgotError.errorMessage"></app-alert>
      </div>
      <a [routerLink]="[]" (click)="resetLoginFormsStatus()" class="text-button">
        {{ 'login_back_to_login_form' | translate }}
      </a>
    </form>
  </div>
</ng-template>

<ng-template #forgotMailSent>
  <app-alert
    status="success"
    [message]="'forgot_mail_sent_message_success' | translate"
    [tooltip]="forgotPasswordForm.get('email').value"
  ></app-alert>
</ng-template>
