import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entity } from 'src/app/models/entity';
import { DynamicFlatNode, GenericTreeNode } from 'src/app/models/tree-view-flat-node';
import { CoreDataService } from 'src/app/services/core-data.service';
import { FilesService } from 'src/app/services/files.data.services';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import {
  MoveDirectorySelectDialogComponent,
  MoveDirectorySelectDialogData,
} from '../move-directory-select-dialog/move-directory-select-dialog.component';
import { FileTreeViewDatabase, FileTreeViewDatasource } from './file-tree-view-data-source';

@Component({
  selector: 'app-file-tree-view',
  templateUrl: './file-tree-view.component.html',
  styleUrls: ['./file-tree-view.component.scss'],
  providers: [FileTreeViewDatabase],
})
export class FileTreeViewComponent extends FormatComponent implements OnInit {
  @Input() fileTabType: string;
  @Input() rootNodeLabel = 'label_root';
  @Input() defaultRootNode = false;
  @Input() autoselectEmptyNode = false;
  @Output() nodeClick = new EventEmitter<Entity>();

  isLoading = false;
  noEntityData = false;
  treeControl: FlatTreeControl<DynamicFlatNode>;
  dataSource: FileTreeViewDatasource;
  selectedNode: DynamicFlatNode;
  displayedColumnsTable = ['entityName'];
  rootNode: GenericTreeNode[] = [
    {
      entityCount: 0,
      entityId: -1,
      entityName: this.translate.instant(this.rootNodeLabel),
      entityKind: 'root',
      entityDescription: 'label_root_description',
      entityInfo: '',
      entityUpdate: false,
      entityDelete: false,
      entityChilds: 1,
      entityParentId: null,
      entityActive: true,
    },
  ];
  /* Drag and drop */
  dragNode: DynamicFlatNode;
  dragNodeExpandOverWaitTimeMs = 300;
  dragNodeExpandOverNode: DynamicFlatNode;
  dragNodeExpandOverTime: number;
  dragNodeExpandOverArea: number;

  constructor(
    private dialog: MatDialog,
    private database: FileTreeViewDatabase,
    private coreDataService: CoreDataService,
    private messageNotifierService: MessageNotifierService,
    private filesService: FilesService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
  }

  // ngOnChanges(change: SimpleChanges) {
  //   this.defaultRootNode = change.defaultRootNode ? change.defaultRootNode.currentValue : false;
  //   if (!this.defaultRootNode) {
  //     this.fetchData();
  //   }
  // }

  ngOnInit() {
    this.dataSource = new FileTreeViewDatasource(this.treeControl, this.database, this.coreDataService);
    this.dataSource.baseApiPath = ApiPath.Files.FILES_TREE(this.fileTabType);
    if (this.defaultRootNode) {
      this.rootNode[0].entityName = this.translate.instant(this.rootNodeLabel);
      this.dataSource.data = this.database.initialData(this.rootNode);
      this.noEntityData = false;
    } else {
      this.fetchData();
    }
  }

  fetchData(skipNodeClick?: boolean) {
    this.isLoading = true;
    this.subscribe(
      this.coreDataService.getGenericTreeNodeChilds(ApiPath.Files.FILES_TREE(this.fileTabType), -1, 'nodes', null),
      (response) => {
        if (response.data) {
          this.dataSource.data = this.database.initialData(response.data);
          this.noEntityData = false;
        } else {
          this.noEntityData = true;
        }
        if (this.autoselectEmptyNode && !skipNodeClick) {
          this.nodeClick.emit({ ...new Entity(), entityKind: response.entityKind, entityId: -1 });
        }
        this.isLoading = false;
      }
    );
  }

  moveDirectory() {
    this.subscribe(
      this.dialog
        .open(MoveDirectorySelectDialogComponent, {
          width: '40vw',
          minHeight: '60vh',
          data: {
            fileTabType: this.fileTabType,
            movedFolder: this.selectedNode.item.entityName,
            movedFolderId: this.selectedNode.item.entityId,
            parentFolderId: this.getParent(this.selectedNode)?.item.entityId,
            disableChildNodes: true,
          } as MoveDirectorySelectDialogData,
        })
        .afterClosed(),
      (entity) => {
        if (entity) {
          this.moveDirectoryPost(this.selectedNode.item, entity);
        }
      }
    );
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (x: number, nodeData: DynamicFlatNode) => nodeData.expandable;

  onClick(event) {
    this.selectedNode = event;
    const { entityId, entityName, entityKind } = event.item;
    this.nodeClick.emit(event.item);
  }

  async addChildToSelectedNode(child: Entity) {
    this.dataSource.addChildrenToParentNode(this.selectedNode);
    this.onClick(
      new DynamicFlatNode(
        { ...new GenericTreeNode(), ...child },
        this.selectedNode.level + 1,
        false,
        false,
        false,
        this.selectedNode.item.entityId
      )
    );
    // this.refreshNodeChilds(this.selectedNode);
  }

  updateEntityNameSelectedNode(name: string) {
    this.selectedNode.item.entityName = name;
  }

  removeChildToSelectedNode() {
    this.dataSource.removeChildrenToParentNode(this.selectedNode);
  }

  removeSelectedNode() {
    this.database.removeChildren(this.getParent(this.selectedNode)?.item, this.selectedNode.item);
  }

  /**
   * Iterate over each node in reverse order and return the first node that has a lower level than the passed node.
   */
  getParent(node: DynamicFlatNode) {
    const { treeControl } = this;
    const currentLevel = treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = treeControl.dataNodes[i];

      if (treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }

  getAllParentIds(node: DynamicFlatNode) {
    const { treeControl } = this;
    const currentLevel = treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = treeControl.dataNodes.indexOf(node) - 1;
    const ids = [];

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = treeControl.dataNodes[i];
      ids.push(currentNode.item.entityId);

      if (treeControl.getLevel(currentNode) === 0) {
        break;
      }
    }
    return ids;
  }

  /* Drag and Drop*/
  handleDragStart(event, node) {
    // Required by Firefox (https://stackoverflow.com/questions/19055264/why-doesnt-html5-drag-and-drop-work-in-firefox)
    event.dataTransfer.setData('foo', 'bar');
    //event.dataTransfer.setDragImage(this.emptyItem.nativeElement, 0, 0);
    this.dragNode = node;
    this.cdRef.detectChanges();
    // this.treeControl.collapse(node);
  }

  handleDragOver(event, node: DynamicFlatNode) {
    event.preventDefault();
    // Handle node expand
    if (this.dragNodeExpandOverNode && node === this.dragNodeExpandOverNode) {
      if (Date.now() - this.dragNodeExpandOverTime > this.dragNodeExpandOverWaitTimeMs) {
        if (!this.treeControl.isExpanded(node)) {
          this.treeControl.expand(node);
          //this.cd.detectChanges();
        }
      }
    } else {
      this.dragNodeExpandOverNode = node;
      this.dragNodeExpandOverTime = new Date().getTime();
    }

    // Handle drag area
    const percentageY = event.offsetY / event.target.clientHeight;
    if (0 <= percentageY && percentageY <= 0.25) {
      this.dragNodeExpandOverArea = 1;
    } else if (1 >= percentageY && percentageY >= 0.75) {
      this.dragNodeExpandOverArea = -1;
    } else {
      this.dragNodeExpandOverArea = 0;
    }
  }

  handleDrop(event, node: DynamicFlatNode) {
    if (node !== this.dragNode) {
      // let newItem: TodoItemNode;
      if (this.dragNodeExpandOverArea === 0) {
        if (this.getParent(this.dragNode)?.item.entityId === node.item.entityId) {
          this.messageNotifierService.showWarningMessage('toastr_directory_cannot_move_into_parent_folder');
        } else if (this.getAllParentIds(node)?.includes(this.dragNode.item.entityId)) {
          this.messageNotifierService.showWarningMessage('toastr_directory_cannot_move_into_child_folder');
        } else {
          this.moveDirectoryPost(this.dragNode.item, node.item);
        }
      }
    }
    this.handleDragEnd(event);
  }

  private moveDirectoryPost(movedNode: GenericTreeNode, destinationNode: GenericTreeNode) {
    this.subscribe(this.filesService.moveDirectory(this.fileTabType, movedNode.entityId, destinationNode.entityId), (response) => {
      if (response.data) {
        if (response.data) {
          if (response.data.state) {
            this.messageNotifierService.showSuccessMessage(_('toastr_directory_success_moved'));
            this.fetchData();
          } else if (response.data.error) {
            // Fail
            this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
          } else {
            this.messageNotifierService.showWarningMessage('toastr_no_data');
          }
        }
      }
    });
  }

  handleDragEnd(event) {
    this.dragNode = null;
    this.dragNodeExpandOverNode = null;
    this.dragNodeExpandOverTime = 0;
    this.dragNodeExpandOverArea = NaN;
    this.cdRef.detectChanges();
    event.preventDefault();
  }

  getStyle(node: DynamicFlatNode) {
    if (this.dragNode === node) {
      return 'drag-start';
    } else if (this.dragNodeExpandOverNode === node) {
      switch (this.dragNodeExpandOverArea) {
        case 1:
          // return 'drop-above';
          return '';
        case -1:
          // return 'drop-below';
          return '';
        default:
          return 'drop-center';
      }
    } else {
      return '';
    }
  }
}
