import { createAction, props } from '@ngrx/store';

export const setGlobalDateFilter = createAction('[GlobalDateFilter] Set Global Date Filter', props<{ date: Date }>());

export const resetGlobalDateFilter = createAction('[GlobalDateFilter] Reset Global Date Filter');

export const currentGlobalDateFilter = createAction('[GlobalDateFilter] Current Global Date Filter');

export const saveGlobalDateFilterStatus = createAction('[GlobalDateFilter] Save Global Date Filter Status');

export const deleteGlobalDateFilterStatus = createAction('[GlobalDateFilter] Delete Global Date Filter Status');

export const deleteConfigurationGlobalDateFilterStatus = createAction('[GlobalDateFilter] Delete Configuration Global Date Filter Status');

export const useConfigurationGlobalDateFilter = createAction('[GlobalDateFilter] Use Configuration Global Date Filter');

export const leaveConfigurationGlobalDateFilter = createAction('[GlobalDateFilter] Leave Configuration Global Date Filter');

export const setConfigurationGlobalDateFilter = createAction(
  '[GlobalDateFilter] Set Configuration Global Date Filter',
  props<{ date: Date }>()
);

export const resetConfigurationGlobalDateFilter = createAction('[GlobalDateFilter] Reset Configuration Global Date Filter');

export const currentConfigurationGlobalDateFilter = createAction('[GlobalDateFilter] Current Configuration Global Date Filter');
