import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPath } from 'src/app/configs/api-paths';
import { ActionResponse } from 'src/app/models/action-response';
import { AssetSubscriptionQuantity } from 'src/app/models/asset-subscription';
import { BaseResponse } from 'src/app/models/base-response';
import { BillingItem, BillingItemParams, ENABLED_FOR_ORDER_YES } from 'src/app/models/billingitem';
import { CatalogItemType } from 'src/app/models/catalog-item-particulars';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { ReplaceProductServiceRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-product-service-wizard/replace-product-service-confirm-step/replace-product-service-request';
import { environment } from 'src/environments/environment';
import { LogService } from '../log-service';

@Injectable({
  providedIn: 'root',
})
export class ReplaceProductServiceWizardDataService {
  getBillingItems(
    request: PaginatedRequest,
    billingitemId: number,
    productfamilyId: number,
    categoryId: number
  ): Observable<BaseResponse<BillingItem[]>> {
    const m = this.getBillingItems.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = params
      .append(BillingItemParams.FILTER_EXCLUDE_IDS, billingitemId)
      .append(BillingItemParams.FILTER_PRODUCTFAMILY_IDS, productfamilyId)
      .append(BillingItemParams.FILTER_CATALOGITEM_CATEGORY_IDS, categoryId)
      .append(BillingItemParams.FILTER_CATALOGITEM_TYPE_IDS, CatalogItemType.terminal)
      .append(BillingItemParams.FILTER_ENABLEDFORORDER_IDS, ENABLED_FOR_ORDER_YES);
    const retVal = this.http.get<BaseResponse<BillingItem[]>>(path, {
      params,
    });

    return retVal;
  }

  replaceProductService(
    assetId: number,
    subscriptionId: number,
    request: ReplaceProductServiceRequest
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.replaceProductService.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_REPLACE_PRODUCT_SERVICE(assetId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = new HttpParams()
      .set('subscriptionId', subscriptionId)
      .set('replaceBillingitemId', request.billingitemId)
      .set('billingEnds', request.billingEnds);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getDefaultBillingValues(productserviceId: number): Observable<BaseResponse<AssetSubscriptionQuantity>> {
    const m = this.getDefaultBillingValues.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SUBSCRIPTIONS_CHANGE_QUANTITY);
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams().set('filterSubscriptionIds', productserviceId);
    const retVal = this.http.get<BaseResponse<AssetSubscriptionQuantity>>(path, {
      params,
    });

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
