import { Action, createReducer, on } from '@ngrx/store';
import * as ReactivateAssetWizardActions from './actions';
import { State, initialState } from './state';

export const reactivateAssetWizardFeatureKey = 'reactivateAssetWizard';

const addProductWizardReducer = createReducer(
  initialState,

  on(ReactivateAssetWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(ReactivateAssetWizardActions.selectAssetServicesInit, (state, { initAssetServiceStep }) => ({
    ...state,
    initAssetServiceStep,
  })),
  on(ReactivateAssetWizardActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(ReactivateAssetWizardActions.selectDeskStep, (state, { deskStep }) => ({
    ...state,
    deskStep,
  })),
  on(ReactivateAssetWizardActions.selectProductStep, (state, { productStep }) => ({
    ...state,
    productStep,
  })),
  on(ReactivateAssetWizardActions.selectServiceStep, (state, { serviceStep }) => ({
    ...state,
    serviceStep,
  })),
  on(ReactivateAssetWizardActions.deleteServiceStep, (state) => ({
    ...state,
    serviceStep: null,
  })),
  on(ReactivateAssetWizardActions.selectAdditionalStep, (state, { addInfoStep }) => ({
    ...state,
    addInfoStep,
  })),
  on(ReactivateAssetWizardActions.deleteAdditionalStep, (state) => ({
    ...state,
    addInfoStep: null,
  })),
  on(ReactivateAssetWizardActions.exitReactivateAssetWizard, (state) => ({
    ...state,
    initStep: null,
    initAssetServiceStep: null,
    dueDatesStep: null,
    deskStep: null,
    productStep: null,
    serviceStep: null,
    addInfoStep: null,
    errorMessage: null,
  })),
  on(ReactivateAssetWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    initAssetServiceStep: null,
    dueDatesStep: null,
    deskStep: null,
    productStep: null,
    serviceStep: null,
    addInfoStep: null,
    errorMessage: null,
  })),
  on(ReactivateAssetWizardActions.createNewReactivateAsset, (state, { newOrder }) => ({
    ...state,
    newOrder,
  })),
  on(ReactivateAssetWizardActions.createNewOrderSuccess, (state, { response }) => ({
    ...state,
    newOrderResponse: response,
  })),
  on(ReactivateAssetWizardActions.createNewOrderFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return addProductWizardReducer(state, action);
}
