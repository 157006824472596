import { KeyValue } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { PublicPageSettings } from 'src/app/models/preference-settings';
import { AuthStoreAction, AuthStoreSelectors, RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BaseComponent } from 'src/app/shared/base-components/base-component';
import { AuthAttempt } from '../auth-attempt';
import { AuthError } from '../auth-result';
import { OauthTokens } from '../auth-token';

@Component({
  selector: 'app-login-oauth-okta',
  templateUrl: './login-oauth-okta.component.html',
  styleUrls: ['./login-oauth-okta.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginOauthComponent extends BaseComponent implements OnInit {
  registrationId: string;

  publicPageSettings: PublicPageSettings;
  headerImage: string;

  code: string;
  state: string;
  isLoading = false;
  authAttempt: AuthAttempt;
  logintryId: number;
  isMultiTenant = false;
  isTwoFactorMethod = false;
  isOneTimePinTwoFactorMethod = false;
  tenants: KeyValue<string, string>[];
  loginMultitenantForm: UntypedFormGroup;
  loginOneTimePinForm: UntypedFormGroup;
  isSubmitted = true;
  isAuthenticated = false;
  authError: AuthError = null;
  oauthTokens: OauthTokens;
  adminFlag = false;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private store: Store<RootStoreState.State>,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.isLoading = true;
    this.adminFlag = this.route.snapshot.data['admin'];
    this.registrationId = this.route.snapshot.paramMap.get('registrationId');
    this.code = this.route.snapshot.queryParams['code'];
    this.state = this.route.snapshot.queryParams['state'];
    this.isMultiTenant = false;
    this.isTwoFactorMethod = false;
    this.isOneTimePinTwoFactorMethod = false;
    this.loginMultitenantForm = this.formBuilder.group({
      tenant: ['', Validators.required],
    });
    this.loginOneTimePinForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
    this.subscribe(
      this.store.pipe(select(PreferencesSelectors.selectPreferencesPublicPageSettings)),
      (publicSettings) => (this.publicPageSettings = publicSettings)
    );
    this.subscribe(
      this.store.pipe(select(PreferencesSelectors.selectPreferencesLoginImage)),
      (headerImage) => (this.headerImage = headerImage)
    );
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectLoginTryId)), (logintryId) => {
      this.logintryId = logintryId;
    });
    this.subscribe(
      combineLatest([
        this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticated)),
        this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin)),
      ]),
      ([isAuth, isAuthTenantAdmin]) => {
        this.isLoading = false;
        this.isAuthenticated = isAuth || isAuthTenantAdmin;
        // if (this.isAuthenticated) {
        //   setTimeout(() => {
        //     return this.dialogRef.close();
        //   }, 3000);
        // }
      }
    );
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectTenantList)), (tenants) => {
      this.isLoading = false;
      this.isMultiTenant = this.isSubmitted && tenants && tenants.length > 1;
      if (this.isMultiTenant) {
        this.tenants = tenants;
        this.loginMultitenantForm.setValue({ tenant: this.tenants[0].key });
      }
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectTwoFactorMethod)), (twoFactorMethod) => {
      this.isLoading = false;
      this.isTwoFactorMethod = twoFactorMethod != null;
      if (this.isTwoFactorMethod) {
        this.isOneTimePinTwoFactorMethod = twoFactorMethod === 'one-time-pin';
      }
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectOauthTokens)), (oauthTokens) => {
      this.isLoading = false;
      this.oauthTokens = oauthTokens;
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectAuthError)), (error) => {
      this.authError = error;
      if (this.authError != null) {
        this.isSubmitted = true;
      }
      this.isLoading = false;
    });

    this.authAttempt = new AuthAttempt(null, null, null, null, null, null, this.registrationId, this.code, null, null);
    if (this.adminFlag) {
      this.store.dispatch(AuthStoreAction.authTenantAdmin({ auth: this.authAttempt }));
    } else {
      this.store.dispatch(AuthStoreAction.authenticate({ auth: this.authAttempt }));
    }
  }

  // Call this method in the image source, it will sanitize it.
  transform(base64Image: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }

  selectTenant(): void {
    this.isLoading = true;
    // this.authError = null;
    this.isSubmitted = true;
    if (this.loginMultitenantForm.invalid) {
      this.isLoading = false;
      return;
    }
    this.authAttempt = new AuthAttempt(
      this.oauthTokens.getIdTokenEmail(),
      null,
      this.loginMultitenantForm.value.tenant,
      this.loginOneTimePinForm.value.otp !== '' ? this.loginOneTimePinForm.value.otp : null,
      this.logintryId,
      null,
      this.registrationId,
      null,
      this.oauthTokens.accessToken,
      this.oauthTokens.idToken
    );
    this.store.dispatch(AuthStoreAction.authenticate({ auth: this.authAttempt }));
  }

  sendOTP(): void {
    this.isLoading = true;
    // this.authError = null;
    this.isSubmitted = true;
    if (this.loginOneTimePinForm.invalid) {
      this.isLoading = false;
      return;
    }
    this.authAttempt = new AuthAttempt(
      this.oauthTokens.getIdTokenEmail(),
      null,
      this.loginMultitenantForm.value.tenant !== '' ? this.loginMultitenantForm.value.tenant : null,
      this.loginOneTimePinForm.value.otp,
      this.logintryId,
      null,
      this.registrationId,
      null,
      this.oauthTokens.accessToken,
      this.oauthTokens.idToken
    );
    this.store.dispatch(AuthStoreAction.authenticate({ auth: this.authAttempt }));
  }

  resendOTP(): void {
    // this.authError = null;
    this.isSubmitted = true;
    this.authAttempt = new AuthAttempt(
      null,
      null,
      this.loginMultitenantForm.value.tenant !== '' ? this.loginMultitenantForm.value.tenant : null,
      null,
      this.logintryId,
      null
    );
    this.store.dispatch(AuthStoreAction.authenticate({ auth: this.authAttempt }));
  }

  get otpFormControls() {
    return this.loginOneTimePinForm.controls;
  }
}
