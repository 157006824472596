import { Action, createReducer, on } from '@ngrx/store';
import * as MoveAssetServicesWizardActions from './actions';
import { State, initialState } from './state';

export const moveAssetServicesWizardFeatureKey = 'moveAssetServicesWizard';

const moveAssetServicesWizardReducer = createReducer(
  initialState,

  on(MoveAssetServicesWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(MoveAssetServicesWizardActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(MoveAssetServicesWizardActions.selectBillingAccountStep, (state, { billingaccountStep }) => ({
    ...state,
    billingaccountStep,
  })),
  on(MoveAssetServicesWizardActions.exitMoveAssetServicesWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    billingaccountStep: null,
    errorMessage: null,
    cancelRequest: null,
    response: null,
  })),
  on(MoveAssetServicesWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    billingaccountStep: null,
    errorMessage: null,
    cancelRequest: null,
    response: null,
  })),
  on(MoveAssetServicesWizardActions.createNewMoveAssetServices, (state, { cancelRequest }) => ({
    ...state,
    cancelRequest,
  })),
  on(MoveAssetServicesWizardActions.createNewMoveAssetServicesSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(MoveAssetServicesWizardActions.createNewMoveAssetServicesFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return moveAssetServicesWizardReducer(state, action);
}
