import { Component } from '@angular/core';
import { FormFieldTextKind, FormFieldTextSize, FormFieldTextWeight } from 'src/app/models/forms/form-configuration-text';
import { GenericFormFieldRequiredType } from 'src/app/models/forms/form-field';
import { BaseFormFieldComponent } from '../base-form-field/base-form-field-component';

@Component({
  selector: 'app-form-field-text',
  templateUrl: './form-field-text.component.html',
  styleUrls: ['./form-field-text.component.scss'],
})
export class FormFieldTextComponent extends BaseFormFieldComponent {
  get FormFieldTextKind() {
    return FormFieldTextKind;
  }

  get FormFieldTextSize() {
    return FormFieldTextSize;
  }

  get FormFieldTextWeight() {
    return FormFieldTextWeight;
  }

  get GenericFormFieldRequiredType() {
    return GenericFormFieldRequiredType;
  }
}
