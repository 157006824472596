import { ActionResponse } from 'src/app/models/action-response';
import { MoveAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-wizard/init-step/init-step';
import { MoveAssetWizardRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-wizard/move-asset-confirm-step/move-asset-wizard-request';
import { MoveAssetMovingToStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-wizard/move-asset-movingto-step/move-asset-movingto-step';

export interface State {
  initStep: MoveAssetInitStep;
  movingTo: MoveAssetMovingToStep;
  request: MoveAssetWizardRequest;
  response: ActionResponse;
  errorMessage: any;
}

export const initialState: State = {
  initStep: null,
  movingTo: null,
  request: null,
  response: null,
  errorMessage: null,
};
