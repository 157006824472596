import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { CancelAssetOptions } from 'src/app/models/cancel-asset-options';
import { MoveAssetServicesDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-services-wizard/activation-step/move-asset-services-due-date-info';
import { MoveAssetServicesInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-services-wizard/init-step/init-step';
import * as fromMoveAssetServicesWizard from './reducer';
import { State } from './state';

export const selectMoveAssetServicesWizardState = createFeatureSelector<State>(
  fromMoveAssetServicesWizard.moveAssetServicesWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectMoveAssetServicesWizardState,
  (state: State): MoveAssetServicesInitStep => state.initStep
);

export const selectDueDateStep = createSelector(
  selectMoveAssetServicesWizardState,
  (state: State): MoveAssetServicesDueDates => state.dueDatesStep
);

export const selectBillingAccountStep = createSelector(
  selectMoveAssetServicesWizardState,
  (state: State): CancelAssetOptions[] => state.billingaccountStep
);

export const selectMoveAssetServicesState = createSelector(
  selectMoveAssetServicesWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectMoveAssetServicesWizardState, (state: State): string => state.errorMessage);
