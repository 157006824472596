import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericFormField } from 'src/app/models/forms/form-field';
import { FormWizardStep } from 'src/app/models/forms/form-wizard';
import { FormDataService } from 'src/app/services/form-data.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

@Component({
  selector: 'app-generic-form-wizard-form-step',
  templateUrl: './generic-form-wizard-form-step.component.html',
  styleUrls: ['./generic-form-wizard-form-step.component.scss'],
})
export class GenericFormWizardFormStepComponent extends FormatComponent implements OnInit {
  @Input() wizardId: number;
  @Input() entityKind: string;
  @Input() formWizardStepData: FormWizardStep;
  @Input() saveSubj: Observable<void> = new Observable<void>();
  @Output() searchChangeValueEvEm = new EventEmitter<GenericFormField[]>();
  @Output() changeValueEvEm = new EventEmitter<GenericFormField[]>();
  isLoading = false;
  formWizardStepFields: GenericFormField[];

  constructor(private formDataService: FormDataService) {
    super();
  }

  ngOnInit(): void {
    this.loadStepData();
  }

  loadStepData() {
    this.isLoading = true;
    this.subscribe(this.formDataService.getFormWizardStepData(this.wizardId, this.formWizardStepData.formstepStep), ({ data }) => {
      this.formWizardStepFields = data ? data.map((d) => new GenericFormField(d)) : [];
      // LogService.debug(this, this.loadStepData.name, 'Step Data', this.formWizardStepFields);
      this.isLoading = false;
    });
  }
}
