/* eslint-disable @angular-eslint/directive-selector */
import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeWhile } from 'rxjs/operators';

@Directive({
  selector: '[debounceFilterInput]',
})
export class TableFilterInputDirective implements OnInit, OnDestroy {
  private search$: Subject<string> = new Subject<string>();
  private alive = true;
  @Output() debounceFilterInput = new EventEmitter<string>();
  @Input() debounceTime = 600;

  ngOnInit() {
    this.search$
      .pipe(
        takeWhile(() => this.alive),
        debounceTime(this.debounceTime),
        distinctUntilChanged()
      )
      .subscribe((query: string) => {
        this.debounceFilterInput.emit(query);
      });
  }

  ngOnDestroy() {
    this.alive = false;
    this.search$.complete();
  }

  @HostListener('input', ['$event'])
  filter(event) {
    this.search$.next(event.target.value);
  }
}
