import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

export enum CronMinutesOptions {
  everyMinute = 1,
  everyNMinuteStartFrom,
  specificMinutes,
  rangeMinutes,
}

@Component({
  selector: 'app-cron-minutes-tab, cron-minutes-tab',
  templateUrl: './cron-minutes-tab.component.html',
  styleUrls: ['./cron-minutes-tab.component.scss'],
})
export class CronMinutesTabComponent implements OnInit {
  @Input() cronExpression: string;
  @Input() isEditing: boolean;
  @Output() minutesExpressionEvEm = new EventEmitter<string>();
  selectedOption: CronMinutesOptions;
  minutesExpression: string;

  rangeMinutesOptions = this.getRange(0, 59);
  everyNMinuteOptions = this.getRange(1, 60);

  everyNMinute: number;
  everyNMinuteStartFrom: number;

  //specificMinutes
  specificMinutes: number[] = [];

  //rangeMinutes
  rangeMinutesStarts: number;
  rangeMinutesEnds: number;

  constructor() {}

  ngOnInit(): void {
    this.everyNMinute = this.everyNMinuteOptions[0];
    this.everyNMinuteStartFrom = this.rangeMinutesOptions[0];
    this.rangeMinutesStarts = this.rangeMinutesOptions[0];
    this.rangeMinutesEnds = this.rangeMinutesOptions[0];
    this.parseCronExpression();
  }

  parseCronExpression() {
    this.minutesExpression = this.cronExpression.split(' ')[1];
    if (this.minutesExpression === '*') {
      this.selectedOption = CronMinutesOptions.everyMinute;
    } else if (this.minutesExpression.match(/^\d{1,2}\/\d{1,2}$/)) {
      this.selectedOption = CronMinutesOptions.everyNMinuteStartFrom;
      const cron_parts = this.minutesExpression.split('/');
      this.everyNMinute = parseInt(cron_parts[1]);
      this.everyNMinuteStartFrom = parseInt(cron_parts[0]);
    } else if (this.minutesExpression.match(/^\d{1,2}\-\d{1,2}$/)) {
      this.selectedOption = CronMinutesOptions.rangeMinutes;
      const cron_parts = this.minutesExpression.split('-');
      this.rangeMinutesStarts = parseInt(cron_parts[0]);
      this.rangeMinutesEnds = parseInt(cron_parts[1]);
    } else if (this.minutesExpression.match(/^\d{1,2}$|^(\d{1,2},)+\d{1,2}$/)) {
      this.selectedOption = CronMinutesOptions.specificMinutes;
      this.specificMinutes = this.minutesExpression.split(',').map((n) => parseInt(n));
    }
  }

  selectAllCheckboxes() {
    this.specificMinutes = JSON.parse(JSON.stringify(this.rangeMinutesOptions));
    this.regenerateCron();
  }

  resetCheckboxes() {
    this.specificMinutes = [];
    this.regenerateCron();
  }

  checkboxSelectionChanged($event: MatCheckboxChange) {
    this.selectedOption = CronMinutesOptions.specificMinutes;
    const value = parseInt($event.source.value);
    const newChecked = $event.checked;
    if (newChecked) {
      this.specificMinutes.push(value);
      this.specificMinutes.sort(function (a, b) {
        return a - b;
      });
    } else {
      const index = this.specificMinutes.indexOf(value, 0);
      if (index > -1) {
        this.specificMinutes.splice(index, 1);
      }
    }
    this.regenerateCron();
  }

  regenerateCron() {
    switch (this.selectedOption) {
      case CronMinutesOptions.everyMinute:
        this.minutesExpression = '*';
        break;

      case CronMinutesOptions.everyNMinuteStartFrom:
        this.minutesExpression = `${this.everyNMinuteStartFrom}/${this.everyNMinute}`;
        break;

      case CronMinutesOptions.rangeMinutes:
        this.minutesExpression = `${this.rangeMinutesStarts}-${this.rangeMinutesEnds}`;
        break;

      case CronMinutesOptions.specificMinutes:
        this.minutesExpression = this.specificMinutes.length > 0 ? this.specificMinutes.join(',') : '0';
        break;
    }
    this.minutesExpressionEvEm.emit(this.minutesExpression);
  }

  isCheckboxChecked(n: number): boolean {
    return this.specificMinutes.includes(n);
  }

  get CronMinutesOptions() {
    return CronMinutesOptions;
  }

  private getRange(start: number, end: number): number[] {
    const length = end - start + 1;
    return Array.apply(null, Array(length)).map((__, i) => i + start);
  }
}
