import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Entity } from 'src/app/models/entity';
import * as fromGlobalSearchStore from './reducer';
import { State, featureAdapter } from './state';

export const selectGlobalSearchStoreState = createFeatureSelector<State>(fromGlobalSearchStore.globalSearchStoreFeatureKey);

export const selectGlobalSearchFilter = createSelector(selectGlobalSearchStoreState, (state: State): string => state.newFilter);
export const selectGlobalSearchOldFilter = createSelector(selectGlobalSearchStoreState, (state: State): string => state.oldFilter);

export const selectGlobalSearchfilterEntityKind = createSelector(
  selectGlobalSearchStoreState,
  (state: State): string[] => state.filterEntityKind
);

export const selectPreviousGlobalSearchId = createSelector(selectGlobalSearchStoreState, (state: State): number => state.searchId);

export const selectIsFinished = createSelector(selectGlobalSearchStoreState, (state: State): boolean => state.isFinished);

export const selectAllGlobalSearchResults: (state: object) => Entity[] =
  featureAdapter.getSelectors(selectGlobalSearchStoreState).selectAll;

export const selectGlobalSearchError = createSelector(selectGlobalSearchStoreState, (state: State): any => state.error);
