import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Entities } from 'src/app/configs/entities';
import { FileGridNode } from 'src/app/models/file-grid-node';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { FileTabType } from 'src/app/shared/files/file-tab/file-tab-type';

export interface FilesMultiselectionModalDialogData {
  isMultiSelect: boolean;
}
@Component({
  selector: 'app-files-multiselection-modal',
  templateUrl: './files-multiselection-modal.component.html',
  styleUrls: ['./files-multiselection-modal.component.scss'],
})
export class FilesMultiselectionModalComponent extends FormatComponent implements OnInit {
  isMultiselect: boolean;
  filesSelected: FileGridNode[];

  constructor(
    public dialogRef: MatDialogRef<FilesMultiselectionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FilesMultiselectionModalDialogData
  ) {
    super();
    this.isMultiselect = this.data.isMultiSelect;
  }

  ngOnInit() {}

  fileSelected(row: FileGridNode[]) {
    this.filesSelected = row;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSelect() {
    this.dialogRef.close(this.filesSelected);
  }

  isValidSelection() {
    return this.filesSelected && this.filesSelected.length > 0;
  }

  get FileTabType() {
    return FileTabType;
  }

  get Entities() {
    return Entities;
  }
}
