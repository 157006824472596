export class TaricsEntitlements {
  orderId: number;
  error: string;
  userAdd: TaricsEntitlementChange[];
  userRemove: TaricsEntitlementChange[];
  userUpdate: TaricsEntitlementChange[];
  entitlementAdd: TaricsEntitlementChange[];
  entitlementRemove: TaricsEntitlementChange[];
  entitlementUpdate: TaricsEntitlementChange[];
  serviceChangeAdd: TaricsEntitlementChange[];
  serviceChangeRemove: TaricsEntitlementChange[];
  serviceChangeUpdate: TaricsEntitlementChange[];
}

export class TaricsEntitlementChange {
  entitlementchangeId: number;
  entitlementchangeMessage: string;
}

export class TaricsNotificationCount {
  id: number;
  name: string;
  value: string;
  date: Date;
}
