<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <h1 mat-dialog-title>
    {{ 'modal_dialog_please_select' | translate }}
    {{ data.labelName | translate }}
  </h1>

  <div class="button-wrapper">
    <button (click)="onSelect()" mat-raised-button [disabled]="!isOptionSelected()" color="primary">
      {{ 'label_select' | translate }}
    </button>
    <span style="padding-right: 5px"></span>
    <button (click)="onCancel()" mat-raised-button color="primary">
      {{ 'label_cancel' | translate }}
    </button>
  </div>
</div>
<div mat-dialog-content>
  <!-- DIALOG CONTENT -->
  <div id="options-table">
    <div class="container-fluid">
      <div class="table-wrapper table-options-wrapper">
        <div class="options-table-header">
          <mat-form-field appearance="outline" class="reset-outline-hint filter-field search">
            <input
              #searchInputField
              matInput
              [(ngModel)]="filter"
              (debounceFilterInput)="applyFilterTable($event)"
              [debounceTime]="600"
              maxlength="1500"
              placeholder="{{ 'options_modal_table_filter_placeholder' | translate }}"
            />
            <!-- placeholder="Filter by any field ..." -->
          </mat-form-field>
        </div>
        <div class="table-container">
          <table class="table table-stripes table-truncate-text" mat-table [dataSource]="dataSourceTable">
            <!-- radio button column -->
            <ng-container matColumnDef="radio">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row" class="radio-button-wrapper">
                <mat-radio-button
                  class="table-radio-button"
                  (change)="onOptionSelected(row)"
                  disableRipple="true"
                  [checked]="selectedOption.id === row.id"
                ></mat-radio-button>
              </td>
            </ng-container>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? onCheckboxClicked(row) : null"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <!-- name column -->
            <ng-container matColumnDef="optionName">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'label_option' | translate }}
              </th>
              <td mat-cell showIfTruncated [matTooltip]="row.value" showIfTruncated *matCellDef="let row">
                {{ row.value }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
            <tr
              mat-row
              (click)="onOptionSelected(row)"
              *matRowDef="let row; columns: displayedColumnsTable"
              [ngClass]="selectedOption.id === row.id ? 'selected-row' : ''"
            ></tr>
          </table>
          <mat-toolbar class="table-footer-toolbar">
            <mat-toolbar-row>
              <mat-paginator #paginatorTable [pageSizeOptions]="pageSizeList" class="reset-paginator"></mat-paginator>
            </mat-toolbar-row>
          </mat-toolbar>
        </div>
      </div>
    </div>
  </div>
  <!-- END DIALOG CONTENT -->
</div>
