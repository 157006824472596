import { ChangeDetectorRef, Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { Entity } from 'src/app/models/entity';
import { RootStoreState } from 'src/app/root-store';
import { LogService } from 'src/app/services/log-service';
import { GridBaseComponent } from 'src/app/shared/base-components/grid-base-component';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TreeGridBaseComponent extends GridBaseComponent {
  treeViewBasePath: string;
  isRestoreExpandedNodes: boolean;
  expandedNodes: Entity[];

  tgbGetApiTreeEntitiesPath?(): string;

  constructor(protected store: Store<RootStoreState.State>, protected cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    this.treeViewBasePath = this.tgbGetApiTreeEntitiesPath();
  }

  onNodeClicked({ entityId, entityName }) {
    const m = this.onNodeClicked.name;

    LogService.info(this, m, 'On Node Clicked', null);
    const entity = new Entity();
    entity.entityId = entityId;
    entity.entityName = entityName;
    this.onRowClicked(entity);
  }
}
