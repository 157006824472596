import { Action, createReducer, on } from '@ngrx/store';
import * as TerminateMultiAssetWizardActions from './actions';
import { State, initialState } from './state';

export const terminateMultiAssetWizardFeatureKey = 'terminateMultiAssetWizard';

const terminateMultiAssetWizardReducer = createReducer(
  initialState,

  on(TerminateMultiAssetWizardActions.selectAssetStep, (state, { assetStep }) => ({
    ...state,
    assetStep,
  })),
  on(TerminateMultiAssetWizardActions.selectCancelDetailsStep, (state, { cancelDetailsStep }) => ({
    ...state,
    cancelDetailsStep,
  })),
  on(TerminateMultiAssetWizardActions.deleteCancelDetailsStep, (state) => ({
    ...state,
    cancelDetailsStep: null,
  })),
  on(TerminateMultiAssetWizardActions.exitTerminateMultiAssetWizard, (state) => ({
    ...state,
    assetStep: null,
    cancelDetailsStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(TerminateMultiAssetWizardActions.destroyWizard, (state) => ({
    ...state,
    assetStep: null,
    cancelDetailsStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(TerminateMultiAssetWizardActions.createNewTerminateMultiAsset, (state, { request }) => ({
    ...state,
    request,
  })),
  on(TerminateMultiAssetWizardActions.createNewTerminateMultiAssetSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(TerminateMultiAssetWizardActions.createNewTerminateMultiAssetFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return terminateMultiAssetWizardReducer(state, action);
}
