import { ActionResponse } from 'src/app/models/action-response';
import { CancelAssetOptions } from 'src/app/models/cancel-asset-options';
import { CancelLicenseServicesRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/confirmation-step/cancel-license-services-request';
import { MoveAssetServicesDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-services-wizard/activation-step/move-asset-services-due-date-info';
import { MoveAssetServicesInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-services-wizard/init-step/init-step';

export interface State {
  initStep: MoveAssetServicesInitStep;
  dueDatesStep: MoveAssetServicesDueDates;
  billingaccountStep: CancelAssetOptions[];
  cancelRequest: CancelLicenseServicesRequest;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  dueDatesStep: null,
  billingaccountStep: null,
  cancelRequest: null,
  response: null,
  errorMessage: null,
};
