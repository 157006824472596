export class Flags {
  public static CREATE_FLAG = 'create';
  public static ORDER_FLAG = 'order';
  public static DOCUMENT_FLAG = 'documents';
  public static NOTES_FLAG = 'notes';
  public static AUTOEXPAND_FLAG = 'autoexpand';
  public static EXCEL_FLAG = 'excel';
  public static PDF_FLAG = 'pdf';
  public static MULTIPLE_COSTCENTRES_FLAG = 'multiplecostcenters';
  public static CATALOG_OLD_STRUCTURE_FLAG = 'catalogoldstructure';
  public static OUTSTANDINGS_FLAG = 'outstandings';
  public static SINGLE_ATTACHMENTS_FLAG = 'sendsingleattachment';
  public static MULTIPLE_ATTACHMENTS_FLAG = 'sendmultipleattachments';
  public static THREEDI_PROFILER_FLAG = '3diprofiler';
  public static COLLABORATION_MODULE_FLAG = 'collaboration';
  public static INVOICE_ITEM_EDIT_FLAG = 'itemedit';
}
