export interface State {
  isErrorOccurred: boolean;
  isServerUnreachableError: boolean;
  isSvgLoadErrorOccurred: boolean;
}

export const initialState: State = {
  isErrorOccurred: false,
  isServerUnreachableError: false,
  isSvgLoadErrorOccurred: false,
};
