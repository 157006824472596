import { Action, createReducer, on } from '@ngrx/store';
import * as CopyBillingitemsWizardActions from './actions';
import { State, initialState } from './state';

export const copyBillingitemsWizardFeatureKey = 'copyBillingitemsWizard';

const copyBillingitemsWizardReducer = createReducer(
  initialState,

  on(CopyBillingitemsWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(CopyBillingitemsWizardActions.selectBillingitemStep, (state, { billingitemStep }) => ({
    ...state,
    initStep: {
      ...state.initStep,
      billingitem: billingitemStep,
      billingitemId: billingitemStep.billingitemId,
    },
  })),
  on(CopyBillingitemsWizardActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(CopyBillingitemsWizardActions.selectBillingaccountStep, (state, { billingaccountStep }) => ({
    ...state,
    billingaccountStep,
  })),
  on(CopyBillingitemsWizardActions.deleteBillingaccountStep, (state) => ({
    ...state,
    billingaccountStep: null,
  })),
  on(CopyBillingitemsWizardActions.exitCopyBillingitemsWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    billingaccountStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(CopyBillingitemsWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    billingaccountStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(CopyBillingitemsWizardActions.createNewCopyBillingitems, (state, { request }) => ({
    ...state,
    request,
  })),
  on(CopyBillingitemsWizardActions.createNewCopyBillingitemsSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(CopyBillingitemsWizardActions.createNewCopyBillingitemsFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return copyBillingitemsWizardReducer(state, action);
}
