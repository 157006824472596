import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { AddConsumersToAssetConsumerStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-consumers-to-asset-wizard/add-consumers-to-asset-consumers-step/add-consumers-to-asset-consumers.step';
import { AddConsumersToAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-consumers-to-asset-wizard/init-step/init-step';
import * as fromAddConsumersToAssetWizard from './reducer';
import { State } from './state';

export const selectAddConsumersToAssetWizardState = createFeatureSelector<State>(
  fromAddConsumersToAssetWizard.addConsumersToAssetWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectAddConsumersToAssetWizardState,
  (state: State): AddConsumersToAssetInitStep => state.initStep
);

export const selectConsumersStep = createSelector(
  selectAddConsumersToAssetWizardState,
  (state: State): AddConsumersToAssetConsumerStep => state.consumersStep
);

export const selectAddConsumersToAssetState = createSelector(
  selectAddConsumersToAssetWizardState,
  (state: State): EntityWizardResponse => state.response
);

export const selectErrorMessage = createSelector(selectAddConsumersToAssetWizardState, (state: State): string => state.errorMessage);
