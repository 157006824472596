import { Action, createReducer, on } from '@ngrx/store';
import * as ErrorHandlingActions from './actions';
import { State, initialState } from './state';

export const errorHandlingFeatureKey = 'foErrorHandling';

const errorHandlingReducer = createReducer(
  initialState,
  on(ErrorHandlingActions.setError, (state) => ({
    ...state,
    isErrorOccurred: true,
  })),
  on(ErrorHandlingActions.resetError, (state) => {
    return {
      ...state,
      isErrorOccurred: false,
    };
  }),
  on(ErrorHandlingActions.setServerUnreachableError, (state) => ({
    ...state,
    isServerUnreachableError: true,
  })),
  on(ErrorHandlingActions.resetServerUnreachableError, (state) => {
    return {
      ...state,
      isServerUnreachableError: false,
    };
  }),

  on(ErrorHandlingActions.setSvgLoadError, (state) => ({
    ...state,
    isSvgLoadErrorOccurred: true,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return errorHandlingReducer(state, action);
}
