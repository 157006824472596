import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FoUserLicenseServicesEffects } from './effects';
import * as fromUserLicenseServices from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromUserLicenseServices.fromUserLicenseServicesFeatureKey, fromUserLicenseServices.reducer),
    EffectsModule.forFeature([FoUserLicenseServicesEffects]),
  ],
})
export class FoUserLicenseServicesStoreModule {}
