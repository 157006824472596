<ngx-file-drop
  dropZoneClassName="dropzone-area"
  contentClassName="dropzone-content"
  (onFileDrop)="dropped($event)"
  (onFileOver)="fileOver($event)"
  (onFileLeave)="fileLeave($event)"
>
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <textarea
      #textarea
      matInput
      cdkTextareaAutosize
      cdkAutosizeMinRows="5"
      cdkAutosizeMaxRows="5"
      (change)="changeHandler(textarea.value)"
      (click)="onTouched()"
      [value]="fileContent"
    >
    </textarea>
    <!-- required -->
  </ng-template>
</ngx-file-drop>
