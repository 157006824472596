<div class="entity-sidenav-container multiselection-container">
  <mat-tab-group class="entity-tab-group">
    <mat-tab>
      <ng-template mat-tab-label>
        {{ 'costcentre_tab_grid' | translate }}
      </ng-template>
      <ng-template matTabContent>
        <div id="costcentre-table" class="entity-table">
          <div class="table-wrapper">
            <generic-dynamic-grid-search-elements
              *ngIf="searchElements"
              [gridName]="GRID_NAME"
              [searchElements]="searchElements"
              [lastUsedFilters]="lastUsedFilters"
              [filtersMap]="filtersMap"
              (setFilterEvEm)="applyFilterTable($event)"
              (setFilterRuleEvEm)="setFilterRule($event)"
              (setClipboardFilterEvEm)="setClipboardFilter($event)"
              (setSearchElementsEvEm)="setFilterElements($event)"
              (clearFilterEvEm)="clearSearchFilter()"
              (clearAllFiltersEvEm)="clearFilters()"
            ></generic-dynamic-grid-search-elements>
            <div class="table-filters" [ngClass]="enableFilter ? 'visible' : 'hidden'">
              <generic-dynamic-grid-filters
                *ngIf="dynamicFilters && dynamicFilters.length > 0"
                [dynamicFilters]="dynamicFilters"
                [disableDefaultFilter]="disableDefaultFilter"
                [clearFilterSubject]="clearFilterSubject.asObservable()"
                [filtersMapSubject]="filtersMapSubject"
                [gridName]="GRID_NAME"
                (filterIdsEvEm)="setDynamicFiltersIds($event)"
              ></generic-dynamic-grid-filters>
            </div>
            <div class="table-selection-container">
              <div class="table-buttons-container">
                <div class="selection-buttons">
                  <button mat-raised-button color="primary" (click)="askAddAllFiltered()" [disabled]="isLoadingSelection">
                    <span>{{ 'label_select_all' | translate }}</span>
                  </button>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="addAllToSelection()"
                    [disabled]="isLoadingSelection"
                    style="margin-left: 0.5rem"
                  >
                    <span>{{ 'label_select_displayed' | translate }}</span>
                  </button>
                  <mat-progress-bar mode="indeterminate" *ngIf="isLoadingSelection"></mat-progress-bar>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="extraQuerySelectionEvEm.emit()"
                    style="margin-left: auto"
                    *ngIf="enableExtraQuerySelection"
                  >
                    <span>{{ extraQuerySelectionButtonKey | translate }}</span>
                  </button>
                </div>
                <div class="table-container">
                  <table
                    class="table table-stripes table-truncate-text"
                    mat-table
                    [dataSource]="dataSourceTable"
                    matSort
                    #sortTable="matSort"
                    [matSortActive]="gbGetInitialOrderBy()"
                    [matSortDirection]="gbGetInitialSort()"
                    matSortDisableClear
                    cdkDropList
                    cdkDropListSortingDisabled
                    cdkDropListConnectedTo="generic-multigrid-multiselection-table"
                    [cdkDropListData]="dataSourceTable"
                  >
                    <ng-container matColumnDef="selection">
                      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                          (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                        >
                        </mat-checkbox>
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <mat-checkbox
                          (click)="$event.stopPropagation()"
                          (change)="$event ? onCheckboxClicked(row) : null"
                          [checked]="selection.isSelected(row)"
                          [disabled]="isReadonly(row)"
                        >
                        </mat-checkbox>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="costcentre_name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'costcentre_name' | translate }}
                      </th>
                      <td mat-cell showIfTruncated [matTooltip]="row.costcentreName" *matCellDef="let row">
                        {{ row.costcentreName }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="costcentre_abb">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'costcentre_abb' | translate }}
                      </th>
                      <td mat-cell showIfTruncated [matTooltip]="row.costcentreAbb" *matCellDef="let row">
                        {{ row.costcentreAbb }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="costcentre_no">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'costcentre_no' | translate }}
                      </th>
                      <td mat-cell showIfTruncated [matTooltip]="row.costcentreNo" *matCellDef="let row">
                        {{ row.costcentreNo }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="costcentre_level">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'costcentre_level' | translate }}
                      </th>
                      <td mat-cell showIfTruncated [matTooltip]="row.costcentreLevel" *matCellDef="let row">
                        {{ row.costcentreLevel }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="costcentre_type">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'costcentre_type' | translate }}
                      </th>
                      <td mat-cell showIfTruncated [matTooltip]="row.costcentreType" *matCellDef="let row">
                        {{ row.costcentreType }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="costcentre_starts">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'costcentre_starts' | translate }}
                      </th>
                      <td mat-cell showIfTruncated [matTooltip]="getDate(row.costcentreStarts)" *matCellDef="let row">
                        {{ getDate(row.costcentreStarts) }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="costcentre_parent">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'costcentre_parent' | translate }}
                      </th>
                      <td mat-cell showIfTruncated [matTooltip]="row.costcentreParent" *matCellDef="let row">
                        {{ row.costcentreParent }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="costcentre_remarks">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'costcentre_remarks' | translate }}
                      </th>
                      <td mat-cell showIfTruncated [matTooltip]="row.costcentreRemarks" *matCellDef="let row">
                        {{ row.costcentreRemarks }}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedColumnsTable"
                      (click)="onRowClicked(row)"
                      [ngClass]="selectedEntity.entityId === row.entityId ? 'selected-row' : ''"
                      cdkDrag
                      [cdkDragData]="row"
                    >
                      <!-- DRAG&DROP PREVIEW -->
                      <mat-card class="drag-preview" *cdkDragPreview>
                        <mat-card-content class="card-content">
                          <span class="card-item-name"> {{ row.entityName }}</span>
                        </mat-card-content>
                      </mat-card>
                      <!-- DRAG&DROP PREVIEW -->
                    </tr>
                  </table>
                  <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
                    <mat-toolbar-row *ngIf="isLoading" class="spinner">
                      <mat-spinner diameter="50"></mat-spinner>
                    </mat-toolbar-row>
                    <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
                      <span>{{ 'costcentre_table_error_nodata' | translate }}</span>
                    </mat-toolbar-row>
                  </mat-toolbar>
                  <mat-toolbar class="table-footer-toolbar">
                    <mat-toolbar-row>
                      <mat-paginator
                        [disabled]="isLoading"
                        #paginatorTable
                        [pageSizeOptions]="pageSizeList"
                        [length]="pageTotalElements"
                        class="reset-paginator"
                      ></mat-paginator>
                    </mat-toolbar-row>
                  </mat-toolbar>
                </div>
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <!-- SELECTION GRID -->
              <div class="filters-selections table-wrapper">
                <div class="filters-selections-title">
                  <span> {{ 'report_saved_filters_selection' | translate }} ({{ selectionDataSourceTable.filteredData.length }})</span>
                  <button mat-raised-button color="primary" (click)="askRemoveAllFiltered()" [disabled]="isLoadingSelection">
                    <mat-icon>delete_outline</mat-icon>
                    <span>{{ 'label_remove_all' | translate }}</span>
                  </button>
                </div>
                <div
                  id="generic-multigrid-multiselection-table"
                  cdkDropList
                  #genericMultigridMultiselectionTable="cdkDropList"
                  [cdkDropListData]="selectionDataSourceTable"
                  (cdkDropListDropped)="dropEntity($event)"
                >
                  <div class="table-container">
                    <table class="table table-stripes table-truncate-text" mat-table [dataSource]="selectionDataSourceTable">
                      <ng-container matColumnDef="entity_name">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ 'entity_name' | translate }}
                        </th>
                        <td mat-cell showIfTruncated [matTooltip]="row.entityName" *matCellDef="let row">
                          {{ row.entityName }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="entity_remove">
                        <th mat-header-cell *matHeaderCellDef class="icondelete-header"></th>
                        <td mat-cell class="icondelete-cell" *matCellDef="let row">
                          <mat-icon
                            (click)="removeEntityFromSelection(row)"
                            style="color: red"
                            fontSet="fas"
                            fontIcon="fa-times"
                          ></mat-icon>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="selectionDisplayedColumnsTable; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: selectionDisplayedColumnsTable"></tr>
                    </table>
                    <mat-toolbar
                      class="table-footer-toolbar"
                      *ngIf="
                        selectionDataSourceTable &&
                        selectionDataSourceTable.filteredData &&
                        selectionDataSourceTable.filteredData.length === 0
                      "
                    >
                      <mat-toolbar-row
                        *ngIf="
                          selectionDataSourceTable &&
                          selectionDataSourceTable.filteredData &&
                          selectionDataSourceTable.filteredData.length === 0
                        "
                        class="no-data-message"
                      >
                        <span>{{ 'report_filters_table_error_nodata' | translate }}</span>
                      </mat-toolbar-row>
                    </mat-toolbar>
                  </div>
                </div>
              </div>
            </div>
            <!-- SELECTION GRID -->
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        {{ 'costcentre_tab_tree' | translate }}
      </ng-template>

      <ng-template matTabContent>
        <div class="tree-selection-container">
          <div class="tree-buttons-container">
            <app-generic-tree-view
              (addToSelectionEvEm)="addToSelection($event)"
              (removeFromSelectionEvEm)="removeFromSelection($event)"
              [selectedDataSubj]="selectedDataSubj"
              [baseApiPath]="treeViewBasePath"
              [isNodeSelection]="true"
              [enableDrag]="true"
              [enableCheckboxSelection]="true"
              dragTarget="generic-multigrid-multiselection-table"
            ></app-generic-tree-view>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <!-- SELECTION GRID -->
          <div class="filters-selections table-wrapper">
            <div class="filters-selections-title">
              <span> {{ 'report_saved_filters_selection' | translate }} ({{ selectionDataSourceTable.filteredData.length }})</span>
              <button mat-raised-button color="primary" (click)="clearSelectionEvEm.emit()">
                <mat-icon>delete_outline</mat-icon>
                <span>{{ 'label_remove_all' | translate }}</span>
              </button>
            </div>
            <div
              id="generic-multigrid-multiselection-table"
              cdkDropList
              #genericMultigridMultiselectionTable="cdkDropList"
              [cdkDropListData]="selectionDataSourceTable"
              (cdkDropListDropped)="dropEntity($event)"
            >
              <div class="table-container">
                <table class="table table-stripes table-truncate-text" mat-table [dataSource]="selectionDataSourceTable">
                  <ng-container matColumnDef="entity_name">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'entity_name' | translate }}
                    </th>
                    <td mat-cell showIfTruncated [matTooltip]="row.entityName" *matCellDef="let row">
                      {{ row.entityName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="entity_remove">
                    <th mat-header-cell *matHeaderCellDef class="icondelete-header"></th>
                    <td mat-cell class="icondelete-cell" *matCellDef="let row">
                      <mat-icon
                        *ngIf="!row.isReadonly"
                        (click)="removeEntityFromSelection(row)"
                        style="color: red"
                        fontSet="fas"
                        fontIcon="fa-times"
                      ></mat-icon>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="selectionDisplayedColumnsTable; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: selectionDisplayedColumnsTable"></tr>
                </table>
                <mat-toolbar
                  class="table-footer-toolbar"
                  *ngIf="
                    selectionDataSourceTable && selectionDataSourceTable.filteredData && selectionDataSourceTable.filteredData.length === 0
                  "
                >
                  <mat-toolbar-row
                    *ngIf="
                      selectionDataSourceTable &&
                      selectionDataSourceTable.filteredData &&
                      selectionDataSourceTable.filteredData.length === 0
                    "
                    class="no-data-message"
                  >
                    <span>{{ 'report_filters_table_error_nodata' | translate }}</span>
                  </mat-toolbar-row>
                </mat-toolbar>
              </div>
            </div>
          </div>
        </div>
        <!-- SELECTION GRID -->
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
