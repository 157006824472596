<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <h1 mat-dialog-title *ngIf="data.movedFolder">
    {{ 'modal_dialog_please_select_destination_folder_for' | translate }}
    {{ data.movedFolder | translate }}
  </h1>
  <h1 mat-dialog-title *ngIf="!data.movedFolder">
    {{ 'modal_dialog_please_select_destination_folder_for_files' | translate }}
  </h1>

  <div class="button-wrapper">
    <button (click)="onSelect()" mat-raised-button [disabled]="!isEntitySelected()" color="primary">
      {{ 'label_select' | translate }}
    </button>
    <span style="padding-right: 5px"></span>
    <button [mat-dialog-close]="null" mat-raised-button color="primary">
      {{ 'label_cancel' | translate }}
    </button>
  </div>
</div>
<div mat-dialog-content>
  <!-- DIALOG CONTENT -->
  <div id="file-tree-view-table" class="entity-table">
    <div class="table-wrapper">
      <div class="table-container" [ngClass]="dataSource && dataSource.data.length > 0 ? '' : 'no-border'">
        <table
          class="table table-stripes table-truncate-text no-header-table"
          mat-table
          matSort
          [dataSource]="dataSource"
          [ngStyle]="{ visibility: !isLoading ? '' : 'hidden' }"
        >
          <ng-container matColumnDef="entityName">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <div class="toggle-checkbox-name">
                <div class="icon-toggle-cell">
                  <button
                    mat-icon-button
                    [style.visibility]="
                      !row.expandable || (data.disableChildNodes && row.item.entityId === data.movedFolderId) ? 'hidden' : ''
                    "
                    [style.marginLeft.px]="row.level * 16"
                    (click)="treeControl.toggle(row); $event.stopPropagation()"
                  >
                    <mat-icon class="mat-icon-rtl-mirror expand-icon">
                      {{ treeControl.isExpanded(row) ? 'remove' : 'add' }}
                    </mat-icon>
                  </button>
                </div>
                <mat-radio-button
                  class="table-radio-button"
                  (change)="onEntitySelected(row)"
                  disableRipple="true"
                  [disabled]="disabledRow(row)"
                  [checked]="selectedNode && selectedNode?.item.entityId === row.item.entityId"
                ></mat-radio-button>
                <span class="toggle-name" showIfTruncated [matTooltip]="row.item.entityName" [ngClass]="row.isLoading ? 'loading' : ''">{{
                  row.item.entityName
                }}</span>
                <mat-progress-bar *ngIf="row.isLoading" mode="indeterminate" class="example-tree-progress-bar"> </mat-progress-bar>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsTable"
            [ngClass]="[selectedNode?.item.entityId === row.item.entityId ? 'selected-row' : '']"
          ></tr>
        </table>
        <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
          <mat-toolbar-row *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="50"></mat-spinner>
          </mat-toolbar-row>
          <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
            <span>{{ 'generic_table_error_nodata' | translate }}</span>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
    </div>
  </div>
  <!-- END DIALOG CONTENT -->
</div>
