<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span>{{ 'changepwd_title' | translate }}</span>
</div>
<div mat-dialog-content>
  <form [formGroup]="changePwdForm" (ngSubmit)="changePwd()">
    <div class="dialog-form-container change-password">
      <div class="particulars-form">
        <ng-container *ngIf="!(isSubmitted && isChanged); else pwdChanged">
          <div class="particulars-row">
            <div class="particulars-col fullwidth">
              <div class="label-container">
                <span class="label truncate-text">{{ 'changepwd_placeholder_newpwd' | translate }}</span>
              </div>
              <span class="control-value">
                <mat-form-field appearance="outline" class="password-input form-field-input reset-outline-hint">
                  <input type="password" formControlName="newPassword" matInput class="form-control" autocomplete="new-password" />
                </mat-form-field>
              </span>
            </div>
          </div>
          <div class="particulars-row no-label" *ngIf="formControls.newPassword.errors && getErrorMessageNewPassword()">
            <div class="particulars-col fullwidth">
              <div class="control-value">
                <mat-error>
                  <div *ngFor="let errorMessage of getErrorMessageNewPassword()">
                    {{ errorMessage | translate }}
                  </div>
                </mat-error>
              </div>
            </div>
          </div>
          <div class="particulars-row">
            <div class="particulars-col fullwidth">
              <div class="label-container">
                <span class="label truncate-text">{{ 'changepwd_placeholder_confirmpwd' | translate }}</span>
              </div>
              <span class="control-value">
                <mat-form-field appearance="outline" class="password-input form-field-input reset-outline-hint">
                  <input type="password" formControlName="confirmNewPassword" matInput autocomplete="new-password" />
                </mat-form-field>
              </span>
            </div>
          </div>
          <div class="particulars-row no-label" *ngIf="formControls.confirmNewPassword.errors && getErrorMessageConfirmNewPassword()">
            <div class="particulars-col fullwidth">
              <div class="control-value">
                <mat-error>
                  <div *ngFor="let errorMessage of getErrorMessageConfirmNewPassword()">
                    {{ errorMessage | translate }}
                  </div>
                </mat-error>
              </div>
            </div>
          </div>
          <div class="particulars-row no-label app-alert" *ngIf="isSubmitted && error">
            <app-alert status="error" [message]="error.errorKey | translate" [tooltip]="error.errorMessage"></app-alert>
          </div>
        </ng-container>
      </div>
      <div class="particulars-form no-background">
        <div class="particulars-row">
          <div class="particulars-col fullwidth float-right">
            <div class="label-container">
              <span class="label truncate-text">&nbsp;</span>
            </div>
            <span class="control-value button float-right">
              <button mat-stroked-button color="primary" [mat-dialog-close]="null" style="margin-right: 0.5rem">
                <span>{{ 'label_close' | translate }}</span>
              </button>
              <button
                mat-flat-button
                color="primary"
                (click)="changePwd()"
                [disabled]="!(changePwdForm && changePwdForm.valid && changePwdForm.dirty) || (isSubmitted && isChanged)"
              >
                <span>{{ 'label_change_password' | translate }}</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
  <ng-template #pwdChanged>
    <div class="particulars-row no-label app-alert">
      <app-alert status="success" [message]="'changepwd_message_success' | translate"></app-alert>
    </div>
  </ng-template>
</div>
