import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { FeeditemReplaceRequest } from 'src/app/models/feeditem';
import { ServiceAlias } from 'src/app/models/service';
import { ReplaceFeeditemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-feeditems-wizard/activation-step/replacefeeditems-due-date-info';
import { ReplaceFeeditemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-feeditems-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Replace Feeditems Information Step',
  props<{ initStep: ReplaceFeeditemsInitStep }>()
);

export const selectDueDatesStep = createAction(
  '[Wizard Due Date Step] Select Replace Feeditems Due Date Step',
  props<{ dueDatesStep: ReplaceFeeditemsDueDates }>()
);

export const selectServiceStep = createAction(
  '[Wizard Service Step] Select Replace Feeditems Service Step',
  props<{ serviceStep: ServiceAlias[] }>()
);

export const deleteServiceStep = createAction('[Wizard Service Step] Delete Replace Feeditems Service Step');

export const exitReplaceFeeditemsWizard = createAction('[ReplaceFeeditems Wizard Page] Exit Replace Feeditems Wizard');

export const destroyWizard = createAction('[ReplaceFeeditems Wizard Page] Destroy Wizard');

export const createNewReplaceFeeditems = createAction(
  '[Wizard Confirmation Step] Create Replace Feeditems Information Step',
  props<{ request: FeeditemReplaceRequest }>()
);

export const createNewReplaceFeeditemsSuccess = createAction(
  '[Wizard Confirmation Step] Create Replace Feeditems Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewReplaceFeeditemsFail = createAction(
  '[Wizard Confirmation Step] Create Replace Feeditems Information Step Fail',
  props<{ errorMessage: string }>()
);
