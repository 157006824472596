import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserLicenseServices } from 'src/app/models/product-services';

export const featureAdapter: EntityAdapter<UserLicenseServices> = createEntityAdapter<UserLicenseServices>({
  selectId: (model) => model.licenseId,
});

export interface State extends EntityState<UserLicenseServices> {
  isLoading?: boolean;
  error?: any;
  noData?: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  noData: false,
});
