import { HttpParams } from '@angular/common/http';
import { Entity } from './entity';
import { PaginatedRequest } from './paginated-request';

export class Type extends Entity {
  typeId: number;
  typeKind: string;
  typeName: string;
}

export class TypeParams {
  public static FILTER_KIND_IDS = 'filterKindIds';
}

export function getTypeRequestParams(request: PaginatedRequest): HttpParams {
  return PaginatedRequest.getBaseRequestParams(request);
}
