import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FormatComponent } from '../../base-components/format-component';

@Component({
  selector: 'app-cron-editor-input, cron-editor-input',
  templateUrl: './cron-editor-input.component.html',
  styleUrls: ['./cron-editor-input.component.scss'],
})
export class CronEditorInputComponent extends FormatComponent implements OnInit {
  @Input() cronExpressionSubj: Observable<string>;
  @Input() isEditing: boolean;
  @Output() cronExpressionEvEm = new EventEmitter<string>();
  cronExpression: string;
  selectedTabIndex: number;
  splittedCronExpression: string[];

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.cronExpressionSubj, (cron) => {
      this.cronExpression = null;
      this.cdRef.detectChanges();
      this.cronExpression = cron;
      this.splittedCronExpression = this.cronExpression.split(' ');
      if (this.splittedCronExpression.length !== 7) {
        // ERROR - NOT A QUARTZ CRON
        throw Error('Given Cron is not a valid Quartz Cron expression');
      }
    });
  }

  setCronPart(value: string, index: number) {
    this.splittedCronExpression[index] = value;
    this.cronExpression = this.splittedCronExpression.join(' ');
    this.cronExpressionEvEm.emit(this.cronExpression);
  }
}
