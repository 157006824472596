import { KeyValue } from '@angular/common';
import { ActionResponse } from './action-response';

export class SectionNode {
  entityKind?: string;
  entityTextname: string;
  entityType: string;
  entityText: any;
  entityValue: number;
  entityUnit: string;
  entityTooltip: string;
  entityAppendnext: boolean;
  entityOptions?: KeyValue<string, string>[];
  entityRequired: number;
  entityReadOnly: boolean;
  entitySpecialedit: boolean;
  entityRemovable: boolean;
  entityRegex?: string;
  entityRegexerrormsg?: string;
  entityScale?: number;
  entityPrecision?: number;
  // Frontend types
  entitySibling?: SectionNode; // when entityAppendnext = true is valorized with next Node;
  entityIsSibling?: boolean; // is true when a node is the next one of entityAppendnext = true;
  entityFilterColumn?: string;
  entityFilterColumnValue?: number;
}

export class GenericEntityField {
  sectionTitle: string;
  sectionLeft: boolean;
  sectionExpanded: boolean;
  sectionChildren: SectionNode[];
}

export class GenericEntityEvent {
  sectionNode: SectionNode;
  sectionList: GenericEntityField[];
}

export enum GenericEntityRequiredType {
  notRequired = 0,
  requiredSoonAsPossible = 1,
  required = 2,
}

export class EntityFieldsListContentCallBack {
  data: any[];
  actionResponse: ActionResponse;
  isEditing: boolean;
  isCopyEntity: boolean;
  isSuccess: boolean;
  constructor(data: any[], actionResponse: ActionResponse, isEditing: boolean, isCopyEntity: boolean, isSuccess: boolean) {
    this.data = data;
    this.actionResponse = actionResponse;
    this.isEditing = isEditing ? isEditing : false;
    this.isCopyEntity = isCopyEntity ? isCopyEntity : false;
    this.isSuccess = isSuccess;
  }
}
