import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { RequestDataService } from 'src/app/services/request-data.service';
import * as OrderMarketDataWizardActions from './actions';

@Injectable()
export class OrderMarketDataWizardEffects {
  newOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderMarketDataWizardActions.createNewOrderMarketData),
      concatMap(({ request }) =>
        this.requestWizardService.createMarketDataOrder(request).pipe(
          map((response) => {
            if (response) {
              return OrderMarketDataWizardActions.createNewOrderSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            const errorMessage = error;
            return of(OrderMarketDataWizardActions.createNewOrderFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private router: Router, private requestWizardService: RequestDataService) {}
}
