import { KeyValue } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormatComponent } from '../../base-components/format-component';

export interface TranslationsDialogData {
  keyvaluesTranslations: KeyValue<string, string>[];
  originalTranslations: KeyValue<string, string>[];
  isViewMode: boolean;
  isDirtyEnabled: boolean;
}

@Component({
  selector: 'app-translations-modal-dialog',
  templateUrl: './translations-modal-dialog.component.html',
  styleUrls: ['./translations-modal-dialog.component.scss'],
})
export class TranslationsModalDialogComponent extends FormatComponent implements OnInit {
  currentLang: string;
  keyvaluesTranslations: KeyValue<string, string>[];
  originalTranslations: KeyValue<string, string>[];
  originalTableTranslations: KeyValue<string, string>[];
  displayedColumnsTable = ['language', 'translation'];
  controls: UntypedFormArray;

  constructor(
    public dialogRef: MatDialogRef<TranslationsModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TranslationsDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.keyvaluesTranslations = this.data.keyvaluesTranslations;
    this.originalTranslations = this.data.originalTranslations;
    this.originalTableTranslations = JSON.parse(JSON.stringify(this.keyvaluesTranslations));
    this.initForm();
  }

  onApply() {}

  onCancel() {}

  isValorized(value: string): boolean {
    return value !== null && value !== undefined && value !== '';
  }

  updateField($event, index: number, field: string, key: string) {
    const control = this.getControl(index, field);
    if ($event && this.keyvaluesTranslations[index].value !== $event) {
      control.markAsDirty();
    }
    this.keyvaluesTranslations[index].value = $event ? $event : control.value; // When enter is pressed, value is passed by event emitter
    if ($event) {
      control.setValue($event);
    }
  }

  resetField(index: number, field: string, key: string) {
    const originalValue = this.keyvaluesTranslations[index].value;
    this.getControl(index, field).setValue(originalValue);
  }

  getControl(index: number, fieldName: string) {
    return this.controls.at(index).get(fieldName) as UntypedFormControl;
  }

  isDirtyTranslations() {
    return JSON.stringify(this.keyvaluesTranslations) !== JSON.stringify(this.originalTranslations);
  }

  isDirtyTableTranslations() {
    return JSON.stringify(this.keyvaluesTranslations) !== JSON.stringify(this.originalTableTranslations);
  }

  private initForm() {
    const toGroups = this.keyvaluesTranslations.map((keyvalue) => {
      return new UntypedFormGroup(
        {
          language: new UntypedFormControl(keyvalue.key, Validators.required),
          translation: new UntypedFormControl(keyvalue.value, Validators.required),
        },
        { updateOn: 'blur' }
      );
    });
    this.controls = new UntypedFormArray(toGroups);
    if (this.isDirtyTranslations()) {
      this.keyvaluesTranslations.forEach((kv, index) => {
        if (this.originalTranslations[index].value !== kv.value) {
          this.getControl(index, 'translation').markAsDirty();
        }
      });
    }
  }
}
