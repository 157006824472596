import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { License } from 'src/app/models/license';
import { SwapContractInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/swap-contract-wizard/init-step/swap-contract-init-step';
import * as fromSwapContractWizardStore from './reducer';
import { State } from './state';

export const selectSwapContractWizardStoreState = createFeatureSelector<State>(
  fromSwapContractWizardStore.swapContractWizardStoreFeatureKey
);

export const selectInitStep = createSelector(selectSwapContractWizardStoreState, (state: State): SwapContractInitStep => state.initStep);

export const selectAassetTobeCancelledStepStep = createSelector(
  selectSwapContractWizardStoreState,
  (state: State): License => state.assetTobeCancelledStep
);

export const selectValidationSwapAssetResponse = createSelector(
  selectSwapContractWizardStoreState,
  (state: State): ActionResponse => state.validationResponse
);

export const selectValidationErrorMessage = createSelector(
  selectSwapContractWizardStoreState,
  (state: State): string => state.validationErrorMessage
);

export const selectSwapAssetResponse = createSelector(
  selectSwapContractWizardStoreState,
  (state: State): ActionResponse => state.newOrderResponse
);

export const selectErrorMessage = createSelector(selectSwapContractWizardStoreState, (state: State): string => state.errorMessage);
