import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AllocateConsumerDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-wizard/dates-step/allocate-consumer-date-info';
import { AllocateConsumerInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-wizard/init-step/allocate-consumers-init-step';
import { AllocateConsumerMiscellaneousRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-wizard/miscellaneous-step/allocate-consumer-miscellaneous-request';
import { ConsumerDesk } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-wizard/users-step/consumer-desk';
import * as fromAllocateConsumerWizard from './reducer';
import { State } from './state';

export const selectAllocateConsumerWizardState = createFeatureSelector<State>(fromAllocateConsumerWizard.allocateConsumerWizardFeatureKey);

export const selectInitStep = createSelector(selectAllocateConsumerWizardState, (state: State): AllocateConsumerInitStep => state.initStep);

export const selectDatesStep = createSelector(
  selectAllocateConsumerWizardState,
  (state: State): AllocateConsumerDueDates => state.datesStep
);

export const selectUsersStep = createSelector(selectAllocateConsumerWizardState, (state: State): ConsumerDesk[] => state.usersStep);

export const selectMiscellaneousStep = createSelector(
  selectAllocateConsumerWizardState,
  (state: State): AllocateConsumerMiscellaneousRequest => state.miscellaneousStep
);
