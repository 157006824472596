/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericFormFieldItem } from 'src/app/models/forms/form-field';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { BaseFormFieldInterface } from './base-form-field-interface';

@Directive()
export class BaseFormFieldComponent extends FormatComponent implements BaseFormFieldInterface {
  @Input() public formFieldData: GenericFormFieldItem;
  @Input() public isViewMode: boolean;
  @Input() public isDirtyEnabled: boolean;
  @Input() public entityKind: string;
  @Input() public entityId: number;
  @Input() public saveSubj: Observable<void> = new Observable<void>();
  @Input() public validationSubj: Observable<boolean> = new Observable<boolean>();
  @Input() public mailpreviewSubj: Observable<void> = new Observable<void>();
  @Output() changeValueEvEm = new EventEmitter();
  @Output() autoUpdateEvEm = new EventEmitter();
  @Output() loseFocusEvEm = new EventEmitter();

  DIRTY_CLASSNAME = 'fo-dirty-behaviour';
  DIRTY_CHECKBOX_CLASSNAME = 'fo-dirty-behaviour-checkbox';
  isSavePressed = false;
  isValidationFailed = false;

  constructor() {
    super();
  }
}
