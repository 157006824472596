import { Entity } from './entity';

export class Product extends Entity {
  productGroup: string;
  productGroupId: number;
  productId: number;
  productKind: string;
  productKindId: number;
  productName: string;
  productPlatform: string;
  productPlatformId: number;
  productSite: string;
  productSiteId: number;
  productStatus: string;
  productStatusId: number;
  productVendor: string;
  productVendorId: number;
}

export const ENABLED_FLAG_YES = 1;

export class ProductParams {
  public static FILTER_ISENABLED_IDS = 'filterEnabledIds';
  public static FILTER_GROUP_IDS = 'filterGroupIds';
  public static FILTER_IDS = 'filterIds';
  public static FILTER_KIND_IDS = 'filterKindIds';
  public static FILTER_PLATFORM_IDS = 'filterPlatformIds';
  public static FILTER_SITE_IDS = 'filterSiteIds';
  public static FILTER_STATUS_IDS = 'filterStatusIds';
  public static FILTER_TYPE_IDS = 'filterTypeIds';
  public static FILTER_VENDOR_IDS = 'filterVendorIds';
}
