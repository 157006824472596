<div class="main-wizard-container" *ngIf="entityWizardData">
  <div class="wizard-container">
    <div class="wizard-title">
      <span>{{ 'label_create_new' | translate }} {{ wizardName | translate }}</span>
    </div>
    <mat-horizontal-stepper
      id="entity-wizard-horizontal-stepper"
      class="horizontal-stepper"
      [ngClass]="entityWizardData && entityWizardData.steps && entityWizardData.steps.length === 1 ? 'hide-stepper' : ''"
      labelPosition="bottom"
      #entityWizardStepper
      ><ng-template matStepperIcon="edit" let-index="index">
        <mat-icon *ngIf="getStepperIcon(index) !== ''">
          {{ getStepperIcon(index) }}
        </mat-icon>
        <span *ngIf="getStepperIcon(index) === ''">{{ index + 1 }}</span>
      </ng-template>
      <mat-step *ngFor="let formStep of entityWizardData.steps; let i = index">
        <ng-template matStepLabel>{{ formStep.formstepName }}</ng-template>
        <ng-container *ngTemplateOutlet="stepperButtons"></ng-container>
        <div class="step-content-container" [ngClass]="error || errorMessage ? 'error' : ''">
          <app-generic-entity-wizard-form-step
            *ngIf="wizardId !== -1 && entityWizardStepper.selectedIndex === i"
            [wizardId]="wizardId"
            [saveSubj]="saveSubj.asObservable()"
            [entityKind]="wizardName"
            [entityWizardStepData]="formStep"
            [foreignFilterEntityKind]="foreignFilterEntityKind"
            [foreignFilterEntityId]="foreignFilterEntityId"
            [eventDataChangedCallback]="eventDataChangedCallback"
            [isDocumentsTabEnabled]="isDocumentsTabEnabled"
            (searchChangeValueEvEm)="apply($event, i)"
            (changeValueEvEm)="checkValidity($event, i)"
          ></app-generic-entity-wizard-form-step>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
<div
  #flyingButtons
  [cdkDragBoundary]="isWizardDialog ? '.cdk-overlay-pane' : '.main-content-container'"
  (cdkDragEnded)="flyingButtonsDragEnd($event)"
  (cdkDragStarted)="flyingBtnDragging = true"
  [style.pointer-events]="flyingBtnDragging ? 'none' : null"
  class="flying-button-overlay-button triple-buttons"
  cdkDrag
>
  <ng-container>
    <button
      mat-icon-button
      (click)="onBack()"
      [disabled]="isFirstStep()"
      matTooltip="{{ 'label_back' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-arrow-circle-left"></mat-icon>
    </button>
  </ng-container>
  <button mat-icon-button (click)="onCancel()" matTooltip="{{ 'label_cancel' | translate }}" [matTooltipClass]="'fo-mat-tooltip'">
    <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
  </button>
  <ng-container *ngIf="!isFinishStep()">
    <button
      mat-icon-button
      (click)="onNext()"
      [disabled]="isNextDisabled()"
      matTooltip="{{ 'label_next' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-arrow-circle-right"></mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="isFinishStep()">
    <button
      mat-icon-button
      (click)="onNext()"
      [disabled]="isNextDisabled()"
      matTooltip="{{ 'label_save' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-save"></mat-icon>
    </button>
  </ng-container>
</div>
<ng-template #stepperButtons>
  <div class="navigation-header" *ngIf="error || errorMessage">
    <div class="error-wrapper" *ngIf="error">
      <div class="icon"><i class="fa fa-exclamation icon"></i></div>
      <div class="message">
        {{ error | translate }}
      </div>
      <div *ngIf="errorMessage" class="tooltip">
        <i class="fa fa-info-circle icon" (click)="openDialogWithRef(errorMessageDialog)"></i>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #errorMessageDialog>
  <div
    class="dialog-draggable-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
  >
    <mat-icon>drag_indicator</mat-icon>
  </div>

  <div class="errormessage-dialog-header">
    <button mat-icon-button matDialogClose>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <pre class="errormessage-dialog-info">{{ errorMessage }}</pre>
  </div>
</ng-template>
