import { Userlicense } from 'src/app/models/userlicense';
import { AllocateConsumerAddonsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-addons-wizard/dates-step/allocate-consumer-addons-date-info';
import { AllocateConsumerAddonsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-addons-wizard/init-step/allocate-consumers-addons-init-step';

export interface State {
  initStep: AllocateConsumerAddonsInitStep;
  datesStep: AllocateConsumerAddonsDueDates;
  assetsStep: Userlicense[];
}

export const initialState: State = {
  initStep: null,
  datesStep: null,
  assetsStep: null,
};
