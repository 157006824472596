import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { ApiPath } from 'src/app/configs/api-paths';
import { BaseResponse } from 'src/app/models/base-response';
import { Entity } from 'src/app/models/entity';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { ServiceBillingAccounts } from 'src/app/models/service-calc-units';
import { AddSubscriptionsRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-subscriptions-wizard/add-subscriptions-confirm-step/add-subscriptions-request';
import { environment } from 'src/environments/environment';
import { LogService } from '../log-service';

@Injectable({
  providedIn: 'root',
})
export class AddSubscriptionsWizardService {
  getTrialPeriodOptions(): Observable<BaseResponse<Entity[]>> {
    const m = this.getTrialPeriodOptions.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_TRIAL_PERIOD);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<Entity[]>>(path);

    return retVal;
  }

  getAddonsBillingAccounts(
    licenseId: number,
    serviceIds: string,
    productfamilyId: number,
    activationDate: Date
  ): Observable<BaseResponse<ServiceBillingAccounts[]>> {
    const m = this.getAddonsBillingAccounts.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS_BILLINGACCOUNTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'serviceIds', serviceIds);
    let params = new HttpParams().set('add', true).set('serviceIds', serviceIds);
    params = params.append('licenseId', licenseId);
    params = params.append('productfamilyId', productfamilyId);
    if (moment(activationDate).isValid()) {
      params = params.append('activationDate', moment(activationDate).utc(true).format('YYYY-MM-DD'));
    }
    const retVal = this.http.get<BaseResponse<ServiceBillingAccounts[]>>(path, {
      params,
    });

    return retVal;
  }

  addSubscriptionsValidation(request: AddSubscriptionsRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.addSubscriptionsValidation.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ADD_SUBSCRIPTIONS_VALIDATION);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);

    return retVal;
  }

  addSubscriptions(request: AddSubscriptionsRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.addSubscriptions.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ADD_SUBSCRIPTIONS);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
