import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { BillingAccount } from 'src/app/models/vendor-account';
import { MoveBillingitemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-billingitems-wizard/activation-step/movebillingitems-due-date-info';
import { MoveBillingitemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-billingitems-wizard/init-step/init-step';
import * as fromMoveBillingitemsWizard from './reducer';
import { State } from './state';

export const selectMoveBillingitemsWizardState = createFeatureSelector<State>(fromMoveBillingitemsWizard.moveBillingitemsWizardFeatureKey);

export const selectInitStep = createSelector(selectMoveBillingitemsWizardState, (state: State): MoveBillingitemsInitStep => state.initStep);

export const selectDueDateStep = createSelector(
  selectMoveBillingitemsWizardState,
  (state: State): MoveBillingitemsDueDates => state.dueDatesStep
);

export const selectBillingaccountsStep = createSelector(
  selectMoveBillingitemsWizardState,
  (state: State): BillingAccount[] => state.billingaccountStep
);

export const selectMoveBillingitemsState = createSelector(
  selectMoveBillingitemsWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectMoveBillingitemsWizardState, (state: State): string => state.errorMessage);
