<div class="cron-tab-container">
  <mat-radio-group [disabled]="!isEditing" [(ngModel)]="selectedOption" (change)="regenerateCron()">
    <mat-radio-button [value]="CronMinutesOptions.everyMinute">
      <label>{{ 'cron_editor_label_every_minute' | translate }}</label>
    </mat-radio-button>

    <mat-radio-button [value]="CronMinutesOptions.everyNMinuteStartFrom">
      <label>{{ 'cron_editor_label_every' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronMinutesOptions.everyNMinuteStartFrom"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="everyNMinute"
        >
          <mat-option class="form-field-option" *ngFor="let every of everyNMinuteOptions" [value]="every">
            {{ every }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_every_minute_startingat' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronMinutesOptions.everyNMinuteStartFrom"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="everyNMinuteStartFrom"
        >
          <mat-option class="form-field-option" *ngFor="let startingFrom of rangeMinutesOptions" [value]="startingFrom">
            {{ startingFrom | number : '2.0' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-radio-button>

    <mat-radio-button [value]="CronMinutesOptions.specificMinutes">
      <label>{{ 'cron_editor_label_specific_minute' | translate }}</label>
      <ng-container *ngIf="isEditing">
        <span style="margin-left: 0.5rem"></span>
        <button
          mat-raised-button
          color="primary"
          [disabled]="selectedOption !== CronMinutesOptions.specificMinutes"
          (click)="selectAllCheckboxes()"
        >
          {{ 'label_select_all' | translate }}
        </button>
        <span style="margin-left: 0.5rem"></span>
        <button
          mat-raised-button
          color="primary"
          [disabled]="selectedOption !== CronMinutesOptions.specificMinutes"
          (click)="resetCheckboxes()"
        >
          {{ 'label_reset' | translate }}
        </button>
      </ng-container>
    </mat-radio-button>
    <div class="cron-input-specific-checkboxes-container">
      <mat-checkbox
        [disabled]="!isEditing || selectedOption !== CronMinutesOptions.specificMinutes"
        *ngFor="let minute of rangeMinutesOptions"
        [checked]="isCheckboxChecked(minute)"
        (change)="checkboxSelectionChanged($event)"
        [value]="minute"
      >
        {{ minute | number : '2.0' }}</mat-checkbox
      >
    </div>

    <mat-radio-button [value]="CronMinutesOptions.rangeMinutes">
      <label>{{ 'cron_editor_label_every_minute_between' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronMinutesOptions.rangeMinutes"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="rangeMinutesStarts"
        >
          <mat-option class="form-field-option" *ngFor="let startingFrom of rangeMinutesOptions" [value]="startingFrom">
            {{ startingFrom | number : '2.0' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_every_minute_between_andminutes' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronMinutesOptions.rangeMinutes"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="rangeMinutesEnds"
        >
          <mat-option class="form-field-option" *ngFor="let to of rangeMinutesOptions" [value]="to">
            {{ to | number : '2.0' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-radio-button>
  </mat-radio-group>
  <!-- <h3>Cron expression - minutes {{ minutesExpression }}</h3> -->
</div>
