import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { ReplaceProductServiceInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-product-service-wizard/init-step/init-step';
import { ReplaceProductServiceBillingStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-product-service-wizard/replace-product-service-billing-step/replace-product-service-billing-step';
import { ReplaceProductServiceReplacementStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-product-service-wizard/replace-product-service-replacement-step/replace-product-service-replacement-step';
import * as fromReplaceProductServiceWizard from './reducer';
import { State } from './state';

export const selectReplaceProductServiceWizardState = createFeatureSelector<State>(
  fromReplaceProductServiceWizard.replaceProductServiceWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectReplaceProductServiceWizardState,
  (state: State): ReplaceProductServiceInitStep => state.initStep
);

export const selectReplacementStep = createSelector(
  selectReplaceProductServiceWizardState,
  (state: State): ReplaceProductServiceReplacementStep => state.replacementStep
);

export const selectBillingStep = createSelector(
  selectReplaceProductServiceWizardState,
  (state: State): ReplaceProductServiceBillingStep => state.billingStep
);

export const selectReplaceProductServiceState = createSelector(
  selectReplaceProductServiceWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectReplaceProductServiceWizardState, (state: State): any => state.errorMessage);
