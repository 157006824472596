import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, first, switchMap } from 'rxjs';
import { HttpHeaders } from 'src/app/configs/http-headers';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';

@Injectable()
export class AppLanguageInterceptor implements HttpInterceptor {
  constructor(private store: Store<RootStoreState.State>, private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(PreferencesSelectors.selectPreferencesHelp).pipe(
      first(),
      switchMap((helpSetting) => {
        if (!helpSetting || !req.url.match(helpSetting.settingHelpUrl)) {
          const currentLanguage = this.translateService.currentLang;
          if (currentLanguage) {
            req = req.clone({
              headers: req.headers.set(HttpHeaders.APP_LANGUAGE, currentLanguage),
            });
          }
          return next.handle(req);
        } else {
          return next.handle(req);
        }
      })
    );
  }

  private get translateService(): TranslateService {
    return this.injector.get(TranslateService);
  }
}
