import { Entity } from './entity';

export class License extends Entity {
  licenseBillingAccount: string;
  licenseBillingAccountId: number;
  licenseDesksidname: string;
  licenseEnds: Date;
  licenseId: number;
  licenseIssuer: string;
  licenseIssuerId: number;
  entityName: string;
  licenseNoticedate: Date;
  licensePlatform: string;
  licensePlatformId: number;
  licenseProduct: string;
  licenseProductFamily: string;
  licenseProductFamilyId: number;
  licenseProductService: string;
  licenseProductServiceId: number;
  licenseRolloverdate: Date;
  licenseSite: string;
  licenseStarts: Date;
  licenseUseMainBillingAccount: boolean;
  licenseUsersNumber: string;
  licenseUsers: string;
}
export const WAITINGROOM_FLAG_YES = 1;
export const ALLOCATED_FLAG_NO = 0;
export const NEED_APPROVALS_FLAG_YES = 1;

export class LicenseParams {
  public static FILTER_ACCESSMODE = 'accessmode';
  public static FILTER_DESK_IDS = 'filterDeskIds';
  public static FILTER_GROUP_IDS = 'filterGroupIds';
  public static FILTER_KIND_IDS = 'filterKindIds';
  public static FILTER_LOCATION_IDS = 'filterLocationIds';
  public static FILTER_PLATFORM_IDS = 'filterPlatformIds';
  public static FILTER_FEEDITEM_IDS = 'filterFeeditemIds';
  public static FILTER_SHARED_IDS = 'filterSharedIds';
  public static FILTER_SITE_IDS = 'filterSiteIds';
  public static FILTER_STATUS_IDS = 'filterStatusIds';
  public static FILTER_TYPE_IDS = 'filterTypeIds';
  public static FILTER_PRODUCT_IDS = 'filterProductIds';
  public static FILTER_PRODUCTFAMILY_IDS = 'filterProductfamilyIds';
  public static FILTER_BILLINGACCOUNT_IDS = 'filterBillingaccountIds';
  public static FILTER_VENDOR_IDS = 'filterVendorIds';
  public static FILTER_WAITINGROOM_IDS = 'filterWaitingroomIds';
  public static FILTER_START_DATE = 'filterStarts';
  public static FILTER_DESK_DATE = 'desksFilterDate';
}
