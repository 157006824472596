import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { LogService } from '../log-service';

@Injectable({ providedIn: 'root' })
export class RoleGuardService implements CanActivate {
  constructor(private router: Router, protected store: Store<RootStoreState.State>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(PreferencesSelectors.selectEnabledPaths).pipe(
      filter((enabledPaths) => !!enabledPaths),
      switchMap((enabledPaths) => {
        // LogService.info(this, this.canActivate.name, 'enabledPaths', enabledPaths);
        let pathEnabled = false;
        // LogService.debug(this, this.canActivate.name, 'RouterStateSnapshot', state);
        if (state.url && !state.url.includes('configurationOutlet')) {
          // LogService.debug(this, this.canActivate.name, 'Pages', state.url);
          const path = state.url.split(';').pop().split(')')[0];
          // if (state.url === '/pages/news') {
          //   pathEnabled = true;
          // } else {
          pathEnabled = enabledPaths.includes(state.url.split(';')[0].split('?')[0]);
          // }
        } else {
          const configPath = state.url.split('configurationOutlet:').pop().split(')')[0];
          if (configPath === 'configurations') {
            pathEnabled = true;
          } else {
            LogService.debug(this, this.canActivate.name, 'Configurations', `/${configPath}`);
            pathEnabled = enabledPaths.includes(`/${configPath}`);
          }
        }
        if (!pathEnabled) {
          this.router.navigate(['']);
        }
        return of(pathEnabled);
      })
    );
  }
}
