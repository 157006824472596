import { Component, ViewChild } from '@angular/core';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import Base64UploaderPlugin from 'src/@ckeditor/Base64Upload';
import { BaseFormFieldConfigComponent } from '../base-form-field-config/base-form-field-config-component';

@Component({
  selector: 'app-form-field-config-mailtemplate',
  templateUrl: './form-field-config-mailtemplate.component.html',
  styleUrls: ['./form-field-config-mailtemplate.component.scss'],
})
export class FormFieldConfigMailtemplateComponent extends BaseFormFieldConfigComponent {
  @ViewChild('ckeditor') ckeditor: CKEditorComponent;

  public Editor = ClassicEditor;
  public config;

  constructor() {
    super();
    this.config = {
      heading: {
        options: [
          { model: 'paragraph', title: this.translate.instant(_('ckeditor_paragraph')), class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: this.translate.instant(_('ckeditor_heading1')), class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: this.translate.instant(_('ckeditor_heading2')), class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: this.translate.instant(_('ckeditor_heading3')), class: 'ck-heading_heading3' },
        ],
      },
      language: this.translate.currentLang,
      extraPlugins: [Base64UploaderPlugin],
      link: { addTargetToExternalLinks: true },
    };
  }
}
