<div class="grid-filter-panel-input">
  <mat-form-field
    appearance="outline"
    class="form-field-input reset-outline-hint grid-filter-dropdown"
    [ngClass]="selection.selected.length > 0 ? 'filter-defined' : ''"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    [matTooltip]="filterTooltip"
  >
    <input type="text" matInput #optionSelect [value]="filterString" readonly (click)="$event.stopPropagation(); openOverlay()" />
    <button *ngIf="!isOverlayOpen" mat-icon-button matSuffix (click)="$event.stopPropagation(); openOverlay()">
      <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
    </button>
    <button *ngIf="!isOverlayOpen && selection.selected.length > 0" mat-icon-button matSuffix (click)="reset()">
      <mat-icon>close</mat-icon>
    </button>
    <button *ngIf="isOverlayOpen" mat-icon-button matSuffix>
      <mat-icon class="dropdown-icon">arrow_drop_up</mat-icon>
    </button>
  </mat-form-field>
</div>
<ng-template
  #filterOverlay="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOverlayOpen"
  [cdkConnectedOverlayPositions]="overlayPositions"
  (overlayOutsideClick)="closeOverlay()"
>
  <div class="grid-filter-options-overlay" ngResizable rzHandles="e,edrag" [rzMinWidth]="300">
    <div class="grid-filter-options-container">
      <mat-expansion-panel
        *ngIf="selection && selection.selected.length > 0"
        class="detail-expansion-panel selected-items-expansion-panel"
        [expanded]="true"
        [@.disabled]="true"
        [hideToggle]="true"
        [disabled]="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> {{ 'grid_filter_options_selected' | translate }} ({{ selection.selected.length }}) </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="table-wrapper">
          <div class="table-container">
            <table class="table table-stripes table-truncate-text" mat-table [dataSource]="selection.selected">
              <ng-container matColumnDef="entityName">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell showIfTruncated [matTooltip]="row.entityName" *matCellDef="let row">
                  {{ row.entityName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="cancel">
                <th mat-header-cell *matHeaderCellDef class="icondelete-header"></th>
                <td mat-cell class="icondelete-cell" *matCellDef="let row">
                  <mat-icon (click)="onCheckboxClicked(row)" style="color: red" fontSet="fas" fontIcon="fa-times"></mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsSelectionTable"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsSelectionTable"></tr>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
      <div class="options-container">
        <div class="table-wrapper">
          <div class="table-search-paginate">
            <div class="row" *ngIf="showSearchBar" style="margin-bottom: 4px">
              <mat-form-field appearance="outline" class="reset-outline-hint filter-field search">
                <mat-autocomplete
                  (opened)="matAutocompletPanelOpened()"
                  (closed)="matAutocompletPanelClosed()"
                  #autoLastStringOption="matAutocomplete"
                  (optionSelected)="applyFilterTable($event.option.value)"
                >
                  <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
                    {{ 'label_recent_searches' | translate }}
                  </mat-option>
                  <mat-option
                    class="form-field-option"
                    *ngFor="let opt of lastUsedFilters"
                    [value]="opt"
                    [class.mat-selected]="filter === opt"
                  >
                    {{ opt }}
                  </mat-option>
                </mat-autocomplete>
                <input
                  matInput
                  #filterInput
                  #searchInputAutocompleteTrigger="matAutocompleteTrigger"
                  [matAutocomplete]="autoLastStringOption"
                  (input)="searchInputAutocompleteTrigger.closePanel()"
                  [(ngModel)]="filter"
                  (debounceFilterInput)="applyFilterTable($event)"
                  [debounceTime]="600"
                  maxlength="1500"
                  placeholder="{{ 'grid_filter_options_table_filter_placeholder' | translate }}"
                />
                <button mat-icon-button (click)="resetFilter()" matSuffix>
                  <mat-icon class="reset-filter-icon"> close </mat-icon>
                </button>
              </mat-form-field>
              <mat-paginator
                [disabled]="isLoading"
                [hidePageSize]="true"
                #paginatorTable
                [pageSizeOptions]="pageSizeList"
                [length]="pageTotalElements"
                (page)="onPageChanged()"
                class="reset-paginator hidden-labels"
              ></mat-paginator>
            </div>
            <div class="row filter-selection-button">
              <button mat-button color="primary" (click)="selectDisplayed()">
                {{ 'label_select_displayed' | translate }}
              </button>
              <button mat-button color="primary" (click)="selectAll()" *ngIf="showSearchBar">
                {{ 'label_select_all' | translate }}
              </button>
              <button mat-button color="primary" (click)="reset()" *ngIf="selection && selection.selected.length > 0">
                {{ 'label_reset' | translate }}
              </button>
              <button
                *ngIf="gridName && gridfilterId != null"
                mat-icon-button
                color="primary"
                (click)="changeDefaults()"
                matTooltip="{{ 'label_change_filter_default' | translate }}"
                [matTooltipClass]="'fo-mat-tooltip'"
              >
                <mat-icon>star_border</mat-icon>
              </button>
            </div>
          </div>
          <div class="table-container items">
            <table class="table table-stripes table-truncate-text" mat-table [dataSource]="dataSourceTable" *ngIf="!isLoading">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? onCheckboxClicked(row) : null"
                    [checked]="isRowSelected(row)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="entityName">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell showIfTruncated [matTooltip]="row.entityName" *matCellDef="let row">
                  {{ row.entityName }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsTable" [ngClass]="isDefaultFilter(row) ? 'default-row' : ''"></tr>
            </table>
            <mat-toolbar class="grid-filter-footer-toolbar" *ngIf="noEntityData || isLoading">
              <mat-toolbar-row *ngIf="isLoading" class="spinner">
                <mat-spinner diameter="50"></mat-spinner>
              </mat-toolbar-row>
              <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
                <span>{{ 'generic_filter_error_nodata' | translate }}</span>
              </mat-toolbar-row>
            </mat-toolbar>
          </div>
          <div class="close-button-container">
            <button mat-flat-button color="primary" (click)="closeOverlay()">
              <ng-container> {{ 'label_close' | translate }}</ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
