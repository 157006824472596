import { Action, createReducer, on } from '@ngrx/store';
import * as AddConsumersToAssetWizardActions from './actions';
import { State, initialState } from './state';

export const addConsumersToAssetWizardFeatureKey = 'addConsumersToAssetWizard';

const addConsumersToAssetWizardReducer = createReducer(
  initialState,

  on(AddConsumersToAssetWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(AddConsumersToAssetWizardActions.selectConsumersStep, (state, { consumersStep }) => ({
    ...state,
    consumersStep,
  })),
  on(AddConsumersToAssetWizardActions.deleteConsumersStep, (state) => ({
    ...state,
    consumersStep: null,
  })),
  on(AddConsumersToAssetWizardActions.exitAddConsumersToAssetWizard, (state) => ({
    ...state,
    initStep: null,
    consumersStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(AddConsumersToAssetWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    consumersStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(AddConsumersToAssetWizardActions.createNewAddConsumersToAsset, (state, { request }) => ({
    ...state,
    request,
  })),
  on(AddConsumersToAssetWizardActions.createNewAddConsumersToAssetSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(AddConsumersToAssetWizardActions.createNewAddConsumersToAssetFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return addConsumersToAssetWizardReducer(state, action);
}
