import { ApiPath } from 'src/app/configs/api-paths';

export class ExcludedAPIServices {
  static map = new Map<string, string>([
    [ApiPath.Auth.AUTH, 'POST'],
    [ApiPath.Auth.RESET_PWD, 'POST'],
    [ApiPath.Auth.CHANGE_PWD, 'POST'],
    [ApiPath.Auth.FORGOT_PWD, 'POST'],
    [ApiPath.TenantAdministration.AUTH, 'POST'],
  ]);
}
