import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Observable } from 'rxjs';
import { Entity, EntitySelection } from 'src/app/models/entity';
import { CoreDataService } from 'src/app/services/core-data.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { DynamicFlatNode, GenericTreeNode } from '../../../models/tree-view-flat-node';
import { DynamicDataSource, DynamicDatabase } from './dynamic-data-source';

@Component({
  selector: 'app-generic-tree-view',
  templateUrl: './generic-tree-view.component.html',
  styleUrls: ['./generic-tree-view.component.scss'],
  providers: [DynamicDatabase],
})
export class GenericTreeViewComponent extends FormatComponent implements OnInit {
  @Input() baseApiPath: string;
  @Input() baseApiParams: string;
  @Input() nodeName = 'nodes';
  @Input() rootNodeLabel = 'label_root';
  @Input() baseIcon = 'chevron_right';
  @Input() expandedIcon = 'expand_more';
  @Input() enableCheckboxSelection = false;
  @Input() showIconLeaf = false;
  @Input() defaultRootNode = false;
  @Input() autoselectEmptyNode = false;
  @Input() enableDrag = false;
  @Input() showInfoTooltip = false;
  @Input() dragTarget: string;
  @Input() isNodeSelection = false;
  @Input() selectedDataSubj: Observable<EntitySelection[]>;
  @Output() nodeClick = new EventEmitter<Entity>();
  @Output() addToSelectionEvEm = new EventEmitter<Entity>();
  @Output() removeFromSelectionEvEm = new EventEmitter<Entity>();

  isLoading = false;
  noEntityData = false;
  treeControl: FlatTreeControl<DynamicFlatNode>;
  dataSource: DynamicDataSource;
  selectedNodes: GenericTreeNode[];
  selectedNode: DynamicFlatNode;
  displayedColumnsTable = ['entityName'];
  rootNode: GenericTreeNode[] = [
    {
      entityCount: 0,
      entityId: -1,
      entityName: this.translate.instant(_(this.rootNodeLabel)),
      entityKind: 'root',
      entityDescription: 'label_root_description',
      entityInfo: '',
      entityUpdate: false,
      entityDelete: false,
      entityChilds: 1,
      entityParentId: null,
      entityActive: true,
    },
  ];
  dataSelection: EntitySelection[] = [];

  constructor(private database: DynamicDatabase, private coreDataService: CoreDataService, private cdRef: ChangeDetectorRef) {
    super();
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
  }

  // ngOnChanges(change: SimpleChanges) {
  //   this.defaultRootNode = change.defaultRootNode ? change.defaultRootNode.currentValue : false;
  //   if (!this.defaultRootNode) {
  //     this.fetchData();
  //   }
  // }

  ngOnInit() {
    this.dataSource = new DynamicDataSource(this.treeControl, this.database, this.coreDataService);
    this.dataSource.baseApiPath = this.baseApiPath;
    this.dataSource.nodeName = this.nodeName;
    if (this.defaultRootNode) {
      this.rootNode[0].entityName = this.translate.instant(_(this.rootNodeLabel));
      this.dataSource.data = this.database.initialData(this.rootNode);
      this.noEntityData = false;
    } else {
      this.fetchData();
    }
    if (this.selectedDataSubj) {
      this.subscribe(this.selectedDataSubj, (data) => {
        if (data) {
          this.dataSelection = data;
          if (this.dataSource && this.dataSource.data) {
            this.dataSource.nodeSelection.clear();
            this.dataSource.data.forEach((row) => {
              if (this.isRowSelected(row.item)) {
                this.dataSource.toggleNodeSelection(row);
              }
            });
          }
        }
      });
    }
  }

  isRowSelected(row: Entity) {
    if (this.dataSelection.length === 0) {
      return false;
    }
    if (this.dataSelection.some((e) => !e.deleted && e.entityId === -1)) {
      return true;
    }
    return this.dataSelection.some((e) => !e.deleted && e.entityId === row.entityId);
  }

  fetchData() {
    this.isLoading = true;
    this.subscribe(this.coreDataService.getGenericTreeNodeChilds(this.baseApiPath, -1, this.nodeName, this.baseApiParams), (response) => {
      if (response.data) {
        this.dataSource.data = this.database.initialData(response.data);
        this.dataSource.nodeSelection.clear();
        this.dataSource.data.forEach((row) => {
          if (this.isRowSelected(row.item)) {
            this.dataSource.toggleNodeSelection(row);
          }
        });
      } else {
        this.noEntityData = true;
      }
      if (this.autoselectEmptyNode) {
        this.nodeClick.emit({ ...new Entity(), entityKind: response.entityKind, entityId: -1 });
      }
      this.isLoading = false;
    });
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (x: number, nodeData: DynamicFlatNode) => nodeData.expandable;

  onClick(event) {
    this.selectedNode = event;
    const { entityId, entityName, entityKind } = event.item;
    this.nodeClick.emit(event.item);
  }

  async addChildToSelectedNode(child: Entity) {
    this.dataSource.addChildrenToParentNode(this.selectedNode);
    this.onClick(
      new DynamicFlatNode(
        { ...new GenericTreeNode(), ...child },
        this.selectedNode.level + 1,
        false,
        false,
        false,
        this.selectedNode.item.entityId
      )
    );
    // this.refreshNodeChilds(this.selectedNode);
  }

  updateEntityNameSelectedNode(name: string) {
    this.selectedNode.item.entityName = name;
  }

  removeChildToSelectedNode() {
    this.dataSource.removeChildrenToParentNode(this.selectedNode);
  }

  // removeChildToSelectedNode() {
  //   console.log(this.selectedNode);
  //   const parentNode = this.getParent(this.selectedNode);
  //   parentNode.item.entityChilds = parentNode.item.entityChilds - 1;
  //   parentNode.expandable = parentNode.item.entityChilds > 0;
  //   this.cdRef.detectChanges();
  //   this.refreshNodeChilds(parentNode);
  //   this.selectedNode = parentNode;
  // }

  removeSelectedNode() {
    this.database.removeChildren(this.getParent(this.selectedNode).item, this.selectedNode.item);
  }

  private refreshNodeChilds(parentNode: DynamicFlatNode) {
    if (this.treeControl.isExpanded(parentNode)) {
      this.treeControl.collapse(parentNode);
    }
    if (parentNode.expandable) {
      this.treeControl.expand(parentNode);
    }
  }

  addToSelection(row: Entity) {
    this.addToSelectionEvEm.emit(row);
  }

  onNodeCheckboxClicked(row: DynamicFlatNode) {
    this.dataSource.toggleNodeSelection(row);
    if (this.dataSource.nodeSelection.isSelected(row.item)) {
      this.addToSelectionEvEm.emit(row.item);
    } else {
      this.removeFromSelectionEvEm.emit(row.item);
    }
  }

  /**
   * Iterate over each node in reverse order and return the first node that has a lower level than the passed node.
   */
  private getParent(node: DynamicFlatNode) {
    const { treeControl } = this;
    const currentLevel = treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = treeControl.dataNodes[i];

      if (treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }
}
