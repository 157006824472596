import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationCount } from 'src/app/models/notification';
import * as fromNotificationsStore from './reducer';
import { State } from './state';

export const selectNotificationsStoreState = createFeatureSelector<State>(fromNotificationsStore.notificationsStoreFeatureKey);

export const selectCountNotifications = createSelector(selectNotificationsStoreState, (state: State): NotificationCount => state.count);

export const selectNotificationsIsFinished = createSelector(selectNotificationsStoreState, (state: State): boolean => state.isFinished);

export const selectNotificationsError = createSelector(selectNotificationsStoreState, (state: State): any => state.error);
