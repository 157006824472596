import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { Property } from 'src/app/models/property';
import { BaseParams } from '../models/paginated-request';
import { SearchResult } from '../models/search-result';
import { TypeTreeNode } from '../models/type-node';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService {
  getGlobalSearchEntities(): Observable<BaseResponse<Property[]>> {
    const m = this.getGlobalSearchEntities.name;

    const path = environment.getEndpoint(ApiPath.GlobalSearch.SEARCH_ENTITIES);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<Property[]>>(path);
    return retVal;
  }

  getGlobalSearchLastSearchs(last: number): Observable<BaseResponse<string[]>> {
    const m = this.getGlobalSearchEntities.name;

    const path = environment.getEndpoint(ApiPath.GlobalSearch.SEARCH_LAST);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'last', last);
    let params = new HttpParams().set('last', last.toString());
    const retVal = this.http.get<BaseResponse<string[]>>(path);
    return retVal;
  }

  startGlobalSearch(filter: string, filterEntityKind?: string[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.startGlobalSearch.name;

    const path = environment.getEndpoint(ApiPath.GlobalSearch.SEARCH);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, 'filter', filter);
    LogService.info(this, m, 'filterEntityKinds', filterEntityKind);
    let params = new HttpParams().set(BaseParams.FILTER, filter);
    if (filterEntityKind && filterEntityKind.length > 0) {
      params = params.append('filterEntityKinds', filterEntityKind.join(','));
    }
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getSearchResult(searchId): Observable<BaseResponse<SearchResult>> {
    const m = this.getSearchResult.name;

    const path = environment.getEndpoint(ApiPath.GlobalSearch.SEARCH_ID(searchId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<SearchResult>>(path);
    return retVal;
  }

  deleteSearch(searchId): Observable<BaseResponse<ActionResponse>> {
    const m = this.deleteSearch.name;

    const path = environment.getEndpoint(ApiPath.GlobalSearch.SEARCH_ID(searchId));
    LogService.info(this, m, LogService.DELETE + path, null);
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path);
    return retVal;
  }

  getSearchFilterrules(): Observable<BaseResponse<TypeTreeNode[]>> {
    const m = this.getSearchResult.name;

    const path = environment.getEndpoint(ApiPath.GlobalSearch.SEARCH_FILTERRULES);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<TypeTreeNode[]>>(path);
    return retVal;
  }

  constructor(private http: HttpClient) {}
}
