import { ActionResponse } from 'src/app/models/action-response';
import { FeeditemReplaceRequest } from 'src/app/models/feeditem';
import { ServiceAlias } from 'src/app/models/service';
import { ReplaceFeeditemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-feeditems-wizard/activation-step/replacefeeditems-due-date-info';
import { ReplaceFeeditemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-feeditems-wizard/init-step/init-step';

export interface State {
  initStep: ReplaceFeeditemsInitStep;
  dueDatesStep: ReplaceFeeditemsDueDates;
  serviceStep: ServiceAlias[];
  request: FeeditemReplaceRequest;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  dueDatesStep: null,
  serviceStep: null,
  request: null,
  response: null,
  errorMessage: null,
};
