import { Directive, OnDestroy, OnInit } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[matAutocompleteScrollToOption]',
})
export class MatAutocompleteScrollToOptionDirective implements OnInit, OnDestroy {
  private sub: Subscription;

  constructor(private matAutocomplete: MatAutocomplete) {}

  ngOnInit(): void {
    this.sub = this.matAutocomplete.opened.subscribe(() => {
      const options = this.matAutocomplete.options;
      const optionHeigth = options.get(0)._getHostElement().clientHeight;
      const selectedOptionIndex = options.toArray().findIndex((o) => o.selected);
      if (selectedOptionIndex !== -1) {
        setTimeout(() => {
          this.matAutocomplete._setScrollTop(optionHeigth * selectedOptionIndex);
        }, 0);
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
