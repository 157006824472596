<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <h1 mat-dialog-title>
    {{ 'modal_dialog_please_select' | translate }}
    {{ data.labelName | translate }}
  </h1>

  <div class="button-wrapper">
    <button [mat-dialog-close]="selectedEntity" mat-raised-button [disabled]="!isEntitySelected()" color="primary">
      {{ 'label_select' | translate }}
    </button>
    <span style="padding-right: 5px"></span>
    <button [mat-dialog-close]="null" mat-raised-button color="primary">
      {{ 'label_cancel' | translate }}
    </button>
  </div>
</div>
<div mat-dialog-content>
  <!-- DIALOG CONTENT -->
  <div id="entity-table">
    <!-- SEARCH & PAGINATOR START-->
    <div class="table-search-paginate hidden-filters">
      <div class="table-search-paginate-left-wrapper">
        <mat-form-field appearance="outline" class="reset-outline-hint filter-field search"
          ><mat-autocomplete
            (opened)="matAutocompletPanelOpened()"
            (closed)="matAutocompletPanelClosed()"
            #autoLastStringOption="matAutocomplete"
            (optionSelected)="applyFilterTable($event.option.value)"
          >
            <mat-option
              class="form-field-option"
              *ngFor="let opt of lastUsedFilters"
              [value]="opt"
              [class.mat-selected]="filterValue === opt"
            >
              {{ opt }}
            </mat-option>
          </mat-autocomplete>
          <input
            #searchInputField
            #searchInputAutocompleteTrigger="matAutocompleteTrigger"
            (input)="searchInputAutocompleteTrigger.closePanel()"
            matInput
            [(ngModel)]="filterValue"
            [matAutocomplete]="autoLastStringOption"
            (debounceFilterInput)="applyFilterTable($event)"
            [debounceTime]="600"
            maxlength="1500"
            placeholder="{{ 'table_filter_placeholder' | translate }}"
          />
        </mat-form-field>
        <span style="padding-right: 0.5rem"></span>
        <div class="search-filterrules">
          <mat-button-toggle-group
            class="topics"
            #kindButtonGroup="matButtonToggleGroup"
            [multiple]="true"
            [value]="[selectedFilteruleId]"
            (change)="setFilterRule($event.value)"
          >
            <mat-button-toggle [value]="1">
              <span>{{ 'label_exact_match' | translate }}</span>
            </mat-button-toggle>
            <mat-button-toggle [value]="2">
              <span>{{ 'label_begin_with' | translate }}</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="table-search-paginate-right">
        <button mat-button color="primary" (click)="resetFilter()" *ngIf="isFiltersSetted()">
          {{ 'label_reset_filters' | translate }}
        </button>
      </div>
    </div>
    <!-- SEARCH & PAGINATOR END-->
    <div class="container-fluid">
      <div class="table-wrapper table-entity-wrapper">
        <div class="table-container">
          <table
            class="table table-stripes table-truncate-text"
            mat-table
            [dataSource]="dataSourceTable"
            matSort
            #sortTable="matSort"
            matSortDisableClear
            [matSortActive]="data.disableSorting ? '' : 'entityName'"
            [matSortDirection]="data.disableSorting ? '' : 'asc'"
          >
            <!-- radio button column -->
            <ng-container matColumnDef="radio">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row" class="radio-button-wrapper">
                <mat-radio-button
                  class="table-radio-button"
                  (change)="onEntitySelected(row)"
                  disableRipple="true"
                  [checked]="selectedEntity && selectedEntity.entityId === row.entityId"
                ></mat-radio-button>
              </td>
            </ng-container>
            <!-- name column -->
            <ng-container matColumnDef="entityName">
              <ng-container *ngIf="data.disableSorting">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'entity_name' | translate }}
                </th>
              </ng-container>
              <ng-container *ngIf="!data.disableSorting">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'entity_name' | translate }}
                </th>
              </ng-container>
              <td mat-cell *matCellDef="let row" [matTooltip]="row.entityName" showIfTruncated>
                {{ row.entityName }}
              </td>
            </ng-container>
            <!-- description column -->
            <ng-container matColumnDef="entityDescription">
              <ng-container *ngIf="data.disableSorting">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'entity_description' | translate }}
                </th>
              </ng-container>
              <ng-container *ngIf="!data.disableSorting">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'entity_description' | translate }}
                </th>
              </ng-container>
              <td mat-cell *matCellDef="let row" [matTooltip]="row.entityDescription" showIfTruncated>
                {{ row.entityDescription }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
            <tr
              mat-row
              (click)="onEntitySelected(row)"
              *matRowDef="let row; columns: displayedColumnsTable"
              [ngClass]="selectedEntity && selectedEntity.entityId === row.entityId ? 'selected-row' : ''"
            ></tr>
          </table>
          <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
            <mat-toolbar-row *ngIf="isLoading" class="spinner">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-toolbar-row>
            <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
              <span>{{ 'generic_table_error_nodata' | translate }}</span>
            </mat-toolbar-row>
          </mat-toolbar>
          <mat-toolbar class="table-footer-toolbar">
            <mat-toolbar-row>
              <mat-paginator
                [disabled]="isLoading"
                #paginatorTable
                [pageSizeOptions]="pageSizeList"
                [length]="pageTotalElements"
                class="reset-paginator"
              ></mat-paginator>
            </mat-toolbar-row>
          </mat-toolbar>
        </div>
      </div>
    </div>
  </div>
  <!-- END DIALOG CONTENT -->
</div>
