import { SelectionModel } from '@angular/cdk/collections';
import { CdkConnectedOverlay, ConnectedPosition, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { Observable, firstValueFrom } from 'rxjs';
import { Entity } from 'src/app/models/entity';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { RootStoreState } from 'src/app/root-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from '../../base-components/format-component';

@Component({
  selector: 'app-generic-grid-filter-panel, generic-grid-filter-panel',
  templateUrl: './generic-grid-filter-panel.component.html',
  styleUrls: ['./generic-grid-filter-panel.component.scss'],
})
export class GenericGridFilterPanelComponent extends FormatComponent implements OnInit {
  @ViewChild('filterOverlay') filterOverlay: CdkConnectedOverlay;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('searchInputAutocompleteTrigger') autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('filterInput', { static: false })
  set input(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
      if (this.autocompleteTrigger) {
        this.autocompleteTrigger.closePanel();
        this.matAutocompletPanelClosed();
      } else {
        this.matAutocompletPanelClosed();
      }
    }
  }
  @Input() clearFilter: Observable<void>;
  @Input() filtersMapSubj: Observable<Map<string, any[]>>;
  @Input() filterApiPath: string;
  @Input() filterTitle: string;
  @Input() filterName: string;
  @Input() isStringFilter = false;
  @Input() gridName: string;
  @Input() gridfilterId: number;
  @Output() filterIdsEvEm = new EventEmitter<any[]>();

  defaultSettedFilters: any[];
  filterString: string;
  filterTooltip: string;
  isOverlayOpen = false;
  overlayPositions: ConnectedPosition[] = [
    new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
  ];

  selectedfilterOptions: Entity[] = [];
  dataSourceTable: MatTableDataSource<Entity>;
  displayedColumnsTable: string[] = ['select', 'entityName'];
  displayedColumnsSelectionTable: string[] = ['entityName', 'cancel'];
  selection = new SelectionModel<Entity>(true, []);

  pageSizeList: number[] = [];
  filter: string;
  showSearchBar = false;
  request: PaginatedRequest;
  isFirstLoaded = false;
  defaultFilters: any[];
  isLoading: boolean;
  noEntityData: boolean;
  pageTotalElements = 0;
  lastUsedFilters: string[];

  constructor(
    private coreDataService: CoreDataService,
    protected store: Store<RootStoreState.State>,
    private cdRef: ChangeDetectorRef,
    private messageNotifierService: MessageNotifierService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.filterApiPath === null || this.filterApiPath === undefined) {
      throw new TypeError(`The input 'filterApiPath' is required`);
    }
    this.filterString = this.filterTitle;
    this.filterTooltip = null;
    this.dataSourceTable = new MatTableDataSource();
    // this.dataSourceTable.filterPredicate = (data, filter: string): boolean => data.entityName.toLowerCase().includes(filter);

    // #ISSUE #5983 Grid Filters Improvements
    // this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesPaginatonsOptions)), (pageSizeOpt) => {
    //   this.pageSizeList = pageSizeOpt;
    //   if (this.paginator) {
    //     this.paginator.pageSize = pageSizeOpt[0];
    //   }
    // });
    this.pageSizeList = [20]; //Fixed page size
    if (this.paginator) {
      this.paginator.pageSize = this.pageSizeList[0];
    }
    this.initRequest();
    if (this.filtersMapSubj && this.filterName) {
      this.subscribe(this.filtersMapSubj, (map) => {
        if (map) {
          this.clearFilterSelection();
          map.forEach((value, key) => {
            if (key === this.filterName) {
              this.defaultFilters = value;
            }
          });
        }
        if (this.defaultFilters) {
          this.defaultSettedFilters = this.defaultFilters;
          this.loadFilterData(true);
        }
      });
    }
    // if (this.defaultSettedFilters) {
    //   this.loadFilterData();
    // }
    if (this.clearFilter) {
      this.subscribe(this.clearFilter, () => {
        this.clearFilterSelection();
      });
    }
  }

  clearFilterSelection() {
    this.selection.clear();
    this.filterString = this.filterTitle;
    this.filterTooltip = null;
    this.defaultSettedFilters = null;
  }

  reset() {
    this.clearFilterSelection();
    this.filterIdsEvEm.emit([]);
  }

  openOverlay() {
    this.isOverlayOpen = true;
    // if (!this.isFirstLoaded) {
    this.loadFilterData();
    // }
    this.cdRef.detectChanges();
    this.updateOverlaySize();
  }

  closeOverlay() {
    this.isOverlayOpen = false;
    this.filter = '';
    this.setFilter(null);
  }

  loadFilterData(loadAll?: boolean) {
    this.isLoading = true;
    this.noEntityData = false;
    if (loadAll) {
      this.request.pageSize = null;
    } else {
      this.request.pageSize = this.pageSizeList ? this.pageSizeList[0] : 20;
    }
    this.subscribe(this.coreDataService.getFilterOptions(this.request, this.filterApiPath), (response) => {
      this.lastUsedFilters = response.filters;
      if (response.data) {
        this.pageTotalElements = response.data.length > 0 ? response.data[0].entityCount : 0;
        if (!this.isFirstLoaded) {
          this.showSearchBar = this.pageTotalElements >= this.pageSizeList[0];
        }
        this.dataSourceTable.data = response.data;
        this.cdRef.detectChanges();

        this.updateOverlaySize();
        if (this.paginator) {
          this.paginator.pageSize = this.pageSizeList[0];
          // this.dataSourceTable.paginator = this.paginator;
        }
        if (!this.isFirstLoaded && this.defaultSettedFilters && this.defaultSettedFilters.length > 0) {
          this.defaultSettedFilters.forEach((filterValue) => {
            const filter = this.dataSourceTable.data.find((f) =>
              this.isStringFilter ? f.entityName === filterValue : f.entityId === filterValue
            );
            if (filter && !this.selection.selected.some((f) => f.entityId === filter.entityId)) {
              this.selection.toggle(filter);
            }
          });
          this.filterString = `${this.filterTitle}${this.selection.selected.length > 0 ? ': ' : ''}${this.selection.selected
            .map((f) => f.entityName)
            .join(', ')}`;
        }
      } else {
        this.noEntityData = true;
        this.dataSourceTable.data = [];
        this.cdRef.detectChanges();
      }
      this.isLoading = false;
      this.isFirstLoaded = true;
    });
  }

  changeDefaults() {
    this.subscribe(
      this.coreDataService.changeDynamicFiltersDefaultsByGridName(
        this.gridName,
        this.gridfilterId,
        this.selection.selected.map((f) => (this.isStringFilter ? f.entityName : f.entityId))
      ),
      (response) => {
        this.defaultSettedFilters = this.selection.selected.map((f) => (this.isStringFilter ? f.entityName : f.entityId));
        LogService.info(this, this.changeDefaults.name, 'Default changed', response);
        this.messageNotifierService.showSuccessMessage(_('label_default_filter_values_changed'));
      }
    );
  }

  isDefaultFilter(row: Entity) {
    return (
      this.defaultSettedFilters &&
      this.defaultSettedFilters.length > 0 &&
      this.defaultSettedFilters.includes(this.isStringFilter ? row.entityName : row.entityId)
    );
  }

  private updateOverlaySize() {
    if (this.filterOverlay && this.filterOverlay.overlayRef) {
      const maxHeight = window.window.innerHeight - this.filterOverlay.overlayRef.overlayElement.offsetTop - 32;
      this.filterOverlay.overlayRef.updateSize({ maxHeight: maxHeight });
    }
  }

  // applyFilterTable(filterValue: string) {
  //   if (filterValue && filterValue.length >= 3) {
  //     // this.setFilter(filterValue);
  //     this.dataSourceTable.filter = filterValue.trim().toLocaleLowerCase();
  //   } else {
  //     if (!filterValue || (filterValue && filterValue.length === 0)) {
  //       this.dataSourceTable.filter = null;
  //     }
  //   }
  // }
  applyFilterTable(filterValue: string) {
    if (filterValue && filterValue.length >= 3) {
      this.setFilter(filterValue);
      this.loadFilterData();
    } else {
      if (!filterValue || (filterValue && filterValue.length === 0)) {
        this.setFilter(null);
        this.loadFilterData();
      }
    }
  }

  onPageChanged() {
    this.setPage();
    this.loadFilterData();
  }

  initRequest() {
    this.filter = '';
    let pagesize = 20;
    if (this.pageSizeList && this.pageSizeList.length !== 0) {
      pagesize = this.pageSizeList[0];
    }

    this.request = {
      pageIndex: 0,
      pageSize: pagesize,
      orderBy: 'entity_name',
      sort: 'asc',
      filter: this.filter,
      filterMap: null,
    };
  }

  protected setPage() {
    this.request = {
      ...this.request,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    };
  }

  protected setFilter(filter: string) {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.request = {
      ...this.request,
      pageIndex: 0,
      filter,
    };
  }

  resetFilter() {
    if (this.filter) {
      this.filter = '';
      this.applyFilterTable(this.filter);
    }
  }

  onCheckboxClicked(row: Entity) {
    // I check if clicked row is just present into selection (I need to check by Id and not by object due to possible reload of data)
    const selectedRow = this.selection.selected.find((e) => e.entityId === row.entityId);
    this.selection.toggle(selectedRow ? selectedRow : row);
    LogService.debug(
      this,
      this.filterTitle,
      'filterIdsEvEm',
      this.selection.selected.map((f) => f.entityId)
    );
    this.filterString = `${this.filterTitle}${this.selection.selected.length > 0 ? ': ' : ''}${this.selection.selected
      .map((f) => f.entityName)
      .join(', ')}`;
    this.filterTooltip = this.selection.selected.length > 0 ? this.selection.selected.map((f) => f.entityName).join(', ') : null;
    this.filterIdsEvEm.emit(this.selection.selected.map((f) => (this.isStringFilter ? f.entityName : f.entityId)));
  }

  isRowSelected(row: Entity) {
    return this.selection.selected.find((e) => e.entityId === row.entityId);
  }

  selectDisplayed() {
    this.dataSourceTable.data.forEach((row) => {
      if (!this.selection.selected.find((e) => e.entityId === row.entityId)) {
        this.selection.select(row);
      }
    });
    LogService.debug(
      this,
      this.filterTitle,
      'filterIdsEvEm',
      this.selection.selected.map((f) => f.entityId)
    );
    this.filterTooltip = this.selection.selected.length > 0 ? this.selection.selected.map((f) => f.entityName).join(', ') : null;
    this.filterIdsEvEm.emit(this.selection.selected.map((f) => (this.isStringFilter ? f.entityName : f.entityId)));
  }

  async selectAll() {
    this.isLoading = true;
    const selectAllRequest = {
      pageIndex: 0,
      pageSize: null,
      orderBy: 'entity_name',
      sort: 'asc',
      filter: null,
      filterMap: null,
    };
    const { data } = await firstValueFrom(this.coreDataService.getFilterOptions(selectAllRequest, this.filterApiPath));
    this.isLoading = false;
    data.forEach((row) => {
      if (!this.selection.selected.find((e) => e.entityId === row.entityId)) {
        this.selection.select(row);
      }
    });
    LogService.debug(
      this,
      this.filterTitle,
      'filterIdsEvEm',
      this.selection.selected.map((f) => f.entityId)
    );
    this.filterTooltip = this.selection.selected.length > 0 ? this.selection.selected.map((f) => f.entityName).join(', ') : null;
    this.filterIdsEvEm.emit(this.selection.selected.map((f) => (this.isStringFilter ? f.entityName : f.entityId)));
  }
}
