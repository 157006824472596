<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span>{{
    'label_following_records_will_be_modified' | translate : { number: selectedEntities?.length, entityKind: entityKindTransation }
  }}</span>
</div>
<div mat-dialog-content class="generic-mass-edit-dialog-content">
  <div id="generic-entity-files-table" class="entity-table">
    <div class="table-wrapper">
      <div class="table-container">
        <table class="table table-stripes table-truncate-text" mat-table [dataSource]="dataSourceTable" *ngIf="controls">
          <ng-container matColumnDef="fieldname">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.formfieldName" showIfTruncated>{{ row.formfieldName | translate }}</td>
          </ng-container>

          <ng-container matColumnDef="currentvalue">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'entity_current_value' | translate }}
              <br />
              &nbsp;
            </th>
            <td
              mat-cell
              *matCellDef="let row; let index = index"
              [matTooltip]="
                formFields[index].formfieldEntityText === '[VARIOUS]'
                  ? ('label_various' | translate)
                  : formFields[index].formfieldEntityText
              "
              showIfTruncated
            >
              {{
                formFields[index].formfieldEntityText === '[VARIOUS]'
                  ? ('label_various' | translate)
                  : formFields[index].formfieldEntityText
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="newvalue">
            <th mat-header-cell *matHeaderCellDef class="new-value">
              {{ 'entity_new_value' | translate }}
              <br />
              <i>{{ 'entity_new_value_hint' | translate }}</i>
            </th>
            <td
              mat-cell
              *matCellDef="let row; let index = index"
              [matTooltip]="getControl(index, 'formfieldEntityText').value"
              showIfTruncated
            >
              <ng-container [ngSwitch]="true">
                <ng-container *ngSwitchCase="getControl(index, 'formfieldEntityType').value === 'string'">
                  <mat-form-field
                    appearance="outline"
                    class="form-field-input reset-outline-hint"
                    [ngClass]="[getControl(index, 'formfieldEntityText').dirty ? 'fo-dirty-behaviour' : '']"
                    ><input
                      matInput
                      type="text"
                      [formControl]="getControl(index, 'formfieldEntityText')"
                      (keyup)="updateField(null, index, 'formfieldEntityText')"
                    />
                    <button
                      *ngIf="getControl(index, 'formfieldEntityText').value"
                      mat-icon-button
                      matSuffix
                      class="small-icon"
                      (click)="clearField(index, 'formfieldEntityText'); $event.stopPropagation()"
                    >
                      <mat-icon>clear</mat-icon>
                    </button>
                  </mat-form-field>
                </ng-container>
                <ng-container
                  *ngSwitchCase="
                    getControl(index, 'formfieldEntityType').value === 'integer' ||
                    getControl(index, 'formfieldEntityType').value === 'bigint'
                  "
                >
                  <mat-form-field
                    appearance="outline"
                    class="form-field-input reset-outline-hint"
                    [ngClass]="[getControl(index, 'formfieldEntityValue').dirty ? 'fo-dirty-behaviour' : '']"
                    ><input
                      matInput
                      type="number"
                      [formControl]="getControl(index, 'formfieldEntityValue')"
                      (keyup)="updateField(null, index, 'formfieldEntityValue')"
                      mat-input-number
                    />
                    <button
                      *ngIf="getControl(index, 'formfieldEntityValue').value"
                      mat-icon-button
                      matSuffix
                      class="small-icon"
                      (click)="clearField(index, 'formfieldEntityValue'); $event.stopPropagation()"
                    >
                      <mat-icon>clear</mat-icon>
                    </button>
                  </mat-form-field>
                </ng-container>
                <ng-container
                  *ngSwitchCase="
                    getControl(index, 'formfieldEntityType').value === 'money' ||
                    getControl(index, 'formfieldEntityType').value === 'cost' ||
                    getControl(index, 'formfieldEntityType').value === 'factor' ||
                    getControl(index, 'formfieldEntityType').value === 'percentage'
                  "
                >
                  <mat-form-field
                    appearance="outline"
                    class="form-field-input reset-outline-hint"
                    [ngClass]="[getControl(index, 'formfieldEntityValue').dirty ? 'fo-dirty-behaviour' : '']"
                  >
                    <input
                      matInput
                      type="text"
                      [formControl]="getControl(index, 'formfieldEntityValue')"
                      (keyup)="updateFloatField(index, 'formfieldEntityValue', row)"
                      localizedNumericInput
                    />
                    <button
                      *ngIf="getControl(index, 'formfieldEntityValue').value"
                      mat-icon-button
                      matSuffix
                      class="small-icon"
                      (click)="clearField(index, 'formfieldEntityValue'); $event.stopPropagation()"
                    >
                      <mat-icon>clear</mat-icon>
                    </button>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchCase="getControl(index, 'formfieldEntityType').value === 'date'">
                  <p-calendar
                    rPCalendarMask
                    #pcalendarDatepicker
                    [ngClass]="[getControl(index, 'formfieldEntityValue').dirty ? 'fo-dirty-behaviour' : '']"
                    [formControl]="getControl(index, 'formfieldEntityValue')"
                    [selectOtherMonths]="true"
                    [showIcon]="true"
                    [showClear]="getControl(index, 'formfieldEntityValue').value != null"
                    [placeholder]="getDatePlaceholder()"
                    [dateFormat]="getDateRegionalFormat()"
                    (onSelect)="updateDate($event, index, 'formfieldEntityValue')"
                    (onClear)="clearField(index, 'formfieldEntityValue')"
                    appendTo="body"
                    ><p-footer>
                      <div class="p-datepicker-buttonbar">
                        <button
                          class="p-button-text"
                          type="button"
                          (click)="calendarOnTodayButtonClick($event, pcalendarDatepicker)"
                          pButton
                          pRipple
                        >
                          {{ 'label_today' | translate }}
                        </button>
                        <button class="p-button-text" type="button" (click)="pcalendarDatepicker.hideOverlay()" pButton pRipple>
                          {{ 'label_close' | translate }}
                        </button>
                      </div>
                    </p-footer>
                  </p-calendar>
                </ng-container>
                <ng-container *ngSwitchCase="getControl(index, 'formfieldEntityType').value === 'datetime'">
                  <p-calendar
                    rPCalendarMask
                    #pcalendarDatepicker
                    [ngClass]="[getControl(index, 'formfieldEntityValue').dirty ? 'fo-dirty-behaviour' : '']"
                    [formControl]="getControl(index, 'formfieldEntityValue')"
                    [selectOtherMonths]="true"
                    [showTime]="true"
                    [showIcon]="true"
                    [showClear]="getControl(index, 'formfieldEntityValue').value != null"
                    [placeholder]="getDatetimePlaceholder()"
                    [dateFormat]="getDatetimeRegionalFormat()"
                    (onSelect)="updateDatetime($event, index, 'formfieldEntityValue')"
                    (onClear)="clearField(index, 'formfieldEntityValue')"
                    appendTo="body"
                    ><p-footer>
                      <div class="p-datepicker-buttonbar">
                        <button
                          class="p-button-text"
                          type="button"
                          (click)="calendarOnTodayButtonClick($event, pcalendarDatepicker)"
                          pButton
                          pRipple
                        >
                          {{ 'label_today' | translate }}
                        </button>
                        <button class="p-button-text" type="button" (click)="pcalendarDatepicker.hideOverlay()" pButton pRipple>
                          {{ 'label_close' | translate }}
                        </button>
                      </div>
                    </p-footer>
                  </p-calendar>
                </ng-container>
                <ng-container *ngSwitchCase="getControl(index, 'formfieldEntityType').value === 'option'">
                  <mat-form-field
                    appearance="outline"
                    class="form-field-input reset-outline-hint"
                    [ngClass]="[getControl(index, 'formfieldEntityValue').dirty ? 'fo-dirty-behaviour' : '']"
                    ><input
                      matInput
                      type="text"
                      #optionSelect
                      [formControl]="getControl(index, 'filterStringOptionValue')"
                      (click)="$event.target.select()"
                      [matAutocomplete]="autoStringOption"
                    />
                    <mat-autocomplete
                      (opened)="matAutocompletPanelOpened()"
                      (closed)="matAutocompletPanelClosed()"
                      matAutocompleteScrollToOption
                      #autoStringOption="matAutocomplete"
                      (optionSelected)="onOptionSelectionChanged($event, index, row)"
                      (closed)="checkSelectedOption(index, row)"
                    >
                      <mat-option
                        class="form-field-option"
                        *ngFor="let opt of getFilteredOptions(index, row)"
                        [value]="opt.value"
                        [class.mat-selected]="getControl(index, 'filterStringOptionValue').value === opt.value"
                      >
                        {{ opt.value }}
                      </mat-option>
                    </mat-autocomplete>
                    <button #dropDownButton mat-icon-button matSuffix (click)="optionSelect.select()">
                      <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
                    </button>
                    <button
                      *ngIf="getControl(index, 'formfieldEntityValue').value"
                      mat-icon-button
                      matSuffix
                      class="small-icon"
                      (click)="optionSelect.select(); clearField(index, 'formfieldEntityValue'); $event.stopPropagation()"
                    >
                      <mat-icon>clear</mat-icon>
                    </button>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchCase="getControl(index, 'formfieldEntityType').value === 'foreign'">
                  <mat-form-field
                    appearance="outline"
                    class="form-field-input reset-outline-hint"
                    (click)="loadDefaultOptions(index, row)"
                    [ngClass]="[getControl(index, 'formfieldEntityValue').dirty ? 'fo-dirty-behaviour' : '']"
                  >
                    <input
                      type="text"
                      matInput
                      #foreignSelect
                      [formControl]="getControl(index, 'formfieldEntityText')"
                      (matInputAutocompleteFromApi)="foreignEntityOptions = $event"
                      [debounceTime]="600"
                      maxlength="1500"
                      [entityKind]="row.formfieldEntityKind"
                      (blur)="checkSelectedForeignOption(index)"
                      [matAutocomplete]="autoForeignOption"
                    />
                    <mat-autocomplete
                      (opened)="matAutocompletPanelOpened()"
                      (closed)="matAutocompletPanelClosed()"
                      matAutocompleteScrollToOption
                      #autoForeignOption="matAutocomplete"
                      autoActiveFirstOption
                      (optionSelected)="onForeignOptionSelectionChanged($event.option.value, index)"
                    >
                      <mat-option
                        class="form-field-option"
                        *ngFor="let opt of foreignEntityOptions"
                        [value]="opt.entityName"
                        [class.mat-selected]="getControl(index, 'formfieldEntityText').value === opt.entityName"
                      >
                        {{ opt.entityName }}
                      </mat-option>
                    </mat-autocomplete>
                    <button
                      *ngIf="getControl(index, 'formfieldEntityValue').value"
                      mat-icon-button
                      matSuffix
                      class="small-icon"
                      (click)="
                        clearField(index, 'formfieldEntityValue');
                        clearField(index, 'formfieldEntityText');
                        foreignSelect.select();
                        loadDefaultOptions(index, row);
                        $event.stopPropagation()
                      "
                    >
                      <mat-icon>clear</mat-icon>
                    </button>
                    <button mat-icon-button matSuffix class="small-icon" (click)="openForeignDialog(index, row); $event.stopPropagation()">
                      <mat-icon>search</mat-icon>
                    </button>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchCase="getControl(index, 'formfieldEntityType').value === 'boolean'">
                  <mat-slide-toggle
                    color="primary"
                    (change)="onChangeBoolean(index, $event)"
                    [checked]="
                      getControl(index, 'formfieldEntityValue').value != null && getControl(index, 'formfieldEntityValue').value === 'true'
                    "
                  ></mat-slide-toggle>
                </ng-container>
                <ng-container *ngSwitchDefault></ng-container>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="buttons-container">
    <button mat-stroked-button (click)="$event.stopPropagation(); cancel()" style="margin-right: 1rem" color="primary">
      {{ 'label_cancel' | translate }}
    </button>
    <button mat-flat-button (click)="$event.stopPropagation(); save()" color="primary" [disabled]="!controls?.dirty">
      {{ 'label_save' | translate }}
    </button>
  </div>
</div>
