<div class="reset-password">
  <div class="form-wrapper">
    <!-- <img class="brand-logo" src="./../../../../assets/imgs/PoweredByFinOffice.png" alt="BST" /> -->
    <img *ngIf="headerImage" class="brand-logo" [src]="transform(headerImage)" alt="BST" />
    <!--<div class="divider">
      <hr class="line" />
    </div>-->
    <div *ngIf="!(isSubmitted && isResetted); else pwdChanged">
      <span class="modal-title">{{ 'resetpwd_title' | translate }}</span>

      <form class="reset-password-form" [formGroup]="resetPwdForm" (ngSubmit)="resetPwd()">
        <mat-form-field class="password-input">
          <input
            type="password"
            formControlName="newPassword"
            matInput
            class="form-control"
            placeholder="{{ 'resetpwd_placeholder_newpwd' | translate }}"
            autocomplete="new-password"
          />
        </mat-form-field>
        <mat-error *ngIf="formControls.newPassword.errors">
          <div *ngFor="let errorMessage of getErrorMessageNewPassword()">
            {{ errorMessage | translate }}
          </div>
        </mat-error>
        <mat-form-field class="password-input">
          <input
            type="password"
            formControlName="confirmNewPassword"
            matInput
            placeholder="{{ 'resetpwd_placeholder_confirmpwd' | translate }}"
            autocomplete="new-password"
          />
        </mat-form-field>
        <mat-error *ngIf="formControls.confirmNewPassword.errors">
          <div *ngFor="let errorMessage of getErrorMessageConfirmNewPassword()">
            {{ errorMessage | translate }}
          </div>
        </mat-error>
        <button mat-flat-button color="primary" [disabled]="isSubmitted && isResetted">
          {{ 'resetpwd_button' | translate }}
        </button>
        <div style="width: 100%" *ngIf="isSubmitted && error">
          <app-alert status="error" [message]="error.errorKey | translate"></app-alert>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #pwdChanged>
  <app-alert status="success" [message]="'resetpwd_message_success' | translate"></app-alert>
</ng-template>
