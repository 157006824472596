import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component, Inject, PLATFORM_ID, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { DomSanitizer, Title } from '@angular/platform-browser';
// Google Analytics
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { ActivationEnd, Router, RouterOutlet } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { combineLatest, empty, interval } from 'rxjs';
import { catchError, filter, startWith, switchMap, takeWhile } from 'rxjs/operators';
import { PrimeNGTranslations } from 'src/assets/i18n/primeng/primeng-translations';
import { environment } from '../environments/environment';
import { ApiPath } from './configs/api-paths';
import { ResponsiveBreakpointsService, SideMenuContentComponent, SideMenuService } from './core';
import {
  AuthLicenseRenewalDialogComponent,
  AuthLicenseRenewalDialogData,
} from './core/auth/auth-license-renewal-dialog/auth-license-renewal-dialog.component';
import { AuthUser } from './core/auth/auth-user';
import { FinesseChatbotComponent } from './core/finesse-chatbot/finesse-chatbot.component';
import { BaseResponse } from './models/base-response';
import { BannerSettings } from './models/preference-settings';
import { AuthStoreAction, AuthStoreSelectors, RootStoreState } from './root-store';
import { ErrorHandlingStoreAction, ErrorHandlingStoreSelectors } from './root-store/error-handling-store';
import { FinesseChatbotInterfaceStoreAction, FinesseChatbotInterfaceStoreSelectors } from './root-store/finesse-chatbot-interface.store';
import { ImageFullscreenViewerStoreAction, ImageFullscreenViewerStoreSelectors } from './root-store/image-fullscreen-viewer-store';
import { PreferencesAction, PreferencesSelectors } from './root-store/preferences-store';
import { RestrictedAuthStoreSelectors } from './root-store/restricted-auth-store';
import { TokenService } from './services/auth/token.service';
import { LogService } from './services/log-service';
import { BrowserTitleService } from './services/utils/browser-title.service';
import { BaseComponent } from './shared/base-components/base-component';

interface SvgIcons {
  iconName: string;
  iconPath: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['../styles/app.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends BaseComponent implements AfterContentInit {
  @ViewChild('drawerContainer', { static: true }) drawerContainer: MatSidenavContainer;
  @ViewChild('sideMenu', { static: true }) sideMenu: MatSidenav;
  @ViewChild('sideMenuContent') sideMenuContent: SideMenuContentComponent;
  @ViewChild('configurationOutlet') configurationOutlet: RouterOutlet;
  @ViewChild('helpOutlet') helpOutlet: RouterOutlet;
  @ViewChild('usecasesOutlet') usecasesOutlet: RouterOutlet;

  languageStorageKey = 'app-language';
  languageAuthStorageKey = 'app-language-auth';
  defaultLang = 'en';
  storedLang: string;
  svgIcons: SvgIcons[] = [
    { iconName: 'ms-excel', iconPath: '../assets/imgs/ms-excel.svg' },
    // { iconName: 'spreadsheet', iconPath: '../assets/imgs/spreadsheet.svg' },
  ];
  bannerSettings: BannerSettings;

  notifications = [];
  messages = [];
  openMenu = false;

  authUser: AuthUser;
  isAuthenticated = false;
  isRestrictedAuthenticated = false;
  isRestrictedPage = true;
  isServerUnreachableError = false;
  isTenantPrefixBrowserTab = false;

  // Fullscreen image viewer
  showPreview = false;
  imageObject: Array<object>;

  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dateAdapter: DateAdapter<any>,
    private datetimeAdapter: NgxMatDateAdapter<any>,
    private tokenService: TokenService,
    private store: Store<RootStoreState.State>,
    private responsiveService: ResponsiveBreakpointsService,
    public sideMenuService: SideMenuService,
    public browserTitleService: BrowserTitleService,
    private translate: TranslateService,
    private http: HttpClient,
    private config: PrimeNGConfig,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title,
    public vcRef: ViewContainerRef //Used by color picker dialog
  ) {
    super();
    // Favicon
    this._document.getElementById('appFavicon').setAttribute('href', environment.favicon);
    this.titleService.setTitle(environment.defaultTitle);
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(this.defaultLang);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.storedLang = sessionStorage.getItem(this.languageStorageKey);
    if (this.storedLang) {
      this.translate.use(this.storedLang);
      this.config.setTranslation(PrimeNGTranslations.getTranslation(this.storedLang));
    } else {
      this.translate.use(this.defaultLang);
      this.config.setTranslation(PrimeNGTranslations.getTranslation(this.defaultLang));
    }

    for (let i of this.svgIcons) {
      const res = this.addSvgIcon(i.iconName, i.iconPath);
      if (!res) {
        break;
      }
    }
  }

  async addSvgIcon(iconName: string, iconPath: string) {
    const loaded = await this.getIconPathWithFallback(iconPath);
    if (loaded) {
      this.matIconRegistry.addSvgIcon(iconName, this.domSanitizer.bypassSecurityTrustResourceUrl(iconPath));
    } else {
      this.store.dispatch(ErrorHandlingStoreAction.setSvgLoadError());
    }
    return loaded;
  }

  // use a get request to see if the svg exists, if not return a default svg
  async getIconPathWithFallback(iconPath: string): Promise<boolean> {
    try {
      const response = await this.http.head(iconPath, { responseType: 'blob' }).toPromise();
      // if the svg icon is found the response will have type svg. If file not found returns html
      console.log('response type', response.type);
      return response.type === 'image/svg+xml';
    } catch (error) {
      return false;
    }
  }

  ngAfterContentInit(): void {
    this.sideMenuService.sidenav = this.sideMenu;
    this.sideMenuService.drawerContainer = this.drawerContainer;
    this.store.dispatch(
      AuthStoreAction.getInitialAuthStatus({
        token: this.tokenService.getAuthToken(),
      })
    );
    console.log('STARTING URL', this.router.url);

    if (isPlatformBrowser(this.platformId)) {
      this.openMenu = false;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd && event.snapshot.component) {
        const configuration = event.snapshot.data && event.snapshot.data.isConfiguration ? event.snapshot.data.isConfiguration : false;
        const currentTitle =
          event.snapshot.data && event.snapshot.data.authTitle ? this.translate.instant(event.snapshot.data.authTitle) : null;
        if (event.snapshot.data && event.snapshot.data.authTitle) {
          this.store.dispatch(
            FinesseChatbotInterfaceStoreAction.changeFinesseSource({
              source: event.snapshot.data.authTitle,
            })
          );
        }
        const isMultiKindTree = event.snapshot.data && event.snapshot.data.isMultiKindTree ? event.snapshot.data.isMultiKindTree : false;
        if (currentTitle) {
          if (this.isTenantPrefixBrowserTab && this.authUser) {
            this.browserTitleService.currentTitle = configuration
              ? `${this.authUser.tenantdescription}: ${this.translate.instant('configuration_configuration')} - ${currentTitle}`
              : `${this.authUser.tenantdescription}: ${currentTitle}`;
          } else {
            this.browserTitleService.currentTitle = configuration
              ? `${this.translate.instant('configuration_configuration')} - ${currentTitle}`
              : currentTitle;
          }
          this.browserTitleService.isMultiKindTree = isMultiKindTree;
          this.titleService.setTitle(this.browserTitleService.currentTitle);
        }
        this.isRestrictedPage = event.snapshot.data && event.snapshot.data.isRestrictedPage ? event.snapshot.data.isRestrictedPage : false;
        this.isAuthenticated && !this.isRestrictedPage ? this.sideMenuService.sidenav.open() : this.sideMenuService.sidenav.close();
      }
    });

    this.subscribe(
      combineLatest([
        this.store.select(AuthStoreSelectors.selectIsAuthenticated),
        this.store.select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin),
        this.store.select(RestrictedAuthStoreSelectors.selectIsRestrictedAuthenticated),
      ]),
      ([isAuth, isAdminAuth, isRestrictedAuth]) => {
        if ((isAuth || isAdminAuth) !== this.isAuthenticated && !isRestrictedAuth) {
          this.reloadLanguageWhenAuthIsChanged(isAuth || isAdminAuth, null);
          if (isAuth) {
            this.subscribe(this.store.pipe(select(PreferencesSelectors.selectLicenseRenewal)), (settingLicenseRenewal) => {
              if (settingLicenseRenewal != null) {
                this.dialog.open(AuthLicenseRenewalDialogComponent, {
                  disableClose: true,
                  width: '800px',
                  data: { settingLicenseRenewal } as AuthLicenseRenewalDialogData,
                });
              }
            });
          }
        }
        if (isRestrictedAuth) {
          this.translate.use(this.defaultLang);
          this.config.setTranslation(PrimeNGTranslations.getTranslation(this.defaultLang));
        }

        this.isRestrictedAuthenticated = isRestrictedAuth;
        this.isAuthenticated = isAuth || isAdminAuth;
        // this.isAuthenticated && !this.isRestrictedPage ? this.sideMenuService.sidenav.open() : this.sideMenuService.sidenav.close();
        if (this.isAuthenticated) {
          this.store.dispatch(PreferencesAction.loadFlyingButtonSettings({ isTenantAdmin: isAdminAuth }));
        } else {
          this.browserTitleService.currentTitle = environment.defaultTitle;
          this.browserTitleService.isMultiKindTree = false;
          this.titleService.setTitle(this.browserTitleService.currentTitle);
        }
        if (isAuth) {
          this.store.dispatch(PreferencesAction.loadEnabledPaths());
        }
        this.store.dispatch(PreferencesAction.loadPreferenceSettings({ isAuth, isTenantAdmin: isAdminAuth }));
        if (!this.isAuthenticated && this.configurationOutlet) {
          this.configurationOutlet.deactivate();
        }
        if (!this.isAuthenticated && this.helpOutlet) {
          this.helpOutlet.deactivate();
        }
        if (!this.isAuthenticated && this.usecasesOutlet) {
          this.usecasesOutlet.deactivate();
        }
      }
    );

    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectTenantPrefixBrowserTab)), (settingTenantPrefixBrowserTab) => {
      this.isTenantPrefixBrowserTab = settingTenantPrefixBrowserTab;
    });

    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectAuthUser)), (authUser) => {
      this.authUser = authUser;
      // if (this.authUser) {
      //   this.showWelcomMessage();
      // }
    });

    this.subscribe(this.store.pipe(select(PreferencesSelectors.selectPreferencesBannerSettings)), (bannerSettings) => {
      this.bannerSettings = bannerSettings;
    });

    this.subscribe(this.store.select(ErrorHandlingStoreSelectors.selectServerUnreachableErrorOccurred), (isError) => {
      this.isServerUnreachableError = isError;
      if (this.isServerUnreachableError) {
        this.subscribe(
          interval(environment.serverUnreachablePollingIntervall).pipe(
            startWith(0),
            switchMap(() =>
              this.http.get<BaseResponse<boolean>>(environment.getEndpoint(ApiPath.Public.IS_ALIVE)).pipe(
                catchError((error) => {
                  console.log(error);
                  return empty(); // or return of(error) and do sth about it in the subscribe body
                })
              )
            ),
            takeWhile((response) => response && response.data)
          ),
          (response) => {
            if (response && response.data) {
              this.store.dispatch(ErrorHandlingStoreAction.resetServerUnreachableError());
              window.location.reload();
            }
          }
        );
      }
    });

    this.subscribe(this.store.select(ImageFullscreenViewerStoreSelectors.selectImage), (imageObject) => {
      this.imageObject = imageObject;
      this.showPreview = true;
    });

    this.subscribe(this.store.select(FinesseChatbotInterfaceStoreSelectors.selectFinesseChatbotInterfaceOpenChatbot), (open) => {
      if (open) {
        const position: DialogPosition = { bottom: '0', right: '0' };
        this.dialog.open(FinesseChatbotComponent, {
          autoFocus: false,
          width: '410px',
          height: '70%',
          panelClass: 'finesse-chatbot',
          hasBackdrop: false,
          disableClose: true,
          position,
        });
      }
    });

    this.responsiveService.responsiveSubject.pipe(filter((breakpoint) => breakpoint.screen === 'xs-or-sm')).subscribe((breakpoint) => {
      if (!this.isRestrictedPage) {
        if (breakpoint.active) {
          // this.userProfileVisible = false;
          this.sideMenuService.sidenav.mode = 'side';
          this.sideMenuService.sidenav.open();
          this.sideMenuService.sidemenuExpanded = false;
          this.sideMenuContent.collapseMenuItems();
        } else {
          // this.userProfileVisible = true;
          this.sideMenuService.sidenav.mode = 'side';
          if (this.isAuthenticated) {
            this.sideMenuService.sidenav.open();
            this.sideMenuService.sidemenuExpanded = true;
          }
        }
      }
    });

    this.subscribe(this.store.select(PreferencesSelectors.selectPreferencesLanguageRegion), (langRegion) => {
      LogService.debug(this, this.ngAfterContentInit.name, 'Language and Region', langRegion);
      if (!this.isRestrictedAuthenticated) {
        if (langRegion && langRegion.language && langRegion.region) {
          this.dateAdapter.setLocale(langRegion.region);
          this.datetimeAdapter.setLocale(langRegion.region);
          this.reloadLanguageWhenAuthIsChanged(this.isAuthenticated, langRegion.language);
        } else if (langRegion && langRegion.language) {
          this.reloadLanguageWhenAuthIsChanged(this.isAuthenticated, langRegion.language);
        } else {
          // Default language in case of errors
          this.translate.use('en');
          this.config.setTranslation(PrimeNGTranslations.getTranslation('en'));
          this.dateAdapter.setLocale('en');
          this.datetimeAdapter.setLocale('en');
          sessionStorage.setItem(this.languageStorageKey, 'en');
        }
      }
    });
  }

  private reloadLanguageWhenAuthIsChanged(isAuth: boolean, lang: string) {
    const appLangAuth = sessionStorage.getItem('app-language-auth');
    if (appLangAuth) {
      const storedLang = appLangAuth.split('-')[0];
      const authL = appLangAuth.split('-')[1];
      if (lang != null && lang !== storedLang) {
        this.subscribe(this.translate.reloadLang(lang), () => {
          this.translate.use(lang);
          this.config.setTranslation(PrimeNGTranslations.getTranslation(lang));
        });
      } else {
        if (isAuth && authL === 'public') {
          this.subscribe(this.translate.reloadLang(storedLang), () => {
            this.translate.use(storedLang);
            this.config.setTranslation(PrimeNGTranslations.getTranslation(storedLang));
          });
        } else if (!isAuth && authL === 'auth') {
          this.subscribe(this.translate.reloadLang(storedLang), () => {
            this.translate.use(storedLang);
            this.config.setTranslation(PrimeNGTranslations.getTranslation(storedLang));
          });
        }
      }
    } else if (lang != null) {
      this.translate.use(lang);
      this.config.setTranslation(PrimeNGTranslations.getTranslation(lang));
    }
  }

  closeEventHandler() {
    this.showPreview = false;
    this.store.dispatch(ImageFullscreenViewerStoreAction.resetImage());
  }

  get Environment() {
    return environment;
  }
}
