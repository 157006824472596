import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FileGridNode } from 'src/app/models/file-grid-node';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { FileTableComponent } from '../file-table/file-table.component';
import { FileTreeComponent, TreeEntity } from '../file-tree/file-tree.component';
import { FileTabType } from './file-tab-type';

@Component({
  selector: 'app-file-tab',
  templateUrl: './file-tab.component.html',
  styleUrls: ['./file-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileTabComponent extends FormatComponent implements OnInit {
  @ViewChild(FileTableComponent) fileTable: FileTableComponent;
  @ViewChild(FileTreeComponent) fileTree: FileTreeComponent;
  @Input() externalSidenavOpened = false;
  @Input() tabType: FileTabType;
  @Input() fileDetailsEvent: Observable<void>;
  @Input() isModal = false;
  @Input() rootNodeLabel = 'label_root';
  @Output() fileSelected = new EventEmitter<FileGridNode>();
  @Output() treeNodeClickEvEm = new EventEmitter<void>();

  selectedTreeNode: TreeEntity;

  eventsSubject: Subject<TreeEntity> = new Subject<TreeEntity>();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (this.tabType === null || this.tabType === undefined) {
      throw new TypeError('The input ‘tabType’ is required');
    }
    if (this.fileDetailsEvent) {
      this.subscribe(this.fileDetailsEvent, () => {
        if (this.selectedTreeNode) {
          this.eventsSubject.next(this.selectedTreeNode);
        }
      });
    }
    this.cdRef.detectChanges();
  }

  treeNodeClick(entity: TreeEntity) {
    const m = this.treeNodeClick.name;

    LogService.info(this, m, 'On Node Clicked', entity);
    this.selectedTreeNode = entity;
    this.eventsSubject.next(entity);
    this.treeNodeClickEvEm.emit();
  }
}
