import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsDialogData, TranslationsModalDialogComponent } from '../translations-modal-dialog/translations-modal-dialog.component';

@Component({
  selector: 'app-translations-input-dialog-control',
  templateUrl: './translations-input-dialog-control.component.html',
  styleUrls: ['./translations-input-dialog-control.component.scss'],
})
export class TranslationsInputDialogControlComponent implements OnInit {
  @Input() keyvaluesTranslations: KeyValue<string, string>[];
  @Input() isViewMode = false;
  @Input() isDisabled = false;
  @Input() isDirtyEnabled = false;
  @Output() changedValueEvEm = new EventEmitter<string>();

  DIRTY_CLASSNAME = 'fo-dirty-behaviour';

  actualLanguageTranslation: string;
  originalTranslations: KeyValue<string, string>[];
  originalTableTranslations: KeyValue<string, string>[];
  currentLang: string;

  displayedColumnsTable = ['language', 'translation'];
  controls: UntypedFormArray;

  constructor(private dialog: MatDialog, private translate: TranslateService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.originalTranslations = JSON.parse(JSON.stringify(this.keyvaluesTranslations));
    this.actualLanguageTranslation = this.keyvaluesTranslations
      ? this.keyvaluesTranslations.find((kv) => kv.key === this.currentLang)?.value
      : null;
  }

  isDirtyTranslations() {
    return JSON.stringify(this.keyvaluesTranslations) !== JSON.stringify(this.originalTranslations);
  }

  setActualLanguageTraslation() {
    this.keyvaluesTranslations.find((kv) => kv.key === this.currentLang).value = this.actualLanguageTranslation;
    this.changedValueEvEm.emit(this.actualLanguageTranslation);
  }

  /* DIALOG */

  // openTranslationsDialog(ref: TemplateRef<any>) {
  //   this.initForm();
  //   this.originalTableTranslations = JSON.parse(JSON.stringify(this.keyvaluesTranslations));
  //   this.dialog.open(ref, { autoFocus: false, disableClose: true, width: '800px' });
  // }

  openTranslationsDialog() {
    // this.initForm();
    this.originalTableTranslations = JSON.parse(JSON.stringify(this.keyvaluesTranslations));
    this.dialog
      .open(TranslationsModalDialogComponent, {
        autoFocus: false,
        disableClose: true,
        width: '800px',
        panelClass: 'translations-dialog',
        data: {
          keyvaluesTranslations: this.keyvaluesTranslations,
          originalTranslations: this.originalTranslations,
          isViewMode: this.isViewMode || this.isDisabled,
          isDirtyEnabled: this.isDirtyEnabled,
        } as TranslationsDialogData,
      })
      .afterClosed()
      .subscribe((translations) => {
        if (translations) {
          this.keyvaluesTranslations = JSON.parse(JSON.stringify(translations));
          this.actualLanguageTranslation = this.keyvaluesTranslations.find((kv) => kv.key === this.currentLang)?.value;
          this.changedValueEvEm.emit(this.actualLanguageTranslation);
        } else {
          this.keyvaluesTranslations = JSON.parse(JSON.stringify(this.originalTableTranslations));
          this.actualLanguageTranslation = this.keyvaluesTranslations.find((kv) => kv.key === this.currentLang)?.value;
        }
      });
  }

  // onApply() {
  //   this.actualLanguageTranslation = this.keyvaluesTranslations.find((kv) => kv.key === this.currentLang)?.value;
  //   this.changedValueEvEm.emit();
  // }

  // onCancel() {
  //   this.keyvaluesTranslations = JSON.parse(JSON.stringify(this.originalTableTranslations));
  //   this.actualLanguageTranslation = this.keyvaluesTranslations.find((kv) => kv.key === this.currentLang)?.value;
  // }

  // isValorized(value: string): boolean {
  //   return value !== null && value !== undefined && value !== '';
  // }

  // updateField($event, index: number, field: string, key: string) {
  //   const control = this.getControl(index, field);
  //   if ($event && this.keyvaluesTranslations[index].value !== $event) {
  //     control.markAsDirty();
  //   }
  //   this.keyvaluesTranslations[index].value = $event ? $event : control.value; // When enter is pressed, value is passed by event emitter
  //   if (this.controls.valid && this.controls.dirty) {
  //     this.actualLanguageTranslation = this.keyvaluesTranslations.find((kv) => kv.key === this.currentLang)?.value;
  //     // this.dirtyEvEm.emit(this.keyvaluesTranslations);
  //   }
  // }

  // resetField(index: number, field: string, key: string) {
  //   const originalValue = this.keyvaluesTranslations[index].value;
  //   this.getControl(index, field).setValue(originalValue);
  //   this.actualLanguageTranslation = this.keyvaluesTranslations.find((kv) => kv.key === this.currentLang)?.value;
  // }

  // getControl(index: number, fieldName: string) {
  //   return this.controls.at(index).get(fieldName) as FormControl;
  // }

  // private initForm() {
  //   const toGroups = this.keyvaluesTranslations.map((keyvalue) => {
  //     return new FormGroup(
  //       {
  //         language: new FormControl(keyvalue.key, Validators.required),
  //         translation: new FormControl(keyvalue.value, Validators.required),
  //       },
  //       { updateOn: 'blur' }
  //     );
  //   });
  //   this.controls = new FormArray(toGroups);
  //   if (this.isDirtyTranslations()) {
  //     this.keyvaluesTranslations.forEach((kv, index) => {
  //       if (this.originalTranslations[index].value !== kv.value) {
  //         this.getControl(index, 'translation').markAsDirty();
  //       }
  //     });
  //   }
  // }
}
