import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entities } from 'src/app/configs/entities';
import { Entity } from 'src/app/models/entity';
import { Note, NoteParams } from 'src/app/models/note';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { LogService } from 'src/app/services/log-service';
import { NoteDataService } from 'src/app/services/note-data.service';
import { FormatComponent } from '../../base-components/format-component';
import {
  GenericEntityWizardModalDialogComponent,
  GenericEntityWizardModalDialogData,
} from '../generic-entity-wizard-modal-dialog/generic-entity-wizard-modal-dialog.component';

@Component({
  selector: 'app-generic-note',
  templateUrl: './generic-note.component.html',
  styleUrls: ['./generic-note.component.scss'],
})
export class GenericNoteComponent extends FormatComponent implements OnInit {
  @Input() entitySubj: Observable<Entity>;
  notes: Note[];
  isLoading = false;
  noEntityData = false;
  selectedEntity: Entity;
  isCreateEnabled = false;

  //request
  request: PaginatedRequest;
  filter: string;
  lastUsedFilters: string[];

  clearFilterSubject: Subject<void> = new Subject<void>();
  filtersMapSubject: BehaviorSubject<Map<string, any[]>> = new BehaviorSubject<Map<string, any[]>>(null);
  filtersMap = new Map<string, any[]>();

  constructor(private noteDataService: NoteDataService, private dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.entitySubj, (entity) => {
      if (entity) {
        this.selectedEntity = entity;
        this.initRequest();
        this.loadNotes();
      }
    });
  }

  loadNotes() {
    this.isLoading = true;
    this.noEntityData = false;
    this.subscribe(
      this.noteDataService.getNotes(this.selectedEntity.entityKind, this.selectedEntity.entityId, this.request),
      (response) => {
        this.setFlagsByResponse(response);
        if (response.data) {
          this.notes = response.data;
          this.isLoading = false;
        } else {
          this.notes = null;
          this.isLoading = false;
          this.noEntityData = true;
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      }
    );
  }

  onAdd() {
    const dialogRef = this.dialog.open(GenericEntityWizardModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'generic-entity-wizard-modal-dialog',
      disableClose: true,
      data: {
        wizardName: Entities.NOTE,
        relatedEntityIds: [this.selectedEntity.entityId],
        relatedEntityKinds: [this.selectedEntity.entityKind],
        isDocumentsTabEnabled: this.isDocumentEnabled,
      } as GenericEntityWizardModalDialogData,
    });
    this.subscribe(dialogRef.afterClosed(), (entity) => {
      if (entity) {
        this.loadNotes();
      }
    });
  }

  // Filters
  initRequest() {
    this.filter = '';
    this.request = {
      pageIndex: null,
      pageSize: null,
      orderBy: null,
      sort: null,
      filter: this.filter,
      filterMap: this.filtersMap,
    };
  }

  isFiltersSetted(): boolean {
    return this.filtersMap.size > 0 || this.filter != '';
  }

  applyFilterTable(filterValue: string) {
    if (filterValue && filterValue.length >= 3) {
      this.setFilter(filterValue);
      this.loadNotes();
    } else {
      if (!filterValue || (filterValue && filterValue.length === 0)) {
        this.setFilter(null);
        this.loadNotes();
      }
    }
  }

  clearFilters() {
    this.clearFilterSubject.next();
    // this.filtersMap = this.filtersMapStatic;
    this.filtersMap.clear();
    LogService.debug(this, this.clearFilters.name, 'FiltersMap', this.filtersMap);
    this.resetFilter();
    this.initRequest();
    this.loadNotes();
  }

  resetFilter() {
    if (this.filter) {
      this.filter = '';
      this.setFilter(null);
    }
  }

  clearSearchFilter() {
    this.resetFilter();
    this.initRequest();
    this.loadNotes();
  }

  setFiltersIds($event: number[], filterName: string) {
    if ($event && $event.length > 0) {
      this.filtersMap.set(filterName, $event);
    } else {
      this.filtersMap.delete(filterName);
    }
    LogService.debug(this, this.clearFilters.name, 'FiltersMap', this.filtersMap);
    // if (this.paginatorTable) {
    //   this.paginatorTable.pageIndex = 0;
    // }
    this.request = { ...this.request, pageIndex: 0, filterMap: this.filtersMap };
    this.loadNotes();
  }

  setFilter(filter: string) {
    // if (this.paginatorTable) {
    //   this.paginatorTable.pageIndex = 0;
    // }
    this.request = {
      ...this.request,
      pageIndex: 0,
      filter,
    };
  }

  get NoteParams() {
    return NoteParams;
  }

  get ApiPath() {
    return ApiPath;
  }
}
