import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { AddBillingitemsWizardDataService } from 'src/app/services/wizards/add-billingitems-wizard.service';
import * as ReplaceBillingitemsWizardActions from './actions';
import { selectInitStep } from './selectors';
import { State } from './state';

@Injectable()
export class ReplaceBillingitemsWizardEffects {
  // loadReplaceProductWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(ReplaceBillingitemsWizardActions.selectInitStep),
  //       tap(() => this.router.navigateByUrl('/pages/allocation-wizards/order'))
  //     ),
  //   { dispatch: false }
  // );
  // exitReplaceBillingitemsAllocationWizards$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(ReplaceBillingitemsWizardActions.exitAllocationReplaceBillingitemsWizard),
  //       tap(() => this.router.navigateByUrl('/pages/consumers'))
  //     ),
  //   { dispatch: false }
  // );

  newReplaceBillingitems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReplaceBillingitemsWizardActions.createNewReplaceBillingitems),
      withLatestFrom(this.store.select(selectInitStep)),
      concatMap(([{ request }, initStep]) =>
        this.addBillingitemsWizardService.replaceBillingitems(initStep.billingitemId, request).pipe(
          map((response) => {
            if (response) {
              return ReplaceBillingitemsWizardActions.createNewReplaceBillingitemsSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            let errorMessage = '';
            if (error instanceof HttpErrorResponse) {
              errorMessage = error.error.errorKey;
            } else {
              errorMessage = error;
            }
            return of(ReplaceBillingitemsWizardActions.createNewReplaceBillingitemsFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private addBillingitemsWizardService: AddBillingitemsWizardDataService
  ) {}
}
