import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { Entities } from 'src/app/configs/entities';
import {
  ForeignEntity,
  ForeignEntityDynamicDataSource,
  ForeignEntityDynamicDatabase,
  ForeignEntityDynamicFlatNode,
  ForeignEntityRequest,
} from 'src/app/models/foreign-entity';
import { RootStoreState } from 'src/app/root-store';
import { GridBaseComponent } from 'src/app/shared/base-components/grid-base-component';

export interface CustomConfigurableModalData {
  isMultiselection: boolean;
  labelName: string;
  disableSorting?: boolean;
  basePath?: string;
  basePathParams?: string;
  gridColumnsConfig?: CustomConfigurableModalColumn[];
  gridFiltersConfig?: CustomConfigurableModalFilter[];
  gridFiltersConfigDefaultValues?: CustomConfigurableModalFilterDefault[];
  enableFilterRules?: boolean;
  kind?: string;
  linkableFlag?: boolean;
  filterIds?: any[];
  filterName?: string;
  filterEntityKind?: string;
  filterEntityId?: number;
  filterColumn1?: string;
  filterColumn1Values?: number[];
  filterColumn2?: string;
  filterColumn2Values?: number[];
  baseEntityKind?: string;
  baseEntityId?: number;
  excludeIds?: number[];
  forceTreeView?: boolean;
}

export class CustomConfigurableModalColumn {
  entityField: string;
  columnTitleKey: string;
  columnType: string;
  columnWidth: string;
  isOrderByActive?: boolean;
  isOrdesAsc?: boolean;
}

export class CustomConfigurableModalFilter {
  filterTitle: string;
  filterApiPath: string;
  filterParams: string;
  isDateFilter?: boolean;
}

export class CustomConfigurableModalFilterDefault {
  filterParams: string;
  defaultValue: any[];
}

@Component({
  selector: 'app-custom-configurable-modal',
  templateUrl: './custom-configurable-modal.component.html',
  styleUrls: ['./custom-configurable-modal.component.scss'],
  providers: [ForeignEntityDynamicDatabase],
})
export class CustomConfigurableModalComponent extends GridBaseComponent {
  @ViewChildren('paginatorTable') Paginator: QueryList<MatPaginator>;
  @ViewChildren('sortTable') SortTable: QueryList<MatSort>;

  constructor(
    private database: ForeignEntityDynamicDatabase,
    public dialogRef: MatDialogRef<CustomConfigurableModalComponent>,
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: CustomConfigurableModalData
  ) {
    super(store, cdRef);
  }

  request: ForeignEntityRequest;
  selectedEntity: ForeignEntity;

  dataSourceTable: MatTableDataSource<ForeignEntity> = new MatTableDataSource<ForeignEntity>();
  displayedColumnsTable: string[];
  selectedColumnForOrdering: CustomConfigurableModalColumn;

  isGridView = true;
  isGridTreeSelectionActive = true;
  //tree
  showTreeToggle = true;
  dataSource: ForeignEntityDynamicDataSource;
  treeControl: FlatTreeControl<ForeignEntityDynamicFlatNode>;
  displayedColumnsTreeTable: string[];

  async ngOnInit() {
    const columns = [];
    if (!this.data.gridColumnsConfig) {
      this.data.gridColumnsConfig = this.getDefaultColumnsConfiguration();
    }

    this.data.gridColumnsConfig.forEach((column) => {
      columns.push(column.entityField);
      if (column.isOrderByActive) {
        this.selectedColumnForOrdering = column;
      }
    });

    this.displayedColumnsTable = this.data.isMultiselection ? ['select', ...columns] : ['radio', ...columns];
    this.displayedColumnsTreeTable = [...columns];
  }

  async gbAfterViewInitTable() {
    super.gbAfterViewInitTable();
    if (this.data.kind != null) {
      const option = this.data.filterEntityKind === Entities.TYPES_KIND ? this.data.filterEntityId : null;
      this.subscribe(this.coreDataService.getEntityTreeenabledByEntityKind(this.data.kind, this.isTenantAdmin, option), ({ data }) => {
        this.isGridTreeSelectionActive = data;
        if (this.isGridTreeSelectionActive) {
          this.isGridView = !this.data.forceTreeView;
          this.gbLoadEntitiesData();
        }
      });
    } else {
      this.isGridTreeSelectionActive = false;
    }
    this.subscribe(this.Paginator.changes, (p: QueryList<MatPaginator>) => {
      this.paginatorTable = p.first;
      if (this.paginatorTable) {
        this.subscribe(this.paginatorTable.page, () => {
          this.setPage();
          this.gbLoadEntitiesData();
        });
      }
    });
    this.subscribe(this.SortTable.changes, (p: QueryList<MatSort>) => {
      this.sortTable = p.first;
      if (this.sortTable) {
        this.subscribe(this.sortTable.sortChange, () => {
          this.setOrder();
          this.gbLoadEntitiesData();
        });
      }
    });
  }

  initRequest() {
    this.filter = '';
    this.request = {
      pageIndex: 0,
      pageSize: this.pageSizeList[0],
      orderBy: this.selectedColumnForOrdering ? this.selectedColumnForOrdering.entityField : this.gbGetInitialOrderBy(),
      sort: this.selectedColumnForOrdering ? (this.selectedColumnForOrdering.isOrdesAsc ? 'asc' : 'desc') : this.gbGetInitialSort(),
      filter: this.filter,
      filterMap: this.filtersMap,
      kind: this.data.kind,
      linkableFlag: this.data.linkableFlag,
      filterIds: this.data.filterIds,
      filterName: this.data.filterName,
      filterEntityKind: this.data.filterEntityKind,
      filterEntityId: this.data.filterEntityId,
      filterColumn1: this.data.filterColumn1,
      filterColumn1Values: this.data.filterColumn1Values,
      filterColumn2: this.data.filterColumn2,
      filterColumn2Values: this.data.filterColumn2Values,
      baseEntityKind: this.data.baseEntityKind,
      baseEntityId: this.data.baseEntityId,
      excludeIds: this.data.excludeIds,
      isTenantAdmin: this.isTenantAdmin,
    };
  }

  onGridTreeSelectionChanged($event: MatRadioChange) {
    this.isGridView = $event.value;
    this.cdRef.detectChanges();
    // Table Initialization
    this.gbLoadEntitiesData();
  }

  gbSetDefaultFilters() {
    if (
      this.data &&
      this.data.gridFiltersConfigDefaultValues &&
      this.data.gridFiltersConfigDefaultValues.filter((f) => f.defaultValue != null).length > 0
    ) {
      this.data.gridFiltersConfigDefaultValues
        .filter((f) => f.defaultValue != null)
        .forEach((f) => {
          this.filtersMap.set(f.filterParams, f.defaultValue);
        });
      this.filtersMapSubject.next(this.filtersMap);
    }
  }

  gbLoadEntitiesData() {
    this.clearSelection();
    this.isLoading = true;
    if (this.isGridView) {
      this.dataSourceTable = new MatTableDataSource();
      let apiPath: string;
      if (this.data.kind) {
        apiPath = this.isTenantAdmin ? `api/tenantadministration/entities/${this.data.kind}` : `api/entities/${this.data.kind}`;
      } else {
        apiPath = this.data.basePathParams ? this.data.basePath + this.data.basePathParams : this.data.basePath;
      }
      if (this.gridLoadSubscription != null) {
        this.gridLoadSubscription.unsubscribe();
      }
      this.gridLoadSubscription = this.subscribe(
        this.coreDataService.getForeignEntity(apiPath, this.request),
        (response) => {
          this.lastUsedFilters = response.filters;
          this.dataSourceTable.data = response.data ? response.data : [];
          this.noEntityData = !response.data || response.data.length === 0;
          this.pageTotalElements = !response.data || response.data.length === 0 ? 0 : response.data[0].entityCount;
          this.isLoading = false;
          this.cdRef.detectChanges();
        },
        (error) => {
          this.isLoading = false;
          this.noEntityData = true;
        }
      );
    } else {
      this.treeControl = new FlatTreeControl<ForeignEntityDynamicFlatNode>(this.getLevel, this.isExpandable);
      this.dataSource = new ForeignEntityDynamicDataSource(this.treeControl, this.database, this.coreDataService, this.request);
      const apiPath = this.isTenantAdmin
        ? `api/tenantadministration/entities/${this.data.kind}/-1/nodes`
        : `api/entities/${this.data.kind}/-1/nodes`;
      this.subscribe(
        this.coreDataService.getForeignEntity(apiPath, this.request),
        (response) => {
          this.dataSource.data = response.data ? this.database.initialData(response.data) : [];
          this.noEntityData = !response.data || response.data.length === 0;
          this.pageTotalElements = !response.data || response.data.length === 0 ? 0 : response.data[0].entityCount;
          this.showTreeToggle = response.data ? response.data.some((e) => e.entityChilds > 0) : false;
          this.isLoading = false;
          this.cdRef.detectChanges();
        },
        (error) => {
          this.isLoading = false;
          this.noEntityData = true;
        }
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSelect() {
    if (this.data.isMultiselection) {
      this.dialogRef.close(this.selection.selected);
    } else {
      this.dialogRef.close([this.selectedEntity]);
    }
  }

  onEntitySelected(row: ForeignEntity) {
    this.selectedEntity = row;
  }

  isEntitySelected() {
    if (this.data.isMultiselection) {
      return this.selection.selected.length > 0;
    } else {
      return this.selectedEntity && this.selectedEntity.entityName != null && this.selectedEntity.entityName !== '';
    }
  }

  getDefaultColumnsConfiguration(): CustomConfigurableModalColumn[] {
    const col1 = new CustomConfigurableModalColumn();
    col1.columnType = 'string';
    col1.entityField = 'entityName';
    col1.columnTitleKey = _('entity_name');
    const col2 = new CustomConfigurableModalColumn();
    col2.columnType = 'string';
    col2.entityField = 'entityDescription';
    col2.columnTitleKey = _('entity_description');
    return [col1, col2];
  }

  getTooltipString(c: CustomConfigurableModalColumn, row: any): string {
    switch (c.columnType) {
      case 'date':
        return this.getDate(row[c.entityField]);
      case 'timestamp':
        return this.getTimestamp(row[c.entityField]);
      case 'price':
        return this.getMoney(row[c.entityField]);
      case 'factor':
        return this.getFactor(row[c.entityField]);
      case 'percentage':
        return this.getPercentageNormalized(row[c.entityField]);
      default:
        return row[c.entityField];
    }
  }

  /* TREE METHODS */
  getLevel = (node: ForeignEntityDynamicFlatNode) => node.level;

  isExpandable = (node: ForeignEntityDynamicFlatNode) => node.expandable;

  hasChild = (x: number, nodeData: ForeignEntityDynamicFlatNode) => nodeData.expandable;
}
