import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { CancelLicenseServicesRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/confirmation-step/cancel-license-services-request';
import { TerminateMultiAssetAssetStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/terminate-multi-asset-wizard/terminate-multi-asset-asset-step/terminate-multi-asset-asset-step-info';
import { TerminateMultiAssetCancelDetailsData } from 'src/app/shared-components/allocation-wizard-modal-dialogs/terminate-multi-asset-wizard/terminate-multi-asset-cancel-details-step/terminate-multi-asset-cancel-details-data';

export const selectAssetStep = createAction(
  '[Wizard Asset Step] Select Init Terminate Multi Asset Asset Step',
  props<{ assetStep: TerminateMultiAssetAssetStep }>()
);

export const selectCancelDetailsStep = createAction(
  '[Wizard Cancel Details Step] Select Terminate Multi Asset Cancel Details Step',
  props<{ cancelDetailsStep: TerminateMultiAssetCancelDetailsData[] }>()
);

export const deleteCancelDetailsStep = createAction('[Wizard CancelDetails Step] Delete Terminate Multi Asset CancelDetails Step');

export const exitTerminateMultiAssetWizard = createAction('[TerminateMultiAsset Wizard Page] Exit Terminate Multi Asset Wizard');

export const destroyWizard = createAction('[TerminateMultiAsset Wizard Page] Destroy Wizard');

export const createNewTerminateMultiAsset = createAction(
  '[Wizard Confirmation Step] Create Terminate Multi Asset Information Step',
  props<{ request: CancelLicenseServicesRequest }>()
);

export const createNewTerminateMultiAssetSuccess = createAction(
  '[Wizard Confirmation Step] Create Terminate Multi Asset Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewTerminateMultiAssetFail = createAction(
  '[Wizard Confirmation Step] Create Terminate Multi Asset Information Step Fail',
  props<{ errorMessage: string }>()
);
