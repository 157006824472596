import { Action, createReducer, on } from '@ngrx/store';
import * as MoveAssetWizardActions from './actions';
import { State, initialState } from './state';

export const moveAssetWizardFeatureKey = 'moveAssetWizard';

const moveAssetWizardReducer = createReducer(
  initialState,

  on(MoveAssetWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(MoveAssetWizardActions.selectMovingToStep, (state, { movingTo }) => ({
    ...state,
    movingTo,
  })),
  on(MoveAssetWizardActions.exitMoveAssetWizard, (state) => ({
    ...state,
    initStep: null,
    errorMessage: null,
    movingTo: null,
    request: null,
    response: null,
  })),
  on(MoveAssetWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    errorMessage: null,
    movingTo: null,
    request: null,
    response: null,
  })),
  on(MoveAssetWizardActions.createNewMoveAsset, (state, { request }) => ({
    ...state,
    request,
  })),
  on(MoveAssetWizardActions.createNewMoveAssetSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(MoveAssetWizardActions.createNewMoveAssetFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return moveAssetWizardReducer(state, action);
}
