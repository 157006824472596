import { ActionResponse } from 'src/app/models/action-response';
import { BillingItem } from 'src/app/models/billingitem';
import { AddServiceTopackageDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-service-topackage-wizard/activation-step/addservices-due-date-info';
import { AddServiceTopackageInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-service-topackage-wizard/init-step/init-step';

export interface State {
  initStep: AddServiceTopackageInitStep;
  dueDatesStep: AddServiceTopackageDueDates;
  servicesStep: BillingItem[];
  // request: ServiceParticularRequest;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  dueDatesStep: null,
  servicesStep: null,
  response: null,
  errorMessage: null,
};
