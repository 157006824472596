import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders as HttpHeadersNames } from 'src/app/configs/http-headers';
import { AuthUser } from 'src/app/core/auth/auth-user';
import { ChangePwdAttempt } from 'src/app/core/auth/change-pwd-attempt';
import { ForgotPasswordAttempt } from 'src/app/core/auth/forgot-pwd-attempt';
import { ResetPwdAttempt } from 'src/app/core/auth/reset-pwd-attempt';
import { ActionResponse } from 'src/app/models/action-response';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../../configs/api-paths';
import { AuthAttempt } from '../../core/auth/auth-attempt';
import { AuthToken, Token } from '../../core/auth/auth-token';
import { BaseResponse } from '../../models/base-response';
import { LogService } from '../log-service';
import { RestrictedTokenService } from './restricted-token.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private tokenService: TokenService, private restrictedTokenService: RestrictedTokenService) {}

  getAdminTentantCogsFlag(): Observable<BaseResponse<boolean>> {
    const m = this.authenticate.name;

    const path = environment.getEndpoint(ApiPath.TenantAdministration.TENANT_CAPABILITY);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<boolean>>(path);

    return retVal;
  }

  authenticate(data: AuthAttempt): Observable<BaseResponse<Token>> {
    const m = this.authenticate.name;

    const path = environment.getEndpoint(ApiPath.Auth.AUTH);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, 'UserLogin ' + LogService.REQUEST, data.email + ', ' + data.tenant);
    const retVal = this.http.post<BaseResponse<Token>>(path, data);

    return retVal;
  }

  authenticateTenantAdmin(data: AuthAttempt): Observable<BaseResponse<Token>> {
    const m = this.authenticate.name;

    const path = environment.getEndpoint(ApiPath.TenantAdministration.AUTH);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, 'UserLogin ' + LogService.REQUEST, data.email + ', ' + data.tenant);
    const retVal = this.http.post<BaseResponse<Token>>(path, data);

    return retVal;
  }

  setPassword(data: ChangePwdAttempt): Observable<BaseResponse<ActionResponse>> {
    const m = this.changePassword.name;

    const path = environment.getEndpoint(ApiPath.Auth.SET_PWD);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, 'SetPsw ' + LogService.REQUEST, data.email);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, data);

    return retVal;
  }

  changePassword(data: ChangePwdAttempt): Observable<BaseResponse<ActionResponse>> {
    const m = this.changePassword.name;

    const path = environment.getEndpoint(ApiPath.Auth.CHANGE_PWD);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, 'ChangePsw ' + LogService.REQUEST, data.email);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, data);

    return retVal;
  }

  oauthEndpoint(oauthIss: string): Observable<any> {
    const m = this.changePassword.name;

    const path = `${oauthIss}.well-known/openid-configuration`;
    LogService.info(this, m, LogService.GET + path, null);
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    });

    const retVal = this.http.get<Observable<any>>(path, { headers });

    return retVal;
  }

  forgotPassword(data: ForgotPasswordAttempt): Observable<BaseResponse<boolean>> {
    const m = this.forgotPassword.name;

    const path = environment.getEndpoint(ApiPath.Auth.FORGOT_PWD);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, 'ForgotPsw ' + LogService.REQUEST, data.email);
    const retVal = this.http.post<BaseResponse<boolean>>(path, data);

    return retVal;
  }

  resetPassword(token: AuthToken, data: ResetPwdAttempt): Observable<BaseResponse<ActionResponse>> {
    const m = this.resetPassword.name;

    const path = environment.getEndpoint(ApiPath.Auth.RESET_PWD);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, 'ResetPsw ' + LogService.REQUEST, data.email);
    const JWT = `${HttpHeadersNames.AUTH_BEARER} ${token.toString()}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: JWT,
      }),
    };
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, data, httpOptions);

    return retVal;
  }

  viewAsUser(userId: number): Observable<BaseResponse<Token>> {
    const m = this.authenticate.name;

    const path = environment.getEndpoint(ApiPath.Administrations.ADMIN_VIEW_AS_USER);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, 'UserId ' + LogService.REQUEST, userId);
    const params = new HttpParams().set('userId', userId);
    const retVal = this.http.post<BaseResponse<Token>>(path, null, { params });

    return retVal;
  }

  isAuthenticated(): Observable<boolean> {
    return this.tokenService.get().pipe(map((token: AuthToken) => token.isValid()));
  }

  isAdminAuthenticated(): Observable<boolean> {
    return this.tokenService.get().pipe(map((token: AuthToken) => token.isValid() && token.getUser().adminrights));
  }

  isTenantAdminAuthenticated(): Observable<boolean> {
    return this.tokenService.get().pipe(map((token: AuthToken) => token.isValid() && token.isTenantAdmin()));
  }

  isRestrictedAuthenticated(): Observable<boolean> {
    return this.restrictedTokenService.get().pipe(map((token: AuthToken) => token.isValid()));
  }

  getToken(): Observable<AuthToken> {
    return this.tokenService.get();
  }

  getRestrictedToken(): Observable<AuthToken> {
    return this.restrictedTokenService.get();
  }

  getAuthUser(): Observable<AuthUser> {
    return this.tokenService.get().pipe(map((token: AuthToken) => token.getUser()));
  }

  onTokenChange(): Observable<AuthToken> {
    return this.tokenService.tokenChange();
  }

  onAuthenticationChange(): Observable<boolean> {
    return this.onTokenChange().pipe(map((token: AuthToken) => token.isValid()));
  }
}
