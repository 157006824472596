import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TreeHeadComponent } from './tree-head.component';

@NgModule({
  declarations: [TreeHeadComponent],
  imports: [CommonModule],
  exports: [TreeHeadComponent],
})
export class TreeHeadModule {}
