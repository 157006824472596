import { createAction, props } from '@ngrx/store';
import { CancelAssetOptions } from 'src/app/models/cancel-asset-options';
import { AdditionalInfo } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/additional-step/additional-info';
import { CancelLicenseServicesRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/confirmation-step/cancel-license-services-request';
import { CancelLicenseServicesResponse } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/confirmation-step/cancel-license-services-response';
import { CancelDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/dates-step/cancel-due-dates';
import { InitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/init-step/init-step';

export const selectInitStep = createAction('[User Property Panel] Select Cancel Init Information Step', props<{ initStep: InitStep }>());

export const exitAllocationCancelWizard = createAction('[Cancel Wizard Page] Exit Allocation Cancel Wizard');

export const destroyWizard = createAction('[Cancel Wizard Page] Exit Destroy Wizard');

export const selectDueDatesStep = createAction(
  '[Wizard Due Date Step] Select Cancel Due Date Step',
  props<{ dueDatesStep: CancelDueDates }>()
);

export const selectLicenseStep = createAction(
  '[Cancel Wizard Page] Select Cancel License Services Information Step',
  props<{ licenseStep: CancelAssetOptions[] }>()
);

export const selectAdditionalStep = createAction(
  '[Cancel Wizard Page] Select Cancel Additional Information Step',
  props<{ addInfoStep: AdditionalInfo }>()
);

export const sendLicenseServicesCancelRequest = createAction(
  '[Cancel Wizard Confirmation Step] Create Cancel Information Step',
  props<{ cancelRequest: CancelLicenseServicesRequest }>()
);

export const cancelRequestSuccess = createAction(
  '[Cancel Wizard Confirmation Step] Create Cancel Information Step Success',
  props<{ response: CancelLicenseServicesResponse }>()
);

export const cancelRequestFail = createAction(
  '[Cancel Wizard Confirmation Step] Create Cancel Information Step Fail',
  props<{ errorMessage: string }>()
);
