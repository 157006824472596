import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appResponsiveBreakpoint]',
})
export class ResponsiveBreakpointDirective {
  @Input() screen: string;

  constructor(public element: ElementRef) {}
}
