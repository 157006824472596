import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { TaricsConfiguration } from '../models/interfaces/tarics/tarics-configuration';
import { TaricsEntitlements } from '../models/interfaces/tarics/tarics-entitlement-change';
import { TaricsInstance } from '../models/interfaces/tarics/tarics-instance';
import { TaricsLog } from '../models/interfaces/tarics/tarics-log';
import { TaricsRequest } from '../models/interfaces/tarics/tarics-request';
import { PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class TaricsInterfaceDataService {
  getTaricsConfigurations(request: PaginatedRequest): Observable<BaseResponse<TaricsConfiguration[]>> {
    const m = this.getTaricsConfigurations.name;

    const path = environment.getEndpoint(ApiPath.Interfaces.Tarics.TARICS_CONFIGURATIONS);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<TaricsConfiguration[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });
    return retVal;
  }

  getTaricsConfigurationsSpreadsheet(request: PaginatedRequest) {
    const m = this.getTaricsConfigurationsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Interfaces.Tarics.TARICS_CONFIGURATIONS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getTaricsLogs(request: PaginatedRequest): Observable<BaseResponse<TaricsLog[]>> {
    const m = this.getTaricsLogs.name;

    const path = environment.getEndpoint(ApiPath.Interfaces.Tarics.TARICS_LOGS);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<TaricsLog[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });
    return retVal;
  }

  getTaricsLogsSpreadsheet(request: PaginatedRequest) {
    const m = this.getTaricsLogsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Interfaces.Tarics.TARICS_LOGS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getTaricsInstances(request: PaginatedRequest): Observable<BaseResponse<TaricsInstance[]>> {
    const m = this.getTaricsInstances.name;

    const path = environment.getEndpoint(ApiPath.Interfaces.Tarics.TARICS_INSTANCES);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<TaricsInstance[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });
    return retVal;
  }

  getTaricsInstancesSpreadsheet(request: PaginatedRequest) {
    const m = this.getTaricsInstancesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Interfaces.Tarics.TARICS_INSTANCES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getTaricsRequests(request: PaginatedRequest): Observable<BaseResponse<TaricsRequest[]>> {
    const m = this.getTaricsRequests.name;

    const path = environment.getEndpoint(ApiPath.Interfaces.Tarics.TARICS_REQUESTS);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<TaricsRequest[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });
    return retVal;
  }

  getTaricsRequestsSpreadsheet(request: PaginatedRequest) {
    const m = this.getTaricsRequestsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Interfaces.Tarics.TARICS_REQUESTS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  triggerTaricsMapCollect(): Observable<BaseResponse<ActionResponse>> {
    const m = this.triggerTaricsMapCollect.name;

    const path = environment.getEndpoint(ApiPath.Interfaces.Tarics.TARICS_MAP_COLLECT);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  triggerTaricsConfigurationMapCollect(configurationId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.triggerTaricsConfigurationMapCollect.name;

    const path = environment.getEndpoint(ApiPath.Interfaces.Tarics.TARICS_CONFIGURATIONS_MAP_COLLECT(configurationId));
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  getTaricsEntitlementChanges(platformId: number): Observable<BaseResponse<TaricsEntitlements>> {
    const m = this.getTaricsEntitlementChanges.name;

    const path = environment.getEndpoint(ApiPath.Interfaces.Tarics.TARICS_ENTITLEMENT_CHANGES);
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams().set('platformId', platformId.toString());

    const retVal = this.http.get<BaseResponse<TaricsEntitlements>>(path, { params });
    return retVal;
  }

  sendTaricsEntitlementChanges(orderId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.getTaricsEntitlementChanges.name;

    const path = environment.getEndpoint(ApiPath.Interfaces.Tarics.TARICS_ENTITLEMENT_CHANGES);
    LogService.info(this, m, LogService.PUT + path, null);
    let params = new HttpParams().set('orderId', orderId.toString());

    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null, { params });
    return retVal;
  }

  constructor(private http: HttpClient) {}
}
