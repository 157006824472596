import { DatePipe } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, first, switchMap } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';

@Injectable()
export class CleanEmptyFieldReuqestInterceptor implements HttpInterceptor {
  constructor(private datePipe: DatePipe, private store: Store<RootStoreState.State>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(PreferencesSelectors.selectPreferencesHelp).pipe(
      first(),
      switchMap((helpSetting) => {
        if (!helpSetting || !req.url.match(helpSetting.settingHelpUrl)) {
          if (req.body && !(req.body instanceof File) && !(req.body instanceof FormData)) {
            const request = JSON.parse(JSON.stringify(req.body));
            const removeEmpty = (obj) => {
              Object.keys(obj).forEach((key) => {
                if (obj[key] && typeof obj[key] === 'object') {
                  removeEmpty(obj[key]);
                } else if (obj[key] === '') {
                  obj[key] = null;
                }
              });
              return obj;
            };

            req = req.clone({
              body: JSON.parse(JSON.stringify(removeEmpty(request))),
            });
          }
          // const now = new Date();
          // req = req.clone({
          //   headers: req.headers.set(HttpHeaders.DATETIME_FILTER, this.datePipe.transform(now, 'yyyy-MM-dd HH:mm:ss')),
          // });
          return next.handle(req);
        } else {
          return next.handle(req);
        }
      })
    );
  }
}
