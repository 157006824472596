import { Action, createReducer, on } from '@ngrx/store';
import * as AddSubscriptionsWizardActions from './actions';
import { State, initialState } from './state';

export const addSubscriptionsWizardFeatureKey = 'addSubscriptionsWizard';

const addSubscriptionsWizardReducer = createReducer(
  initialState,

  on(AddSubscriptionsWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(AddSubscriptionsWizardActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(AddSubscriptionsWizardActions.selectServicesStep, (state, { servicesStep }) => ({
    ...state,
    servicesStep,
  })),
  on(AddSubscriptionsWizardActions.selectBillingStep, (state, { billingStep }) => ({
    ...state,
    billingStep,
  })),
  on(AddSubscriptionsWizardActions.selectValidationStep, (state, { validationStep }) => ({
    ...state,
    validationStep,
  })),
  on(AddSubscriptionsWizardActions.exitAddSubscriptionsWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    servicesStep: null,
    billingStep: null,
    validationStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(AddSubscriptionsWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    servicesStep: null,
    billingStep: null,
    validationStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  // on(AddSubscriptionsWizardActions.createNewAddSubscriptions, (state, { request }) => ({
  //   ...state,
  //   request,
  // })),
  on(AddSubscriptionsWizardActions.createNewAddSubscriptionsSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(AddSubscriptionsWizardActions.createNewAddSubscriptionsFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return addSubscriptionsWizardReducer(state, action);
}
