import { Entity } from './entity';

export class Questionaire extends Entity {
  questionaireId: number;
  questionaireQuestion: string;
  questionaireType: string;
  questionaireTypeId: number;
  questionaireRequired: boolean;
  questionaireHasOtherOption: boolean;
  questionaireAnswerOptions: QuestionaireOption[];
  questionaireSortorder: number;
  questionaireAllowAttachment: boolean;
  questionaireDeleted?: boolean;
  questionaireAnswerOptionsDeleted?: QuestionaireOption[];
}

export class QuestionaireRequest extends Entity {
  questionaireId: number;
  questionaireQuestion: string;
  questionaireTypeId: number;
  questionaireRequired: boolean;
  questionaireHasOtherOption: boolean;
  questionaireAnswerOptions: QuestionaireOption[]; // FLAG
  questionaireDeleted: boolean;

  constructor(q: Questionaire) {
    super();
    this.questionaireId = q.questionaireId != null ? q.questionaireId : -1;
    this.questionaireQuestion = q.questionaireQuestion;
    this.questionaireTypeId = q.questionaireTypeId;
    this.questionaireRequired = q.questionaireRequired;
    this.questionaireHasOtherOption = q.questionaireHasOtherOption;
    this.questionaireDeleted = q.questionaireDeleted != null ? q.questionaireDeleted : false;
    this.questionaireAnswerOptions = [
      ...(q.questionaireAnswerOptions != null ? q.questionaireAnswerOptions : []),
      ...(q.questionaireAnswerOptionsDeleted != null ? q.questionaireAnswerOptionsDeleted : []),
    ];
  }
}

export class QuestionaireOption {
  questionaireoptionId: number;
  questionaireoptionAnswer: string;
  questionaireoptionDeleted: boolean;
  questionaireoptionSelected: boolean;
}

export function isQuestionOptionsEnabled(question: Questionaire) {
  return (
    question.questionaireTypeId === QuestionType.singleSelect ||
    question.questionaireTypeId === QuestionType.multipleSelect ||
    question.questionaireTypeId === QuestionType.singleChoise ||
    question.questionaireTypeId === QuestionType.multipleChoise
  );
}

export function isSingleSelectionOption(question: Questionaire) {
  return question.questionaireTypeId === QuestionType.singleSelect || question.questionaireTypeId === QuestionType.singleChoise;
}

export function isMultipleSelectionOption(question: Questionaire) {
  return question.questionaireTypeId === QuestionType.multipleSelect || question.questionaireTypeId === QuestionType.multipleChoise;
}

export enum QuestionType {
  singleChoise = 1,
  singleSelect,
  multipleChoise,
  multipleSelect,
  text,
  integer,
  money,
  factor,
  percentage,
  date,
}
