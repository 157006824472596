import { Action, createReducer, on } from '@ngrx/store';
import * as AddBillingitemTopackageWizardActions from './actions';
import { State, initialState } from './state';

export const addBillingitemTopackageWizardFeatureKey = 'addBillingitemTopackageWizard';

const addBillingitemTopackageWizardReducer = createReducer(
  initialState,

  on(AddBillingitemTopackageWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(AddBillingitemTopackageWizardActions.selectDueDatesStep, (state, { dueDatesStep }) => ({
    ...state,
    dueDatesStep,
  })),
  on(AddBillingitemTopackageWizardActions.selectBillingitemPackage, (state, { billingitemPackageStep }) => ({
    ...state,
    billingitemPackageStep,
  })),
  on(AddBillingitemTopackageWizardActions.exitAddBillingitemTopackageWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    billingitemPackageStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(AddBillingitemTopackageWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    dueDatesStep: null,
    billingitemPackageStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  // on(AddBillingitemTopackageWizardActions.createNewAddBillingitemTopackage, (state, { request }) => ({
  //   ...state,
  //   request,
  // })),
  on(AddBillingitemTopackageWizardActions.createNewAddBillingitemTopackageSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(AddBillingitemTopackageWizardActions.createNewAddBillingitemTopackageFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return addBillingitemTopackageWizardReducer(state, action);
}
