import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AllocateConsumerAddonsWizardEffects } from './effects';
import * as fromAllocateConsumerAddonsWizard from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromAllocateConsumerAddonsWizard.allocateConsumerAddonWizardFeatureKey,
      fromAllocateConsumerAddonsWizard.reducer
    ),
    EffectsModule.forFeature([AllocateConsumerAddonsWizardEffects]),
  ],
})
export class AllocateConsumerAddonsWizardStoreModule {}
