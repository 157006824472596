import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, QueryList, ViewChildren } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { EntityModalConfig } from 'src/app/configs/entity-modal-dialog-config';
import {
  ForeignEntity,
  ForeignEntityDynamicDataSource,
  ForeignEntityDynamicFlatNode,
  ForeignEntityRequest,
} from 'src/app/models/foreign-entity';
import { RootStoreState } from 'src/app/root-store';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GridBaseComponent } from 'src/app/shared/base-components/grid-base-component';
import { MessageBox, MessageBoxButton, MessageBoxStyle, MessageBoxType } from 'src/app/shared/message-box';
import { CustomConfigurableModalColumn } from '../custom-configurable-modal/custom-configurable-modal.component';

@Component({
  selector: 'app-viewasuser-modal-dialog',
  templateUrl: './viewasuser-modal-dialog.component.html',
  styleUrls: ['./viewasuser-modal-dialog.component.scss'],
})
export class ViewasuserModalDialogComponent extends GridBaseComponent {
  @ViewChildren('paginatorTable') Paginator: QueryList<MatPaginator>;
  @ViewChildren('sortTable') SortTable: QueryList<MatSort>;

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<ViewasuserModalDialogComponent>,
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef
  ) {
    super(store, cdRef);
  }

  request: ForeignEntityRequest;
  selectedEntity: ForeignEntity;

  dataSourceTable: MatTableDataSource<ForeignEntity> = new MatTableDataSource<ForeignEntity>();
  displayedColumnsTable: string[];
  selectedColumnForOrdering: CustomConfigurableModalColumn;

  isGridView = true;
  isGridTreeSelectionActive = true;
  //tree
  showTreeToggle = true;
  dataSource: ForeignEntityDynamicDataSource;
  treeControl: FlatTreeControl<ForeignEntityDynamicFlatNode>;
  displayedColumnsTreeTable: string[];
  gridFiltersConfig = EntityModalConfig.Users.FILTERS;
  gridColumnsConfig = EntityModalConfig.Users.COLUMNS;

  async ngOnInit() {
    this.isGridTreeSelectionActive = false;
    const columns = [];

    this.gridColumnsConfig.forEach((column) => {
      columns.push(column.entityField);
      if (column.isOrderByActive) {
        this.selectedColumnForOrdering = column;
      }
    });

    this.displayedColumnsTable = ['radio', ...columns];
    this.displayedColumnsTreeTable = [...columns];
  }

  async gbAfterViewInitTable() {
    super.gbAfterViewInitTable();
    this.subscribe(this.Paginator.changes, (p: QueryList<MatPaginator>) => {
      this.paginatorTable = p.first;
      if (this.paginatorTable) {
        this.subscribe(this.paginatorTable.page, () => {
          this.setPage();
          this.gbLoadEntitiesData();
        });
      }
    });
    this.subscribe(this.SortTable.changes, (p: QueryList<MatSort>) => {
      this.sortTable = p.first;
      if (this.sortTable) {
        this.subscribe(this.sortTable.sortChange, () => {
          this.setOrder();
          this.gbLoadEntitiesData();
        });
      }
    });
  }

  initRequest() {
    this.filter = '';
    this.request = {
      pageIndex: 0,
      pageSize: this.pageSizeList[0],
      orderBy: this.selectedColumnForOrdering ? this.selectedColumnForOrdering.entityField : this.gbGetInitialOrderBy(),
      sort: this.selectedColumnForOrdering ? (this.selectedColumnForOrdering.isOrdesAsc ? 'asc' : 'desc') : this.gbGetInitialSort(),
      filter: this.filter,
      filterMap: this.filtersMap,
      isTenantAdmin: this.isTenantAdmin,
    };
  }

  onGridTreeSelectionChanged($event: MatRadioChange) {
    this.isGridView = $event.value;
    this.cdRef.detectChanges();
    // Table Initialization
    this.gbLoadEntitiesData();
  }

  gbLoadEntitiesData() {
    this.clearSelection();
    this.isLoading = true;
    this.dataSourceTable = new MatTableDataSource();
    this.noEntityData = false;
    if (this.gridLoadSubscription != null) {
      this.gridLoadSubscription.unsubscribe();
    }
    this.gridLoadSubscription = this.subscribe(
      this.coreDataService.getForeignEntity(this.ApiPath.Users.USERS_VIEW_AS_USER, this.request),
      (response) => {
        this.lastUsedFilters = response.filters;
        this.dataSourceTable.data = response.data ? response.data : [];
        this.noEntityData = !response.data || response.data.length === 0;
        this.pageTotalElements = !response.data || response.data.length === 0 ? 0 : response.data[0].entityCount;
        this.isLoading = false;
        this.cdRef.detectChanges();
      },
      (error) => {
        this.isLoading = false;
        this.noEntityData = true;
      }
    );
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  async onSelect() {
    let newWindow = window.open();
    const { data } = await firstValueFrom(this.authService.viewAsUser(this.selectedEntity.entityId));
    if (data && data.token) {
      const urlTree = this.router.createUrlTree([`view-as-user`]);
      urlTree.queryParams['token'] = data.token;
      const url = this.router.serializeUrl(urlTree);
      newWindow.location.href = url;
      // window.open(url, '_blank');
    } else {
      newWindow.close();
      MessageBox.show(
        this.dialog,
        this.translate.instant(data.error),
        this.translate.instant(_('label_view_as_user_not_possible')),
        null,
        MessageBoxType.Comfirm,
        MessageBoxButton.Ok,
        false,
        MessageBoxStyle.Full,
        '600px'
      );
    }
    this.dialogRef.close();
  }

  getTooltipString(c: CustomConfigurableModalColumn, row: any): string {
    switch (c.columnType) {
      case 'date':
        return this.getDate(row[c.entityField]);
      case 'timestamp':
        return this.getTimestamp(row[c.entityField]);
      case 'price':
        return this.getMoney(row[c.entityField]);
      case 'factor':
        return this.getFactor(row[c.entityField]);
      case 'percentage':
        return this.getPercentageNormalized(row[c.entityField]);
      default:
        return row[c.entityField];
    }
  }

  onEntitySelected(row: ForeignEntity) {
    this.selectedEntity = row;
  }

  isEntitySelected() {
    return this.selectedEntity && this.selectedEntity.entityName != null && this.selectedEntity.entityName !== '';
  }
}
