import { ActionResponse } from 'src/app/models/action-response';
import { BillingItem } from 'src/app/models/billingitem';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { BillingAccount } from 'src/app/models/vendor-account';

export interface State {
  servicesStep: BillingItem[];
  billingaccountsStep: BillingAccount[];
  validationResponse: EntityWizardResponse;
  validationErrorMessage: string;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  servicesStep: null,
  billingaccountsStep: null,
  validationResponse: null,
  validationErrorMessage: null,
  response: null,
  errorMessage: null,
};
