import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { License } from 'src/app/models/license';
import { SwapContractInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/swap-contract-wizard/init-step/swap-contract-init-step';
import { NewSwapAssetRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/swap-contract-wizard/summary-step/new-swap-asset-request';

export const selectSwapContractInitStep = createAction(
  '[User Property Panel] Select Swap Contract Init Information Step',
  props<{ initStep: SwapContractInitStep }>()
);

export const selectAssetTobeCancelledStep = createAction(
  '[Wizard License Product Step] Select License Product Information Step',
  props<{ assetTobeCancelledStep: License }>()
);

export const validateSwapRequest = createAction(
  '[Wizard Confirmation Step] Validate Swap Contract Step',
  props<{ request: NewSwapAssetRequest }>()
);

export const validateSwapRequestResult = createAction(
  '[Wizard Confirmation Step] Validate Swap Contract Step Result',
  props<{ response: ActionResponse }>()
);

export const validateSwapRequestFail = createAction(
  '[Wizard Confirmation Step] Validate Swap Contract Step Fail',
  props<{ errorMessage: string }>()
);

export const exitAllocationSwapContractWizard = createAction('[Cancel Wizard Page] Exit Swap Contract Wizard');

export const destroyWizard = createAction('[Swap Contract Wizard Page] Exit Destroy Wizard');

export const createNewOrder = createAction(
  '[Wizard Confirmation Step] Create Swap Contract Step',
  props<{ newOrder: NewSwapAssetRequest }>()
);

export const createNewOrderSuccess = createAction(
  '[Wizard Confirmation Step] Create Swap Contract Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewOrderFail = createAction(
  '[Wizard Confirmation Step] Create Swap Contract Step Fail',
  props<{ errorMessage: string }>()
);
