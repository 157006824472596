export class ModuleMenuItem {
  moduleId: number;
  moduleParentTextname: string;
  moduleTextname: string;
  moduleDescription: string;
  moduleEnabled: boolean;
  moduleIcon: string;
  moduleLink: string;
  moduleAdmin: boolean;
  moduleExpanded = false;
}

export class SidemenuItem {
  sidemenuId: number;
  sidemenuIcon: string;
  sidemenuLink: string;
  sidemenuModule: string;
  sidemenuModuleId: number;
  sidemenuModuleTextname: string;
  sidemenuName: string;
  sidemenuUppername: string;
  hide?: boolean;
}
