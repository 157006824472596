<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div mat-dialog-content class="draggable-wizard-modal-dialog-content">
  <ng-container *ngIf="wizardName">
    <app-generic-entity-wizard
      [isWizardDialog]="true"
      [wizardName]="wizardName"
      [relatedEntityKinds]="relatedEntityKinds"
      [relatedEntityIds]="relatedEntityIds"
      [foreignFilterEntityKind]="foreignFilterEntityKind"
      [foreignFilterEntityId]="foreignFilterEntityId"
      [propertyTemplateId]="propertyTemplateId"
      [inputValue]="inputValue"
      [fieldTextnames]="fieldTextnames"
      [fieldValues]="fieldValues"
      [eventDataChangedCallback]="eventDataChangedCallback"
      [isDocumentsTabEnabled]="isDocumentsTabEnabled"
      (finishWizardEvEm)="returnWizardResponse ? null : onFinishCreateEntity($event)"
      (entityWizardResponseEvEm)="returnWizardResponse ? onFinishWizardResponse($event) : null"
      (cancelWizardEvEm)="onCancelCreateEntity()"
    >
    </app-generic-entity-wizard>
  </ng-container>
</div>
