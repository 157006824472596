import { Directive, ElementRef, HostListener, Input, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';

@Directive({
  selector: 'input[localizedNumericInput]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: LocalizedNumericInputDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocalizedNumericInputDirective),
      multi: true,
    },
  ],
})
export class LocalizedNumericInputDirective implements ControlValueAccessor, OnDestroy {
  private currentRegion = 'en';
  private DECIMAL_SEPARATOR: string;
  private THOUSANDS_SEPARATOR: string;

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  constructor(private element: ElementRef<HTMLInputElement>, protected store: Store<RootStoreState.State>) {
    this.store
      .select(PreferencesSelectors.selectPreferencesLanguageRegion)
      // Replace required for toLocaleString. Accept only Locale in format xx-XX
      .subscribe((langRegion) => {
        this.currentRegion = langRegion ? langRegion.region.replace('_', '-') : 'en';

        // const [thousandSeparator, decimalMarker] = formatNumber(1000.99, 'ch').replace(/\d/g, '');
        this.DECIMAL_SEPARATOR = this.getDecimalSeparator(this.currentRegion);
        this.THOUSANDS_SEPARATOR = this.getThousandSeparator(this.currentRegion);
      });
  }
  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
  }

  private _value: string | null;

  get value(): string | null {
    return this._value;
  }

  @Input('value')
  set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }
    const reg = new RegExp(/^[0-9]|\.|\,|\-$/);
    // Ensure that it is a number and stop the keypress
    if (e.shiftKey || !reg.test(e.key)) {
      e.preventDefault();
    }
  }

  @HostListener('input', ['$event.target.value'])
  input(value) {
    //Find all numerics, decimal marker(, or .) and -
    //It will delete thousandSeparator cos it's always opposite to decimal marker
    const regExp = new RegExp(`[^\\d${this.DECIMAL_SEPARATOR}-]`, 'g');
    //Separate value on before and after decimal marker
    const [integer, decimal] = value.replace(regExp, '.').split('.');

    //Send non localized value, with dot as decimalMarker to API
    this._value = decimal ? integer.concat('.', decimal) : integer;

    // If decimal separator is last character don't update
    // because it will delete . || ,
    if (this.isLastCharacterDecimalSeparator(value)) {
      this._value = value;
    }

    // here to notify Angular Validators
    this._onChange(this._value);
  }

  @HostListener('blur')
  _onBlur() {
    /**
     * Adding thousand separators
     */
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue();
  }

  _onChange(value: any): void {}

  /**
   * @param value
   * apply formatting on value assignment
   */
  writeValue(value: any) {
    this._value = value;
    this.formatValue(this._value);
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {}

  isLastCharacterDecimalSeparator(value: any) {
    return isNaN(value[value.length - 1]);
  }

  private formatValue(value: string | number | null) {
    if (value === null) {
      this.element.nativeElement.value = '';
      return;
    }

    const isNumber = typeof value === 'number';
    let stringValue = isNumber ? value.toString() : value;

    if (this.isLastCharacterDecimalSeparator(value)) {
      this.element.nativeElement.value = stringValue;
      return;
    }

    // Conclude the decimal and thousand separators from locale
    // const [thousandSeparator, decimalMarker] = formatNumber(1000.99, this.currentRegion).replace(/\d/g, '');
    // this.DECIMAL_SEPARATOR = decimalMarker;
    if (!isNumber) {
      stringValue = stringValue.replace(this.DECIMAL_SEPARATOR, '.');
    }

    //Here value should always come with . as decimal marker thus any other behavior is bug
    const [integer, decimal] = stringValue.split('.');

    //Group every three elements, and add thousandSeparator after them
    this.element.nativeElement.value = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

    //Add decimals and decimalMarker if any
    if (decimal) {
      this.element.nativeElement.value = this.element.nativeElement.value.concat(this.DECIMAL_SEPARATOR, decimal);
    }
  }

  private unFormatValue() {
    const value = this.element.nativeElement.value;
    if (this.isLastCharacterDecimalSeparator(value)) {
      return;
    }
    const regExp = new RegExp(`[^\\d${this.DECIMAL_SEPARATOR}-]`, 'g');
    const stringValue = value.replace(regExp, '');
    const [integer, decimal] = stringValue.split(this.DECIMAL_SEPARATOR);

    this._value = decimal ? integer.concat('.', decimal) : integer;
    if (value) {
      this.element.nativeElement.value = this._value;
    } else {
      this.element.nativeElement.value = '';
    }
  }

  private getDecimalSeparator(locale) {
    const numberWithDecimalSeparator = 1.1;
    return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
  }

  private getThousandSeparator(locale) {
    const numberWithDecimalSeparator = 1111;
    return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
  }
}
