import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MoveDeskData } from 'src/app/models/move-desk-data';
import { MoveInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-wizard/init-step/move-init-step';
import { MoveLicenseOptions } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-wizard/license-step/move-license-options';
import * as fromMoveWizardStore from './reducer';
import { State } from './state';

export const selectMoveWizardStoreState = createFeatureSelector<State>(fromMoveWizardStore.moveWizardStoreFeatureKey);

export const selectInitStep = createSelector(selectMoveWizardStoreState, (state: State): MoveInitStep => state.initStep);

export const selectToDeskStep = createSelector(selectMoveWizardStoreState, (state: State): MoveDeskData => state.toDeskStep);

export const selectFromDeskStep = createSelector(selectMoveWizardStoreState, (state: State): MoveDeskData => state.fromDeskStep);

export const selectLicenseStep = createSelector(selectMoveWizardStoreState, (state: State): MoveLicenseOptions[] => state.licenseStep);
// export const selectAdditionaInfoStep = createSelector(selectCancelWizardStoreState, (state: State): AdditionalInfo => state.addInfoStep);

// export const selectCancelLicenseServicesResponse = createSelector(
//   selectCancelWizardStoreState,
//   (state: State): CancelLicenseServicesResponse => state.cancelLicenseServicesResponse
// );

export const selectErrorMessage = createSelector(selectMoveWizardStoreState, (state: State): string => state.errorMessage);
