import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { FeedItem } from 'src/app/models/feeditem';
import { AddSubscriptionsBillingNode } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-subscriptions-wizard/add-subscriptions-billing-step/add-subscriptions-billing-node';
import { AddSubscriptionsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-subscriptions-wizard/add-subscriptions-duedate-step/add-subscriptions-duedate-step';
import { AddSubscriptionsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-subscriptions-wizard/init-step/init-step';
import * as fromAddSubscriptionsWizard from './reducer';
import { State } from './state';

export const selectAddSubscriptionsWizardState = createFeatureSelector<State>(fromAddSubscriptionsWizard.addSubscriptionsWizardFeatureKey);

export const selectInitStep = createSelector(selectAddSubscriptionsWizardState, (state: State): AddSubscriptionsInitStep => state.initStep);

export const selectDueDateStep = createSelector(
  selectAddSubscriptionsWizardState,
  (state: State): AddSubscriptionsDueDates => state.dueDatesStep
);

export const selectServicesStep = createSelector(selectAddSubscriptionsWizardState, (state: State): FeedItem[] => state.servicesStep);

export const selectBillingStep = createSelector(
  selectAddSubscriptionsWizardState,
  (state: State): AddSubscriptionsBillingNode[] => state.billingStep
);

export const selectValidationStep = createSelector(
  selectAddSubscriptionsWizardState,
  (state: State): AddSubscriptionsBillingNode[] => state.validationStep
);

export const selectAddSubscriptionsState = createSelector(
  selectAddSubscriptionsWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectAddSubscriptionsWizardState, (state: State): any => state.errorMessage);
