import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { BillingitemPackageRequest } from 'src/app/models/billingitem';
import { PackageTreeNode } from 'src/app/models/package';
import { AddBillingitemTopackageDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-billingitems-topackage-wizard/activation-step/addbillingitems-due-date-info';
import { AddBillingitemTopackageInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-billingitems-topackage-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Add BillingitemTopackage Information Step',
  props<{ initStep: AddBillingitemTopackageInitStep }>()
);

export const selectDueDatesStep = createAction(
  '[Wizard Due Date Step] Select Add BillingitemTopackage Due Date Step',
  props<{ dueDatesStep: AddBillingitemTopackageDueDates }>()
);

export const selectBillingitemPackage = createAction(
  '[Wizard Billing account Step] Select Add BillingitemTopackage BillingitemPackage Step',
  props<{ billingitemPackageStep: PackageTreeNode[] }>()
);

export const exitAddBillingitemTopackageWizard = createAction('[AddBillingitemTopackage Wizard Page] Exit Add BillingitemTopackage Wizard');

export const destroyWizard = createAction('[AddBillingitemTopackage Wizard Page] Destroy Wizard');

export const createNewAddBillingitemTopackage = createAction(
  '[Wizard Confirmation Step] Create Add BillingitemTopackage Information Step',
  props<{ request: BillingitemPackageRequest }>()
);

export const createNewAddBillingitemTopackageSuccess = createAction(
  '[Wizard Confirmation Step] Create Add BillingitemTopackage Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewAddBillingitemTopackageFail = createAction(
  '[Wizard Confirmation Step] Create Add BillingitemTopackage Information Step Fail',
  props<{ errorMessage: string }>()
);
