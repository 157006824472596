/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/consistent-type-assertions */
import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  HostListener,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnInit,
  forwardRef,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InputHandler } from './input-handler';

export const CURRENCYMASKDIRECTIVE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => CurrencyMaskDirective),
  multi: true,
};

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[currencyMask]',
  providers: [CURRENCYMASKDIRECTIVE_VALUE_ACCESSOR, { provide: NG_VALIDATORS, useExisting: CurrencyMaskDirective, multi: true }],
})
export class CurrencyMaskDirective implements AfterViewInit, ControlValueAccessor, DoCheck, OnInit, Validator {
  @Input() max: number;
  @Input() min: number;
  @Input() options: any = {};

  inputHandler: InputHandler;
  keyValueDiffer: KeyValueDiffer<any, any>;

  optionsTemplate = {
    align: 'right',
    allowNegative: true,
    decimal: '.',
    precision: 2,
    prefix: '',
    suffix: '',
    thousands: '',
  };

  constructor(
    // @Optional() @Inject(CURRENCY_MASK_CONFIG) private currencyMaskConfig: CurrencyMaskConfig,

    private translate: TranslateService,
    private elementRef: ElementRef,
    private keyValueDiffers: KeyValueDiffers
  ) {
    // if (currencyMaskConfig) {
    //   this.optionsTemplate = currencyMaskConfig;
    // }
    this.optionsTemplate.decimal = this.getDecimalSeparator(this.translate.currentLang);
    this.keyValueDiffer = keyValueDiffers.find({}).create();
  }

  private getDecimalSeparator(locale) {
    const numberWithDecimalSeparator = 1.1;
    return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.style.textAlign = this.options.align ? this.options.align : this.optionsTemplate.align;
  }

  ngDoCheck() {
    if (this.keyValueDiffer.diff(this.options)) {
      this.elementRef.nativeElement.style.textAlign = this.options.align ? this.options.align : this.optionsTemplate.align;
      this.inputHandler.updateOptions((<any>Object).assign({}, this.optionsTemplate, this.options));
    }
  }

  ngOnInit() {
    this.inputHandler = new InputHandler(this.elementRef.nativeElement, (<any>Object).assign({}, this.optionsTemplate, this.options));
  }

  @HostListener('blur', ['$event'])
  handleBlur(event: any) {
    this.inputHandler.getOnModelTouched().apply(event);
  }

  @HostListener('click', ['$event'])
  handleClick(event: any) {
    this.inputHandler.handleClick(event, this.isChromeAndroid());
  }

  @HostListener('cut', ['$event'])
  handleCut(event: any) {
    if (!this.isChromeAndroid()) {
      this.inputHandler.handleCut(event);
    }
  }

  @HostListener('input', ['$event'])
  handleInput(event: any) {
    if (this.isChromeAndroid()) {
      this.inputHandler.handleInput(event);
    }
  }

  @HostListener('keydown', ['$event'])
  handleKeydown(event: any) {
    if (!this.isChromeAndroid()) {
      this.inputHandler.handleKeydown(event);
    }
  }

  @HostListener('keypress', ['$event'])
  handleKeypress(event: any) {
    if (!this.isChromeAndroid()) {
      this.inputHandler.handleKeypress(event);
    }
  }

  @HostListener('keyup', ['$event'])
  handleKeyup(event: any) {
    if (!this.isChromeAndroid()) {
      this.inputHandler.handleKeyup(event);
    }
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: any) {
    if (!this.isChromeAndroid()) {
      this.inputHandler.handlePaste(event);
    }
  }

  isChromeAndroid(): boolean {
    return /chrome/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent);
  }

  registerOnChange(callbackFunction: Function): void {
    this.inputHandler.setOnModelChange(callbackFunction);
  }

  registerOnTouched(callbackFunction: Function): void {
    this.inputHandler.setOnModelTouched(callbackFunction);
  }

  setDisabledState(value: boolean): void {
    this.elementRef.nativeElement.disabled = value;
  }

  validate(abstractControl: AbstractControl): { [key: string]: any } {
    const result: any = {};

    if (abstractControl.value > this.max) {
      result.max = true;
    }

    if (abstractControl.value < this.min) {
      result.min = true;
    }

    return result.max != null || result.min != null ? result : null;
  }

  writeValue(value: number): void {
    this.inputHandler.setValue(value);
  }
}
