import { createAction, props } from '@ngrx/store';
import { BaseResponse } from 'src/app/models/base-response';
import { SearchResult } from 'src/app/models/search-result';

export const requestNewGlobalSearch = createAction('[Global Search] Start Global Search', props<{ filter: string }>());

export const stopPreviousSearch = createAction('[Global Search] Stop Previous Search', props<{ searchId: number }>());

export const stopPreviousSearchSuccess = createAction('[Global Search] Stop Previous Search Success');

export const startNewSearch = createAction('[Global Search] Start New Search', props<{ filter: string }>());

export const startNewSearchSuccess = createAction('[Global Search] Start New Search Success', props<{ searchId: number }>());

export const startPollingSearchResult = createAction('[Global Search] Start Polling Search Result', props<{ searchId: number }>());

export const storePollingSearchResultData = createAction(
  '[Global Search] Store Polling Search Result',
  props<{ response: BaseResponse<SearchResult> }>()
);

export const setFilterEntityKinds = createAction('[Global Search] Set Filter Entity Kinds', props<{ filterEntityKind?: string[] }>());

export const finishPollingSearchResult = createAction('[Global Search] Finish Polling Search Result');

export const globalSearchError = createAction('[Global Search] Error', props<{ error: any }>());

export const destroySearch = createAction('[Global Search] Destroy Search Result');
