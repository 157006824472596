import { Action, createReducer, on } from '@ngrx/store';
import * as FoModuleActions from './actions';
import { State, featureAdapter, initialState } from './state';

export const foModuleFeatureKey = 'foModule';

const foModuleReducer = createReducer(
  initialState,

  on(FoModuleActions.loadModuleItems, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(FoModuleActions.loadSuccess, (state, { response }) =>
    featureAdapter.setAll(response.data, {
      ...state,
      isLoading: false,
      error: null,
    })
  ),
  on(FoModuleActions.loadEmptySuccess, (state) =>
    featureAdapter.setAll([], {
      ...state,
      isLoading: false,
      error: null,
    })
  ),
  on(FoModuleActions.loadFailure, (state, { error }) =>
    featureAdapter.removeAll({
      ...state,
      isLoading: false,
      error,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return foModuleReducer(state, action);
}
