import { ActionResponse } from 'src/app/models/action-response';
import { FeedItem } from 'src/app/models/feeditem';
import { AddSubscriptionsBillingNode } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-subscriptions-wizard/add-subscriptions-billing-step/add-subscriptions-billing-node';
import { AddSubscriptionsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-subscriptions-wizard/add-subscriptions-duedate-step/add-subscriptions-duedate-step';
import { AddSubscriptionsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-subscriptions-wizard/init-step/init-step';

export interface State {
  initStep: AddSubscriptionsInitStep;
  dueDatesStep: AddSubscriptionsDueDates;
  servicesStep: FeedItem[];
  billingStep: AddSubscriptionsBillingNode[];
  validationStep: AddSubscriptionsBillingNode[];
  // request: ServiceParticularRequest;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  dueDatesStep: null,
  servicesStep: null,
  billingStep: null,
  validationStep: null,
  response: null,
  errorMessage: null,
};
