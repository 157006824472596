import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { CancelAssetCancellationDetailsStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-asset-wizard/cancel-asset-cancellation-details-step/cancel-asset-cancellation-details-step';
import { CancelAssetWizardRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-asset-wizard/cancel-asset-confirm-step/cancel-asset-services-request';
import { CancelAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-asset-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Cancel Asset Information Step',
  props<{ initStep: CancelAssetInitStep }>()
);

export const selectCancellationDetailsStep = createAction(
  '[CancelAsset Wizard Page] Cancel Asset Wizard - Allocation Update Step',
  props<{ cancellationDetails: CancelAssetCancellationDetailsStep }>()
);

export const deleteCancellationDetailsStep = createAction('[CancelAsset Wizard Page] Delete Allocation Update Step');

export const exitCancelAssetWizard = createAction('[CancelAsset Wizard Page] Exit Cancel Asset Wizard');

export const destroyWizard = createAction('[CancelAsset Wizard Page] Destroy Wizard');

export const createNewCancelAsset = createAction(
  '[Wizard Confirmation Step] Create Cancel  Asset Information Step',
  props<{ request: CancelAssetWizardRequest }>()
);

export const createNewCancelAssetSuccess = createAction(
  '[Wizard Confirmation Step] Create Cancel Asset Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewCancelAssetFail = createAction(
  '[Wizard Confirmation Step] Create Cancel Asset Information Step Fail',
  props<{ errorMessage: any }>()
);
