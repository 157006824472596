import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { MoveAssetBillingaccountInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-billingaccount-wizard/init-step/init-step';
import { MoveAssetBillingaccountMovingToStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-billingaccount-wizard/move-asset-billingaccount-movingto-step/move-asset-billingaccount-movingto-step';
import { MoveAssetWizardRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-wizard/move-asset-confirm-step/move-asset-wizard-request';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Move Asset Information Step',
  props<{ initStep: MoveAssetBillingaccountInitStep }>()
);

export const selectMovingToStep = createAction(
  '[MoveAssetBillingaccount Wizard Page] Move Asset Wizard - Moving to Step',
  props<{ movingTo: MoveAssetBillingaccountMovingToStep }>()
);

export const exitMoveAssetBillingaccountWizard = createAction('[MoveAssetBillingaccount Wizard Page] Exit Move Asset Wizard');

export const destroyWizard = createAction('[MoveAssetBillingaccount Wizard Page] Destroy Wizard');

export const createNewMoveAssetBillingaccount = createAction(
  '[Wizard Confirmation Step] Create Move Asset Billingaccount Information Step',
  props<{ request: MoveAssetWizardRequest }>()
);

export const createNewMoveAssetBillingaccountSuccess = createAction(
  '[Wizard Confirmation Step] Create Move Asset Billingaccount Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewMoveAssetBillingaccountFail = createAction(
  '[Wizard Confirmation Step] Create Move Asset Billingaccount Information Step Fail',
  props<{ errorMessage: any }>()
);
