import { Directive, ElementRef, HostListener } from '@angular/core';
import { EditableComponent } from './editable.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[editableOnEnter]',
})
export class EditableOnEnterDirective {
  constructor(private host: ElementRef, private editable: EditableComponent) {}

  @HostListener('keydown.enter', ['$event'])
  onEnter(e: KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.editable.toViewMode(true, this.host.nativeElement.value);
  }
}
