import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { ReplaceProductServiceInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-product-service-wizard/init-step/init-step';
import { ReplaceProductServiceBillingStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-product-service-wizard/replace-product-service-billing-step/replace-product-service-billing-step';
import { ReplaceProductServiceRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-product-service-wizard/replace-product-service-confirm-step/replace-product-service-request';
import { ReplaceProductServiceReplacementStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-product-service-wizard/replace-product-service-replacement-step/replace-product-service-replacement-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Replace Product Service Information Step',
  props<{ initStep: ReplaceProductServiceInitStep }>()
);

export const selectReplacementStep = createAction(
  '[Wizard Replacement Step] Select Replace Product Service Replacement Step',
  props<{ replacementStep: ReplaceProductServiceReplacementStep }>()
);

export const selectBillingStep = createAction(
  '[Wizard Billing Step] Select Replace Product Service Billing Step',
  props<{ billingStep: ReplaceProductServiceBillingStep }>()
);

export const exitReplaceProductServiceWizard = createAction('[ReplaceProductService Wizard Page] Exit Replace Product Service Wizard');

export const destroyWizard = createAction('[ReplaceProductService Wizard Page] Destroy Wizard');

export const createNewReplaceProductService = createAction(
  '[Wizard Confirmation Step] Create Replace Product Service Information Step',
  props<{ request: ReplaceProductServiceRequest }>()
);

export const createNewReplaceProductServiceSuccess = createAction(
  '[Wizard Confirmation Step] Create Replace Product Service Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewReplaceProductServiceFail = createAction(
  '[Wizard Confirmation Step] Create Replace Product Service Information Step Fail',
  props<{ errorMessage: string }>()
);
