import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { BillingItem } from 'src/app/models/billingitem';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { BillingAccount } from 'src/app/models/vendor-account';
import * as fromAddServicesToBillingaccountsWizard from './reducer';
import { State } from './state';

export const selectAddServicesToBillingaccountsWizardState = createFeatureSelector<State>(
  fromAddServicesToBillingaccountsWizard.addServicesToBillingaccountsWizardFeatureKey
);

export const selectServicesStep = createSelector(
  selectAddServicesToBillingaccountsWizardState,
  (state: State): BillingItem[] => state.servicesStep
);

export const selectBillingaccountsStep = createSelector(
  selectAddServicesToBillingaccountsWizardState,
  (state: State): BillingAccount[] => state.billingaccountsStep
);

export const selectValidationResponse = createSelector(
  selectAddServicesToBillingaccountsWizardState,
  (state: State): EntityWizardResponse => state.validationResponse
);

export const selectValidationErrorMessage = createSelector(
  selectAddServicesToBillingaccountsWizardState,
  (state: State): string => state.validationErrorMessage
);

export const selectAddServicesToBillingaccountsState = createSelector(
  selectAddServicesToBillingaccountsWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(
  selectAddServicesToBillingaccountsWizardState,
  (state: State): string => state.errorMessage
);
