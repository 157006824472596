import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { AuthGuardService } from 'src/app/services/auth/auth-guard.service';
import { RoleGuardService } from 'src/app/services/auth/role-guard.service';
import { SharedModule } from 'src/app/shared';
import { DemoPageComponent } from './demopage.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        component: DemoPageComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          authTitle: _('sidemenu_demo'),
          noauthTitle: _('sidemenu_demo'),
        },
      },
    ]),
  ],
  declarations: [DemoPageComponent],
  exports: [DemoPageComponent],
})
export class DemoPageModule {}
