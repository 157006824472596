import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { GenericRelationEntity } from '../models/generic-relation-entity';
import { PaginatedRequest } from '../models/paginated-request';
import { RolegroupAccessRight } from '../models/role-group-accessright';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class RelationDataService {
  getRelations(apiPath: string, request: PaginatedRequest): Observable<BaseResponse<GenericRelationEntity[]>> {
    const m = this.getRelations.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let retVal = null;
    if (request) {
      retVal = this.http.get<BaseResponse<GenericRelationEntity[]>>(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
      });
    } else {
      retVal = this.http.get<BaseResponse<GenericRelationEntity[]>>(path);
    }

    return retVal;
  }

  getOtherEntityElements(
    apiPath: string,
    relationBaseId: number,
    request: PaginatedRequest
  ): Observable<BaseResponse<GenericRelationEntity[]>> {
    const m = this.getOtherEntityElements.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    // params = params.append('relationBaseId', relationBaseId.toString());
    const retVal = this.http.get<BaseResponse<GenericRelationEntity[]>>(path, {
      params,
    });

    return retVal;
  }

  addRelation(apiPath: string, ids: number[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.addRelation.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST + ' ids: ', ids);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, {
      params: { entityIds: ids.toString() },
    });

    return retVal;
  }

  removeRelation(apiPath: string, ids: number[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.removeRelation.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST + ' ids: ', ids);
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path, {
      params: { entityIds: ids.toString() },
    });

    return retVal;
  }

  // CUSTOM RELATION API'S

  getRoleGroupAccessRights(rolegroupId: number): Observable<BaseResponse<RolegroupAccessRight[]>> {
    const m = this.getRoleGroupAccessRights.name;

    const path = environment.getEndpoint(ApiPath.Administrations.ADMIN_ROLEGROUP_ACCESSRIGHT(rolegroupId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<RolegroupAccessRight[]>>(path);

    return retVal;
  }

  putRoleGroupAccessRights(rolegroupId: number, request: RolegroupAccessRight[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.putRoleGroupAccessRights.name;

    const path = environment.getEndpoint(ApiPath.Administrations.ADMIN_ROLEGROUP_ACCESSRIGHT(rolegroupId));
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
