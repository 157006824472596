import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { BillingitemReplaceRequest } from 'src/app/models/billingitem';
import { ServiceAlias } from 'src/app/models/service';
import { ReplaceBillingitemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-billingitems-wizard/activation-step/replacebillingitems-due-date-info';
import { ReplaceBillingitemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-billingitems-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Replace Billingitems Information Step',
  props<{ initStep: ReplaceBillingitemsInitStep }>()
);

export const selectDueDatesStep = createAction(
  '[Wizard Due Date Step] Select Replace Billingitems Due Date Step',
  props<{ dueDatesStep: ReplaceBillingitemsDueDates }>()
);

export const selectServiceStep = createAction(
  '[Wizard Billing account Step] Select Replace Billingitems Billing account Step',
  props<{ serviceStep: ServiceAlias[] }>()
);

export const deleteServiceStep = createAction('[Wizard Billing account Step] Delete Replace Billingitems Billing account Step');

export const exitReplaceBillingitemsWizard = createAction('[ReplaceBillingitems Wizard Page] Exit Replace Billingitems Wizard');

export const destroyWizard = createAction('[ReplaceBillingitems Wizard Page] Destroy Wizard');

export const createNewReplaceBillingitems = createAction(
  '[Wizard Confirmation Step] Create Replace Billingitems Information Step',
  props<{ request: BillingitemReplaceRequest }>()
);

export const createNewReplaceBillingitemsSuccess = createAction(
  '[Wizard Confirmation Step] Create Replace Billingitems Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewReplaceBillingitemsFail = createAction(
  '[Wizard Confirmation Step] Create Replace Billingitems Information Step Fail',
  props<{ errorMessage: string }>()
);
