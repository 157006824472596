import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthResult } from 'src/app/core/auth/auth-result';
import { AuthUser } from 'src/app/core/auth/auth-user';
import * as fromRestrictedAuth from './reducer';
import { State } from './state';

export const selectRestrictedAuthState = createFeatureSelector<State>(fromRestrictedAuth.restrictedAuthFeatureKey);

export const selectIsRestrictedAuthenticated = createSelector(
  selectRestrictedAuthState,
  (state: State): boolean => state.isRestrictedAuthenticated
);

export const selectRestrictedAuthError = createSelector(selectRestrictedAuthState, (state: State): AuthResult => state.error);

export const selectRestrictedAuthUser = createSelector(selectRestrictedAuthState, (state: State): AuthUser => state.authUser);
