import { createAction, props } from '@ngrx/store';
import { MoveDeskData } from 'src/app/models/move-desk-data';
import { MoveInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-wizard/init-step/move-init-step';
import { MoveLicenseOptions } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-wizard/license-step/move-license-options';

export const selectMoveInitStep = createAction(
  '[User Property Panel] Select Move Init Information Step',
  props<{ initStep: MoveInitStep }>()
);

export const exitAllocationMoveWizard = createAction('[Cancel Wizard Page] Exit Allocation Move Wizard');

export const destroyWizard = createAction('[Move Wizard Page] Exit Destroy Wizard');

export const selectFromDeskStep = createAction(
  '[Move Wizard Page] Select Move From Desk Data Step',
  props<{ fromDeskStep: MoveDeskData }>()
);
export const selectToDeskStep = createAction('[Move Wizard Page] Select Move To Desk Data Step', props<{ toDeskStep: MoveDeskData }>());
export const selectLicenseStep = createAction(
  '[Move Wizard Page] Select License Data Step',
  props<{ licenseStep: MoveLicenseOptions[] }>()
);

export const sendLicenseServicesMoveRequest = createAction(
  '[Move Wizard Confirmation Step] Create Move Information Step' /*,
  props<{ moveRequest: MoveLicenseServicesRequest }>()*/
);

export const moveRequestSuccess = createAction(
  '[Move Wizard Confirmation Step] Create Move Information Step Success' /*,
  props<{ response: MoveLicenseServicesResponse }>()*/
);

export const moveRequestFail = createAction(
  '[Move Wizard Confirmation Step] Create Move Information Step Fail',
  props<{ errorMessage: string }>()
);
