import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { CancelAssetOptions } from 'src/app/models/cancel-asset-options';
import { CancelLicenseServicesRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-wizard/confirmation-step/cancel-license-services-request';
import { MoveAssetServicesDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-services-wizard/activation-step/move-asset-services-due-date-info';
import { MoveAssetServicesInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-services-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Move AssetServices Information Step',
  props<{ initStep: MoveAssetServicesInitStep }>()
);

export const selectDueDatesStep = createAction(
  '[Wizard Due Date Step] Select Move AssetServices Due Date Step',
  props<{ dueDatesStep: MoveAssetServicesDueDates }>()
);

export const selectBillingAccountStep = createAction(
  '[Wizard Due Date Step] Select Move AssetServices Billing Account Step',
  props<{ billingaccountStep: CancelAssetOptions[] }>()
);

export const exitMoveAssetServicesWizard = createAction('[MoveAssetServices Wizard Page] Exit Move AssetServices Wizard');

export const destroyWizard = createAction('[MoveAssetServices Wizard Page] Destroy Wizard');

export const createNewMoveAssetServices = createAction(
  '[Wizard Confirmation Step] Create Move AssetServices Information Step',
  props<{ cancelRequest: CancelLicenseServicesRequest }>()
);

export const createNewMoveAssetServicesSuccess = createAction(
  '[Wizard Confirmation Step] Create Move AssetServices Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewMoveAssetServicesFail = createAction(
  '[Wizard Confirmation Step] Create Move AssetServices Information Step Fail',
  props<{ errorMessage: string }>()
);
