<div id="global-date-filter" *ngIf="showit">
  <!-- !isTenantAdminAuthenticated && -->
  <ng-container *ngIf="!isConfiguration">
    <mat-form-field
      appearance="outline"
      class="form-field-input reset-outline-hint"
      [ngClass]="selectedGlobalDateOption === 2 ? 'snapshot' : ''"
    >
      <mat-select
        disableOptionCentering
        panelClass="finoffice-matselect-panel global-date-panel"
        [(ngModel)]="selectedGlobalDateOption"
        [hideSingleSelectionIndicator]="true"
        (selectionChange)="onOptionChange()"
        [disabled]="isDisabled"
      >
        <mat-option class="form-field-option" [value]="1">
          {{ 'global_filter_current_records_label' | translate }}
        </mat-option>
        <mat-option class="form-field-option" [value]="0">
          {{ 'global_filter_all_records_label' | translate }}
        </mat-option>
        <mat-option class="form-field-option snapshot" [value]="2">
          <!-- (click)="globalDatePicker.open()"-->
          <span>{{ 'global_filter_snapshot_label' | translate }}</span>
          <!-- &nbsp;
        <span *ngIf="selectedGlobalDateOption === 2">{{ getDate(selectedGlobalDate) }}</span> -->
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="selectedGlobalDateOption === 2">
      <span style="padding-right: 0.5rem"></span>
      <p-calendar
        rPCalendarMask
        #pcalendarDatepicker
        class="date-input"
        [(ngModel)]="selectedGlobalDate"
        [selectOtherMonths]="true"
        [showIcon]="true"
        [placeholder]="getDatePlaceholder()"
        [dateFormat]="getDateRegionalFormat()"
        (onSelect)="setDate($event)"
        appendTo="body"
        ><p-footer>
          <div class="p-datepicker-buttonbar">
            <button class="p-button-text" type="button" (click)="calendarOnTodayButtonClick($event, pcalendarDatepicker)" pButton pRipple>
              {{ 'label_today' | translate }}
            </button>
            <button class="p-button-text" type="button" (click)="pcalendarDatepicker.hideOverlay()" pButton pRipple>
              {{ 'label_close' | translate }}
            </button>
          </div>
        </p-footer>
      </p-calendar>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isConfiguration">
    <mat-form-field
      appearance="outline"
      class="form-field-input reset-outline-hint"
      [ngClass]="selectedConfigurationGlobalDateOption === 2 ? 'snapshot' : ''"
    >
      <mat-select
        disableOptionCentering
        panelClass="finoffice-matselect-panel global-date-panel"
        [(ngModel)]="selectedConfigurationGlobalDateOption"
        [hideSingleSelectionIndicator]="true"
        (selectionChange)="onConfigurationOptionChange()"
        [disabled]="isDisabled"
      >
        <mat-option class="form-field-option" [value]="1">
          {{ 'global_filter_current_records_label' | translate }}
        </mat-option>
        <mat-option class="form-field-option" [value]="0">
          {{ 'global_filter_all_records_label' | translate }}
        </mat-option>
        <mat-option class="form-field-option snapshot" [value]="2">
          <!-- (click)="globalDatePicker.open()"-->
          <span>{{ 'global_filter_snapshot_label' | translate }}</span>
          <!-- &nbsp;
      <span *ngIf="selectedGlobalDateOption === 2">{{ getDate(selectedGlobalDate) }}</span> -->
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="selectedConfigurationGlobalDateOption === 2">
      <span style="padding-right: 0.5rem"></span>
      <p-calendar
        rPCalendarMask
        #pcalendarDatepicker
        class="date-input"
        [(ngModel)]="selectedConfigurationGlobalDate"
        [selectOtherMonths]="true"
        [showIcon]="true"
        [placeholder]="getDatePlaceholder()"
        [dateFormat]="getDateRegionalFormat()"
        (onSelect)="setDate($event)"
        appendTo="body"
        ><p-footer>
          <div class="p-datepicker-buttonbar">
            <button class="p-button-text" type="button" (click)="calendarOnTodayButtonClick($event, pcalendarDatepicker)" pButton pRipple>
              {{ 'label_today' | translate }}
            </button>
            <button class="p-button-text" type="button" (click)="pcalendarDatepicker.hideOverlay()" pButton pRipple>
              {{ 'label_close' | translate }}
            </button>
          </div>
        </p-footer>
      </p-calendar>
    </ng-container>
  </ng-container>
</div>
