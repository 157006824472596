import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

export enum CronSecondsOptions {
  everySecond = 1,
  everyNSecondStartFrom,
  specificSeconds,
  rangeSeconds,
}

@Component({
  selector: 'app-cron-seconds-tab, cron-seconds-tab',
  templateUrl: './cron-seconds-tab.component.html',
  styleUrls: ['./cron-seconds-tab.component.scss'],
})
export class CronSecondsTabComponent implements OnInit {
  @Input() cronExpression: string;
  @Input() isEditing: boolean;
  @Output() secondsExpressionEvEm = new EventEmitter<string>();
  selectedOption: CronSecondsOptions;
  secondsExpression: string;

  rangeSecondsOptions = this.getRange(0, 59);
  everyNSecondOptions = this.getRange(1, 60);

  //everyNSecondStartFrom
  everyNSecond: number;
  everyNSecondStartFrom: number;

  //specificSeconds
  specificSeconds: number[] = [];

  //rangeSeconds
  rangeSecondsStarts: number;
  rangeSecondsEnds: number;

  constructor() {}

  ngOnInit(): void {
    this.everyNSecond = this.everyNSecondOptions[0];
    this.everyNSecondStartFrom = this.rangeSecondsOptions[0];
    this.rangeSecondsStarts = this.rangeSecondsOptions[0];
    this.rangeSecondsEnds = this.rangeSecondsOptions[0];
    this.specificSeconds = [this.rangeSecondsOptions[0]];
    this.parseCronExpression();
  }

  parseCronExpression() {
    this.secondsExpression = this.cronExpression.split(' ')[0];
    if (this.secondsExpression === '*') {
      this.selectedOption = CronSecondsOptions.everySecond;
    } else if (this.secondsExpression.match(/^\d{1,2}\/\d{1,2}$/)) {
      this.selectedOption = CronSecondsOptions.everyNSecondStartFrom;
      const cron_parts = this.secondsExpression.split('/');
      this.everyNSecond = parseInt(cron_parts[1]);
      this.everyNSecondStartFrom = parseInt(cron_parts[0]);
    } else if (this.secondsExpression.match(/^\d{1,2}\-\d{1,2}$/)) {
      this.selectedOption = CronSecondsOptions.rangeSeconds;
      const cron_parts = this.secondsExpression.split('-');
      this.rangeSecondsStarts = parseInt(cron_parts[0]);
      this.rangeSecondsEnds = parseInt(cron_parts[1]);
    } else if (this.secondsExpression.match(/^\d{1,2}$|^(\d{1,2},)+\d{1,2}$/)) {
      this.selectedOption = CronSecondsOptions.specificSeconds;
      this.specificSeconds = this.secondsExpression.split(',').map((n) => parseInt(n));
    }
  }

  checkboxSelectionChanged($event: MatCheckboxChange) {
    this.selectedOption = CronSecondsOptions.specificSeconds;
    const value = parseInt($event.source.value);
    const newChecked = $event.checked;
    if (newChecked) {
      this.specificSeconds.push(value);
      this.specificSeconds.sort(function (a, b) {
        return a - b;
      });
    } else {
      const index = this.specificSeconds.indexOf(value, 0);
      if (index > -1) {
        this.specificSeconds.splice(index, 1);
      }
    }
    this.regenerateCron();
  }

  selectAllCheckboxes() {
    this.specificSeconds = JSON.parse(JSON.stringify(this.rangeSecondsOptions));
    this.regenerateCron();
  }

  resetCheckboxes() {
    this.specificSeconds = [];
    this.regenerateCron();
  }

  regenerateCron() {
    switch (this.selectedOption) {
      case CronSecondsOptions.everySecond:
        this.secondsExpression = '*';
        break;

      case CronSecondsOptions.everyNSecondStartFrom:
        this.secondsExpression = `${this.everyNSecondStartFrom}/${this.everyNSecond}`;
        break;

      case CronSecondsOptions.rangeSeconds:
        this.secondsExpression = `${this.rangeSecondsStarts}-${this.rangeSecondsEnds}`;
        break;

      case CronSecondsOptions.specificSeconds:
        this.secondsExpression = this.specificSeconds.length > 0 ? this.specificSeconds.join(',') : '0';
        break;
    }
    this.secondsExpressionEvEm.emit(this.secondsExpression);
  }

  isCheckboxChecked(n: number): boolean {
    return this.specificSeconds.includes(n);
  }

  get CronSecondsOptions() {
    return CronSecondsOptions;
  }

  private getRange(start: number, end: number): number[] {
    const length = end - start + 1;
    return Array.apply(null, Array(length)).map((__, i) => i + start);
  }
}
