import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesAction } from 'src/app/root-store/preferences-store';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router,
    private store: Store<RootStoreState.State>
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isAuthenticated().pipe(
      tap((authenticated) => {
        if (!authenticated) {
          this.tokenService.clear();
          // this.router.navigate(['/auth/login']);
          this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } }).then(() => {
            this.store.dispatch(PreferencesAction.loadPreferenceSettings({ isAuth: false, isTenantAdmin: false }));
          });
        }
      })
    );
  }
}
