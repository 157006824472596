<div id="files-tree-container">
  <app-generic-tree-view
    #filesTreeView
    class="files-tree"
    (nodeClick)="onNodeClicked($event)"
    [autoselectEmptyNode]="autoselectEmptyNode"
    [rootNodeLabel]="rootNodeLabel"
    [baseApiPath]="treeViewBasePath"
    [showIconLeaf]="false"
    [defaultRootNode]="rootNode"
  ></app-generic-tree-view>
</div>
