import { Action, createReducer, on } from '@ngrx/store';
import * as ImageFullscreenViewerActions from './actions';
import { State, initialState } from './state';

export const errorHandlingFeatureKey = 'foImageFullscreenViewer';

const errorHandlingReducer = createReducer(
  initialState,
  on(ImageFullscreenViewerActions.setImage, (state, { imageObject }) => ({
    ...state,
    imageObject,
  })),
  on(ImageFullscreenViewerActions.resetImage, (state) => {
    return {
      ...state,
      imageObject: null,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return errorHandlingReducer(state, action);
}
