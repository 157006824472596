<div class="base-form-field text">
  <div class="label-container">
    <span
      [class.form-field-text-kind-normal]="formFieldData.formfieldTextKindId === FormFieldTextKind.DEFAULT"
      [class.form-field-text-kind-label]="formFieldData.formfieldTextKindId === FormFieldTextKind.LABEL"
      [class.form-field-text-size-normal]="formFieldData.formfieldTextSizeId === FormFieldTextSize.NORMAL"
      [class.form-field-text-size-medium]="formFieldData.formfieldTextSizeId === FormFieldTextSize.MEDIUM"
      [class.form-field-text-size-high]="formFieldData.formfieldTextSizeId === FormFieldTextSize.HIGH"
      [class.form-field-text-weight-normal]="formFieldData.formfieldTextWeightId === FormFieldTextWeight.NORMAL"
      [class.form-field-text-weight-bold]="formFieldData.formfieldTextWeightId === FormFieldTextWeight.BOLD"
    >
      {{ formFieldData.formfieldTextname | translate }}
    </span>
    <ng-container *ngIf="!isViewMode">
      <span
        *ngIf="formFieldData.formfieldRequired === GenericFormFieldRequiredType.required && !formFieldData.isReadonlyField()"
        class="requiredField"
        >*</span
      >
      <span
        *ngIf="formFieldData.formfieldRequired === GenericFormFieldRequiredType.requiredSoonAsPossible && !formFieldData.isReadonlyField()"
        class="requiredSoonAsPossibileField"
        >*</span
      >
    </ng-container>
  </div>
</div>
