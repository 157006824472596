import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { LogLevel } from '../configs/log-level';
@Injectable({
  providedIn: 'root',
})
export class LogService {
  public static GET = 'GET ';
  public static PUT = 'PUT ';
  public static ERROR = 'Error ';
  public static POST = 'POST ';
  public static REQUEST = 'Request ';
  public static DELETE = 'DELETE ';
  public static RESPONSE = 'Response ';
  public static PARAMS = '** PARAMS **';
  private static JSON_LENGHT_LIMIT = 800;

  public static debug(clazz: any, method: string, msg: string, obj: any) {
    if (this.debLogIsEnabled()) {
      const type = LogLevel.DEBUG;
      if (this.isChromeBrowser()) {
        // Print raw object;
        const message = this.createLogMessage(type, clazz, method, msg, null);
        obj ? console.log(message, obj) : console.log(message);
      } else {
        // Print json serialization of object;
        const message = this.createLogMessage(type, clazz, method, msg, obj);
        console.log(message);
      }
    }
  }

  public static info(clazz: any, method: string, msg: string, obj: any) {
    if (this.infoLogIsEnabled()) {
      const type = LogLevel.INFO;
      if (this.isChromeBrowser()) {
        // Print raw object;
        const message = this.createLogMessage(type, clazz, method, msg, null);
        obj ? console.log(message, obj) : console.log(message);
      } else {
        // Print json serialization of object;
        const message = this.createLogMessage(type, clazz, method, msg, obj);
        console.log(message);
      }
    }
  }

  public static warning(clazz: any, method: string, msg: string, obj: any) {
    if (this.warnLogIsEnabled()) {
      const type = LogLevel.WARNING;
      if (this.isChromeBrowser()) {
        // Print raw object;
        const message = this.createLogMessage(type, clazz, method, msg, null);
        obj ? console.warn(message, obj) : console.warn(message);
      } else {
        // Print json serialization of object;
        const message = this.createLogMessage(type, clazz, method, msg, obj);
        console.warn(message);
      }
    }
  }

  public static error(clazz: any, method: string, msg: string, obj: any) {
    if (this.errLogIsEnabled()) {
      const type = LogLevel.ERROR;
      if (this.isChromeBrowser()) {
        // Print raw object;
        const message = this.createLogMessage(type, clazz, method, msg, null);
        obj ? console.error(message, obj) : console.error(message);
      } else {
        // Print json serialization of object;
        const message = this.createLogMessage(type, clazz, method, msg, obj);
        console.error(message);
      }
    }
  }

  private static debLogIsEnabled() {
    return environment.logLevel === LogLevel.DEBUG;
  }

  private static infoLogIsEnabled() {
    return this.debLogIsEnabled() || environment.logLevel === LogLevel.INFO;
  }

  private static warnLogIsEnabled() {
    return this.debLogIsEnabled() || environment.logLevel === LogLevel.WARNING;
  }

  private static errLogIsEnabled() {
    return this.warnLogIsEnabled() || environment.logLevel === LogLevel.ERROR;
  }

  private static createLogMessage(type: LogLevel, clazz: any, method: string, message: string, object: any): any {
    const s = this.getPrefix(type, clazz, method) + message;
    return object ? s + ' - ' + this.getJsonStringified(object) : s;
  }

  private static getPrefix(t: LogLevel, clazz: any, method: string) {
    const d = moment().format('DD/MM/YYYY HH:mm:ss.SSS');
    let c = 'undefinedClass';
    if (clazz) {
      c = clazz.constructor.name + '.' + method;
    }
    return '[FOLight ' + d + '][' + LogLevel[t] + '][' + c + '] ';
  }

  private static getJsonStringified(object: any) {
    let stringified = '';
    try {
      stringified = JSON.stringify(object);
    } catch (error) {
      stringified = 'The object cannot be stringified';
    }
    if (stringified.length > this.JSON_LENGHT_LIMIT) {
      return stringified.substring(0, this.JSON_LENGHT_LIMIT) + ' ...more';
    }
    return stringified;
  }

  private static isChromeBrowser() {
    return navigator.userAgent.indexOf('Chrome') !== -1 ? true : false;
  }
}
