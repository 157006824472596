import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { BillingitemMoveRequest } from 'src/app/models/billingitem';
import { BillingAccount } from 'src/app/models/vendor-account';
import { MoveBillingitemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-billingitems-wizard/activation-step/movebillingitems-due-date-info';
import { MoveBillingitemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-billingitems-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Move Billingitems Information Step',
  props<{ initStep: MoveBillingitemsInitStep }>()
);

export const selectDueDatesStep = createAction(
  '[Wizard Due Date Step] Select Move Billingitems Due Date Step',
  props<{ dueDatesStep: MoveBillingitemsDueDates }>()
);

export const selectBillingaccountStep = createAction(
  '[Wizard Billing account Step] Select Move Billingitems Billing account Step',
  props<{ billingaccountStep: BillingAccount[] }>()
);

export const deleteBillingaccountStep = createAction('[Wizard Billing account Step] Delete Move Billingitems Billing account Step');

export const exitMoveBillingitemsWizard = createAction('[MoveBillingitems Wizard Page] Exit Move Billingitems Wizard');

export const destroyWizard = createAction('[MoveBillingitems Wizard Page] Destroy Wizard');

export const createNewMoveBillingitems = createAction(
  '[Wizard Confirmation Step] Create Move Billingitems Information Step',
  props<{ request: BillingitemMoveRequest }>()
);

export const createNewMoveBillingitemsSuccess = createAction(
  '[Wizard Confirmation Step] Create Move Billingitems Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewMoveBillingitemsFail = createAction(
  '[Wizard Confirmation Step] Create Move Billingitems Information Step Fail',
  props<{ errorMessage: string }>()
);
