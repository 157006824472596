import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';

@Injectable({
  providedIn: 'root',
})
export class DecimalDateFormatterService {
  currentRegion: string;
  DECIMAL_SEPARATOR: string;
  THOUSANDS_SEPARATOR: string;

  constructor(private translate: TranslateService, protected store: Store<RootStoreState.State>) {
    this.store
      .select(PreferencesSelectors.selectPreferencesLanguageRegion)
      // Replace required for toLocaleString. Accept only Locale in format xx-XX
      .subscribe((langRegion) => {
        this.currentRegion = langRegion && langRegion.region ? langRegion.region.replace('_', '-') : 'en';
        this.DECIMAL_SEPARATOR = this.getDecimalSeparator(this.currentRegion);
        this.THOUSANDS_SEPARATOR = this.getThousandSeparator(this.currentRegion);
      });
  }

  getDecimalSeparator(locale) {
    const numberWithDecimalSeparator = 1.1;
    return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
  }

  getThousandSeparator(locale) {
    const numberWithDecimalSeparator = 1111;
    return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
  }

  getDate(date: Date | moment.Moment | number): string {
    if (date == null) {
      return '';
    }
    moment.locale(this.currentRegion);
    if (typeof date === 'number' || date instanceof Number) {
      return moment(date).isValid() ? moment(date).utc(false).format('L') : '-';
    } else {
      return moment(date).isValid() ? moment(date).utc(true).format('L') : '-';
    }
  }

  getDateNotUTC(date: Date): string {
    if (!date) {
      return '';
    }
    moment.locale(this.currentRegion);
    return moment(date).isValid() ? moment(date).utc(true).format('L') : '-';
  }

  getDatePlaceholder(): string {
    moment.locale(this.currentRegion);
    return moment(new Date()).localeData().longDateFormat('L').toLowerCase();
  }

  getDateRegionalFormat(): string {
    moment.locale(this.currentRegion);
    return moment(new Date()).localeData().longDateFormat('L').toLowerCase().replace('yyyy', 'yy');
  }

  getDateLiteral(date: Date): string {
    if (!date) {
      return '';
    }
    moment.locale(this.currentRegion);
    return moment(date).isValid() ? moment(date).utc(false).format('MMMM Do YYYY') : '-';
  }

  getDateWithoutYear(date: Date): string {
    if (!date) {
      return '';
    }
    // const formatL = moment.localeData().longDateFormat('ll');
    // const formatYearlessL = formatL.replace(/Y/g, '').replace(/^\W|\W$|\W\W/, '');
    moment.locale(this.currentRegion);
    return moment(date).isValid() ? moment(date).utc(false).format('MMM D') : '-';
  }

  getDateWithoutYearNotUTC(date: Date): string {
    if (!date) {
      return '';
    }
    // const formatL = moment.localeData().longDateFormat('ll');
    // const formatYearlessL = formatL.replace(/Y/g, '').replace(/^\W|\W$|\W\W/, '');
    moment.locale(this.currentRegion);
    return moment(date).isValid() ? moment(date).utc(true).format('MMM D') : '-';
  }

  getTime(date: Date): string {
    if (!date) {
      return '';
    }
    moment.locale(this.currentRegion);
    return moment(date).isValid() ? moment(date).utc(true).format('LT') : '-';
  }

  getDatetime(date: Date): string {
    if (!date) {
      return '';
    }
    moment.locale(this.currentRegion);
    return moment(date).isValid() ? this.getDateNotUTC(date) + ' ' + this.getTime(date) : '-';
  }

  getTimestamp(date: Date): string {
    if (!date) {
      return '';
    }
    moment.locale(this.currentRegion);
    return moment(date).isValid() ? this.getDateNotUTC(date) + ' ' + moment(date).utc(true).format('LTS') : '-';
  }

  getDatetimePlaceholder(): string {
    moment.locale(this.currentRegion);
    return (
      moment(new Date()).localeData().longDateFormat('L').toLowerCase() +
      ' ' +
      moment(new Date()).localeData().longDateFormat('LT').toLowerCase()
    );
  }

  getDatetimeRegionalFormat(): string {
    moment.locale(this.currentRegion);
    return moment(new Date()).localeData().longDateFormat('L').toLowerCase().replace('yyyy', 'yy') + ',';
  }

  getCalendarDate(date: Date): string {
    if (!date) {
      return '';
    }
    moment.locale(this.currentRegion);
    return moment(date).isValid() ? moment(date).utc(true).calendar() : '-';
  }

  getInteger(decimal: number): string {
    if (decimal === null || decimal === undefined) {
      return '';
    } else {
      return decimal.toLocaleString(this.currentRegion, {
        style: 'decimal',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }
  }

  // .replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');
  get2DecimalDigits(decimal: number): string {
    if (decimal === null || decimal === undefined) {
      return '';
    } else {
      return decimal.toLocaleString(this.currentRegion, {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      });
    }
  }

  get4DecimalDigits(decimal: number): string {
    if (decimal === null || decimal === undefined) {
      return '';
    } else {
      return decimal.toLocaleString(this.currentRegion, {
        style: 'decimal',
        maximumFractionDigits: 4,
        minimumFractionDigits: 0,
      });
    }
  }

  get6DecimalDigits(decimal: number): string {
    if (decimal === null || decimal === undefined) {
      return '';
    } else {
      return decimal.toLocaleString(this.currentRegion, {
        style: 'decimal',
        maximumFractionDigits: 6,
        minimumFractionDigits: 0,
      });
    }
  }

  get14DecimalDigits(decimal: number): string {
    if (decimal === null || decimal === undefined) {
      return '';
    } else {
      return decimal.toLocaleString(this.currentRegion, {
        style: 'decimal',
        maximumFractionDigits: 14,
        minimumFractionDigits: 0,
      });
    }
  }

  get2DecimalDigitsWithoutThousandSeparator(decimal: number): string {
    if (decimal === null || decimal === undefined) {
      return '';
    } else {
      return decimal
        .toLocaleString(this.currentRegion, {
          style: 'decimal',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })
        .replace(new RegExp(`\\${this.THOUSANDS_SEPARATOR}`, 'g'), '');
    }
  }

  get4DecimalDigitsWithoutThousandSeparator(decimal: number): string {
    if (decimal === null || decimal === undefined) {
      return '';
    } else {
      return decimal
        .toLocaleString(this.currentRegion, {
          style: 'decimal',
          maximumFractionDigits: 4,
          minimumFractionDigits: 0,
        })
        .replace(new RegExp(`\\${this.THOUSANDS_SEPARATOR}`, 'g'), '');
    }
  }

  get6DecimalDigitsWithoutThousandSeparator(decimal: number): string {
    if (decimal === null || decimal === undefined) {
      return '';
    } else {
      return decimal
        .toLocaleString(this.currentRegion, {
          style: 'decimal',
          maximumFractionDigits: 6,
          minimumFractionDigits: 0,
        })
        .replace(new RegExp(`\\${this.THOUSANDS_SEPARATOR}`, 'g'), '');
    }
  }

  get14DecimalDigitsWithoutThousandSeparator(decimal: number): string {
    if (decimal === null || decimal === undefined) {
      return '';
    } else {
      return decimal
        .toLocaleString(this.currentRegion, {
          style: 'decimal',
          maximumFractionDigits: 14,
          minimumFractionDigits: 0,
        })
        .replace(new RegExp(`\\${this.THOUSANDS_SEPARATOR}`, 'g'), '');
    }
  }
}
