import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SubgridModule } from '../subgrid/subgrid.module';
import { TreeCellModule } from '../tree-cell/tree-cell.module';
import { AddRowComponent } from './components/add-row/add-row.component';
import { FilterRowComponent } from './components/filter-row/filter-row.component';
import { TreeBodyComponent } from './tree-body.component';

@NgModule({
  declarations: [TreeBodyComponent, AddRowComponent, FilterRowComponent],
  imports: [CommonModule, TreeCellModule, FormsModule, SubgridModule],
  exports: [TreeBodyComponent],
})
export class TreeBodyModule {}
