import { HttpParams } from '@angular/common/http';

export class BaseParams {
  public static PAGE_OFFSET = 'pageOffset';
  public static PAGE_SIZE = 'pageSize';
  public static ORDER_BY = 'orderBy';
  public static SORT = 'sort';
  public static FILTER = 'filter';
  public static FILTER_RULE = 'filterRule';
  public static FILTER_ELEMENTS = 'filterElements';
}
export class PaginatedRequest {
  pageIndex: number;
  pageSize: number;
  orderBy: string;
  sort: string;
  filter?: string;
  filterRule?: number;
  filterElements?: string[];
  // extra filter for derived classes
  filterFirstId?: number;
  filterFirstText?: string;
  filterFirstDate?: Date;
  filterFirstBoolean?: boolean;
  filterSecondId?: number;
  filterMap?: Map<string, any[]>;

  public static getBaseRequestParams(request: PaginatedRequest): HttpParams {
    const pageOffset = request.pageIndex != null && request.pageSize != null ? request.pageIndex * request.pageSize : 0;
    let params = new HttpParams().set(BaseParams.PAGE_OFFSET, pageOffset.toString());
    if (request.orderBy) {
      params = params.append(BaseParams.ORDER_BY, request.orderBy);
      if (request.sort) {
        params = params.append(BaseParams.SORT, request.sort);
      }
    }
    if (request.pageSize) {
      params = params.append(BaseParams.PAGE_SIZE, request.pageSize.toString());
    }
    if (request.filter) {
      params = params.append(BaseParams.FILTER, request.filter);
      if (request.filterRule != null) {
        params = params.append(BaseParams.FILTER_RULE, request.filterRule);
      }
      if (request.filterElements != null && request.filterElements.length > 0) {
        params = params.append(BaseParams.FILTER_ELEMENTS, request.filterElements.join(','));
      }
    }
    if (request.filterMap) {
      request.filterMap.forEach((ids, filter) => {
        params = params.append(filter, ids.join(','));
      });
    }
    return params;
  }
}
