import { HttpParams } from '@angular/common/http';
import { Entity } from './entity';
import { PaginatedRequest } from './paginated-request';

export class Platform extends Entity {
  platformId: number;
  platformType: string;
  platformName: string;
  platformStatus: string;
  platformRemarks: string;
}

export class PlatformParams {
  public static FILTER_TYPE_IDS = 'filterTypeIds';
  public static FILTER_STATUS_IDS = 'filterStatusIds';
}

export function getPlatformRequestParams(request: PaginatedRequest): HttpParams {
  return PaginatedRequest.getBaseRequestParams(request);
}
