import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { PackageTreeNode } from 'src/app/models/package';
import { AddBillingitemTopackageDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-billingitems-topackage-wizard/activation-step/addbillingitems-due-date-info';
import { AddBillingitemTopackageInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-billingitems-topackage-wizard/init-step/init-step';
import * as fromAddBillingitemTopackageWizard from './reducer';
import { State } from './state';

export const selectAddBillingitemTopackageWizardState = createFeatureSelector<State>(
  fromAddBillingitemTopackageWizard.addBillingitemTopackageWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectAddBillingitemTopackageWizardState,
  (state: State): AddBillingitemTopackageInitStep => state.initStep
);

export const selectDueDateStep = createSelector(
  selectAddBillingitemTopackageWizardState,
  (state: State): AddBillingitemTopackageDueDates => state.dueDatesStep
);

export const selectBillingitemPackagesStep = createSelector(
  selectAddBillingitemTopackageWizardState,
  (state: State): PackageTreeNode[] => state.billingitemPackageStep
);

export const selectAddBillingitemTopackageState = createSelector(
  selectAddBillingitemTopackageWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectAddBillingitemTopackageWizardState, (state: State): string => state.errorMessage);
