import { createAction, props } from '@ngrx/store';
import { RequestValidationResponse } from 'src/app/models/request';
import { ActionRequest } from 'src/app/pages/requests/request-action';

export const requestPageStatusRestored = createAction('[Wizard Page] Request Page Status Restored');

export const validateRequest = createAction('[Requests Page] Validate Request', props<{ requestId: number; requestKindId: number }>());

export const validateRequestResult = createAction(
  '[Requests Page] Validate Request Result',
  props<{ validationResult: RequestValidationResponse }>()
);

export const validateRequestError = createAction('[Requests Page] Validate Request Error', props<{ error: string }>());

export const actionRequest = createAction(
  '[Requests Page] Action Request',
  props<{ requestId: number; action: ActionRequest; remarks?: string }>()
);

export const executionRequestResult = createAction('[Requests Page] Request Execution Result', props<{ executionStatus: boolean }>());

export const rejectionRequestResult = createAction('[Requests Page] Request Rejection Result', props<{ rejectionStatus: boolean }>());

export const approveRequestResult = createAction('[Requests Page] Request Approve Result', props<{ approveStatus: boolean }>());

export const rejectionApprovalRequestResult = createAction(
  '[Requests Page] Request Rejection Approval Result',
  props<{ rejectionApprovalStatus: boolean }>()
);

export const actionRequestError = createAction('[Requests Page] Action Request Error', props<{ error: string }>());

export const actionRequestDone = createAction('[Requests Page] Action Request Done');
