import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entity } from 'src/app/models/entity';
import { GenericEntityFormFieldStep } from 'src/app/models/forms/form-field';
import { EntityFieldsListContentCallBack } from 'src/app/models/generic-entity-field';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from '../../base-components/format-component';

export interface GenericFormForeignModalDialogData {
  entity: Entity;
  isRightButtonString?: boolean;
  rightButtonKey?: string;
}

@Component({
  selector: 'app-generic-form-foreign-modal-dialog',
  templateUrl: './generic-form-foreign-modal-dialog.component.html',
  styleUrls: ['./generic-form-foreign-modal-dialog.component.scss'],
})
export class GenericFormForeignModalDialogComponent extends FormatComponent implements OnInit {
  entitySubj: BehaviorSubject<Entity> = new BehaviorSubject<Entity>(null);
  selectedEntity: Entity;
  atomicDetailsSteps: GenericEntityFormFieldStep[];
  atomicCommonIsLoading = false;
  atomicCommonIsEditing = false;
  isRightButtonString: boolean;
  rightButtonKey: string;
  rightButtonCustomButtonEvEm = new EventEmitter();

  dataIsUpdated = false;

  constructor(
    protected coreDataService: CoreDataService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GenericFormForeignModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericFormForeignModalDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.data.entity) {
      this.isRightButtonString = this.data.isRightButtonString;
      this.rightButtonKey = this.data.rightButtonKey;
      this.selectedEntity = this.data.entity;
      this.loadEntityData();
    } else {
      this.dialogRef.close();
    }
  }

  loadEntityData() {
    this.entitySubj.next(this.selectedEntity);
    this.loadEntityFormFieldSteps();
  }

  async loadEntityFormFieldSteps() {
    if (this.selectedEntity) {
      this.atomicCommonIsLoading = true;
      const { data } = await firstValueFrom(
        this.coreDataService.getGenericEntityFormFieldSteps(
          this.selectedEntity.entityKind,
          this.selectedEntity.entityId,
          this.isTenantAdmin
        )
      );
      if (data) {
        this.atomicDetailsSteps = data;
      }
      this.atomicCommonIsLoading = false;
    }
  }

  getMainAPIFormFieldsPath(formId: number) {
    return this.isTenantAdmin
      ? ApiPath.TenantAdministration.Entities.ENTITY_GET_PUT_FORMFIELDS(
          this.selectedEntity.entityKind,
          this.selectedEntity.entityId,
          formId
        )
      : ApiPath.Entities.ENTITY_GET_PUT_FORMFIELDS(this.selectedEntity.entityKind, this.selectedEntity.entityId, formId);
  }

  putMainAPIFormFieldsPath(formId: number) {
    return this.isTenantAdmin
      ? ApiPath.TenantAdministration.Entities.ENTITY_GET_PUT_FORMFIELDS(
          this.selectedEntity.entityKind,
          this.selectedEntity.entityId,
          formId
        )
      : ApiPath.Entities.ENTITY_GET_PUT_FORMFIELDS(this.selectedEntity.entityKind, this.selectedEntity.entityId, formId);
  }

  createUpdateDetailsCallbackEvEm(callback: EntityFieldsListContentCallBack) {
    const m = this.createUpdateDetailsCallbackEvEm.name;

    LogService.debug(this, m, 'CREATE/UPDATE callback.isSuccess: ' + callback.isSuccess + ' - Callback Object:', callback);
    if (callback.isSuccess) {
      // this.atomicUpdatedEvEm.emit(this.selectedEntity);
    }
    this.atomicCommonIsEditing = callback.isEditing;
    this.loadEntityFormFieldSteps();
    this.dataIsUpdated = true;

    // ? this.isNewEntity = callback.isNewEntity;
  }

  getSidenavTitle() {
    const entityId = this.isShowDetailsEntityid ? ` (${this.selectedEntity.entityId})` : '';
    return this.selectedEntity && this.selectedEntity.entityKind
      ? `${this.translate.instant(this.selectedEntity.entityKind)}${entityId}: ${this.selectedEntity.entityName}`
      : this.selectedEntity.entityName;
  }
}
