import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { PreferenceDataService } from 'src/app/services/preference-data.service';
import * as ModuleItemActions from './actions';

@Injectable()
export class ModuleItemEffects {
  // loadModuleItems$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ModuleItemActions.loadModuleItems),
  //     concatMap(({ isAuth, isTenantAdmin }) =>
  //       this.dataService.getModuleItems(isAuth, isTenantAdmin).pipe(
  //         map((response) => {
  //           if (response.data) {
  //             return ModuleItemActions.loadSuccess({ response });
  //           } else {
  //             return ModuleItemActions.loadFailure({ error: 'No data' });
  //           }
  //         }),
  //         catchError((error) => of(ModuleItemActions.loadFailure({ error })))
  //       )
  //     )
  //   )
  // );

  loadModuleMenuItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModuleItemActions.loadModuleItems),
      concatMap(({ isAuth, isTenantAdmin }) =>
        !isAuth && !isTenantAdmin
          ? of(ModuleItemActions.loadEmptySuccess())
          : this.dataService.getModuleItems(isAuth, isTenantAdmin).pipe(
              map((response) => {
                if (response.data) {
                  return ModuleItemActions.loadSuccess({ response });
                } else {
                  return ModuleItemActions.loadFailure({ error: 'No data' });
                }
              }),
              catchError((error) => of(ModuleItemActions.loadFailure({ error })))
            )
      )
    )
  );

  constructor(private actions$: Actions, private dataService: PreferenceDataService) {}
}
