import { moveItemInArray } from '@angular/cdk/drag-drop';
import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { QuestionType, Questionaire, QuestionaireOption, isQuestionOptionsEnabled } from 'src/app/models/questionaire';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from '../../base-components/format-component';

@Component({
  selector: 'app-questionaire-config-panel',
  templateUrl: './questionaire-config-panel.component.html',
  styleUrls: ['./questionaire-config-panel.component.scss'],
})
export class QuestionaireConfigPanelComponent extends FormatComponent implements OnInit {
  @Input() question: Questionaire;
  @Input() questionOptions: KeyValue<string, string>[];
  @Input() questionIndex: number;
  @Input() questionsLength: number;
  @Input() isEditMode: boolean;
  @Input() isDirtyEnabled: boolean;
  @Input() isCreateWizard: boolean;
  @Input() saveSubj: Observable<void> = new Observable<void>();
  @Output() removeQuestionEvEm = new EventEmitter<void>();
  @Output() addQuestionEvEm = new EventEmitter<void>();
  @Output() moveupQuestionEvEm = new EventEmitter<void>();
  @Output() movedownQuestionEvEm = new EventEmitter<void>();
  isSavePressed = false;

  originalQuestion: Questionaire;
  isPanelExpanded = false;

  dataSourceTable: MatTableDataSource<QuestionaireOption>;
  displayedColumnsTable: string[];
  columns: string[] = [
    'questionSingleSelection',
    'questionMultiSelection',
    'questionOption',
    'questionOptionMoveUp',
    'questionOptionMoveDown',
    'questionOptionRemove',
  ];
  controls: UntypedFormArray;
  isLoading = false;
  hasData = false;

  constructor(public cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.saveSubj) {
      this.subscribe(this.saveSubj, () => {
        this.isSavePressed = true;
      });
    }
    this.originalQuestion = JSON.parse(JSON.stringify(this.question));
    if (!this.question.questionaireAnswerOptions) {
      this.question.questionaireAnswerOptions = [];
    }
    if (!this.question.questionaireAnswerOptionsDeleted) {
      this.question.questionaireAnswerOptionsDeleted = [];
    }
    this.changeOptionColumn();
    this.dataSourceTable = new MatTableDataSource();
    this.dataSourceTable.data = this.question.questionaireAnswerOptions;
    this.initForm();
  }

  changeOptionColumn() {
    if (this.isSingleSelectionOption()) {
      this.displayedColumnsTable = this.columns.filter((c) => c !== 'questionMultiSelection');
    } else if (this.isMultipleSelectionOption()) {
      this.displayedColumnsTable = this.columns.filter((c) => c !== 'questionSingleSelection');
    } else {
      this.displayedColumnsTable = this.columns.filter((c) => c !== 'questionSingleSelection' && c !== 'questionMultiSelection');
      this.question.questionaireHasOtherOption = false;
    }
  }

  resetSelection() {
    this.question.questionaireAnswerOptions.forEach((o) => {
      o.questionaireoptionSelected = false;
    });
  }

  removeQuestion() {
    this.removeQuestionEvEm.emit();
  }

  addQuestion() {
    this.addQuestionEvEm.emit();
  }

  moveUp() {
    this.moveupQuestionEvEm.emit();
  }

  moveDown() {
    this.movedownQuestionEvEm.emit();
  }

  addOption() {
    this.question.questionaireAnswerOptions.push({
      questionaireoptionId: -1,
      questionaireoptionAnswer: '',
      questionaireoptionDeleted: false,
      questionaireoptionSelected: false,
    });
    this.dataSourceTable._updateChangeSubscription();
    this.initForm();
  }

  deleteOption(row: QuestionaireOption, index: number) {
    if (row.questionaireoptionId != null && row.questionaireoptionId !== -1) {
      row.questionaireoptionDeleted = true;
      this.question.questionaireAnswerOptionsDeleted.push(row);
    }
    this.question.questionaireAnswerOptions.splice(index, 1);
    this.dataSourceTable._updateChangeSubscription();
    this.initForm();
  }

  moveOptionUp(index: number) {
    LogService.debug(this, this.moveOptionUp.name, 'index', index);
    moveItemInArray(this.question.questionaireAnswerOptions, index, index - 1);
    this.dataSourceTable._updateChangeSubscription();
    this.initForm();
  }

  moveOptionDown(index: number) {
    LogService.debug(this, this.moveOptionDown.name, 'index', index);
    moveItemInArray(this.question.questionaireAnswerOptions, index, index + 1);
    this.dataSourceTable._updateChangeSubscription();
    this.initForm();
  }

  isOptionsEnabled() {
    return isQuestionOptionsEnabled(this.question);
  }

  isSingleSelectionOption() {
    return this.question.questionaireTypeId === QuestionType.singleSelect || this.question.questionaireTypeId === QuestionType.singleChoise;
  }

  isMultipleSelectionOption() {
    return (
      this.question.questionaireTypeId === QuestionType.multipleSelect || this.question.questionaireTypeId === QuestionType.multipleChoise
    );
  }

  isValorized(value: string): boolean {
    return value !== null && value !== undefined && value !== '';
  }

  updateField($event, index: number, field: string) {
    const control = this.getControl(index, field);
    if ($event && this.dataSourceTable.data[index].questionaireoptionAnswer !== $event) {
      control.markAsDirty();
    }
    this.dataSourceTable.data[index].questionaireoptionAnswer = $event ? $event : control.value; // When enter is pressed, value is passed by event emitter
    if ($event) {
      control.setValue($event);
    }
  }

  resetField(index: number, field: string) {
    const originalValue = this.dataSourceTable.data[index].questionaireoptionAnswer;
    this.getControl(index, field).setValue(originalValue);
  }

  getControl(index: number, fieldName: string) {
    return this.controls.at(index).get(fieldName) as UntypedFormControl;
  }

  private initForm() {
    const toGroups = this.dataSourceTable.data.map((keyvalue) => {
      return new UntypedFormGroup(
        {
          id: new UntypedFormControl(keyvalue.questionaireoptionId, Validators.required),
          value: new UntypedFormControl(keyvalue.questionaireoptionAnswer, Validators.required),
        },
        { updateOn: 'blur' }
      );
    });
    this.controls = new UntypedFormArray(toGroups);
    if (this.isDirtyOptions()) {
      this.question.questionaireAnswerOptions.forEach((o, index) => {
        if (o.questionaireoptionId !== -1) {
          const originalOpt = this.originalQuestion.questionaireAnswerOptions.find(
            (opt) => opt.questionaireoptionId === o.questionaireoptionId
          );
          if (originalOpt && originalOpt.questionaireoptionAnswer !== o.questionaireoptionAnswer) {
            this.getControl(index, 'value').markAsDirty();
          }
        } else {
          this.getControl(index, 'value').markAsDirty();
        }
      });
    }
  }

  isDirtyOptions() {
    return JSON.stringify(this.question.questionaireAnswerOptions) !== JSON.stringify(this.originalQuestion.questionaireAnswerOptions);
  }

  get selectedOptionsStringified() {
    return this.question.questionaireAnswerOptions && this.question.questionaireAnswerOptions.length > 0
      ? this.question.questionaireAnswerOptions.map((o) => o.questionaireoptionAnswer).join(', ')
      : null;
  }
}
