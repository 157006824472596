<button
  mat-raised-button
  color="primary"
  color="warn"
  (click)="taricsNotifications(taricDecideEntitlementDialog)"
  style="margin-right: 0.5rem"
  [disabled]="isDisabled"
>
  <span>{{ 'label_tarics_send_entitlements' | translate }}</span>
</button>

<ng-template #taricDecideEntitlementDialog>
  <div
    class="dialog-draggable-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
  >
    <mat-icon>drag_indicator</mat-icon>
  </div>
  <div class="dialog-header">
    <div class="title">
      <span
        >{{ 'tarics_send_entitlements_decide' | translate }}
        <mat-form-field appearance="outline" class="form-field-input reset-outline-hint" style="width: 150px; font-size: 14px">
          <mat-select disableOptionCentering panelClass="finoffice-matselect-panel" #departementSelect [(ngModel)]="selectedTaricsPlatform">
            <mat-option class="form-field-option" *ngFor="let platform of taricsCount" [value]="platform">
              {{ platform.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        ?</span
      >
    </div>
    <div class="buttons-container">
      <button
        mat-raised-button
        color="primary"
        (click)="$event.stopPropagation(); openTaricsSendEntitlementDialog(taricSendEntitlementDialog)"
      >
        <span>{{ 'label_yes' | translate }}</span>
      </button>
      <span style="margin-left: 0.5rem"></span>
      <button mat-raised-button color="primary" (click)="selectionTaricsPlatformDialog.close()">
        <span>{{ 'label_no' | translate }}</span>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="dialog-content">
    <span>{{ 'tarics_send_entitlements_last_update' | translate }} {{ getDate(selectedTaricsPlatform?.date) }}</span>
  </div>
</ng-template>
<ng-template #taricSendEntitlementDialog>
  <div
    class="dialog-draggable-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
  >
    <mat-icon>drag_indicator</mat-icon>
  </div>
  <div class="dialog-header">
    <ng-container *ngIf="!taricsChanges">
      <span>{{ 'tarics_send_entitlements_collecting_changes' | translate }}</span>
    </ng-container>
    <ng-container *ngIf="taricsChanges">
      <span *ngIf="!taricsChanges.error && !sendingError">{{ 'tarics_send_entitlements_send_changes' | translate }}</span>
      <span *ngIf="taricsChanges.error || sendingError">{{ 'tarics_send_entitlements_error_message' | translate }}</span>
      <div class="buttons-container">
        <ng-container *ngIf="!taricsChanges.error && !sendingError">
          <button
            mat-raised-button
            color="primary"
            (click)="$event.stopPropagation(); sendTaricsNotification()"
            [disabled]="!selectedTaricsPlatform"
          >
            <span>{{ 'label_yes' | translate }}</span>
          </button>
          <span style="margin-left: 0.5rem"></span>
          <button mat-raised-button color="primary" matDialogClose>
            <span>{{ 'label_no' | translate }}</span>
          </button>
        </ng-container>
        <ng-container *ngIf="taricsChanges.error || sendingError">
          <button mat-raised-button color="primary" matDialogClose>
            <span>{{ 'label_close' | translate }}</span>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div mat-dialog-content class="tarics-entitlements-dialog-content">
    <mat-toolbar class="table-footer-toolbar" *ngIf="isLoading || taricsChanges?.error || sendingError">
      <mat-toolbar-row *ngIf="isLoading" class="spinner">
        <mat-spinner diameter="50"></mat-spinner>
      </mat-toolbar-row>
      <mat-toolbar-row *ngIf="sendingError || taricsChanges?.error" class="no-data-message">
        <span class="error" *ngIf="taricsChanges?.error">{{ taricsChanges.error | translate }}</span>
        <span class="error" *ngIf="sendingError">{{ sendingError | translate }}</span>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-accordion class="example-headers-align" multi *ngIf="!isLoading && !taricsChanges.error">
      <ng-container *ngTemplateOutlet="entitlementListTemplate; context: { list: taricsChanges.userAdd, title: userAddTitle }">
      </ng-container>
      <ng-container *ngTemplateOutlet="entitlementListTemplate; context: { list: taricsChanges.userUpdate, title: userUpdateTitle }">
      </ng-container>
      <ng-container *ngTemplateOutlet="entitlementListTemplate; context: { list: taricsChanges.userRemove, title: userRemoveTitle }">
      </ng-container>
      <ng-container *ngTemplateOutlet="entitlementListTemplate; context: { list: taricsChanges.serviceChangeAdd, title: serviceAddTitle }">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="entitlementListTemplate; context: { list: taricsChanges.serviceChangeUpdate, title: serviceUpdateTitle }"
      >
      </ng-container>
      <ng-container
        *ngTemplateOutlet="entitlementListTemplate; context: { list: taricsChanges.serviceChangeRemove, title: serviceRemoveTitle }"
      >
      </ng-container>
      <ng-container
        *ngTemplateOutlet="entitlementListTemplate; context: { list: taricsChanges.entitlementAdd, title: entitlementAddTitle }"
      >
      </ng-container>
      <ng-container
        *ngTemplateOutlet="entitlementListTemplate; context: { list: taricsChanges.entitlementUpdate, title: entitlementUpdateTitle }"
      >
      </ng-container>
      <ng-container
        *ngTemplateOutlet="entitlementListTemplate; context: { list: taricsChanges.entitlementRemove, title: entitlementRemoveTitle }"
      >
      </ng-container>
    </mat-accordion>
  </div>
</ng-template>

<ng-template #entitlementListTemplate let-entitlements="list" let-title="title">
  <ng-container *ngIf="entitlements">
    <mat-expansion-panel [expanded]="false" class="detail-expansion-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>{{ title | translate }} ({{ entitlements.length }})</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="detail-expansion-panel-body">
        <div class="entity-table">
          <div class="table-wrapper">
            <div class="table-container">
              <table class="table table-stripes table-truncate-text no-header-table" mat-table [dataSource]="entitlements">
                <ng-container matColumnDef="entitlementchangeMessage">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell showIfTruncated [matTooltip]="row.entitlementchangeMessage" *matCellDef="let row">
                    {{ row.entitlementchangeMessage }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </ng-container>
</ng-template>
