import { IdValue } from './core/key-value-data';
import { Entity } from './entity';
import { TaricsNotificationCount } from './interfaces/tarics/tarics-entitlement-change';

export class Notification extends Entity {
  notificationId: number;
  notificationType: string;
  notificationSource: string;
  notificationDate: Date;
  notificationMessage: string;
  notificationEntityKind: string;
  notificationEntityId: number;
  notificationEntityUpdate: boolean;
  notificationEntityDelete: boolean;
  notificationRead: boolean;
  notificationDescription: string;
  notificationDismissed: Date;
  notificationInfo: string;
  notificationKind: string;
  notificationKindId: number;
  notificationMailsentStatus: string;
  notificationShowtoastr: boolean;
  notificationSourceId: number;
  notificationTypeId: number;
  notificationUser: string;
  notificationUserId: number;
}

export class NotificationCount {
  countNotification: number;
  countEmail: number;
  countTaricsEntitlement: TaricsNotificationCount[];
  toastrMessages: IdValue<number, string>[];
}

export class NotificationParams {
  public static FILTER_IDS = 'filterIds';
  public static FILTER_KIND_IDS = 'filterKindIds';
  public static FILTER_TYPE_IDS = 'filterTypeIds';
}

export enum NotificationKind {
  task = 1,
  info = 2,
  warning = 4,
  error = 8,
}
