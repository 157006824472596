import { Action, createReducer, on } from '@ngrx/store';
import * as FoUserLicenseServicesStoreAction from './actions';
import { State, featureAdapter, initialState } from './state';

export const fromUserLicenseServicesFeatureKey = 'foUserLicenseServices';

const userLicenseServicesReducer = createReducer(
  initialState,

  on(FoUserLicenseServicesStoreAction.loadUserLicenceServices, (state) =>
    featureAdapter.removeAll({
      ...state,
      isLoading: true,
      error: null,
      noData: false,
    })
  ),
  on(FoUserLicenseServicesStoreAction.loadSuccess, (state, { response }) =>
    featureAdapter.setAll(response.data, {
      ...state,
      isLoading: false,
      error: null,
    })
  ),
  on(FoUserLicenseServicesStoreAction.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(FoUserLicenseServicesStoreAction.noData, (state) => ({
    ...state,
    isLoading: false,
    error: null,
    noData: true,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return userLicenseServicesReducer(state, action);
}
