import { ChangeDetectorRef, Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { Entity, EntitySelection } from 'src/app/models/entity';
import { Invoice, InvoiceParams } from 'src/app/models/invoice';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { RootStoreState } from 'src/app/root-store';
import { InvoiceDataService } from 'src/app/services/invoice-data.service';
import { MessageBox, MessageBoxButton, MessageBoxStyle, MessageBoxType } from 'src/app/shared/message-box';
import { MultiselectionGridBaseComponent } from '../multiselection-grid-base-component';

@Component({
  selector: 'app-multiselection-invoice-grid',
  templateUrl: './multiselection-invoice-grid.component.html',
  styleUrls: ['./multiselection-invoice-grid.component.scss'],
})
export class MultiselectionInvoiceGridComponent extends MultiselectionGridBaseComponent {
  constructor(
    private invoiceDataService: InvoiceDataService,
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef
  ) {
    super(store, cdRef);
  }

  GRID_NAME = 'tab_grid_invoice';

  gbGetDisplayColumnTable() {
    return [
      'selection',
      'invoice_invoiceno',
      'invoice_reference',
      'invoice_account',
      'invoice_vendor',
      'invoice_starts',
      'invoice_ends',
      'invoice_subtotal',
      'invoice_total',
    ];
  }

  gbGetInitialOrderBy() {
    return 'invoice_date';
  }

  gbGetInitialSort() {
    return 'desc';
  }

  gbLoadEntitiesData() {
    this.clearSelection();

    this.isLoading = true;
    this.isLoadingEvEm.emit(this.isLoading);
    this.dataSourceTable = new MatTableDataSource();
    this.noEntityData = false;
    if (this.gridLoadSubscription != null) {
      this.gridLoadSubscription.unsubscribe();
    }
    this.gridLoadSubscription = this.subscribe(
      this.invoiceDataService.getInvoices(this.request, false),
      (response) => {
        this.lastUsedFilters = response.filters;
        if (response.data) {
          this.dataSourceTable.data = response.data as Entity[];
          this.pageTotalElements = response.data[0].entityCount;
          this.initSelection();
        } else {
          this.pageTotalElements = 0;
          this.noEntityData = true;
        }
        if (this.addModeSelectAllEnabled) {
          this.addSelectAllEntry();
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      },
      () => {
        this.isLoading = false;
        this.isLoadingEvEm.emit(this.isLoading);
      }
    );
  }

  askRemoveAllFiltered() {
    MessageBox.show(
      this.dialog,
      this.translate.instant(_('label_filter_confirm_remove_all_filtered'), { value: this.selectionDataSourceTable.filteredData.length }),
      this.translate.instant('label_confirm'),
      null,
      MessageBoxType.Comfirm,
      MessageBoxButton.YesNo,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'yes') {
        this.clearSelectionEvEm.emit();
      }
    });
  }

  askAddAllFiltered() {
    MessageBox.show(
      this.dialog,
      this.translate.instant(_('label_filter_confirm_add_all_filtered'), { value: this.pageTotalElements }),
      this.translate.instant('label_confirm'),
      null,
      MessageBoxType.Comfirm,
      MessageBoxButton.YesNo,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'yes') {
        this.addAllFiltered();
      }
    });
  }

  addAllFiltered() {
    this.isLoadingSelection = true;
    this.isLoadingSelectionEvEm.emit(this.isLoadingSelection);
    const noPaginationRequest: PaginatedRequest = Object.assign({}, this.request);
    noPaginationRequest.pageSize = null;
    this.subscribe(this.invoiceDataService.getInvoices(noPaginationRequest, false), (response) => {
      this.lastUsedFilters = response.filters;
      if (response.data) {
        const entities: EntitySelection[] = response.data.map((row) => {
          return { ...(row as Entity), deleted: false };
        });
        this.entitiesSelectedEvEm.emit(entities);
      }
      this.isLoadingSelection = false;
      this.isLoadingSelectionEvEm.emit(this.isLoadingSelection);
    });
  }

  addSelectAllEntry() {
    const allRelations: Invoice = {
      ...new Invoice(),
      entityId: -1,
      entityName: `${this.translate.instant('label_none')}`,
      invoiceInvoiceno: `${this.translate.instant('label_none')}`,
    };
    this.dataSourceTable.data.unshift(allRelations);
    this.dataSourceTable._updateChangeSubscription();
  }

  get InvoiceParams() {
    return InvoiceParams;
  }
}
