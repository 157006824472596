import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CatalogItemType } from 'src/app/models/catalog-item-particulars';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { AssetParticulars, AssetTreeNode } from '../models/asset';
import { AssetAllocation } from '../models/asset-allocation';
import { AssetPerBillingVendorTreeNode } from '../models/asset-per-billingvendor';
import { AssetPerContractTreeNode } from '../models/asset-per-contract';
import { AssetSubscription, AssetSubscriptionQuantity, AssetSubscriptionQuantityRequest } from '../models/asset-subscription';
import { BaseResponse } from '../models/base-response';
import { DateResponse } from '../models/core/date-period';
import { EntityWizardResponse } from '../models/forms/entity-wizard';
import { PaginatedRequest } from '../models/paginated-request';
import { UserPerAssetTreeNode } from '../models/user-per-asset';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class AssetsDataService {
  getAssetNodes(
    request: PaginatedRequest,
    assetId?: number,
    categoryId?: number,
    subscriptionId?: number,
    nodeId?: number
  ): Observable<BaseResponse<AssetTreeNode[]>> {
    const m = this.getAssetNodes.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_NODES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'assetId', assetId);
    LogService.info(this, m, 'categoryId', categoryId);
    LogService.info(this, m, 'subscriptionId', subscriptionId);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = assetId != null ? params.append('assetId', assetId.toString()) : params;
    params = categoryId != null ? params.append('categoryId', categoryId.toString()) : params;
    params = subscriptionId != null ? params.append('subscriptionId', subscriptionId.toString()) : params;
    params = nodeId != null ? params.append('filterIds', nodeId.toString()) : params;
    const retVal = this.http.get<BaseResponse<AssetTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  copyAsset(assetId: number, assetUniqueId: string, deskId: number, activationDate: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.copyAsset.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_COPY(assetId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    let params = new HttpParams().set('assetUniqueId', assetUniqueId).set('deskId', deskId).set('activationDate', activationDate);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  startGeneratingAssetNodesSpreadsheet(request: PaginatedRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.startGeneratingAssetNodesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_NODES_SPREADSHEET);
    LogService.info(this, m, LogService.POST + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, null, { params });

    return retVal;
  }

  getAssetSpreadsheetByAssetId(assetId: number) {
    const m = this.getAssetSpreadsheetByAssetId.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SPREADSHEET_BYASSETID(assetId));
    LogService.info(this, m, LogService.GET + path, null);
    return this.http
      .get(path, {
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getAssetPerBillingVendorNodes(
    request: PaginatedRequest,
    billingvendorId?: number,
    providerId?: number,
    assetId?: number,
    nodeId?: number
  ): Observable<BaseResponse<AssetPerBillingVendorTreeNode[]>> {
    const m = this.getAssetPerBillingVendorNodes.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSET_PER_VENDOR_NODES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'billingvendorId', billingvendorId);
    LogService.info(this, m, 'providerId', providerId);
    LogService.info(this, m, 'assetId', assetId);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = billingvendorId != null ? params.append('billingvendorId', billingvendorId.toString()) : params;
    params = providerId != null ? params.append('providerId', providerId.toString()) : params;
    params = assetId != null ? params.append('assetId', assetId.toString()) : params;
    params = nodeId != null ? params.append('filterIds', nodeId.toString()) : params;
    const retVal = this.http.get<BaseResponse<AssetPerBillingVendorTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  startGeneratingAssetPerBillingVendorNodesSpreadsheet(request: PaginatedRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.startGeneratingAssetPerBillingVendorNodesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSET_PER_VENDOR_NODES_SPREADSHEET);
    LogService.info(this, m, LogService.POST + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, null, { params });

    return retVal;
  }

  getAssetperContractNodes(
    request: PaginatedRequest,
    vendorId?: number,
    billingaccountId?: number,
    assetId?: number,
    categoryId?: number,
    nodeId?: number
  ): Observable<BaseResponse<AssetPerContractTreeNode[]>> {
    const m = this.getAssetNodes.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSET_PER_VENDOR_PER_BILLINGACCOUNT_NODES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'vendorId', vendorId);
    LogService.info(this, m, 'billingaccountId', billingaccountId);
    LogService.info(this, m, 'categoryId', categoryId);
    LogService.info(this, m, 'assetId', assetId);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = vendorId != null ? params.append('vendorId', vendorId.toString()) : params;
    params = billingaccountId != null ? params.append('billingaccountId', billingaccountId.toString()) : params;
    params = assetId != null ? params.append('assetId', assetId.toString()) : params;
    params = categoryId != null ? params.append('categoryId', categoryId.toString()) : params;
    params = nodeId != null ? params.append('filterIds', nodeId.toString()) : params;
    const retVal = this.http.get<BaseResponse<AssetPerContractTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getAssetperContractNodesSpreadsheet(request: PaginatedRequest) {
    const m = this.getAssetperContractNodesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSET_PER_VENDOR_PER_BILLINGACCOUNT_NODES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getUserPerAssetNodes(request: PaginatedRequest, serviceId?: number, nodeId?: number): Observable<BaseResponse<UserPerAssetTreeNode[]>> {
    const m = this.getAssetNodes.name;

    const path = environment.getEndpoint(ApiPath.Assets.USERPERASSET_NODES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'serviceId', serviceId);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = serviceId != null ? params.append('serviceId', serviceId.toString()) : params;
    params = nodeId != null ? params.append('filterIds', nodeId.toString()) : params;
    const retVal = this.http.get<BaseResponse<UserPerAssetTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getUserPerAssetNodesSpreadsheet(request: PaginatedRequest) {
    const m = this.getUserPerAssetNodesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Assets.USERPERASSET_NODES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getAssetsAllocations(request: PaginatedRequest): Observable<BaseResponse<AssetAllocation[]>> {
    const m = this.getAssetsAllocations.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ALLOCATIONS);
    LogService.info(this, m, LogService.GET + path, null);

    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<AssetAllocation[]>>(path, {
      params,
    });

    return retVal;
  }

  getAssetsAllocationsByDeskId(deskId: number, assetId: number, filterEnds: string): Observable<BaseResponse<AssetAllocation[]>> {
    const m = this.getAssetsAllocations.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ALLOCATIONS);
    LogService.info(this, m, LogService.GET + path, null);

    const params = new HttpParams().set('filterDeskIds', deskId).set('filterAssetIds', assetId).set('filterEnds', filterEnds);
    const retVal = this.http.get<BaseResponse<AssetAllocation[]>>(path, {
      params,
    });

    return retVal;
  }

  getAssetAllocationsDatesByAssetId(assetId: number): Observable<BaseResponse<DateResponse>> {
    const m = this.getAssetsAllocations.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ALLOCATIONS_STEPDATES(assetId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<DateResponse>>(path);

    return retVal;
  }

  getAssetsAllocationsAddon(request: PaginatedRequest): Observable<BaseResponse<AssetSubscription[]>> {
    const m = this.getAssetsAllocationsAddon.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SUBSCRIPTIONS);
    LogService.info(this, m, LogService.GET + path, null);

    let params = PaginatedRequest.getBaseRequestParams(request);
    params = params.append('filterCatalogitemtypeIds', CatalogItemType.addon);
    const retVal = this.http.get<BaseResponse<AssetSubscription[]>>(path, {
      params,
    });

    return retVal;
  }

  getAssetsParticularsDefaults(productfamilyId: number): Observable<BaseResponse<AssetParticulars>> {
    const m = this.getAssetsAllocationsAddon.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_PARTTICULARS_DEFAULTS);
    LogService.info(this, m, LogService.GET + path, null);

    const params = new HttpParams().set('productfamilyId', productfamilyId);
    const retVal = this.http.get<BaseResponse<AssetParticulars>>(path, {
      params,
    });

    return retVal;
  }

  getAssetSubscriptionQuantity(
    filterAssetIds: number[],
    filterSubscriptionIds: number[],
    filterBillingitemIds: number[],
    filterDeliveryitemIds: number[],
    filterServiceIds: number[]
  ): Observable<BaseResponse<AssetSubscriptionQuantity>> {
    const m = this.getAssetSubscriptionQuantity.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SUBSCRIPTIONS_CHANGE_QUANTITY);
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams();
    if (filterAssetIds) {
      params = params.append('filterAssetIds', filterAssetIds.join(','));
    }
    if (filterSubscriptionIds) {
      params = params.append('filterSubscriptionIds', filterSubscriptionIds.join(','));
    }
    if (filterBillingitemIds) {
      params = params.append('filterBillingitemIds', filterBillingitemIds.join(','));
    }
    if (filterDeliveryitemIds) {
      params = params.append('filterDeliveryitemIds', filterDeliveryitemIds.join(','));
    }
    if (filterServiceIds) {
      params = params.append('filterServiceIds', filterServiceIds.join(','));
    }
    const retVal = this.http.get<BaseResponse<AssetSubscriptionQuantity>>(path, {
      params,
    });

    return retVal;
  }

  changeAssetSubscriptionQuantity(request: AssetSubscriptionQuantityRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.changeAssetSubscriptionQuantity.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SUBSCRIPTIONS_CHANGE_QUANTITY);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
