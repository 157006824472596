import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { RemoveRecordsModalComponent } from 'src/app/core/modal/remove-records-modal/remove-records-modal.component';
import { Chat } from 'src/app/models/chat';
import { Entity } from 'src/app/models/entity';
import { CommentChatDataService } from 'src/app/services/comment-chat-data.service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from '../../base-components/format-component';

@Component({
  selector: 'app-generic-comment-chat',
  templateUrl: './generic-comment-chat.component.html',
  styleUrls: ['./generic-comment-chat.component.scss'],
})
export class GenericCommentChatComponent extends FormatComponent implements OnInit {
  @ViewChild('commentsContainer') private commentsContainer: ElementRef;
  @Input() entitySubj: Observable<Entity>;
  @Input() entityApiPath: string;
  @Input() isEditing: boolean;
  @Output() isEditingChange = new EventEmitter<boolean>();

  selectedEntity: Entity;
  isLoading = false;
  noEntityData = false;

  comments: Chat[];
  inputComment: string;
  inputCommentId = -1;

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected messageNotifierService: MessageNotifierService,
    private chatService: CommentChatDataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.entitySubj, (entity) => {
      if (entity) {
        this.selectedEntity = entity;
        this.loadComments();
      }
    });
  }

  loadComments() {
    this.isLoading = true;
    this.noEntityData = false;
    this.subscribe(
      this.chatService.getChats(this.entityApiPath, this.selectedEntity.entityId),
      (response) => {
        if (response.data) {
          this.noEntityData = false;
          this.isLoading = false;
          this.comments = response.data;
        } else {
          this.comments = [];
          this.isLoading = false;
          this.noEntityData = true;
        }
        this.scrollToBottom();
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      }
    );
  }

  removeSingleRecords(chat: Chat) {
    this.subscribe(
      this.dialog
        .open(RemoveRecordsModalComponent, {
          width: '70vw',
          minHeight: '60vh',
          autoFocus: false,
          disableClose: true,
          data: {
            entities: [chat],
            isTenantAdmin: this.isTenantAdmin,
          },
        })
        .afterClosed(),
      (result) => {
        if (result) {
          this.loadComments();
        }
      }
    );
  }

  editComment(chat: Chat) {
    this.inputComment = chat.chatMessage;
    this.inputCommentId = chat.chatId;
  }

  sendComment() {
    if (this.inputComment && this.inputComment !== '' && this.inputCommentId === -1) {
      this.subscribe(this.chatService.sendChatMessage(this.entityApiPath, this.selectedEntity.entityId, this.inputComment), ({ data }) => {
        if (data && data.state) {
          this.inputComment = null;
          this.inputCommentId = -1;
          this.loadComments();
          this.goViewMode();
        } else if (data && data.error) {
          // Fail
          this.messageNotifierService.showErrorMessage(data.error, data.systemerrorId);
        } else {
          this.messageNotifierService.showWarningMessage('toastr_no_data');
        }
      });
    } else if (this.inputComment && this.inputComment !== '' && this.inputCommentId !== -1) {
      this.subscribe(
        this.chatService.putChatMessage(this.entityApiPath, this.selectedEntity.entityId, this.inputComment, this.inputCommentId),
        ({ data }) => {
          if (data && data.state) {
            this.inputComment = null;
            this.inputCommentId = -1;
            this.loadComments();
            this.goViewMode();
          } else if (data && data.error) {
            // Fail
            this.messageNotifierService.showErrorMessage(data.error, data.systemerrorId);
          } else {
            this.messageNotifierService.showWarningMessage('toastr_no_data');
          }
        }
      );
    }
  }

  isCommentModified(chat: Chat) {
    if (chat.chatModified != null) {
      return !moment(chat.chatModified).isSame(chat.chatCreated);
    }
    return false;
  }

  goEditMode() {
    this.isEditing = true;
    this.isEditingChange.emit(this.isEditing);
    this.scrollToBottom();
  }

  goViewMode() {
    this.isEditing = false;
    this.isEditingChange.emit(this.isEditing);
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    this.cdRef.detectChanges();
    try {
      if (this.commentsContainer) {
        this.commentsContainer.nativeElement.scrollTop = this.commentsContainer.nativeElement.scrollHeight;
      }
    } catch (err) {
      //
    }
  }

  get AuthUser() {
    return this.authUser;
  }
}
