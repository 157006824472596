import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { FileGridNode } from 'src/app/models/file-grid-node';
import { LogService } from 'src/app/services/log-service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { FileTabType } from 'src/app/shared/files/file-tab/file-tab-type';
import { TreeEntity } from 'src/app/shared/files/file-tree/file-tree.component';
import { FilesMultiselectionTableComponent } from '../files-multiselection-table/files-multiselection-table.component';
import { FilesMultiselectionTreeComponent } from '../files-multiselection-tree/files-multiselection-tree.component';

@Component({
  selector: 'app-files-multiselection-tab',
  templateUrl: './files-multiselection-tab.component.html',
  styleUrls: ['./files-multiselection-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilesMultiselectionTabComponent extends FormatComponent implements OnInit {
  @ViewChild(FilesMultiselectionTableComponent) fileTable: FilesMultiselectionTableComponent;
  @ViewChild(FilesMultiselectionTreeComponent) fileTree: FilesMultiselectionTreeComponent;
  @Input() tabType: FileTabType;
  @Input() rootNodeLabel = 'label_root';
  @Input() isMultiselect: boolean;
  @Output() fileSelected = new EventEmitter<FileGridNode[]>();
  @Output() treeNodeClickEvEm = new EventEmitter<void>();

  selectedTreeNode: TreeEntity;

  eventsSubject: Subject<TreeEntity> = new Subject<TreeEntity>();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (this.tabType === null || this.tabType === undefined) {
      throw new TypeError('The input ‘tabType’ is required');
    }
    this.cdRef.detectChanges();
  }

  treeNodeClick(entity: TreeEntity) {
    const m = this.treeNodeClick.name;

    LogService.info(this, m, 'On Node Clicked', entity);
    this.selectedTreeNode = entity;
    this.eventsSubject.next(entity);
    this.treeNodeClickEvEm.emit();
  }
}
