import { ActionResponse } from 'src/app/models/action-response';
import { ReplaceProductServiceInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-product-service-wizard/init-step/init-step';
import { ReplaceProductServiceBillingStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-product-service-wizard/replace-product-service-billing-step/replace-product-service-billing-step';
import { ReplaceProductServiceRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-product-service-wizard/replace-product-service-confirm-step/replace-product-service-request';
import { ReplaceProductServiceReplacementStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/replace-product-service-wizard/replace-product-service-replacement-step/replace-product-service-replacement-step';

export interface State {
  initStep: ReplaceProductServiceInitStep;
  replacementStep: ReplaceProductServiceReplacementStep;
  billingStep: ReplaceProductServiceBillingStep;
  request: ReplaceProductServiceRequest;
  response: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  replacementStep: null,
  billingStep: null,
  request: null,
  response: null,
  errorMessage: null,
};
