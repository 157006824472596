import { Action, createReducer, on } from '@ngrx/store';
import * as CancelConsumersFromAssetWizardActions from './actions';
import { State, initialState } from './state';

export const cancelConsumersFromAssetWizardFeatureKey = 'cancelConsumersFromAssetWizard';

const cancelConsumersFromAssetWizardReducer = createReducer(
  initialState,

  on(CancelConsumersFromAssetWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(CancelConsumersFromAssetWizardActions.selectConsumersStep, (state, { consumersStep }) => ({
    ...state,
    consumersStep,
  })),
  on(CancelConsumersFromAssetWizardActions.deleteConsumersStep, (state) => ({
    ...state,
    consumersStep: null,
  })),
  on(CancelConsumersFromAssetWizardActions.selectBillingStep, (state, { billingStep }) => ({
    ...state,
    billingStep,
  })),
  on(CancelConsumersFromAssetWizardActions.deleteBillingStep, (state) => ({
    ...state,
    billingStep: null,
  })),
  on(CancelConsumersFromAssetWizardActions.exitCancelConsumersFromAssetWizard, (state) => ({
    ...state,
    initStep: null,
    consumersStep: null,
    billingStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(CancelConsumersFromAssetWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    consumersStep: null,
    billingStep: null,
    errorMessage: null,
    request: null,
    response: null,
  })),
  on(CancelConsumersFromAssetWizardActions.createNewCancelConsumersFromAsset, (state, { request }) => ({
    ...state,
    request,
  })),
  on(CancelConsumersFromAssetWizardActions.createNewCancelConsumersFromAssetSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(CancelConsumersFromAssetWizardActions.createNewCancelConsumersFromAssetFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return cancelConsumersFromAssetWizardReducer(state, action);
}
