/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* Note eslint disabled due to low level javascript operations */

import { Component, ContentChild, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, fromEvent } from 'rxjs';
import { filter, switchMapTo, take } from 'rxjs/operators';
import { EditModeDirective } from './edit-mode.directive';
import { ViewModeDirective } from './view-mode.directive';

@UntilDestroy()
@Component({
  selector: 'app-editable, editable',
  templateUrl: './editable.component.html',
  styleUrls: ['./editable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditableComponent implements OnInit {
  @ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective) editModeTpl: EditModeDirective;
  @Output() update = new EventEmitter();
  @Output() resetField = new EventEmitter<void>();

  editMode = new Subject();
  editMode$ = this.editMode.asObservable();
  selectedTarget;

  mode: 'view' | 'edit' = 'view';

  constructor(private host: ElementRef) {}

  ngOnInit(): void {
    this.viewModeHandler();
    this.editModeHandler();
  }

  toViewMode(update: boolean, value?: number): void {
    if (update) {
      this.update.next(value);
    } else {
      this.resetField.next();
    }
    this.mode = 'view';
  }

  private get element() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.host.nativeElement;
  }

  private viewModeHandler() {
    // fromEvent(this.element, 'dblclick')
    fromEvent(this.element, 'click')
      .pipe(untilDestroyed(this))
      .subscribe(($event) => {
        // ($event as MouseEvent).stopPropagation();
        const e = $event as MouseEvent;
        this.selectedTarget = e.target;
        // console.log('[EDITABLE] $event', e.target);
        this.mode = 'edit';
        this.editMode.next(true);
      });
  }

  private editModeHandler() {
    const clickOutside$ = fromEvent(document, 'click').pipe(
      filter(({ target }) => {
        // console.log('[EDITABLE] target', target);
        // console.log('[EDITABLE] classname', (target as any).className);
        return (
          this.selectedTarget.contains(target) === false &&
          (target as any).outerHTML.includes('mat-calendar') === false &&
          (target as any).outerHTML.includes('mat-calendar-period-button') === false &&
          (target as any).outerHTML.includes('mat-calendar-arrow') === false &&
          (target as any).outerHTML.includes('mat-calendar-custom-double-arrow') === false &&
          (target as any).outerHTML.includes('mat-calendar-custom-icon') === false &&
          (target as any).outerHTML.includes('p-datepicker') === false &&
          (target as any).outerHTML.includes('p-yearpicker-year') === false &&
          (target as any).outerHTML.includes('p-monthpicker-month') === false &&
          (target as any).outerHTML.includes('p-datepicker-todaybutton') === false
          // && (target as any).outerHTML.includes('p-datepicker-closebutton') === false
        );
      }),
      take(1)
    );

    const keyUp$ = fromEvent(this.element, 'keyup');

    this.editMode$.pipe(switchMapTo(keyUp$), untilDestroyed(this)).subscribe((event) => {
      console.log('keyUp$', (event as any).target.value);
      this.update.next((event as any).target.value);
    });

    this.editMode$.pipe(switchMapTo(clickOutside$), untilDestroyed(this)).subscribe(() => {
      this.toViewMode(true);
    });
  }

  get currentView(): TemplateRef<any> {
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }
}
