import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { EntityWizardResponse } from '../models/forms/entity-wizard';
import { PaginatedRequest } from '../models/paginated-request';
import { PolicyRelation, PolicyRelationRequest } from '../models/policy';
import { Vendor, VendorParticulars, VendorParticularsRequest, VendorTreeNode } from '../models/vendor';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class VendorDataService {
  getVendors(request: PaginatedRequest): Observable<BaseResponse<Vendor[]>> {
    const m = this.getVendors.name;

    const path = environment.getEndpoint(ApiPath.Vendors.VENDORS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Vendor[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getVendorsSpreadsheet(request: PaginatedRequest) {
    const m = this.getVendorsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Vendors.VENDORS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getVendorNodes(request: PaginatedRequest, parentId?: number): Observable<BaseResponse<VendorTreeNode[]>> {
    const m = this.getVendorNodes.name;

    const organisationId = parentId != null ? parentId : -1;
    const path = `${environment.getEndpoint(ApiPath.Vendors.VENDORS)}/${organisationId}/nodes`;
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'organisationId', organisationId);
    const retVal = this.http.get<BaseResponse<VendorTreeNode[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getVendorParticularsDefault(): Observable<BaseResponse<VendorParticulars>> {
    const m = this.getVendorParticularsDefault.name;

    const path = environment.getEndpoint(ApiPath.Vendors.VENDOR_PARTICULAR_DEFAULTS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<VendorParticulars>>(path);
    return retVal;
  }

  createVendorParticulars(request: VendorParticularsRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.createVendorParticulars.name;

    const path = environment.getEndpoint(ApiPath.Vendors.VENDOR_PARTICULARS);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);
    return retVal;
  }

  getVendorParticulars(vendorId: number): Observable<BaseResponse<VendorParticulars>> {
    const m = this.getVendorParticulars.name;

    const path = environment.getEndpoint(ApiPath.Vendors.VENDOR_PARTICULARS_BYID(vendorId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<VendorParticulars>>(path);
    return retVal;
  }

  putVendorParticulars(vendorId: number, request: VendorParticularsRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.putVendorParticulars.name;

    const path = environment.getEndpoint(ApiPath.Vendors.VENDOR_PARTICULARS_BYID(vendorId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);
    return retVal;
  }

  getVendorPolicies(vendorId: number): Observable<BaseResponse<PolicyRelation[]>> {
    const m = this.getVendorNodes.name;

    const path = environment.getEndpoint(ApiPath.Vendors.VENDOR_POLICIES(vendorId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<PolicyRelation[]>>(path);

    return retVal;
  }

  saveVendorPolicies(vendorId: number, request: PolicyRelationRequest[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.getVendorNodes.name;

    const path = environment.getEndpoint(ApiPath.Vendors.VENDOR_POLICIES(vendorId));
    LogService.info(this, m, LogService.POST + path, null);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  // getVendorProfiles(vendorId: number): Observable<BaseResponse<VendorProfile[]>> {
  //   const m = this.getVendorNodes.name;

  //   const path = environment.getEndpoint(ApiPath.Vendors.VENDOR_PROFILES(vendorId));
  //   LogService.info(this, m, LogService.GET + path, null);

  //   const retVal = this.http.get<BaseResponse<VendorProfile[]>>(path);

  //   return retVal;
  // }

  // saveVendorProfiles(vendorId: number, request: VendorProfileRequest[]): Observable<BaseResponse<ActionResponse>> {
  //   const m = this.getVendorNodes.name;

  //   const path = environment.getEndpoint(ApiPath.Vendors.VENDOR_PROFILES(vendorId));
  //   LogService.info(this, m, LogService.POST + path, null);

  //   const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

  //   return retVal;
  // }

  getVendorProfilesSpreadsheet(vendorId: number) {
    const m = this.getVendorProfilesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Vendors.VENDOR_PROFILES_SPREADSHEET(vendorId));
    LogService.info(this, m, LogService.GET + path, null);

    return this.http
      .get(path, {
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  constructor(private http: HttpClient) {}
}
