import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { EntityWizardResponse } from '../models/forms/entity-wizard';
import { PaginatedRequest } from '../models/paginated-request';
import { User, UserParticularRequest } from '../models/user';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  getUsers(request: PaginatedRequest): Observable<BaseResponse<User[]>> {
    const m = this.getUsers.name;

    const path = environment.getEndpoint(ApiPath.Users.USERS);
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<User[]>>(path, {
      params,
    });

    return retVal;
  }

  createUserParticulars(request: UserParticularRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.createUserParticulars.name;

    const path = environment.getEndpoint(ApiPath.Users.USERS_PARTICULARS);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);
    return retVal;
  }

  getUserParticulars(userId: number): Observable<BaseResponse<User>> {
    const m = this.getUserParticulars.name;

    const path = environment.getEndpoint(ApiPath.Users.USERS_PARTICULARS_BYID(userId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<User>>(path);
    return retVal;
  }

  putUserParticulars(userId: number, request: UserParticularRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.putUserParticulars.name;

    const path = environment.getEndpoint(ApiPath.Users.USERS_PARTICULARS_BYID(userId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);
    return retVal;
  }

  checkExistingUser(firstname: string, lastname: string, login: string): Observable<BaseResponse<User[]>> {
    const m = this.getUsers.name;

    const path = environment.getEndpoint(ApiPath.Users.USERS);
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams().set('filterLastnames', lastname).set('filterFirstnames', firstname).set('filterLogins', login);
    const retVal = this.http.get<BaseResponse<User[]>>(path, {
      params,
    });

    return retVal;
  }

  getUserResponsibilies(userId: number): Observable<BaseResponse<User[]>> {
    const m = this.getUsers.name;

    const path = environment.getEndpoint(ApiPath.Users.USERS_RESPONSIBILITIES(userId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<User[]>>(path);

    return retVal;
  }
  constructor(private http: HttpClient) {}
}
