<div class="cron-tab-container">
  <mat-radio-group [disabled]="!isEditing" [(ngModel)]="selectedOption" (change)="regenerateCron()">
    <mat-radio-button [value]="CronMonthsOptions.everyMonth">
      <label>{{ 'cron_editor_label_every_month' | translate }}</label>
    </mat-radio-button>

    <mat-radio-button [value]="CronMonthsOptions.everyNMonthStartFrom">
      <label>{{ 'cron_editor_label_every' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronMonthsOptions.everyNMonthStartFrom"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="everyNMonth"
        >
          <mat-option class="form-field-option" *ngFor="let every of everyNMonthOptions" [value]="every">
            {{ every }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_every_month_startingin' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-string">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronMonthsOptions.everyNMonthStartFrom"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="everyNMonthStartFrom"
        >
          <mat-option class="form-field-option" *ngFor="let startingFrom of rangeMonthsOptions" [value]="startingFrom">
            {{ monthDisplay(startingFrom) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-radio-button>

    <mat-radio-button [value]="CronMonthsOptions.specificMonths">
      <label>{{ 'cron_editor_label_specific_month' | translate }}</label>
      <ng-container *ngIf="isEditing">
        <span style="margin-left: 0.5rem"></span>
        <button
          mat-raised-button
          color="primary"
          [disabled]="selectedOption !== CronMonthsOptions.specificMonths"
          (click)="selectAllCheckboxes()"
        >
          {{ 'label_select_all' | translate }}
        </button>
        <span style="margin-left: 0.5rem"></span>
        <button
          mat-raised-button
          color="primary"
          [disabled]="selectedOption !== CronMonthsOptions.specificMonths"
          (click)="resetCheckboxes()"
        >
          {{ 'label_reset' | translate }}
        </button>
      </ng-container>
    </mat-radio-button>
    <div class="cron-input-specific-checkboxes-container">
      <mat-checkbox
        [disabled]="!isEditing || selectedOption !== CronMonthsOptions.specificMonths"
        *ngFor="let month of rangeMonthsOptions"
        [checked]="isCheckboxChecked(month)"
        (change)="checkboxSelectionChanged($event)"
        [value]="month"
        class="string-label"
      >
        {{ monthDisplay(month) | translate }}</mat-checkbox
      >
    </div>

    <mat-radio-button [value]="CronMonthsOptions.rangeMonths">
      <label>{{ 'cron_editor_label_every_month_between' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-string">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronMonthsOptions.rangeMonths"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="rangeMonthsStarts"
        >
          <mat-option class="form-field-option" *ngFor="let startingFrom of rangeMonthsOptions" [value]="startingFrom">
            {{ monthDisplay(startingFrom) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_every_month_between_andmonths' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-string">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronMonthsOptions.rangeMonths"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="rangeMonthsEnds"
        >
          <mat-option class="form-field-option" *ngFor="let to of rangeMonthsOptions" [value]="to">
            {{ monthDisplay(to) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-radio-button>
  </mat-radio-group>
  <!-- <h3>Cron expression - months {{ monthsExpression }}</h3> -->
</div>
