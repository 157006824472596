import { Entity } from './entity';

export class Note extends Entity {
  noteContent: string;
  noteHistoryId: number;
  noteId: number;
  noteTimestamp: Date;
  noteTitle: string;
  noteTopics: string;
  noteUsers: string;
  noteDeleted: boolean;
  noteAttachmentCount: number;
  noteHistoryCount: number;
}

export class NoteParams {
  public static FILTER_IDS = 'filterIds';
  public static FILTER_TOPIC_IDS = 'filterTopicIds';
  public static FILTER_USER_IDS = 'filterUserIds';
}
