import { KeyValue } from '@angular/common';
import { AuthAttempt } from 'src/app/core/auth/auth-attempt';
import { AuthError } from 'src/app/core/auth/auth-result';
import { OauthTokens } from 'src/app/core/auth/auth-token';
import { AuthUser } from 'src/app/core/auth/auth-user';

export interface State {
  isAuthenticated: boolean;
  isAuthenticatedTenantAdmin: boolean;
  error: AuthError;
  tenants: KeyValue<string, string>[];
  twoFactorMethod: string;
  logintryId: number;
  authUser: AuthUser;
  authAttempt: AuthAttempt;
  oauthTokens: OauthTokens;
  // change pwd
  isChangingPwd: boolean;
  isChanged: boolean;
  changePwdErrorMessage: AuthError;
  // forgot pwd
  isPwdForgotten: boolean;
  forgotPwdErrorMessage: AuthError;
  // reset pwd
  isResettingPwd: boolean;
  isResetted: boolean;
  resetPwdErrorMessage: AuthError;
}

export const initialState: State = {
  isAuthenticated: false,
  isAuthenticatedTenantAdmin: false,
  error: null,
  tenants: [],
  twoFactorMethod: null,
  logintryId: null,
  authUser: null,
  authAttempt: null,
  oauthTokens: null,
  // change pwd
  isChangingPwd: false,
  isChanged: false,
  changePwdErrorMessage: null,
  // forgot pwd
  isPwdForgotten: false,
  forgotPwdErrorMessage: null,
  // reset pwd
  isResettingPwd: false,
  isResetted: false,
  resetPwdErrorMessage: null,
};
