import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { BillingitemParticularRequest } from 'src/app/models/billingitem';
import { PricingFormParticulars } from 'src/app/models/pricing-form-particulars';
import { BillingAccount } from 'src/app/models/vendor-account';
import { AddBillingitemsDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-billingitems-wizard/activation-step/addbillingitems-due-date-info';
import { AddBillingitemsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-billingitems-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Add Billingitems Information Step',
  props<{ initStep: AddBillingitemsInitStep }>()
);

export const selectDueDatesStep = createAction(
  '[Wizard Due Date Step] Select Add Billingitems Due Date Step',
  props<{ dueDatesStep: AddBillingitemsDueDates }>()
);

export const selectBillingaccountStep = createAction(
  '[Wizard Billing account Step] Select Add Billingitems Billing account Step',
  props<{ billingaccountStep: BillingAccount[] }>()
);

export const selectPricingStep = createAction(
  '[Wizard Pricing Step] Select Add Billingitems Pricing Step',
  props<{ pricingStep: PricingFormParticulars }>()
);

export const deleteBillingaccountStep = createAction('[Wizard Billing account Step] Delete Add Billingitems Billing account Step');

export const deletePricingStep = createAction('[Wizard Billing account Step] Delete Add Billingitems Pricing Step');

export const exitAddBillingitemsWizard = createAction('[AddBillingitems Wizard Page] Exit Add Billingitems Wizard');

export const destroyWizard = createAction('[AddBillingitems Wizard Page] Destroy Wizard');

export const createNewAddBillingitems = createAction(
  '[Wizard Confirmation Step] Create Add Billingitems Information Step',
  props<{ request: BillingitemParticularRequest }>()
);

export const createNewAddBillingitemsSuccess = createAction(
  '[Wizard Confirmation Step] Create Add Billingitems Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewAddBillingitemsFail = createAction(
  '[Wizard Confirmation Step] Create Add Billingitems Information Step Fail',
  props<{ errorMessage: string }>()
);
