import { Action, createReducer, on } from '@ngrx/store';
import { OauthTokens } from 'src/app/core/auth/auth-token';
import * as AuthActions from './actions';
import { State, initialState } from './state';

export const authFeatureKey = 'auth';

const authReducer = createReducer(
  initialState,
  // authentication
  on(AuthActions.authenticate, (state, { auth }) => ({
    ...state,
    isAuthenticated: false,
    errorKey: null,
    errorMessage: null,
    authUser: null,

    authAttempt: auth,
  })),
  on(AuthActions.resetLoginFormsStatus, (state) => ({
    ...state,
    authAttempt: null,
    isAuthenticated: false,
    isAuthenticatedTenantAdmin: false,
    errorKey: null,
    errorMessage: null,
    authUser: null,
    tenants: null,
    oauthTokens: null,
    logintryId: null,
    twoFactorMethod: null,
    isChangingPwd: false,
    isChanged: false,
    changePwdErrorMessage: null,
    isPwdForgotten: false,
    forgotPwdErrorMessage: null,
    isResettingPwd: false,
    isResetted: false,
    resetPwdErrorMessage: null,
  })),
  on(AuthActions.demoAutoAuthenticate, (state) => ({
    ...state,
    isAuthenticated: false,
    errorKey: null,
    errorMessage: null,
    authUser: null,
  })),
  on(AuthActions.authMultiTenant, (state, { tenants, logintryId, accessToken, idToken }) => ({
    ...state,
    tenants,
    logintryId,
    oauthTokens: new OauthTokens(accessToken, idToken),
  })),
  on(AuthActions.authTwoFactorAuth, (state, { twoFactorMethod, logintryId, accessToken, idToken }) => ({
    ...state,
    twoFactorMethod,
    logintryId,
    tenants: null,
    oauthTokens: new OauthTokens(accessToken, idToken),
  })),
  on(AuthActions.authSuccess, (state, { result }) => ({
    ...state,
    isAuthenticated: true,
    authUser: result.getAuthUser(),
    tenants: null,
    oauthTokens: null,
    twoFactorMethod: null,
    logintryId: null,
  })),
  on(AuthActions.demoAutoAuthSuccess, (state, { result }) => ({
    ...state,
    isAuthenticated: true,
    authUser: result.getAuthUser(),

    tenants: null,
    oauthTokens: null,
    twoFactorMethod: null,
  })),
  on(AuthActions.authFailure, (state, { error }) => ({
    ...state,
    isAuthenticated: false,
    error: error.error,

    tenants: null,
    oauthTokens: null,
    logintryId: null,
  })),
  on(AuthActions.authAsUser, (state) => ({
    ...state,
    isAuthenticated: false,

    errorKey: null,
    errorMessage: null,
    authUser: null,
  })),
  on(AuthActions.authAsUserSuccess, (state, { result }) => ({
    ...state,
    isAuthenticated: true,
    authUser: result.getAuthUser(),

    tenants: null,
    oauthTokens: null,
    twoFactorMethod: null,
  })),
  // authentication tenant administration
  on(AuthActions.authTenantAdmin, (state) => ({
    ...state,
    isAuthenticatedTenantAdmin: false,

    errorKey: null,
    errorMessage: null,
    authUser: null,
    logintryId: null,
  })),
  on(AuthActions.authSuccessTenantAdmin, (state, { result }) => ({
    ...state,
    isAuthenticatedTenantAdmin: true,
    authUser: result.getAuthUser(),

    tenants: null,
    oauthTokens: null,
    twoFactorMethod: null,
    logintryId: null,
  })),
  on(AuthActions.authFailureTenantAdmin, (state, { error }) => ({
    ...state,
    isAuthenticatedTenantAdmin: false,
    error: error.error,

    tenants: null,
    oauthTokens: null,
    logintryId: null,
  })),
  // logout
  on(AuthActions.logout, (state) => ({
    ...state,
  })),
  on(AuthActions.logoutExecuted, (state) => ({
    ...state,
    isAuthenticated: false,
    isAuthenticatedTenantAdmin: false,
    errorKey: null,
    errorMessage: null,
    authUser: null,
  })),
  // get Initial Status
  on(AuthActions.getInitialAuthStatus, (state, { token }) => ({
    ...state,
    isAuthenticated: token.isValid() && !token.isTenantAdmin(),
    isAuthenticatedTenantAdmin: token.isValid() && token.isTenantAdmin(),
    errorKey: null,
    errorMessage: null,

    authUser: token.getUser(),
  })),
  // change password
  on(AuthActions.changePwd, (state) => ({
    ...state,
    isChangingPwd: true,
    isChanged: false,
    changePwdErrorMessage: null,
  })),
  on(AuthActions.changePwdSuccess, (state) => ({
    ...state,
    isChangingPwd: false,
    isChanged: true,
  })),
  on(AuthActions.changePwdSuccessDone, (state) => ({
    ...state,
    isChanged: false,
  })),
  on(AuthActions.changePwdFailure, (state, { error }) => ({
    ...state,
    isChangingPwd: false,
    changePwdErrorMessage: error,
  })),
  // forgot password
  on(AuthActions.forgotPwd, (state) => ({
    ...state,
    isPwdForgotten: false,
    forgotPwdErrorMessage: null,
  })),
  on(AuthActions.forgotPwdSuccess, (state) => ({
    ...state,
    isPwdForgotten: true,
  })),
  on(AuthActions.forgotPwdSuccessDone, (state) => ({
    ...state,
    isPwdForgotten: false,
  })),
  on(AuthActions.forgotPwdFailure, (state, { error }) => ({
    ...state,
    forgotPwdErrorMessage: error,
  })),
  // reset password
  on(AuthActions.resetPwd, (state) => ({
    ...state,
    isResettingPwd: true,
    isResetted: false,
    resetPwdErrorMessage: null,
  })),
  on(AuthActions.resetPwdSuccess, (state) => ({
    ...state,
    isResettingPwd: false,
    isResetted: true,
  })),
  on(AuthActions.resetPwdSuccessDone, (state) => ({
    ...state,
    isResetted: false,
  })),
  on(AuthActions.resetPwdFailure, (state, { error }) => ({
    ...state,
    isResettingPwd: false,
    resetPwdErrorMessage: error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
