import { HttpParams } from '@angular/common/http';
import {
  WizardCommunicationStage,
  WizardCommunicationStageType,
} from '../shared/wizard-common-steps/wizard-communication-step/wizard-communication-step.component';
import { Entity } from './entity';
import { PaginatedRequest } from './paginated-request';

export class ProductFamily extends Entity {
  productfamilyId: number;
  productfamilyCatalogitemkindId: number;
  productfamilyCatalogitemkind: string;
  productfamilyName: string;
  productfamilyMdate: Date;
  productfamilyMuser: string;
  productfamilyProviderId: number;
  productfamilyProvider: string;
  productfamilyPermissioningSystemId: number;
  productfamilyPermissioningSystem: string;
  productfamilySiteId: number;
  productfamilySite: string;
  productfamilyAddonPolicy: string;
  productfamilyAddonPolicyId: number;
  productfamilyGenericPolicy: string;
  productfamilyGenericPolicyId: number;
  productfamilyProductPolicy: string;
  productfamilyProductPolicyId: number;
  productfamilyProvider3diprofilerId: number;
  productfamilyProvider3diprofilerType: number;
}

export class ProductFamilyParams {
  public static FILTER_CATALOGITEMKIND_IDS = 'filterCatalogitemkindIds';
  public static FILTER_PROVIDER_IDS = 'filterProviderIds';
  public static FILTER_PERMISSIONINGSYSTEM_IDS = 'filterPermissioningsystemIds';
  public static FILTER_SITE_IDS = 'filterSiteIds';
}

export function getProductFamilyRequestParams(request: PaginatedRequest): HttpParams {
  return PaginatedRequest.getBaseRequestParams(request);
}

export class ProductFamilyMailtemplateStages {
  public static stages: WizardCommunicationStage[] = [
    new WizardCommunicationStage(1050, true, WizardCommunicationStageType.info),
    new WizardCommunicationStage(1051, true, WizardCommunicationStageType.invoice),
  ];
}
