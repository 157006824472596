<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span>{{ getSidenavTitle() }}</span>
  <div class="buttons-container">
    <button mat-icon-button [matDialogClose]="dataIsUpdated" [disabled]="atomicCommonIsEditing">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content class="form-field-foreign-details-dialog-content">
  <div class="atomic-sidenav-detail-content">
    <div class="spinner-container" *ngIf="atomicCommonIsLoading">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <mat-tab-group class="atomic-sidenav-detail-tab-group" *ngIf="selectedEntity && !atomicCommonIsLoading">
      <mat-tab *ngFor="let step of atomicDetailsSteps" [disabled]="atomicCommonIsEditing">
        <ng-template mat-tab-label>
          {{ step.formstepName }}
        </ng-template>
        <ng-template matTabContent>
          <div class="flying-button-custom-container">
            <generic-entity-form
              [isFormDialog]="true"
              [entitySubj]="entitySubj.asObservable()"
              [getAPIPath]="getMainAPIFormFieldsPath(step.formstepId)"
              [putAPIPath]="putMainAPIFormFieldsPath(step.formstepId)"
              (createUpdateCallbackEvEm)="createUpdateDetailsCallbackEvEm($event)"
              [showForeignDetailsButton]="false"
              [isEditing]="atomicCommonIsEditing"
              (isEditingChange)="atomicCommonIsEditing = $event"
              [leftButtonEnabled]="true"
              leftButtonKey="label_edit"
              [isRightButtonCustom]="rightButtonKey != null"
              [rightButtonEnabled]="rightButtonKey != null"
              [isRightButtonString]="isRightButtonString"
              [rightButtonKey]="rightButtonKey"
              (rightButtonCustomButtonEvent)="rightButtonCustomButtonEvEm.emit()"
            ></generic-entity-form>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
