/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiPath } from 'src/app/configs/api-paths';
import { BaseResponse } from 'src/app/models/base-response';
import { TokenService } from 'src/app/services/auth/token.service';
import { LogService } from 'src/app/services/log-service';
import { environment } from 'src/environments/environment';

export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getTranslation(lang: string): Observable<any> {
    const token = this.tokenService.getAuthToken();
    const isAuth = token.isValid();
    const isTenantAdminAuth = isAuth ? token.isTenantAdmin() : false;
    const m = this.getTranslation.name;

    const apiPath = isTenantAdminAuth
      ? ApiPath.TenantAdministration.TRANSLATIONS
      : isAuth
      ? ApiPath.TRANSLATIONS
      : ApiPath.Public.TRANSLATIONS;
    const auth = isAuth ? '-auth' : '-public';
    const appLangAuth = `${lang}${auth}`;
    sessionStorage.setItem('app-language-auth', appLangAuth);
    const retVal = this.http.get<BaseResponse<any>>(environment.getEndpoint(apiPath + '?lang=' + lang)).pipe(
      map((resp) => {
        LogService.info(this, m, 'GET TRANSLATIONS Response', resp.data);
        return resp.data;
      })
    );

    return retVal;
  }
}
