import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { Service } from 'src/app/models/service';
import { TerminateMultiAddonsInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/terminate-multi-addons-wizard/init-step/init-step';
import * as fromTerminateMultiAddonsWizard from './reducer';
import { State } from './state';

export const selectTerminateMultiAddonsWizardState = createFeatureSelector<State>(
  fromTerminateMultiAddonsWizard.terminateMultiAddonsWizardFeatureKey
);

export const selectInitStep = createSelector(
  selectTerminateMultiAddonsWizardState,
  (state: State): TerminateMultiAddonsInitStep => state.initStep
);

export const selectServicesStep = createSelector(selectTerminateMultiAddonsWizardState, (state: State): Service[] => state.serviceStep);

export const selectTerminateMultiAddonsState = createSelector(
  selectTerminateMultiAddonsWizardState,
  (state: State): ActionResponse => state.response
);

export const selectErrorMessage = createSelector(selectTerminateMultiAddonsWizardState, (state: State): string => state.errorMessage);
