import { NGX_MAT_DATE_FORMATS, NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMomentDateModule } from '@angular-material-components/moment-adapter';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppInjector } from './app-injector.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DemoPageModule } from './pages/demopage/demopage.module';
import { RootStoreModule } from './root-store/root-store.module';
import { TokenService } from './services/auth/token.service';
import { AppLanguageInterceptor } from './services/http-interceptors.ts/app-language.interceptors';
import { CleanEmptyFieldReuqestInterceptor } from './services/http-interceptors.ts/clean-empty-field-request.interceptor';
import { DateHeaderInterceptor } from './services/http-interceptors.ts/date-header.interceptors';
import { EncodeHttpParamsInterceptor } from './services/http-interceptors.ts/encode-http-params.interceptors';
import { GlobalFilterInterceptor } from './services/http-interceptors.ts/global-filter.interceptors';
import { AuthJWTInterceptor } from './services/http-interceptors.ts/jwt-interceptor';
import { RestrictedAuthJWTInterceptor } from './services/http-interceptors.ts/restricted-jwt-interceptor';
import { BlobErrorHttpInterceptor } from './services/http-interceptors.ts/server-error/server-blob-error-interceptor';
import { ServerErrorInterceptor } from './services/http-interceptors.ts/server-error/server-error-interceptor';
import { SharedModule } from './shared/shared.module';
import { CustomTranslateLoader } from './shared/translate-text/translate-loader';

const DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const DATETIMEPICKER_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'L, LT',
  },
  display: {
    dateInput: 'L, LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient, TokenService],
      },
      isolate: false,
    }),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatMomentDateModule,
    NgxMomentDateModule,
    AppRoutingModule,
    RootStoreModule,
    SharedModule,
    CoreModule,
    DemoPageModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppLanguageInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RestrictedAuthJWTInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthJWTInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalFilterInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CleanEmptyFieldReuqestInterceptor,
      multi: true,
    },
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-gb' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMATS },
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: NGX_MAT_DATE_FORMATS, useValue: DATETIMEPICKER_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
