import { CollectionViewer, SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, map, merge } from 'rxjs';
import { LogService } from '../services/log-service';
import { PackageDataService } from '../services/package-data.service';
import { PaginatedRequest } from './paginated-request';
import { GenericTreeNode } from './tree-view-flat-node';

export class PackageTreeNode extends GenericTreeNode {
  packageId: number;
  packageBillingitem: string;
  packageBillingitemId: number;
  packageDeliveryitem: string;
  packageDeliveryitemId: number;
  packageService: string;
  packageServiceId: number;
  packageStarts: Date;
  packageEnds: Date;
  packageUsecount: string;
  packageUsecountId: number;
  packageCurrent: number;
  packageRule: string;
  packageRuleId: number;
  packageCode: string;
  packageContentId: number;
  packagePriceAdditionalCosts: number;
  packagePriceCostPeriod: string;
  packagePriceCurrency: string;
  packagePriceFixedCosts: number;
  packagePriceInherited: boolean;
  packagePriceUnitCosts: number;
}

export class PackageParams {
  public static FILTER_IDS = 'filterIds';
  public static FILTER_BILLINGITEM_IDS = 'filterBillingitemIds';
  public static FILTER_USECOUNT_IDS = 'filterUsecountIds';
  public static FILTER_RULE_IDS = 'filterRuleIds';
  public static FILTER_CONTENT_BILLINGITEM_IDS = 'filterContentBillingitemIds';
  public static FILTER_EXCLUDE_BILLINGITEM_IDS = 'filterExcludeContentBillingitemIds';
}

export class PackageDynamicFlatNode {
  constructor(
    public item: PackageTreeNode,
    public level = 1,
    public expandable = false,
    public isLoading = false,
    public isIndeterminate = false,
    parentId?: number
  ) {
    this.item.parentId = parentId;
  }
}

@Injectable()
export class PackageDynamicDataSource {
  dataChange = new BehaviorSubject<PackageDynamicFlatNode[]>([]);
  nodeSelection = new SelectionModel<PackageTreeNode>(true, []);
  baseApiPath: string;
  nodeName: string;

  isManualLoading = false;

  get data(): PackageDynamicFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: PackageDynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private treeControl: FlatTreeControl<PackageDynamicFlatNode>,
    private database: PackageDynamicDatabase,
    private packageDataService: PackageDataService,
    private request: PaginatedRequest
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<PackageDynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if ((change as SelectionChange<PackageDynamicFlatNode>).added || (change as SelectionChange<PackageDynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<PackageDynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(): void {}

  handleTreeControl(change: SelectionChange<PackageDynamicFlatNode>) {
    if (change.added && !this.isManualLoading) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed && !this.isManualLoading) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  toggleNode(node: PackageDynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);

    node.isLoading = true;

    if (expand) {
      const childs = this.database.getChildren(node.item);
      if (childs) {
        const nodes = childs.map(
          (item) => new PackageDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId)
        );
        this.data.splice(index + 1, 0, ...nodes);
        // notify the change
        this.dataChange.next(this.data);
        node.isLoading = false;
      } else {
        this.packageDataService.getPackages(this.request, node.item.packageId).subscribe(({ data }) => {
          if (data) {
            const nodes = data.map(
              (item) => new PackageDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId)
            );
            if (this.isNodeSelected(node)) {
              this.nodeSelection.select(...data);
            }
            this.database.setChildren(node.item, data);
            this.data.splice(index + 1, 0, ...nodes);
            // notify the change
            this.dataChange.next(this.data);
          } else {
            node.expandable = false;
          }
          node.isLoading = false;
        });
      }
    } else {
      let count = 0;
      // eslint-disable-next-line no-empty
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
      this.data.splice(index + 1, count);
      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }
  }

  async expandAllChildNodes(packageId: number) {
    this.isManualLoading = true;
    // Level 0 to expand (packageId, null, null)
    const level0Node = this.data.find((n) => n.item.packageId === packageId);
    if (level0Node != null) {
      LogService.debug(this, this.expandAllChildNodes.name, 'LEVEL 0 NODE TO EXPAND', level0Node);
      await this.manualExpandNode(level0Node);
      this.treeControl.expansionModel.select(...[level0Node]);
    } else {
      this.isManualLoading = false;
      return;
    }
    this.isManualLoading = false;
  }

  async manualExpandNode(node: PackageDynamicFlatNode) {
    const index = this.data.indexOf(node);
    node.isLoading = true;
    const { data } = await firstValueFrom(this.packageDataService.getPackages(this.request, node.item.packageId));
    if (data) {
      const nodes = data.map(
        (item) => new PackageDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId)
      );
      this.database.setChildren(node.item, data);
      this.data.splice(index + 1, 0, ...nodes);
      // notify the change
      this.dataChange.next(this.data);
    } else {
      node.expandable = false;
    }
    node.isLoading = false;
  }

  async refreshNodeData(item: PackageTreeNode) {
    const node = this.data.find((n) => n.item.entityId === item.entityId && n.item.entityKind === item.entityKind);
    const isExpanded = this.treeControl.isExpanded(node);
    const parentNode = this.getParentNode(node);
    const index = this.data.indexOf(node);
    const { data } = await firstValueFrom(
      this.packageDataService.getPackages(this.request, parentNode ? parentNode.item.packageId : null, node.item.entityId)
    );
    if (data && index !== -1) {
      const updatedNode = data.map(
        (item) => new PackageDynamicFlatNode(item, node.level, this.database.isExpandable(item), false, false, node.item.parentId)
      )[0];
      this.data[index] = updatedNode;
      if (parentNode) {
        this.database.refreshChildren(parentNode.item, updatedNode.item);
      }
      // notify the change
      this.dataChange.next(this.data);
      if (isExpanded) {
        this.isManualLoading = true;
        this.treeControl.expansionModel.select(...[updatedNode]);
        this.isManualLoading = false;
      }
    }
  }

  /* SELECTION MANAGEMENT */

  isNodeSelected(node: PackageDynamicFlatNode): boolean {
    return this.nodeSelection.isSelected(node.item);
  }

  isNodeChecked(node: PackageDynamicFlatNode): boolean {
    return node.expandable && this.treeControl.isExpanded(node)
      ? this.descendantsAllSelected(node)
      : this.nodeSelection.isSelected(node.item);
  }

  getSelectedNodes() {
    return this.nodeSelection.selected;
  }

  toggleNodeSelection(node: PackageDynamicFlatNode) {
    this.nodeSelection.toggle(node.item);
    const descendants = this.database.getChildren(node.item);
    this.toggleDescendants(this.isNodeSelected(node), descendants);
    this.checkAllParentsSelection(node);
  }

  toggleDescendants(parentNodeSelected: boolean, descendants: PackageTreeNode[]) {
    if (descendants) {
      parentNodeSelected ? this.nodeSelection.select(...descendants) : this.nodeSelection.deselect(...descendants);
      descendants.forEach((childNode) => this.toggleDescendants(parentNodeSelected, this.database.getChildren(childNode)));
    }
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: PackageDynamicFlatNode): boolean {
    const descendants = this.database.getChildren(node.item);
    const descAllSelected =
      descendants &&
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.nodeSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: PackageDynamicFlatNode): boolean {
    if (node.expandable && this.treeControl.isExpanded(node)) {
      const descendants = this.database.getChildren(node.item);
      const result = descendants ? descendants.some((child) => this.nodeSelection.isSelected(child)) : false;
      const isIndeterminate = result && !this.descendantsAllSelected(node);
      node.isIndeterminate = isIndeterminate;
    }
    return node.isIndeterminate;
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: PackageDynamicFlatNode): void {
    let parent: PackageDynamicFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: PackageDynamicFlatNode): void {
    const nodeSelected = this.isNodeSelected(node);
    const descendants = this.database.getChildren(node.item);
    const descAllSelected =
      descendants &&
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.nodeSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.nodeSelection.deselect(node.item);
    } else if (!nodeSelected && descAllSelected) {
      this.nodeSelection.select(node.item);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: PackageDynamicFlatNode): PackageDynamicFlatNode | null {
    const currentLevel = node.level;
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}

@Injectable()
export class PackageDynamicDatabase {
  dataMap = new Map<PackageTreeNode, PackageTreeNode[]>([]);

  initialData(rootLevelNodes: PackageTreeNode[]): PackageDynamicFlatNode[] {
    const initialNodes: PackageDynamicFlatNode[] = [];
    rootLevelNodes.forEach((node) => {
      initialNodes.push(new PackageDynamicFlatNode(node, 0, this.isExpandable(node), false));
    });

    return initialNodes;
  }

  setChildren(parentNode: PackageTreeNode, children: PackageTreeNode[]) {
    this.dataMap.set(parentNode, children);
  }

  getChildren(node: PackageTreeNode): PackageTreeNode[] | undefined {
    return this.dataMap.get(node);
  }

  isExpandable(node: PackageTreeNode): boolean {
    return node.entityChilds > 0;
  }

  refreshChildren(parentNode: PackageTreeNode, node: PackageTreeNode) {
    const values: PackageTreeNode[] = this.dataMap.get(parentNode);
    const index: number = values.findIndex((n) => n.entityId === node.entityId && n.entityKind === node.entityKind);
    if (index !== -1) {
      values[index] = node;
    }
  }
}
