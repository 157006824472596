<div class="grid-filter-date-panel-input">
  <p-calendar
    #pcalendarDatepicker
    class="grid-filter"
    [ngClass]="selectedDate ? 'filter-defined' : ''"
    [(ngModel)]="selectedDate"
    [selectOtherMonths]="true"
    [showIcon]="true"
    [showClear]="selectedDate"
    [placeholder]="filterTitle"
    [dateFormat]="getDateFormat()"
    [readonlyInput]="true"
    (onSelect)="updateDate($event)"
    (onBlur)="updateDate(selectedDate)"
    (onClear)="reset()"
    appendTo="body"
    ><p-footer>
      <div class="p-datepicker-buttonbar">
        <button class="p-button-text" type="button" (click)="calendarOnTodayButtonClick($event, pcalendarDatepicker)" pButton pRipple>
          {{ 'label_today' | translate }}
        </button>
        <button
          *ngIf="gridName && gridfilterId != null"
          mat-icon-button
          color="primary"
          (click)="changeDefaults()"
          matTooltip="{{ 'label_change_filter_default' | translate }}"
          [matTooltipClass]="'fo-mat-tooltip'"
        >
          <mat-icon>star_border</mat-icon>
        </button>
        <button class="p-button-text" type="button" (click)="pcalendarDatepicker.hideOverlay()" pButton pRipple>
          {{ 'label_close' | translate }}
        </button>
      </div>
    </p-footer>
  </p-calendar>
</div>
