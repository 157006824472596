<div class="file-tab-content-container">
  <as-split direction="horizontal">
    <as-split-area size="40" minSize="20">
      <app-files-multiselection-tree
        [tabType]="tabType"
        (nodeClick)="treeNodeClick($event)"
        [rootNodeLabel]="rootNodeLabel"
      ></app-files-multiselection-tree>
    </as-split-area>
    <as-split-area size="60" minSize="40">
      <app-files-multiselection-table
        [tabType]="tabType"
        [treeEvent]="eventsSubject.asObservable()"
        [isMultiselect]="isMultiselect"
        (fileSelected)="fileSelected.emit($event)"
      ></app-files-multiselection-table>
    </as-split-area>
  </as-split>
</div>
