import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of, throwError } from 'rxjs';
import { CatalogDataService } from 'src/app/services/catalog-data.service';
import { DeactivateBillingitemAssetWizardActions } from '.';

@Injectable()
export class DeactivateBillingitemAssetWizardEffects {
  newOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeactivateBillingitemAssetWizardActions.sendDeactivateBillingitemAssetRequest),
      concatMap(({ request }) =>
        this.catalogService.deactivateCatalogItem(request).pipe(
          map((response) => {
            if (response) {
              return DeactivateBillingitemAssetWizardActions.sendDeactivateBillingitemAssetRequestSuccess({
                response: response.data,
              });
            } else {
              throwError('No data received');
            }
          }),
          catchError((error) => {
            const errorMessage = error;
            return of(DeactivateBillingitemAssetWizardActions.sendDeactivateBillingitemAssetRequestFail({ errorMessage }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private router: Router, private catalogService: CatalogDataService) {}
}
