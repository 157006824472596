import { Action, createReducer, on } from '@ngrx/store';
import * as MoveSharedAssetWizardActions from './actions';
import { State, initialState } from './state';

export const moveSharedAssetWizardFeatureKey = 'moveSharedAssetWizard';

const moveSharedAssetWizardReducer = createReducer(
  initialState,

  on(MoveSharedAssetWizardActions.selectInitStep, (state, { initStep }) => ({
    ...state,
    initStep,
  })),
  on(MoveSharedAssetWizardActions.selectMovingToStep, (state, { movingTo }) => ({
    ...state,
    movingTo,
  })),
  on(MoveSharedAssetWizardActions.selectAllocationUpdateStep, (state, { allocationUpdate }) => ({
    ...state,
    allocationUpdate,
  })),
  on(MoveSharedAssetWizardActions.deleteAllocationUpdateStep, (state) => ({
    ...state,
    allocationUpdate: null,
  })),
  on(MoveSharedAssetWizardActions.exitMoveSharedAssetWizard, (state) => ({
    ...state,
    initStep: null,
    errorMessage: null,
    movingTo: null,
    allocationUpdate: null,
    request: null,
    response: null,
  })),
  on(MoveSharedAssetWizardActions.destroyWizard, (state) => ({
    ...state,
    initStep: null,
    errorMessage: null,
    movingTo: null,
    allocationUpdate: null,
    request: null,
    response: null,
  })),
  on(MoveSharedAssetWizardActions.createNewMoveSharedAsset, (state, { request }) => ({
    ...state,
    request,
  })),
  on(MoveSharedAssetWizardActions.createNewMoveSharedAssetSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
  on(MoveSharedAssetWizardActions.createNewMoveSharedAssetFail, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return moveSharedAssetWizardReducer(state, action);
}
