import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { interval, switchMap, takeWhile } from 'rxjs';
import { Entity } from 'src/app/models/entity';
import { ProgressMessage } from 'src/app/models/wizard-import-response';
import { CoreDataService } from 'src/app/services/core-data.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

export interface EntitySaveProgressDialogData {
  importId: number;
  apiPath: string;
  progressTitleKey: string;
  progressTitleMessageKey: string;
  isAutoclose: boolean;
  showSuccessMessage: boolean;
  successMessage: string;
}
@Component({
  selector: 'app-entity-save-progress-dialog',
  templateUrl: './entity-save-progress-dialog.component.html',
  styleUrls: ['./entity-save-progress-dialog.component.scss'],
})
export class EntitySaveProgressDialogComponent extends FormatComponent implements OnInit {
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;
  importId: number;
  progressTitleKey: string;
  progressTitleMessageKey: string;
  isProgressing = false;
  progressPercentage = 0;
  progressCompleted = false;
  progressSuccess = false;
  progressErrorMessage: string;
  progressErrorMessageDescription: string;
  progressMessages: ProgressMessage[] = [];
  progressDisplayedColumnsTable = ['progressTimestamp', 'progressText'];
  progressMsgDatasource: MatTableDataSource<ProgressMessage>;
  createdEntity: Entity;
  apiPath: string;
  showSuccessMessage: boolean;
  successMessage: string = _('label_success');

  constructor(
    private entityService: CoreDataService,
    public dialogRef: MatDialogRef<EntitySaveProgressDialogComponent, Entity>,
    @Inject(MAT_DIALOG_DATA) public data: EntitySaveProgressDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.progressSuccess = false;
    this.importId = this.data.importId;
    this.apiPath = this.data.apiPath;
    this.showSuccessMessage = this.data.showSuccessMessage;
    if (this.data.successMessage) {
      this.successMessage = this.data.successMessage;
    }

    this.progressTitleKey = this.data.progressTitleKey;
    this.progressTitleMessageKey = this.data.progressTitleMessageKey;
    this.progressMsgDatasource = new MatTableDataSource(this.progressMessages);
    this.subscribe(
      interval(2000).pipe(
        takeWhile(() => !this.progressCompleted),
        switchMap(() =>
          this.apiPath != null
            ? this.entityService.getSaveEntityStatusByApipath(this.apiPath)
            : this.entityService.getSaveEntityStatus(this.importId)
        )
      ),
      (response) => {
        if (response.data) {
          if (response.data.progressMessages && response.data.progressMessages.length > 0) {
            this.progressMsgDatasource.data.push(...response.data.progressMessages);
            this.progressMsgDatasource._updateChangeSubscription();
            this.scrollToBottom();
          } else {
            // only for dev
            // for (let index = 0; index < 1; index++) {
            //   this.progressMsgDatasource.data.push(`Progess message ${this.progressPercentage}`);
            // }
            // this.progressMsgDatasource._updateChangeSubscription();
            // this.scrollToBottom();
          }
          if (!response.data.error) {
            this.progressPercentage = response.data.progress;
            this.progressCompleted = response.data.state;
            if (this.progressCompleted && !this.progressErrorMessage) {
              // do something
              this.isProgressing = false;
              this.progressSuccess = true;
              this.createdEntity = {
                ...new Entity(),
                entityId: response.data.data[0].wizarddataId,
                entityName: response.data.data[0].wizarddataName,
                entityKind: response.data.data[0].wizarddataKind,
                entityUpdate: true,
                entityDelete: true,
              };
              if (this.data.isAutoclose) {
                this.dialogRef.close(this.createdEntity);
              }
            }
          } else {
            this.progressErrorMessage = response.data.error;
            this.progressErrorMessageDescription = response.data.errorMessage;
            this.progressPercentage = 100;
            this.progressCompleted = true;
            this.isProgressing = false;
          }
        }
      },
      (error) => {
        this.progressErrorMessage = _('label_internal_server_error');
        this.isProgressing = false;
      }
    );
  }

  close() {
    this.dialogRef.close(this.createdEntity);
  }

  cancelSaveEntity() {
    this.subscribe(
      this.entityService.cancelSaveEntity(this.importId),
      ({ data }) => {
        this.progressCompleted = true;
        this.isProgressing = false;
      },
      (error) => {
        this.progressCompleted = true;
        this.isProgressing = false;
      }
    );
  }

  scrollToBottom(): void {
    try {
      if (this.messagesContainer) {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      }
    } catch (err) {
      //
    }
  }
}
