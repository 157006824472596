import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MailTemplatesDataService } from 'src/app/services/mail-template-data.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

export interface MailPreviewModalDialogData {
  mailframedefaultId: number;
  mailframeId: number;
  mailoutgoingaccountId: number;
  mailtemplateId: number;
  stageId: number;
  languageId: number;
  fontfamilyId: number;
  subject: string;
  body: string;
}

@Component({
  selector: 'app-mail-preview-modal-dialog',
  templateUrl: './mail-preview-modal-dialog.component.html',
  styleUrls: ['./mail-preview-modal-dialog.component.scss'],
})
export class MailPreviewModalDialogComponent extends FormatComponent implements OnInit {
  mailPreview: string;

  constructor(
    private sanitized: DomSanitizer,
    private mailTemplateDataService: MailTemplatesDataService,
    public dialogRef: MatDialogRef<MailPreviewModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MailPreviewModalDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(
      this.mailTemplateDataService.getMailTemplatePreview(
        this.data.mailframedefaultId,
        this.data.mailframeId,
        this.data.mailoutgoingaccountId,
        this.data.mailtemplateId,
        this.data.stageId,
        this.data.languageId,
        this.data.fontfamilyId,
        this.data.subject,
        this.data.body
      ),

      ({ data }) => (this.mailPreview = data)
    );
  }

  onClose() {
    this.dialogRef.close();
  }

  sanitizeHtml(value: string) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
