import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, timer } from 'rxjs';
import { catchError, concatMap, map, switchMap, takeWhile, withLatestFrom } from 'rxjs/operators';
import { GlobalSearchService } from 'src/app/services/global-search-service';
import { environment } from 'src/environments/environment';
import * as GlobalSearchStoreActions from './actions';
import {
  selectGlobalSearchFilter,
  selectGlobalSearchOldFilter,
  selectGlobalSearchfilterEntityKind,
  selectIsFinished,
  selectPreviousGlobalSearchId,
} from './selectors';
import { State } from './state';

@Injectable()
export class GlobalSearchStoreEffects {
  requestForNewGlobalSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchStoreActions.requestNewGlobalSearch),
      withLatestFrom(
        this.store.select(selectIsFinished),
        this.store.select(selectPreviousGlobalSearchId),
        this.store.select(selectGlobalSearchOldFilter)
      ),
      concatMap(([filter, isFinished, searchId, oldFilter]) => {
        if (isFinished) {
          return of(GlobalSearchStoreActions.startNewSearch({ filter: filter.filter ? filter.filter : oldFilter }));
        } else {
          return of(GlobalSearchStoreActions.stopPreviousSearch({ searchId }));
        }
      })
    )
  );

  startNewGlobalSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchStoreActions.startNewSearch),
      withLatestFrom(this.store.select(selectGlobalSearchfilterEntityKind)),
      concatMap(([{ filter }, filterEntityKind]) =>
        this.globalSearchService.startGlobalSearch(filter, filterEntityKind).pipe(
          map((response) => {
            if (response && response.data) {
              if (response.data.state) {
                this.router.navigateByUrl('/pages/searchresult');
                return GlobalSearchStoreActions.startNewSearchSuccess({ searchId: response.data.id });
              } else {
                return GlobalSearchStoreActions.globalSearchError({ error: response.data.error });
              }
            } else {
              return GlobalSearchStoreActions.globalSearchError({ error: 'Empty Response error' });
            }
          }),
          catchError((error) => {
            return of(GlobalSearchStoreActions.globalSearchError({ error }));
          })
        )
      )
    )
  );

  startNewGlobalSearchSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchStoreActions.startNewSearchSuccess),
      concatMap(({ searchId }) => {
        return of(GlobalSearchStoreActions.startPollingSearchResult({ searchId }));
      })
    )
  );

  destroySearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchStoreActions.destroySearch),
      concatMap(() => {
        return of(GlobalSearchStoreActions.finishPollingSearchResult());
      })
    )
  );

  setFilterEntityKinds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchStoreActions.setFilterEntityKinds),
      concatMap(() => {
        return of(GlobalSearchStoreActions.requestNewGlobalSearch({ filter: null }));
      })
    )
  );

  stopPreviousGlobalSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchStoreActions.stopPreviousSearch),
      concatMap(({ searchId }) =>
        this.globalSearchService.deleteSearch(searchId).pipe(
          map((response) => {
            if (response && response.data) {
              if (response.data.state) {
                return GlobalSearchStoreActions.stopPreviousSearchSuccess();
              } else {
                return GlobalSearchStoreActions.globalSearchError({ error: response.data.error });
              }
            } else {
              return GlobalSearchStoreActions.globalSearchError({ error: 'Empty Response error' });
            }
          }),
          catchError((error) => {
            return of(GlobalSearchStoreActions.globalSearchError({ error }));
          })
        )
      )
    )
  );

  stopPreviousGlobalSearchSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchStoreActions.stopPreviousSearchSuccess),
      withLatestFrom(this.store.select(selectGlobalSearchFilter), this.store.select(selectGlobalSearchOldFilter)),
      concatMap(([a, filter, oldFilter]) => {
        return of(GlobalSearchStoreActions.startNewSearch({ filter: filter ? filter : oldFilter }));
      })
    )
  );

  pollingSearchData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchStoreActions.startPollingSearchResult),
      switchMap(({ searchId }) =>
        timer(0, environment.globalSearchPollingIntervall).pipe(
          withLatestFrom(this.store.select(selectIsFinished)),
          takeWhile(([searchid, isFinished]) => !isFinished),
          switchMap(() =>
            this.globalSearchService.getSearchResult(searchId).pipe(
              map((response) => {
                if (response.data) {
                  return GlobalSearchStoreActions.storePollingSearchResultData({ response });
                } else {
                  return GlobalSearchStoreActions.globalSearchError({ error: 'Empty Response error' });
                }
              }),
              catchError((error) => {
                return of(GlobalSearchStoreActions.globalSearchError({ error }));
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private globalSearchService: GlobalSearchService,
    private router: Router
  ) {}
}
