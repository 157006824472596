import { createAction, props } from '@ngrx/store';
import { AllocateConsumerDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-wizard/dates-step/allocate-consumer-date-info';
import { AllocateConsumerInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-wizard/init-step/allocate-consumers-init-step';
import { AllocateConsumerMiscellaneousRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-wizard/miscellaneous-step/allocate-consumer-miscellaneous-request';
import { ConsumerDesk } from 'src/app/shared-components/allocation-wizard-modal-dialogs/allocate-consumers-wizard/users-step/consumer-desk';

export const selectInitStep = createAction(
  '[Catalog Page] Select Allocate Consumer Init Information Step',
  props<{ initStep: AllocateConsumerInitStep }>()
);

export const selectDatesStep = createAction('[Wizard Dates Step] Select Dates Step', props<{ datesStep: AllocateConsumerDueDates }>());

export const selectUsersStep = createAction('[Wizard Dates Step] Select Users Step', props<{ usersStep: ConsumerDesk[] }>());

export const selectMiscellaneousStep = createAction(
  '[Wizard Dates Step] Select Miscellaneous Step',
  props<{ miscellaneousStep: AllocateConsumerMiscellaneousRequest }>()
);

export const exitAllocateConsumerWizard = createAction('[Allocate Consumer Wizard Page] Exit Allocate Consumer Wizard');

export const destroyWizard = createAction('[Allocate Consumer Wizard Page] Destroy Wizard');
