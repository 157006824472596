import { KeyValue } from '@angular/common';
import { GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
import { StepValue } from 'src/app/shared/inputs/step-value-control/step-value';
import { KeyValueAddon } from '../core/key-value-data';
import { EntityRecord } from '../entity';
import { FormFieldConfigurationKind } from './form-configuration-kind';

export class GenericFormField {
  formfieldId: number;
  formfieldKindId: number;
  formfieldKind: string;
  formfieldTextname: string;
  formfieldInfo: string;
  formfieldSearchEntityKind: string;
  formfieldTextKindId: number;
  formfieldTextKind: string;
  formfieldTextSizeId: number;
  formfieldTextSize: string;
  formfieldTextWeightId: number;
  formfieldTextWeight: string;
  formfieldEntityColumn: string;
  formfieldEntityType: string;
  formfieldEntityText: any;
  formfieldEntityDate?: Date;
  formfieldEntityFloatText?: any;
  formfieldEntityValue: number;
  formfieldEntityKind: string;
  formfieldEntityOptions: KeyValueAddon<string, string, string>[];
  // formfieldEntityOptions: KeyValue<string, string>[];
  formfieldEntityRegex: string;
  formfieldEntityRegexerrormsg: string;
  formfieldEntityUnit: string;
  formfieldEntityPrecision: number;
  formfieldEntityScale: number;
  formfieldEntitySpecification: string;
  formfieldEntitySteps: StepValue[];
  formfieldEntityKindOther: string;
  formfieldEntityKindRelated: string;
  formfieldEntityRelations: EntityRecord[];
  formfieldEntityTranslations: KeyValue<string, string>[];
  formfieldHideonfield: number;
  formfieldHideontrue: boolean;
  formfieldHideoncompact: boolean;
  formfieldReadonlyonfield: number;
  formfieldReadonlyontrue: boolean;
  formfieldReadonly: boolean;
  formfieldLabelShow: boolean;
  formfieldLabelDisplay: boolean;
  formfieldName: string;
  // formfieldDisplayBooleanType: number;
  // formfieldDisplayOptionType: number;
  formfieldDisplayType: number;
  formfieldRequired: number;
  formfieldHide: number;
  formfieldRoundBottomLeft: boolean;
  formfieldRoundBottomRight: boolean;
  formfieldRoundTopLeft: boolean;
  formfieldRoundTopRight: boolean;
  formfieldUnique: string;
  formfieldX: number;
  formfieldY: number;
  formfieldW: number;
  formfieldH: number;
  filterStringOption: string;
  isRequiredControlValid: boolean;
  isStepControlValid = false;
  hideBindedField?: GenericFormField;
  readonlyBindedField?: GenericFormField;
  constructor(fwf: GenericFormField) {
    if (fwf) {
      if (
        fwf.formfieldKindId === FormFieldConfigurationKind.SEARCH ||
        fwf.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
        fwf.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
        fwf.formfieldKindId === FormFieldConfigurationKind.CHIPS
      ) {
        if (fwf.formfieldRequired === GenericFormFieldRequiredType.required && !this.isHidedField() && !this.isReadonlyField()) {
          if (fwf.formfieldEntityType === 'translation') {
            fwf.isRequiredControlValid = fwf.formfieldEntityTranslations.some((kv) => kv != null);
          } else if (fwf.formfieldEntityType === 'foreign') {
            fwf.isRequiredControlValid = fwf.formfieldEntityValue != null;
          } else {
            fwf.isRequiredControlValid =
              (fwf.formfieldEntityText != null && fwf.formfieldEntityText !== '') ||
              fwf.formfieldEntityValue != null ||
              (fwf.formfieldEntityRelations != null && fwf.formfieldEntityRelations.length > 0);
          }
        }
      }
      Object.assign(this, fwf);
    }
  }
  public isHidedField(): boolean {
    if (this.formfieldHide > 0) return true;
    if (this.hideBindedField == null) {
      return false;
    } else {
      return this.hideBindedField.isHidedField() ? true : this.xnor(this.formfieldHideontrue, this.fieldBooleanValue(this.hideBindedField));

      // return this.xnor(this.formfieldHideontrue, this.fieldBooleanValue(this.hideBindedField) && !this.hideBindedField.isHidedField());1
    }
  }

  public isHiddenItemToKeep(): boolean {
    if (this.isHidedField()) {
      if (this.formfieldHide == 1) return true;
      return this.hideBindedField == null
        ? !this.formfieldHideoncompact
        : this.hideBindedField.isHidedField()
        ? this.hideBindedField.isHiddenItemToKeep()
        : !this.formfieldHideoncompact;
    } else {
      return false;
    }
  }

  public isReadonlyField(): boolean {
    if (this.formfieldReadonly) {
      return true;
    }
    if (this.readonlyBindedField == null) {
      return false;
    } else {
      return this.readonlyBindedField.formfieldReadonly // Read only by configuration
        ? this.xnor(this.formfieldReadonlyontrue, this.fieldBooleanValue(this.readonlyBindedField))
        : this.readonlyBindedField.isReadonlyField()
        ? true
        : this.xnor(this.formfieldReadonlyontrue, this.fieldBooleanValue(this.readonlyBindedField));
    }
  }

  private xnor(a: boolean, b: boolean): boolean {
    const xnor = !((a || b) && !(a && b));
    return xnor;
  }

  private fieldBooleanValue(formFieldData: GenericFormField) {
    if (formFieldData.formfieldEntityType === 'boolean' || formFieldData.formfieldEntityType === 'option') {
      return formFieldData.formfieldEntityValue != null && formFieldData.formfieldEntityValue != 0;
    } else {
      return formFieldData.formfieldEntityValue != null || formFieldData.formfieldEntityText != null;
    }
  }
}

export class GenericFormFieldItem extends GenericFormField implements GridsterItem {
  // [propName: string]: any;
  x: number;
  y: number;
  rows: number;
  cols: number;
  layerIndex?: number;
  initCallback?: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => void;
  dragEnabled?: boolean;
  resizeEnabled?: boolean;
  compactEnabled?: boolean;
  maxItemRows?: number;
  minItemRows?: number;
  maxItemCols?: number;
  minItemCols?: number;
  minItemArea?: number;
  maxItemArea?: number;

  constructor(fwf: GenericFormField, currentRegion: string, THOUSANDS_SEPARATOR: string) {
    super(fwf);
    if (fwf) {
      this.formfieldEntityValue = this.formfieldEntityValue === undefined ? null : this.formfieldEntityValue;
      this.formfieldEntityText = this.formfieldEntityText === undefined ? null : this.formfieldEntityText;

      if (this.formfieldEntityType === 'percentage') {
        const number = +parseFloat(this.formfieldEntityText).toFixed(6);
        const normalizedPercentage = parseFloat((number * 100).toFixed(4));
        this.formfieldEntityText = this.formfieldEntityText != null ? normalizedPercentage.toString() : null;
        const decimal = +parseFloat(this.formfieldEntityText).toFixed(4);
        if (decimal === null || decimal === undefined || Number.isNaN(decimal)) {
          this.formfieldEntityFloatText = null;
        } else {
          this.formfieldEntityFloatText = decimal
            .toLocaleString(currentRegion, {
              style: 'decimal',
              maximumFractionDigits: 4,
              minimumFractionDigits: 4,
            })
            .replace(new RegExp(`\\${THOUSANDS_SEPARATOR}`, 'g'), '');
        }
      }
      if (this.formfieldEntityType === 'money') {
        const decimal = +parseFloat(this.formfieldEntityText).toFixed(2);
        if (decimal === null || decimal === undefined || Number.isNaN(decimal)) {
          this.formfieldEntityFloatText = null;
        } else {
          this.formfieldEntityFloatText = decimal
            .toLocaleString(currentRegion, {
              style: 'decimal',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })
            .replace(new RegExp(`\\${THOUSANDS_SEPARATOR}`, 'g'), '');
        }
      }
      if (this.formfieldEntityType === 'cost') {
        const decimal = +parseFloat(this.formfieldEntityText).toFixed(4);
        if (decimal === null || decimal === undefined || Number.isNaN(decimal)) {
          this.formfieldEntityFloatText = null;
        } else {
          this.formfieldEntityFloatText = decimal
            .toLocaleString(currentRegion, {
              style: 'decimal',
              maximumFractionDigits: 4,
              minimumFractionDigits: 4,
            })
            .replace(new RegExp(`\\${THOUSANDS_SEPARATOR}`, 'g'), '');
        }
      }
      if (this.formfieldEntityType === 'factor') {
        const decimal = +parseFloat(this.formfieldEntityText).toFixed(6);
        if (decimal === null || decimal === undefined || Number.isNaN(decimal)) {
          this.formfieldEntityFloatText = null;
        } else {
          this.formfieldEntityFloatText = decimal
            .toLocaleString(currentRegion, {
              style: 'decimal',
              maximumFractionDigits: 6,
              minimumFractionDigits: 6,
            })
            .replace(new RegExp(`\\${THOUSANDS_SEPARATOR}`, 'g'), '');
        }
      }
      if (this.formfieldEntityType === 'date') {
        const dateString: string[] =
          this.formfieldEntityText != null && this.formfieldEntityText !== '' ? this.formfieldEntityText.split('-') : null;
        const date =
          dateString != null
            ? new Date(Date.UTC(parseInt(dateString[0]), parseInt(dateString[1]) - 1, parseInt(dateString[2].split('T')[0])))
            : null;
        var userTimezoneOffset = date ? date.getTimezoneOffset() * 60000 : null;
        const utcDate = date ? new Date(date.getTime() + userTimezoneOffset) : null;
        this.formfieldEntityDate = utcDate;
      }
      if (this.formfieldEntityType === 'datetime') {
        const date = this.formfieldEntityText != null && this.formfieldEntityText !== '' ? new Date(this.formfieldEntityText) : null;
        this.formfieldEntityDate = date;
      }
      this.x = fwf.formfieldX;
      this.y = fwf.formfieldY;
      this.rows = fwf.formfieldH;
      this.cols = fwf.formfieldW;
    }
  }
}

export class FormFieldDataRequest {
  formfielddataId: number;
  formfielddataTextname: string;
  formfielddataType: string;
  formfielddataText: string;
  formfielddataValue: number;
  formfielddataSteps: StepValue[];
  formfielddataRelations: EntityRecord[];
  formfielddataTranslations: KeyValue<string, string>[];
  constructor(gff: GenericFormField) {
    this.formfielddataId = gff.formfieldId;
    this.formfielddataTextname = gff.formfieldTextname;
    this.formfielddataType = gff.formfieldEntityType;
    this.formfielddataText = gff.formfieldEntityText;
    this.formfielddataValue = gff.formfieldEntityValue;
    this.formfielddataSteps = gff.formfieldEntitySteps;
    this.formfielddataRelations = gff.formfieldEntityRelations;
    this.formfielddataTranslations = gff.formfieldEntityTranslations;
  }
}

export class GenericMassEditRequest {
  entityIds: number[];
  fields: FormFieldDataRequest[];
}

export class GenericFormFieldColumnRequest {
  entityText: string;
  entityTextname: string;
  entityTranslations: KeyValue<string, string>[];
  entityType: string;
  entityValue: number;
  constructor(gff?: GenericFormField) {
    if (gff != null) {
      this.entityTextname = gff.formfieldUnique;
      this.entityType = gff.formfieldEntityType;
      this.entityText = gff.formfieldEntityText;
      this.entityValue = gff.formfieldEntityValue;
      this.entityTranslations = gff.formfieldEntityTranslations;
    }
  }
}

export class GenericEntityFormFieldStep {
  formstepId: number;
  formstepStep: number;
  formstepTextname: string;
  formstepName: string;
}

export class GenericEntityFormFieldEvent {
  changedField: GenericFormField;
  changedFieldItem: GenericFormFieldItem;
  genericEntityFormFields: GenericFormField[];
  genericEntityFormFieldItems: GenericFormFieldItem[];
}

export enum GenericFormFieldRequiredType {
  notRequired = 0,
  requiredSoonAsPossible = 1,
  required = 2,
}
