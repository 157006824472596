import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { AssetSubscription } from 'src/app/models/asset-subscription';
import { Desk } from 'src/app/models/desk';
import { FeedItem } from 'src/app/models/feeditem';
import { AddAssetOrderRequest } from 'src/app/models/wizards/add-asset-order-request';
import { CopyAssetDueDates } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-asset-wizard/activation-step/copy-asset-due-date-info';
import { CopyAssetAdditionalInfo } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-asset-wizard/additional-step/additional-info';
import { CopyAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/copy-asset-wizard/init-step/init-step';

export const selectInitStep = createAction('[Asset Grid] Select Copy Init Information Step', props<{ initStep: CopyAssetInitStep }>());

export const selectAssetServicesInit = createAction(
  '[Copy Asset Init Step] Select Asset Services Init Information Step',
  props<{ initAssetServiceStep: AssetSubscription[] }>()
);

export const selectDueDatesStep = createAction(
  '[Copy Asset Due Date Step] Select Due Date Step',
  props<{ dueDatesStep: CopyAssetDueDates }>()
);

export const selectDeskStep = createAction('[Copy Asset Desk Step] Select Desk Step', props<{ deskStep: Desk }>());

export const selectServiceStep = createAction(
  '[Copy Asset Service Step] Select Service Information Step',
  props<{ serviceStep: FeedItem[] }>()
);

export const deleteServiceStep = createAction('[Copy Asset Service Step] Delete Service Information Step');

export const selectAdditionalStep = createAction(
  '[Copy Asset AddInfo Step] Select Additional Information Step',
  props<{ addInfoStep: CopyAssetAdditionalInfo }>()
);

export const deleteAdditionalStep = createAction('[Copy Asset AddInfo Step] Delete Additional Information Step');

export const exitCopyAssetWizard = createAction('[Copy Asset Wizard Page] Exit Allocation Copy Asset Wizard');

export const destroyWizard = createAction('[Copy Asset Wizard Page] Destroy Wizard');

export const createNewCopyAsset = createAction(
  '[Copy Asset Confirmation Step] Copy Asset Information Step',
  props<{ newOrder: AddAssetOrderRequest }>()
);

export const createNewOrderSuccess = createAction(
  '[Copy Asset Confirmation Step] Copy Asset Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewOrderFail = createAction(
  '[Copy Asset Confirmation Step] Copy Asset Information Step Fail',
  props<{ errorMessage: string }>()
);
