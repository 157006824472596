import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccessMode } from '../configs/access-mode';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { Entity, EntityOptionFilter, EntityRequest } from '../models/entity';
import { EntityTemplateTreeNode } from '../models/entity-template';
import { ForeignEntity, ForeignEntityRequest } from '../models/foreign-entity';
import { EntityWizard, EntityWizardResponse } from '../models/forms/entity-wizard';
import { FormFieldConfigurationRequest, FormFieldsOptions } from '../models/forms/form-configuration';
import { FormFieldConfigurationFieldKind } from '../models/forms/form-configuration-kind';
import {
  FormFieldDataRequest,
  GenericEntityFormFieldStep,
  GenericFormField,
  GenericFormFieldColumnRequest,
  GenericMassEditRequest,
} from '../models/forms/form-field';
import { GenericEntityField } from '../models/generic-entity-field';
import {
  GenericEntityConfiguration,
  GenericEntityFieldConfiguration,
  GenericEntitySectionConfig,
} from '../models/generic-entity-field-config';
import { GenericEntityFormFieldTreeNode } from '../models/generic-entity-form-field';
import { GenericPeriod } from '../models/generic-period';
import { GridFilterResponse } from '../models/grid-filter';
import { MailTemplateFrame } from '../models/mailtemplate/mail-template-particular';
import { OrderedRequest } from '../models/ordered-request';
import { PaginatedRequest } from '../models/paginated-request';
import { PropertyColumn, PropertyParticulars, PropertyParticularsRequest } from '../models/property';
import { SelectableEntity } from '../models/selectable-entity';
import { GenericTreeNode } from '../models/tree-view-flat-node';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class CoreDataService {
  // getModuleItems(isAuth: boolean, isTenantAdmin: boolean): Observable<BaseResponse<ModuleItem[]>> {
  //   const m = this.getModuleItems.name;
  //
  //   const path = isTenantAdmin
  //     ? environment.getEndpoint(ApiPath.TenantAdministration.MODULE_ITEM)
  //     : isAuth
  //     ? environment.getEndpoint(ApiPath.MODULE_ITEM)
  //     : environment.getEndpoint(ApiPath.Public.MODULE_ITEM);
  //   LogService.info(this, m, LogService.GET + path, null);
  //   const retVal = this.http.get<BaseResponse<ModuleItem[]>>(path);
  //
  //   return retVal;
  // }

  getEntitiesForeignFilters(entityKind: string): Observable<BaseResponse<Entity[]>> {
    const m = this.getEntitiesForeignFilters.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITIES_FOREIGN_FILTERS(entityKind));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<Entity[]>>(path);

    return retVal;
  }

  getEntitiesOptionFilters(entityKind: string): Observable<BaseResponse<EntityOptionFilter[]>> {
    const m = this.getEntitiesOptionFilters.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITIES_OPTION_FILTERS(entityKind));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<EntityOptionFilter[]>>(path);

    return retVal;
  }

  getEntitiesConfigurationsList(request: PaginatedRequest): Observable<BaseResponse<GenericEntityFieldConfiguration[]>> {
    const m = this.getEntitiesConfigurationsList.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_CONFIGURATION_LIST);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<GenericEntityFieldConfiguration[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getEntitiesConfigurationsListSpreadsheet(request: PaginatedRequest) {
    const m = this.getEntitiesConfigurationsListSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_CONFIGURATION_LIST_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getEntitiesFormsList(
    request: PaginatedRequest,
    entityId: number,
    formId: number,
    columnId: number,
    isTenantAdmin?: boolean
  ): Observable<BaseResponse<GenericEntityFormFieldTreeNode[]>> {
    const m = this.getEntitiesConfigurationsList.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.Entities.ENTITY_FORMS_LIST)
      : environment.getEndpoint(ApiPath.Entities.ENTITY_FORMS_LIST);
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, 'entityId', entityId);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = entityId != null ? params.append('entityId', entityId.toString()) : params;
    params = formId != null ? params.append('formId', formId.toString()) : params;
    params = columnId != null ? params.append('columnId', columnId.toString()) : params;
    const retVal = this.http.get<BaseResponse<GenericEntityFormFieldTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getEntitiesFormsListSpreadsheet(request: PaginatedRequest, isTenantAdmin?: boolean) {
    const m = this.getEntitiesConfigurationsListSpreadsheet.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.Entities.ENTITY_FORMS_LIST_SPREADSHEET)
      : environment.getEndpoint(ApiPath.Entities.ENTITY_FORMS_LIST_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getEntityKindConfiguration(entityKind: string, customConfigApiPath: string): Observable<BaseResponse<GenericEntityConfiguration>> {
    const m = this.getEntityKindConfiguration.name;

    const path = customConfigApiPath
      ? environment.getEndpoint(customConfigApiPath)
      : environment.getEndpoint(ApiPath.Entities.ENTITY_KIND_CONFIG(entityKind));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<GenericEntityConfiguration>>(path);

    return retVal;
  }

  putEntityKindConfiguration(
    entityKind: string,
    customConfigApiPath: string,
    request: GenericEntitySectionConfig[]
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.putEntityKindConfiguration.name;

    const path = customConfigApiPath
      ? environment.getEndpoint(customConfigApiPath)
      : environment.getEndpoint(ApiPath.Entities.ENTITY_KIND_CONFIG(entityKind));
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, 'Request ', request);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getDynamicFiltersByGridName(gridName: string): Observable<BaseResponse<GridFilterResponse>> {
    const m = this.getDynamicFiltersByGridName.name;

    const path = environment.getEndpoint(ApiPath.Grids.GRIDS_FILTERS(gridName));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<GridFilterResponse>>(path);

    return retVal;
  }

  changeDynamicFiltersFavoriteByGridName(gridName: string, filterId: number, favorite: boolean): Observable<BaseResponse<ActionResponse>> {
    const m = this.getDynamicFiltersByGridName.name;

    const path = environment.getEndpoint(ApiPath.Grids.GRIDS_FILTERS_FAVORITE(gridName, filterId));
    LogService.info(this, m, LogService.PUT + path, null);
    const params = new HttpParams().set('favorited', favorite ? 'true' : 'false');

    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  changeDynamicFiltersSortOrderByGridName(gridName: string, gridfilterIds: number[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.getDynamicFiltersByGridName.name;

    const path = environment.getEndpoint(ApiPath.Grids.GRIDS_FILTERS_SORTORDER(gridName));
    LogService.info(this, m, LogService.POST + path, null);
    const params = new HttpParams().set('gridfilterIds', gridfilterIds.join(','));

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  changeSearchElementActiveByGridName(
    gridName: string,
    searchelementId: number,
    active: boolean
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.changeSearchElementActiveByGridName.name;

    const path = environment.getEndpoint(ApiPath.Grids.GRIDS_SEARCH_ELEMENTS_ACTIVE(gridName, searchelementId));
    LogService.info(this, m, LogService.PUT + path, null);
    const params = new HttpParams().set('active', active ? 'true' : 'false');

    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  changeSearchElementSortOrderByGridName(gridName: string, searchelementIds: number[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.changeSearchElementSortOrderByGridName.name;

    const path = environment.getEndpoint(ApiPath.Grids.GRIDS_SEARCH_ELEMENTS_SORTORDER(gridName));
    LogService.info(this, m, LogService.POST + path, null);
    const params = new HttpParams().set('searchelementIds', searchelementIds.join(','));

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  changeDynamicFiltersDefaultsByGridName(
    gridName: string,
    filterId: number,
    defaultValues: any[]
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.getDynamicFiltersByGridName.name;

    const path = environment.getEndpoint(ApiPath.Grids.GRIDS_FILTERS_DEFAULT(gridName, filterId));
    LogService.info(this, m, LogService.PUT + path, null);
    const params = new HttpParams().set('defaultValues', defaultValues.join(','));

    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getFilterOptions(request: PaginatedRequest, apiPath): Observable<BaseResponse<Entity[]>> {
    const m = this.getFilterOptions.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<Entity[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getTreeFilterOptions(request: PaginatedRequest, apiPath): Observable<BaseResponse<GenericTreeNode[]>> {
    const m = this.getFilterOptions.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<GenericTreeNode[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getFilterOptionsWithoutPagination(apiPath): Observable<BaseResponse<Entity[]>> {
    const m = this.getFilterOptionsWithoutPagination.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<Entity[]>>(path);

    return retVal;
  }

  getGenericEntityFormFieldSteps(
    entityKind: string,
    entityId: number,
    isTenantAdmin: boolean
  ): Observable<BaseResponse<GenericEntityFormFieldStep[]>> {
    const m = this.getGenericEntityFormField.name;

    const path = environment.getEndpoint(
      isTenantAdmin
        ? ApiPath.TenantAdministration.Entities.ENTITY_FORMFIELDS_STEPS(entityKind)
        : ApiPath.Entities.ENTITY_FORMFIELDS_STEPS(entityKind, entityId)
    );
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<GenericEntityFormFieldStep[]>>(path);

    return retVal;
  }

  getCustomEntityFormFieldSteps(apiPath: string): Observable<BaseResponse<GenericEntityFormFieldStep[]>> {
    const m = this.getGenericEntityFormField.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<GenericEntityFormFieldStep[]>>(path);

    return retVal;
  }

  createEntityWizard(
    entityKind: string,
    relatedEntityKinds: string,
    relatedEntityIds: string,
    entityValue: string,
    fieldTextnames: string,
    fieldValues: string,
    isTenantAdmin: boolean,
    propertyTemplateId?: number
  ): Observable<BaseResponse<EntityWizard>> {
    const m = this.createEntityWizard.name;

    const path = environment.getEndpoint(
      isTenantAdmin ? ApiPath.TenantAdministration.Entities.ENTITY_WIZARD : ApiPath.Entities.ENTITY_WIZARD
    );
    LogService.info(this, m, LogService.POST + path, null);
    let params = new HttpParams().set('entityKind', entityKind);
    if (relatedEntityKinds) {
      params = params.append('relatedEntityKinds', relatedEntityKinds);
    }
    if (relatedEntityIds) {
      params = params.append('relatedEntityIds', relatedEntityIds);
    }
    if (entityValue) {
      params = params.append('entityValue', entityValue);
    }
    if (fieldTextnames && fieldValues) {
      params = params.append('fieldTextnames', fieldTextnames);
      params = params.append('fieldValues', fieldValues);
    }
    if (propertyTemplateId) {
      params = params.append('propertyTemplateId', propertyTemplateId.toString());
    }
    const retVal = this.http.post<BaseResponse<EntityWizard>>(path, null, { params });

    return retVal;
  }

  cancelEntityWizard(wizardId: number, isTenantAdmin: boolean): Observable<BaseResponse<ActionResponse>> {
    const m = this.cancelEntityWizard.name;

    const path = environment.getEndpoint(
      isTenantAdmin ? ApiPath.TenantAdministration.Entities.ENTITY_WIZARD : ApiPath.Entities.ENTITY_WIZARD
    );
    LogService.info(this, m, LogService.DELETE + path, null);
    const params = new HttpParams().set('wizardId', wizardId.toString());
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path, { params });

    return retVal;
  }

  getEntityWizardFinishStatus(wizardId: number): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.getEntityWizardFinishStatus.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_WIZARD_FINISH);
    const params = new HttpParams().set('wizardId', wizardId.toString());
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.PARAMS, params);
    const retVal = this.http.get<BaseResponse<EntityWizardResponse>>(path, {
      params,
    });
    return retVal;
  }

  getEntityWizardStepData(wizardId: number, step: number, isTenantAdmin: boolean): Observable<BaseResponse<GenericFormField[]>> {
    const m = this.getEntityWizardStepData.name;

    const path = environment.getEndpoint(
      isTenantAdmin ? ApiPath.TenantAdministration.Entities.ENTITY_WIZARD_STEPDATA : ApiPath.Entities.ENTITY_WIZARD_STEPDATA
    );
    LogService.info(this, m, LogService.GET + path, null);
    const params = new HttpParams().set('wizardId', wizardId.toString()).set('step', step.toString());
    const retVal = this.http.get<BaseResponse<GenericFormField[]>>(path, { params });

    return retVal;
  }

  putEntityWizardStepData(
    wizardId: number,
    step: number,
    request: FormFieldDataRequest[],
    isTenantAdmin: boolean,
    isApply?: boolean
  ): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.putEntityWizardStepData.name;

    const path = environment.getEndpoint(
      isTenantAdmin ? ApiPath.TenantAdministration.Entities.ENTITY_WIZARD_STEPDATA : ApiPath.Entities.ENTITY_WIZARD_STEPDATA
    );
    LogService.info(this, m, LogService.PUT + path, null);
    let params = new HttpParams().set('wizardId', wizardId.toString()).set('step', step.toString());
    if (isApply) {
      params = params.append('applyOnly', 'true');
    }
    const retVal = this.http.put<BaseResponse<EntityWizardResponse>>(path, request, { params });

    return retVal;
  }

  getGenericEntityFormField(apiPath: string): Observable<BaseResponse<GenericFormField[]>> {
    const m = this.getGenericEntityFormField.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<GenericFormField[]>>(path);

    return retVal;
  }

  createGenericEntityFormField(request: FormFieldDataRequest[], apiPath: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.createGenericEntityFormField.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  updateGenericEntityFormField(request: FormFieldDataRequest[], apiPath: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.updateGenericEntityFormField.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getFormFieldConfigurationFieldKinds(apiPath: string): Observable<BaseResponse<FormFieldConfigurationFieldKind[]>> {
    const m = this.getFormFieldConfigurationFieldKinds.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<FormFieldConfigurationFieldKind[]>>(path);

    return retVal;
  }

  getFormFieldConfiguration(apiPath: string): Observable<BaseResponse<FormFieldsOptions>> {
    const m = this.getFormFieldConfiguration.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<FormFieldsOptions>>(path);

    return retVal;
  }

  putFormFieldConfiguration(apiPath: string, request: FormFieldConfigurationRequest[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.putFormFieldConfiguration.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getEntitiesByEntityKind(
    request: PaginatedRequest,
    entityKind: string,
    relationEntityKind: string,
    isTenantAdmin: boolean
  ): Observable<BaseResponse<Entity[]>> {
    const m = this.getEntities.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.Entities.ENTITIES_GRID_BY_ENTITYKIND(entityKind))
      : environment.getEndpoint(ApiPath.Entities.ENTITIES_GRID_BY_ENTITYKIND(entityKind));
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    if (relationEntityKind) {
      params = params.append('relationEntityKinds', relationEntityKind);
    }
    LogService.info(this, m, 'Request ', request);
    const retVal = this.http.get<BaseResponse<Entity[]>>(path, {
      params,
    });

    return retVal;
  }

  getEntitiesByApipath(request: PaginatedRequest, apiPath: string): Observable<BaseResponse<Entity[]>> {
    const m = this.getEntities.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    LogService.info(this, m, 'Request ', request);
    const retVal = this.http.get<BaseResponse<Entity[]>>(path, {
      params,
    });

    return retVal;
  }

  getEntityTreeenabledByEntityKind(entityKind: string, isTenantAdmin: boolean, option: any): Observable<BaseResponse<boolean>> {
    const m = this.getEntityTreeenabledByEntityKind.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.Entities.ENTITIES_ENTITYKIND_TREEENABLED(entityKind))
      : environment.getEndpoint(ApiPath.Entities.ENTITIES_ENTITYKIND_TREEENABLED(entityKind));

    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<boolean>>(path, { params: option != null ? new HttpParams().set('option', option) : null });

    return retVal;
  }

  getEntities(request: PaginatedRequest, apiPath: string): Observable<BaseResponse<Entity[]>> {
    const m = this.getEntities.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'Request ', request);
    const retVal = this.http.get<BaseResponse<Entity[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getEntitiesSpreadsheet(request: PaginatedRequest, apiPath: string) {
    const m = this.getEntitiesSpreadsheet.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getExceptionErrorFileForLoggedUser(request: PaginatedRequest, isTenantAdmin: boolean) {
    const m = this.getExceptionErrorFileForLoggedUser.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.Errors.ERRORS_SPREADSHEET_LOGGED_USER)
      : environment.getEndpoint(ApiPath.Errors.ERRORS_SPREADSHEET_LOGGED_USER);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = params.append('filterDate', this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getEntitiesWithoutPagination(request: OrderedRequest, apiPath: string): Observable<BaseResponse<Entity[]>> {
    const m = this.getEntitiesWithoutPagination.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'Request ', request);
    const retVal = this.http.get<BaseResponse<Entity[]>>(path, {
      params: OrderedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getGenericEntityRelatedRecord(entityKind: string, entityId: number, relatedKind: string): Observable<BaseResponse<Entity>> {
    const m = this.getGenericEntityFields.name;

    const path = environment.getEndpoint(ApiPath.Entities.GET_RELATED_RECORD(entityKind, entityId, relatedKind));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<Entity>>(path);

    return retVal;
  }

  getGenericEntityFields(apiPath: string): Observable<BaseResponse<GenericEntityField[]>> {
    const m = this.getGenericEntityFields.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<GenericEntityField[]>>(path);

    return retVal;
  }

  getGenericEntityFormFields(
    apiPath: string,
    relatedEntityKinds?: string[],
    relatedEntityIds?: number[]
  ): Observable<BaseResponse<GenericFormField[]>> {
    const m = this.getGenericEntityFields.name;

    const path = environment.getEndpoint(apiPath);
    let params = new HttpParams();
    if (relatedEntityKinds != null && relatedEntityKinds.length > 0 && relatedEntityIds != null && relatedEntityIds.length > 0) {
      params = params.append('relatedEntityKinds', relatedEntityKinds.join(','));
      params = params.append('relatedEntityIds', relatedEntityIds.join(','));
    }
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<GenericFormField[]>>(path, { params });

    return retVal;
  }

  createGenericEntityFields(request: GenericEntityField[], apiPath: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.createGenericEntityFields.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  updateGenericEntityFields(request: GenericEntityField[], apiPath: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.updateGenericEntityFields.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  removeEntity(apiPath: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.removeEntity.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.DELETE + path, null);
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path);

    return retVal;
  }

  getForeignEntity(apiPath: string, request: ForeignEntityRequest, parentId?: number): Observable<BaseResponse<ForeignEntity[]>> {
    const m = this.getForeignEntity.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);
    let params = request ? PaginatedRequest.getBaseRequestParams(request) : new HttpParams();
    if (parentId) {
      params = params.append('parentId', parentId.toString());
    }
    if (request) {
      if (request.linkableFlag != null) {
        params = params.append('accessmode', AccessMode.link.toString());
      }
      if (request.filterName != null && request.filterIds != null && request.filterIds.length > 0) {
        params = params.append(request.filterName, request.filterIds.join(','));
      }
      if (request.filterEntityKind != null && request.filterEntityId != null) {
        params = params.append('filterEntityKind', request.filterEntityKind);
        params = params.append('filterEntityId', request.filterEntityId.toString());
      }
      if (request.filterColumn1 != null && request.filterColumn1Values != null) {
        params = params.append('filterColumn1', request.filterColumn1);
        params = params.append('filterColumn1Values', request.filterColumn1Values.join(','));
      }
      if (request.filterColumn2 != null && request.filterColumn2Values != null) {
        params = params.append('filterColumn2', request.filterColumn2);
        params = params.append('filterColumn2Values', request.filterColumn2Values.join(','));
      }
      if (request.baseEntityKind != null) {
        params = params.append('baseEntityKind', request.baseEntityKind);
      }
      if (request.baseEntityId != null) {
        params = params.append('baseEntityId', request.baseEntityId);
      }
      if (request.excludeIds != null) {
        params = params.append('excludeIds', request.excludeIds.join(','));
      }
    }
    const retVal = this.http.get<BaseResponse<ForeignEntity[]>>(path, {
      params,
    });

    return retVal;
  }

  getGenericTreeNodeChilds(
    baseApiPath: string,
    nodeId: number,
    nodeName: string,
    params?: string
  ): Observable<BaseResponse<GenericTreeNode[]>> {
    const m = this.getGenericTreeNodeChilds.name;

    let path = `${environment.getEndpoint(baseApiPath)}/${nodeId}/${nodeName}`;
    if (params) {
      path = path.concat(`?${params}`);
    }
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<GenericTreeNode[]>>(path);

    return retVal;
  }

  getSelectableEntities(apiPath: string) {
    const m = this.getSelectableEntities.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<SelectableEntity[]>>(path);

    return retVal;
  }

  startDeletionPrecast(entities: EntityRequest[], isTenantAdmin?: boolean): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.getGenericTreeNodeChilds.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.Entities.ENTITIES_DELETION_PRECAST)
      : environment.getEndpoint(ApiPath.Entities.ENTITIES_DELETION_PRECAST);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, entities);

    return retVal;
  }

  getDeletionPrecastStatus(importId: number, isTenantAdmin?: boolean): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.getGenericTreeNodeChilds.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.Entities.ENTITIES_DELETION_PRECAST)
      : environment.getEndpoint(ApiPath.Entities.ENTITIES_DELETION_PRECAST);
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams().set('importId', importId.toString());
    const retVal = this.http.get<BaseResponse<EntityWizardResponse>>(path, { params });

    return retVal;
  }

  stopDeletionPrecast(importId: number, isTenantAdmin?: boolean): Observable<BaseResponse<ActionResponse>> {
    const m = this.getGenericTreeNodeChilds.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.Entities.ENTITIES_DELETION_PRECAST)
      : environment.getEndpoint(ApiPath.Entities.ENTITIES_DELETION_PRECAST);
    LogService.info(this, m, LogService.DELETE + path, null);
    let params = new HttpParams().set('importId', importId.toString());
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path, { params });

    return retVal;
  }

  deletionEntities(entities: EntityRequest[], isTenantAdmin?: boolean): Observable<BaseResponse<ActionResponse>> {
    const m = this.removeEntity.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.Entities.ENTITIES_DELETION)
      : environment.getEndpoint(ApiPath.Entities.ENTITIES_DELETION);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, entities);

    return retVal;
  }

  deleteEntityByCustomApi(apiPath: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.removeEntity.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.DELETE + path, null);
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path);

    return retVal;
  }

  // OLD DELETE
  deleteEntities(entityKind: string, ids: number[], isTenantAdmin: boolean): Observable<BaseResponse<ActionResponse>> {
    const m = this.getGenericTreeNodeChilds.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.Entities.DELETE_ENTITY_KIND(entityKind, ids))
      : environment.getEndpoint(ApiPath.Entities.DELETE_ENTITY_KIND(entityKind, ids));
    LogService.info(this, m, LogService.DELETE + path, null);
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path);

    return retVal;
  }

  getGenericPeriods(apiPath: string): Observable<BaseResponse<GenericPeriod[]>> {
    const m = this.getGenericPeriods.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<GenericPeriod[]>>(path);

    return retVal;
  }

  getEntityTemplateNodes(
    request: PaginatedRequest,
    entityKindId?: number,
    templateId?: number,
    formId?: number
  ): Observable<BaseResponse<EntityTemplateTreeNode[]>> {
    const m = this.getEntityTemplateNodes.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_TEMPLATE_NODES);
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = entityKindId != null ? params.append('entityKindId', entityKindId.toString()) : params;
    params = templateId != null ? params.append('templateId', templateId.toString()) : params;
    params = formId != null ? params.append('formId', formId.toString()) : params;
    const retVal = this.http.get<BaseResponse<EntityTemplateTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getEntityTemplateByEntityKind(entityKind: string): Observable<BaseResponse<EntityTemplateTreeNode[]>> {
    const m = this.getEntityTemplateNodes.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_TEMPLATE_BY_ENTITYKIND(entityKind));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<EntityTemplateTreeNode[]>>(path);

    return retVal;
  }

  getEntityTemplateNodesSpreadsheet(request: PaginatedRequest) {
    const m = this.getEntitiesConfigurationsListSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_TEMPLATE_NODES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  resetToOriginalProperty(entityKind: string, isTenantAdmin?: boolean): Observable<BaseResponse<ActionResponse>> {
    const m = this.resetToOriginalProperty.name;

    const path = isTenantAdmin
      ? environment.getEndpoint(ApiPath.TenantAdministration.Entities.ENTITY_RESET_FORM(entityKind))
      : environment.getEndpoint(ApiPath.Entities.ENTITY_RESET_FORM(entityKind));
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  resetToOriginalPropertyForm(entityKind: string, formId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.resetToOriginalPropertyForm.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_RESET_FORMFIELD(entityKind, formId));
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  getPropertyParticulars(propertyId: number): Observable<BaseResponse<PropertyParticulars>> {
    const m = this.getPropertyParticulars.name;

    const path = environment.getEndpoint(ApiPath.Properties.PROPERTY_PARTICULARS(propertyId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<PropertyParticulars>>(path);

    return retVal;
  }

  putPropertyParticulars(propertyId: number, request: PropertyParticularsRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.putPropertyParticulars.name;

    const path = environment.getEndpoint(ApiPath.Properties.PROPERTY_PARTICULARS(propertyId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getPropertyColumns(request: PaginatedRequest): Observable<BaseResponse<PropertyColumn[]>> {
    const m = this.getEntityTemplateNodes.name;

    const path = environment.getEndpoint(ApiPath.Properties.PROPERTY_COLUMNS);
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<PropertyColumn[]>>(path, { params });

    return retVal;
  }

  takeOwnership(entityKind: string, entityId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.takeOwnership.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_OWNERSHIP_BY_ENTITYKIND_BY_ENTITYID(entityKind, entityId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  assignOwnershipToUserId(entityKind: string, entityId: number, userId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.takeOwnership.name;

    const path = environment.getEndpoint(
      ApiPath.Entities.ENTITY_OWNERSHIP_BY_ENTITYKIND_BY_ENTITYID_AND_USERID(entityKind, entityId, userId)
    );
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  releaseOwnership(entityKind: string, entityId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.releaseOwnership.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_OWNERSHIP_BY_ENTITYKIND_BY_ENTITYID(entityKind, entityId));
    const params = new HttpParams().set('release', true);
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getSaveEntityStatus(importId: number): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.getSaveEntityStatus.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_SAVE_PROGRESS);
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams().set('importId', importId.toString());
    const retVal = this.http.get<BaseResponse<EntityWizardResponse>>(path, { params });

    return retVal;
  }

  getSaveEntityStatusByApipath(apiPath: string): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.getSaveEntityStatusByApipath.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<EntityWizardResponse>>(path);

    return retVal;
  }

  cancelSaveEntity(importId: number): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.cancelSaveEntity.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_SAVE_PROGRESS);
    LogService.info(this, m, LogService.DELETE + path, null);
    let params = new HttpParams().set('importId', importId.toString());
    const retVal = this.http.delete<BaseResponse<EntityWizardResponse>>(path, { params });

    return retVal;
  }

  validateGenericFormFieldUniqueness(
    entityKind: string,
    request: GenericFormFieldColumnRequest[]
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.validateGenericFormFieldUniqueness.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_UNIQUENESS_VALIDATION(entityKind));
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  validateGenericFormFieldRelatedUniqueness(
    entityKind: string,
    relatedEntityKind: string,
    relatedEntityId: number,
    request: GenericFormFieldColumnRequest[]
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.validateGenericFormFieldRelatedUniqueness.name;

    const path = environment.getEndpoint(
      ApiPath.Entities.ENTITY_RELATED_UNIQUENESS_VALIDATION(entityKind, relatedEntityKind, relatedEntityId)
    );
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getMassEditForms(entityKind: string, entityIds: number[]): Observable<BaseResponse<GenericFormField[]>> {
    const m = this.getMassEditForms.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_KIND_MASS_EDIT_FORM(entityKind));
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams().set('entityIds', entityIds.join(','));
    const retVal = this.http.get<BaseResponse<GenericFormField[]>>(path, { params });

    return retVal;
  }

  putMassEditForms(entityKind: string, request: GenericMassEditRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.getMassEditForms.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_KIND_MASS_EDIT_FORM(entityKind));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getEntityMailByEntitKindEntityId(entityKind: string, entityId: number): Observable<BaseResponse<MailTemplateFrame>> {
    const m = this.getEntityMailByEntitKindEntityId.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_KIND_MAIL_TEMPLATE(entityKind, entityId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<MailTemplateFrame>>(path);

    return retVal;
  }

  putEntityMailByEntitKindEntityId(
    entityKind: string,
    entityId: number,
    mailframe: MailTemplateFrame
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.putEntityMailByEntitKindEntityId.name;

    const path = environment.getEndpoint(ApiPath.Entities.ENTITY_KIND_MAIL_TEMPLATE(entityKind, entityId));
    LogService.info(this, m, LogService.PUT + path, null);
    // const params = new HttpParams().set('mailframe', mailframe);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, mailframe);

    return retVal;
  }

  constructor(private datePipe: DatePipe, private http: HttpClient) {}
}
