<div
  class="upload-drop-zone"
  [ngClass]="isUploadDisabled() ? 'disabled' : ''"
  appFileDragDrop
  (fileDropped)="uploadFile($event)"
  backgroundColor="#f5fcff"
  dragOverBackgroundColor="#9ecbec"
  [dropDisabled]="isUploadDisabled()"
>
  {{
    expectedFiles > 1
      ? ('file_dropper_drop_multiple_files_area_placeholder' | translate)
      : ('file_dropper_drop_single_file_area_placeholder' | translate)
  }}
</div>
<div class="hint-text">
  <span>{{ 'file_dropper_max_file_size' | translate }}</span>
  <span *ngIf="fileRestrictionText">{{ fileRestrictionText | translate }}</span>
</div>
<button mat-raised-button color="primary" (click)="fileInput.click()" [disabled]="isUploadDisabled()">
  {{ 'file_dropper_select_file_button' | translate }}
  <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" multiple />
</button>
<button
  mat-raised-button
  color="primary"
  (click)="openDocumentsDialog()"
  [disabled]="isUploadDisabled()"
  style="margin-left: 0.5rem"
  *ngIf="loadDbFiles"
>
  {{ 'file_dropper_select_filefromdocument_button' | translate }}
</button>
<app-alert *ngIf="showError()" status="error" [message]="'file_dropper_too_many_files_loaded' | translate"></app-alert>
<div class="files-list">
  <ng-container *ngIf="encodeFiles">
    <mat-card *ngFor="let file of encodedFiles; let i = index" class="file-item">
      <span class="file-info">{{ file.filename }}</span>
      <section class="loaded-file-section">
        <mat-icon class="action-icon" (click)="deleteAttachment(i)"> cancel </mat-icon>
      </section>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="!encodeFiles">
    <mat-card *ngFor="let file of files; let i = index" class="file-item">
      <span class="file-info">{{ file.name }}</span>
      <section class="loaded-file-section">
        <mat-icon class="action-icon" (click)="deleteAttachment(i)"> cancel </mat-icon>
      </section>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="loadDbFiles">
    <mat-card *ngFor="let file of dbFiles; let i = index" class="file-item">
      <span class="file-info">{{ file.filenodeName }}</span>
      <section class="loaded-file-section">
        <mat-icon class="action-icon" (click)="deleteDbAttachment(i)"> cancel </mat-icon>
      </section>
    </mat-card>
  </ng-container>
</div>
