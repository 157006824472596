import { CdkDragEnd, DragRef, Point } from '@angular/cdk/drag-drop';
import { Directive, ElementRef, EventEmitter, Output, Renderer2, RendererFactory2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Calendar } from 'primeng/calendar';
import { AuthUser } from 'src/app/core/auth/auth-user';
import {
  BaseResponse,
  has3diProfilerFlag,
  hasAutoexpandFlag,
  hasCollaborationModuleFlag,
  hasCreateFlag,
  hasDocumentFlag,
  hasExcelFlag,
  hasInvoiceItemEditFlag,
  hasMultipleAttachmentsFlag,
  hasMultipleCostcentresFlag,
  hasNotesFlag,
  hasOrderFlag,
  hasOutstandingsFlag,
  hasPdfFlag,
  hasSingleAttachmentsFlag,
} from 'src/app/models/base-response';
import { Entity } from 'src/app/models/entity';
import { FlyingButtonSettings } from 'src/app/models/preference-settings';
import { AuthStoreSelectors } from 'src/app/root-store';
import { FinesseChatbotInterfaceStoreAction } from 'src/app/root-store/finesse-chatbot-interface.store';
import { PreferencesAction, PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { LogService } from 'src/app/services/log-service';
import { BrowserTitleService } from 'src/app/services/utils/browser-title.service';
import { DecimalDateFormatterService } from 'src/app/services/utils/date-formatter.service';
import { BaseComponent } from 'src/app/shared/base-components/base-component';
import { AppInjector } from '../../app-injector.service';
import { DatepickerTodayHeaderComponent } from '../datepicker-today-header/datepicker-today-header.component';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FormatComponent extends BaseComponent {
  @Output() isDocumentEnabledEvEm = new EventEmitter<boolean>();
  @Output() isCatalogProfilerEnabledEvEm = new EventEmitter<boolean>();
  @Output() isCollaborationModuleEnabledEvEm = new EventEmitter<boolean>();
  @ViewChild('flyingButtons') set content(el: ElementRef) {
    if (el) {
      // LogService.debug(this, 'FormatComponent.flyingButtons', 'START', null);
      const buttonWidth = el.nativeElement.clientWidth;
      const buttonHeight = el.nativeElement.clientHeight;
      // initially setter gets called with undefined
      let container = getClosestMatchingAncestor(el.nativeElement, '.main-content-container');
      if (container == null) {
        container = getClosestMatchingAncestor(el.nativeElement, '.route-panel');
      }
      if (container == null) {
        container = getClosestMatchingAncestor(el.nativeElement, '.flying-button-custom-container');
      }
      if (container == null) {
        container = getClosestMatchingAncestor(el.nativeElement, '.cdk-overlay-pane');
      }
      if (container != null) {
        const maxContainerX = container.clientWidth;
        const maxContainerY = container.clientHeight;
        // LogService.debug(this, 'FormatComponent.flyingButtons', 'container', container);
        // LogService.debug(
        //   this,
        //   'FormatComponent.flyingButtons',
        //   'container',
        //   `maxContainerX: ${maxContainerX}, maxContainerY: ${maxContainerY}`
        // );
        // LogService.debug(this, 'FormatComponent.flyingButtons', 'coordinates', `X: ${this.x}, Y: ${this.y}`);
        const flyingX = Math.abs(this.x) < maxContainerX - buttonWidth ? this.x : -(maxContainerX - buttonWidth);
        const flyingY = Math.abs(this.y) < maxContainerY - buttonHeight ? this.y : -(maxContainerY - buttonHeight);
        this.renderer.setStyle(el.nativeElement, 'transform', `translate3d(${flyingX}px,${flyingY}px,0px)`);
        // LogService.debug(this, 'FormatComponent.flyingButtons', 'transform', `translate3d(${flyingX}px,${flyingY}px,0px)`);
      } else {
        this.renderer.setStyle(el.nativeElement, 'transform', `translate3d(${this.x}px,${this.y}px,0px)`);
        // LogService.debug(this, 'FormatComponent.flyingButtons', 'transform', `translate3d(${this.x}px,${this.y}px,0px)`);
      }
      // LogService.debug(this, 'FormatComponent.flyingButtons', 'END', null);
    }
  }
  protected translate: TranslateService;
  protected dateFormatterService: DecimalDateFormatterService;
  protected rendererFactory: RendererFactory2;
  protected renderer: Renderer2;
  protected store: Store;
  protected authUser: AuthUser;
  protected isTenantAdmin: boolean;
  protected titleService: Title;
  protected browserTitleService: BrowserTitleService;
  flyingBtnDragging = false;
  isShowDetailsEntityid = false;
  $mainThemeColor: string;
  $dragOverBorderColor: string;
  //FlyingButtons Coordinates
  x: number;
  y: number;

  datepickerTodayHeader = DatepickerTodayHeaderComponent;

  // Flags
  isCreateEnabled = false;
  isOrderEnabled = false;
  isDocumentEnabled = false;
  isMulticostcentresEnabled = false;
  isAutoExpandEnabled = false;
  isNoteEnabled = false;
  isExcelEnabled = false;
  isPdfEnabled = false;
  isOutstandingsEnabled = false;
  isCatalogProfilerEnabled = false;
  isSingleAttachmentsEnabled = false;
  isMultipleAttachmentsEnabled = false;
  isCollaborationModuleEnabled = false;
  isInvoiceItemEditEnabled = false;
  isChatbotEnabled = false;

  constructor() {
    super();
    // Manually retrieve the dependencies from the injector
    // so that constructor has no dependencies that must be passed in from child
    const injector = AppInjector.getInjector();
    this.translate = injector.get(TranslateService);
    this.dateFormatterService = injector.get(DecimalDateFormatterService);
    this.rendererFactory = injector.get(RendererFactory2);
    this.store = injector.get(Store);
    this.titleService = injector.get(Title);
    this.browserTitleService = injector.get(BrowserTitleService);
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.$mainThemeColor = getComputedStyle(document.body).getPropertyValue('--bst-color').trim();
    this.$dragOverBorderColor = getComputedStyle(document.body).getPropertyValue('--drag-over-border-color').trim();
    this.subscribe(
      this.store.select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin),
      (isAdminAuth) => (this.isTenantAdmin = isAdminAuth)
    );
    this.subscribe(this.store.select(AuthStoreSelectors.selectAuthUser), (authUser) => (this.authUser = authUser));
    this.subscribe(this.store.select(PreferencesSelectors.selectFlyingButtonCoordinates), (coordinates) => {
      if (coordinates.x > 0 || coordinates.y > 0) {
        this.x = 0;
        this.y = 0;
      } else {
        this.x = coordinates.x;
        this.y = coordinates.y;
      }
    });
    this.subscribe(this.store.select(PreferencesSelectors.selectShowEntityid), (showId) => {
      this.isShowDetailsEntityid = showId;
    });
    this.subscribe(this.store.select(PreferencesSelectors.selectChatbotEnabled), (chatbotEnabled) => {
      this.isChatbotEnabled = chatbotEnabled;
    });
  }

  setFlagsByResponse<T>(response: BaseResponse<T>) {
    this.isCreateEnabled = hasCreateFlag(response);
    this.isOrderEnabled = hasOrderFlag(response);
    this.isDocumentEnabled = hasDocumentFlag(response);
    this.isMulticostcentresEnabled = hasMultipleCostcentresFlag(response);
    this.isAutoExpandEnabled = hasAutoexpandFlag(response);
    this.isNoteEnabled = hasNotesFlag(response);
    this.isExcelEnabled = hasExcelFlag(response);
    this.isPdfEnabled = hasPdfFlag(response);
    this.isSingleAttachmentsEnabled = hasSingleAttachmentsFlag(response);
    this.isMultipleAttachmentsEnabled = hasMultipleAttachmentsFlag(response);
    this.isOutstandingsEnabled = hasOutstandingsFlag(response);
    this.isCatalogProfilerEnabled = has3diProfilerFlag(response);
    this.isCollaborationModuleEnabled = hasCollaborationModuleFlag(response);
    this.isInvoiceItemEditEnabled = hasInvoiceItemEditFlag(response);
    this.isDocumentEnabledEvEm.emit(this.isDocumentEnabled);
    this.isCatalogProfilerEnabledEvEm.emit(this.isCatalogProfilerEnabled);
    this.isCollaborationModuleEnabledEvEm.emit(this.isCollaborationModuleEnabled);
  }

  setFocus(el: HTMLInputElement) {
    setTimeout(() => {
      el.focus();
    }, 100);
  }

  computeDragRenderPos(pos: Point, dragRef: DragRef, dimensions: ClientRect, pickupPositionInElement: Point) {
    const newPos = { x: pos.x - pickupPositionInElement.x, y: pos.y - pickupPositionInElement.y };
    if (newPos.x < -(dimensions.width - 50)) newPos.x = -(dimensions.width - 50);
    if (newPos.y < 0) newPos.y = 0;
    if (newPos.x > window.innerWidth - 50) newPos.x = window.innerWidth - 50;
    if (newPos.y > window.innerHeight - 50) newPos.y = window.innerHeight - 50;
    return newPos;
  }

  calendarOnTodayButtonClick($event, pCalendar: Calendar) {
    let date: Date = new Date();
    let dateMeta = {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
      otherMonth: date.getMonth() !== pCalendar.currentMonth || date.getFullYear() !== pCalendar.currentYear,
      today: true,
      selectable: true,
    };
    if (pCalendar.disabled || !dateMeta.selectable) {
      $event.preventDefault();
      return;
    }

    if (pCalendar.isMultipleSelection() && pCalendar.isSelected(dateMeta)) {
      pCalendar.value = pCalendar.value.filter((date, i) => {
        return !pCalendar.isDateEquals(date, dateMeta);
      });
      if (pCalendar.value.length === 0) {
        pCalendar.value = null;
      }
      pCalendar.updateModel(pCalendar.value);
    } else {
      if (pCalendar.shouldSelectDate(dateMeta)) {
        pCalendar.selectDate(dateMeta);
        // pCalendar.updateModel(pCalendar.value);
        pCalendar.updateUI();
      }
    }

    pCalendar.updateInputfield();
    $event.preventDefault();
    pCalendar.onTodayClick.emit($event);
  }

  matAutocompletPanelOpened() {
    setTimeout(() => {
      let element = document.querySelector('.cdk-overlay-connected-position-bounding-box');
      if (element) {
        element.insertAdjacentHTML(
          'beforebegin',
          '<div id="mat-autocomplete-backdrop" class="cdk-overlay-backdrop cdk-overlay-transparent-backdrop cdk-overlay-backdrop-showing"></div>'
        );
      }
    }, 100);
  }

  matAutocompletPanelClosed() {
    setTimeout(() => {
      let element = document.getElementById('mat-autocomplete-backdrop');
      if (element) {
        element.remove();
      }
    }, 100);
  }

  concatenateEntities(pf: Entity[]): string {
    return pf ? pf.map((p) => p.entityName).join(', ') : null;
  }

  castToBoolean(key: string): boolean {
    if (key != null && (key === 'true' || key === 'false')) {
      return key === 'true' ? true : false;
    } else {
      return null;
    }
  }

  castToInt(key: string): number {
    return key != null ? parseInt(key, 10) : null;
  }

  castToFloat2Decimal(key: string): number {
    return key != null ? +parseFloat(key.replace(',', '.')).toFixed(2) : null;
  }

  castToFloat4Decimal(key: string): number {
    return key != null ? +parseFloat(key.replace(',', '.')).toFixed(4) : null;
  }

  castToFloat6Decimal(key: string): number {
    return key != null ? +parseFloat(key.replace(',', '.')).toFixed(6) : null;
  }

  castToFloat14Decimal(key: string): number {
    return key != null ? +parseFloat(key.replace(',', '.')).toFixed(14) : null;
  }

  addNumber(summand1: number, summand2: number): number {
    if (summand1 == null) return summand2;
    else if (summand2 == null) return summand1;
    else return summand1 + summand2;
  }

  divideNumber(dividend: number, divisor: number): number {
    if (dividend == null || divisor == null || divisor === 0) return null;
    return dividend / divisor;
  }

  dateFormInit(date: Date): Date {
    if (date == null || !moment(date).isValid()) {
      return null;
    }
    if (typeof date === 'number' || date instanceof Number) {
      var d = new Date(date);
      var userTimezoneOffset = d.getTimezoneOffset() * 60000;
      const utcDate = new Date(d.getTime() + userTimezoneOffset);
      return utcDate;
    }
    return moment(date).isValid() ? date : null;
  }

  dateFormRequest(date: Date): any {
    if (date == null) {
      return null;
    }
    if (typeof date === 'string' || date instanceof String) {
      return date;
    }
    if (typeof date === 'number' || date instanceof Number) {
      return moment(date).isValid() ? moment(date).utc(false).format('YYYY-MM-DD') : null;
    }
    return moment(date).isValid() ? moment(date).utc(true).format('YYYY-MM-DD') : null;
  }

  dateCast(date: Date): any {
    if (date == null) {
      return null;
    }
    if (typeof date === 'string' || date instanceof String) {
      return date;
    }
    return moment(date).isValid() ? moment(date).utc(false).format('YYYY-MM-DD') : null;
  }

  datetimeCast(date: Date): any {
    if (date == null) {
      return null;
    }
    // if (typeof date === 'string' || date instanceof String) {
    //   return date;
    // }
    return moment(date).isValid() ? moment(date).utc(true).format('YYYY-MM-DD HH:mm:ss') : null;
  }

  datetimeCastUTC(date: Date): any {
    if (date == null) {
      return null;
    }
    // if (typeof date === 'string' || date instanceof String) {
    //   return date;
    // }
    return moment(date).isValid() ? moment(date).utc(false).format('YYYY-MM-DD HH:mm:ss') : null;
  }

  timeCast(date: Date) {
    return date === null || date === undefined ? null : moment(date).isValid() ? moment(date).utc(false).format('HH:mm') : null;
  }

  getDate(date: Date | moment.Moment | number): string {
    return this.dateFormatterService.getDate(date);
  }

  getDateNotUTC(date: Date): string {
    return this.dateFormatterService.getDateNotUTC(date);
  }

  getDateFromString(date: string): string {
    const utcDate = moment(date).utc(true).toDate();
    return this.dateFormatterService.getDate(utcDate);
  }

  getDateNotUTCFromString(date: string): string {
    const utcDate = moment(date).utc(false).toDate();
    return this.dateFormatterService.getDate(utcDate);
  }

  getDatePlaceholder(): string {
    return this.dateFormatterService.getDatePlaceholder();
  }

  getDateRegionalFormat(): string {
    return this.dateFormatterService.getDateRegionalFormat();
  }

  getDateLiteral(date: Date): string {
    return this.dateFormatterService.getDateLiteral(date);
  }

  getCalendarDate(date: Date): string {
    return this.dateFormatterService.getCalendarDate(date);
  }

  getDateWithoutYear(date: Date): string {
    return this.dateFormatterService.getDateWithoutYear(date);
  }

  getDateWithoutYearNotUTC(date: Date): string {
    return this.dateFormatterService.getDateWithoutYearNotUTC(date);
  }

  getTime(date: Date): string {
    return this.dateFormatterService.getTime(date);
  }

  getDatetime(date: Date): string {
    return this.dateFormatterService.getDatetime(date);
  }

  getTimestamp(date: Date): string {
    return this.dateFormatterService.getTimestamp(date);
  }

  getDatetimePlaceholder(): string {
    return this.dateFormatterService.getDatetimePlaceholder();
  }

  getDatetimeRegionalFormat(): string {
    return this.dateFormatterService.getDatetimeRegionalFormat();
  }

  getDecimal(decimal: number): string {
    return this.dateFormatterService.get2DecimalDigits(decimal);
  }

  getInteger(decimal: number): string {
    return this.dateFormatterService.getInteger(decimal);
  }

  getCost(decimal: number): string {
    return this.dateFormatterService.get4DecimalDigits(decimal);
  }

  getMoney(decimal: number): string {
    return this.dateFormatterService.get2DecimalDigits(decimal);
  }

  getFactor(decimal: number): string {
    return this.dateFormatterService.get6DecimalDigits(decimal);
  }

  getPercentage(decimal: number): string {
    // xxx.dddd 100.0000 0.9999
    return this.dateFormatterService.get4DecimalDigits(decimal);
  }

  getFactorPercentage(decimal: number): string {
    // xxx.dddddddddddddd 100.0000 0.9999
    return this.dateFormatterService.get14DecimalDigits(decimal);
  }

  getCostEdit(decimal: number): string {
    return this.dateFormatterService.get4DecimalDigitsWithoutThousandSeparator(decimal);
  }

  getMoneyEdit(decimal: number): string {
    return this.dateFormatterService.get2DecimalDigitsWithoutThousandSeparator(decimal);
  }

  getFactorEdit(decimal: number): string {
    return this.dateFormatterService.get6DecimalDigitsWithoutThousandSeparator(decimal);
  }

  getPercentageEdit(decimal: number): string {
    // xxx.dddd 100.0000 0.9999
    return this.dateFormatterService.get4DecimalDigitsWithoutThousandSeparator(decimal);
  }

  getFactorPercentageEdit(decimal: number): string {
    // xxx.dddd 100.0000 0.9999
    return this.dateFormatterService.get14DecimalDigitsWithoutThousandSeparator(decimal);
  }

  getPercentageNormalized(decimal: number): string {
    // multiply by 100 and format like getPercentage
    return this.dateFormatterService.get4DecimalDigits(parseFloat((decimal * 100).toFixed(4)));
  }

  isDateExpired(date: Date | string): boolean {
    return moment().isAfter(date, 'day');
  }

  isDateNearToExpire(date: Date): boolean {
    return moment().add(4, 'days').isAfter(date, 'day');
  }

  countDaysToToday(date: Date | moment.Moment | number) {
    let nDate;
    let nReferenceDate = moment().utc(true).format('YYYY-MM-DD');

    if (typeof date === 'number' || date instanceof Number) {
      nDate = moment(date).isValid() ? moment(date).utc(false).format('YYYY-MM-DD') : null;
    } else {
      nDate = moment(date).isValid() ? moment(date).utc(true).format('YYYY-MM-DD') : null;
    }

    return moment(nDate).diff(moment(nReferenceDate), 'days');
  }

  isDayBefore(date: Date | moment.Moment | number, referenceDate: Date | moment.Moment | number) {
    let nDate;
    let nReferenceDate;

    if (typeof date === 'number' || date instanceof Number) {
      nDate = moment(date).isValid() ? moment(date).utc(false).format('YYYY-MM-DD') : null;
    } else {
      nDate = moment(date).isValid() ? moment(date).utc(true).format('YYYY-MM-DD') : null;
    }

    if (typeof referenceDate === 'number' || referenceDate instanceof Number) {
      nReferenceDate = moment(referenceDate).isValid() ? moment(referenceDate).utc(false).format('YYYY-MM-DD') : null;
    } else {
      nReferenceDate = moment(referenceDate).isValid() ? moment(referenceDate).utc(true).format('YYYY-MM-DD') : null;
    }
    if (nDate == null || nReferenceDate == null) return false;

    return moment(nDate).add(1, 'days').isSame(moment(nReferenceDate), 'day');
  }

  isSameDay(date: Date | moment.Moment | number, referenceDate: Date | moment.Moment | number) {
    let nDate;
    let nReferenceDate;

    if (typeof date === 'number' || date instanceof Number) {
      nDate = moment(date).isValid() ? moment(date).utc(false).format('YYYY-MM-DD') : null;
    } else {
      nDate = moment(date).isValid() ? moment(date).utc(true).format('YYYY-MM-DD') : null;
    }

    if (typeof referenceDate === 'number' || referenceDate instanceof Number) {
      nReferenceDate = moment(referenceDate).isValid() ? moment(referenceDate).utc(false).format('YYYY-MM-DD') : null;
    } else {
      nReferenceDate = moment(referenceDate).isValid() ? moment(referenceDate).utc(true).format('YYYY-MM-DD') : null;
    }
    if (nDate == null || nReferenceDate == null) return false;

    const a = moment(nDate);
    const b = moment(nReferenceDate);
    const result = a.isSame(b, 'day');
    return result;
  }

  isSameDatetime(date: Date | moment.Moment | number, referenceDate: Date | moment.Moment | number) {
    let nDate;
    let nReferenceDate;

    if (typeof date === 'number' || date instanceof Number) {
      nDate = moment(date).isValid() ? moment(date).utc(false).format('YYYY-MM-DD HH:mm:ss') : null;
    } else {
      nDate = moment(date).isValid() ? moment(date).utc(true).format('YYYY-MM-DD HH:mm:ss') : null;
    }

    if (typeof referenceDate === 'number' || referenceDate instanceof Number) {
      nReferenceDate = moment(referenceDate).isValid() ? moment(referenceDate).utc(false).format('YYYY-MM-DD HH:mm:ss') : null;
    } else {
      nReferenceDate = moment(referenceDate).isValid() ? moment(referenceDate).utc(true).format('YYYY-MM-DD HH:mm:ss') : null;
    }
    if (nDate == null || nReferenceDate == null) return false;

    const a = moment(nDate);
    const b = moment(nReferenceDate);
    const result = a.isSame(b, 'minute');
    return result;
  }

  isDateAfter(date: Date | moment.Moment | number, referenceDate: Date | moment.Moment | number) {
    let nDate;
    let nReferenceDate;

    if (typeof date === 'number' || date instanceof Number) {
      nDate = moment(date).isValid() ? moment(date).utc(false).format('YYYY-MM-DD') : null;
    } else {
      nDate = moment(date).isValid() ? moment(date).utc(true).format('YYYY-MM-DD') : null;
    }

    if (typeof referenceDate === 'number' || referenceDate instanceof Number) {
      nReferenceDate = moment(referenceDate).isValid() ? moment(referenceDate).utc(false).format('YYYY-MM-DD') : null;
    } else {
      nReferenceDate = moment(referenceDate).isValid() ? moment(referenceDate).utc(true).format('YYYY-MM-DD') : null;
    }
    if (nDate == null || nReferenceDate == null) return false;

    const a = moment(nDate);
    const b = moment(nReferenceDate);
    const result = a.isAfter(b, 'day');
    return result;
  }

  isDateBefore(date: Date | moment.Moment | number, referenceDate: Date | moment.Moment | number) {
    let nDate;
    let nReferenceDate;

    if (typeof date === 'number' || date instanceof Number) {
      nDate = moment(date).isValid() ? moment(date).utc(false).format('YYYY-MM-DD') : null;
    } else {
      nDate = moment(date).isValid() ? moment(date).utc(true).format('YYYY-MM-DD') : null;
    }

    if (typeof referenceDate === 'number' || referenceDate instanceof Number) {
      nReferenceDate = moment(referenceDate).isValid() ? moment(referenceDate).utc(false).format('YYYY-MM-DD') : null;
    } else {
      nReferenceDate = moment(referenceDate).isValid() ? moment(referenceDate).utc(true).format('YYYY-MM-DD') : null;
    }
    if (nDate == null || nReferenceDate == null) return false;

    const a = moment(nDate);
    const b = moment(nReferenceDate);
    const result = a.isBefore(b, 'day');
    return result;
  }

  isDateBetween(start: Date | moment.Moment | number, end: Date | moment.Moment | number): boolean {
    if (end != null) {
      return this.isDateAfter(moment(), start) && this.isDateBefore(moment(), end);
    } else {
      return this.isDateAfter(moment(), start);
    }
  }

  millisPerDay(): number {
    return 1000 * 60 * 60 * 24;
  }

  addDay(date: Date | moment.Moment | number, daysToAdd: number): Date {
    let nDate;
    if (typeof date === 'number' || date instanceof Number) {
      nDate = moment(date).isValid() ? moment(date).utc(false).format('YYYY-MM-DD') : null;
    } else {
      nDate = moment(date).isValid() ? moment(date).utc(true).format('YYYY-MM-DD') : null;
    }
    const result = nDate !== null ? moment(nDate).add(daysToAdd, 'days').toDate() : null;
    return result;
  }

  removeDay(date: Date | moment.Moment | number, daysToremove: number): Date {
    let nDate;
    if (typeof date === 'number' || date instanceof Number) {
      nDate = moment(date).isValid() ? moment(date).utc(false).format('YYYY-MM-DD') : null;
    } else {
      nDate = moment(date).isValid() ? moment(date).utc(true).format('YYYY-MM-DD') : null;
    }
    const result = nDate !== null ? moment(nDate).subtract(daysToremove, 'days').toDate() : null;
    return result;
  }

  // Do not change this syntax. htmlSpace is passed from html because not work if it is defined into method!
  // Same thing for newLine. Probabily it is a mat tooltip bug or an angular bug.
  parseEntityTooltip(newLine: string, htmlSpace: string, entityTooltip: string): string {
    if (entityTooltip) {
      const KEY_TREE = 'tree:';
      const KEY_TREE_SEPARATOR = ';';
      let tooltip = '';
      if (entityTooltip.startsWith(KEY_TREE)) {
        entityTooltip = entityTooltip.substr(entityTooltip.indexOf(KEY_TREE) + KEY_TREE.length);
        const childrens = entityTooltip.split(KEY_TREE_SEPARATOR);
        for (let i = 0; i < childrens.length - 1; i++) {
          const child = childrens[i].trim();
          let spaces = '';
          for (let s = 0; s < i; s++) {
            spaces += htmlSpace;
          }
          tooltip += i > 0 ? newLine + spaces + child : child;
        }
      } else {
        // tooltip = entityTooltip;
        const childrens = entityTooltip.split(KEY_TREE_SEPARATOR);
        for (let i = 0; i < childrens.length; i++) {
          const child = childrens[i].trim();
          tooltip += i > 0 ? newLine + child : child;
        }
      }
      return tooltip;
    }
  }

  flyingButtonsDragEnd($event: CdkDragEnd) {
    LogService.debug(this, 'FormatComponent.flyingButtonsDragEnd', 'flyingButtons CdkDragEnd', $event);
    const moveX = $event.distance.x;
    const moveY = $event.distance.y;
    let newX = this.x + moveX;
    let newY = this.y + moveY;
    const buttonWidth = $event.source.element.nativeElement.clientWidth;
    const buttonHeight = $event.source.element.nativeElement.clientHeight;
    let maxContainerX = 9999;
    let maxContainerY = 9999;

    let container = getClosestMatchingAncestor($event.source.element.nativeElement, $event.source.boundaryElement as string);
    if (container != null) {
      maxContainerX = container.clientWidth;
      maxContainerY = container.clientHeight;
    }
    // Adjust coordinates to container size and button size
    newX = Math.abs(newX) <= maxContainerX - buttonWidth ? newX : -(maxContainerX - buttonWidth);
    newY = Math.abs(newY) <= maxContainerY - buttonHeight ? newY : -(maxContainerY - buttonHeight);

    const flyingButtonSettings: FlyingButtonSettings = {
      x: newX <= 0 ? newX : 0,
      y: newY <= 0 ? newY : 0,
    };
    this.store.dispatch(PreferencesAction.storeFlyingButtonSettings({ isTenantAdmin: this.isTenantAdmin, flyingButtonSettings }));
    this.flyingBtnDragging = false;
  }

  openChatbot() {
    this.store.dispatch(FinesseChatbotInterfaceStoreAction.openFinesseChatbotInterface());
  }
}

/** Gets the closest ancestor of an element that matches a selector. */
export function getClosestMatchingAncestor(element: HTMLElement, selector: string) {
  let currentElement = element.parentElement as HTMLElement | null;

  while (currentElement) {
    // IE doesn't support `matches` so we have to fall back to `msMatchesSelector`.
    if (currentElement.matches ? currentElement.matches(selector) : (currentElement as any).msMatchesSelector(selector)) {
      return currentElement;
    }

    currentElement = currentElement.parentElement;
  }

  return null;
}
