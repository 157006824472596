import { ActionResponse } from 'src/app/models/action-response';
import { License } from 'src/app/models/license';
import { SwapContractInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/swap-contract-wizard/init-step/swap-contract-init-step';

export interface State {
  initStep: SwapContractInitStep;
  assetTobeCancelledStep: License;
  validationResponse: ActionResponse;
  validationErrorMessage: string;
  newOrderResponse: ActionResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  assetTobeCancelledStep: null,
  validationResponse: null,
  validationErrorMessage: null,
  newOrderResponse: null,
  errorMessage: null,
};
