import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Subject } from 'rxjs';
import { FormatComponent } from '../base-components/format-component';

@Injectable()
export class MatPaginatorTranslations extends FormatComponent implements MatPaginatorIntl {
  changes = new Subject<void>();

  constructor() {
    super();
  }

  itemsPerPageLabel = this.translate.instant(_('paginator_itemssperpage_label'));
  firstPageLabel = this.translate.instant(_('paginator_firstpage_label'));
  lastPageLabel = this.translate.instant(_('paginator_lastpage_label'));
  nextPageLabel = this.translate.instant(_('paginator_nextpage_label'));
  previousPageLabel = this.translate.instant(_('paginator_previouspage_label'));
  pageLabel = this.translate.instant(_('paginator_page_label'));
  pageOfLabel = this.translate.instant(_('paginator_pageof_label'));

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      // return `${this.pageLabel} 0 ${this.pageOfLabel} 0`;
      return `0 ${this.pageOfLabel} 0`;
    }
    // const amountPages = Math.ceil(length / pageSize);
    const startRange = page * pageSize + 1;
    const endRange = startRange + pageSize - 1;
    return `${startRange}-${endRange < length ? endRange : length} ${this.pageOfLabel} ${length}`;
    // return `${this.pageLabel} ${startRange}-${endRange < length ? endRange : length} ${this.pageOfLabel} ${length}`;
  }
}
