import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { Entity } from '../models/entity';
import { Notification, NotificationCount } from '../models/notification';
import { PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class NotificationDataService {
  getNotificationCount(): Observable<BaseResponse<NotificationCount>> {
    const m = this.getNotificationCount.name;

    const path = environment.getEndpoint(ApiPath.Notifications.NOTIFICATIONS_COUNT);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<NotificationCount>>(path);

    return retVal;
  }

  getNotificationKinds(): Observable<BaseResponse<Entity[]>> {
    const m = this.getNotificationKinds.name;

    const path = environment.getEndpoint(ApiPath.Notifications.NOTIFICATIONS_KINDS);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<Entity[]>>(path);

    return retVal;
  }

  getNotificationSnoozes(): Observable<BaseResponse<Entity[]>> {
    const m = this.getNotificationSnoozes.name;

    const path = environment.getEndpoint(ApiPath.Notifications.NOTIFICATIONS_SNOOZES);
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<Entity[]>>(path);

    return retVal;
  }

  getNotifications(request: PaginatedRequest): Observable<BaseResponse<Notification[]>> {
    const m = this.getNotifications.name;

    const path = environment.getEndpoint(ApiPath.Notifications.NOTIFICATIONS_GRID);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Notification[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  setNotificationRead(notificationIds: number[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.setNotificationRead.name;

    const path = environment.getEndpoint(ApiPath.Notifications.NOTIFICATIONS_READ);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    const params = new HttpParams().set('notificationIds', notificationIds.join(','));
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getNotificationsSpreadsheet(request: PaginatedRequest) {
    const m = this.getNotificationsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Notifications.NOTIFICATIONS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  postDismiss(notificationIds: number[]): Observable<BaseResponse<ActionResponse>> {
    const m = this.postDismiss.name;

    const path = environment.getEndpoint(ApiPath.Notifications.NOTIFICATIONS_DISMISS);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    const params = new HttpParams().set('notificationIds', notificationIds.join(','));
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  postSnooze(notificationIds: number[], snoozeId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.postDismiss.name;

    const path = environment.getEndpoint(ApiPath.Notifications.NOTIFICATIONS_SNOOZE);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    const params = new HttpParams().set('notificationIds', notificationIds.join(',')).set('snoozeId', snoozeId.toString());
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
