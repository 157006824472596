<div class="cron-tab-container">
  <mat-radio-group [disabled]="!isEditing" [(ngModel)]="selectedOption" (change)="regenerateCron()">
    <mat-radio-button [value]="CronSecondsOptions.everySecond">
      <label>{{ 'cron_editor_label_every_second' | translate }}</label>
    </mat-radio-button>

    <mat-radio-button [value]="CronSecondsOptions.everyNSecondStartFrom">
      <label>{{ 'cron_editor_label_every' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronSecondsOptions.everyNSecondStartFrom"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="everyNSecond"
        >
          <mat-option class="form-field-option" *ngFor="let every of everyNSecondOptions" [value]="every">
            {{ every }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_every_second_startingat' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronSecondsOptions.everyNSecondStartFrom"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="everyNSecondStartFrom"
        >
          <mat-option class="form-field-option" *ngFor="let startingFrom of rangeSecondsOptions" [value]="startingFrom">
            {{ startingFrom | number : '2.0' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-radio-button>

    <mat-radio-button [value]="CronSecondsOptions.specificSeconds">
      <label>{{ 'cron_editor_label_specific_second' | translate }}</label>
      <ng-container *ngIf="isEditing">
        <span style="margin-left: 0.5rem"></span>
        <button
          mat-raised-button
          color="primary"
          [disabled]="selectedOption !== CronSecondsOptions.specificSeconds"
          (click)="selectAllCheckboxes()"
        >
          {{ 'label_select_all' | translate }}
        </button>
        <span style="margin-left: 0.5rem"></span>
        <button
          mat-raised-button
          color="primary"
          [disabled]="selectedOption !== CronSecondsOptions.specificSeconds"
          (click)="resetCheckboxes()"
        >
          {{ 'label_reset' | translate }}
        </button>
      </ng-container>
    </mat-radio-button>
    <div class="cron-input-specific-checkboxes-container">
      <mat-checkbox
        [disabled]="!isEditing || selectedOption !== CronSecondsOptions.specificSeconds"
        *ngFor="let second of rangeSecondsOptions"
        [checked]="isCheckboxChecked(second)"
        (change)="checkboxSelectionChanged($event)"
        [value]="second"
      >
        {{ second | number : '2.0' }}</mat-checkbox
      >
    </div>

    <mat-radio-button [value]="CronSecondsOptions.rangeSeconds">
      <label>{{ 'cron_editor_label_every_second_between' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronSecondsOptions.rangeSeconds"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="rangeSecondsStarts"
        >
          <mat-option class="form-field-option" *ngFor="let startingFrom of rangeSecondsOptions" [value]="startingFrom">
            {{ startingFrom | number : '2.0' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'cron_editor_label_every_second_between_andseconds' | translate }}</label>
      <mat-form-field appearance="outline" class="reset-outline-hint cron-input-select-twodigits">
        <mat-select
          disableOptionCentering
          panelClass="finoffice-matselect-panel"
          [disabled]="!isEditing || selectedOption !== CronSecondsOptions.rangeSeconds"
          (selectionChange)="regenerateCron()"
          [(ngModel)]="rangeSecondsEnds"
        >
          <mat-option class="form-field-option" *ngFor="let to of rangeSecondsOptions" [value]="to">
            {{ to | number : '2.0' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-radio-button>
  </mat-radio-group>
  <!-- <h3>Cron expression - seconds {{ secondsExpression }}</h3> -->
</div>
