import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import {
  Desk,
  DeskCostcentreChangeRequest,
  DeskCostcentreRequest,
  DeskParticularsRequest,
  OrganisationDeskParticularsRequest,
} from '../models/desk';
import { EntityWizardResponse } from '../models/forms/entity-wizard';
import { GenericPeriod } from '../models/generic-period';
import { OrganisationCostcentrePeriod } from '../models/organisation-costcentre-period';
import { PaginatedRequest } from '../models/paginated-request';
import { MoveAllDesksRequest } from '../shared-components/allocation-wizard-modal-dialogs/move-all-desks-wizard/confirmation-step/move-all-desks-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class DeskDataService {
  getDesks(request: PaginatedRequest): Observable<BaseResponse<Desk[]>> {
    const m = this.getOrganisationDesks.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Desk[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getDesksBySelectedAsset(assetId: number, filterStarts: string, excludeAssetId: number): Observable<BaseResponse<Desk[]>> {
    const m = this.getOrganisationDesks.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS);
    LogService.info(this, m, LogService.GET + path, null);

    const params = new HttpParams()
      .set('filterAssetIds', assetId)
      .set('filterStarts', filterStarts)
      .set('filterExcludeAssetIds', excludeAssetId);
    const retVal = this.http.get<BaseResponse<Desk[]>>(path, {
      params,
    });

    return retVal;
  }

  getDefaultDesk(date: string, assetId: number): Observable<BaseResponse<Desk>> {
    const m = this.getOrganisationDesks.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS_DEFAULT);
    LogService.info(this, m, LogService.GET + path, null);

    const params = new HttpParams().set('date', date).set('filter_asset_id', assetId);
    const retVal = this.http.get<BaseResponse<Desk>>(path, {
      params,
    });
    return retVal;
  }

  getDeskParticularsDefault(userId: number): Observable<BaseResponse<Desk>> {
    const m = this.getDeskParticularsDefault.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS_PARTICULAR_DEFAULTS);
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams();
    if (userId) {
      params = params.append('userId', userId);
    }
    const retVal = this.http.get<BaseResponse<Desk>>(path, { params });
    return retVal;
  }

  getOrganisationDeskParticularsDefault(): Observable<BaseResponse<Desk>> {
    const m = this.getDeskParticularsDefault.name;

    const path = environment.getEndpoint(ApiPath.OrganisationDesks.ORGANISATIONDESKS_PARTICULAR_DEFAULTS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<Desk>>(path);
    return retVal;
  }

  getDeskOrganisationsDefault(organisationId: number, costcentreId: number): Observable<BaseResponse<OrganisationCostcentrePeriod>> {
    const m = this.getDeskParticularsDefault.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS_DEFAULT_PERIOD);
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams();
    if (organisationId) {
      params = params.append('organisationId', organisationId);
    }
    if (costcentreId) {
      params = params.append('costcentreId', costcentreId);
    }
    const retVal = this.http.get<BaseResponse<OrganisationCostcentrePeriod>>(path, { params });
    return retVal;
  }

  getDeskParticulars(deskId: number): Observable<BaseResponse<Desk>> {
    const m = this.getDeskParticulars.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS_PARTICULARS_BYID(deskId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<Desk>>(path);
    return retVal;
  }

  getDeskClipboardInfo(deskId: number): Observable<BaseResponse<string>> {
    const m = this.getDeskParticulars.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS_CLIPBOARD_BYID(deskId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<string>>(path);
    return retVal;
  }

  putDeskParticulars(deskId: number, request: DeskParticularsRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.putDeskParticulars.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS_PARTICULARS_BYID(deskId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);
    return retVal;
  }

  createDeskParticulars(request: DeskParticularsRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.createDeskParticulars.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS_PARTICULARS);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);
    return retVal;
  }

  getOrganisationDeskParticulars(deskId: number): Observable<BaseResponse<Desk>> {
    const m = this.getOrganisationDeskParticulars.name;

    const path = environment.getEndpoint(ApiPath.OrganisationDesks.ORGANISATIONDESKS_PARTICULARS_BYID(deskId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<Desk>>(path);
    return retVal;
  }

  getOrganisationDeskClipboardInfo(deskId: number): Observable<BaseResponse<string>> {
    const m = this.getOrganisationDeskClipboardInfo.name;

    const path = environment.getEndpoint(ApiPath.OrganisationDesks.ORGANISATIONDESKS_CLIPBOARD_BYID(deskId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<string>>(path);
    return retVal;
  }

  putOrganisationDeskParticulars(deskId: number, request: OrganisationDeskParticularsRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.putOrganisationDeskParticulars.name;

    const path = environment.getEndpoint(ApiPath.OrganisationDesks.ORGANISATIONDESKS_PARTICULARS_BYID(deskId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);
    return retVal;
  }

  createOrganisationDeskParticulars(request: OrganisationDeskParticularsRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.createOrganisationDeskParticulars.name;

    const path = environment.getEndpoint(ApiPath.OrganisationDesks.ORGANISATIONDESKS_PARTICULARS);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);
    return retVal;
  }

  getOrganisationDesks(request: PaginatedRequest): Observable<BaseResponse<Desk[]>> {
    const m = this.getOrganisationDesks.name;

    const path = environment.getEndpoint(ApiPath.OrganisationDesks.ORGANISATIONDESKS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Desk[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getOrganisationDesksSpreadsheet(request: PaginatedRequest) {
    const m = this.getOrganisationDesksSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.OrganisationDesks.ORGANISATIONDESKS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getDeskCostcentrePeriods(deskId: number): Observable<BaseResponse<GenericPeriod[]>> {
    const m = this.getDeskCostcentrePeriods.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS_COSTCENTRE_PERIODS(deskId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<GenericPeriod[]>>(path);

    return retVal;
  }

  getDeskMultipleCostcentres(deskId: number): Observable<BaseResponse<Desk[]>> {
    const m = this.getDeskMultipleCostcentres.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS_COSTCENTRES(deskId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<Desk[]>>(path);

    return retVal;
  }

  postDeskMultipleCostcentres(deskId: number, request: DeskCostcentreRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.postDeskMultipleCostcentres.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS_COSTCENTRES(deskId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);
    return retVal;
  }

  postDeskMultipleCostcentresChanges(deskId: number, request: DeskCostcentreChangeRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.postDeskMultipleCostcentresChanges.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESKS_COSTCENTRES_CHANGE(deskId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);
    return retVal;
  }

  moveAllDesks(request: MoveAllDesksRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.moveAllDesks.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESK_MOVE);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);
    return retVal;
  }

  constructor(private http: HttpClient) {}
}
