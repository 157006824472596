import { KeyValue } from '@angular/common';
import { StepValue } from '../shared/inputs/step-value-control/step-value';
import { Entity } from './entity';
import { ProductFamily } from './productfamily';
import { BillingAccount } from './vendor-account';

export const CATALOGITEM_BILLING_CHARGE_SAMEBILLINGALLPROVIDERS = 0;
export const CATALOGITEM_BILLING_CHARGE_INDIVIDUALBILLINGPERACCOUNT = 1;
export const CATALOGITEM_BILLING_CHARGE_INDIVIDUALBILLINGPERPROVIDER = 2;

export class CatalogItemParticulars {
  catalogAssetIdDefault: string;
  catalogAssetIdDefaultId: number;
  catalogAssetIdDefaultOptions: KeyValue<string, string>[];
  catalogBiller: string;
  catalogBillerId: number;
  catalogBillingaccountCharge: string;
  catalogBillingaccountChargeId: number;
  catalogBillingaccountChargeOptions: KeyValue<string, string>[];
  catalogCode: string;
  catalogContract: string;
  catalogContractDescription: string;
  catalogContractId: number;
  catalogContractNo: string;
  catalogDescription: string;
  catalogId: number;
  catalogInterface: string;
  catalogInterfaceId: number;
  catalogInterfaceManaged: boolean;
  catalogInterfaceServiceCode: string;
  catalogInterfaceServiceName: string;
  catalogInterfaceSite: string;
  catalogInterfaceSiteId: number;
  catalogIssuer: string;
  catalogIssuerId: number;
  catalogItemkind: string;
  catalogItemkindId: number;
  catalogItemkindOptions: KeyValue<string, string>[];
  catalogItemcategory: string;
  catalogItemcategoryId: number;
  catalogItemcategoryOptions: KeyValue<string, string>[];
  catalogItemtype: string;
  catalogItemtypeId: number;
  catalogItemtypeOptions: KeyValue<string, string>[];
  catalogName: string;
  catalogPolicy: string;
  catalogPolicyDescription: string;
  catalogPolicyId: number;
  catalogPolicyLogic: string;
  catalogPolicyLogicId: number;
  catalogPolicyLogicOptions: KeyValue<string, string>[];
  catalogPriceShared: boolean;
  catalogPrices: CatalogItemPriceParticulars[];
  catalogDefaultPrice?: CatalogItemPriceParticulars;
  catalogServiceCategory: string;
  catalogServiceCategoryId: number;
  catalogServiceProviders: CatalogItemServiceProvider[];
  catalogServiceType: string;
  catalogServiceTypeId: number;
  catalogSharingAllowed: boolean | number;
  catalogSharingAllowedOptions: KeyValue<string, string>[];
  catalog3diProfilerId: number;
  catalog3diProfilerName: string;
  catalogOriginalPolicyId?: number;
  catalogOriginalPolicy?: string;
  catalogOverridePolicy?: boolean;

  public constructor(init?: Partial<CatalogItemParticulars>) {
    Object.assign(this, init);
  }
}

export class CatalogItemParticularsRequest {
  catalogAssetIdDefaultId: number;
  catalogBillingaccountChargeId: number;
  catalogCode: string;
  catalogDescription: string;
  catalogInterfaceId: number;
  catalogInterfaceManaged: boolean;
  catalogInterfaceServiceCode: string;
  catalogInterfaceServiceName: string;
  catalogInterfaceSiteId: number;
  catalogIssuerId: number;
  catalogItemcategoryId: number;
  catalogItemkindId: number;
  catalogItemtypeId: number;
  catalogName: string;
  catalogPolicyId: number;
  catalogPolicyLogicId: number;
  catalogPrices: CatalogItemPriceParticularsRequest[];
  catalogSharingAllowed: boolean;
  catalogProductFamilies: CatalogItemProductFamilyRequest[];
  catalogServiceCategoryId: number;
  catalogServiceTypeId: number;
  catalog3diProfilerId: number;
  catalog3diProfilerName: string;
}

export class CatalogItemPriceParticulars {
  priceId: number;
  priceProductFamilyId: number;
  priceBillingaccountId: number;
  priceServiceId: number;
  priceBillingitemId: number;
  priceDeliveryitemId: number;
  priceProviderId: number;
  // PRICE DETAILS
  priceBillingFrequencyId: number;
  priceAccount: string;
  priceAccountId: number;
  priceBudget: string;
  priceBudgetId: number;
  priceCurrency: string;
  priceCurrencyId: number;
  priceStarts: Date;
  priceEnds: Date;
  priceDiscount: number;
  priceRemarks: string;
  priceFixedSplitted: boolean;
  priceTaxexempt: boolean;
  // UNIT PRICE GROUP
  priceUnitUsageToggle: boolean;
  priceUnitUsageId: number;
  priceUnitSlidingScaleToggle: boolean;
  priceUnitSlidingScaleId: number;
  priceUnitPriceSteps: StepValue[];
  priceUnitPriceStepsValid?: boolean;
  priceUnitPrice: number;
  // FIXED PRICE GROUP
  priceFixedAllocationId: number;
  priceFixedSlidingScaleToggle: boolean;
  priceFixedSlidingScaleId: number;
  priceFixedPriceSteps: StepValue[];
  priceFixedPriceStepsValid?: boolean;
  priceFixedPrice: number;
  // ADDITIONAL PRICE
  priceAdditionalAllocationId: number;
  priceAdditionalSlidingScaleToggle: boolean;
  priceAdditionalSlidingScaleId: number;
  priceAdditionalPriceSteps: StepValue[];
  priceAdditionalPriceStepsValid?: boolean;
  priceAdditionalPrice: number;
  //OPTIONS
  priceAllocationOptions: KeyValue<string, string>[];
  priceBillingFrequencyOptions: KeyValue<string, string>[];
  priceSlidingScaleOptions: KeyValue<string, string>[];
  priceUnitUsageOptions: KeyValue<string, string>[];

  public constructor(init?: Partial<CatalogItemPriceParticulars>) {
    Object.assign(this, init);
  }
}

export class CatalogItemPriceParticularsRequest {
  priceId: number;
  priceProductFamilyId: number;
  priceBillingaccountId: number;
  priceServiceId: number;
  priceBillingitemId: number;
  priceDeliveryitemId: number;
  priceDeleted: boolean;
  // PRICE DETAILS
  priceAccountId: number;
  priceBillingFrequencyId: number;
  priceBudgetId: number;
  priceCurrencyId: number;
  priceStarts: string;
  priceDiscount: number;
  priceRemarks: string;
  priceFixedSplitted: boolean;
  priceTaxexempt: boolean;
  // UNIT PRICE GROUP
  priceUnitUsageToggle: boolean;
  priceUnitUsageId: number;
  priceUnitSlidingScaleToggle: boolean;
  priceUnitSlidingScaleId: number;
  priceUnitPriceSteps: StepValue[];
  priceUnitPrice: number;
  // FIXED PRICE GROUP
  priceFixedAllocationId: number;
  priceFixedSlidingScaleToggle: boolean;
  priceFixedSlidingScaleId: number;
  priceFixedPriceSteps: StepValue[];
  priceFixedPrice: number;
  // ADDITIONAL PRICE
  priceAdditionalAllocationId: number;
  priceAdditionalSlidingScaleToggle: boolean;
  priceAdditionalSlidingScaleId: number;
  priceAdditionalPriceSteps: StepValue[];
  priceAdditionalPrice: number;
}

export class CatalogItemConfirmPrice {
  productfamily: string;
  provider: string;
  providerName: string;
  providerCode: string;
  billingaccount: string;
  currency: string;
  unitPrice: string;
  fixedPrice: string;
  additionalPrice: string;
  priceDerived: boolean;
}

export class CatalogItemProductFamily extends ProductFamily {
  productfamilyProviderName: string;
  productfamilyProviderCode: string;
  productfamilyPolicyId: number;
  productfamilyPolicy: string;
  productfamilyBillingAccounts: BillingAccount[];
  entityDeleted?: boolean;
}

export class CatalogItemServiceProvider extends Entity {
  catalogserviceproviderBillingaccounts: BillingAccount[];
  catalogserviceproviderId: number;
  catalogserviceproviderDeliveryitemId: number;
  catalogserviceproviderDeliveryitem: string;
  catalogserviceproviderProductfamily: string;
  catalogserviceproviderProductfamilyId: number;
  catalogserviceproviderProvider: string;
  catalogserviceproviderProviderId: number;
  catalogserviceproviderProductPolicy: string;
  catalogserviceproviderProductPolicyId: number;
  catalogserviceproviderServiceProviderCode: string;
  catalogserviceproviderServiceProviderId: number;
  catalogserviceproviderServiceProviderName: string;
  entityDeleted?: boolean;
  isReadOnly?: boolean;
}

export class CatalogItemProductFamilyRequest {
  productfamilyId: number;
  providerCode: string;
  providerName: string;
  providerId: number;
  deliveryitemId: number;
  billingaccountIds: number[];
}

export class CatalogItemProvider {
  providerId: number;
  providerName: string;
}

export enum CatalogItemType {
  //untyped = 0,
  terminal = 1,
  //package = 2,
  allotherservices = 3,
  addon = 4,
  infrastructure = 5,
}

export enum CatalogItemKind {
  marketData = 1,
  licenseTechnologyAsset = 2,
  projectTracking = 3,
}

export enum CatalogItemCategory {
  //uncategorized = 0,
  display = 1,
  nonDisplay = 2,
  otherServices = 3,
  //package = 4,
}
