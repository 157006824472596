import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FilesMultiselectionModalComponent,
  FilesMultiselectionModalDialogData,
} from 'src/app/core/modal/files-multiselection-modal/files-multiselection-modal.component';
import { FileGridNode } from 'src/app/models/file-grid-node';
import { LoadedEncodedFile } from './loaded-encoded-file';

@Component({
  selector: 'app-file-dropper',
  templateUrl: './file-dropper.component.html',
  styleUrls: ['./file-dropper.component.scss'],
})
export class FileDropperComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() loadDbFiles = false;
  @Input() encodeFiles = false;
  @Input() expectedFiles: number;
  @Input() fileRestrictionText: string;
  @Output() loadedEncodedFiles: EventEmitter<LoadedEncodedFile[]> = new EventEmitter<LoadedEncodedFile[]>();
  @Output() loadedFiles: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() loadedDbFiles: EventEmitter<FileGridNode[]> = new EventEmitter<FileGridNode[]>();
  encodedFiles: LoadedEncodedFile[] = [];
  files: File[] = [];
  dbFiles: FileGridNode[] = [];

  constructor(private dialog: MatDialog) {}

  reset() {
    this.encodedFiles = [];
    this.files = [];
    this.dbFiles = [];
    this.fileInput.nativeElement.value = '';
  }

  isUploadDisabled() {
    if (this.expectedFiles) {
      if (this.encodeFiles) {
        return this.expectedFiles <= this.encodedFiles.length + this.dbFiles.length;
      } else {
        return this.expectedFiles <= this.files.length + this.dbFiles.length;
      }
    } else {
      return false;
    }
  }

  showError() {
    if (this.expectedFiles) {
      if (this.encodeFiles) {
        return this.expectedFiles < this.encodedFiles.length + this.dbFiles.length;
      } else {
        return this.expectedFiles < this.files.length + this.dbFiles.length;
      }
    } else {
      return false;
    }
  }

  openDocumentsDialog() {
    this.dialog
      .open(FilesMultiselectionModalComponent, {
        height: '80vh',
        width: '80vw',
        autoFocus: false,
        data: {
          isMultiSelect: this.expectedFiles > 1,
        } as FilesMultiselectionModalDialogData,
      })
      .afterClosed()
      .subscribe((selectedEntities) => {
        const files = selectedEntities as FileGridNode[];
        if (files != null && files.length > 0) {
          this.dbFiles.push(...files);
          this.loadedDbFiles.emit(this.dbFiles);
        }
      });
  }

  uploadFile(fileList: FileList) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < fileList.length; index++) {
      const file = fileList[index];
      if (this.encodeFiles) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.encodedFiles.push({
            filename: file.name,
            base64: reader.result.toString(),
          });
          this.loadedEncodedFiles.emit(this.encodedFiles);
        };
      } else {
        this.files.push(file);
        this.loadedFiles.emit(this.files);
      }
    }
    this.fileInput.nativeElement.value = '';
  }

  deleteAttachment(index) {
    if (this.encodeFiles) {
      this.encodedFiles.splice(index, 1);
      this.loadedEncodedFiles.emit(this.encodedFiles);
    } else {
      this.files.splice(index, 1);
      this.loadedFiles.emit(this.files);
    }
  }

  deleteDbAttachment(index) {
    this.dbFiles.splice(index, 1);
    this.loadedDbFiles.emit(this.dbFiles);
  }
}
