import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { RemoveRecordsModalComponent } from 'src/app/core/modal/remove-records-modal/remove-records-modal.component';
import { Entity } from 'src/app/models/entity';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { RootStoreState } from 'src/app/root-store';
import { LogService } from 'src/app/services/log-service';
import { GridBaseComponent } from 'src/app/shared/base-components/grid-base-component';
import {
  GenericAuditLogModalDialogComponent,
  GenericAuditLogModalDialogData,
} from '../generic-audit-log-modal-dialog/generic-audit-log-modal-dialog.component';

export class GenericGridViewModel {
  entityField: string;
  columnTitleKey: string;
  columnType: string;
  columnWidth: string;
  isOrderByActive?: boolean;
  isOrdesAsc?: boolean;
  columnPriceSwitchField?: string;
}
export class GenericGridFilterModel {
  filterTitle: string;
  filterApiPath: string;
  filterParams: string;
}

@Component({
  selector: 'app-generic-grid-view',
  templateUrl: './generic-grid-view.component.html',
  styleUrls: ['./generic-grid-view.component.scss'],
})
export class GenericGridViewComponent extends GridBaseComponent {
  @Input() gridApiPath: string;
  @Input() gridEnableHeader = true;
  @Input() gridNoDataMessage: string;
  @Input() gridRefreshEvent: Observable<void>;
  @Input() gridEnableSearch = false;
  @Input() gridEnablePagination = false;
  @Input() gridColumnsConfig: GenericGridViewModel[];
  @Input() gridPageSize: number;
  @Input() gridAllowRemove = false;
  @Input() showGridTitle = false;
  @Input() isWizard = false;
  @Input() gridTitle: string;
  @Input() selectedRowToRestore: Entity;
  @Input() autoSelectFirstRow: boolean;
  @Output() resetSelectedRowEvEm = new EventEmitter();
  @Output() isLoadingEvEm = new EventEmitter<boolean>();
  @Output() noDataEvEm = new EventEmitter<boolean>();

  selectedRow: Entity;

  @Output() rowClick = new EventEmitter<Entity>();
  @Output() createFlagEvEm = new EventEmitter<boolean>();

  selectedColumnForOrdering: GenericGridViewModel;

  isConfigurationLoaded = false;

  constructor(protected store: Store<RootStoreState.State>, protected cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  gbOnInit() {
    const m = this.gbOnInit.name;

    // Workaround to set columns. gbGetDisplayColumnTable method called before
    // this.gridColumnNames valorization is done becouse it managed into constructor.
    const columns = [];
    this.noEntityData = true;

    if (!this.gridColumnsConfig) {
      this.gridColumnsConfig = this.getDefaultColumnsConfiguration();
    }

    this.gridColumnsConfig.forEach((column) => {
      columns.push(column.entityField);
      if (column.isOrderByActive) {
        this.selectedColumnForOrdering = column;
      }
    });

    this.displayedColumnsTable = this.gridAllowRemove ? ['select', ...columns, 'auditlog'] : [...columns, 'auditlog'];
    this.displayedColumnsTable = this.isWizard ? this.displayedColumnsTable.filter((c) => c !== 'auditlog') : this.displayedColumnsTable;
    // Workaround to set columns. gbGetDisplayColumnTable method called before
    // this.gridColumnNames valorization is done becouse it managed into constructor.
    this.isConfigurationLoaded = true;
    if (this.gridRefreshEvent) {
      this.subscribe(this.gridRefreshEvent, () => {
        this.refreshGrid();
      });
    }
  }

  refreshGrid() {
    this.initRequest();
    this.gbLoadEntitiesData();
  }

  initRequest() {
    this.filter = '';
    this.request = {
      pageIndex: 0,
      pageSize: this.gridPageSize,
      orderBy: this.selectedColumnForOrdering ? this.selectedColumnForOrdering.entityField : this.gbGetInitialOrderBy(),
      sort: this.selectedColumnForOrdering ? (this.selectedColumnForOrdering.isOrdesAsc ? 'asc' : 'desc') : this.gbGetInitialSort(),
      filter: this.filter,
      filterMap: this.filtersMap,
    };
  }

  onEditSwitch($event) {
    this.isEditing = $event;
  }

  /* GRID COMPONENT OVERRIDED METHOD */
  gbLoadEntitiesData() {
    this.clearSelection();
    const m = this.gbLoadEntitiesData.name;

    this.isLoading = true;
    this.isLoadingEvEm.emit(this.isLoading);
    this.dataSourceTable = new MatTableDataSource();
    if (this.gridLoadSubscription != null) {
      this.gridLoadSubscription.unsubscribe();
    }
    this.gridLoadSubscription = this.subscribe(
      this.coreDataService.getEntities(this.request, this.gbGetApiEntitiesPath()),
      (response) => {
        this.lastUsedFilters = response.filters;
        this.setFlagsByResponse(response);
        this.createFlagEvEm.emit(this.isCreateEnabled);
        if (response.data) {
          this.dataSourceTable.data = response.data as Entity[];
          this.pageTotalElements = response.data[0].entityCount;
          this.noEntityData = false;
          this.noDataEvEm.emit(this.noEntityData);
          if (this.selectedRowToRestore) {
            this.onRowClicked(this.selectedRowToRestore);
            this.selectedRowToRestore = null;
          } else if (this.autoSelectFirstRow) {
            this.onRowClicked(this.dataSourceTable.data[0]);
          }
          // this.dataSourceTable.sort = this.sortTable;
        } else {
          this.pageTotalElements = 0;
          this.dataSourceTable.data = [];
          this.noEntityData = true;
          this.noDataEvEm.emit(this.noEntityData);
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
        this.isLoading = false;
        this.isLoadingEvEm.emit(this.isLoading);
        this.noEntityData = true;
        this.noDataEvEm.emit(this.noEntityData);
      },
      () => {
        this.isLoading = false;
        this.isLoadingEvEm.emit(this.isLoading);
      }
    );
  }

  loadSpreadsheetFile() {
    this.setSpreadsheetLoading(true);
    const pr: PaginatedRequest = { ...this.request, pageSize: null };
    this.subscribe(
      this.coreDataService.getEntitiesSpreadsheet(pr, `${this.gbGetApiEntitiesPath()}/spreadsheet`),
      (blob) => {
        this.setSpreadsheetLoading(false);
        FileSaver.saveAs(blob.file, blob.filename); // FileSaver;
      },
      (error) => this.setSpreadsheetLoading(false)
    );
  }

  async gbAfterViewInitTable() {
    const m = this.gbAfterViewInitTable.name;

    LogService.debug(this, m, 'gridApiPath: ' + this.gridApiPath, null);
    LogService.debug(this, m, 'gridColumnsConfig: ', this.gridColumnsConfig);
    if (this.paginatorTable) {
      this.paginatorTable.pageSize = this.gridPageSize;
    }
    this.initRequest();
    this.gbLoadEntitiesData();
  }

  gbGetApiEntitiesPath() {
    const m = this.gbGetApiEntitiesPath.name;
    LogService.debug(this, m, 'gridApiPath: ' + this.gridApiPath, null);
    return this.gridApiPath;
  }

  gbGetApiEntitiesDetailsPath(entityId: number) {
    return null; // No details needed;
  }

  gbSetSelectedEntity(row: Entity) {
    this.selectedRow = { ...(row as Entity) };
  }

  onRowClicked(row: Entity) {
    this.gbSetSelectedEntity(row);
    this.rowClick.emit(row);
  }

  getDefaultColumnsConfiguration(): GenericGridViewModel[] {
    const col1 = new GenericGridViewModel();
    col1.columnType = 'string';
    col1.entityField = 'entityName';
    col1.columnTitleKey = _('entity_name');
    const col2 = new GenericGridViewModel();
    col2.columnType = 'string';
    col2.entityField = 'entityDescription';
    col2.columnTitleKey = _('entity_description');
    return [col1, col2];
  }

  getTooltipString(c: GenericGridViewModel, row: any): string {
    switch (c.columnType) {
      case 'date':
        return this.getDate(row[c.entityField]);
      case 'timestamp':
        return this.getTimestamp(row[c.entityField]);
      case 'price':
        return row[c.entityField] != null ? this.getMoney(row[c.entityField]) : row[c.columnPriceSwitchField];
      case 'factor':
        return this.getFactor(row[c.entityField]);
      case 'percentage':
        return this.getPercentageNormalized(row[c.entityField]);
      default:
        return row[c.entityField];
    }
  }

  removeMultipleRecords() {
    // const entitiesIds: number[] = this.selectedEntities;
    // const entityKind = this.selection.selected[0].entityKind;
    this.subscribe(
      this.dialog
        .open(RemoveRecordsModalComponent, {
          width: '70vw',
          minHeight: '60vh',
          autoFocus: false,
          disableClose: true,
          data: {
            entities: this.selectedDeletableEntities,
            isTenantAdmin: this.isTenantAdmin,
          },
        })
        .afterClosed(),
      (result) => {
        if (result) {
          this.clearSelection();
          this.resetSelectedRowEvEm.emit();
          this.gbLoadEntitiesData();
        }
      }
    );
  }

  openAuditLog(row: Entity) {
    this.dialog.open(GenericAuditLogModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'generic-audit-log-modal-dialog',
      disableClose: true,
      data: {
        selectedEntity: row,
      } as GenericAuditLogModalDialogData,
    });
  }
}
